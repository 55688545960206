<template>
  <b-row align-h="center" align-v="center" style="margin-bottom: 2em;">
    <b-col lg="6">
      <div class="pr-2 title-text">{{ title }}<slot></slot></div>
    </b-col>
    <b-col lg="6" class="text-right pr-0 mt-3 mt-sm-0">
      <div class="mr-1 mr-sm-0">
        <slot name="right"></slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Subheader',
  props: {
    title: String
  }
}
</script>

<style scoped>
.title-text {
  font-size: 20px;
  padding-left: 5px;
}
</style>

