<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">8</span>
            <span class="step-title">CHECKLIST DE LIMITACIONES</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="ready" align-h="center">
        <b-col lg="10" sm="11">
          <!-- Con arco? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="conArcoImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.con_arco" />
                    <span>Con arco?</span>
                  </template>
                  <small class="text-info font-italic">
                    Debe medir todo desde el punto más alto
                  </small>
                  <b-radio-group v-model="form.con_arco" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <!-- Garaje descuadrado? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="descuadradoImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.descuadrado" />
                    <span>Garaje descuadrado?</span>
                  </template>
                  <small class="text-info font-italic">
                    El portón requiere al menos la altura total + 30 cm para abrir sino colisionará con la pared
                  </small>
                  <b-radio-group v-model="form.descuadrado" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <!-- Viga inclinada? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="vigaInclinadaImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.viga_inclinada" />
                    <span>Viga inclinada?</span>
                  </template>
                  <small class="text-info font-italic">
                    Debe tomar la altura de buque en el punto más alto
                  </small>
                  <b-radio-group v-model="form.viga_inclinada" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <!-- Superficie discontinua? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="superficieImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.superficie" />
                    <span>Superficie discontinua?</span>
                  </template>
                  <small class="text-info font-italic">
                    Tome la medida más grande para que el portón cubra todo el espacio
                  </small>
                  <b-radio-group v-model="form.superficie" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <!-- Viga desplazada? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="vigaDesplazadaImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.viga_desplazada" />
                    <span>Viga desplazada?</span>
                  </template>
                  <small class="text-info font-italic">
                    Agregue los elementos de ajuste necesarios para tener una superficie plana para el portón
                  </small>
                  <b-radio-group v-model="form.viga_desplazada" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <!-- Estorbos internos? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="estorbosImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.estorbos" />
                    <span>Estorbos internos?</span>
                  </template>
                  <small class="text-info font-italic">
                    Coordine con el cliente para remover obstáculos o agregue elementos de ajuste
                  </small>
                  <b-radio-group v-model="form.estorbos" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <!-- Molduras o cornisas limitan? -->
          <b-row class="mt-5">
            <b-col class="text-center">
              <b-img :src="moldurasImg" class="limit-diagram" />
            </b-col>
            <b-col>
              <b-row align-h="center">
                <b-form-group label-class="outer-label">
                  <template #label>
                    <BlankIcon :field="form.molduras" />
                    <span>Molduras o cornisas limitan?</span>
                  </template>
                  <small class="text-info font-italic">
                    Coordine con el cliente para remover obstáculos o agregue elementos de ajuste
                  </small>
                  <b-radio-group v-model="form.molduras" stacked class="mt-2">
                    <b-radio value="No">No hay</b-radio>
                    <b-radio value="Yes">Sí, detallar</b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_limitations" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BlankIcon from '../BlankIcon'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
import ConArcoImg from 'images/limitations/conarco.png'
import CieloRazoImg from 'images/limitations/cielorazoinclinado.png'
import DescuadradoImg from 'images/limitations/garagedescuadrado.png'
import VigaInclinadaImg from 'images/limitations/vigainclinada.png'
import SuperficieImg from 'images/limitations/superficie.png'
import VigaDesplazadaImg from 'images/limitations/vigadesplazada.png'
import EstorbosImg from 'images/limitations/estorbos.png'
import MoldurasImg from 'images/limitations/molduras_el.png'

export default {
  name: 'CortinaLimitations',
  components: { BlankIcon, ConfiguratorStepButtons },
  data () {
    return {
      form: {
        con_arco: null,
        cielo_razo: null,
        descuadrado: null,
        viga_inclinada: null,
        superficie: null,
        viga_desplazada: null,
        estorbos: null,
        molduras: null,
        notes_limitations: ''
      },
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId']),
    conArcoImg () { return ConArcoImg },
    cieloRazoImg () { return CieloRazoImg },
    descuadradoImg () { return DescuadradoImg },
    vigaInclinadaImg () { return VigaInclinadaImg },
    superficieImg () { return SuperficieImg },
    vigaDesplazadaImg () { return VigaDesplazadaImg },
    estorbosImg () { return EstorbosImg },
    moldurasImg () { return MoldurasImg }
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.con_arco = this.cortina.con_arco
    this.form.cielo_razo = this.cortina.cielo_razo
    this.form.descuadrado = this.cortina.descuadrado
    this.form.viga_inclinada = this.cortina.viga_inclinada
    this.form.superficie = this.cortina.superficie
    this.form.viga_desplazada = this.cortina.viga_desplazada
    this.form.estorbos = this.cortina.estorbos
    this.form.molduras = this.cortina.molduras
    this.form.notes_limitations = this.cortina.notes_limitations
    this.ready = true
  },
  methods: {
    update () {
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: this.form
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    }
  }
}
</script>
