<template>
  <!-- edit vue transition -->
  <transition v-if="isDev || isPurchaseDeliveryAdmin || isPurchaseDeliverySupervisor" name="slide" mode="out-in">
    <b-container fluid class="px-3 main">
      <NavBar class="sticky-top py-3" style="z-index: 4!important;"/>
      <ScrollToBtn :threshold="150"/>
      <section class="px-1">
        <!-- render every child route component -->
        <router-view></router-view>
      </section>
    </b-container>
  </transition>
  <NotFoundComponent v-else/>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from './NavBar'
import ScrollToBtn from '../../shared/ScrollToBtn'
import NotFoundComponent from '../../NotFoundComponent.vue'
export default {
  name: "PurchaseDeliveryLayout",
  components : { NavBar, ScrollToBtn, NotFoundComponent},
  computed: {
    ...mapGetters(['isDev', 'isPurchaseDeliveryAdmin', 'isPurchaseDeliverySupervisor'])
  }
}
</script>

<style lang="scss" scoped>
.main {
  scroll-behavior: smooth;
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(10%);
}
</style>