<template>
  <div class="scorecard" @click="selectMotor">
    <label class="scorecard-label">
      <!-- <input class="d-none" type="radio"
        :value="motor.id"
        name="cortina[motor_id]"
        @click="selectMotor(motor)"/> -->
      <b-row class="scorecard-header">
        <b-col cols="4">
          <b-img :src="motor.cover_sample_url" class="motor-image" />
        </b-col>
        <b-col cols="8">
          <b-row align-h="between">
            <div class=scorecard-title>
              {{ motor.item_code }}
            </div>
          </b-row>
          <b-row align-h="between" class="pt-1">
            <span class="motor-trans pl-3">{{ motor.transmission }}</span>
            <span class="pr-2">{{ motor.voltage }} V </span>
          </b-row>
          <b-row align-h="between">
            <span class="pl-3">Backup: {{ motor.can_backup }}</span>
          </b-row>
          <b-row align-h="between">
            <span class="pl-3">{{ motor.speed }}</span>
            <span class="pr-2">{{ motor.price }}</span>
          </b-row>
          <b-row align-h="between">
            <span class="pl-3">Stock:</span>
            <span class="pr-2">{{ motor.price }}</span>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" class="progress-label">
          <span>Peso</span>
        </b-col>
        <b-col cols="10">
          <div class="progress-container">
            <div class="progress" v-if="configurable.weight">
              <div class="progress-bar" role="progressbar"
                :style="{ width: motorWeightCap + '%' }"
                :class="[motorWeightBarClass]">
              </div>
              <div class="progress-marker" style="left: 101%;" v-if="configurable.weight > weightEndValue"></div>
              <div class="progress-marker" :style="{ left: motorWeightVal + '%' }" v-else></div>
            </div>
            <div class="progress" v-else>Pend.</div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" class="progress-label">
          <span>Largo</span>
        </b-col>
        <b-col cols="10">
          <div class="progress-container">
            <div class="progress" v-if="configurable.hp">
              <div class="progress-bar" role="progressbar"
                :style="{ width: motorLengthCap + '%' }"
                :class="[motorLengthBarClass]">
              </div>
              <div class="progress-marker" style="left: 101%;" v-if="(configurable.hp / 100) > lengthEndValue"></div>
              <div class="progress-marker" :style="{ left: motorLengthVal + '%' }" v-else></div>
            </div>
            <div class="progress" v-else>Pend.</div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" class="progress-label">
          <span>Ciclos</span>
        </b-col>
        <b-col cols="10">
          <div class="progress-container">
            <div class="progress" v-if="configurable.cycles">
              <div class="progress-bar" role="progressbar"
                :style="{ width: motorCyclesCap + '%' }"
                :class="[motorCyclesBarClass]">
              </div>
              <div class="progress-marker" style="left: 101%;" v-if="configurable.cycles > cyclesEndValue"></div>
              <div class="progress-marker" :style="{ left: motorCyclesVal + '%' }" v-else></div>
            </div>
            <div class="progress" v-else>Pend.</div>
          </div>
        </b-col>
      </b-row>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MotorSelectorScorecard',
  props: {
    motor: {
      type: Object
    },
    weightEndValue: {
      type: Number
    },
    lengthEndValue: {
      type: Number
    },
    cyclesEndValue: {
      type: Number
    }
  },
  computed: {
    ...mapState ({ configurable: state => state.configurator.configurable }),
    motorWeightCap () {
      return this.motor.max_weight / this.weightEndValue * 100
    },
    motorLengthCap () {
      return this.motor.max_length / this.lengthEndValue * 100
    },
    motorCyclesCap () {
      return this.motor.cycles / this.cyclesEndValue * 100
    },
    motorWeightVal () {
      return this.configurable.weight / this.weightEndValue * 100
    },
    motorLengthVal () {
      return this.configurable.hp / this.lengthEndValue
    },
    motorCyclesVal () {
      return this.configurable.cycles / this.cyclesEndValue * 100
    },
    motorWeightBarClass () {
      return this.configurable.weight <= this.motor.max_weight ? 'bg-success' : 'bg-danger'
    },
    motorLengthBarClass () {
      return (this.configurable.hp / 100) <= this.motor.max_length ? 'bg-success' : 'bg-danger'
    },
    motorCyclesBarClass () {
      return this.configurable.cycles <= this.motor.cycles ? 'bg-success' : 'bg-danger'
    }
  },
  methods: {
    selectMotor () {
      this.$emit('motor-clicked', this.motor)
    }
  }
}
</script>

<style scoped>
.scorecard {
  margin-bottom: 2em;
  width: 300px;
  height: 220px;
  border: 1px solid #808080;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 10px #aaa;
}
.scorecard-label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1em;
}
.scorecard-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}
.scorecard-title {
  font-size: 17px;
  padding-left: 5px;
  font-weight: bold;
}
span { font-size: 11px; }
img {
  width: 75px;
  height: 75px;
}
i {
  display: none;
  color: #38948F;
}
.progress-label {
  text-align: right;
  padding-bottom: 5px;
}
.progress-label span { font-size: 11px; }
.progress-container {
  position: relative;
  margin-bottom: 0.25em;
  margin-right: 0.5em;
  padding-top: 5px;
  padding-bottom: 5px;
}
.progress-marker {
  height: 100%;
  bottom: 0;
  width: 2px;
  position: absolute;
  background: black;
}
.scorecard.active {
  border: 4px solid #38948F;
  border-radius: 5px;
}
</style>
