<template>
  <div>
    <b-container v-if="ready" fluid class="sticky-top" style="z-index: 4!important;">
      <b-row align-h="between" class="top-shelf py-4">
        <b-col lg="2" sm="2">
          <b-link  class="ml-3" @click="goBack">
            <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
            <span class="pl-2">Atrás</span>
          </b-link>
        </b-col>
        <b-col lg="8" sm="8">
          <TitleBadge v-if="ready" type="Client" :id="client_num" :title="clientForm.name"/>
        </b-col>
        <b-col lg="2" sm="2">
          <b-row align-h="center" class="py-1">
            <Button variant="green" class="float-right" @click="updateClient">
              Guardar
            </Button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <ContentView v-if="ready">
      <b-row>
        <b-col md="12">
          <!-- General information -->
          <b-row id="general_information_form" class="pb-3">
            <b-col cols="12">
              <div
                :class="showClientData ? null : 'collapsed'"
                :aria-expanded="showClientData ? 'true' : 'false'"
                aria-controls="collapse-1"
                @click="showClientData = !showClientData"
              >
                <span class="text-muted fs-20 p-0">Información General</span>
                <font-awesome-icon v-if="showClientData" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="sm" fixed-width />
              </div>
              <b-collapse id="collapse-1" class="py-2" v-model="showClientData">
                <b-card>
                <ValidationObserver ref="observer1" v-slot="{ invalid }">
                  <b-row class="pl-3">
                    <!-- column 1 -->
                    <b-col lg="6" sm="12">
                      <!-- Client Name -->
                      <ValidationProvider :rules="{required: true, max: 100}" name="Nombre" v-slot="{ valid, errors }">
                        <b-form class="pb-3">
                          <b-row>
                            <b-col sm="3" lg="3">
                              <span>Nombre:</span><span class="asterisk pr-3 ">*</span>
                            </b-col>
                            <b-col sm="9" lg="9">
                              <b-input
                                v-model="clientForm.name"
                                type="text"
                                id="client-name"
                                trim
                                autofocus
                                style="width: 100%;"
                                size="sm"
                                :state="errors[0] || !valid ? false : true"
                                :disabled="!isDev"
                              />
                              <small class="text-danger" v-if="!valid">{{ errors[0] }}</small>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationProvider>
                      <!-- Client ID & SAP GLN -->
                      <ValidationProvider  :rules="{numeric: true, required: true}" name="cedula" v-slot="{ valid, errors }">
                        <b-row>
                          <b-col sm="12" lg="6">
                            <b-row class="pt-3">
                              <b-col sm="2" lg="4" class="pr-0">
                                <label for="client-id">
                                  <span>Cédula:</span><span class="asterisk">*</span>
                                </label>
                              </b-col>
                              <b-col sm="10" lg="7" class="px-0">
                                <b-input id="client-id" autofocus type="text" style="width: 96%;" v-model="clientForm.cedula" :disabled="!isDev" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: 3101177456"/>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <!-- SAP GLN -->
                            <ValidationProvider rules="required" name="Tipo de ID" v-slot="{ valid, errors }">
                              <b-form label-size="sm" :invalid-feedback="errors[0]">
                                <b-row class="pt-3"> <!--class="pt-sm-3"-->
                                  <b-col sm="2" lg="4" class="px-0">
                                    <span class="pl-3">Tipo ID:</span><span class="asterisk">*</span>
                                  </b-col>
                                  <b-col sm="10" lg="8" class="pl-0">
                                    <b-select
                                      id="client-gln"
                                      v-model="clientForm.sap_gln"
                                      :options="sapGlnOptions"
                                      size="sm"
                                      autofocus
                                      :disabled="!isDev"
                                      style="width: 100%;"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                        <option :value="null" disabled>-- Eligir uno --</option>
                                      </template>
                                    </b-select>
                                  </b-col>
                                </b-row>
                              </b-form>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </ValidationProvider>
                      <!-- Industry  -->
                      <ValidationProvider  rules="required" name="Sector" v-slot="{ valid, errors }">
                        <b-row class="pt-3">
                          <b-col cols="12">
                            <b-form label-size="sm" :invalid-feedback="errors[0]">
                              <b-row>
                                <b-col sm="2" lg="2" class="pr-0">
                                  <span>Sector:</span><span class="asterisk">*</span>
                                </b-col>
                                <b-col sm="10" lg="10" class="pl-0">
                                  <b-select
                                    id="client-industry"
                                    v-model="clientForm.industry"
                                    :options="industryOptions"
                                    size="sm"
                                    autofocus
                                    style="width: 100%;"
                                    :state="valid ? true : false"
                                    @change="clientForm.subsector = null"
                                  >
                                    <template v-slot:first>
                                      <option :value="null" disabled>-- Eligir uno --</option>
                                    </template>
                                  </b-select>
                                </b-col>
                              </b-row>
                              <label for="client-industry">
                              </label>
                            </b-form>
                          </b-col>
                        </b-row>
                      </ValidationProvider>
                      <!-- Subsector -->
                      <ValidationProvider name="subsector">
                        <b-row>
                          <b-col cols="12">
                            <b-form v-if="[1,2,3,4,5,6,10,14].includes(clientForm.industry)" label-size="sm">
                              <b-row>
                                <b-col sm="3" lg="3" class="pr-0">
                                  <span>Subsector:</span>
                                </b-col>
                                <b-col sm="9" lg="9" class="pl-0">
                                  <b-select
                                    v-model="clientForm.subsector"
                                    size="sm"
                                    style="width: 100%;"
                                    :state="clientForm.subsector ? true : null"
                                    :options="subsectorMapping[clientForm.industry]"
                                  >
                                    <template v-slot:first>
                                      <option :value="null" disabled>-- Eligir uno --</option>
                                    </template>
                                  </b-select>
                                </b-col>
                              </b-row>
                              <label for="client-industry">
                              </label>
                            </b-form>
                          </b-col>
                        </b-row>
                      </ValidationProvider>
                      <!-- Phone 1 & 2-->
                      <div v-if="clientForm.sap_gln == '00'">
                        <!-- External Client phone1 -->
                        <ValidationProvider :rules="{numeric: true}" name="Teléfono" v-slot="{ valid, errors }">
                          <b-form label-size="sm" :invalid-feedback="errors[0]">
                            <b-row :class="{'ml-0': !isMobile}">
                              <b-col sm="12" lg="6">
                                <b-row>
                                  <b-form-group
                                    label-cols-sm="4"
                                    label-cols-lg="4"
                                    content-cols-sm
                                    content-cols-lg="8"
                                    label="Teléfono 1:"
                                    label-for="client-phone-1-1"
                                  >
                                    <b-form-input v-model="clientForm.phone" id="client-phone-1-1" size="sm" :state="valid ? true : false" placeholder="Ej: 22270909"/>
                                  </b-form-group>
                                </b-row>
                              </b-col>
                              <b-col sm="12" lg="6">
                                <ValidationProvider :rules="{numeric: true}" name="ext-1" v-slot="{ valid }">
                                  <b-input-group size="sm" prepend="ext -">
                                    <b-form-input type="number" v-model="clientForm.phone_extension" :state="valid ? true : false" id="client-ext-1" size="sm" placeholder="500"/>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form>
                        </ValidationProvider>
                      </div>
                      <div v-else>
                        <!-- Local Client phone1 -->
                        <ValidationProvider :rules="{digits: 8}" name="Teléfono" v-slot="{ valid, errors }">
                          <b-form label-size="sm" :invalid-feedback="errors[0]">
                            <b-row :class="{'ml-0': !isMobile}">
                              <b-col sm="12" lg="6">
                                <b-row>
                                  <b-form-group
                                    label-cols-sm="4"
                                    label-cols-lg="4"
                                    content-cols-sm
                                    content-cols-lg="8"
                                    label="Teléfono 1:"
                                    label-for="client-phone-1"
                                  >
                                    <b-form-input v-model="clientForm.phone" id="client-phone-1" size="sm" :state="valid ? true : false" placeholder="Ej: 22270909"/>
                                  </b-form-group>
                                </b-row>
                              </b-col>
                              <b-col sm="12" lg="6">
                                <ValidationProvider :rules="{numeric: true}" name="ext-2" v-slot="{ valid }">
                                  <b-input-group size="sm" prepend="ext -">
                                    <b-form-input type="number" v-model="clientForm.phone_extension" :state="valid ? true : false" id="client-ext-2" size="sm" placeholder="500"/>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form>
                        </ValidationProvider>
                      </div>
                      <!-- Phone 2 external-->
                      <ValidationProvider v-if="clientForm.sap_gln == '00'" :rules="{numeric: true}" name="Teléfono2" v-slot="{ valid }">
                        <b-form-group label-size="sm">
                          <b-row class="pt-1" :class="{'ml-0': !isMobile}">
                            <b-col sm="3" lg="4" class="pl-0">
                              <span >Teléfono 2:</span>
                            </b-col>
                            <b-col sm="9" lg="8" class="pl-0">
                              <b-input v-model="clientForm.phone_2" size="sm" :state="valid ? true : false"/>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- Phone 2 local-->
                      <ValidationProvider v-else :rules="{digits: 8}" name="Teléfono2" v-slot="{ valid, errors }">
                        <b-form-group label-size="sm">
                          <b-row class="pt-1" :class="{'ml-0': !isMobile}">
                            <b-col sm="3" lg="4" class="px-0">
                              <span>Teléfono 2:</span>
                            </b-col>
                            <b-col sm="9" lg="8" class="pl-0">
                              <b-input v-model="clientForm.phone_2" size="sm" :state="valid || errors[0] ? true : false" placeholder="Ej: 26535050"/>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- Client cellular & fax -->
                      <ValidationProvider :rules="{digits: 8}" name="celular" v-slot="{ valid, errors }">
                        <b-form class="py-2">
                          <b-row>
                            <b-col sm="12" lg="6">
                              <b-row class="pt-">
                                <b-col sm="3" lg="5" class="pr-0">
                                  <span>Celular:</span>
                                </b-col>
                                <b-col sm="9" lg="7" class="pl-0">
                                  <b-input type="text" id="client-cellular" style="width: 100%;" v-model="clientForm.cellular" :state="valid || errors[0] ? true : false" size="sm" />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <ValidationProvider :rules="{digits: 8}" name="fax" v-slot="{ valid, errors }">
                                <b-row class="pt-2">
                                  <b-col sm="3" lg="3" class="pr-0">
                                    <span>Fax:</span>
                                  </b-col>
                                  <b-col sm="9" lg="9" class="pl-0">
                                    <b-input type="text" id="client-fax" style="width: 100%;" v-model="clientForm.fax"  :state="valid || errors[0] ? true : false" size="sm" />
                                  </b-col>
                                </b-row>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationProvider>
                    </b-col>
                    <!-- column 2 -->
                    <b-col lg="6" sm="12" style=" border-left: 2px solid #e0e2e4">
                      <!-- Client Rank -->
                      <ValidationProvider>
                        <b-row>
                          <b-col sm="12" lg="4">
                            <span class="pr-3">Categoría cliente:</span>
                          </b-col>
                          <b-col sm="12" lg="8">
                            <b-form class="pb-3">
                              <b-select
                                id="client-rank"
                                v-model="clientForm.rank"
                                :options="clientRankOptions"
                                size="sm"
                                style="width: 100%;"
                              >
                                <template v-slot:first>
                                  <option :value="null" disabled>-- Eligir uno --</option>
                                </template>
                              </b-select>
                            </b-form>
                          </b-col>
                        </b-row>
                      </ValidationProvider>
                      <!-- Client group -->
                      <b-row>
                        <b-col sm="12" lg="4">
                          <span class="pr-3">Condiciones de Pago:</span>
                        </b-col>
                        <b-col sm="12" lg="8">
                          <b-form class="pb-3">
                            <b-input  style="width: 70%;" :value="clientForm.payment_terms" :disabled="true"/>
                          </b-form>
                        </b-col>
                      </b-row>
                      <!-- Email-->
                      <ValidationProvider rules="email" name="Correo" v-slot="{ valid, errors }">
                        <b-row>
                          <b-col sm="12" lg="4">
                            <span class="pr-0">Correo facturación:</span>
                          </b-col>
                          <b-col sm="12" lg="8">
                            <b-form class="pb-3" label-size="sm" :invalid-feedback="errors[0]">
                              <b-input id="client-email" type="email" v-model="clientForm.email" size="sm"  style="width: 70%;" :state="valid ? true : false" placeholder="Ej: facturacion@accesos.cr"/>
                            </b-form>
                          </b-col>
                        </b-row>
                      </ValidationProvider>
                      <ValidationProvider v-for="(line, index) in additionalClientEmails" :key="index" rules="email" :name="'Correo' + (index + 2).toString()" v-slot="{ valid, errors }">
                        <b-row>
                          <b-col sm="0" lg="4">
                          </b-col>
                          <b-col sm="9" lg="6">
                            <b-form-group  label-size="sm">
                              <b-input v-model="line.email" size="sm"  autofocus  style="width: 96%;" :state="errors[0] ? false : (line.email && valid ? true : null)" />
                            </b-form-group>
                          </b-col>
                          <b-col sm="3" lg="2">
                            <div class="clickable" @click="removeLine(index, 'client')">
                              <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                            </div>
                          </b-col>
                        </b-row>
                      </ValidationProvider>
                      <b-row align-h="center">
                        <b-col cols="3"></b-col>
                        <b-col class="pl-2">
                          <Button v-show="clientForm.email && !invalid && AdditionalEmailsAllowed" size="sm" @click="addLine('client')">
                            <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                            <span class="pl-1">Email adicional</span>
                          </Button>
                        </b-col>
                      </b-row>
                      <!-- Client website url -->
                      <ValidationProvider :rules="{max: 254}" name="website_url" v-slot="{ valid, errors }">
                        <b-form-group>
                          <template #label>
                            <span class="fs-18">Portal Web</span>
                          </template>
                          <b-input
                            id="website_url"
                            type="url"
                            v-model="clientForm.website_url"
                            :state="errors[0] || !valid ? false : true"
                            name="portal web"
                            trim
                            size="sm"
                            autofocus
                            placeholder="https://accesosautomaticos.com"
                            style="width: 100%;"
                          />
                        </b-form-group>
                      </ValidationProvider>
                      <!-- Client notes -->
                      <ValidationProvider :rules="{max: 100}" name="Notas" v-slot="{ valid, errors }">
                        <b-form-group label-size="sm" >
                          <template #label>
                            <span class="fs-18">Notas</span>
                          </template>
                          <b-textarea
                            v-model="clientForm.notes"
                            :state="errors[0] || !valid ? false : true"
                            rows="4"
                            size="sm"
                            trim
                            placeholder="Ej: Sabana sur 50 este del colegio, 30 mtrs oeste"
                          />
                          <small class="text-danger" v-if="!valid">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row v-if="!isValid && invalid" align-h="end" class="pr-2">
                    <span class="text-warning"><font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width /> Debe verificar los datos del formulario</span>
                  </b-row>
                </ValidationObserver>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- Contacts  -->
          <b-row id="contacts_form" class="pb-3">
            <b-col cols="12">
              <div
                :class="showContactData ? null : 'collapsed'"
                :aria-expanded="showContactData ? 'true' : 'false'"
                aria-controls="collapse-2"
                @click="showContactData = !showContactData"
              >
                <span class="text-muted fs-20 p-0">Contactos</span>
                <font-awesome-icon v-if="showContactData" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="sm" fixed-width />
              </div>
              <b-collapse id="collapse-2"  class="py-2" v-model="showContactData">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <b-row align-h="between" class="align-items-center">
                        <b class="text-primary ml-3">Contactos:</b>
                        <Button variant="orange" size="sm" class="mb-2 mr-2" @click="createContact(true)">
                          <font-awesome-icon :icon="['fas', 'plus-circle']" size="sm" fixed-width />
                          <span class="pl-1">Nuevo</span>
                        </Button>
                      </b-row>
                      <b-table
                        hover
                        :fields="contactFields"
                        :items="contacts"
                        fixed
                        no-border-collapse
                        @row-clicked="onRowContactClick"
                      >
                        <template #cell(name)="data">
                          <span v-if="data.item.first_name">
                            {{data.item.first_name}} {{ data.item.second_name }} {{ data.item.last_name }}
                          </span>
                          <span v-else>
                            {{ data.value }}
                          </span>
                          <sup v-if="data.value.replace(/\s{2,}/g, ' ') == clientForm.contact_person "><b-badge variant="primary">Principal</b-badge></sup>
                        </template>
                        <template #cell(phone)="data">
                          <span v-if="data.value">
                            {{data.value}}
                            <span v-if="data.item.phone_extension"> ext {{ data.item.phone_extension }}</span>
                          </span>
                          <span v-else>
                            <span class="text-secondary">pendiente</span>
                            <font-awesome-icon :icon="['far', 'info-circle']" size="sm" class="text-danger"/>
                          </span>
                        </template>
                        <template #cell(profession)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else> -- </span>
                        </template>
                        <template #cell(phone_2)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else> -- </span>
                        </template>
                        <template #cell(cellular)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else> -- </span>
                        </template>
                        <template #cell(email)="data">
                          <div  v-if="data.value">
                            <div v-for="(email, index) in data.value.split(';')" :key="index">
                              <span>{{email}}</span>
                            </div>
                          </div>
                          <span v-else>
                            <span class="text-secondary">pendiente</span>
                            <font-awesome-icon :icon="['far', 'info-circle']" size="sm" class="text-danger"/>
                          </span>
                        </template>
                        <template #cell(is_active)="data">
                          <span v-if="data.value"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></span>
                          <span v-else><font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /> </span>
                        </template>
                        <template #cell(actions)>
                          <span class="text-warning"><font-awesome-icon :icon="['far', 'edit']" fixed-width /></span>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- Addresses -->
          <b-row id="addresses_form" class="pb-3">
            <b-col cols="12">
              <div
                :class="showAddressData ? null : 'collapsed'"
                :aria-expanded="showAddressData ? 'true' : 'false'"
                aria-controls="collapse-3"
                @click="showAddressData = !showAddressData"
              >
                <span class="text-muted fs-20 p-0">Direcciones</span>
                <font-awesome-icon v-if="showAddressData" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="sm" fixed-width />
              </div>
              <b-collapse id="collapse-3"  class="py-2" v-model="showAddressData">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <b-row align-h="between" class="align-items-center">
                        <b class="text-primary ml-3">Direcciones:</b>
                        <Button variant="orange" size="sm" class="mb-2 mr-2" @click="openNewAddressModal">
                          <font-awesome-icon :icon="['fas', 'plus-circle']" size="sm" fixed-width />
                          <span class="pl-1">Nuevo</span>
                        </Button>
                      </b-row>
                      <b-table
                        v-if="addresses.length > 0"
                        hover :fields="addressFields"
                        :items="addresses"
                        @row-clicked="onRowAddressClick"
                      >
                        <template #cell(address)="data">
                          <span>{{data.value}} <sup v-if="data.value.replace(/\s{2,}/g, ' ') == clientForm.default_address "><b-badge variant="primary">Principal</b-badge></sup></span>
                        </template>
                        <template #cell(street)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else>
                            <span class="text-secondary">pendiente</span>
                            <font-awesome-icon :icon="['far', 'info-circle']" size="sm" class="text-danger"/>
                          </span>
                        </template>
                        <template #cell(province)="data">
                          <span v-if="data.value">{{data.value[1]}}</span>
                          <span v-else>
                            <span class="text-secondary">pendiente</span>
                            <font-awesome-icon :icon="['far', 'info-circle']" size="sm" class="text-danger"/>
                          </span>
                        </template>
                        <template #cell(district)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else>
                            <span class="text-secondary">pendiente</span>
                            <font-awesome-icon :icon="['far', 'info-circle']" size="sm" class="text-danger"/>
                          </span>
                        </template>
                        <template #cell(canton)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else>
                            <span class="text-secondary">pendiente</span>
                            <font-awesome-icon :icon="['far', 'info-circle']" size="sm" class="text-danger"/>
                          </span>
                        </template>
                        <template #cell(actions)>
                          <span class="text-warning"><font-awesome-icon :icon="['far', 'edit']" fixed-width /></span>
                        </template>
                      </b-table>
                      <b-container v-else fluid class="fs-13">
                        <b-row align-h="center" class="py-4">
                          <span class="text-danger">Cliente sin direcciones registradas</span>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- requirements -->
          <b-row id="requirements_form"  class="pb-3">
            <b-col cols="12">
              <div
                :class="showRequirementsData ? null : 'collapsed'"
                :aria-expanded="showRequirementsData ? 'true' : 'false'"
                aria-controls="collapse-4"
                @click="showRequirementsData = !showRequirementsData"
              >
                <span class="text-muted fs-20 p-0">Requerimientos</span>
                <font-awesome-icon v-if="showRequirementsData" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="sm" fixed-width />
              </div>
              <b-collapse id="collapse-4"  class="py-2" v-model="showRequirementsData">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <b-row align-h="between" class="align-items-center">
                        <b class="text-primary ml-3">Requerimientos:</b>
                        <Button variant="orange" size="sm" class="mb-2 mr-2" @click="createRequirement(true)">
                          <font-awesome-icon :icon="['fas', 'plus-circle']" size="sm" fixed-width />
                          <span class="pl-1">Nuevo</span>
                        </Button>
                      </b-row>
                      <b-table
                        v-if="requirements.length > 0 "
                        hover
                        :fields="requirementFields"
                        :items="requirements"
                        @row-clicked="onRowRequirementClick"
                      >
                        <template #cell(category)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else> -- </span>
                        </template>
                        <template #cell(name)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else> -- </span>
                        </template>
                        <template #cell(description)="data">
                          <span v-if="data.value">{{data.value}}</span>
                          <span v-else> -- </span>
                        </template>
                        <template #cell(actions)>
                          <span class="text-warning"><font-awesome-icon :icon="['far', 'edit']" fixed-width /></span>
                        </template>
                      </b-table>
                      <b-container v-else fluid class="fs-13">
                        <b-row align-h="center" class="py-4">
                          <span class="text-danger">Cliente sin requerimientos registrados</span>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- Billing Notes -->
          <b-row id="billing_notes_form" class="pb-3">
            <b-col cols="12">
              <div
                :class="showBillingData ? null : 'collapsed'"
                :aria-expanded="showBillingData ? 'true' : 'false'"
                aria-controls="collapse-4"
                @click="showBillingData = !showBillingData"
              >
                <span class="text-muted fs-20 p-0">Notas facturación</span>
                <font-awesome-icon v-if="showBillingData" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="sm" fixed-width />
              </div>
              <b-collapse id="collapse-4"  class="py-2" v-model="showBillingData">
                <b-card>
                <ValidationObserver  v-if="hasBillingNote" ref="observer4" v-slot="{ invalid }">
                  <b-row>
                    <!-- column 1 -->
                    <b-col lg="6" sm="12">
                      <!-- OC Required -->
                      <ValidationProvider rules="required" name="Requiere OC" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8" :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Requiere OC?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="purchase_order_required-1"
                            v-model="billingForm.purchase_order_required"
                            name="purchase_order_required-1"
                            class="pt-2"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="purchase_order_required-2"
                            v-model="billingForm.purchase_order_required"
                            name="purchase_order_required-2"
                            value=false
                            class="pt-2"
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- Credit Required -->
                      <ValidationProvider rules="required" name="Requiere crédito" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Requiere crédito?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="credit_required-1"
                            v-model="billingForm.credit_required"
                            name="credit_required-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="checkbox-2"
                            v-model="billingForm.credit_required"
                            name="checkbox-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- Exonerated -->
                      <ValidationProvider rules="required" name="Es exento" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Es exento?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="exonerated-1"
                            v-model="billingForm.exonerated"
                            name="exonerated-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="exonerated-2"
                            v-model="billingForm.exonerated"
                            name="exonerated-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                          <!-- <b-form-checkbox
                            id="exonerated-2"
                            v-model="billingForm.exonerated"
                            name="exonerated-2"
                            value=false
                            unchecked-value= null
                            inline
                            @change="resetExonerationForm()"
                          > -->
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- has_billing_mail -->
                      <ValidationProvider rules="required" name="Correo de facturación" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]">
                          <template #label>
                            <span v-b-tooltip.hover.v-dark.topright='"FE: Facturación Electrónica"'>¿Email FE validado?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="has_billing_mail-1"
                            v-model="billingForm.has_billing_mail"
                            name="has_billing_mail-1"
                            value=true
                            unchecked-value= null
                            inline
                            v-b-tooltip.hover.v-dark.topright='"FE: Facturación Electrónica"'
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="has_billing_mail-2"
                            v-model="billingForm.has_billing_mail"
                            name="has_billing_mail-2"
                            value=false
                            unchecked-value= null
                            inline
                            v-b-tooltip.hover.v-dark.topright='"FE: Facturación Electrónica"'
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- local_currency -->
                      <ValidationProvider rules="required" name="Colones" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Facturación en colones?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="local_currency-1"
                            v-model="billingForm.local_currency"
                            name="local_currency-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="local_currency-2"
                            v-model="billingForm.local_currency"
                            name="local_currency-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- physical_procedure -->
                      <ValidationProvider rules="required" name="Trámite físico" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Trámite físico?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="physical_procedure-1"
                            v-model="billingForm.physical_procedure"
                            name="physical_procedure-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="physical_procedure-2"
                            v-model="billingForm.physical_procedure"
                            name="physical_procedure-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- column 2 -->
                    <b-col lg="6" sm="12" style=" border-left: 2px solid #e0e2e4">
                      <!-- document_portal -->
                      <ValidationProvider rules="required" name="Portal de documentos" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Portal de documentos?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="document_portal-1"
                            v-model="billingForm.document_portal"
                            name="document_portal-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="document_portal-2"
                            v-model="billingForm.document_portal"
                            name="document_portal-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- advance_payment -->
                      <ValidationProvider rules="required" name="Anticipos" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Anticipos?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="advance_payment-1"
                            v-model="billingForm.advance_payment"
                            name="advance_payment-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="advance_payment-2"
                            v-model="billingForm.advance_payment"
                            name="advance_payment-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- mailing_attachments -->
                      <ValidationProvider rules="required" name="Envío de anexos" v-slot="{ errors }">
                        <b-form-group label-size="sm" label-cols-lg="4" content-cols-lg="8"  :invalid-feedback="errors[0]" >
                          <template #label>
                            <span>¿Envío de anexos?</span><span class="asterisk">*</span>
                          </template>
                          <b-form-checkbox
                            id="mailing_attachments-1"
                            v-model="billingForm.mailing_attachments"
                            name="mailing_attachments-1"
                            value=true
                            unchecked-value= null
                            inline
                          >
                            Si
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="mailing_attachments-2"
                            v-model="billingForm.mailing_attachments"
                            name="mailing_attachments-2"
                            value=false
                            unchecked-value= null
                            inline
                          >
                            No
                          </b-form-checkbox>
                        </b-form-group>
                      </ValidationProvider>
                      <!-- Billing Notes -->
                      <ValidationProvider name="Notas">
                        <b-form-group label="Comentarios:" label-size="sm">
                          <b-textarea v-model="billingForm.notes" size="sm" rows="4" placeholder="Comentarios adicionales" />
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                  </b-row>
                  <!-- <b-row v-if="billingForm.exonerated == 'true'">
                    <b-col>
                      <div class="mb-1">
                        <span class="text-muted fs-16"><u>Datos exoneración:</u></span>
                      </div>
                      <b-card>
                        <ValidationObserver  ref="observer6" v-slot="{ invalid }">
                          <b-row>
                            <b-col lg="6" sm="12">
                              <ValidationProvider :rules="{required: true}" name="tipo documento" v-slot="{ valid, errors }">
                                <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                                  <template #label>
                                    <span>Tipo de documento</span><span class="asterisk">*</span>
                                  </template>
                                  <b-input type="text" v-model="clientForm.exo_doc_type" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                                </b-form-group>
                              </ValidationProvider>
                              <ValidationProvider :rules="{required: true, max: 19}" name="Número documento" v-slot="{ valid, errors }">
                                <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                                  <template #label>
                                    <span>Número de documento</span><span class="asterisk">*</span>
                                  </template>
                                  <b-input type="text" v-model="clientForm.exo_doc_num" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                                </b-form-group>
                              </ValidationProvider>
                              <ValidationProvider :rules="{required: true, max: 99}" name="Nombre institución" v-slot="{ valid, errors }">
                                <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                                  <template #label>
                                    <span>Nombre institución</span><span class="asterisk">*</span>
                                  </template>
                                  <b-input type="text" v-model="clientForm.exo_business_name" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" style=" border-left: 2px solid #e0e2e4">
                              <ValidationProvider rules="required" name="Fecha emisión" v-slot="{ valid, errors }">
                                <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                                  <template #label>
                                    <span>Fecha emisión</span><span class="asterisk">*</span>
                                  </template>
                                  <b-input type="date" v-model="clientForm.exo_started_at" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                                </b-form-group>
                              </ValidationProvider>
                              <ValidationProvider rules="required" name="Monto impuesto" v-slot="{ valid, errors }">
                                <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                                  <template #label>
                                    <span>Monto impuesto</span><span class="asterisk">*</span>
                                  </template>
                                  <b-input type="number" v-model="clientForm.exo_total_tax" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                                </b-form-group>
                              </ValidationProvider>
                              <ValidationProvider rules="required" name="Porcentaje compra" v-slot="{ valid, errors }">
                                <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                                  <template #label>
                                    <span>Porcentaje compra</span><span class="asterisk">*</span>
                                  </template>
                                  <b-input-group size="sm" prepend="%">
                                    <b-form-input type="number" v-model="clientForm.exo_purchase_percent" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                                  </b-input-group>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <span class="d-none">{{ invalid }}</span>
                          </b-row>
                        </ValidationObserver>
                      </b-card>
                    </b-col>
                  </b-row> -->
                  <b-row align-h="center" class="mt-3">
                    <Button v-if="isNewBillingForm" variant="red" class="float-right" @click="hasBillingNote = false">
                      Cancelar
                    </Button>
                  </b-row>
                  <b-row v-if="!isValid && !validBillingForm || invalid" align-h="end" class="pr-2">
                    <span class="text-warning"><font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width /> Debe verificar los datos del formulario</span>
                  </b-row>
                </ValidationObserver>
                <b-container v-else fluid class="fs-13">
                  <b-row align-h="center">
                    <!-- <div class="text-danger mb-3">Cliente sin notas de facturación</div> -->
                    <Button variant="orange" size="sm" @click="hasBillingNote = true" >
                      <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
                      <span class="pl-1">Crear</span>
                    </Button>
                  </b-row>
                </b-container>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ContentView>

    <!-- Contact Modal -->
    <b-modal
      v-model="contactModalShow"
      centered
      hide-footer
      @hide="onHideContactModal"
    >
      <template #modal-title>
        <!-- TODO: Add iconv5 -->
        <span v-if="isNewContact">Nuevo contacto</span>
        <span v-else>Actualizar contacto</span>
      </template>
      <b-row align-h="center">
        <ValidationObserver  ref="observer2" v-slot="{ invalid, }">
          <b-row align-h="between">
            <b-col cols="5">
              <!-- valid -->
              <ValidationProvider rules="required" name="Estado" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]"
                >
                  <label for="client-status">
                    <span>Estado:</span><span class="asterisk pr-3 ">*</span>
                  </label>
                  <toggle-button
                    id="client-status"
                    v-model="contactForm.is_active"
                    :labels="{ checked: 'Activo', unchecked: 'Inactivo'}"
                    :width="75"
                    :disabled="isNewContact"
                    :state="errors[0] ? false : (valid ? true : null)"
                    color="#6eb165"
                    class="mb-0"
                  />
                </b-form>
              </ValidationProvider>
            </b-col>
            <b-col cols="7" class="pl-5">
              <!-- Primary Contact -->
              <ValidationProvider v-if="selectedItem.name != clientForm.contact_person " name="Principal" v-slot="{ errors }">
                <b-form
                  inline
                  class="pb-3 pl-2"
                  :invalid-feedback="errors[0]"
                >
                  <label for="main">
                    <span class="pr-3">Marcar como principal</span>
                  </label>
                  <b-form-radio id="main" v-model="selectedPrincipal" :value=true></b-form-radio>
                </b-form>
              </ValidationProvider>
              <div v-else class="float-right fs-20">
                <sup><b-badge variant="primary">Principal</b-badge></sup>
              </div>
            </b-col>
          </b-row>

          <!-- ID SAP -->
          <b-form-group v-if="!isNewContact" label-size="sm">
            <template #label>
              <span>ID Contacto</span>
            </template>
            <b-input :value="contactForm.name" disabled size="sm"  style="min-width: 28rem;" placeholder="ID contacto"/>
          </b-form-group>
          <!-- FistName -->
          <ValidationProvider :rules="{required: true, max: 49}" name="Nombre" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Nombre</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="contactForm.first_name" autofocus size="sm" :state="errors[0] ? false : (valid ? true : null)" style="min-width: 28rem;" placeholder="Nombre contacto"/>
            </b-form-group>
          </ValidationProvider>
          <!-- LastName -->
          <ValidationProvider :rules="{required: true, max: 49}" name="Apellido" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Apellido</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="contactForm.last_name" autofocus  size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Apellido contacto" />
            </b-form-group>
          </ValidationProvider>
          <!-- profession -->
          <ValidationProvider :rules="{required: false, max: 49}" name="Puesto" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Puesto</span>
              </template>
              <b-input v-model="contactForm.profession"  size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Puesto" />
            </b-form-group>
          </ValidationProvider>
          <!-- Phone -->
          <ValidationProvider :rules="{required: true, digits: 8}" name="Teléfono" v-slot="{ valid, errors }">
            <b-row>
              <b-col class="fs-14">
                <span>Teléfono 1</span><span class="asterisk">*</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group label-size="sm">
                  <b-input v-model="contactForm.phone" autofocus size="sm" :state="errors[0] ? false : (contactForm.phone && valid ? true : null)" placeholder="Ej: 22270909"/>
                </b-form-group>
              </b-col>
              <b-col sm="12" lg="6">
                <ValidationProvider :rules="{numeric: true}" name="ext-phone-1" v-slot="{ valid }">
                  <b-input-group size="sm" prepend="ext -">
                    <b-form-input type="number"  v-model="contactForm.phone_extension" :state="valid ? true : false" id="contact-phone-2" size="sm" placeholder="500"/>
                  </b-input-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row v-if="errors[0]">
              <b-col>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-col>
            </b-row>
          </ValidationProvider>
          <!-- Phone 2 -->
          <ValidationProvider :rules="{digits: 8}" name="Teléfono 2" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Teléfono 2</span>
              </template>
              <b-input v-model="contactForm.phone_2" size="sm" :state="errors[0] ? false : (contactForm.phone_2 && valid ? true : null)" placeholder="Ej: 22270909"/>
            </b-form-group>
          </ValidationProvider>
          <!-- Cellular -->
          <ValidationProvider rules="digits:8" name="Celular" v-slot="{ valid, errors }">
            <b-form-group label="Celular" label-size="sm" :invalid-feedback="errors[0]">
              <b-input v-model="contactForm.cellular" size="sm" :state="errors[0] ? false : (contactForm.cellular && valid ? true : null)" placeholder="Ej: 88008800"/>
            </b-form-group>
          </ValidationProvider>
          <!-- Emails -->
          <ValidationProvider rules="required|email" name="Correo" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Correo electrónico</span><span class="asterisk">*</span>
              </template>
              <b-input type="email" autofocus v-model="contactForm.email" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: contacto@dominio.cr"/>
            </b-form-group>
          </ValidationProvider>
          <!-- Additional Emails -->
          <ValidationProvider v-for="(line, index) in additionalContactEmails" :key="index" rules="email" :name="'Correo' + (index + 2).toString()" v-slot="{ valid, errors }">
            <b-form-group label-size="sm">
              <template #label>
                <b-row align-h="between" class="px-3">
                  <span>Correo electrónico ({{index + 2}})</span>
                  <div class="clickable" @click="removeLine(index, 'contact')">
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </div>
                </b-row>
              </template>
              <b-input v-model="line.email" size="sm" autofocus :state="errors[0] ? false : (line.email && valid ? true : null)" />
            </b-form-group>
          </ValidationProvider>

          <Button v-show="contactForm.email && !invalid && AdditionalContactEmailsAllowed" size="sm" @click="addLine('contact')">
            <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
            <span class="pl-1">Email adicional</span>
          </Button>

          <b-row v-if="duplicateContact" align-h="center" class="mt-1">
            <small class="text-danger">El nombre del cliente ya existe</small>
          </b-row>

          <b-row align-h="center" class="mt-3">
            <Button v-if="isNewContact" variant="green" class="float-right" :disabled="invalid" @click="createContact(false)" >
              Crear
            </Button>
            <Button v-else variant="green" class="float-right" :disabled="invalid" @click="updateContact" >
              Actualizar
            </Button>
          </b-row>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <!-- Addresses Modal -->
    <b-modal
      v-model="addressModalShow"
      centered
      hide-footer
      @hide="onHideAddressModal"
    >
      <template #modal-title>
        <!-- TODO: Add iconv5 -->
        <span v-if="isNewAddress">Nueva dirección</span>
        <span v-else>Actualizar dirección</span>
      </template>
      <b-row align-h="center">
        <ValidationObserver  ref="observer3" v-slot="{ invalid }">
          <!-- Main address -->
          <b-row align-h="between">
            <b-col cols="5">
            </b-col>
            <b-col cols="7" class="pl-5">
              <!-- Primary Contact -->
              <ValidationProvider v-if="!isNewAddress || (clientForm.default_address && (selectedItem.address != clientForm.default_address))" name="Principal2" v-slot="{ errors }">
                <b-form
                  inline
                  class="pb-3 pl-2"
                  :invalid-feedback="errors[0]"
                >
                  <label for="main">
                    <span class="pr-3">Marcar como principal</span>
                  </label>
                  <b-form-radio id="main" v-model="selectedPrincipal" :value=true></b-form-radio>
                </b-form>
              </ValidationProvider>
              <div v-else class="float-right fs-20">
                <sup><b-badge variant="primary">Principal</b-badge></sup>
              </div>
            </b-col>
          </b-row>
          <!-- Address Name -->
          <ValidationProvider :rules="{required: true, max: 49}" name="Nombre" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Nombre de dirección</span><span class="asterisk">*</span>
              </template>
              <b-input type="text" autofocus v-model="addressForm.address" size="sm" placeholder="Ej: Oficina" style="min-width: 28rem;" :state="errors[0] ? false : (valid ? true : null)" />
            </b-form-group>
          </ValidationProvider>
          <!-- Street/ Address -->
          <ValidationProvider :rules="{required: true, max: 99}" name="Dirección" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Dirección</span><span class="asterisk">*</span>
              </template>
              <b-textarea
                placeholder="Ej: Sabana sur 50 este del colegio, 30 mtrs oeste"
                v-model="addressForm.street"
                size="sm"
                rows="3"
                autofocus
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>
          <!-- Province -->
          <ValidationProvider rules="required" name="Provincia"  v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Provincia</span><span class="asterisk">*</span>
              </template>
              <b-select
                v-model="addressForm.province"
                :options="provinceOptions"
                size="sm"
                :state="errors[0] ? false : (valid ? true : null)"
                @change="searchCantons"
              >
                <template #first>
                  <b-select-option :value="null">Eligir Provincia</b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </ValidationProvider>
          <!-- Canton -->
          <ValidationProvider v-if="cantonAddressOptions.length > 0" rules="required" name="Canton"  v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Canton</span><span class="asterisk">*</span>
                <p v-if="!isNewAddress" class="text-muted">Anterior:  {{selectedItem.canton}}</p>
              </template>
              <b-select
                v-model="addressForm.canton"
                :options="cantonAddressOptions"
                size="sm"
                :disabled="toggleCanton || !addressForm.province"
                :state="errors[0] ? false : (valid ? true : null)"
                @change="searchDistricts"
              >
                <template #first>
                  <b-select-option :value="null">Eligir Canton</b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </ValidationProvider>
          <!-- District -->
          <ValidationProvider v-if="districtAddressOptions.length > 0" rules="required" name="Distrito"  v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Distrito</span><span class="asterisk">*</span>
                <p v-if="!isNewAddress" class="text-muted">Anterior:  {{selectedItem.district}}</p>
              </template>
              <b-select
                v-model="addressForm.district"
                :options="districtAddressOptions"
                size="sm"
                :disabled="toggleDistrict || !addressForm.canton"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template #first>
                  <b-select-option :value="null">Eligir Distrito</b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </ValidationProvider>

          <b-row v-if="duplicateAddress" align-h="center" class="mt-1">
            <small class="text-danger">El nombre de la dirección ya existe</small>
          </b-row>

          <b-row align-h="center" class="mt-3">
            <Button v-if="isNewAddress" variant="green" class="float-right" :disabled="invalid" @click="createAddress" >
              Crear
            </Button>
            <Button v-else variant="green" class="float-right" :disabled="invalid" @click="updateAddress" >
              Actualizar
            </Button>
          </b-row>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <!-- Requirement Modal -->
    <b-modal
      v-model="requirementModalShow"
      centered
      hide-footer
      @hide="onHideRequirementModal"
      class="p-1 m-1"
    >
      <template #modal-title>
        <span v-if="isNewRequirement">Nuevo requerimiento</span>
        <span v-else>Actualizar requerimiento</span>
      </template>
      <b-row align-h="center">
        <ValidationObserver  ref="observer5" v-slot="{ invalid }">
          <!-- Category -->
          <ValidationProvider rules="required" name="Categoria"  v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Categoría</span><span class="asterisk">*</span>
              </template>
              <b-select
                v-model="requirementForm.category"
                :options="requirementOptions"
                size="sm"
                style="max-width: 30rem;"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template #first>
                  <b-select-option autofocus :value="null">Eligir categoría</b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </ValidationProvider>
          <!-- Name -->
          <ValidationProvider :rules="{required: true, max: 49}" name="Nombre" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Nombre</span><span class="asterisk">*</span>
              </template>
              <b-input type="text" autofocus v-model="requirementForm.name" size="sm" placeholder="Ej: requerimiento especial" style="min-width: 28rem;" :state="errors[0] ? false : (valid ? true : null)" />
            </b-form-group>
          </ValidationProvider>
          <!-- Description -->
          <ValidationProvider :rules="{required: false, max: 99}" name="Descripción" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Descripción</span>
              </template>
              <b-textarea
                placeholder="Ej: Se debe de cumplir este requerimiento para visitar el cliente"
                v-model="requirementForm.description"
                size="sm"
                rows="3"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-row align-h="center" class="mt-3">
            <Button v-if="isNewRequirement" variant="green" class="float-right" :disabled="invalid" @click="createRequirement(false)" >
              Crear
            </Button>
            <Button v-else variant="green" class="float-right" :disabled="invalid" @click="updateRequirement" >
              Actualizar
            </Button>
          </b-row>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <!-- Exit Modal -->
    <b-modal
      v-model="exitModalShow"
      centered
      hide-title
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'exclamation-circle']"  class=" text-warning modal-icon" />
      </b-row>
      <b-row align-h="center">
        <b class="fs-20">Hay cambios recientes, ¿Desea descartarlos?</b>
      </b-row>
      <b-row align-h="center" class="pt-2 text-secondary">
        <small>Tome en cuenta que los cambios no podrán revertirse</small>
      </b-row>
      <template #modal-footer>
        <b-row align-h="around">
          <b-col cols="6">
            <Button variant="red" class="float-right" @click="exitModalShow = false">
              No
            </Button>
          </b-col>
          <b-col cols="6">
            <Button variant="green" class="float-right" @click="$router.push(`/app/prod/clientes/${id}`)">
              Si
            </Button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import TitleBadge from '../shared/TitleBadge'
import ToastAlert from '../shared/ToastAlert'
import { provinces} from '../../sharedOptions/provinces'
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: 'ClientUpdate',
  components: { Button, ContentView, TitleBadge, ToastAlert, ValidationObserver, ValidationProvider },
  data () {
    return {
      id: Number(this.$route.params.id),
      isMobile: false,
      idCount: 0,
      selectedItem: [],
      client_num: null,
      additionalContactEmails: [],
      additionalClientEmails: [],
      addressData:  {},
      addressList: [],
      requirementData:  {},
      requirementList: [],
      contactData:  {},
      contactList: [],
      billingNote: {},
      clientForm: {},
      newContacts: [],
      newAddresses: [],
      newRequirements: [],
      selectedPrincipal: false,
      duplicateContact: false,
      duplicateAddress: false,
      addressForm: {
        id: null,
        address: null,
        street: null,
        province: null,
        canton: null,
        district: null
      },
      requirementForm: {
        id: null,
        category: null,
        name: null,
        description: null
      },
      contactForm: {
        id: null,
        first_name: null,
        last_name: null,
        phone: null,
        name: null,
        profession: null,
        phone_extension: null,
        phone_2: null,
        cellular: null,
        email: null,
        is_active: true
      },
      billingForm: {
        purchase_order_required: 'null',
        credit_required: 'null',
        exonerated: 'null',
        has_billing_mail: 'null',
        local_currency: 'null',
        physical_procedure: 'null',
        document_portal: 'null',
        advance_payment: 'null',
        mailing_attachments: 'null',
        notes: ''
      },
      sapGlnOptions: [
        { value: '01', text: 'Cédula Física' },
        { value: '02', text: 'Cédula Jurídica' },
        { value: '03', text: 'DIMEX' },
        { value: '04', text: 'NITE' },
        { value: '00', text: 'Extranjero' },
      ],
      clientRankOptions: [
        { value: 'Cliente A', text: 'Cliente A' },
        { value: 'Cliente AA', text: 'Cliente AA' },
        { value: 'Cliente AAA', text: 'Cliente AAA' },
        { value: 'Cliente VIP	', text: 'Cliente VIP	' },
        { value: 'Contado', text: 'Contado' },
        { value: 'Cuentas Claves', text: 'Cuentas Claves' }
      ],
      requirementOptions: [
        { value: 'Inducción de contratista', text: 'Inducción de contratista' },
        { value: 'Pólizas de seguros', text: 'Pólizas de seguros' },
        { value: 'Permiso de trabajo seguro', text: 'Permiso de trabajo seguro' },
        { value: 'Paso a paso seguro y/o matriz de riesgos', text: 'Paso a paso seguro y/o matriz de riesgos' },
        { value: 'Equipos de protección personal', text: 'Equipos de protección personal' },
        { value: 'Certificaciones trabajos de alto riesgo (indicadores de vida)', text: 'Certificaciones trabajos de alto riesgo (indicadores de vida)' },
        { value: 'Equipos y herramientas para trabajos de alto riesgo (indicadores de vida)', text: 'Equipos y herramientas para trabajos de alto riesgo (indicadores de vida)' },
        { value: 'Procedimientos de trabajo seguro', text: 'Procedimientos de trabajo seguro' },
        { value: 'Planes y programas', text: 'Planes y programas' },
        { value: 'Supervisión SySOA', text: 'Supervisión SySOA' },
        { value: 'Reuniones SySOA', text: 'Reuniones SySOA' },
        { value: 'Otros documentos', text: 'Otros documentos' }
      ],
      industryOptions: [
        { value: 1, text: 'A1 - Construcción' },
        { value: 2, text: 'A2 - Industrial' },
        { value: 3, text: 'A3 - Institución' },
        { value: 4, text: 'A4 - Comercial' },
        { value: 5, text: 'A5 - Condominios' },
        { value: 6, text: 'A6 - Casas' },
        { value: 14, text: 'A7 - Gobierno' },
        { value: 7, text: 'B1 - Inst. Premium' },
        { value: 8, text: 'B2 - Inst. Normal' },
        { value: 9, text: 'B3 - Tienda Especial' },
        { value: 10, text: 'B4 - Retailers' },
        { value: 11, text: 'B5 - Revendedor' },
        { value: 13, text: 'C1 - Administrado' },
        { value: 15, text: 'Público' }
      ],
      subsectorMapping: {
        1: [
          { value: 'A1-1', text: 'Constructoras-Electromecanicas' },
          { value: 'A1-2', text: 'Cemento-Concreto-Vidrio-Materiales' },
          { value: 'A1-3', text: 'Especificación-Diseño-Arquitectura' },
          { value: 'A1-4', text: 'Desarrollador Inmobiliario' },
          { value: 'A1-5', text: 'Constructora de Casas' },
          { value: 'A1-6', text: 'Contratista General' }
        ],
        2: [
          { value: 'A2-1', text: 'Metalmecánica-Plásticos-Carton-Textil'},
          { value: 'A2-2', text: 'Agroindustria'},
          { value: 'A2-3', text: 'Alimentaria y Bebidas' },
          { value: 'A2-4', text: 'Centros Logísticos y Aduanales' },
          { value: 'A2-5', text: 'Alta Tecnología (Médico-Biotec-Electrónica-Electromédico)' },
          { value: 'A2-6', text: 'Comunicación-Energía' },
          { value: 'A2-7', text: 'Química-Petroquímica-Cementos' },
          { value: 'A2-8', text: 'Zona Franca' },
          { value: 'A2-9', text: 'Condominio Industrial-ofibodegas' },
          { value: 'A2-10', text: 'Parque Industrial' },
        ],
        3: [
          { value: 'A3-1', text: 'Bancario-Financiero-Ahorro y Crédito' },
          { value: 'A3-2', text: 'Edificios Gubernamentales' },
          { value: 'A3-3', text: 'Educativo (Univer-Coleg-Esc)' },
          { value: 'A3-4', text: 'Hotelería-Turismo-Clubes-Entretenimiento' },
          { value: 'A3-5', text: 'Cámaras - Colegios Profesionales.' },
          { value: 'A3-6', text: 'Iglesias' },
          { value: 'A3-7', text: 'Otros Edificios Grandes' },
          { value: 'A3-8', text: 'Salud (Clínica-Hospital-Ebais)' }
        ],
        4: [
          { value: 'A4-1', text: 'Automotríz y Transportes' },
          { value: 'A4-2', text: 'Centros y Plazas Comerciales' },
          { value: 'A4-3', text: 'General (Tienda-Farmacia-Oficina-Opticas)' },
          { value: 'A4-4', text: 'Compañías Seguridad(Física-Electrónica)' },
          { value: 'A4-5', text: 'Megatiendas-Supermercados' },
          { value: 'A4-6', text: 'Parqueos públicos' },
          { value: 'A4-7', text: 'CallCenter y Oficinas Corporativas' },
          { value: 'A4-8', text: 'Centro de Deportivo' }
        ],
        5: [
          { value: 'A5-1', text: 'Condominio Horizontal' },
          { value: 'A5-2', text: 'Condominio Vertical' },
          { value: 'A5-3', text: 'Condominio Mixto' },
          { value: 'A5-4', text: 'z - sin asignar' }
        ],
        6: [
          { value: 'A6-1', text: 'Casas de Lujo' },
          { value: 'A6-2', text: 'Constructora de Residencias' },
          { value: 'A6-3', text: 'Fincas' },
          { value: 'A6-4', text: 'Casas' },
          { value: 'A6-5', text: 'Apartamentos' }
        ],
        10: [
          { value: 'B4-1', text: 'Cliente A' },
          { value: 'B4-2', text: 'Cliente B' },
          { value: 'B4-3', text: 'Cliente C' }
        ],
        14: [
          { value: 'A7-1', text: 'Ministerios'},
          { value: 'A7-2', text: 'Sector Pub Descentralizados'},
          { value: 'A7-3', text: 'Instituciones SemiAutonomas'},
          { value: 'A7-4', text: 'Empresas Publicas Estatales'},
          { value: 'A7-5', text: 'Empresas Publicas no Estatales'},
          { value: 'A7-6', text: 'Municipalidades'},
          { value: 'A7-7', text: 'Concejos Municipales'},
          { value: 'A7-8', text: 'Organos Adscritos a Instituciones Autonomas'}
        ]
      },
      contactFields: [
        { key: 'name', label: 'Nombre', thStyle: { width: '250px' } },
        { key: 'phone', label: 'Teléfono1' },
        { key: 'phone_2', label: 'Teléfono2' },
        { key: 'profession', label: 'Puesto' },
        { key: 'cellular', label: 'Celular' },
        { key: 'email', label: 'Correo' , thStyle: { width: '220px' }},
        { key: 'is_active', label: 'Activo', tdClass: 'align-middle'},
        { key: 'actions',  label: '', tdClass: 'align-middle clickable', thStyle: { width: '70px' } },
      ],
      addressFields: [
      { key: 'address', label: 'Nombre' },
      { key: 'street', label: 'Dirección' },
      { key: 'province', label: 'Provincia' },
      { key: 'canton', label: 'Canton' },
      { key: 'district', label: 'Distrito' },
      { key: 'actions',  label: '', tdClass: 'align-middle clickable', thStyle: { width: '30px' } },
      ],
      requirementFields: [
      { key: 'category', label: 'Categoría' },
      { key: 'name', label: 'Nombre' },
      { key: 'description', label: 'Descripción' },
      { key: 'actions',  label: '', tdClass: 'align-middle clickable', thStyle: { width: '30px' } },
      ],
      cantonAddressOptions: [],
      districtAddressOptions: [],
      addressModalShow: false,
      contactModalShow: false,
      requirementModalShow: false,
      exitModalShow: false,
      hasBillingNote: false,
      isNewContact: true,
      isNewAddress: true,
      showClientData: true,
      showContactData: true,
      showAddressData: true,
      showRequirementsData: true,
      showBillingData: true,
      provinceOptions: provinces,
      toggleCanton: false,
      toggleDistrict: false,
      processing: false,
      isValid: true,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      ready: false,
      bTabIndex: 0
    }
  },
  computed: {
    ...mapGetters(['isDev']),
    addresses () {
      let array = this.addressList.map(id => this.addressData[id])
      array.map( val  => {
        let provinceName = this.provinceOptions.find(el => el.value == val.province)
        if (provinceName) {
          val.province = [provinceName.value, provinceName.text]
        }
        if (val.address == this.clientForm.default_address){
          val.sap_line_num = 0
        } else {
          val.sap_line_num = 1
        }
      })
      return array.sort((a,b) => {
        if (a.sap_line_num !== b.sap_line_num) {
          return a.sap_line_num - b.sap_line_num
        }
        return a.address.localeCompare(b.address)
      })
    },
    requirements () {
      let array = this.requirementList.map(id => this.requirementData[id])
      return array.sort((a,b) => a.id - b.id)
    },
    contacts () {
      let array = this.contactList.map(id => this.contactData[id])
      const filtered = array.map( val  => {
        if (val.name == this.clientForm.contact_person) { val.position = 0 }
        else { val.position = 1 }
        return val
      })
      return filtered.sort((a,b) => {
        if (a.position !== b.position) {
          return a.position - b.position
        }
        return a.name.localeCompare(b.name)
      })
    },
    currentUser () { return this.$store.state.currentUser },
    validBillingForm () {
      let notRequiredFields = Object.assign({}, this.billingForm)
      notRequiredFields['notes'] = 'not_required'
      const values = Object.values(notRequiredFields)
      return values.some(val => val == 'null' ) ? false : true
    },
    isNewBillingForm () {
      let notRequiredFields = Object.assign({}, this.billingForm)
      delete notRequiredFields['notes']
      const values = Object.values(notRequiredFields)
      return values.every(val => val == 'null' ) ? true : false
    },
    selectedSector() {
      return this.industryOptions.find(o => o.value == this.clientForm.industry).text;
    },
    recentChanged () {
      let billingFormChanged
      let clientFormChanged = this.$refs.observer1.flags.changed
      let contactFormChange = this.newContacts.length > 0 ? true : false
      let addressFormChange = this.newAddresses.length > 0 ? true : false
      let requirementFormChange = this.newRequirements.length > 0 ? true : false
      if (this.hasBillingNote ) {
        billingFormChanged = this.$refs.observer4.flags.changed
      }
      if (addressFormChange || contactFormChange || clientFormChanged || billingFormChanged || requirementFormChange) {
        return true
      } else {
        return false
      }
    },
    AdditionalEmailsAllowed () {
      if (this.additionalClientEmails.length > 0) {
        // Additional emails allowed = 1
        return this.additionalClientEmails.length < 1 ? true : false
      } else {
        return true
      }
    },
    AdditionalContactEmailsAllowed () {
      if (this.additionalContactEmails.length > 0) {
        // Additional emails allowed = 1
        return this.additionalContactEmails.length < 1 ? true : false
      } else {
        return true
      }
    },
    invalidObserverForms () {
      const invalidClientForm =  this.$refs.observer1.flags.invalid
      // let invalidExonerationForm = false
      let invalidBillingForm = false
      if (this.hasBillingNote) {
        invalidBillingForm = this.hasBillingNote && !this.validBillingForm
        // if (this.billingForm.exonerated == 'true'){ invalidExonerationForm =  this.$refs.observer6.flags.invalid }
      }
      if (invalidClientForm || !this.recentChanged || invalidBillingForm) {
        if (!this.recentChanged) {
          return 'no-changes'
        } else if (invalidClientForm) {
          return 'client'
        } else if (invalidBillingForm) {
          return 'billing'
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  mounted () {
    this.fetchClientData()
    this.isMobile = this.detectMobile()
  },
  methods: {
    detectMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.screen.width < 576) {
        return true
      } else {
        return false
      }
    },
    fetchClientData () {
      this.ready = false
      this.$http.get(`/api/production/clients/${this.id}`)
        .then((res) => {
          // console.log(res);
          const { client } = res.data
          this.client_num = client.client_num
          // seed forms
          this.clientForm = {
            ...client,
            phone: client.phone ? client.phone.split('-').join('') : '',
            phone_2: client.phone_2 && client.phone_2 != "####-####" ? client.phone_2.split('-').join('') : '',
            cellular: client.cellular ? client.cellular.split('-').join('') : '',
            fax: client.fax ? client.fax.split('-').join('') : '',
            notes: client.notes ? client.notes : '',
            contact_person: client.contact_person ? client.contact_person.trim().replace(/\s{2,}/g, ' ') : null,
            exo_started_at: client.exo_started_at ? client.exo_started_at.split('T')[0] : null,
          }

          if (this.clientForm.email && this.clientForm.email.includes(';')) {
            let array = this.clientForm.email.split(';')
            this.clientForm.email = array[0].trim()
            this.additionalClientEmails.push({ email: array[1].trim() })
          }
          this.formatClientForm()

          if (res.data.client.client_billing_note) {
            this.hasBillingNote = true
            this.billingForm = res.data.client.client_billing_note
            let val = this.billingForm.notes
            Object.keys(this.billingForm).forEach((i) => this.billingForm[i] == false ? this.billingForm[i] = 'false' : this.billingForm[i] = 'true')
            this.billingForm['notes'] = val
            delete this.billingForm['created_at']
            delete this.billingForm['id']
          }

          // Contacts
          const contactData = normalize(
            { contacts: client.client_contacts },
            { contacts: [new schema.Entity('contacts') ] }
          )
          if (contactData.entities.hasOwnProperty('contacts')) {
            this.contactData = contactData.entities.contacts
          } else {
            this.contactData = {}
          }
          this.contactList = contactData.result.contacts

          // Addresses
          const addressData = normalize(
            { addresses: client.client_addresses },
            { addresses: [new schema.Entity('addresses') ] }
          )
          if (addressData.entities.hasOwnProperty('addresses')) {
            this.addressData = addressData.entities.addresses
          } else {
            this.addressData = {}
          }
          this.addressList = addressData.result.addresses

          // requirements
          const requirementData = normalize(
            { requirements: client.client_requirements },
            { requirements: [new schema.Entity('requirements') ] }
          )
          if (requirementData.entities.hasOwnProperty('requirements')) {
            this.requirementData = requirementData.entities.requirements
          } else {
            this.requirementData = {}
          }
          this.requirementList = requirementData.result.requirements

          this.ready = true
          delete this.clientForm['client_addresses']
          delete this.clientForm['client_requirements']
          delete this.clientForm['client_billing_note']
          delete this.clientForm['client_contacts']
          delete this.clientForm['projects']
        })
        .catch(e => console.log(e))
    },
    updateClient () {
      if (this.invalidObserverForms) {
        this.isValid = false
        if (this.invalidObserverForms === 'no-changes') {
          return this.showAlert('No hay cambios nuevos para guardar ', 'warning')
        } else if (this.invalidObserverForms === 'client'){
          this.showClientData = true
          this.scrollToFormError('general_information_form')
          return this.showAlert('Error en formulario de Información General, verifique para continuar y guardar el cliente correctamente', 'danger')
        } else if (this.invalidObserverForms === 'billing') {
          this.showBillingData = true
          this.scrollToFormError('billing_notes_form')
          return this.showAlert('Error en formulario de Notas de facturación, verifique para continuar y guardar el cliente correctamente', 'danger')
        } else if (this.invalidObserverForms === 'exoneration') {
          this.showBillingData = true
          this.scrollToFormError('billing_notes_form')
          return this.showAlert('Error en formulario de exoneración, verifique para continuar y guardar el cliente correctamente', 'danger')
        }
      }
      if (this.processing) return
      this.isValid = true

      // Format client form
      let clientForm = Object.assign({}, this.clientForm)
      if (this.additionalClientEmails.length > 0) clientForm['email'] += '; ' + this.additionalClientEmails.filter(y => y).map(x => x.email).join('; ')
      if (this.clientForm.sap_gln != '00') {
        clientForm['phone'] =  this.clientForm.phone ?  [ this.clientForm.phone.slice(0, 4), '-',  this.clientForm.phone.slice(4)].join('') : null
        clientForm['phone_2'] =  this.clientForm.phone_2 ?  [ this.clientForm.phone_2.slice(0, 4), '-',  this.clientForm.phone_2.slice(4)].join('') : null
      }
      clientForm['cellular'] =  this.clientForm.cellular ?  [ this.clientForm.cellular.slice(0, 4), '-',  this.clientForm.cellular.slice(4)].join('') : null
      clientForm['fax'] =  this.clientForm.fax ?  [ this.clientForm.fax.slice(0, 4), '-',  this.clientForm.fax.slice(4)].join('') : null
      clientForm['sector'] = this.selectedSector
      delete clientForm['id']
      delete clientForm['client_num']

      // Format billing form
      if (this.hasBillingNote) {
        var billingForm = Object.assign({}, this.billingForm)
        Object.keys(billingForm).forEach((i) => billingForm[i] == 'false' || billingForm[i] == false ? billingForm[i] = false : billingForm[i] = true)
        billingForm['notes'] = this.billingForm.notes
      }

      if (this.newContacts.length > 0 ) {
        this.newContacts.map( el => Number.isInteger(el.id) ?  el.id : delete el.id)
      }

      if (this.newAddresses.length > 0 ) {
        this.newAddresses.map( el => Number.isInteger(el.id) ?  el.id : delete el.id)
      }

      if (this.newRequirements.length > 0 ) {
        this.newRequirements.map( el => Number.isInteger(el.id) ?  el.id : delete el.id)
      }

      this.$http.patch(`/api/production/clients/${this.id}`, {
        client: clientForm,
        billing: this.hasBillingNote ? billingForm : null,
        contact: this.newContacts.length > 0 ? this.newContacts : null,
        address: this.newAddresses.length > 0 ? this.newAddresses : null,
        requirement: this.newRequirements.length > 0 ? this.newRequirements : null,
      })
      .then(response => {
        console.log(response)
        this.showAlert('Cliente actualizado correctamente, saliendo...', 'success', true)
        setTimeout(() => this.$router.push(`/app/prod/clientes/${this.id}`), 3000)
      })
      .catch(error => {
        console.log(error)
        this.showAlert('Algo no funcionó, intente de nuevo', 'danger')
      })
      .finally(() => {
        this.resetRecentChanges()
        this.processing = false
      })
    },
    scrollToFormError (formName) {
      if (!formName) { return }
      const el = document.querySelector(`#${formName}`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        let rowClass = 'highlighted-row'
        el.classList.add(rowClass)
        setTimeout(() => el.classList.remove(rowClass), 2000)
      }
    },
    formatClientForm() {
      delete this.clientForm['created_at']
      delete this.clientForm['updated_at']
      delete this.clientForm['lead']
      delete this.clientForm['current_account_balance']
      delete this.clientForm['projects_count']
      delete this.clientForm['sap_valid']
      delete this.clientForm['sap_valid_from']
      delete this.clientForm['sap_valid_to']
      delete this.clientForm['sap_valid_to']
      delete this.clientForm['sap_valid_remarks']
      delete this.clientForm['sap_frozen_remarks']
      delete this.clientForm['sap_frozen_from']
      delete this.clientForm['sap_frozen_to']
      delete this.clientForm['sap_frozen']
      delete this.clientForm['sap_card_type']
      delete this.clientForm['telemarketer_id']
      delete this.clientForm['group']
      delete this.clientForm['client_type']
      delete this.clientForm['price_list']
      delete this.clientForm['sap_price_list_no']
      delete this.clientForm['sap_course_doc_num']
      delete this.clientForm['sap_aplica_orden']
    },
    // Contacts
    onRowContactClick(item) {
      this.contactModalShow = true
      // console.log('input', )
      this.isNewContact = false
      this.selectedItem = item
      this.selectedItem['name'] = item.name.replace(/\s{2,}/g, ' ').trim()
      let contactPerson = this.clientForm.contact_person ? this.clientForm.contact_person.replace(/\s{2,}/g, ' ').trim() : this.clientForm.contact_person
      if (this.selectedItem.name == contactPerson ) {
        this.selectedPrincipal = true
      }
      // seed form
      this.contactForm.first_name = item.first_name ? item.first_name.replace(/\s{2,}/g, ' ').trim() : item.name.replace(/\s{2,}/g, ' ').trim()
      this.contactForm.last_name = item.last_name ? item.last_name.replace(/\s{2,}/g, ' ').trim() : ''
      this.contactForm.first_name = item.first_name && item.second_name ? `${item.first_name.replace(/\s{2,}/g, ' ').trim()} ${item.second_name.replace(/\s{2,}/g, ' ').trim()}` : this.contactForm.first_name
      this.contactForm.name = item.name.replace(/\s{2,}/g, ' ').trim()

      this.contactForm.phone = item.phone ?  item.phone.split('-').join('')  :  null
      this.contactForm.phone_2 =  item.phone_2 && item.phone_2 != "####-####"  ? item.phone_2.split('-').join('') : null
      this.contactForm.phone_extension = item.phone_extension
      this.contactForm.profession = item.profession
      this.contactForm.cellular = item.cellular ? item.cellular.split('-').join('')  :  null
      this.contactForm.is_active = item.is_active
      this.contactForm.id = item.id
      if (item.email && item.email.includes(';')) {
        let array = item.email.split(';')
        this.contactForm.email = array[0]
        array.slice(1).map( value => this.additionalContactEmails.push({ email: value.trim() }))
      } else {
        this.contactForm.email = item.email
      }

    },
    createContact (bool) {
      if (bool) {
        this.contactModalShow = true
        this.isNewContact = true
        // reset form
        this.contactForm =  {
          id: null,
          first_name: null,
          last_name: null,
          name: null,
          profession: null,
          phone: null,
          phone_extension: null,
          phone_2: null,
          cellular: null,
          email: null,
          is_active: true
        }
      } else {
        let contactForm = Object.assign({}, this.contactForm)
        this.idCount += 1
        contactForm['id'] = `id-${this.idCount}`
        contactForm['phone'] = this.contactForm.phone ?  [this.contactForm.phone.slice(0, 4), '-', this.contactForm.phone.slice(4)].join('') : null
        contactForm['phone_extension'] = this.contactForm.phone_extension
        contactForm['phone_2'] = this.contactForm.phone_2 ?  [this.contactForm.phone_2.slice(0, 4), '-', this.contactForm.phone_2.slice(4)].join('') : null
        contactForm['cellular'] = this.contactForm.cellular ?  [this.contactForm.cellular.slice(0, 4), '-', this.contactForm.cellular.slice(4)].join('') : null
        contactForm['first_name'] = this.contactForm.first_name.replace(/\s{2,}/g, ' ').trim() // delete whitespace
        contactForm['last_name'] = this.contactForm.last_name.replace(/\s{2,}/g, ' ').trim() // delete whitespace
        contactForm['profession'] = this.contactForm.profession ? this.contactForm.profession.replace(/\s{2,}/g, ' ').trim() : null // delete whitespace
        contactForm['name'] = `${this.contactForm.first_name.replace(/\s{2,}/g, ' ').trim()} ${this.contactForm.last_name.replace(/\s{2,}/g, ' ').trim()}`
        if (this.additionalContactEmails.length > 0) contactForm['email'] += '; ' + this.additionalContactEmails.filter(y => y).map(x => x.email).join('; ')
        this.clientForm.contact_person =  this.selectedPrincipal ? contactForm.name : this.clientForm.contact_person

        // check duplicate
        const checkDuplicate = this.contacts.findIndex((obj => obj.name.replace(/\s{2,}/g, ' ').trim() == contactForm.name))
        if(checkDuplicate > -1) {
          this.duplicateContact = true
          return this.showAlert('El nombre del contacto ya existe', 'danger', true)
        }
        // Save on temp array
        this.newContacts.push(contactForm)
        this.contactList.push(contactForm.id)
        this.$set(this.contactData, contactForm.id, contactForm)
        this.onHideContactModal()
      }
    },
    updateContact () {
      let contactForm = Object.assign({}, this.contactForm)
      contactForm['phone'] = this.contactForm.phone ?  [this.contactForm.phone.slice(0, 4), '-', this.contactForm.phone.slice(4)].join('') : null
      contactForm['phone_2'] = this.contactForm.phone_2 ?  [this.contactForm.phone_2.slice(0, 4), '-', this.contactForm.phone_2.slice(4)].join('') : null
      contactForm['cellular'] = this.contactForm.cellular ?  [this.contactForm.cellular.slice(0, 4), '-', this.contactForm.cellular.slice(4)].join('') : null
      contactForm['first_name'] = this.contactForm.first_name.replace(/\s{2,}/g, ' ').trim() // delete whitespace
      contactForm['last_name'] = this.contactForm.last_name.replace(/\s{2,}/g, ' ').trim() // delete whitespace
      contactForm['profession'] = this.contactForm.profession ? this.contactForm.profession.replace(/\s{2,}/g, ' ').trim() : null // delete whitespace
      contactForm['name'] = `${this.contactForm.first_name.replace(/\s{2,}/g, ' ').trim()} ${this.contactForm.last_name.replace(/\s{2,}/g, ' ').trim()}`
      if (this.additionalContactEmails.length > 0) contactForm['email'] += '; ' + this.additionalContactEmails.filter(y => y).map(x => x.email).join('; ')
      this.clientForm.contact_person =  this.selectedPrincipal ? contactForm.name : this.clientForm.contact_person

      // check duplicate
      const checkDuplicate = this.contacts.findIndex((obj => obj.name.replace(/\s{2,}/g, ' ').trim() == contactForm.name  && obj.id != contactForm.id))
      if(checkDuplicate > -1) {
        this.duplicateContact = true
        return this.showAlert('El nombre del contacto ya existe', 'danger', true)
      }

      //add or updated on temp array
      const index = this.newContacts.findIndex((obj => obj.id == this.selectedItem.id))
      if (index > -1){
        this.newContacts.splice(index, 1, contactForm)
      } else {
        this.newContacts.push(contactForm)
      }
      this.$set(this.contactData, this.selectedItem.id, contactForm)
      this.onHideContactModal()
    },
    onHideContactModal () {
      // reset form
      this.additionalContactEmails = [];
      this.isNewContact = false
      this.selectedItem = []
      this.contactModalShow = false
      this.selectedPrincipal = false
      this.duplicateContact = false
      this.contactForm =  {
        id: null,
        first_name: null,
        last_name: null,
        name: null,
        profession: null,
        phone: null,
        phone_extension: null,
        phone_2: null,
        cellular: null,
        email: null,
        is_active: true
      }
    },
    // Addresses
    onRowAddressClick(item) {
      this.addressModalShow = true
      this.isNewAddress = false
      this.selectedItem = item
      this.selectedItem['address'] = item.address.replace(/\s{2,}/g, ' ').trim()
      this.setPrincipalAddress(this.selectedItem)

      // seed form
      this.addressForm.id = item.id,
      this.addressForm.address = item.address,
      this.addressForm.street =  item.street,
      this.addressForm.province = item.province[0],
      this.addressForm.canton = item.canton,
      this.addressForm.district = item.district
      // this.searchCantons(item.province[0])
    },
    openNewAddressModal () {
      if (this.addresses.length === 0) {
        this.selectedPrincipal = true
      }
      this.addressModalShow = true
      this.isNewAddress = true
      // reset form
      this.addressForm = {
        id: null,
        address: null,
        street: null,
        province: null,
        canton: null,
        district: null
      }
    },
    createAddress () {
      // format form
      this.idCount += 1
      const addressForm = {
        ...this.addressForm,
        id: `id-${this.idCount}`,
        district: this.addressForm.district[1],
        canton: this.addressForm.canton[1],
        address: this.addressForm.address.replace(/\s{2,}/g, ' ').trim()
      }
      this.clientForm.default_address =  this.selectedPrincipal ? addressForm.address : this.clientForm.default_address

      let newAddress = Object.assign({}, this.addressForm)
      newAddress['id'] = `id-${this.idCount}`
      newAddress['canton'] = this.addressForm.canton[1]
      newAddress['district'] = this.addressForm.district[1]

      // check duplicate
      const checkDuplicate = this.addresses.findIndex((obj => obj.address == addressForm.address))
      if(checkDuplicate > -1) {
        this.duplicateAddress = true
        return this.showAlert('El nombre de la dirección ya existe', 'danger', true)
      }
      // Save on temp array
      this.newAddresses.push(newAddress)
      this.addressList.push(addressForm.id)
      this.$set(this.addressData, addressForm.id, addressForm)

      this.onHideAddressModal()
    },
    updateAddress () {
      let newAddress = Object.assign({}, this.addressForm)
      const addressForm = {
        ...this.addressForm,
        address: this.addressForm.address.replace(/\s{2,}/g, ' ').trim()
      }

      if (this.cantonAddressOptions.length > 0) {
        newAddress['canton'] = this.addressForm.canton[1]
        newAddress['district'] = this.addressForm.district[1]

        addressForm['canton'] = this.addressForm.canton[1]
        addressForm['district'] = this.addressForm.district[1]
      }
      this.clientForm.default_address =  this.selectedPrincipal ? addressForm.address : this.clientForm.default_address

      // check duplicate
      const checkDuplicate = this.addresses.findIndex((obj => obj.address.replace(/\s{2,}/g, ' ').trim() == addressForm.address && obj.id != addressForm.id ))
      if(checkDuplicate > -1) {
        this.duplicateAddress = true
        return this.showAlert('El nombre de la dirección ya existe', 'danger', true)
      }

      //add or updated on temp array
      const index = this.newAddresses.findIndex((obj => obj.id == this.addressForm.id))
      if (index > -1) {
        this.newAddresses.splice(index, 1, newAddress)
      }else{
        this.newAddresses.push(newAddress)
      }
      this.$set(this.addressData, addressForm.id, addressForm)
      this.onHideAddressModal()
    },
    onHideAddressModal () {
      // reset form
      this.cantonAddressOptions = [],
      this.districtAddressOptions = [],
      this.isNewAddress = false
      this.selectedItem = []
      this.addressModalShow = false
      this.selectedPrincipal = false
      this.duplicateAddress= false
      this.addressForm = {
        id: null,
        address: null,
        street: null,
        province: null,
        canton: null,
        district: null
      }
    },
    setPrincipalAddress (currentAddress = {}) {
      if (this.addresses.length === 0 || !currentAddress.address) {
        this.selectedPrincipal = true
        return
      }
      this.selectedPrincipal = currentAddress.address == this.clientForm.default_address
    },
    // Requirements
    onRowRequirementClick(item){
      console.log(item);
      this.requirementModalShow = true
      this.isNewRequirement = false
      this.selectedItem = item

      // seed form
      this.requirementForm.id = item.id,
      this.requirementForm.category = item.category,
      this.requirementForm.name =  item.name,
      this.requirementForm.description = item.description
    },
    createRequirement(bool){
      if (bool) {
        this.requirementModalShow = true
        this.isNewRequirement = true
        // reset form
        this.requirementForm = {
          id: null,
          category: null,
          name: null,
          description: null,
        }
      } else {
        // format form
        let requirementForm = Object.assign({}, this.requirementForm)
        this.idCount += 1
        requirementForm['id'] = `id-${this.idCount}`
        requirementForm['category'] = this.requirementForm.category
        requirementForm['name'] = this.requirementForm.name
        requirementForm['description'] = this.requirementForm.description
        // Save on temp array
        this.newRequirements.push(requirementForm)
        this.requirementList.push(requirementForm.id)
        this.$set(this.requirementData, requirementForm.id, requirementForm)
        this.onHideRequirementModal()
      }
    },
    updateRequirement(){
      let requirementForm = Object.assign({}, this.requirementForm)

      //add or updated on temp array
      const index = this.newRequirements.findIndex((obj => obj.id == this.selectedItem.id))
      if (index > -1){
        this.newRequirements.splice(index, 1, requirementForm)
      } else {
        this.newRequirements.push(requirementForm)
      }
      this.$set(this.requirementData, this.selectedItem.id, requirementForm)
      this.onHideRequirementModal()
    },
    onHideRequirementModal(){
      this.isNewRequirement = false
      this.selectedItem = []
      this.requirementModalShow = false
      this.requirementForm = {
          id: null,
          category: null,
          name: null,
          description: null,
        }
    },
    // additional emails
    addLine(form) {
      if(form === 'contact'){
        let checkEmptyLines = this.additionalContactEmails.filter(line => !line.email);
        if (checkEmptyLines.length >= 1 && this.additionalContactEmails.length > 0) return;
        this.additionalContactEmails.push({ email: null });
      } else if (form === 'client') {
        let checkEmptyLines = this.additionalClientEmails.filter(line => !line.email);
        if (checkEmptyLines.length >= 1 && this.additionalClientEmails.length > 0) return;
        this.additionalClientEmails.push({ email: null });
      }
    },
    removeLine(lineId, form) {
      if (form === 'contact') {
        this.additionalContactEmails.splice(lineId, 1);
      }else if (form === 'client') {
        this.additionalClientEmails.splice(lineId, 1);
      } else {
        return
      }
    },
    // Address mapping
    searchCantons (provinceId) {
      if (this.processing) return;
      this.processing = true;
      this.toggleCanton = true
      this.$http.get('/api/canton_addresses', {
          params: { province_code: provinceId}
        })
        .then(response => {
          this.addressForm.canton = null
          this.addressForm.district = null
          this.formatCantonOptions(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => this.toggleCanton = false, 1000)
        })
    },
    formatCantonOptions(data) {
      this.cantonAddressOptions =  data.map(term => ({text: term.name, value: [term.code, term.name]}))
    },
    searchDistricts () {
      console.log(this.addressForm.canton);
      if (this.processing) return;
      this.processing = true;
      this.toggleDistrict = true
      this.$http.get('/api/district_addresses', {
          params: { canton_code: this.addressForm.canton[0] }
        })
        .then(response => {
          this.addressForm.district = null
          this.formatDistrictOptions(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => this.toggleDistrict = false, 1000)
        })
    },
    formatDistrictOptions(data) {
      this.districtAddressOptions =  data.map(term => ({text: term.name, value: [term.code, term.name]}))
    },
    goBack() {
      if (this.recentChanged) {
        return this.exitModalShow = true
      }
      this.$router.push(`/app/prod/clientes/${this.id}`)
    },
    resetRecentChanges () {
      this.newContacts =[],
      this.newAddresses = [],
      this.newRequirements = [],
      this.$refs.observer1.reset()
      if (this.hasBillingNote) { this.$refs.observer4.reset() }
    },
    showAlert(val, variant, dismiss) {
      this.alertVariant = variant;
      this.alertMessage = val;
      this.alertShow = true;
      if (dismiss) {
        setTimeout(() => {
        this.alertVariant = null,
        this.alertMessage = '';
        this.alertShow = false;
      }, 4000)
      }
    },
    bTabClass(ind) {
      if (this.bTabIndex === ind) {
        return ['font-weight-bold ','text-primary']
      } else {
        return ['text-muted']
      }
    },
    resetExonerationForm () {
      this.clientForm.exo_business_name = null
      this.clientForm.exo_doc_num = null
      this.clientForm.exo_doc_type = null
      this.clientForm.exo_purchase_percent = null
      this.clientForm.exo_started_at = null
      this.clientForm.exo_total_tax = 0.0
    }
  }
}
</script>
