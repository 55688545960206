<template>
  <div>
    <b-row align-h="between" class="custom-tabs mx-0">
      <b-link class="ml-3" @click="$router.go(-1)">
        <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
        <span class="pl-2">Atrás</span>
      </b-link>
      <ul>
        <!-- Nav Tabs ? -->
      </ul>
    </b-row>
    <ContentView class="pb-5">
      <Subheader>
        <font-awesome-icon :icon="['far', 'warehouse-alt']" size="lg" fixed-width />
        <span class="pl-2">Inventario</span>
        <template #right>
          <Button v-if="isAdmin" to="/app/prod/inventario/almacenes" size="sm" class="mr-3">
            Gestión de Almacenes
          </Button>
          <Button v-if="isAuthorizedBundleUser" to="/app/prod/inventario/bultos" size="sm">
            Gestión de Bultos
          </Button>
        </template>
      </Subheader>
      <!-- Search Bar -->
      <b-row align-v="center">
        <b-col>
          <b-form-group style="width: 280px">
            <template #label>
              <small class="text-info">Buscar:</small>
            </template>
            <b-input-group size="sm">
              <b-input
                v-model="searchTerm"
                autofocus
                placeholder="Código o descripción"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" size="sm" @click="search">
                  <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse fixed-width />
                  <font-awesome-icon v-else :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <template #label>
              <small class="text-info">Filtrar:</small>
            </template>
            <b-select v-model="groupFilter" :options="itemGroupOptions" size="sm" style="width: 280px" @input="search">
              <template #first>
                <b-select-option :value="null">Todos</b-select-option>
              </template>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col class='text-right'>
          <b-form-group class="ml-5">
            <b-checkbox v-model="bundleFilter" value="is_bundle" :unchecked-value="null" size="sm" @input="search">
              Sólo Bultos
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="center" class="mt-5">
        <b-alert v-if="startScreen" variant="secondary" show class="w-100">
          <b-row align-h="center">
            <span v-if="count" class="text-muted fs-20">{{ count.toLocaleString() }} artículos que se pueden buscar</span>
            <SpinnerHorizontal v-else />
          </b-row>
        </b-alert>
        <b-table
          v-else
          :fields="authorizedFields"
          :items="items"
          hover
          responsive
          show-empty
          caption-top
          class="fs-13"
          @row-clicked="onRowClick"
        >
          <!-- Table caption -->
          <template #table-caption>
            <div v-if="count" class="text-info pl-2">
              <span>{{ count.toLocaleString() }}</span>
              <span v-if="searchDone">resultados</span>
              <div v-else class="d-inline">
                <span>artículos</span>
                <span v-if="!isAdmin">activos</span>
                <span>en total</span>
              </div>
            </div>
          </template>

          <!-- Loading spinner / empty search text -->
          <template #empty>
            <div class="text-center text-danger">
              <span>No resultados</span>
            </div>
          </template>

          <!-- Table Data -->
          <template v-slot:cell(item_code)="data">
            <span>{{ data.value }}</span>
            <b-badge v-if="data.item.is_bundle" variant="warning" class="ml-3">Bulto</b-badge>
          </template>

          <template v-slot:cell(is_valid)="data">
            <span v-if="data.value" class="text-success">Activo</span>
            <span v-else class="text-danger">Inactivo</span>
          </template>

          <template v-slot:cell(price)="data">
            <span v-if="data.item.price_currency == 'USD'">
              <span v-if="isInstaller">{{ convertToColones(data.value) | colones2 }}</span>
              <span v-else>{{ data.value | dollarize }}</span>
            </span>
            <span v-else-if="data.item.price_currency == 'COL'">
              <span>{{ data.value | colones2 }}</span>
            </span>
            <span v-else>{{ data.item.price_currency }} {{ data.value }}</span>
          </template>

          <template v-slot:cell(installer_price)="data">
            <span v-if="data.item.installer_price_currency == 'USD'">
              <span v-if="isInstaller">{{ convertToColones(data.value) | colones2 }}</span>
              <span v-else>{{ data.value | dollarize }}</span>
            </span>
            <span v-else-if="data.item.installer_price_currency == 'COL'">
              <span>{{ data.value | colones2 }}</span>
            </span>
            <span v-else>{{ data.item.installer_price_currency }} {{ data.value }}</span>
          </template>
        </b-table>
      </b-row>
      <Observer @intersect="intersected" />
      <SpinnerHorizontal v-if="addingPage" />
    </ContentView>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import Observer from '../shared/Observer'
import SearchBar from '../shared/SearchBar'
import SpinnerHorizontal from '../shared/SpinnerHorizontal'
import Subheader from '../shared/Subheader'

export default {
  name: 'ItemIndex',
  components: {
    Button,
    ContentView,
    Observer,
    SearchBar,
    SpinnerHorizontal,
    Subheader
  },
  data () {
    return {
      itemData: {},
      itemList: [],
      itemGroups: [],
      searchTerm: '',
      bundleFilter: null,
      groupFilter: null,
      fields: [
        { key: 'item_code', label: 'Código', tdClass: 'clickable', thStyle: { minWidth: '60px' }},
        { key: 'item_group_name', label: 'Grupo', tdClass: 'clickable' },
        { key: 'description', label: 'Descripción', tdClass: 'clickable' },
        { key: 'price', label: 'Precio Público', thClass: 'text-right', tdClass: 'text-right clickable' },
        { key: 'installer_price', label: 'Precio Instal.', thClass: 'text-right', tdClass: 'text-right clickable' },
        { key: 'stock', thClass: 'text-center', tdClass: 'text-center clickable' }
      ],
      adminFields: [
        { key: 'item_code', label: 'Código', tdClass: 'clickable', thStyle: { minWidth: '60px' }},
        { key: 'item_group_name', label: 'Grupo', tdClass: 'clickable' },
        { key: 'is_valid', label: 'Status', tdClass: 'clickable' },
        { key: 'description', label: 'Descripción', tdClass: 'clickable' },
        { key: 'price', label: 'Precio Público', thClass: 'text-right', tdClass: 'text-right clickable' },
        { key: 'installer_price', label: 'Precio Instal.', thClass: 'text-right', tdClass: 'text-right clickable' },
        { key: 'stock', thClass: 'text-center', tdClass: 'text-center clickable' }
      ],
      page: 1,
      count: null,
      last: null,
      startScreen: false,
      processing: false,
      searchDone: false,
      searching: false,
      addingPage: false
    }
  },
  computed: {
    ...mapGetters (['isDev', 'isAdmin', 'isDistributor', 'isInstaller','usdCrRate', 'isSalesSupervisor', 'isProductLeader']),
    items () {
      return this.itemList.map(id => this.itemData[id])
    },
    authorizedFields () {
      if (this.isAdmin) {
        return this.adminFields;
      } else if (this.isDistributor || this.isInstaller) {
        return this.distributorFields
      } else {
        return this.fields
      }
    },
    distributorFields () {
      return this.fields.filter(field => field.key !== 'stock')
    },
    itemGroupOptions () {
      return this.itemGroups.map(group => ({ text: group.name, value: group.id }))
    },
    isAuthorizedBundleUser() {
      if (this.isAdmin || this.isSalesSupervisor || this.isProductLeader) { return true}
      return false
    }
  },
  mounted () {
    this.startScreen = true
    this.fetchItemGroups()
    this.fetchItems()
  },
  methods: {
    fetchItemGroups () {
      this.$http.get('/api/production/item_groups')
        .then((res) => {
          console.log(res)
          this.itemGroups = res.data
        })
        .catch(e => console.log(e))
    },
    fetchItems () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/production/products', {
          params: {
            q: this.searchTerm,
            group_filter: this.groupFilter,
            bundle_filter: this.bundleFilter,
            page: this.page
          }
        })
        .then((res) => {
          console.log(res)
          const itemData = normalize(
            { items: res.data.items },
            { items: [ new schema.Entity('items') ] }
          )

          if (this.addingPage) {
            // add to existing data
            if (itemData.entities.hasOwnProperty('items')) {
              this.itemData = Object.assign({}, this.itemData, itemData.entities.items)
            }
            this.itemList = [...this.itemList, ...itemData.result.items]
          } else {
            // seed fresh data
            if (itemData.entities.hasOwnProperty('items')) {
              this.itemData = itemData.entities.items
            }
            this.itemList = itemData.result.items
          }
          this.count = res.data.pagy.count
          this.last = res.data.pagy.last
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searchDone = this.searchTerm ? true : false
          this.processing = false
          this.searching = false
          this.addingPage = false
        })
    },
    search () {
      this.startScreen = false
      this.searching = true
      this.page = 1
      this.fetchItems()
    },
    intersected () {
      console.log('Intersected')
      if (this.page + 1 <= this.last) {
        console.log('Adding next page')
        this.page++
        this.addingPage = true
        this.fetchItems()
      }
    },
    convertToColones(dollarValue){
      return dollarValue * this.usdCrRate
    },
    onRowClick (item) {
      this.$router.push(`/app/prod/inventario/${item.id}`);
    }
  }
}
</script>
