import axios from 'axios'
import { normalize, schema } from 'normalizr'

const state = {
  employeeData: {},
  employeeList: [],
  loading: true
}

const getters = {
  employeeDataLoaded (state) {
    return state.employeeList.length > 0
  },
  getEmployeeById (state) {
    return id => state.employeeData[id]
  },
  employees (state) {
    let array = state.employeeList.map(id => state.employeeData[id])
    return array.sort((a, b) => a.last_name.localeCompare(b.last_name, 'es'))
  },
  managers (_, getters) {
    let array = getters.employees.filter(employee => employee.is_manager === true)
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name, 'es'))
  },
  salespeople (_, getters) {
    let array = getters.employees.filter(employee => employee.role === 'vendedor' || employee.role === 'técnico')
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name, 'es'))
  },
  salesQuotationPeople(_, getters) {
    let array = getters.employees.filter(employee => employee.role === 'vendedor' || employee.role === 'técnico' || employee.role === 'supervisor_ventas')
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name, 'es'))
  },
  salesAgents (_, getters) {
    let array = getters.employees.filter(employee => employee?.roles?.some(role => role.name === 'Ventas - Asesores'))
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name, 'es'))
  },
  salesTelemarketers (_, getters) {
    let array = getters.employees.filter(employee => employee?.roles?.some(role => role.name === 'TKM - Asesores'))
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name, 'es'))
  },
  salesSupervisors (_, getters) {
    let array = getters.employees.filter(employee => employee.role === 'administrador' || employee.role === 'supervisor_ventas' || employee.role === 'supervisor_división')
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name, 'es'))
  },
  superAdmins (state, _, rootState) {
    let adminList = rootState.settings.super_admins
    return adminList.map(id => state.employeeData[id])
  },
  humanResourcesAdmins (state, _, rootState) {
    let adminList = rootState.settings.human_resources_admins
    return adminList.map(id => state.employeeData[id])
  },
  vacationsInbox (state, _, rootState) {
    return rootState.settings.vacations_inbox
  },
  //// only used for AppConfig
  distributionAdmins (state, _, rootState) {
    return rootState.settings.distribution_admins.map(id => state.employeeData[id])
  },
  developers (state, _, rootState) {
    return rootState.settings.developers.map(id => state.employeeData[id])
  },
  theAncestryRoot (state, _, rootState) {
    return state.employeeData[rootState.settings.ancestry_root]
  }
  ////
}

const actions = {
  fetchEmployeeData ({ commit }) {
    axios
      .get('/api/human_resources/users')
      .then(response => {
        const employeeData = normalize(
          { employees: response.data },
          { employees: [ new schema.Entity('employees') ] }
        );
        commit('loadEmployeeData', employeeData)
      })
      .catch(error => {
        console.log(error)
        // TODO
      })
      .finally(() => {
        commit('setLoading', false)
      })
  }
}

const mutations = {
  loadEmployeeData (state, employeeData) {
    state.employeeData = employeeData.entities.employees
    state.employeeList = employeeData.result.employees
  },
  addEmployee (state, employee) {
    state.employeeList.push(employee.id)
    state.employeeData[employee.id] = employee
  },
  setEmployeeData (state, employee) {
    state.employeeData[employee.id] = employee
  },
  approveVacationRequest (state, payload) {
    let employee = state.employeeData[payload.id]
    employee.vacation_days_pending = payload.vacation_days_pending
    employee.vacation_days_spent = payload.vacation_days_spent
  },
  rejectVacationRequest (state, payload) {
    let employee = state.employeeData[payload.id]
    employee.vacation_days_pending = payload.vacation_days_pending
  },
  cancelVacationRequest (state, payload) {
    let employee = state.employeeData[payload.id]
    employee.vacation_days_spent = payload.vacation_days_spent
  },
  adjustDeductedVacationDays (state, payload) {
    let employee = state.employeeData[payload.id]
    employee.vacation_days_deducted = payload.vacation_days_deducted
  },
  setLoading (state, boolean) {
    state.loading = boolean
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
