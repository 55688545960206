import Vue from 'vue'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

Vue.filter('formatDateLong', function (value) {
  if (value) {
    let date = parseISO(String(value).trim())
    return format(date, "d 'de' MMMM yyyy", { locale: es })
  }
})
