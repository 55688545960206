import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "motorPosicion", "symbol" ];

  connect() {
    this.filterDots();
  }
  
  filterDots() {
    this.motorPosicionTargets.forEach(el => {
      // only use first 3 words of value
      if (el.checked) {
        var value = el.value.split(' ').slice(0,3).join('').toLowerCase();
        var symbol = document.getElementById(value);
        symbol.classList.remove('d-none');
        var siblings = Array.prototype.filter.call(symbol.parentNode.children, child => {
          return child !== symbol;
        });
        siblings.forEach(el => {
          el.classList.add('d-none');
        });
      }
    });
  }

  // Not in use
  clearSymbols() {
    this.symbolTargets.forEach(el => {
      el.classList.add('d-none');
    });
  }
}

