<template>
  <div>
    <!-- Assets modal -->
    <b-modal
      id="assets-modal"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      scrollable
      :header-class="headerVariant"
      @shown="fetchAssetsData"
      @hide="onAssetModalHide"
    >
      <template #modal-title>
        <b-row>
          <b-col cols="3">
            <b :class="{'text-secondary': loading}">Búsqueda:</b>
          </b-col>
          <b-col>
            <b-form-group style="width: 290px">
              <b-input-group size="sm">
                <b-input
                  v-model="searchValue"
                  autofocus
                  placeholder="Buscar por AF"
                  :disabled="loading"
                  @keyup.enter="fetchAssetsData"
                />
                <b-input-group-append>
                  <b-button variant="dark" size="sm" @click="fetchAssetsData" :disabled="loading">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <b-container v-if="loading" fluid class="fs-13">
        <b-row align-h="center" class="mt-5">
          <div class="text-center text-info" >
            <b-spinner style="width: 1.25rem; height: 1.25rem"></b-spinner>
            <span class="pl-2 fs-20">Obteniendo datos...</span>
          </div>
        </b-row>
      </b-container>
      <b-container v-else fluid class="mt-1">
        <b-row>
          <b-col class="fs-13">
            <b-table
              :items="assetItems"
              :fields="assetFields"
              primary-key="id"
              show-empty
              class="border-bottom"
              sort-icon-left
              hover
            >
              <template #empty>
                <div class="d-flex align-items-center justify-content-center" style="height: 225px">
                  <span class="empty-list-text">
                    Sin resultados
                  </span>
                </div>
              </template>
              <template #cell(total)="data">
                <span v-if="data.item.card_transaction.currency == 'COL'">{{ data.value | colones2 }}</span>
                <span v-else>{{ data.value | dollarize }}</span>
              </template>
              <template #cell(created_at)="data">
                <span>{{ data.value | formatDate }}</span>
              </template>
              <template #cell(transaction)="data">
                <span>{{ data.item.card_transaction.internal_code_1  }}</span>
              </template>
              <template #cell(transaction_status)="data">
                <b-badge v-if="data.item.card_transaction.status == 'pendiente'" pill variant="danger" style="font-size: 90%; width: 80px">Pendiente</b-badge>
                <b-badge v-else-if="data.item.card_transaction.status == 'en_proceso'" pill variant="warning" style="font-size: 90%; width: 80px">En proceso</b-badge>
                <b-badge v-else-if="data.item.card_transaction.status == 'por_aprobar'" pill variant="info" style="font-size: 90%; width: 80px">Por aprobar</b-badge>
                <b-badge v-else-if="data.item.card_transaction.status == 'aprobada'" pill variant="success" style="font-size: 90%; width: 80px">Aprobada</b-badge>
                <b-badge v-else-if="data.item.card_transaction.status == 'pendiente_af'" pill class="badge-orange" style="font-size: 90%; width: 80px">Asignar AF</b-badge>
                <b-badge v-else pill variant="secondary" class="clickable" style="font-size: 90%; width: 80px">{{ data.item.card_transaction.status }}</b-badge>
              </template>
              <template #cell(transaction_business)="data">
                <span>{{ data.item.card_transaction.business_name  }}</span>
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" variant="outline-info" class="p-1" @click="goToTransaction(data.item.card_transaction)">
                  <font-awesome-icon :icon="['far', 'chevron-right']" pull="right"/>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
      <p v-if="assetItems.length > 0 && !loading" class="text-danger text-center pb-0 mb-0">Resultados: {{ assetItems.length }}</p>
      <b-row align-h="end" class="mx-3">
        <Button variant="red" @click="$emit('modal-hidden')">Cerrar</Button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Button from '../../shared/Button.vue'

export default {
  name: 'AssetsModal',
  components: { Button },
  data () {
    return {
      loading: true,
      searchValue: '',
      assetItems: [],
      assetFields: [
        { key: 'asset_code', label: 'Activo', tdClass: 'align-middle font-weight-bold font-lato fs-14'},
        { key: 'quantity', label: 'Cantidad', tdClass: 'align-middle font-lato fs-16'},
        { key: 'total', label: 'Total', tdClass: 'align-middle font-lato fs-16'},
        { key: 'created_at', label: 'Creado en', tdClass: 'align-middle font-lato fs-16'},
        { key: 'transaction', label: 'Transacción', tdClass: 'align-middle font-weight-bold font-lato fs-14'},
        { key: 'transaction_business', label: 'Comercio', tdClass: 'align-middle font-lato fs-16'},
        { key: 'transaction_status', label: 'Status transacción', tdClass: 'align-middle font-lato fs-16'},
        { key: 'actions', label: '', tdClass: 'align-middle pl-2' },
      ]
    }
  },
  computed: {
    headerVariant () {
      return ['bg-oi','pb-0']
    },
  },
  mounted () {
    this.loading = true
  },
  methods: {
    fetchAssetsData() {
      if (this.processing ) return
      this.loading = true
      this.processing = true
      this.$http.get(`/api/production/card_transaction_assets`, {
        params: { q: this.searchValue}
      })
        .then((res) => {
          this.assetItems = res.data
        })
        .catch((e) => {
          console.log(e)
          this.assetItems = []
        })
        .finally( () =>{
          setTimeout(() => {
            this.loading = false
          }, 500)
          this.processing = false
        })
    },
    onAssetModalHide() {
      this.$emit('modal-hidden')
      this.assetItems = []
      this.searchValue = ''
    },
    goToTransaction(transaction) {
      this.$emit('find-transaction', transaction)
    }
  }
}

</script>