<template>
  <div>
    <!-- Toggle button -->
    <b-row align-h="end">
      <b-button v-b-toggle.sidebar-right class="pasos-button rounded-circle theme-orange clickable">
        <font-awesome-icon :icon="['fas', 'bars']" fixed-width />
      </b-button>
    </b-row>

    <b-sidebar id="sidebar-right" ref="sidebar" title="Navegar" right shadow z-index="2000">
      <nav>
        <ul class="border-top" style="list-style: none; padding: 0">
          <SubmitalShowStepLink
            v-for="step in configuratorSteps"
            :step="step"
            :key="step.en"
            @step-link-clicked="goToConfiguratorStep"
          />
        </ul>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubmitalShowStepLink from '../SubmitalShowStepLink'

export default {
  name: 'NavigationSidebar',
  components: { SubmitalShowStepLink },
  computed: {
    ...mapGetters (['configuratorSteps'])
  },
  methods: {
    goToConfiguratorStep (step) {
      this.$store.commit('setCurrentStep', step.en)
      this.$refs.sidebar.hide()
    }
  }

}
</script>
