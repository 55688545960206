export const businessUnits = [
  { value: "DI", text: "DI - División Industrial" },
  // { value: "SG", text: "Servicios Generales" },
  { value: "FB",text: "FB - Fabricaciones" },
  { value: "EX",text: "EX - Exportación" },
  { value: "FCT",text: "FCT - Facturación" },
  { value: "CAL",text: "CAL - Calidad" },
  { value: "COP",text: "COP - Corporativo" },
  { value: "FIN",text: "FIN - Finanzas" },
  { value: "COM",text: "COM - Compras" },
  { value: "FLO",text: "FLO - Flotilla" },
  { value: "GEN1",text: "GEN1 - General" },
  { value: "GFI",text: "GFI - Gerencia Financiera" },
  { value: "GJD",text: "GJD - Gerencia General y Junta Directiva" },
  { value: "GMV",text: "GMV - Gerencia Mercadero y Ventas" },
  { value: "HZ", text: "HZ - Horizontales" },
  // { value: "ING",text: "Ingeniería" },
  { value: "PA",text: "PA - Puertas Automáticas" },
  { value: "GCA",text: "GCA - Gerencia Calidad" },
  // { value: "GDT",text: "Gerencia de Distribución" },
  { value: "PK",text: "PK - Parking" },
  { value: "PMO",text: "PMO - Dirección de Proyectos" },
  // { value: "PRO",text: "Ventas Proyectos" },
  { value: "PVT",text: "PVT - Post venta (ST)" },
  { value: "RRH",text: "RRH - Recursos Humanos" },
  // { value: "SAC",text: "Post Venta" },
  { value: "ST",text: "ST - Servicio Técnico" },
  { value: "SUP",text: "SUP - Supervisores" },
  { value: "TA",text: "TA - Tienda Alajuela" },
  { value: "TC",text: " TC - Tienda Central Calle Blancos" },
  { value: 'TKM', text: 'TKM - Asesores de venta de PVT y CSC' },
  { value: "ALM",text: "ALM - Almacenes" },
  { value: "MR",text: "MR - Mercados Regionales" },
  { value: "MKT",text: "MKT - Marketing" },
  { value: "TG",text: "TG - Tienda Guanacaste" },
  { value: "TH",text: "TH - Tienda Heredia" },
  { value: "TIC",text: "TIC - Tecnología de Información"},
  // { value: "TT", text: "Tienda Tibás" },
  // { value: "BA", text: "Barreras" },
  { value: "TZ", text: "TZ - Tienda Zapote" },
  { value: "VV", text: "VV - Video Vigilancia" },
  { value: "GOP", text: "GOP - Gerencia Operaciones" },
  { value: "GOP1", text: "GOP1 - Gerencia Producción" },
  { value: "SSO", text: "SSO - Seguridad y Salud Ocupacional" },
  { value: "CA", text: "CA - Control Acceso"},
  { value: "CM", text: "CM - Contratos Mantenimiento" },
  { value: "CON", text: "CON - Contabilidad" },
  { value: "CSC", text: "CSC - Centro Servicio COAA" },
  { value: "DIB", text: "DIB - Dibujantes" },
  { value: 'MAK', text: 'MAK - Mercadeo Automática' },
  { value: 'COOR', text: 'COOR - Coordinadores' },
  { value: 'CS-ATK', text: 'CS-ATK - Centro Servicio Automática' },
  { value: 'TD', text: 'TD - Tienda Digital' },
  { value: 'TCA', text: 'TCA - Tienda Cartago' },
  { value: 'TREA', text: 'TREA' },
  { value: 'VTS', text: 'VTS - Asesores Ventas' },
  { value: 'GEN-ATK', text: 'GEN-ATK - General automatika' }
  // { value: "Centr_z", text: "Centro de coste general"}
]
