<template>
  <div>
    <b-row v-if="configurable.motor && configurable.motor.transmission == 'Manual'" align-h="center" class="mt-5">
      <h5 class="text-danger mb-0">
        ** Portón es manual, omita
        <span v-if="forSection">esta sección</span>
        <span v-else>la casilla de abajo</span>
        **
      </h5>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ManualMotorNotice',
  props: {
    forSection: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState ({ configurable: state => state.configurator.configurable })
  }
}
</script>
