const businessUnits = [
  { value: 'CA', text: 'CA - Control de Acceso' },
  // { value: "ING",text: "ING - Ingeniería y Diseño" },
  { value: 'TREA', text: 'TREA' },
  { value: 'CM', text: 'CM - Contratos Mantenimiento' },
  { value: 'CSC', text: 'CSC - Centro Servicio COAA' },
  { value: 'DI', text: 'DI - División Industrial' },
  { value: 'EX', text: 'EX - Exportación' },
  { value: 'HZ', text: 'HZ - Horizontales' },
  { value: 'PA', text: 'PA - Puertas Automáticas' },
  { value: 'PK', text: 'PK - Parking' },
  { value: 'PTY', text: 'PTY - Panamá' },
  { value: 'PVT', text: 'PVT - Post Venta' },
  { value: 'VV', text: 'VV - Video Vigilancia' },
  { value: 'TA', text: 'TA - Tienda Alajuela' },
  { value: 'TC', text: 'TC - Tienda Calle Blancos' },
  { value: 'TH', text: 'TH - Tienda Heredia' },
  { value: 'TG', text: 'TG - Tienda Guanacaste' },
  { value: 'TZ', text: 'TZ - Tienda Zapote' },
  { value: 'TKM', text: 'TKM - Asesores de venta de PVT y CSC' },
  { value: 'MAK', text: 'MAK - Mercadeo Automática' },
  { value: 'COOR', text: 'COOR - Coordinadores' },
  { value: 'CS-ATK', text: 'CS-ATK - Centro Servicio Automática' },
  { value: 'TD', text: 'TD - Tienda Digital' },
  { value: 'TCA', text: 'TCA - Tienda Cartago' },
  { value: 'VTS', text: 'VTS - Asesores Ventas' },
  { value: 'GEN-ATK', text: 'General automatika' }
]

const businessUnits2 = [
  { value: 'CA', text: 'CA - Control de Acceso' },
  { value: 'VV', text: 'VV - Video Vigilancia' },
  { value: 'DI', text: 'DI - División Industrial' },
  { value: 'HZ', text: 'HZ - Horizontales' },
  { value: 'PA', text: 'PA - Puertas Automáticas' },
  { value: 'PK', text: 'PK - Parking' },
  // { value: 'ING', text: 'ING - Investigación y Desarrollo' },
  { value: 'TREA', text: 'TREA' },
  { value: 'CM', text: 'CM - Contratos Mantenimiento' }
]

export { businessUnits, businessUnits2 }
