<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">5</span>
            <span class="step-title">Automatización</span>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center" class="mt-3">
        <b-card class="lead fs-15 w-100">
          <b-container>
            <b-row>
              <b-col cols="8">
                <b-row>
                  <span>Peso aproximado:</span>
                </b-row>
                <b-row>
                  <span>Largo requerido ({{ cortinaDesnivel }}):</span>
                </b-row>
                <b-row>
                  <span>Ciclos requeridos:</span>
                </b-row>
              </b-col>
              <b-col cols="4">
                <b-row>
                  <span v-if="cortina.peso_aproximado">{{ cortina.peso_aproximado }} kg</span>
                  <span v-else class="text-danger">Pend.</span>
                </b-row>
                <b-row>
                  <span v-if="cortina.calc_hp">{{ (cortina.calc_hp / 100).toFixed(2) }} m</span>
                  <span v-else class="text-danger">Pend.</span>
                </b-row>
                <b-row>
                  <span v-if="cortina.calc_cycles">{{ cortina.calc_cycles }}</span>
                  <span v-else class="text-danger">Pend.</span>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-row>

      <b-row align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <b-row align-h="between" class="mt-5">
            <div>
              <BlankIcon :field="cortina.motor_id" />
              <label class="outer-label">Motor:</label>
            </div>
            <Button variant="blue" @click="$store.commit('setCurrentStep', 'motorSelector')">
              Selector
            </Button>
          </b-row>
          <b-row class="mt-3">
            <div class="showcase w-100">
              <div class="showcase-title">Motor Seleccionado:</div>
              <div v-if="cortina.motor_id">
                <b-row align-h="center" class="pb-2">
                  <div class="showcase-label">
                    {{ cortina.motor.item_code }}
                  </div>
                </b-row>
                <b-row align-h="center">
                  <b-img v-if="cortina.motor_cover_sample_url" :src="cortina.motor_cover_sample_url" />
                  <div v-else class="d-flex align-items-center justify-content-center" style="width: 100px; height 50px">
                    <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-muted" fixed-width />
                  </div>
                </b-row>
              </div>
              <b-row v-else align-h="center">
                <p class="pt-5">Pend.</p>
              </b-row>
            </div>
          </b-row>
          <AlarmMessage field="motor" />

          <ManualMotorNotice />

          <!-- Motor posición -->
          <b-row v-if="ready" class="mt-5">
            <b-form-group label-class="outer-label">
              <template #label>
                <BlankIcon :field="form.motor_posicion" />
                <span>Motor posición</span>
              </template>
              <b-radio-group v-model="form.motor_posicion" :options="motorPosicionOptions" stacked @change="update">
              </b-radio-group>
            </b-form-group>
          </b-row>
          <b-row align-h="center">
            <div class="diagram-container">
              <b-img :src="motorPosicionImg" />
              <div>
                <div v-if="form.motor_posicion === 'Izquierdo en pared'" id="izquierdoenpared" class="motor-symbol"><span>M</span></div>
                <div v-if="form.motor_posicion === 'Izquierdo frente a rollo / tubular'" id="izquierdofrentea" class="motor-symbol"><span>M</span></div>
                <div v-if="form.motor_posicion === 'Centro de eje salida izquierda' || form.motor_posicion === 'Centro de eje salida derecha'" id="centrodeeje" class="motor-symbol"><span>M</span></div>
                <div v-if="form.motor_posicion === 'Derecho frente a rollo / tubular'" id="derechofrentea" class="motor-symbol"><span>M</span></div>
                <div v-if="form.motor_posicion === 'Derecho en pared'" id="derechoenpared" class="motor-symbol"><span>M</span></div>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <small class="text-muted">
              * Vista desde lado que se instala *
            </small>
          </b-row>
          <AlarmMessage field="motor_posicion" />

          <!-- Notes automation -->
          <b-row v-if="ready" class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_automation" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AlarmMessage from '../AlarmMessage'
import BlankIcon from '../BlankIcon'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
import ManualMotorNotice from '../ManualMotorNotice'
import Button from '../../../shared/Button'
import MotorPosicion from 'images/cortina/motor-posicion.png'

export default {
  name: 'CortinaAutomation',
  components: { AlarmMessage, BlankIcon, ConfiguratorStepButtons, ManualMotorNotice, Button },
  data () {
    return {
      form: {
        motor_posicion: null,
        notes_automation: ''
      },
      motorPosicionOptions: [
        'Izquierdo en pared',
        'Izquierdo frente a rollo / tubular',
        'Centro de eje salida izquierda',
        'Centro de eje salida derecha',
        'Derecho frente a rollo / tubular',
        'Derecho en pared'
      ],
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId']),
    motorPosicionImg () { return MotorPosicion },
    cortinaDesnivel () {
      if (this.cortina) {
        if (this.cortina.desnivel === 'Plano') {
          return 'H'
        } else if (this.cortina.desnivel === 'Desnivel izquierdo') {
          return 'Hi'
        } else {
          return 'Hd'
        }
      }
    }
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.motor_posicion = this.cortina.motor_posicion
    this.form.notes_automation = this.cortina.notes_automation
    this.ready = true
  },
  methods: {
    update () {
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: this.form
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    }
  }
}
</script>
