<template>
  <div>
    <b-row align-h="center" class="mt-4 mx-0">
      <b-col lg="12">
        <Subheader title="Roles & Permisos" />
      </b-col>
    </b-row>

    <b-container class="fs-14">
      <b-row>
        <b-col v-if="!loading && roles.length > 0">
          <b-card no-body>
            <b-tabs
              v-model="index"
              pills
              card
              vertical
              @activate-tab="updateSelectedRole"
            >
              <b-tab
                v-for="role in roles"
                :key="role.id"
                :title="role.name"
                @click="fetchRoleUsers(role.id)"
              >
                <b-container fluid>
                  <b-row align-v="center" class="mb-3">
                    <b-col cols="10" class="text-center text-lg-left fs-18 pl-0">
                      {{ role.name }}
                    </b-col>
                    <b-col cols="2" class="text-right pr-0">
                      <Button size="sm" class="text-success" @click="modalShow = true">
                        <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                        <span class="pl-1 d-none d-lg-block">Usuario</span>
                      </Button>
                    </b-col>
                  </b-row>
                </b-container>
                <span v-if="selectedRole.name  == 'TKM - Asesores'" class="text-info">
                  Nota: Si el botón para asignar el usuario se encuentra deshabilitado debe agregar primero el número de empleado de SAP
                </span>
                <span v-if="selectedRole.name  == 'Ventas - Asesores'" class="text-info">
                  Nota: Si el botón para asignar el usuario se encuentra deshabilitado debe agregar primero el código de vendedor de SAP
                </span>
                <span v-if="selectedRole.name  == 'ATK - asesores'" class="text-info">
                  Nota: Solo para asesores de ATK con perfil de distribuidor o instalador. Permite acceso a rutas de producción
                </span>
                <div class="py-2">
                  <b-form-input type="search" size="sm" v-model="searchUserTerm" :disabled="roleUsers.length == 0 && !searchUserTerm" placeholder="Buscar por nombre"></b-form-input>
                </div>
                <div style="height: 75vh">
                  <b-table
                    v-if="!fetchingUsers"
                    :items="roleUsers"
                    :fields="fields"
                    primary-key="id"
                    head-variant="light"
                    small
                    sticky-header="75vh"
                    class="border-bottom"
                    show-empty
                  >
                    <template #empty>
                      <div v-if="loading" class="text-center text-info">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="pl-2">Cargando...</span>
                      </div>
                      <div v-else class="text-center text-danger">
                        <span>Rol sin usuarios asignados</span>
                      </div>
                    </template>
                    <template #cell(first_name)="data">
                      {{ data.value | titleize }}
                    </template>
                    <template #cell(last_name)="data">
                      {{ data.value | titleize }}
                    </template>
                    <template #cell(actions)="data">
                      <b-link class="delete-icon" @click="removeUser(role.id, data.item.id)">
                        <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
                      </b-link>
                    </template>
                  </b-table>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col v-else>
          <b-card>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span class="pl-2">No hay roles para mostrar</span>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- Modal for adding users to role -->
      <b-modal v-model="modalShow" hide-footer @hide="onModalHide">
        <template #modal-title>
          <span v-if="selectedRole">
            Asignar rol de {{ selectedRole.name }}
          </span>
        </template>
        <b-container>
          <b-row>
            <b-input-group size="sm">
              <b-input
                v-model="searchTerm"
                placeholder="Ingresar nombre"
                ref="searchBar"
                autofocus
                @click="$nextTick(() => $refs.searchBar.select())"
                @keyup.enter="searchUser"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" size="sm" @click="searchUser">
                  <font-awesome-icon v-if="searching" :icon="['fas', 'spinner']" pulse fixed-width />
                  <font-awesome-icon v-else :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
          <b-row>
            <b-table-simple class="border-bottom">
              <b-tbody>
                <b-tr v-for="user in searchResults" :key="user.id">
                  <b-td>
                    {{ user.first_name }} {{ user.second_name }} {{ user.last_name }} {{ user.last_name2 }}
                  </b-td>
                  <b-td style="width: 80px">
                    <Button size="sm" variant="green" :disabled="invalidUserOnAdd(user)" @click="addUser(user)">
                      <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                      <span class="pl-1">Asignar</span>
                    </Button>
                  </b-td>
                </b-tr>
                <b-tr v-if="searchResults.length === 0 && searchDone">
                  <b-td class="text-center text-danger">
                    No resultados
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
        </b-container>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { normalize, schema } from 'normalizr'
import Button from './shared/Button'
import Subheader from './shared/Subheader'

export default {
  name: 'AppRoles',
  components: { Button, Subheader },
  data () {
    return {
      roleData: {},
      roleList: [],
      selectedRole: null,
      selectedRoleUsers: [],
      modalShow: false,
      searchTerm: '',
      searchResults: [],
      searchUserTerm: '',
      searchDone: false,
      form: {
        user_id: null
      },
      index: 0,
      fields: [
        { key: 'first_name', label: 'Nombre', tdClass: 'align-middle' },
        { key: 'last_name', label: 'Apellido', tdClass: 'align-middle' },
        { key: 'actions', label: '', tdClass: 'align-middle text-right' }
      ],
      loading: true,
      processing: false,
      searching: false,
      fetchingUsers: false
    }
  },
  computed: {
    ...mapGetters(['isSuperAdmin']),
    roles () {
      return this.roleList.map(id => this.roleData[id]).sort((a, b) => a.name - b.name)
    },
    selectedRoleUsersIds () {
      return this.selectedRoleUsers.map(user => user.id)
    },
    roleUsers () {
      return this.selectedRoleUsers.filter(u => {
        if (u.comp_type != 'placeholder') {
          const name = `${u.first_name.toLowerCase()} ${u.last_name?.toLowerCase()} ${u.last_name2?.toLowerCase()}`
          return name.includes(this.searchUserTerm.toLowerCase())
        }
      })
    }
  },
  mounted () {
    if (this.isSuperAdmin) {
      this.fetchRoles()
    } else {
      this.$router.replace('/')
    }
  },
  methods: {
    fetchRoles () {
      this.$http.get('/api/roles')
        .then((res) => {
          console.log(res)
          const data = normalize(
            { roles: res.data.roles },
            { roles: [ new schema.Entity('roles') ] }
          )
          if (data.entities.hasOwnProperty('roles')) {
            this.roleData = data.entities.roles
          }
          this.roleList = data.result.roles
          this.selectedRole = this.roles[0]
          this.selectedRoleUsers = res.data.users
          this.loading = false
        })
        .catch(e => console.log(e))
    },
    updateSelectedRole (newTabIndex) {
      this.selectedRole = this.roles[newTabIndex]
    },
    fetchRoleUsers (roleId) {
      this.fetchingUsers = true
      this.$http.get(`/api/roles/${roleId}/users`)
        .then(res => this.selectedRoleUsers = res.data)
        .catch(e => console.log(e))
        .finally(() => {
          this.searchUserTerm = ''
          this.fetchingUsers = false
        })
    },
    searchUser () {
      if (this.searching || !this.searchTerm) { return }
      this.searching = true
      this.searchDone = false
      this.$http.get('/api/human_resources/users/search', {
        params: {
          q: this.searchTerm,
          current_user_ids: this.selectedRoleUsersIds
        }
      })
        .then((res) => {
          console.log('Search results:')
          console.log(res.data)
          this.searchResults = res.data
          this.searchDone = true
        })
        .catch(e => console.log(e))
        .finally(this.searching = false)
    },
    addUser (user) {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/roles/${this.selectedRole.id}/add_user`, {
        user_id: user.id
      })
        .then(res => {
          this.selectedRoleUsers.push(res.data)
          this.modalShow = false
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    removeUser (roleId, userId) {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/roles/${roleId}/remove_user`, {
        params: { user_id: userId }
      })
        .then(res => this.selectedRoleUsers = this.selectedRoleUsers.filter(u => u.id != res.data.id))
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    onModalHide () {
      this.searchTerm = ''
      this.searchResults = []
      this.searchDone = false
    },
    invalidUserOnAdd(user) {
      if (this.selectedRole.name  == 'TKM - Asesores') {
        return user.sap_employee_id ? false : true
      } else if (this.selectedRole.name  == 'Ventas - Asesores') {
        return user.sap_sales_person_code ? false : true
      } else if (this.selectedRole.name  == 'ATK - asesores') {
        const allowedRoles = ['distribuidor', 'instalador']
        return allowedRoles.includes(user.role) ? false : true
      }
      return false
    }
  }
}
</script>
