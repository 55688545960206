<template>
  <b-row align-h="center" class="mx-0" style="margin-top: 80px">
    <b-col lg="10">
      <SectionCard class="mx-auto">
        <SectionCardTitle>
          <font-awesome-icon :icon="['far', 'warehouse']" size="lg" fixed-width />
          Producción
        </SectionCardTitle>

        <b-row>
          <b-col lg="6" cols="12"  order="2" order-lg="1">
            <b-row align-h="around" class="px-2">
              <b-col cols="4">
                <b-card header="Clientes">
                  <b-row align-h="around">
                    <div class="tile">
                      <b-link to="/app/prod/clientes">
                        <font-awesome-icon :icon="['far', 'address-book']" size="lg" />
                        <span>Clientes</span>
                      </b-link>
                    </div>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="4">
                <b-card header="Compras">
                  <b-row align-h="around">
                    <div class="tile">
                      <b-link :disabled="currentUser.role == 'non_prod'" to="/app/prod/solicitud">
                        <font-awesome-icon :icon="['fas', 'hand-holding-box']" size="lg" />
                        <span>SOC</span>
                      </b-link>
                    </div>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="4">
                <b-card header="Servicio">
                  <b-row align-h="around">
                    <div class="tile">
                      <b-link :disabled="currentUser.role == 'non_prod'" @click="enterServiceModule">
                        <font-awesome-icon :icon="['fas', 'wrench']" size="lg" />
                        <span>Pizarra</span>
                        <span>Técnicas</span>
                      </b-link>
                    </div>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card header="Ventas">
                  <b-row align-h="around" class="px-2">
                    <div class="tile">
                      <b-link :disabled="currentUser.role == 'non_prod'" to="/app/prod/proyectos">
                        <font-awesome-icon :icon="['far', 'briefcase']" size="lg" />
                        <span>Proyectos</span>
                      </b-link>
                    </div>
                    <div class="tile">
                      <b-link :disabled="currentUser.role == 'non_prod'" to="/app/prod/cotizador">
                        <font-awesome-icon :icon="['fas', 'calculator']" size="lg" />
                        <span>Cotizador</span>
                      </b-link>
                    </div>
                    <div class="tile">
                      <b-link href="https://venreg.herokuapp.com/">
                        <font-awesome-icon :icon="['far', 'file-alt']" size="lg" />
                        <span>Ven Reg 01</span>
                      </b-link>
                    </div>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" cols="12" order="1" order-lg="2">
            <RecentVisitsCard />
          </b-col>
        </b-row>

        <b-card header="Accesos">
          <b-row align-h="around" class="px-2">
            <div v-if="canViewAccess('Corredizo')" class="tile">
              <b-link to="/app/prod/accesos/corredizos">
                <img :src="corredizoImg">
                <span>Corredizos</span>
              </b-link>
            </div>
            <div v-if="canViewAccess('Abatible')" class="tile">
              <b-link to="/app/prod/accesos/abatibles">
                <img :src="abatibleImg">
                <span>Abatibles</span>
              </b-link>
            </div>
            <div v-if="canViewAccess('Seccional')" class="tile">
              <b-link to="/app/prod/accesos/seccionales">
                <img :src="seccionalImg">
                <span>Seccionales</span>
              </b-link>
            </div>
            <!-- Deprecate -->
            <div v-if="canViewAccess('Cortina')" class="tile">
              <b-link to="/app/prod/accesos/cortinas">
                <img :src="cortinaImg">
                <span>Cortinas</span>
              </b-link>
            </div>
            <!-- NEXT -->
            <!-- <div v-if="canViewAccess('Cortina')" class="tile">
              <b-link to="/app/prod/submitals/index/cortinas">
                <img :src="cortinaImg">
                <span>Cortinas</span>
              </b-link>
            </div> -->
          </b-row>
        </b-card>

        <b-card header="Catálogo">
          <b-row align-h="around" class="px-2">
            <div class="tile">
              <b-link to="/app/prod/inventario">
                <font-awesome-icon :icon="['far', 'warehouse-alt']" size="lg" />
                <span>Inventario</span>
              </b-link>
            </div>
            <div class="tile">
              <a href="/designs" data-turbolinks="false">
                <font-awesome-icon :icon="['far', 'warehouse']" size="lg" />
                <span>Diseños</span>
              </a>
            </div>
            <div class="tile">
              <a href="/motors" data-turbolinks="false">
                <font-awesome-icon :icon="['fas', 'cogs']" size="lg" />
                <span>Motores</span>
              </a>
            </div>
            <div class="tile">
              <a href="/colors" data-turbolinks="false">
                <font-awesome-icon :icon="['far', 'palette']" size="lg" />
                <span>Colores</span>
              </a>
            </div>
          </b-row>
        </b-card>
      </SectionCard>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SectionCard from '../shared/SectionCard'
import SectionCardTitle from '../shared/SectionCardTitle'
import RecentVisitsCard from '../shared/RecentVisitsCard'
import CorredizoImg from 'images/corredizo.png'
import AbatibleImg from 'images/abatible.png'
import SeccionalImg from 'images/seccional.png'
import CortinaImg from 'images/cortina.png'

export default {
  name: 'ProductionHome',
  components: { RecentVisitsCard, SectionCard, SectionCardTitle },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser
    }),
    ...mapGetters (['isDev', 'canViewAccess']),
    corredizoImg () { return CorredizoImg },
    abatibleImg () { return AbatibleImg },
    seccionalImg () { return SeccionalImg },
    cortinaImg () { return CortinaImg }
  },
  methods: {
    enterServiceModule () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/service/tech_boards/verify_user')
        .then((res) => {
          console.log(res)
          if (res.data && res.data.length === 1) {
            window.location.href = `/servicio/pizarras/${res.data[0].id}`
          } else {
            window.location.href = '/servicio/pizarras'
          }
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 2em;
}
.card-header {
  color: #f0eaea;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 370;
  padding: 0.25rem 1.25rem;
  background-color: #163D7D;
}
.card-body {
  padding: 0;
}
</style>
