<template>
  <div>
    <b-row align-h="center" class="mt-3 mx-0" style="margin-bottom: 2.5em">
      <span class="font-lato fs-20">Centro de Distribución</span>
    </b-row>
    <b-row align-h="center" class="mt-5 mx-0">
      <b-col lg="3" cols="12" class="border-right">
        <ul style="list-style: none">
          <li v-for="user in distributors" :key="user.id" @click="selectedDist = user; fetchQuotes(user.id)">
            {{ user | concatName4 }}
          </li>
        </ul>
      </b-col>
      <b-col lg="9" cols="11">
        <b-table>

        </b-table>
      </b-col>
    </b-row>

    <b-row align-h="end" class="mx-0 px-3">
      <b-col lg="4">
        <b-card title="Reporte CSV de Cotizacíón" title-tag="p" class="report-card">
          <hr>
          <b-card-text class="fs-14">
            <table class="table table-borderless">
              <tbody>
                <!-- <tr>
                  <td>Datos:</td>
                  <td>Los saldos de los empleados activos en salario, filtrados por departamento si se desea</td>
                </tr> -->
                <!-- <tr>
                  <td>Columnas:</td>
                  <td>Carnet, Empleado, Días acumulados, Días tomados, Saldo</td>
                </tr> -->
                <tr>
                  <td>Archivo:</td>
                  <td>.csv</td>
                </tr>
              </tbody>
            </table>
          </b-card-text>
          <hr>
          <b-card-text class="fs-14">
            <b-row align-h="center">
              <b-form-group style="width: 300px">
                <template #label>
                  <small class="text-info">Filtrar por distribuidor:</small>
                </template>
                <b-select v-model="distributorId" size="sm">
                  <option :value="null">Todos</option>
                  <option v-for="distributor in distributors" :value="distributor.id" :key="distributor.id">
                    {{ distributor.first_name + ' ' + distributor.last_name }}
                  </option>
                </b-select>
              </b-form-group>
            </b-row>
            <b-row align-h="center" class="mt-5">
              <Button @click="fetchQuoteActivity">
                <font-awesome-icon :icon="['far', 'arrow-alt-to-bottom']" fixed-width />
                <span class="pl-2">Descargar</span>
              </Button>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import Button from '../shared/Button';

export default {
  name: 'Distribution',
  components: { Button },
  data() {
    return {
      distributorId: null,
      distributors: [],
      selectedDist: null,
      quotes: [],
      page: 1,
      next: null,
      prev: null,
      count: null,
      searchTerm: '',
      queryMode: false,
      sortBy: 'created_at',
      sortDesc: true,
      scope: 'for_distributor',
      hideIgnored: true,
      hideClosed: true,
      distributorFields: [
        { key: 'user', label: 'Asesor', tdClass: 'clickable', thStyle: { width: '150px' } },
        { key: 'quote_id', label: 'ID', tdClass: 'clickable', thStyle: { width: '150px' } },
        { key: 'title', label: 'Titulo', tdClass: 'clickable' },
        { key: 'distributor_client', label: 'Cliente Dist.', tdClass: 'clickable', thStyle: { width: '300px' } },
        { key: 'created_at', label: 'Creado en', tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '100px' } }
      ],
      loading: false,
      processing: false,
      noScroll: false
    }
  },
  computed: {
    ...mapGetters(['isDev'])
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http.get('/api/contractors/distribution_home')
        .then(response => {
          console.log(response)
          this.distributors = response.data.distributors;
        })
    },
    fetchPaginatedQuotes() {
      this.loading = true;
      this.$http.get('/api/production/quotes', {
          params: {
            page: 1,
            q: this.searchTerm,
            // hide_ignored: this.hideIgnored,
            // hide_closed: this.hideClosed,
            // sort_by: this.sortBy,
            // sort_desc: this.sortDesc
          }
        })
        .then(response => {
          console.log(response)
          this.quotes = response.data.quotes;
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.pagy.count;
          if (!this.noScroll) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.noScroll = false;
        });
    },
    onRowClick(item) {
      // this.$router.push(`/app/dist/cotizador/${item.id}`);
    },
    fetchQuotes(distributorId) {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('')
    },
    fetchQuoteActivity() {
      this.$http.get('/api/contractors/reports/quote_activity', {
          params: {
            distributor_id: this.distributorId
          }
        })
        .then(response => {
          console.log(response)
          const date = format(new Date, 'd-M-yyyy')

          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          if (this.department) {
            link.download = `Actividad_Cotizacion_${this.distributor}_${date}.csv`;
          } else {
            link.download = `Actividad_Cotizacion_todos_${date}.csv`;
          }
          link.click()
        })
    }
  }
}
</script>

<style scoped>
/* Report card is duplicate.  Add to global styles? */
.report-card {
  width: 100%;
  margin-bottom: 3em;
}
.card-title {
  font-size: 18px;
  margin-bottom: 1em;
}
</style>
