<template>
  <div>
    <SubmitalHeader :submital="submital" page="submital" />

    <ContentView>
      <b-row class="pt-3">
        <b-col lg="6">
          <div class="section-title">Resumen:</div>
          <b-row v-if="ready" align-h="center" class="acceso-info mb-3">
            <b-col cols="11" offset="1">
              <b-table-simple borderless small style="width: 300px">
                <b-tbody>
                  <b-tr>
                    <b-th>Cantidad</b-th>
                    <b-td>{{ submital.quantity }}</b-td>
                  </b-tr>
                  <b-tr v-if="configurable">
                    <b-th>Ancho</b-th>
                    <b-td v-if="configurable.dim_a">
                      {{ configurable.dim_a }} cm
                    </b-td>
                  </b-tr>
                  <b-tr v-if="configurable">
                    <b-th>Alto</b-th>
                    <b-td v-if="configurableHeight">
                      {{ configurableHeight }} cm
                    </b-td>
                  </b-tr>
                  <b-tr v-if="configurable">
                    <b-th>Peso aproximado</b-th>
                    <b-td v-if="configurable.peso_aproximado">
                      {{ configurable.peso_aproximado }} kg
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" offset-lg="1">
          <!-- Quotes -->
          <b-row align-h="between" class="px-3">
            <div class='section-title'>Cotización:</div>
            <div v-if="configurable && configurable.motor_id">
              <div id="generate-button-info" class="clickable text-info d-inline ml-3">
                <font-awesome-icon :icon="['far', 'question-circle']" fixed-width />
              </div>
              <b-popover target="generate-button-info" placement="bottom" triggers="hover click">
                <span class="text-info">Genera una nueva cotizacíon basada en el motor seleccionado</span>
              </b-popover>
              <Button size="sm" variant="outline-success" @click="createQuote">
                Generar cotización
              </Button>
            </div>
          </b-row>
          <b-row v-if="quotes.length > 0" class="overflow-auto" style="height: 120px">
            <b-col>
              <b-list-group class="w-100 fs-13">
                <b-list-group-item v-for="quote in sortedQuotes" :key="quote.id" :to="`/app/prod/cotizador/${quote.id}`" class="py-2">
                  <span class="font-weight-bold">{{ quote.quote_id }}:</span><span class="pl-2">{{ quote.title }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col lg="6">
          <div class="section-title">Configurador:</div>
          <nav>
            <ul class="border-top" style="list-style: none; padding: 0">
              <SubmitalShowStepLink
                v-for="step in configuratorSteps"
                :step="step"
                :key="step.en"
                @step-link-clicked="goToConfiguratorStep"
              />
            </ul>
          </nav>
        </b-col>
        <b-col lg="5" offset-lg="1" class="font-family: 'Gill Sans', sans-serif">
          <div class="section-title">Reportes PDF:</div>
          <b-row align-h="center" align-v="center">
            <b-col cols="6">
              <b-link class="my-button w-100" @click="downloadSubmital">
                <font-awesome-icon :icon="['far', 'file-pdf']" size="lg" fixed-width />
                <span>Submital</span>
              </b-link>
            </b-col>
            <b-col cols="4">
              <span v-if="ready && submital.progress == 100" class="text-success">Listo</span>
              <span v-else class="text-danger">Pendiente</span>
            </b-col>
            <b-col cols="2">
              <div class="email-toggle" @click="submitalEmailInputShow = !submitalEmailInputShow">
                <font-awesome-icon :icon="['far', 'envelope']" size="lg" fixed-width />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="submitalEmailInputShow" align-h="end">
            <b-col>
              <b-input-group class="mt-3">
                <!-- TODO: Email validation -->
                <b-input v-model="submitalEmailText" size="sm" placeholder="Email" autofocus />
                <b-input-group-append>
                  <b-button :disabled="!submitalEmailText" size="sm" variant="primary" @click="emailSubmital">
                    Enviar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <div v-if="ready" class="mt-5">
            <div class="section-title">Alarmas y Avisos:</div>
            <b-row class="alarms-list" align-h="center">
              <b-col cols="11" class="pr-0">
                <!-- TODO: don't forget lead alarm! -->
                <b-row v-for="warning in submital.warnings" :key="warning" class="text-warning">
                  <p>
                    <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                    <span>{{ warning }}</span>
                  </p>
                </b-row>
                <div v-for="(stepData, stepName) in submital.alarm_data" :key="stepName">
                  <div v-for="(alarmObj, index) in stepData.alarms" :key="index">
                    <b-row>
                      <b-link class="text-danger" @click="goToConfiguratorStep(stepName)">
                        <font-awesome-icon :icon="['far', 'ban']" fixed-width />
                        <span>{{ Object.values(alarmObj)[0] }}</span>
                      </b-link>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </ContentView>

    <ConfiguratorModal v-if="submital" :submital="submital" />

    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { addDays } from 'date-fns'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import ToastAlert from '../shared/ToastAlert'
import SubmitalHeader from './SubmitalHeader'
import SubmitalShowStepLink from './SubmitalShowStepLink'
import ConfiguratorModal from './configurators/ConfiguratorModal'

export default {
  name: 'SubmitalShow',
  components: {
    Button,
    ContentView,
    ToastAlert,
    SubmitalHeader,
    SubmitalShowStepLink,
    ConfiguratorModal
  },
  data () {
    return {
      id: Number(this.$route.params.id),
      quotes: [],
      submitalEmailInputShow: false,
      submitalEmailText: '',
      ready: false,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  computed: {
    ...mapState ({
      submital: state => state.configurator.submital,
      configurable: state => state.configurator.configurable
    }),
    ...mapGetters ([
      'configuratorSteps',
      'configurableType',
      'isCortina',
      'isSeccional',
      'isCorredizo',
      'isAbatible'
    ]),
    configurableHeight () {
      let height
      if (!this.configurable) { return }
      if (this.isSeccional || this.isCortina) {
        if (this.configurable.desnivel == 'Plano') {
          height = this.configurable.dim_h
        } else if (this.configurable.desnivel == 'Desnivel izquierdo') {
          height = this.configurable.dim_hi
        } else {
          height = this.configurable.dim_hd
        }
      } else {
        if (this.configurable.dim_hi && this.configurable.dim_hd) {
          height = this.configurable.dim_hi > this.configurable.dim_hd ? this.configurable.dim_hi : this.configurable.dim_hd
        }
      }
      return height
    },
    sortedQuotes () {
      return this.quotes.sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
    }
  },
  mounted () {
    this.fetchSubmital()
  },
  methods: {
    fetchSubmital () {
      this.ready = false
      this.$http.get(`/api/production/submitals/${this.id}`)
        .then((res) => {
          const { configurable, quotes, ...submital } = res.data
          this.$store.commit('setConfigurable', configurable)
          this.$store.commit('setSubmital', submital)
          this.quotes = quotes
          this.ready = true
        })
        .catch(e => console.log(e))
    },
    goToConfiguratorStep (step) {
      if (typeof step === 'object' && step !== null) {
        this.$store.commit('setCurrentStep', step.en)
      } else {
        this.$store.commit('setCurrentStep', step)
      }
      this.$bvModal.show('configurator-modal')
    },
    openConfiguratorModal () {
      this.$bvModal.show('configurator-modal')
    },
    createQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post('/api/production/quotes', {
          quote: {
            user_id: this.submital.salesperson_id,
            client_id: this.submital.client_id,
            project_id: this.submital.project_id,
            submital_id: this.submital.id,
            business_unit: this.isCorredizo || this.isAbatible ? 'HZ' : 'DI',
            valid_until: addDays(new Date, 15),
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          },
          motor_id: this.configurable ? this.configurable.motor_id : null
        })
        .then((res) => {
          // this.$router.push(`/app/prod/cotizador/${res.data.id}`)
          this.quotes.unshift(res.data)
          this.alertVariant = 'success'
          this.alertMessage = `Cotización ${res.data.quote_id} se ha creado con éxito`
          this.$http.get(`/api/production/quotes/${res.data.id}/generate`)
        })
        .catch((e) => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(() => {
          this.alertShow = true
          this.processing = false
        })
    },
    downloadSubmital () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/submitals/${this.id}/download`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        },
        responseType: 'blob',
        params: {
          report_type: 'submital'
        }
      })
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          // Open in browser window
          // const fileUrl = window.URL.createObjectURL(blob)
          // window.open(fileUrl)

          // Download link
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Submital #${this.submital.submital_no}.pdf`
          link.click()
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    emailSubmital () {
      if (this.processing) return
      this.processing = true
      this.$http.get(`/api/production/submitals/${this.id}/email`, {
        params: {
          report_type: 'submital',
          email: this.submitalEmailText
        }
      })
        .then((res) => {
          console.log('receiving response from email route')
          console.log(res.data)
          this.submitalEmailInputShow = false
          this.submitalEmailText = ''
          // TODO: Flash alert of email being sent
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    }
  }
}
</script>
