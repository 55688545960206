import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["thumbnail", "categoryBox", "categoryRadio"];

  select(event) {
    var radio = document.getElementById("radio-" + event.currentTarget.id);
    radio.checked = true;
  }

  toggleCategory(event) {  // rename to toggleByIdFirstChar
    var checkbox = event.currentTarget;
    checkbox.classList.toggle('checked');
    var category = checkbox.textContent.charAt(0);
    this.thumbnailTargets.forEach(el => {
      if (checkbox.classList.contains('checked')) {
        if (el.id.charAt(0) == category) {
          el.classList.add('selected');
        }
      } else {
        if (el.id.charAt(0) == category) {
          el.classList.remove('selected');
        }
      }
    })
    this.filter();
  }
  
  // Used in Cortinas
  toggleByClass(event) {
    var checkbox = event.currentTarget;
    checkbox.classList.toggle('checked');
    var category = checkbox.textContent.toLowerCase();
    this.thumbnailTargets.forEach(el => {
      if (checkbox.classList.contains('checked')) {
        if (el.classList.contains(category)) {
          el.classList.add('selected');
        }
      } else {
        if (el.classList.contains(category)) {
          el.classList.remove('selected');
        }
      }
    })
    this.filter();
  }

  toggleSection(event) {
    var chosenSection = document.getElementById(event.currentTarget.value);
    chosenSection.classList.remove('d-none');
    var siblings = Array.prototype.filter.call(chosenSection.parentNode.children, function(child) {
      return child !== chosenSection;
    });
    siblings.forEach(el => {
      el.classList.add('d-none');
    });
  }

  filter() {
    if (this.categoryBoxTargets.every(this.isUnchecked)) {
      this.thumbnailTargets.forEach(el => {
        el.classList.remove('d-none');
      }) 
    } else {
      // works
      this.thumbnailTargets.forEach(el => {
        if (el.classList.contains('selected')) {
          el.classList.remove('d-none');
        } else {
          el.classList.add('d-none');
        }
      })
    }
  }

  isUnchecked(el) {
    return !el.classList.contains('checked');
  }
}

