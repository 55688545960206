import Vue from 'vue';

Vue.filter('concatName3', function (obj) {
  if (obj) {
    let name = obj.first_name + ' ' + obj.last_name;
    if (obj.last_name2) {
      name = name + ' ' + obj.last_name2;
    }
    return name;
  }
})
