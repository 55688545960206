<template>
  <div>
    <NavTabs />
    <ContentView>
      <Subheader title="Reportes de RRHH" />
      <b-row v-if="isManager || isHrAdmin || isDev" align-h="center" class="mt-5">
        <b-col lg="8" cols="11">
          <b-row>
            <b-card title="Saldos de vacaciones" title-tag="p" class="report-card">
              <hr>
              <b-card-text class="fs-14">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <td>Datos:</td>
                      <td>Los saldos de los empleados activos en salario, filtrados por departamento si se desea</td>
                    </tr>
                    <tr>
                      <td>Columnas:</td>
                      <td>Carnet, Empleado, Departamento, Gerencia, Días acumulados, Días disfrutados, Días restados, Saldo</td>
                    </tr>
                    <tr>
                      <td>Archivo:</td>
                      <td>.csv</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
              <hr>
              <b-card-text class="fs-14">
                <b-row align-h="center">
                  <b-form-group style="width: 300px">
                    <template #label>
                      <small class="text-info">Filtrar por departamento:</small>
                    </template>
                    <b-select v-model="department" :options="deptOptions" size="sm">
                      <template #first>
                        <b-select-option :value="null">Todos</b-select-option>
                      </template>
                    </b-select>
                  </b-form-group>
                </b-row>
                <b-row align-h="center" class="mt-5">
                  <Button @click="fetchVacationBalanceReport">
                    <font-awesome-icon :icon="['far', 'arrow-alt-to-bottom']" fixed-width />
                    <span class="pl-2">Descargar</span>
                  </Button>
                </b-row>
              </b-card-text>
            </b-card>
          </b-row>

          <b-row>
            <b-card title="Días disfrutados en rango de fechas" title-tag="p" class="report-card">
              <hr>
              <b-card-text class="fs-14">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <td>Datos:</td>
                      <td>Empleados con días disfrutados de vacaciones en un rango seleccionado de fechas, filtrados por tipo de planilla.  Incluye días en el futuro que no fueron disfrutados todavía pero fueron aprobados en una solicitud.</td>
                    </tr>
                    <tr>
                      <td>Columnas:</td>
                    <td>Carnet, Cedula, Nombre Colaborador, Departamento, Mes, De, Hasta, Cantidad Días, # Boleta</td>
                    </tr>
                    <tr>
                      <td>Archivo:</td>
                      <td>.csv</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
              <hr>
              <b-row align-h="center">
                <small class="text-info"><i>Marcar el rango de las fechas en el calendario:</i></small>
              </b-row>
              <b-row align-h="center">
                <flat-pickr
                  v-model="dateRange"
                  :config="flatpickrConfig"
                  class="d-none"
                  name="dateRange"
                ></flat-pickr>
              </b-row>
              <b-row align-h="center" class="mt-3">
                <b-input-group size="sm" style="width: 300px;">
                  <b-input readonly :value="formattedDateRange" class="text-success" />
                  <b-input-group-append>
                    <b-button size="sm" :disabled="!dateRange" @click="dateRange = ''">Reset</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-row>
              <b-row align-h="center" class="mt-3">
                <b-form-group style="width: 300px">
                  <template #label>
                    <small class="text-info">Filtrar por tipo de planilla:</small>
                  </template>
                  <b-select v-model="payrollType" :options="payrollTypeOptions" size="sm" />
                </b-form-group>
              </b-row>
              <b-row align-h="center" class="mt-5">
                <Button :disabled="dateRange.length < 12" @click="fetchVacationRangeReport">
                  <font-awesome-icon :icon="['far', 'arrow-alt-to-bottom']" fixed-width />
                  <span class="pl-2">Descargar</span>
                </Button>
              </b-row>
            </b-card>
          </b-row>
          <!-- Warning about accents -->
          <!-- <b-row align-h="center">
            <div class="font-italic font-weight-light text-muted">
              <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
              <small>Por ahora acentos no aparecen en las tablas</small>
            </div>
          </b-row> -->
          <b-row>
            <b-card title="Boletas de vacaciones" title-tag="p" class="report-card">
              <hr>
              <b-card-text class="fs-14">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <td>Datos:</td>
                      <td>Las boletas de vacaciones, filtradas por status o departamento si se desea</td>
                    </tr>
                    <tr>
                      <td>Columnas:</td>
                      <td>No. Boleta, Status, Empleado, Departamento</td>
                    </tr>
                    <tr>
                      <td>Archivo:</td>
                      <td>.csv</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
              <hr>
              <b-card-text class="fs-14">
                <b-row align-h="center">
                  <b-form-group style="width: 300px">
                    <template #label>
                      <small class="text-info">Filtrar por departamento:</small>
                    </template>
                    <b-select v-model="department2" :options="deptOptions" size="sm">
                      <template #first>
                        <b-select-option :value="null">Todos</b-select-option>
                      </template>
                    </b-select>
                  </b-form-group>
                </b-row>
                <b-row align-h="center">
                  <b-form-group style="width: 300px">
                    <template #label>
                      <small class="text-info">Filtrar por status:</small>
                    </template>
                    <b-select v-model="status" :options="statusOptions" size="sm" />
                  </b-form-group>
                </b-row>
                <b-row align-h="center" class="mt-5">
                  <Button @click="fetchVacationTicketReport">
                    <font-awesome-icon :icon="['far', 'arrow-alt-to-bottom']" fixed-width />
                    <span class="pl-2">Descargar</span>
                  </Button>
                </b-row>
              </b-card-text>
            </b-card>
          </b-row>
        </b-col>
      </b-row>
    </ContentView>
    <!-- Alert -->
      <!-- Also clears dateRange upon close -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false; dateRange = ''">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';
import flatPickr from 'vue-flatpickr-component';
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import Subheader from '../shared/Subheader';
import ToastAlert from '../shared/ToastAlert';
import NavTabs from './NavTabs';
import { departments } from '../../sharedOptions/departments';

export default {
  name: 'Reports',
  components: { Button, ContentView, Subheader, NavTabs, ToastAlert, flatPickr },
  data() {
    return {
      dateRange: '',
      department: null,  // For vacation balance report
      department2: null, // For vacation tickets report
      deptOptions: departments,
      payrollType: 'quincenal',
      payrollTypeOptions: [
        { value: 'quincenal', text: 'Quincenal' },
        { value: 'plr', text: 'PLR' }
      ],
      status: null,
      statusOptions: [
        { value: null, text: 'Pendientes o aprobadas' },
        { value: 'finalizado', text: 'Finalizadas' },
      ],
      flatpickrConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        inline: true,
        mode: "range",
        altInput: true,
        altFormat: "d/m/Y",
        disableMobile: "true"
      },
      //
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
    }
  },
  computed: {
    ...mapGetters(['isManager', 'isHrAdmin', 'isDev']),
    formattedDateRange() {
      if (this.dateRange.length >= 12) {
        let range = this.dateRange.split(' ');
        return format(parseISO(range[0]), 'd-M-yy') + ' a ' + format(parseISO(range[2]), 'd-M-yy');
      } else {
        return '';
      }
    }
  },
  methods: {
    fetchVacationBalanceReport() {
      this.$http.get('/api/human_resources/reports/vacation_balance', {
          params: { department: this.department }
        })
        .then(response => {
          console.log(response)
          const date = format(new Date, 'd-M-yyyy')

          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          if (this.department) {
            link.download = `Vacaciones_Saldos_${this.department}_${date}.csv`;
          } else {
            link.download = `Vacaciones_Saldos_todos_${date}.csv`;
          }
          link.click();
        })
    },
    fetchVacationRangeReport() {
      this.$http.get('/api/human_resources/reports/vacation_range', {
          params: {
            date_range: this.dateRange,
            payroll_type: this.payrollType
          }
        })
        .then(response => {
          console.log(response)

          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Vacaciones_Rango_${this.formattedDateRange}.csv`;
          link.click();

          this.dateRange = '';
        })
        .catch(error => {
          console.log(error.response)
          this.alertShow = true;
          this.alertVariant = 'danger';
          if (error.response.data.error) {
            this.alertMessage = error.response.data.error;
          } else {
            this.alertMessage = 'No funcionó';
          }
        })
    },
    fetchVacationTicketReport() {
      this.$http.get('/api/human_resources/reports/vacation_tickets', {
          params: {
            department: this.department2,
            status: this.status
          }
        })
        .then(response => {
          console.log(response)
          const date = format(new Date, 'd-M-yyyy')

          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          let statusLabel = this.status ? 'finalizadas' : 'pendientes_de_finalizacion';
          let deptLabel = this.department2 ? `_${this.department2}_` : '_';
          link.download = `Boletas_${statusLabel}${deptLabel}${date}.csv`;
          link.click();
        })
    }
  }
}
</script>

<style scoped>
.report-card {
  width: 100%;
  margin-bottom: 3em;
}
.card-title {
  font-size: 18px;
  margin-bottom: 1em;
}
</style>
