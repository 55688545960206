import Vue from 'vue';

Vue.filter('titleizePayrollType', function (value) {
  if (!value) return ''
  value = value.toString()
  if (value === 'plr') {
    return 'PLR'
  } else {
    let words = value.split("_");
    return words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    }).join(' ');
  }
})
