<template>
  <b-row align-h="between" class="custom-tabs mx-0">
    <ul>
      <li>
        <router-link to="/" class="dash-nav-link">
          <font-awesome-icon :icon="['fas', 'home']" fixed-width />
          <span class="pl-1">Inicio</span>
        </router-link>
      </li>
    </ul>
    <ul>
      <li>
        <router-link to="/app/dist/clientes" class="dash-nav-link">
          Mis Clientes
        </router-link>
      </li>
      <li>
        <router-link to="/app/dist/cotizaciones" class="dash-nav-link">Mis Cotizaciones</router-link>
      </li>
    </ul>
  </b-row>
</template>

<script>
export default {
  name: 'DistributorNavTabs'
}
</script>
