<template>
  <li>
    <div class="step-link" @click="$emit('step-link-clicked', step)">
      <b-row align-h="around" align-v="center">
        <div class="step-image">
          <font-awesome-icon v-if="step.en == 'design'" :icon="['far', 'drafting-compass']" size="2x" fixed-width />
          <font-awesome-icon v-else-if="step.en == 'dimensions'" :icon="['far', 'ruler-combined']" size="2x" fixed-width />
          <font-awesome-icon v-else-if="step.en == 'conditions'" :icon="['far', 'wind']" size="2x" fixed-width />
          <font-awesome-icon v-else-if="step.en == 'automation'" :icon="['fas', 'cogs']" size="2x" fixed-width />
          <font-awesome-icon v-else-if="step.en == 'electrical'" :icon="['fas', 'bolt']" size="2x" fixed-width />
          <font-awesome-icon v-else-if="step.en == 'limitations'" :icon="['far', 'exclamation-triangle']" size="2x" fixed-width />
          <img v-else :src="stepImage" class="step-link-image">
        </div>
        <div class="step-label">
          <span>{{ step.es | titleize }}</span>
        </div>
        <div class="step-blanks text-center">
          <div v-if="blankCount > 0">
            <span>{{ blankCount }}</span>
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="text-warning" />
          </div>
        </div>
        <div class="step-alarms text-center">
          <div v-if="alarmCount > 0">
            <span>{{ alarmCount }}</span>
            <font-awesome-icon :icon="['fas', 'ban']" fixed-width class="text-danger" />
          </div>
        </div>
        <div class="step-arrow text-right">
          <font-awesome-icon :icon="['far', 'angle-right']" fixed-width />
        </div>
      </b-row>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import TrackImg from 'images/side_menu_icons/wizard_track.png'
import ComponentsImg from 'images/side_menu_icons/wizard_components.png'

export default {
  name: 'SubmitalShowStepLink',
  props: {
    step: Object
  },
  computed: {
    ...mapState ({
      submital: state => state.configurator.submital
    }),
    stepImage () {
      switch (this.step.en) {
        case 'track':
          return TrackImg
        case 'components':
          return ComponentsImg
      }
    },
    blankCount () {
      return this.submital.blank_data[this.step.en]
    },
    alarmCount () {
      if (this.submital.alarm_data[this.step.en]) {
        return this.submital.alarm_data[this.step.en].count
      }
    }
  }
}
</script>

<style scoped>
.step-label {
  width: 100px;
  font-family: 'Gill Sans', sans-serif;
  color: #163D7D;
  line-height: 2.25;
}
.step-blanks { width: 50px; }
.step-alarms { width: 50px; }
.row {
  padding: 0;
  margin: 0;
}
li { border-bottom: 1px solid #ccc; }
.step-link {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: none;
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: left;
  display: block;
  color: #000;
  text-decoration: none !important;
}
.step-link-image {
  left: 0;
  width: 40px;
}
i {
  line-height: 2.25;
  padding-left: 5px;
  color: #555;
}
.step-link:hover {
  color: #777;
  background-color: #eee;
  cursor: pointer;
}
</style>
