import { Controller } from "stimulus";
import axios from 'axios';

export default class extends Controller {
  static targets = [ "button" ]

  download() {
    let photoId = this.data.get('id')
    axios
      .get(`/api/photos/${photoId}/download`)
      .then(response => {
        console.log(response)
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Foto`;
        link.click()
      })
  }
}

