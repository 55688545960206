<template>
  <div id="uppy-target">
  </div>
</template>

<script>
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import FileInput from '@uppy/file-input'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import AwsS3 from '@uppy/aws-s3'
import Spanish from '@uppy/locales/lib/es_ES'

export default {
  name: 'ImageUploader',
  props:{
    fileType: {
      type: String,
      required: false
    },
    btnLabel: {
      type: String,
      default: 'Seleccionar',
      required: false
    }
  },
  data () {
    return {
      newPhotoForm: {
        image: null,
        // is_serial: false
        caption: ''
      },
      processing: false
    }
  },
  mounted () {
    const uppy = Uppy({
      id: 'image-uploader',
      autoProceed: true,
      restrictions: {
        maxFileSize: 10000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.JPG', '.JPEG', '.png', '.PNG']
      },
      locale: Spanish
    })
    .use(AwsS3, {
      companionUrl: '/'
    })
    if ( this.fileType == 'input' ) {
      uppy.use(FileInput, {
        target: '#uppy-target',
        replaceTargetContent: false,
        locale: {
          strings: {
            chooseFiles: this.btnLabel
          }
        }
      })
      uppy.use(ThumbnailGenerator, {
        thumbnailWidth: 900,
        waitForThumbnailsBeforeUpload: false
      })
    } else {
      uppy.use(Dashboard, {
        inline: true,
        target: '#uppy-target',
        height: 200,
        width: 200,
        thumbnailWidth: 350,
        thumbnailType: 'image/jpeg',
        showProgressDetails: true,
        hideProgressAfterFinish: false,
        showLinkToFileUploadResult: false,
        note: "Sólo archivos de .jpg, .jpeg, .png",
        disableStatusBar: false,
        disableInformer: false,
        disableThumbnailGenerator: false,
        proudlyDisplayPoweredByUppy: false,
        locale: Spanish,
        showRemoveButtonAfterComplete: true,
        doneButtonHandler: () => {
          uppy.reset()
        }
      })
    }
    uppy.on('upload-success', (file, response) => {
      // construct uploaded file data in the format that Shrine expects
      var uploadedFileData = JSON.stringify({
        id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      })
      // console.log('Uploaded File Data:')
      // console.log(uploadedFileData)
      this.newPhotoForm.image = uploadedFileData
      this.addPhoto()

      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      // this.hiddenInputTarget.value = uploadedFileData

      // this.spinnerTarget.classList.remove('d-none')
    })
    uppy.on('thumbnail:generated', (file, preview) => {
      // console.log('Thumbnail generated event')
      this.$emit('preview-generated', preview)
    })

    uppy.on('complete', (result) => {
      // uppy.reset()  // Seems to break the thumbnail preview img link
      console.log('successful files:', result.successful)
      // console.log('failed files:', result.failed)
    })
  },
  methods: {
    addPhoto () {
      this.$emit('photo-added', this.newPhotoForm)
      this.newPhotoForm.image = null
      this.newPhotoForm.caption = ''
    }
  }
}
</script>
<style scoped>
  #uppy-target{
    font-size: 13px;
    color: blue;
  }
</style>
