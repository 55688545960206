<template>
  <b-row align-h="between" class="custom-tabs mx-0">
    <b-link class="ml-3" @click="$router.go(-1)">
      <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
      <span class="pl-2">Atrás</span>
    </b-link>
    <ul>
      <li v-if="accessType != 'corredizos' && canViewAccess('Corredizo')">
        <router-link to="/app/prod/accesos/corredizos" class="dash-nav-link" @click="navTabClicked">Corredizos</router-link>
      </li>
      <li v-if="accessType != 'abatibles' && canViewAccess('Abatible')">
        <router-link to="/app/prod/accesos/abatibles" class="dash-nav-link" @click="navTabClicked">Abatibles</router-link>
      </li>
      <li v-if="accessType != 'seccionales' && canViewAccess('Seccional')">
        <router-link to="/app/prod/accesos/seccionales" class="dash-nav-link" @click="navTabClicked">Seccionales</router-link>
      </li>
      <li v-if="accessType != 'cortinas' && canViewAccess('Cortina')">
        <router-link to="/app/prod/accesos/cortinas" class="dash-nav-link" @click="navTabClicked">Cortinas</router-link>
      </li>
    </ul>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AccessNavTabs',
  props: {
    accessType: String
  },
  computed: {
    ...mapGetters(['canViewAccess'])
  },
  methods: {
    navTabClicked() {
      this.$emit('nav-tab-clicked', response.data);
    }
  }
}
</script>
