<template>
  <div>
    <QuoteNavTabs />
    <ContentView>
      <Subheader title="Nueva Cotización" />
      <b-row align-h="center" class="mb-5">
        <b-col lg="10" sm="11" id="quote-template-box">
          <!-- Header -->
          <b-row align-h="between" class="mb-3">
            <img :src="accesosLogo" class="brand-logo">
            <div id="quote-contact-info">
              <p>Teléfono: 2227-0909, Fax: 2227-4200</p>
              <p>Email: info@accesos.co.cr</p>
              <p>Cedula Juridica: 3-101-177456</p>
              <p>San José, Costa Rica</p>
            </div>
            <div class="d-sm-block d-none">
              <b-row align-h="end" class="pl-5">
                <span class="mr-3">COTIZACIÓN</span>
              </b-row>
            </div>
          </b-row>
          <hr>

          <b-container class="my-3 pt-3" id="start-quote-box">
            <b-row align-h="between" class="mb-2" style="width: 330px">
              <span class="text-info fs-15">Iniciar cotización:</span>
              <Button v-if="step !== 1" size="sm" @click="reset">
                <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                <span class="pl-1">Reset</span>
              </Button>
            </b-row>
            <!-- Business unit -->
            <b-row v-if="step >= 1">
              <b-card style="width: 330px">
                <b-row>
                  <b-col cols="1" class="px-0 text-right">
                    <div class="step-number float-right" :class="{ valid: form.business_unit && step > 1 }">1</div>
                  </b-col>
                  <b-col cols="11">
                    <p class="step-header">Unidad de negocio</p>
                    <p v-if="form.business_unit && step > 1" class="selected-text mb-0">
                      {{ form.business_unit }}
                    </p>
                    <div v-else>
                      <b-form-group>
                        <b-radio-group v-model="form.business_unit" :options="businessUnitOptions" stacked size="sm"></b-radio-group>
                      </b-form-group>
                      <b-form-group>
                        <Button variant="green" size="sm" :disabled="!form.business_unit" class="float-right" @click="saveFirstStep">
                          Guardar
                        </Button>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-row>
            <!-- Business unit2 CONDITIONAL  -->
            <b-row v-if="showBusinessUnit2Options && step >= 1.5">
              <b-card style="width: 330px">
                <b-row>
                  <b-col cols="1" class="px-0 text-right">
                    <div class="step-number float-right" :class="{ valid: form.business_unit2 && step > 1.5 }">1b</div>
                  </b-col>
                  <b-col cols="11">
                    <p class="step-header">Subnorma</p>
                    <p v-if="form.business_unit2 && step > 1.5" class="selected-text mb-0">{{ form.business_unit2 }}</p>
                    <div v-else>
                      <b-form-group>
                        <b-radio-group v-model="form.business_unit2" :options="businessUnit2Options" stacked size="sm"></b-radio-group>
                      </b-form-group>
                      <b-form-group>
                        <Button variant="green" size="sm" :disabled="!form.business_unit2" class="float-right" @click="step = 2">Guardar</Button>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-row>
            <!-- Client -->
            <b-row v-if="form.business_unit && step >= 2">
              <b-card style="width: 330px">
                <b-row>
                  <b-col cols="1" class="px-0 text-right">
                    <div class="step-number float-right" :class="{ valid: form.client_id && step > 2 }">2</div>
                  </b-col>
                  <b-col cols="11">
                    <p class="step-header">Asignar cliente</p>
                    <div v-if="selectedClient">
                      <p class="selected-text mb-0">{{ selectedClient.name }}</p>
                      <p v-if="!selectedClient.sap_valid || !selectedClient.sap_gln" class="mt-2 text-danger">
                        <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
                        <span>Este cliente está inválido en SAP.  No se sincronizará con SAP</span>
                      </p>
                    </div>
                    <b-form-group v-else>
                      <b-input-group size="sm">
                        <b-input
                          v-model="clientSearchTerm"
                          type="text"
                          autofocus
                          placeholder="Buscar por código o nombre"
                          @keyup.enter="searchClients"
                        />
                        <b-input-group-append>
                          <b-button :disabled="!clientSearchTerm" @click="searchClients">
                            <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="!selectedClient" align-h="center" class="mt-4">
                  <div v-show="clientSearchTouch" style="width: 350px; max-height: 250px" class="overflow-auto">
                    <b-table
                      :items="clientSearchResults"
                      :fields="clientSearchFields"
                      hover
                      outlined
                      show-empty
                      empty-text="No resultados.  Intenta de nuevo"
                      class="fs-13"
                      @row-clicked="selectClient"
                    />
                  </div>
                </b-row>
              </b-card>
            </b-row>
            <!-- Project -->
            <b-row v-if="form.business_unit && form.client_id && step >= 3">
              <b-card style="width: 330px">
                <b-row>
                  <b-col cols="1" class="px-0">
                    <div class="step-number float-right" :class="{ valid: form.project_id && step > 3 }">3</div>
                  </b-col>
                  <b-col cols="11">
                    <p class="step-header">Asignar proyecto</p>
                    <div v-if="step === 4" class="selected-text">
                      <p class="mb-0">{{ selectedProject.name }}</p>
                      <p class="mb-0">{{ selectedProject.address }}</p>
                      <p class="mb-0">{{ selectedProject.project_type }}</p>
                    </div>
                    <div v-else>
                      <div v-if="projects.length > 0">
                        <small class="text-info">Asignar proyecto existente o crear nuevo:</small>
                        <b-form-group>
                          <b-radio-group v-model="form.project_id" stacked size="sm">
                            <b-radio v-for="project in projects" :value="project.id" :key="project.id">{{ project.name }}</b-radio>
                            <b-radio value="CREATE"><span class="text-success">Crear nuevo</span></b-radio>
                          </b-radio-group>
                        </b-form-group>
                      </div>
                      <small v-else class="text-info">El cliente eligido no tiene proyectos, así que por favor cree uno nuevo para esta cotización:</small>

                      <div v-if="form.project_id == 'CREATE' || projects.length === 0">
                        <hr>
                        <b-form-group label="Nombre del proyecto:" label-size="sm">
                          <b-input v-model="projectForm.name" size="sm" />
                        </b-form-group>
                        <b-form-group label="Dirección:" label-size="sm">
                          <b-textarea v-model="projectForm.address" size="sm" />
                        </b-form-group>
                        <b-form-group label="Tipo de ciclos de proyecto:" label-size="sm">
                          <b-radio-group v-model="projectForm.project_type" stacked size="sm">
                            <b-radio value="Comercial">Comercial o Industrial</b-radio>
                            <b-radio value="Residencial">Residencial o Condominio</b-radio>
                            <b-radio value="Parqueo">Parqueo</b-radio>
                          </b-radio-group>
                        </b-form-group>
                      </div>
                      <Button variant="green" size="sm" class="float-right" :disabled="!projectFormIsValid" @click="step = 4">Guardar</Button>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-row>
            <!-- Finalize -->
            <b-row v-if="step === 4">
              <b-card style="width: 330px">
                <b-row align-h="center">
                  <Button variant="orange" @click="createQuote">
                    <div v-if="processing">
                      <b-spinner small type="grow"></b-spinner>
                      <span>Procesando...</span>
                    </div>
                    <span v-else>Crear Cotización</span>
                  </Button>
                </b-row>
              </b-card>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { addDays } from 'date-fns'
import AccesosLogo from 'images/logos/accesos-w300.png'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import Subheader from '../shared/Subheader'
import ToastAlert from '../shared/ToastAlert'
import QuoteNavTabs from './QuoteNavTabs'
import { businessUnits, businessUnits2 } from '../../sharedOptions/quoteBusinessUnits'

export default {
  name: 'QuoteNew',
  components: { Button, ContentView, Subheader, ToastAlert, QuoteNavTabs },
  data() {
    return {
      step: 1,
      form: {
        user_id: this.$store.state.currentUser.id,
        business_unit: null,
        business_unit2: null, // only applies if showBusinessUnit2Options is TRUE
        client_id: null,
        project_id: null
      },
      projectForm: {
        user_id: this.$store.state.currentUser.id,
        client_id: null,
        name: null,
        address: '',
        project_type: null
      },
      businessUnitOptions: businessUnits,
      businessUnit2Options: businessUnits2,
      clientSearchTerm: '',
      clientSearchResults: [],
      clientSearchTouch: false,
      clientSearchFields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name', label: 'Nombre', tdClass: 'clickable' }
      ],
      selectedClient: null,
      projects: [],
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
    }
  },
  computed: {
    currentUser() { return this.$store.state.currentUser },
    accesosLogo() { return AccesosLogo },
    projectFormIsValid() {
      if (this.form.project_id === 'CREATE') {
        return this.projectForm.name && this.projectForm.address && this.projectForm.project_type ? true : false;
      } else {
        return this.form.project_id ? true : false;
      }
    },
    selectedProject() {
      if (this.form.project_id === 'CREATE') {
        return this.projectForm;
      } else {
        let match = this.projects.filter(p => p.id === this.form.project_id)[0];
        return match;
      }
    },
    showBusinessUnit2Options () {
      if (this.form.business_unit) {
        const subnorms= ['CA','PA','PK','DI','HZ','VV','PVT','CM','TG','CSC']
        return subnorms.includes(this.form.business_unit)
      }
      return false
    }
  },
  methods: {
    searchClients() {
      this.$http.get('/api/production/clients/search', {
          params: { q: this.clientSearchTerm }
        })
        .then(response => {
          console.log(response)
          this.clientSearchResults = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=> this.clientSearchTouch = true);
    },
    selectClient(item) {
      this.form.client_id = item.id;
      this.projectForm.client_id = item.id;
      this.selectedClient = item;
      this.$http.get(`/api/production/clients/${item.id}/fetch_projects`)
        .then(response => {
          console.log(response)
          this.projects = response.data;
          if (this.projects.length === 0) {
            this.form.project_id = 'CREATE';
          }
          this.step = 3;
        })
        .catch(error => {
          this.alertShow = true;
          this.alertVariant = 'danger';
          this.alertMessage = 'No se pudo encontrar ese cliente';
        });
    },
    saveFirstStep() {
      if (!this.showBusinessUnit2Options) {
        this.step = 2;
      } else {
        this.step = 1.5;
      }
    },
    createQuote() {
      if (this.processing) return;
      this.processing = true;
      let data;
      if (this.form.project_id === 'CREATE') {
        data = {
          quote: {
            user_id: this.form.user_id,
            client_id: this.form.client_id,
            business_unit: this.form.business_unit,
            business_unit2: this.form.business_unit2,
            valid_until: addDays(new Date, 15),
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          },
          project: this.projectForm
        }
      } else {
        data = {
          quote: {
            user_id: this.form.user_id,
            business_unit: this.form.business_unit,
            business_unit2: this.form.business_unit2,
            client_id: this.form.client_id,
            project_id: this.form.project_id,
            valid_until: addDays(new Date, 15),
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          }
        }
      }
      this.$http.post('/api/production/quotes', data)
        .then(response => {
          this.$router.push({ name: 'QuoteShow', params: { id: response.data.id, created: true }});
        })
        .catch(error => {
          this.alertVariant = 'danger';
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0];
          } else {
            this.alertMessage = 'No funcionó';
          }
          this.alertShow = true;
        })
        .finally(() => this.processing = false);
    },
    reset() {
      this.form.business_unit = null;
      this.form.business_unit2 = null;
      this.form.client_id = null;
      this.form.project_id = null;
      this.projectForm.client_id = null;
      this.projectForm.name = null;
      this.projectForm.address = '';
      this.projectForm.project_type = null;
      this.clientSearchTerm = '';
      this.clientSearchResults = [];
      this.clientSearchTouch = false;
      this.selectedClient = null;
      this.projects = [];
      this.step = 1;
    }
  }
}
</script>

<style scoped>
.selected-text {
  font-size: 16px;
  color: #aaa;
}
.step-number {
  border-radius: 50%;
  border: 1px solid #aaa;
  width: 20px;
  height: 20px;
  text-align: center;
}
.step-number.valid {
  background-color: #6eb165;
  border: 1px solid #6eb165;
  color: #fff;
}
.step-header {
  font-size: 15px;
  font-weight: bold;
}
.brand-logo {
  width: 200px;
  height: 70px;
}
#quote-template-box {
  border: 1px dotted #0f0f0f;
  padding: 2em;
}
#quote-contact-info {
  font-size: 10px;
}
#quote-contact-info p {
  margin-bottom: 0;
}
#start-quote-box {
  font-size: 13px;
/*  border: 1px solid #eee;*/
  width: 350px;
  min-height: 550px;
}
@media (max-width: 575.98px) {
  .brand-logo {
    width: 120px;
    height: 40px;
  }
}
</style>
