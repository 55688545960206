import '../styles/application.scss'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
Rails.start()
Turbolinks.start()
import 'jquery'
import 'bootstrap/dist/js/bootstrap'
import './legacy'

// Inactivity timeout
function idleLogout() {
  let t
  window.onload = resetTimer
  window.onmousemove = resetTimer
  window.onmousedown = resetTimer  // catches touchscreen presses as well
  window.ontouchstart = resetTimer // catches touchscreen swipes as well
  window.onclick = resetTimer      // catches touchpad clicks as well
  window.onkeypress = resetTimer
  window.addEventListener('scroll', resetTimer, true)

  function logoutUser() {
    Rails.ajax({
      url: '/timeout',
      type: 'GET',
      dataType: 'script',
      success: function() { window.location.href = '/accounts/sign_in' }
    })
  }

  function resetTimer() {
    clearTimeout(t)
    t = setTimeout(logoutUser, 50 * 60 * 1000)  // time is in milliseconds, set to 50 min
  }
}
idleLogout()

// Vue
import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks'
import App from '../App.vue'
import store from '../store'
import router from '../routes.js'
import '../filters/concatName2.js'
import '../filters/concatName3.js'
import '../filters/concatName4.js'
import '../filters/colones.js'
import '../filters/colones2.js'
import '../filters/colones3.js'
import '../filters/dollarize.js'
import '../filters/dollarize2.js'
import '../filters/formatDate.js'
import '../filters/formatDateCompact.js'
import '../filters/formatDateLong.js'
import '../filters/formatDateTab.js'
import '../filters/formatDateTime.js'
import '../filters/formatDateZeroPad.js'
import '../filters/formatTime.js'
import '../filters/projectId.js'
import '../filters/titleize.js'
import '../filters/titleizePayrollType.js'
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.use(TurbolinksAdapter)

// Axios
import axios from 'axios'
Vue.use({
  install (Vue) {
    const instance = axios.create()
    instance.interceptors.response.use(response => {
      return response
    }, error => {
      if (error.response.status === 401) {
        console.log('401 error intercepted!!!')
        Rails.ajax({
          url: '/timeout',
          type: 'GET',
          dataType: 'script',
          success: function() { window.location.href = '/accounts/sign_in' }
        })
      } else {
        return Promise.reject(error)
      }
    })
    Vue.prototype.$http = instance
  }
})

// Font Awesome SVG
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAddressBook as fasAddressBook,
  faBackward,
  faBan as fasBan,
  faBars,
  faBarcode,
  faBolt,
  faBoxesAlt as fasBoxesAlt,
  faBriefcase as fasBriefcase,
  faBullhorn,
  faCalendarAlt as fasCalendarAlt,
  faCalculator,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheckCircle as fasCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronCircleRight,
  faClipboardCheck,
  faCog,
  faCogs,
  faDolly,
  faDotCircle,
  faEdit as fasEdit,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faFileContract,
  faFileCsv as fasFileCsv,
  faFlag,
  faForward,
  faHandHoldingBox,
  faHandHoldingUsd,
  faHome,
  faIndustryAlt,
  faLink,
  faLockAlt,
  faMapMarkerAlt,
  faMinus,
  faParking,
  faPlus,
  faPlusCircle as fasPlusCircle,
  faReceipt,
  faSearch,
  faShare as fasShare,
  faSitemap,
  faSpinner,
  faUsers,
  faUserCheck,
  faUserCircle,
  faUsersClass,
  faUserGraduate,
  faUserLock,
  faUserTie,
  faUserTimes,
  faWarehouse as fasWarehouse,
  faWrench
} from '@fortawesome/pro-solid-svg-icons'

import {
  faAddressBook as farAddressBook,
  faAddressCard,
  faAlignLeft,
  faAngry,
  faAngleRight,
  faArrowAltSquareUp,
  faArrowAltToBottom, // replace with ArrowToBottom?
  faArrowToBottom,
  faArrowLeft,  // replaced by Chevron left?
  faBalanceScale,
  faBan as farBan,
  faBell,
  faBoxesAlt as farBoxesAlt,
  faBoot,
  faBriefcase as farBriefcase,
  faCalendarAlt as farCalendarAlt,
  faCalendarEdit,
  faCalendarWeek,
  faCaretCircleDown,
  faCopy,
  faCheck,
  faCheckCircle as farCheckCircle, // unused
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCloudUpload,
  faCreditCard,
  faDownload,
  faDraftingCompass,
  faEdit as farEdit,
  faEllipsisV,
  faEnvelope,
  faExchange,
  faExclamation,
  faExclamationCircle as farExclamationCircle,
  faExclamationTriangle as farExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoiceDollar,
  faFilePdf,
  faFileCsv as farFileCsv,
  faGarage,
  faHistory,
  faHandPointer,
  faImage,
  faInfoCircle,
  faInfoSquare,
  faInventory,
  faInboxIn,
  faMinusCircle,
  faMoneyCheckAlt,
  faMoneyCheckEditAlt,
  faMoneyCheckEdit,
  faNewspaper,
  faPalette,
  faPaperPlane,
  faPen,
  faPlusCircle as farPlusCircle,
  faPlusHexagon,
  faQuestionCircle,
  faRulerCombined,
  faRulerHorizontal,
  faRulerVertical,
  faSave,
  faShare as farShare,
  faShoppingCart,
  faSignature,
  faSlidersH,
  faSquare,
  faStickyNote,
  faTag,
  faTicket,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUmbrellaBeach,
  faUndoAlt,
  faUser,
  faUserHeadset,
  faUserHardHat,
  faWarehouse as farWarehouse,
  faWarehouseAlt,
  faWind
} from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(
  farAddressBook,
  fasAddressBook,
  faAddressCard,
  faAlignLeft,
  faAngry,
  faAngleRight,
  faArrowAltSquareUp,
  faArrowAltToBottom,
  faArrowLeft,
  faArrowToBottom,
  faBackward,
  faBalanceScale,
  farBan,
  fasBan,
  faBars,
  faBarcode,
  faBell,
  faBolt,
  faBoot,
  farBoxesAlt,
  fasBoxesAlt,
  farBriefcase,
  fasBriefcase,
  faBullhorn,
  faCalculator,
  farCalendarAlt,
  fasCalendarAlt,
  faCalendarEdit,
  faCalendarWeek,
  faCaretCircleDown,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  farCheckCircle,
  fasCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClipboardCheck,
  faCloudUpload,
  faCog,
  faCogs,
  faCopy,
  faCreditCard,
  faDotCircle,
  faDolly,
  faDownload,
  faDraftingCompass,
  farEdit,
  fasEdit,
  faEllipsisV,
  faEnvelope,
  faExchange,
  faExclamation,
  farExclamationCircle,
  fasExclamationCircle,
  farExclamationTriangle,
  fasExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoiceDollar,
  faFilePdf,
  faFileContract,
  fasFileCsv,
  farFileCsv,
  faFlag,
  faForward,
  faGarage,
  faHandHoldingBox,
  faHandHoldingUsd,
  faHandPointer,
  faHistory,
  faHome,
  faIndustryAlt,
  faInfoCircle,
  faInfoSquare,
  faImage,
  faInventory,
  faInboxIn,
  faLink,
  faLockAlt,
  faMapMarkerAlt,
  faMinus,
  faMinusCircle,
  faMoneyCheckAlt,
  faMoneyCheckEditAlt,
  faMoneyCheckEdit,
  faNewspaper,
  faPalette,
  faParking,
  faPaperPlane,
  faPen,
  faPlus,
  farPlusCircle,
  fasPlusCircle,
  faPlusHexagon,
  faQuestionCircle,
  faReceipt,
  faRulerCombined,
  faRulerHorizontal,
  faRulerVertical,
  faSave,
  faSearch,
  farShare,
  fasShare,
  faShoppingCart,
  faSignature,
  faSitemap,
  faSlidersH,
  faSquare,
  faSpinner,
  faStickyNote,
  faTag,
  faTicket,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUmbrellaBeach,
  faUndoAlt,
  faUser,
  faUserCheck,
  faUserCircle,
  faUsersClass,
  faUserHeadset,
  faUserHardHat,
  faUserLock,
  faUsers,
  faUserTie,
  faUserGraduate,
  faUserTimes,
  farWarehouse,
  fasWarehouse,
  faWarehouseAlt,
  faWind,
  faWrench
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.config.productionTip = false

// Vue-Bootstrap
import {
  BNav,
  BNavItem,
  AlertPlugin,
  BadgePlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  ProgressPlugin,
  PopoverPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  TooltipPlugin
} from 'bootstrap-vue'

Vue.component('b-nav', BNav)
Vue.component('b-nav-item', BNavItem)
Vue.use(AlertPlugin)
Vue.use(BadgePlugin)
Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(CardPlugin)
Vue.use(CarouselPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(ProgressPlugin)
Vue.use(PopoverPlugin)
Vue.use(SidebarPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)


// Vee Validate
import { extend, localize } from 'vee-validate'
import { digits, email, required, max, max_value, min, min_value, numeric, double } from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

extend('required', required)
extend('email', email)
extend('digits', digits)
extend('max', max)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)
extend('numeric', numeric)
extend('double', double)
extend('date_format_ddmmyyyy', {
  validate: (value) => {
    if (value) {

      let indexWithSlash = [2, 5]
      let indexWithNumber = [0, 1, 3, 4, 6, 7, 8, 9]

      for (let i = 0; i < indexWithSlash.length; i++) {
        if (value[indexWithSlash[i]] !== '/') return false
      }
      for (let i = 0; i < indexWithNumber.length; i++) {
        if (isNaN(value[indexWithNumber[i]])) return false
      }

      let day = Number(value[0] + value[1])
      let month = Number(value[3] + value[4])
      let millenium = Number(value[6])
      if (day > 31) return false
      if (month > 12) return false
      if (millenium < 1 || millenium > 2) return false
      if (value.length !== 10) return false

      return true
    }
  },
  message: 'El formato de {_field_} no es válido'
})
extend('before_date', {
  validate(value, {date}) {
    if (value && date) {
      const dateBefore = new Date(value);
      const dateAfter = new Date(date)
      return dateBefore < dateAfter
    }
  },
  params: ['date'],
  message: '{_field_} debe ser menor a {date}'
});
localize('es', { messages: es.messages })
localize('es')

// Vue Toggle Button
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

// Stimulus
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener('turbolinks:load', () => {
  //// Main Vue app
  const el = document.getElementById('main-app')
  if (el != null) {
    const currentUser = JSON.parse(el.dataset.currentUser)
    const roles = JSON.parse(el.dataset.roles)
    const settings = JSON.parse(el.dataset.settings)
    // Routing auth
    router.beforeEach(async (to, from, next) => {
      await Vue.nextTick()

      const bypassRoutes = ['mainPage', 'notFound']
      if (bypassRoutes.includes(to.name)) {
        return next()
      }

      const {
        prodOnly, // Production related only
        distOnly, // Distributors related only
        instOnly, // installers related only
        prodActive // No gerente
      } = to.meta

      const role = store.state.currentUser.role

      const allowedRoutes = {
        distribuidor: ['ItemIndex', 'ItemShow'],
        instalador: ['ItemIndex', 'ItemShow'],
        atkAsesores: ['CardTransactionIndex', 'ItemIndex', 'ItemShow']
      }

      const isAuthorized = (role, routeName) => {
        if (roles.includes('ATK - asesores')) {
          return allowedRoutes.atkAsesores.includes(routeName)
        }
        return allowedRoutes[role].includes(routeName)
      };

      if (role === 'distribuidor') {
        if (!distOnly && !isAuthorized(role, to.name)) return next('/app/not_found')
      } else if (role === 'instalador') {
        if (!instOnly && !isAuthorized(role, to.name)) return next('/app/not_found')
      } else if (distOnly || instOnly) {
        return next('/app/not_found')
      } else if (role == 'non_prod' && prodOnly) {
        return next('/')
      } else if (role == 'gerente' && prodActive) {
        return next(error)
      }
      next()
    })
    router.onError(() => {
      var event = new Event('auth-denied')
      var el = document.getElementById('content')
      if (el != null) {
        el.dispatchEvent(event)
      }
    })
    // Mount app
    new Vue({
      el,
      store,
      router,
      template: "<App />",
      components: { App },
      created() {
        store.state.currentUser = currentUser
        store.state.userRoles = roles
        store.state.settings = settings
        store.state.announcement = JSON.parse(el.dataset.announcement)
      }
    })
  }

  // For Header Sidebar Toggle
  let button = document.getElementById('header-toggle-button')
  if (button) {
    button.addEventListener('click', () => {
      let sidebar = document.getElementById('sidebar')
      sidebar.classList.toggle('tucked')
      document.getElementById('overlay').classList.toggle('active')
      let event = new Event('sidebarToggled')
      sidebar.dispatchEvent(event)
    })
    if (document.body.classList.contains('home')) {
      button.classList.remove('d-none')
    } else {
      button.classList.add('d-none')
    }
  }
})
