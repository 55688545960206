<template>
  <div class="pb-5">
    <NavTabs />
    <!-- Employee TopShelf -->
    <b-container fluid>
      <b-row class="top-shelf py-4">
        <b-col lg="2" class="pl-5 pb-4 pb-sm-0">
          <BackButton @click="$router.go(-1)" />
        </b-col>
        <b-col lg="6" sm="8" class="px-0">
          <TitleBadge type="Employee">
            <template #title>{{ employee | concatName4 }}</template>
          </TitleBadge>
        </b-col>
        <b-col lg="2" sm="3" class="text-right">
          <div v-if="employee.status === 'activo'">
            <b-badge variant="success">Activo</b-badge>
            <div v-if="employee.is_manager">
              <b-badge class="theme-orange">Jefatura</b-badge>
            </div>
            <div v-if="employee.confirmed == true">
              <b-badge variant="secondary">Tiene Login</b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <PanelSection>
      <template #title>Info</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr v-if="(isHrAdmin || isDev) && employee.country == 'costa_rica'">
            <td class="label">Carnet:</td>
            <td v-if="employee.carnet">{{ employee.carnet }}</td>
            <td v-else><span class="text-warning">Falta dato</span></td>
          </tr>
          <tr>
          <td class="label">País:</td>
            <td>{{ employee.country | titleize }}</td>
          </tr>
          <tr>
            <td class="label">Email:</td>
            <td>{{ employee.email }}</td>
          </tr>
          <tr v-if="isHrAdmin || isDev">
            <td class="label">Cedula:</td>
            <td v-if="employee.cedula">{{ employee.cedula }}</td>
            <td v-else><span class="text-warning">Falta dato</span></td>
          </tr>
          <tr v-if="isHrAdmin || isDev">
            <td class="label">Fecha de ingreso:</td>
            <td>{{ employee.start_date | formatDate }}</td>
          </tr>
          <tr v-if="isHrAdmin || isDev">
            <td class="label">Tipo de planilla:</td>
            <td>{{ employee.payroll_type | titleizePayrollType }}</td>
          </tr>
        </table>
      </template>
      <template #action>
        <Button v-if="isHrAdmin || isDev" size="sm" @click="openEditForm()">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          <span class="pl-1">Editar</span>
        </Button>
      </template>
    </PanelSection>

    <PanelSection>
      <template #title>Rol</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr>
            <td class="label">Puesto:</td>
            <td v-if="employee.job_title">{{ employee.job_title }}</td>
            <td v-else><span class="text-warning">Falta dato</span></td>
          </tr>
          <tr>
            <td class="label">Departamento:</td>
            <td>{{ employee.department | titleize }}</td>
          </tr>
          <tr>
            <td class="label">Gerencia:</td>
            <td>{{ employee.division }}</td>
          </tr>
          <tr>
            <td class="label">Unidad:</td>
            <td>{{ employee.business_unit }}</td>
          </tr>
          <tr v-if="!isRoot(employeeId)">
            <td class="label">Jefe Inmediato:</td>
            <td v-if="employee.ancestry">{{ manager | concatName3 }}</td>
            <td v-else><span class="text-danger">No tiene jefe inmediato!</span></td>
          </tr>
        </table>
      </template>
    </PanelSection>

    <!-- <PanelSection v-if="isHrAdmin || isDev">
      <template #title>Vacaciones</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr>
            <td class="label">Días acumulados:</td>
            <td>{{ employee.vacation_days_accumulated }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="label">Días disfrutados:</td>
            <td>{{ employee.vacation_days_spent }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="label">Días restados:</td>
            <td>{{ employee.vacation_days_deducted }}</td>
            <td></td>
          </tr>
          <tr v-if="employee.vacation_days_pending > 0" class="text-info">
            <td>Días solicitados:</td>
            <td>{{ employee.vacation_days_pending }}</td>
          </tr>
          <tr>
            <td class="label">Saldo de vacaciones:</td>
            <td>{{ employee.vacation_days_accumulated - employee.vacation_days_spent - employee.vacation_days_deducted }}</td>
            <td></td>
          </tr>
        </table>
      </template>

      <template #action>
        <Button size="sm" class="mb-3" @click="$router.push(`/app/rrhh/empleados/${employeeId}/vacaciones`)">
          <font-awesome-icon :icon="['far', 'umbrella-beach']" fixed-width />
          <span class="pl-1">Detalles</span>
        </Button>
      </template>
    </PanelSection> -->

    <PanelSection v-if="isHrAdmin || isSuperAdmin || isDev">
      <template #title>App</template>
      <template #content>
        <div v-if="employee.confirmed">
          <table v-if="isSuperAdmin" class="table table-borderless table-show table-sm">
            <tr>
              <td class="label">Autorización:</td>
              <td v-if="employee.role == 'lider_producto'">Líder de Producto</td>
              <td v-else>{{ employee.role | titleize }}</td>
            </tr>
            <tr v-if="employee.role === 'supervisor_división'">
              <td class="label">Encargado de:</td>
              <td>
                <div v-for="division in employee.permissions" :key="division">
                  <span v-if="isNaN(division)">{{ division }}</span>
                </div>
              </td>
            </tr>
            <tr v-if="employee.role === 'supervisor_ventas'">
              <td class="label">Encargado de:</td>
              <td>
                <div v-for="employeeId in employee.permissions" :key="employeeId">
                  <span v-if="employeeId && !isNaN(employeeId) && Number(employeeId) !== employee.id">
                    {{ getEmployeeById(Number(employeeId)) | concatName3 }}
                  </span>
                </div>
              </td>
            </tr>
            <tr v-if="isDev">
              <td class="label">Permisos:</td>
              <td>
                <div v-for="role in roles" :key="role.id">
                  <span>{{ role.name }}</span>
                </div>
              </td>
            </tr>
            <tr v-if="isDev">
              <td colspan="2">
                <Button size="sm" @click="markAsDMTechnician">
                  Técnico DM
                </Button>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <div v-if="!emailSent">
            <Button size="sm" class="mb-2" @click="openAccountSetupModal">Configurar Login</Button>
          </div>
          <div v-else>
            <span class="text-success fs-13">Correo enviado</span>
          </div>
        </div>
      </template>
      <template #action>
        <Button v-if="isSuperAdmin && employee.confirmed" size="sm" class="mb-5" @click="editAuth">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          <span class="pl-1">Editar</span>
        </Button><br>
        <Button size="sm" variant="red" @click="deactivateModalShow = !deactivateModalShow">
          Desactivar
        </Button>
      </template>
    </PanelSection>

    <PanelSection v-if="isSuperAdmin">
      <template #title>SAP</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr v-if="isDev">
            <td class="label">ID Usuario:</td>
            <td>
              <span v-if="employee.sap_internal_key">{{ employee.sap_internal_key }}</span>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <td class="label">Número de empleado:</td>
            <td>
              <span v-if="employee.sap_employee_id">{{ employee.sap_employee_id }}</span>
              <span v-else>N/A</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="label">Código de vendedor:</td>
            <td>
              <span v-if="employee.sap_sales_person_code">{{ employee.sap_sales_person_code }}</span>
              <span v-else>N/A</span>
            </td>
            <td></td>
          </tr>
          <tr v-if="isDev && employee.sap_sales_person_code">
            <td class="label">Descuento máx:</td>
            <td>
              <span v-if="employee.max_sales_discount">{{ employee.max_sales_discount }} %</span>
              <span v-else class="text-danger">Pend.</span>
            </td>
          </tr>
          <!-- Dev only -->
          <tr v-if="isDev && employee.sap_sales_person_code">
            <td colspan="2">
              <Button size="sm" class="mr-2" @click="syncUser">
                Sync
              </Button>
              <Button size="sm" @click="fetchSapData">
                Refresh
              </Button>
            </td>
          </tr>
        </table>
      </template>
      <template #action>
        <Button size="sm" class="ml-3" @click="openSapModal">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          <span class="pl-1">Editar</span>
        </Button>
      </template>
    </PanelSection>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Modals -->
    <b-modal centered v-model="editModalShow" title="Editar datos" hide-footer>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">
          <b-form-group label="Carnet" label-size="sm">
            <b-input
              type="number"
              size="sm"
              class="short-input"
              v-model="editForm.carnet"
              :state="editForm.carnet ? true : null"
            />
          </b-form-group>

          <b-form-group label-size="sm">
            <template #label>
              <span>País</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="editForm.country" size="sm">
              <b-form-radio value="costa_rica">Costa Rica</b-form-radio>
              <b-form-radio value="panamá">Panamá</b-form-radio>
            </b-radio-group>
          </b-form-group>

          <ValidationProvider rules="required" name="Nombre" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Nombre</span><span class="asterisk">*</span>
              </template>
              <b-input
                type="text"
                size="sm"
                v-model="editForm.first_name"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Segundo Nombre" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.second_name"
              :state="editForm.second_name ? true : null"
            />
          </b-form-group>

          <ValidationProvider rules="required" name="Apellido" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Apellido</span><span class="asterisk">*</span>
              </template>
              <b-input
                type="text"
                size="sm"
                v-model="editForm.last_name"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Segundo Apellido" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.last_name2"
              :state="editForm.last_name2 ? true : null"
            />
          </b-form-group>

          <ValidationProvider rules="required|email" name="Correo Electrónico" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Correo Electrónico</span><span class="asterisk">*</span>
                <div class="d-inline float-right" id="email-info">
                  <font-awesome-icon :icon="['far', 'info-circle']" class="text-info" />
                </div>
              </template>
              <!-- Popover -->
              <b-popover target="email-info" placement="top" triggers="hover focus">
                <span class="text-info">
                  Tenga en cuenta que si se cambia el correo electrónico, este empleado tendrá que utilizar el nuevo correo electrónico para iniciar sesión
                </span>
              </b-popover>
              <!-- -->
              <b-input
                type="email"
                size="sm"
                v-model="editForm.email"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Cedula" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.cedula"
              :state="editForm.cedula ? true : null"
            />
          </b-form-group>

          <ValidationProvider rules="required|date_format_ddmmyyyy" name="Fecha de Ingreso" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Fecha de Ingreso</span><span class="asterisk">*</span>
              </template>
              <b-input
                type="text"
                size="sm"
                placeholder="DD/MM/AAAA"
                v-model="editForm.start_date"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label-size="sm">
            <template #label>
              <span>Tipo de planilla</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="editForm.payroll_type" size="sm">
              <b-form-radio value="quincenal">Quincenal</b-form-radio>
              <b-form-radio value="plr">PLR</b-form-radio>
            </b-radio-group>
          </b-form-group>

          <hr>

          <b-form-group>
            <b-form-checkbox v-model="editForm.is_manager" size="sm">
              Es Jefatura
            </b-form-checkbox>
          </b-form-group>

          <b-form-group label="Puesto" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.job_title"
              :state="editForm.job_title ? true : null"
            />
          </b-form-group>

          <ValidationProvider rules="required" name="Departamento" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Departamento</span><span class="asterisk">*</span>
              </template>
              <b-select
                size="sm"
                v-model="editForm.department"
                :state="errors[0] ? false : (valid ? true : null)"
                :options="deptOptions"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required" name="Gerencia" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Gerencia</span><span class="asterisk">*</span>
              </template>
              <b-select
                size="sm"
                v-model="editForm.division"
                :state="errors[0] ? false : (valid ? true : null)"
                :options="divisionOptions"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required" name="un" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Unidad</span><span class="asterisk">*</span>
              </template>
              <b-select
                size="sm"
                v-model="editForm.business_unit"
                :state="errors[0] ? false : (valid ? true : null)"
                :options="businessUnitOptions"
              >
                <template #first>
                  <b-select-option :value="null">- N/A -</b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required" name="Jefe Inmediato" v-slot="{ valid, errors }">
            <b-form-group v-if="!isRoot(employeeId)" label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Jefe Inmediato</span><span class="asterisk">*</span>
              </template>
              <b-select
                size="sm"
                v-model="editForm.parent_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <option v-for="manager in managers" :value="manager.id" :key="manager.id">
                  {{ manager | concatName3 }}
                </option>
              </b-select>
            </b-form-group>
          </ValidationProvider>
          <div class="text-center mt-5 mb-2">
            <Button variant="green" :disabled="invalid" @click="updateEmployee">
              Guardar
            </Button>
          </div>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <b-modal v-model="accountSetupModalShow" title="Configurar cuenta del app">
      <b-container class="px-3 fs-14">
        <b-row v-if="configureWithEmail" class="mt-3">
          <small class="text-muted">1) Configurar el nivel de autorización de {{ employee | concatName4 }}</small>
        </b-row>
        <b-row align-h="center" class="my-3">
          <b-col lg="6" cols="7" class="border-right">
            <b-form-group>
              <b-radio-group v-model="accountSetupForm.role" :options="roleOptions" stacked></b-radio-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" cols="5" class="pr-0 text-info">
            <u><small>Permisos:</small></u>
            <ul class="pl-3">
              <small v-if="accountSetupForm.role === 'non_prod'">
                <li>Acceso limitado</li>
              </small>
              <small v-if="accountSetupForm.role === 'vendedor' || accountSetupForm.role === 'técnico'">
                <li>Puede crear, editar y ver sus propios proyectos, accesos, y cotizaciones</li>
              </small>
              <small v-if="accountSetupForm.role === 'lider_producto'">
                  <li>Puede crear, editar y ver todos los proyectos, accesos y cotizaciones del app</li>
                  <li>Puede modificar el precio de cualquier artículo en cotizaciones</li>
                  <li>Puede crear y editar bultos</li>
              </small>
              <small v-if="accountSetupForm.role === 'supervisor_división'">
                <li>Puede crear, editar y ver los proyectos, accesos de los divisiones en su cargo</li>
                <li>Puede manejar sus propias cotizaciones</li>
              </small>
              <small v-if="accountSetupForm.role === 'supervisor_ventas'">
                <li>Puede crear, editar y ver los proyectos, accesos de los asesores en su cargo</li>
                <li>Puede manejar sus propias cotizaciones</li>
              </small>
              <small v-if="accountSetupForm.role === 'gerente'">
                <li>Puede ver todos los proyectos y accesos, pero no crear o editar</li>
                <li>Puede manejar sus propias cotizaciones</li>
              </small>
              <small v-if="accountSetupForm.role === 'administrador'">
                <li>Puede modificar el precio de cualquier artículo en cotizaciones</li>
                <li>Puede administrar la mayoría de utilidades de producción</li>
              </small>
            </ul>
          </b-col>
        </b-row>
        <hr v-if="accountSetupForm.role === 'supervisor_división' || accountSetupForm.role === 'supervisor_ventas'">
        <b-row align-h="center">
          <b-form-group v-if="accountSetupForm.role === 'supervisor_división'" label="Encargado de:">
            <b-checkbox-group v-model="accountSetupForm.permissionsDivision" stacked>
              <b-checkbox value="Corredizo">Corredizo</b-checkbox>
              <b-checkbox value="Abatible">Abatible</b-checkbox>
              <b-checkbox value="Seccional">Seccional</b-checkbox>
              <b-checkbox value="Cortina">Cortina</b-checkbox>
            </b-checkbox-group>
          </b-form-group>
          <b-form-group v-if="accountSetupForm.role === 'supervisor_ventas'" label="Encargado de:">
            <b-checkbox v-model="accountSetupForm.permissionsSales" v-for="option in salespeople" :key="option.id" :value="option.id.toString()">
              {{ option | concatName3 }}
            </b-checkbox>
          </b-form-group>
        </b-row>
        <div v-if="accountSetupForm.role !== 'non_prod' && !employee.sap_sales_person_code">
          <hr>
          <b-row align-h="center" class="mt-4">
            <b-form-group label="Código de vendedor">
              <b-input v-model="accountSetupForm.sap_sales_person_code" type="number" size="sm" style="width: 150px"/>
            </b-form-group>
          </b-row>
        </div>
        <hr>
        <b-row v-if="configureWithEmail">
          <small class="text-muted">2) Enviar instrucciones a este empleado a través de su correo registrado sobre como configurar un login con contraseña segura.  El empleado debe configurar su login con 36 horas</small>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button variant="green" :disabled="!accountSetupFormValid" @click="updateAuth">
          <span v-if="configureWithEmail">Enviar Correo</span>
          <span v-else>Guardar</span>
        </Button>
      </template>
    </b-modal>

    <b-modal centered v-model="deactivateModalShow" title="Desactivar empleado" @shown="fetchChildren">
      <b-container class="px-3 fs-14">
        <b-row align-h="center" align-v="center" class="mt-4 text-danger">
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
          <span class="pl-2">Peligro</span>
        </b-row>
        <b-row class="mt-3">
          <p class="text-danger text-center">Desactivar este empleado resultará en que su cuenta se marque como "inactiva" y su inicio de sesión de aplicación se bloqueará</p>
        </b-row>
        <div v-if="employee.is_manager">
          <b-row>
            <p class="text-center">Los siguientes empleados requerirán nuevos jefes inmediatos para mantener la exactitud del organigrama:</p>
          </b-row>
          <b-row align-h="center" v-for="child in employeeChildren" :key="child.id">
            <small class="text-center">{{ child | concatName4 }}</small>
          </b-row>
        </div>
        <b-row align-h="center" class="mt-5">
          <b-form-checkbox v-model="deactivateCheck">
            Entiendo, quiero desactivar<br>{{ employee | concatName4 }}
          </b-form-checkbox>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button variant="red" :disabled="!deactivateCheck" @click="deactivateEmployee">
          Desactivar
        </Button>
      </template>
    </b-modal>

    <b-modal centered v-model="sapModalShow" title="Editar códigos de SAP">
      <b-container class="fs-14">
        <b-row align-h="center" class="mt-4">
          <b-form-group label="Número de empleado" label-size="sm">
            <b-input
              v-model="sapForm.sap_employee_id"
              autofocus
              type="number"
              size="sm"
              style="width: 150px"
            />
          </b-form-group>
        </b-row>
        <b-row align-h="center" class="mt-4">
          <b-form-group label="Código de vendedor" label-size="sm">
            <b-input
              v-model="sapForm.sap_sales_person_code"
              type="number"
              size="sm"
              style="width: 150px"
            />
          </b-form-group>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button variant="green" @click="updateSapCodes">
          Guardar
        </Button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BackButton from '../shared/BackButton'
import Button from '../shared/Button'
import PanelSection from '../shared/PanelSection'
import TitleBadge from '../shared/TitleBadge'
import ToastAlert from '../shared/ToastAlert'
import NavTabs from './NavTabs'
import { businessUnits } from '../../sharedOptions/employeeBusinessUnits'
import { departments } from '../../sharedOptions/departments'
import { divisions } from '../../sharedOptions/divisions'

export default {
  name: 'EmployeeShow',
  components: { ValidationObserver, ValidationProvider, BackButton, Button, PanelSection, TitleBadge, ToastAlert, NavTabs },
  data () {
    return {
      employeeId: Number(this.$route.params.id),
      employeeChildren: [],
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      processing: false,
      emailSent: false,
      manager: null,
      roles: [],
      editModalShow: false,
      editForm: {
        carnet: null,
        country: null,
        first_name: null,
        second_name: null,
        last_name: null,
        last_name2: null,
        email: null,
        cedula: null,
        start_date: null,
        payroll_type: null,
        job_title: null,
        is_manager: null,
        department: null,
        division: null,
        business_unit: null,
        parent_id: null
      },
      accountSetupModalShow: false,
      configureWithEmail: true,
      accountSetupForm: {
        role: 'non_prod',
        permissionsDivision: [],
        permissionsSales: [],
        sap_sales_person_code: null
      },
      deactivateModalShow: false,
      deactivateCheck: false,
      sapModalShow: false,
      sapForm: {
        sap_employee_id: null,
        sap_sales_person_code: null
      },
      deptOptions: departments,
      divisionOptions: divisions,
      businessUnitOptions: businessUnits,
      roleOptions: [
        { value: 'non_prod', text: 'No es de Producción' },
        { value: 'técnico', text: 'Técnico' },
        { value: 'vendedor', text: 'Vendedor' },
        { value: 'lider_producto', text: 'Líder de Producto' },
        { value: 'supervisor_ventas', text: 'Supervisor Ventas' },
        { value: 'supervisor_división', text: 'Supervisor División' },
        { value: 'gerente', text: 'Gerente' },
        { value: 'administrador', text: 'Administrador'}
      ]
    }
  },
  computed: {
    ...mapGetters(['getEmployeeById', 'managers', 'salespeople', 'isHrAdmin', 'isRoot', 'isDev', 'isSuperAdmin']),
    employee () { // What if employee not found?
      return this.getEmployeeById(this.employeeId)
    },
    accountSetupFormValid () {
      if (this.accountSetupForm.role !== 'non_prod') {
        if (this.accountSetupForm.role === 'supervisor_división') {
          return this.accountSetupForm.sap_sales_person_code && this.accountSetupForm.permissionsDivision.length > 0
        } else if (this.accountSetupForm.role === 'supervisor_ventas') {
          return this.accountSetupForm.sap_sales_person_code && this.accountSetupForm.permissionsSales.length > 0
        } else {
          return this.accountSetupForm.sap_sales_person_code || this.employee.sap_sales_person_code
        }
      } else {
        return true
      }
    }
  },
  mounted () {
    this.fetchManager()

    if (this.$route.params.created) {
      this.alertVariant = 'success'
      this.alertMessage = 'Empleado se ha agregado'
      this.alertShow = true
    }
  },
  methods: {
    openEditForm () {
      this.editModalShow = true
      this.editForm.carnet = this.employee.carnet
      this.editForm.country = this.employee.country
      this.editForm.first_name = this.employee.first_name
      this.editForm.second_name = this.employee.second_name
      this.editForm.last_name = this.employee.last_name
      this.editForm.last_name2 = this.employee.last_name2
      this.editForm.email = this.employee.email
      this.editForm.cedula = this.employee.cedula
      if (this.employee.start_date) { // to reformat start_date
        let array = this.employee.start_date.split('-')
        this.editForm.start_date = array[2] + '/' + array[1] + '/' + array[0]
      }
      this.editForm.payroll_type = this.employee.payroll_type
      this.editForm.job_title = this.employee.job_title
      this.editForm.is_manager = this.employee.is_manager
      this.editForm.department = this.employee.department
      this.editForm.division = this.employee.division
      this.editForm.business_unit = this.employee.business_unit
      if (this.manager) { this.editForm.parent_id = this.manager.id }
    },
    updateEmployee () {
      if (this.processing) { return }
      this.processing = true
      this.editModalShow = false
      this.$http.put(`/api/human_resources/users/${this.employeeId}`, {
          user: this.editForm
        })
        .then(response => {
          console.log(response)
          this.$store.commit('setEmployeeData', response.data)
          this.fetchManager()
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios se guardaron'
        })
        .catch(error => {
          this.alertVariant = 'danger'
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo guardar los cambios'
          }
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
        })
    },
    // necessary???
    fetchManager () {
      this.$http.get(`/api/human_resources/users/${this.employeeId}/fetch_manager`)
        .then((res) => {
          console.log(res)
          if (res.data.manager) {
            this.manager = res.data.manager
          }
          this.roles = res.data.roles
        })
        .catch(e => console.log(e))
    },
    editAuth () {
      this.accountSetupForm.sap_sales_person_code = this.employee.sap_sales_person_code
      this.accountSetupForm.role = this.employee.role
      if (this.employee.role === 'supervisor_división') {
        this.accountSetupForm.permissionsDivision = this.employee.permissions
      } else if (this.employee.role === 'supervisor_ventas') {
        this.accountSetupForm.permissionsSales = this.employee.permissions.filter(id => id && Number(id) !== this.employee.id)
      }
      this.configureWithEmail = false
      this.accountSetupModalShow = true
    },
    updateAuth () {
      if (this.processing) { return }
      this.processing = true
      this.emailSent = true
      this.accountSetupModalShow = false
      let permissions
      if (this.accountSetupForm.role === 'supervisor_división') {
        permissions = this.accountSetupForm.permissionsDivision
      } else if (this.accountSetupForm.role === 'supervisor_ventas') {
        permissions = this.accountSetupForm.permissionsSales
        permissions.unshift(this.employee.id.toString())
      } else {
        permissions = []
      }
      this.$http.put(`/api/human_resources/users/${this.employeeId}/update_auth`, {
          with_email: this.configureWithEmail,
          user:  {
            role: this.accountSetupForm.role,
            permissions: permissions,
            sap_sales_person_code: this.accountSetupForm.sap_sales_person_code
          }
        })
        .then(response => {
          console.log(response)
          // TODO: update current user permission if necessary
          this.$store.commit('setEmployeeData', response.data);
          this.alertVariant = 'success'
          if (this.configureWithEmail) {
            this.alertMessage = 'Correo ha sido enviado con éxito';
          } else {
            this.alertMessage = 'Autorización se actualizó con éxito';
          }
        })
        .catch(e => {
          console.log(e)
          // TODO: missing helpful error message if duplicate sap salesperson code is submitted
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
        });
    },
    fetchChildren () {
      this.$http.get(`/api/human_resources/users/${this.employeeId}/fetch_children`)
        .then(response => {
          console.log(response)
          this.employeeChildren = response.data
        })
        .catch(e => console.log(e))
    },
    deactivateEmployee () {
      if (this.processing) { return }
      this.processing = true
      this.deactivateModalShow = false
      this.$http.put(`/api/human_resources/users/${this.employeeId}/deactivate`)
        .then(response => {
          console.log(response)
          this.$store.commit('setEmployeeData', response.data)
          // TODO: update ancestry of children in store
          this.$router.push({ name: 'InactiveEmployeeShow', params: { id: response.data.id, deactivated: true }})
        })
        .catch(error => {
          console.log(error)
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(this.processing = false)
    },
    openAccountSetupModal () {
      this.accountSetupForm.sap_sales_person_code = this.employee.sap_sales_person_code
      this.configureWithEmail = true
      this.accountSetupModalShow = true
    },
    openSapModal () {
      this.sapForm.sap_employee_id = this.employee.sap_employee_id
      this.sapForm.sap_sales_person_code = this.employee.sap_sales_person_code
      this.sapModalShow = true
    },
    updateSapCodes () {
      if (this.processing) { return }
      this.processing = true
      this.$http.put(`/api/human_resources/users/${this.employeeId}`, {
          user: this.sapForm
        })
        .then(response => {
          console.log(response)
          this.$store.commit('setEmployeeData', response.data)
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios se guardaron'
        })
        .catch(error => {
          this.alertVariant = 'danger'
          if (error.response && error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo guardar los cambios'
          }
        })
        .finally(() => {
          this.processing = false
          this.sapModalShow = false
          this.alertShow = true
        })
    },
    // Dev only
    syncUser () {
      if (this.processing) return
      this.processing = true
      this.$http.get(`/api/human_resources/users/${this.employeeId}/sync`)
        .then(response => {
          console.log(response.data)

        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    fetchSapData () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/human_resources/users/${this.employeeId}`)
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setEmployeeData', res.data)
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    // Temporary - Dev only
    markAsDMTechnician () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/human_resources/users/${this.employeeId}/set_role`, {
        user: {
          role: 'Técnico - DM'
        }
      })
        .then((res) => {
          console.log(res.data)
          this.fetchManager()
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    }
  }
}
</script>

<style scoped>
.label {
  width: 150px;
  color: #596981;
}
.table {
  margin-bottom: 0;
}
.b-form-group {
  margin-bottom: 2em;
}
.short-input {
  width: 50%;
}
</style>
