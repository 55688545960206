<template>
  <b-container>
    <!-- Register Form Modal -->
    <b-modal
      id="register-modal"
      size="lg"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      scrollable
      @shown="onModalShow"
      @hide="onModalHide"
    >
      <template #modal-title>
        <div>
          <span>Transacción: {{ transaction.internal_code_1 }} </span>
        </div>
      </template>
      <b-row align-h="center" class="p-2">
        <b-col>
          <ValidationObserver ref="observer1" v-slot="{invalid}">
            <!-- user_credit_card -->
            <b-form class="pb-3">
              <b-row>
                <b-col md="12" lg="3">
                  <span>Número de tarjeta:</span>
                </b-col>
                <b-col md="12" lg="8">
                  <b-input type="text" id="credit_card" :disabled="true" style="width: 100%;" :value="transaction.user_credit_card" size="sm" @keydown.enter="event => event.preventDefault()"/>
                </b-col>
              </b-row>
            </b-form>
            <!-- Total -->
            <b-form class="pb-3">
              <b-row>
                <b-col md="12" lg="3">
                  <span>Monto factura:</span>
                </b-col>
                <b-col md="12" lg="8">
                  <b-input v-if="transaction.currency == 'USD'" type="text" id="transaccion" :disabled="true" style="width: 100%;" :value="transaction.total_usd | dollarize" size="sm" @keydown.enter="event => event.preventDefault()"/>
                  <b-input v-else type="text" id="transaccion" :disabled="true" style="width: 100%;" :value="transaction.total | colones2" size="sm" @keydown.enter="event => event.preventDefault()"/>
                </b-col>
              </b-row>
            </b-form>
            <!-- provider_name -->
            <ValidationProvider :rules="{required: true}" name="Nombre proveedor" v-slot="{ }">
              <b-form class="pb-3">
                  <b-row>
                    <b-col md="12" lg="3">
                      <span>Nombre proveedor:</span><span class="asterisk">*</span>
                    </b-col>
                    <b-col md="12" lg="8">
                      <b-input
                        type="text"
                        id="businessName"
                        :disabled="true"
                        style="width: 100%;"
                        :value="validateBusinessIdForm.businessName"
                        :state="validateBusinessIdForm.confirmed ? true : false"
                        size="sm" placeholder="Debe confirmar la cédula del proveedor"
                        @keydown.enter="event => event.preventDefault()"
                      />
                    </b-col>
                  </b-row>
              </b-form>
            </ValidationProvider>
            <!-- business_id -->
            <ValidationProvider :rules="{required: true, numeric: true}" name="Cédula" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="3">
                    <span>Cédula proveedor:</span><span class="asterisk">*</span>
                  </b-col>
                  <b-col md="9" lg="6">
                    <b-input
                      type="number"
                      v-model="form.business_id"
                      size="sm"
                      style="width: 100%;"
                      ref="businessId"
                      placeholder="Ej: 3101177456"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @blur="validateBusinessIdForm.confirmed ? null : openConfirmModal()"
                      @keydown.enter="event => event.preventDefault()"
                    />
                  </b-col>
                  <b-col md="3" lg="2" class="pt-2 pt-md-0">
                    <b-button
                      size="sm"
                      variant="info"
                      class="w-100"
                      :disabled="!form.business_id"
                      @click="openConfirmModal">
                      Buscar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- order_doc_no -->
            <ValidationProvider :rules="{numeric: true}" name="Orden" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5" class="pr-0">
                    <span>Número de Orden(OI-OR-OS-OM):</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-input type="text" ref="orderDocNo" v-model="form.order_doc_no" size="sm" style="width: 100%;" :state="errors[0] ? false : (valid ? true : null)" @keydown.enter="event => event.preventDefault()" />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- purchase_order -->
            <ValidationProvider :rules="{numeric: true}" name="Orden de compra" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5" class="pr-0">
                    <span>Número de Orden de Compras(PO):</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-input type="text" v-model="form.purchase_order" size="sm" style="width: 100%;" :state="errors[0] ? false : (valid ? true : null)" @keydown.enter="event => event.preventDefault()" />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- product_receipt -->
            <ValidationProvider :rules="{numeric: true}" name="Entrada mercaderia" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5" class="pr-0">
                    <span>Entrada mercadería:</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-input type="text" v-model="form.product_receipt" size="sm" style="width: 100%;" :state="errors[0] ? false : (valid ? true : null)" @keydown.enter="event => event.preventDefault()" />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- Other Business Unit -->
            <ValidationProvider :rules="{required: true}" name="¿Su unidad asume?" v-slot="{ valid, errors }">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-row>
                  <b-col cols="12" md="7" lg="5" class="pr-0">
                    <span>¿Su unidad asume el gasto?</span>
                  </b-col>
                  <b-col cols="12" md="4" lg="7">
                    <b-form-radio-group
                      id="isBusinessUnit"
                      v-model="otherBusinessUnit"
                      :aria-describedby="ariaDescribedby"
                      :state="errors[0] ? false : (valid ? true : null)"
                      name="isBusinessUnit"
                      @change="form.business_unit = null"
                    >
                      <b-form-radio  :value="true">Si</b-form-radio>
                      <b-form-radio  :value="false">No</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </ValidationProvider>
            <!-- business_unit -->
            <ValidationProvider v-if="!otherBusinessUnit" :rules="{required: true}" name="Unidad de negocio" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5" class="pr-0">
                    <span>Unidad que asume el gasto:</span><span class="asterisk">*</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-select
                      v-model="form.business_unit"
                      :options="businessUnitOptions"
                      size="sm"
                      style="width: 100%;"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <option :value="null" disabled>-- Seleccione... --</option>
                      </template>
                    </b-select>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- notes -->
            <ValidationProvider :rules="{required: true, max:253}" name="Comentarios" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5" class="pr-0">
                    <span>Comentarios:</span><span class="asterisk">*</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-textarea
                    v-model="form.notes"
                    size="sm"
                    rows="2"
                    style="width: 100%;"
                    :state="errors[0] ? false : (valid ? true : null)"
                    />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <b-row>
              <b-col cols="6" md="6" lg="5">
                <span>Foto factura:</span><span class="asterisk">*</span>
              </b-col>
              <b-col v-if="!createdPhoto" cols="5" md="6" lg="6" class="align-self-center">
                <ImageUploader v-if="!creatingPhoto" :file-type="'input'" btn-label="Agregar" @photo-added="createPhoto" @preview-generated="storePreview" />
                <div v-else class="text-center text-info">
                  <b-spinner small type="grow"></b-spinner>
                  <span class="pl-2">Subiendo foto...</span>
                </div>
              </b-col>
              <b-col v-else md="12" lg="6" class="align-self-center">
                <b-row class="align-items-center">
                  <b-col cols="8" @click="openThumbnail">
                    <div class="preview-anchor"/>
                  </b-col>
                  <b-col class="mt-1 self-end" cols="4">
                    <b-button size="sm" variant="danger" class="fs-13" @click="deleteModalShow = true">Eliminar</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr>
            <!-- purchase_type -->
            <ValidationProvider :rules="{required: true}" name="Tipo de gasto" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5" class="pr-0">
                    <span>Tipo de gasto:</span><span class="asterisk">*</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-select
                      v-model="selectedPurchaseType"
                      :options="purchaseTypeOptions"
                      size="sm"
                      style="width: 100%;"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @change="getPurchaseSubtypes"
                    >
                      <template v-slot:first>
                        <option :value="null" disabled>-- Seleccione... --</option>
                      </template>
                    </b-select>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- purchase_subtype -->
            <ValidationProvider v-if="purchaseSubtypeOptions.length > 0" :rules="{required: true}" name="Subtipos">
              <b-form-group v-slot="{ ariaDescSubt }">
                <b-row>
                  <b-col sm="3" lg="5" class="pr-0">
                    <span>Subtipos:</span><span class="asterisk">*</span>
                  </b-col>
                  <b-col sm="9" lg="7">
                    <b-form-checkbox-group
                      v-model="selectedPurchaseSubtypes"
                      :options="purchaseSubtypeOptions"
                      :aria-describedby="ariaDescSubt"
                      name="Subtipos"
                      stacked
                      value-field="item"
                      text-field="name"
                    ></b-form-checkbox-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </ValidationProvider>
            <!-- business_id -->
            <ValidationProvider v-if="isCarIdNeeded" :rules="{required: true}" name="placa" v-slot="{ valid, errors }">
              <b-form class="pb-3">
                <b-row>
                  <b-col md="12" lg="5">
                    <span>Placa vehículo:</span><span class="asterisk">*</span>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-select
                      v-model="form.car_id"
                      :options="carIdOptions"
                      size="sm"
                      style="width: 100%;"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <option :value="null" disabled>-- Seleccione... --</option>
                      </template>
                    </b-select>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- Card items -->
            <b-row>
              <table class="table border-left border-right border-bottom mb-1">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th class="p-0">Subtipo</th>
                    <th class="p-0">Total<span class="asterisk">*</span></th>
                  </tr>
                </thead>
                <tbody ref="itemContainer">
                  <tr v-for="item in cardItemsForm" :key="item.purchase_account_code_id">
                    <td class="align-middle p-1 pl-2 fs-13">
                      {{ item.purchase_subtype }}
                    </td>
                    <td class="align-middle p-1">
                      <ValidationProvider v-if="transaction.currency == 'USD'" :rules="{required: true, double: true}" name="total" v-slot="{ valid, errors }">
                        <b-form class="p-1">
                          <b-input
                            v-model="item.total"
                            type="text"
                            size="sm"
                            :placeholder="'$ '+transaction.total_usd"
                            style="width: 84%;"
                            :state="errors[0] ? false : (valid ? true : null)"
                            @keydown.enter="event => event.preventDefault()"
                          />
                        </b-form>
                      </ValidationProvider>
                      <ValidationProvider v-else :rules="{required: true, double: true}" name="total" v-slot="{ valid, errors }">
                        <b-form class="p-1">
                          <b-input
                            v-model="item.total"
                            type="text"
                            size="sm"
                            :placeholder="'₡ '+transaction.total"
                            style="width: 84%;"
                            :state="errors[0] ? false : (valid ? true : null)"
                            @keydown.enter="event => event.preventDefault()"
                          />
                        </b-form>
                      </ValidationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row align-h="between" class="border fs-15" v-if="selectedPurchaseSubtypes.length > 0">
              <b-col class="border-right">
                <b-row class="pl-3">
                  <span>Total registrado: </span>
                  <div v-if="transaction.currency == 'USD'" class="pl-1 font-weight-bold">
                    <span :class="{
                      'text-success': totalItems == parseFloat(transaction.total_usd),
                      'text-danger': totalItems != parseFloat(transaction.total_usd),
                      }"
                    >
                      {{ totalItems | dollarize }}
                    </span>
                  </div>
                  <div v-else class="pl-1 font-weight-bold">
                    <span :class="{
                      'text-success': totalItems == parseFloat(transaction.total),
                      'text-danger': totalItems != parseFloat(transaction.total),
                      }"
                    >
                      {{ totalItems | colones2 }}
                    </span>
                  </div>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="pl-3">
                  <span>Total factura: </span>
                  <div v-if="transaction.currency == 'USD'" class="pl-1 font-weight-bold">
                    <span :class="{
                      'text-success': totalItems == parseFloat(transaction.total_usd),
                      'text-dark': totalItems != parseFloat(transaction.total_usd),
                      }"
                    >
                      {{ transaction.total_usd | dollarize }}
                    </span>
                  </div>
                  <div v-else class="pl-1 font-weight-bold">
                    <span :class="{
                      'text-success': totalItems == parseFloat(transaction.total),
                      'text-dark': totalItems != parseFloat(transaction.total),
                      }"
                    >
                      {{ transaction.total | colones2 }}
                    </span>
                  </div>
                </b-row>
              </b-col>
            </b-row>
            <!-- Button bar -->
            <b-row align-h="around" class="mx-4 pt-2">
              <Button variant="red" @click="closeModal()">
                Cancelar
              </Button>
              <Button
                variant="green"
                :disabled="invalid || invalidRegisterForm || validTotalItems"
                @click="updateTransaction()">
                Actualizar
              </Button>
            </b-row>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-modal>

    <!-- delete photo Modal -->
    <b-modal
      v-model="deleteModalShow"
      size="sm"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" class="text-center">
        <b class="fs-18 m-1">¿Desea eliminar la foto?</b>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center">NOTA: Tenga en cuenta que los cambios no se podrán revertir</small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="deleteModalShow = false;">
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-ligth"/>
        </Button>
        <Button variant="green" @click="deleteModalShow = false; deletePhoto(createdPhoto.id)">
          <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-ligth"/>
        </Button>
      </b-row>
    </b-modal>

    <!-- Confirm provider business_id -->
    <b-modal
      v-model="confirmBusinessIdModalShow"
      size="md"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      @shown="onValidateBusinessId"
    >
      <div class="d-flex justify-content-between align-items-center border-bottom">
        <div class="m-1">
          <b class="fs-20">Verificación de cédula del proveedor</b>
          <p class="text-muted fs-14 mb-0" style="line-height: 11px;padding-left: 2px">Mediante el Ministerio de Hacienda</p>
          <!-- <p v-if="!validateBusinessIdForm.validating" class="text-secondary text-left mb-0 fs-14" style="line-height: 14px;padding-left: 2px;padding-right: 2px">
            Si la transacción no requiere de cédula, por favor presione <b>'Confirmar'</b>
          </p> -->
        </div>
        <b-button size="sm" class="p-0 m-0" variant="outline-danger" @click="confirmBusinessIdModalShow = false">
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
        </b-button>
      </div>
      <b-row align-h="center" align-v="center" class="px-2 text-secondary">
        <b-col>
          <p v-if="validateBusinessIdForm.validating" class="text-center text-info mb-0 py-3">
            <b-spinner small type="grow"></b-spinner>
            <span class="pl-2">Validando la cédula con el Ministerio de Hacienda...</span>
          </p>
          <p v-if="!validateBusinessIdForm.validating && validateBusinessIdForm.error" class="text-danger text-center mb-0 py-3">
            {{ validateBusinessIdForm.error }}
          </p>
          <div v-if="!validateBusinessIdForm.validating && !validateBusinessIdForm.error && validateBusinessIdForm.businessName" class="pb-3 pt-1">
            <p class="fs-16 mb-1 text-dark text-center">Resultado de la consulta</p>
            <p class="mb-0">Cédula: {{ form.business_id }}</p>
            <p class="mb-0">Nombre: {{ validateBusinessIdForm.businessName }}</p>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-between align-items-center my-2">
        <Button
          variant="red"
          :disabled="validateBusinessIdForm.validating"
          @click="onFixBusinessId"
        >
          Corregir
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-ligth"/>
        </Button>
        <Button
          variant="orange"
          :disabled="validateBusinessIdForm.validating"
          @click="onValidateBusinessId"
        >
          Reintentar
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-ligth"/>
        </Button>
        <Button
          variant="green"
          :disabled="validateBusinessIdForm.validating"
          @click="onConfirmBusinessId(validateBusinessIdForm.businessName)"
          >
          Confirmar
          <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-ligth"/>
        </Button>
      </div>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </b-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { format } from 'date-fns';

import Button from '../../shared/Button.vue'
import { businessUnits} from '../../../sharedOptions/purchaseRequestBusinessUnits';
import ImageUploader from '../../shared/ImageUploader'
import ToastAlert from '../../shared/ToastAlert'
import { event } from "jquery";

export default {
  name: 'RegisterModal',
  components : { ValidationObserver, ValidationProvider, ImageUploader, Button, ToastAlert},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    purchaseTypeOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      processing: false,
      creatingPhoto: false,
      deleteModalShow: false,
      otherBusinessUnit: true,
      businessUnitOptions: businessUnits,
      selectedPurchaseType: null,
      storedPreview: null,
      createdPhoto: null,
      purchaseSubtypeOptions: [],
      selectedPurchaseSubtypes: [],
      cardItemsForm:[],
      selectedAccountCodes: [],
      form: {
        business_id: null,
        order_doc_no: null,
        purchase_order: null,
        product_receipt: null,
        business_unit: null,
        car_id: null,
        notes: null,
        photo_link: null,
        status: 'por_aprobar',
        status_date: format(new Date, 'dd-MM-yyyy')
      },
      validateBusinessIdForm: {
        businessName: null,
        validating: false,
        error: null,
        confirmed: false
      },
      carIdOptions: [],
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      confirmBusinessIdModalShow: false
    }
  },
  watch: {
    selectedPurchaseSubtypes (to, from) {
      let cardTransactionTotal = null
      if (this.transaction.currency == 'USD') {
        cardTransactionTotal = this.transaction.total_usd
      } else if (this.transaction.currency == 'COL') {
        cardTransactionTotal = this.transaction.total
      }
      let itemsform = to.map(el => (
        {
          total: cardTransactionTotal,
          purchase_account_code_id: el.id,
          account_code: el.account_code,
          purchase_subtype: el.purchase_subtype
        }
      ))
      this.selectedAccountCodes = to.map(el => el.account_code)
      this.cardItemsForm = Object.assign({}, itemsform)
    },
    isCarIdNeeded (to, from) {
      if (this.carIdOptions.length == 0) {
        this.getCarIdOptions()
      }
    }
  },
  computed : {
    invalidRegisterForm () {
      if (!this.createdPhoto) {
        return true
      }
      if (this.selectedPurchaseSubtypes.length > 0) {
        const elements = Object.values(this.cardItemsForm)
        const lineTotals = elements.map(el => el.total)
        return lineTotals.some(val => val == '' || val == null)
      } else {
        return true
      }
    },
    totalItems () {
      if (this.selectedPurchaseSubtypes.length > 0) {
        const elements = Object.values(this.cardItemsForm)
        const total = elements.map( el => {
          if (el.total != '' && el.total != null ) {
            return parseFloat(el.total)
          } else {
            return 0
          }
        }).reduce((acum, item) =>  {
          return acum + item
        }, 0)
        return total.toFixed(2)
      }
    },
    validTotalItems () {
      if (this.transaction && this.totalItems > 0) {
        if (this.transaction.currency == 'USD' && parseFloat(this.transaction.total_usd) != this.totalItems ) {
          return true
        } else if (this.transaction.currency == 'COL' && parseFloat(this.transaction.total) != this.totalItems){
          return true
        } else {
          return false
        }
      }
    },
    isCarIdNeeded () {
      if (this.selectedAccountCodes.length > 0) {
        let result = false
        const assetCodes = ['1-6110501', '6110502', '1-6110705', '2-6110705', '6110504', '6110705']
        this.selectedAccountCodes.forEach(code => {
          if (assetCodes.includes(code)) { result = true }
        })
        return result
      }
      return false
    },
    isAssetTransaction () {
      if (this.selectedAccountCodes.length > 0) {
        let result = false
        const assetCodes = ['1-1210114', '2-1210114']
        this.selectedAccountCodes.forEach(code => {
          if (assetCodes.includes(code)) { result = true }
        })
        return result
      }
      return false
    },
  },
  methods : {
    onModalShow () {
      const { business_id, order_doc_no, purchase_order, product_receipt, car_id, notes, business_unit } = this.transaction
      this.form = {
        ...this.form,
        business_id,
        order_doc_no,
        purchase_order,
        product_receipt,
        business_unit,
        car_id,
        notes
      }
      if (business_unit) {
        this.otherBusinessUnit = false;
      }
    },
    updateTransaction () {
      if (this.processing) { return }
      this.processing = true
      let transaction = Object.assign({},this.form)
      transaction['status'] = this.setStatus()
      transaction['car_id'] = this.isCarIdNeeded ? transaction.car_id : null
      transaction['rejection_notes'] = null
      let data = {
        card_transaction : transaction,
        card_transaction_item : Object.values(this.cardItemsForm),
      }
      this.$http.patch(`/api/production/card_transactions/${this.transaction.id}`,data)
        .then(response => {
          this.$emit('update-transaction', response.data.card_transaction)
          this.alertVariant = 'success'
          this.alertMessage = 'Transacción registrada correctamente'
          this.alertShow = true
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          this.alertMessage = 'Error al realizar el registro, intente nuevamente'
          this.alertShow = true
        })
        .finally(() => {
          this.processing = false
          this.registerModalShow = false
          this.onModalHide()
        })
    },
    getCarIdOptions () {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('/api/vehicles')
        .then(response => {
          // console.log(response);
          this.formatCarIdOptions(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        })
    },
    formatCarIdOptions (data) {
      this.carIdOptions =  data.map(option => ({ value: option.car_id, text: option.car_id}))
    },
    setStatus () {
      const { purchase_order, business_unit } = this.form
      let status = 'por_aprobar'
      if (this.isAssetTransaction) {
        if (!purchase_order || purchase_order.length <= 3) {
          return 'pendiente_af'
        }
      }
      if (this.transaction.user_card_auto_approved && !business_unit) {
        status = 'aprobada'
      }
      return status
    },
    closeModal () {
      if (this.createdPhoto) {
        this.deletePhoto(this.createdPhoto.id)
      }
      this.onModalHide()
    },
    onModalHide () {
      this.deleteModalShow = false
      this.processing= false
      this.creatingPhoto = false
      this.otherBusinessUnit = true
      this.form = {
        business_id: null,
        order_doc_no: null,
        purchase_order: null,
        product_receipt: null,
        business_unit: null,
        notes: null,
        photo_link: null,
        status: null
      }
      this.createdPhoto = null
      this.storedPreview = null
      this.selectedPurchaseType = null
      this.selectedPurchaseSubtypes = []
      this.purchaseSubtypeOptions = []
      this.cardItemsForm = []
      this.selectedAccountCodes = []
      this.$emit('modal-hidden')
      this.validateBusinessIdForm = {
        businessName: null,
        validating: false,
        error: null,
        confirmed: false
      }
    },
    getPurchaseSubtypes (purchaseType) {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('/api/purchase_account_codes/purchase_subtypes', {
          params: { purchase_type: purchaseType}
        })
        .then(response => {
          // console.log(response);
          this.formatPurchaseSubtypes(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          this.form.car_id = null
          this.selectedPurchaseSubtypes = []
          this.cardItemsForm = []
        })
    },
    formatPurchaseSubtypes (data) {
      this.purchaseSubtypeOptions =  data.map(term => ({
                                                        name: term.purchase_subtype,
                                                        item: {
                                                          id: term.id,
                                                          account_code: term.account_code,
                                                          purchase_subtype: term.purchase_subtype
                                                        }}))
    },
    createPhoto (form) {
      if (this.creatingPhoto) { return }
      this.creatingPhoto = true
      this.$http.post(`/api/production/card_transactions/${this.transaction.id}/photos`, {
        photo: form
      })
        .then((res) => {
          // console.log('res', res);
          this.createdPhoto = res.data.photo
          this.$nextTick(() => {
            const anchors = document.getElementsByClassName('preview-anchor')
            Array.from(anchors).forEach(anc => {
              const img = document.createElement('img')
              img.src = this.storedPreview
              img.style.maxHeight = '200px'
              img.style.maxWidth = '200px'
              img.style.maxWidth = '200px'
              img.style.borderRadius = '10px'
              img.style.objectFit = 'cover'
              img.style.backgroundColor = '#fff'
              img.style.cursor= 'pointer'
              anc.appendChild(img)
            })
          })
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.creatingPhoto = false
        })
    },
    storePreview (preview) {
      this.storedPreview = preview
    },
    deletePhoto (id) {
      this.$http.delete(`/api/production/card_transactions/${this.transaction.id}/photos/${id}`)
        .then((res) => {
          console.log(res)
          this.createdPhoto = null
        })
        .catch(e => console.log(e))
    },
    openThumbnail() {
      if (this.storedPreview) {
        window.open(this.storedPreview, '__blank')
      }
    },
    openConfirmModal () {
      if (this.confirmBusinessIdModalShow || !this.form.business_id) return
      this.confirmBusinessIdModalShow = true
    },
    onValidateBusinessId () {
      if (!this.form.business_id) {
        this.validateBusinessIdForm.error = 'Debe ingresar el número cédula del proveedor'
        const timeId = setTimeout(() => this.confirmBusinessIdModalShow = false, 2000)
        return clearTimeout(timeId)
      }
      this.validateBusinessIdForm.validating = true
      this.validateBusinessIdForm.businessName = null
      this.validateBusinessIdForm.error = null
      this.$http.get(`https://api.hacienda.go.cr/fe/ae?identificacion=${this.form.business_id}`, {
        timeout: 4000
      })
      .then(response => {
        this.validateBusinessIdForm.error = null
        this.validateBusinessIdForm.businessName = response.data.nombre
      })
      .catch(error => {
        console.log('error: ', error);
        if (error.response) {
          if (error.response.status == 404) {
            //
            this.validateBusinessIdForm.error = `No hay coincidencias del número de cédula ${this.form.business_id}, intente de nuevo o presione 'Confirmar' para continuar con el registro`
          } else {
            this.validateBusinessIdForm.error = "Error al consultar la cédula, intente de nuevo o presione 'Confirmar' para continuar con el registro"
          }
        } else {
          this.validateBusinessIdForm.error = "Error al consultar la cédula,  intente de nuevo o presione 'Confirmar' para continuar con el registro"
        }
      })
      .finally(() => {
        this.validateBusinessIdForm.validating = false
      })
    },
    onFixBusinessId () {
      // input focus
      this.confirmBusinessIdModalShow = false
      this.validateBusinessIdForm.businessName = null
      this.validateBusinessIdForm.validating = false
      this.validateBusinessIdForm.error = null
      this.validateBusinessIdForm.confirmed = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.businessId.select()
          this.$refs.businessId.focus()
        }, 200);
      })
    },
    onConfirmBusinessId (businessName) {
      this.validateBusinessIdForm.businessName = businessName || 'Cédula confirmada pero no válida'
      this.validateBusinessIdForm.confirmed = true
      this.confirmBusinessIdModalShow = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.orderDocNo.select()
          this.$refs.orderDocNo.focus()
        }, 200);
      })
    }
  }
}
</script>
