<template>
  <div>
    <b-modal
      id="document-uploader-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      size="lg"
      @show="fetchingDocuments = true"
      @shown="fetchDocuments"
      @hide="onHideDocumentsModal"
    >
      <b-row align-h="center" v-if="resource">
        <b-col>
          <h5 class="text-center">
            Archivos adjuntos: {{ title }}
          </h5>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="4">
          <p>Subir archivos:</p>
        </b-col>
        <b-col cols="8">
          <p class="text-center">Archivos subidos:</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <FileUploader v-if="uploadAllowed && editAllowed" @file-added="createDocument"/>
          <b-card v-else class="text-center text-warning">
            <h4><font-awesome-icon :icon="['far', 'exclamation-circle']" size="xl" fixed-width /></h4>
            <p>No permitido</p>
            <small class="text-secondary">Cantidad de archivos permitidos: {{ allowedDocuments }}</small>
          </b-card>
        </b-col>
        <b-col cols="8" class="border-left">
          <b-row v-if="fetchingDocuments" align-h="center">
            <div class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando archivos...</span>
            </div>
          </b-row>
          <b-row v-else>
            <b-col v-if="documents.length > 0 & !fetchingDocuments">
              <b-row v-for="(doc, index) in documents" :key="doc.id">
                <b-col cols="10">
                  <b-list-group>
                    <b-list-group-item>
                      <b-link @click="downloadDocument(doc)">{{doc.name}}</b-link>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2" class="p-0" align-self="center">
                  <Button size="sm" :disabled="!editAllowed" variant="red" @click="onDeleteClick(doc, index)">
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </Button>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else>
              <div class="text-center text-info">
                <span>Sin archivos registrados</span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <b-row align-h="around">
        <Button variant="orange" @click="onHideDocumentsModal()">salir</Button>
      </b-row>
    </b-modal>

    <!-- CONFIRMATION: Delete-->
    <b-modal
      v-model="deleteConfirmationModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'question-circle']"  class="text-info modal-icon" />
      </b-row>
      <b-row align-h="center">
        <span class="fs-20 text-center">¿Desea eliminar el archivo?</span>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center">NOTA: El proceso es irreversible</small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="deleteConfirmationModal = false">No</Button>
        <Button variant="green" @click="deleteDocument()">Sí</Button>
      </b-row>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { normalize, schema } from 'normalizr';
import FileUploader from './FileUploader';
import Button from './Button'
import ToastAlert from './ToastAlert';

export default {
  name: 'DocumentUploaderModal',
  components: { Button, FileUploader, ToastAlert },
  props: {
    resourceName: { // should be in snake case for scope url
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    resource: {
      type: Object,
      required: true
    },
    allowedDocuments: {
      type: Number,
      required: true
    },
    editAllowed: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      processing: false,
      fetchingDocuments: false,
      deleteConfirmationModal: false,
      documentData: {},
      documentList: [],
      selectedDocument: null,
      selectedDocumentIndex: null,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  computed: {
    documents (){
      return this.documentList.map(id => this.documentData[id])
    },
    uploadAllowed () {
      if (this.fetchingDocuments) { return false }
      return this.documents.length < this.allowedDocuments
    }
  },
  methods: {
    fetchDocuments () {
      if (this.processing) { return }
      this.processing = true
      this.fetchingDocuments = true
      this.$http.get(`/api/production/${this.resourceName}/${this.resource.id}/documents`)
        .then((resp) => {
          // console.log('response document:', resp);
          this.normalizeDocuments(resp.data)
        })
        .catch(e => console.log(e))
        .finally(()=>{
          this.processing = false
          setTimeout(()=> this.fetchingDocuments = false, 800)
        })
    },
    normalizeDocuments (files) {
      const data = normalize(
        { documents: files },
        { documents: [ new schema.Entity('documents') ] }
      )
      if (data.entities.hasOwnProperty('documents')) {
        this.documentData = data.entities.documents
      }
      this.documentList = data.result.documents
    },
    onHideDocumentsModal () {
      this.selectedDocument = null
      this.selectedDocumentIndex = null
      this.documentData = {}
      this.documentList = []
      this.$emit('modal-hidden')
    },
    createDocument (form){
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/${this.resourceName}/${this.resource.id}/documents`, {
        document: form
      })
        .then((resp) => {
          console.log('response document:', resp);
          const createdDocument = resp.data.document
          this.$set(this.documentData, createdDocument.id, createdDocument)
          this.documentList.push(createdDocument.id)
        })
        .catch((e) => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'El archivo no se subio correctamente, archivos validos (.xlsx,.pdf)'
          this.alertShow = true
        })
        .finally(()=>{
          this.processing = false
        })
    },
    downloadDocument (doc){
      // console.log(doc);
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/${this.resourceName}/${this.resource.id}/documents/${doc.id}/download`)
        .then((resp) => {
          // console.log(resp.data)
          const link = document.createElement('a')
          link.href = resp.data
          link.download = doc.name
          link.click()
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    onDeleteClick (doc, index){
      this.selectedDocument = doc
      this.selectedDocumentIndex = index
      this.deleteConfirmationModal = true
    },
    deleteDocument () {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/${this.resourceName}/${this.resource.id}/documents/${this.selectedDocument.id}`)
        .then((resp) => {
          this.documentList.splice(this.selectedDocumentIndex, 1)
          delete this.documentData[this.selectedDocument.id]
        })
        .catch(e => console.log(e))
        .finally(()=> {
          this.deleteConfirmationModal = false
          this.processing = false
        })
    }
  }
}
</script>