<template>
  <div id="card-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionCard'
}
</script>

<style scoped>
#card-container {
  max-width: 1000px;
  background-color: #fff;
  padding: 1em;
  margin-bottom: 2em;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
</style>

