<template>
  <b-container fluid>
    <b-row align-h="center" class="my-4 px-2 px-sm-1">
      <b-col lg="10" sm="11">
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ContentView'
}
</script>
