<template>
  <tr>
    <td>
      <QuoteItemAddLineText
        v-if="quote.is_open"
        :quote-id="quote.id"
        @quote-item-added="addQuoteItem"
      />
    </td>
    <td>
      <b-input-group v-if="inputShow" size="sm">
        <b-input
          v-model="searchTerm"
          placeholder="Ingresar código exacto"
          autofocus
          @keyup.enter="searchAndAdd"
        />
        <b-input-group-append>
          <b-button variant="outline-secondary" size="sm" @click="searchAndAdd">
            <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse fixed-width />
            <span v-else>⏎</span>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <Button v-else size="sm" variant="green" @click="inputShow = true">
        <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
        <span class="pl-1">Artículo rápido</span>
      </Button>
    </td>
    <td class="align-middle text-danger">
      <span v-if="feedbackMessage"><font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />  {{ feedbackMessage }}</span>
    </td>
    <td colspan="4"></td>
  </tr>
</template>

<script>
import Button from '../shared/Button'
import QuoteItemAddLineText from './QuoteItemAddLineText'

export default {
  name: 'QuoteItemQuickAddRow',
  components: { Button, QuoteItemAddLineText },
  props: {
    quote: {
      type: Object,
      required: false
    },
    quoteItems: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      searchTerm: '',
      feedbackMessage: '',
      inputShow: false,
      processing: false
    }
  },
  methods: {
    searchAndAdd () {
      if (this.processing) { return }
      this.processing = true
      this.feedbackMessage = ''
      if (this.searchTerm) {
        this.$http.get(`/api/production/quotes/${this.quote.id}/quote_items/quick_add`, {
          params: {
            q: this.searchTerm.trim()
          }
        })
        .then((res) => {
          // console.log(res)
          const item = res.data.item
          if (item) {
            if (item.is_valid) {
              this.$emit('quote-item-added', res.data.quote_item)
              this.$emit('total-changed')
              setTimeout(() => this.resetInputState(),3000)
            } else {
              this.feedbackMessage = `Artículo inactivo`
            }
          } else {
            this.feedbackMessage = 'No se encontró ese artículo'
          }
        })
        .catch(e => console.log(e))
        .finally(()=>{
          this.processing = false
        })
      }
    },
    resetInputState() {
      this.feedbackMessage = ''
      this.inputShow = false
      this.searchTerm = ''
    },
    addQuoteItem(quoteItem){
      this.$emit('quote-item-added', quoteItem)
    }
  }
}
</script>
