<template>
  <div>
    <CortinaDesign v-if="currentStep === 'design'" />
    <CortinaDimensions v-if="currentStep === 'dimensions'" />
    <CortinaTrack v-if="currentStep === 'track'" />
    <CortinaConditions v-if="currentStep === 'conditions'" />
    <CortinaAutomation v-if="currentStep === 'automation'" />
    <CortinaComponents v-if="currentStep === 'components'" />
    <DefaultElectrical v-if="currentStep === 'electrical'" />
    <CortinaLimitations v-if="currentStep === 'limitations'" />

    <DesignSelector v-if="currentStep === 'designSelector'" />
    <DesignSelector v-if="currentStep === 'designSelector2'" secondary />
    <ColorSelector v-if="currentStep === 'colorSelector'" />
    <MotorSelector v-if="currentStep === 'motorSelector'" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CortinaDesign from './CortinaDesign'
import CortinaDimensions from './CortinaDimensions'
import CortinaTrack from './CortinaTrack'
import CortinaConditions from './CortinaConditions'
import CortinaAutomation from './CortinaAutomation'
import CortinaComponents from './CortinaComponents'
import DefaultElectrical from '../DefaultElectrical'
import CortinaLimitations from './CortinaLimitations'

import DesignSelector from '../DesignSelector'
import ColorSelector from '../ColorSelector'
import MotorSelector from '../MotorSelector'

export default {
  name: 'CortinaConfigurator',
  components: {
    CortinaDesign,
    CortinaDimensions,
    CortinaTrack,
    CortinaConditions,
    CortinaAutomation,
    CortinaComponents,
    DefaultElectrical,
    CortinaLimitations,
    DesignSelector,
    ColorSelector,
    MotorSelector
  },
  computed: {
    ...mapState({ currentStep: state => state.configurator.currentStep })
  }
}
</script>
