<template>
  <div>
    <b-modal
      id="configurator-modal"
      size="lg"
      hide-footer
      no-close-on-backdrop
      @shown="fetchConfigurable"
    >
      <template #modal-title>
        Configurador de {{ submital.submital_no }}
      </template>
      <NavigationSidebar />
      <CortinaConfigurator v-if="isCortina" />
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NavigationSidebar from './NavigationSidebar'
import CortinaConfigurator from './cortina/CortinaConfigurator'

export default {
  name: 'ConfiguratorModal',
  components: { NavigationSidebar, CortinaConfigurator },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({ submital: state => state.configurator.submital }),
    ...mapGetters(['configurableTypeUrlString', 'isCortina', 'isSeccional', 'isCorredizo', 'isAbatible'])
  },
  methods: {
    fetchConfigurable () {
      this.$http.get(`/api/production/submitals/${this.$route.params.id}/${this.configurableTypeUrlString}/${this.submital.configurable_id}`)
        .then((res) => {
          console.log('Configurable fetched:')
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
          this.loading = false
        })
        .catch(e => console.log(e))
    }
  }
}
</script>
