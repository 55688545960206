<template>
  <DistributorHome v-if="isDistributor" />
  <InstallerHome v-else-if="isInstaller" />
  <ProductionHome v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import DistributorHome from '../contractors/DistributorHome'
import InstallerHome from '../contractors/InstallerHome'
import ProductionHome from './ProductionHome'

export default {
  name: 'Prod',
  components: {
    DistributorHome,
    InstallerHome,
    ProductionHome
  },
  computed: {
    ...mapGetters(['isDistributor', 'isInstaller'])
  }
}
</script>
