<template>
  <div>
    <div id="sidebar" @sidebarToggled="handlePopovers">
      <div class="collapsable">
        <b-row align-h="center" class="mt-1 px-3">
          <span class="font-weight-bold" style="font-size: 16px">
            {{ currentUser.first_name + ' ' + currentUser.last_name | titleize }}
          </span>
        </b-row>
        <b-row align-h="center" class="px-3">
          <span class="fs-14">
            {{ currentUser.job_title }}
          </span>
        </b-row>
      </div>
      <hr>
      <!-- Links -->
      <b-nav vertical class="position-relative">
        <b-nav-item to="/" exact id="li-prod" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'warehouse']" size="lg" fixed-width />
          <span class="collapsable">Producción</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>

        <!-- Dev only for now -->
        <b-nav-item v-if="isDev" to="/app/distribucion" exact :class="{ active: distributionRoute }" id="li-distribucion" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'plus-hexagon']" size="lg" fixed-width />
          <span class="collapsable">Distribución</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>

        <b-nav-item v-if="currentUser.role != 'non_prod'" to="/app/rrhh/links" exact :class="{ active: rrhhLinksRoute }" id="li-rrhh" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'users']" size="lg" fixed-width />
          <span class="collapsable">Talento Humano</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>

        <b-nav-item v-if="isHrAdmin || isDev" to="/app/rrhh" exact :class="{ active: rrhhRoute }" id="li-rrhh" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'user-circle']" size="lg" fixed-width />
          <span class="collapsable">Usuarios</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>

        <b-nav-item v-if="isAdminCourses || isDev" to="/app/prod/cursos" exact :class="{ active: courseRoute }" id="li-course" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'users-class']" size="lg" fixed-width />
          <span class="collapsable">Cursos clientes</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>
        <b-nav-item to="/app/prod/transacciones" exact :class="{ active: transactionsRoute }" id="li-course" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'credit-card']" size="lg" fixed-width />
          <span class="collapsable">App Tarjetas</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>
        <b-nav-item v-if="isDev || isPurchaseDeliveryAdmin || isPurchaseDeliverySupervisor" to="/app/prod/inventario/ingreso" exact :class="{ active: deliveriesRoute }" id="li-course" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'dolly']" size="lg" fixed-width />
          <span class="collapsable">App EM</span>
          <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="v-center collapsable" />
        </b-nav-item>
      </b-nav>
      <hr>
      <b-nav vertical class="position-relative">
        <!-- External links - ultradox links -->
        <b-dropdown size="sm" id="lis-ultx" variant="link" offset="15px" dropright toggle-class="text-decoration-none text-left px-3 text-dark fs-13" no-caret>
          <template #button-content >
            <font-awesome-icon :icon="['fas', 'file-contract']" size="lg" fixed-width />
            <span class="collapsable">Ultradox</span>
            <font-awesome-icon :icon="['far', 'chevron-right']" pull="right" class="chevron-icon collapsable"/>
          </template>
          <!-- every link in  alphabetical order-->
          <b-dropdown-item href="https://www.ultradox.com/app/1VD1sY-4kio-G9EnX2WBmrLvHa4zmiCJa" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['fas', 'receipt']" size="lg" fixed-width />
            <span>Factura Reserva</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.ultradox.com/app/mercadoI" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'money-check-edit']" size="lg" fixed-width />
            <span>Mercado Interno de Servicios</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://docs.google.com/forms/d/e/1FAIpQLSdKYBC9Po9IgQojGzq0J2VBhzVTBCgjTgLfIo_KYBy7ZTJr1g/viewform" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['fas', 'hand-holding-usd']" size="lg" fixed-width />
            <span>Precio Internacional</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item href="https://www.ultradox.com/app/18Uvv5TaV_wEnS12iOcoxOxSP3oryROBG" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'credit-card']" size="lg"  fixed-width />
            <span>Registro de Tarjetas</span>
          </b-dropdown-item> -->
          <b-dropdown-item href="https://docs.google.com/forms/d/e/1FAIpQLSecRwqeCgXAewWJjmuEI7Ugl3D14XJ4Y3odiGGi8bujbbBCgQ/viewform?vc=0&c=0&w=1" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'boot']" size="lg" fixed-width />
            <span>Solicitud compra zapatos</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.ultradox.com/app/1uhRrIsNhiJ7NZ8gHcmQY7H-_wSjngpmx" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'address-card']" size="lg" class="font-weight-bold" fixed-width />
            <span >Solicitud creación proveedor</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.ultradox.com/app/15uZo97cQRPaoYCAcqntzIH2Te1AHdvSj" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'money-check-alt']" size="lg" fixed-width />
            <span>Solicitud crédito cliente</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.ultradox.com/app/1UTB-FuVaM-YTCzqjOiC9kDF0tqG6PODx" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'money-check-alt']" size="lg" fixed-width />
            <span>Solicitud Plazo de Pago C+8</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.ultradox.com/app/SFABR2019" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'garage']" size="lg" class="font-weight-bold" fixed-width />
            <span >Solicitud de Fabricaciones</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.ultradox.com/app/1uOGn0D8Mjm5vpRl9bt3J16frPENfF1D0" target="_blank" class="fs-12 dropdown-items">
            <font-awesome-icon :icon="['far', 'money-check-edit-alt']" size="lg" class="font-weight-bold" fixed-width />
            <span >Solicitud de fondos</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-nav-item href="https://acad.accesos.cr/login/index.php" target="_blank" id="li-acad" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'user-graduate']" size="lg" fixed-width />
          <span class="collapsable">Academia Accesos</span>
        </b-nav-item>
        <b-nav-item href="https://accesos-automaticos.odoo.com/web/login" target="_blank" id="li-tickets" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'user-headset']" size="lg" fixed-width />
          <span class="collapsable">Sistema de tickets</span>
        </b-nav-item>
        <b-nav-item href="https://appa.esginnova.com/wip/wip2015/registro.cfm?token=MEIwN0M3RjhDQTUxQjM4OUZFMDZFNUM3RjNBOTBGMkUxM0MyRjhDQkE5QTcxQjlCQjk5Njg3QTMwNjM4N0U1MTRDNjFCQjVDN0NEM0FBQkI3NzJG" target="_blank" id="li-complaints" @click="closeOnMobile">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon :icon="['far', 'angry']" transform="shrink-1 down-1 left-2" size="lg" fixed-width/>
            <font-awesome-icon :icon="['far', 'exclamation']" transform="shrink-8 up-8 right-6" style="color: #e93b3b" size="lg" fixed-width/>
          </font-awesome-layers>
          <span class="collapsable pl-3">Quejas</span>
        </b-nav-item>
        <b-nav-item href="https://accesospayroll.cmasap.com/SCGPayRollOne/" target="_blank" id="li-vacaciones" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'umbrella-beach']" size="lg" fixed-width />
          <span class="collapsable">Solicitar Vacaciones</span>
        </b-nav-item>
        <b-nav-item href="https://forms.office.com/r/Uf81giyy01" target="_blank" id="li-vacaciones" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'inbox-in']" size="lg" fixed-width />
          <span class="collapsable">Buzón Sugerencias</span>
        </b-nav-item>
      </b-nav>
      <hr>
      <b-nav vertical class="position-relative">
        <!-- Dev only -->
        <b-nav-item v-if="isDev" to="/app/noticias" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'newspaper']" size="lg" fixed-width />
          <span class="collapsable">Noticias</span>
        </b-nav-item>
        <b-nav-item v-if="isDev" to="/app/anuncios" id="li-anuncios" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'bullhorn']" size="lg" fixed-width />
          <span class="collapsable">Anuncios</span>
        </b-nav-item>
        <b-nav-item v-if="isSuperAdmin" to="/app/roles" id="li-permisos" @click="closeOnMobile">
          <font-awesome-icon :icon="['fas', 'user-lock']" size="lg" fixed-width />
          <span class="collapsable">Roles & Permisos</span>
        </b-nav-item>

        <b-nav-item v-if="isSuperAdmin" to="/app/contratistas" id="li-contratistas" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'user-hard-hat']" size="lg" fixed-width />
          <span class="collapsable">Contratistas</span>
        </b-nav-item>

        <b-nav-item v-if="isSuperAdmin" to="/app/datos_externos" id="li-importar" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'download']" size="lg" fixed-width />
          <span class="collapsable">Datos Externos</span>
        </b-nav-item>

        <b-nav-item v-if="isDev" to="/app/config" @click="closeOnMobile">
          <font-awesome-icon :icon="['far', 'sliders-h']" size="lg" fixed-width />
          <span class="collapsable">App Config</span>
        </b-nav-item>
      </b-nav>
    </div>
    <div id="overlay" @click="isSidebarOpen = false"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'AppSidebar',
  data () {
    return {
      isMobile: false,
      isSidebarOpen: true
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser
    }),
    ...mapGetters ([
      'isSuperAdmin',
      'isDev',
      'isDistributionAdmin',
      'isHrAdmin',
      'isAdminCourses',
      'isPurchaseDeliveryAdmin',
      'isPurchaseDeliverySupervisor'
    ]),
    rrhhRoute () {
      let routeName = this.$route.name
      return routeName ? routeName == 'rrhh' : false
    },
    rrhhLinksRoute () {
      let routeName = this.$route.name
      return routeName ? routeName == 'rrhh-links' : false
    },
    courseRoute () { return this.$route.path.includes('/cursos')},
    transactionsRoute () { return this.$route.path.includes('/transacciones')},
    deliveriesRoute () { return this.$route.path.includes('/inventario/ingreso')},
    distributionRoute () { return this.$route.path.includes('/distribucion') },
    serviceRoute () { return this.$route.path.includes('/servicio') }
  },
  watch: {
    '$route' () {
      if (this.$route.path != '/' && this.$route.path != '/app/rrhh') {
        this.isSidebarOpen = false
      } else {
        if (!this.isMobile) {
          this.isSidebarOpen = true
        }
      }
    },
    isSidebarOpen(value){
      if (value){
        document.getElementById('li-complaints').querySelector('a').classList.remove('pl-0')
        document.getElementById('overlay').classList.add('active')
        document.getElementById('sidebar').classList.remove('tucked')
      } else {
        document.getElementById('overlay').classList.remove('active')
        document.getElementById('sidebar').classList.add('tucked')
        document.getElementById('li-complaints').querySelector('a').classList.add('pl-0')
      }
    }
  },
  mounted () {
    this.isMobile = this.detectMobile()
    window.onresize = () => {
      console.log('resize event')
      this.isMobile = this.detectMobile()
      if (this.isMobile) {
        this.isSidebarOpen = false
      }
    }
    if (this.isMobile || (this.$route.path != '/' && this.$route.path != '/app/rrhh')) {
      this.isSidebarOpen = false
    }
  },
  methods: {
    detectMobile () {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.screen.width < 576) {
        return true
      } else {
        return false
      }
    },
    handlePopovers () {
      if (document.getElementById('sidebar').classList.contains('tucked')) {
        this.isSidebarOpen = false
        this.$root.$emit('bv::enable::popover')
      } else {
        this.isSidebarOpen = true
        this.$root.$emit('bv::hide::popover')
        this.$root.$emit('bv::disable::popover')
      }
    },
    closeOnMobile () {
      if (this.isMobile) {
        this.isSidebarOpen = false
      }
    }
  }
}
</script>

<style scoped>
#sidebar {
  min-height: calc(100vh - 69px);
  height: 100%;
  min-width: 220px;
  max-width: 220px;
  background-color: #dae2f4;
  font-size: 13px;
  padding-top: 1em;
  pointer-events: auto;
  border-right: 1px solid #aaa;
  line-height: 2;
  transition: all 300ms ease;
}
#sidebar.tucked {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}
#sidebar.tucked .collapsable {
  transition: all 800ms ease;
  display: none;
}
#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.8;
  z-index: 990;
}
#lis-ultx{
  position:relative;
  display: inline-block;
}
.chevron-icon{
  margin-top: 0.2rem;
  margin-left: 5.2rem;
}
@media (max-width: 575.98px) {
  #sidebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(0%);
    transition: 300ms ease;
  }
  #sidebar.tucked {
    transform: translateX(-100%);
    transition: 300ms ease;
  }
  #overlay.active {
    display: block;
  }
}
li:hover {
  background-color: #FAFAFA;
}
a {
  color: rgba(0,0,0,0.87);
}
svg {
  margin-right: 8px;
}
.v-center {
  margin-top: 0.5em;
}
.active span, .active svg {
  color: #1867c0 !important;
}

.dropdown-items :hover{
  cursor: alias;
  font-weight: bold;
}
</style>
