<template>
  <div>
    <b-row v-for="(alarmObj, index) in alarms" :key="index" align-h="center" class="mt-2">
      <div class="alarm-message text-danger">
        <font-awesome-icon :icon="['far', 'ban']" fixed-width />
        <span class="pl-2">{{ Object.values(alarmObj)[0] }}</span>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AlarmMessage',
  props: {
    field: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState ({
      submital: state => state.configurator.submital,
      currentStep: state => state.configurator.currentStep
    }),
    alarms () {
      if (this.submital.alarm_data[this.currentStep]) {
        const array = this.submital.alarm_data[this.currentStep].alarms
        return array.filter(obj => Object.keys(obj)[0] === this.field)
      } else {
        return []
      }
    }
  }
}
</script>
