<template>
  <div>
    <b-input-group :size="sizeClass" style="min-width: 95px">
      <b-input-group-prepend>
        <b-button
          :disabled="!isValid || item.quantity <= 0"
          variant="secondary"
          class="px-1"
          @click="debouncedDecrement"
        >
          <font-awesome-icon :icon="['fas', 'minus']" size="sm" fixed-width />
        </b-button>
      </b-input-group-prepend>
      <!-- Editing input -->
      <b-input
        v-show="editing"
        v-model="form.quantity"
        ref="editInput"
        type="number"
        :class="{ 'red-input-border': !isValid }"
        class="text-center px-0 no-spin"
        @blur="update('direct')"
        @keyup.enter="update('direct')"
      />
      <!-- View-only input -->
      <b-input
        v-show="!editing"
        ref="readonlyInput"
        :value="item.quantity"
        plaintext
        class="text-center border-top border-bottom"
        @click="beginEditing"
      />
      <b-input-group-append>
        <b-button
          variant="secondary"
          class="px-1"
          @click="debouncedIncrement"
        >
          <font-awesome-icon :icon="['fas', 'plus']" size="sm" fixed-width />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>

export default {
  name: 'ItemQuantityInput',
  props: {
    parentName: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      form: {
        quantity: 0
      },
      editing: false,
      processing: false,
      debounceTimeout: 800, // ms
      debounceTimer: null
    }
  },
  computed: {
    isValid () {
      const isNumeric = !isNaN(this.form.quantity)
      const isPositive = this.form.quantity >= 0
      const isNotEmpty = this.form.quantity !== "" && this.form.quantity !== '' && this.form.quantity !== null && this.form.quantity !== undefined
      return isNumeric && isNotEmpty && isPositive
    },
    scopeUrl () {
      // NOTE: Modify according to parent component or origin
      if (this.parentName == 'purchase_delivery') {
        return `/api/production/purchase_deliveries/${this.parentId}/purchase_delivery_items/${this.item.id}`
      }
      return null
    },
    debouncedIncrement() {
      return () => {
        this.form.quantity = this.item.quantity
        this.update('increment');
      }
    },
    debouncedDecrement() {
      return () => {
        this.form.quantity = this.item.quantity
        this.update('decrement');
      }
    },
    sizeClass () {
      if (this.size) {
        return this.size
      }
      return 'sm'
    }
  },
  // mounted () {
  //   this.form.quantity = this.item.quantity
  // },
  methods: {
    beginEditing () {
      this.form.quantity = this.item.quantity // unnecessary?
      this.editing = true
      this.$nextTick(() => this.$refs.editInput.select())
    },
    update (mode) {
      if (this.processing) { return }
      this.processing = true
      if (mode == 'increment') {
        this.form.quantity++
      } else if (mode == 'decrement') {
        this.form.quantity--
      }
      if (this.isValid && this.form.quantity != this.item.quantity) {
        const payload = {}
        if (this.parentName == 'purchase_delivery') {
          let isChecked = true
          if (this.form.quantity != this.item.sap_quantity && [null, '', ""].includes(this.item.notes)) {
            isChecked = false
          }
          // if (this.form.quantity == 0) {
          //   isChecked = false
          // }
          payload[`${this.parentName}_item`] = {
            quantity: this.form.quantity,
            is_checked: isChecked
          }
          // // TODO: EMIT NEW QUANTITY AND UPDATE THE STORE
          // this.$emit('quantity-updated', payload)
        } else {
          payload[`${this.parentName}_item`] = {
            quantity: this.form.quantity
          };
        }
        this.$http.patch(this.scopeUrl, payload)
          .then((res) => {
            console.log(res)
            // this.form.quantity = res.data.quantity
            this.$emit('item-updated', res.data)
            this.$emit('total-changed')
            this.editing = false
            this.$nextTick(() => this.$refs.readonlyInput.blur())
          })
          .catch(e => console.log(e))
        // TODO: next version!
        // if (mode == 'direct') {
        //   this.$http.patch(this.scopeUrl, payload)
        //     .then((res) => {
        //       console.log(res)
        //       // this.form.quantity = res.data.quantity
        //       this.$emit('item-updated', res.data)
        //       this.$emit('total-changed')
        //       this.editing = false
        //       this.$nextTick(() => this.$refs.readonlyInput.blur())
        //     })
        //     .catch(e => console.log(e))
        // } else {
        //   clearTimeout(this.debounceTimer)
        //   this.debounceTimer = setTimeout(() => {
        //     this.$http.patch(this.scopeUrl, payload)
        //       .then((res) => {
        //         console.log(res)
        //         this.form.quantity = res.data.quantity
        //         this.$emit('item-updated', res.data)
        //         this.$emit('total-changed')
        //         this.editing = false
        //         this.$nextTick(() => this.$refs.readonlyInput.blur())
        //       })
        //       .catch(e => console.log(e))
        //   }, this.debounceTimeout)
        // }
      } else {
        this.editing = false
        this.form.quantity = this.item.quantity
      }
      this.processing = false
    }
  }
}
</script>
