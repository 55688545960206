import { Controller } from "stimulus";
import { Sortable } from '@shopify/draggable';

export default class extends Controller {
  static targets = ["list"]

  connect() {
    this.load();
  }

  load() {
    const sortable = new Sortable(this.listTarget, {
      draggable: '.sortable-card',
      delay: 300,
      mirror: {
        constrainDimensions: true
      },
      scrollable: {
        speed: 0
      }
    });

    var token = $('meta[name="csrf-token"]').attr('content');

    // sortable.on('sortable:start', () => console.log('sortable:start'));
    // sortable.on('sortable:sort', () => console.log('sortable:sort'));
    // sortable.on('sortable:sorted', () => console.log('sortable:sorted'));
    sortable.on('sortable:stop', () => {
      console.log('sortable:stop');
      var items = sortable.getDraggableElementsForContainer(this.listTarget)
      var data = []
      for (var i = 0; i < items.length; i++) {
        console.log(items[i].id)
        data.push({"item_id": items[i].id.split('-')[1]})
      }
      fetch(this.data.get("url"), {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token
        },
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(data)
      }).then(response => console.log(response.text()))
    });
  }
}

