<template>
  <div>
    <b-form-group>
      <b-input-group
        v-if="!selectedClient"
        size="sm">
        <b-input
          v-model="clientSearchTerm"
          type="text"
          autofocus
          :style="{ 'min-width': searchInputSize + 'px' }"
          placeholder="Buscar por código o nombre"
          @keyup.enter="searchClients"
        />
        <b-input-group-append>
          <b-button :disabled="!clientSearchTerm" @click="searchClients">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div v-show="clientSearchTouch" style="width: 350px; max-height: 200px" class="overflow-auto">
      <b-table
        :items="clientSearchResults"
        :fields="clientSearchFields"
        hover
        outlined
        show-empty
        empty-text="No hay resultados.  Intente de nuevo"
        class="fs-13"
        style="min-width: 200px"
        @row-clicked="selectClient"
      />
    </div>
  </div>
</template>

<script>
import Button from '../../components/shared/Button'

export default {
  name: 'ClientSearchInput',
  components: { Button },
  props: {
    searchInputSize: {
      type: Number,
      default: 300
    },
  },
  data () {
    return {
      clientSearchFields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name', label: 'Nombre', tdClass: 'clickable' }
      ],
      client_id: null,
      clientSearchTerm: '',
      clientSearchResults: [],
      clientSearchTouch: false,
      selectedClient: null,
    }
  },
  methods: {
    searchClients() {
      this.$http.get('/api/production/clients/search', {
          params: { q: this.clientSearchTerm }
        })
        .then(response => {
          console.log(response)
          this.clientSearchResults = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=> this.clientSearchTouch = true);
    },
    selectClient(item) {
      this.selectedClient = item
      this.clientSearchTouch = false
      this.$emit('assign-client', item)
    },
    resetClientSearch () {
      this.selectedClient = null
      this.clientSearchTerm = ''
      this.client_id = null
    }
  }
}
</script>
