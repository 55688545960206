<template>
  <div>
    <b-container fluid>
      <b-row align-h="center">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-title">SELECCIONAR MOTOR</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-link @click="$store.commit('setCurrentStep', 'automation')">
            Regresar
          </b-link>
        </b-col>
      </b-row>

      <b-row v-if="!loading" align-h="around" class="mt-5">
        <MotorSelectorScorecard
          v-for="motor in motors"
          :motor="motor"
          :key="motor.id"
          :weightEndValue="weightEndValue"
          :lengthEndValue="lengthEndValue"
          :cyclesEndValue="cyclesEndValue"
          :class="{ active: selectedMotor && motor.id == selectedMotor.id }"
          @motor-clicked="selectMotor(motor)"
        />
      </b-row>
    </b-container>

    <b-modal
      v-model="modalShow"
      centered
      size="sm"
      hide-header
      hide-footer
      @hide="selectedMotor = null"
    >
      <b-container v-if="selectedMotor">
        <b-row>
          <p>Seleccionar motor {{ selectedMotor.item_code }}?</p>
        </b-row>
        <b-row align-h="around">
          <Button @click="closeModal">
            No
          </Button>
          <Button variant="blue" @click="updateMotor">
            Sí
          </Button>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MotorSelectorScorecard from './MotorSelectorScorecard'
import Button from '../../shared/Button'

export default {
  name: 'MotorSelector',
  components: { MotorSelectorScorecard, Button },
  data () {
    return {
      motors: [],
      modalShow: false,
      weightEndValue: null,
      lengthEndValue: null,
      cyclesEndValue: null,
      selectedMotor: null,
      loading: false,
      processing: false
    }
  },
  computed: {
    ...mapState ({ submital: state => state.configurator.submital }),
    ...mapGetters (['configurableId', 'configurableType', 'configurableTypeUrlString'])
  },
  mounted () {
    this.loading = true
    this.fetchMotors()
  },
  methods: {
    fetchMotors () {
      this.$http.get(`/api/production/submitals/${this.submital.id}/motors`)
        .then((res) => {
          console.log(res.data)
          this.motors = res.data.motors
          this.weightEndValue = res.data.max_weight
          this.lengthEndValue = res.data.max_length
          this.cyclesEndValue = res.data.max_cycles
          this.loading = false
        })
        .catch(e => console.log(e))
    },
    selectMotor (motor) {
      this.selectedMotor = motor
      this.modalShow = true
    },
    updateMotor () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.submital.id}/${this.configurableTypeUrlString}/${this.configurableId}`, {
        [this.configurableType.toLowerCase()]: {
          motor_id: this.selectedMotor.id
        }
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
          this.$store.commit('setCurrentStep', 'automation')
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    closeModal () {
      this.modalShow = false
      this.selectedMotor = null
    }
  }
}
</script>
