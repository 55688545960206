import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "encargadoColor", "ifAccesosColor" ]

  connect() {
    this.filterInterface();
  }

  filterInterface() {
    this.encargadoColorTargets.forEach(el => {
      if (el.checked && el.value == 'Accesos color original') {
        this.ifAccesosColorTarget.classList.remove('d-none');
        this.fetchColors('original');

      } else if (el.checked && el.value == 'Accesos color personalizado') {
        this.ifAccesosColorTarget.classList.remove('d-none');
        this.fetchColors('personalizado');

      } else if (el.checked && el.value == 'Cliente') {
        this.ifAccesosColorTarget.classList.add('d-none');
      }
    });
  }

  fetchColors(data) {
    Rails.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      data: `encargadoColor=${data}`
    });
  }
}

