<template>
  <div>
    <SubmitalHeader :submital="submital" page="admin" />

    <ContentView>
      <b-row align-h="center" class="pt-3 mt-5">
        <Button variant="red" @click="deleteSubmital">
          <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
          <span class="pl-3 fs-15">Borrar este acceso</span>
        </Button>
      </b-row>
    </ContentView>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import SubmitalHeader from './SubmitalHeader'

export default {
  name: 'SubmitalAdmin',
  components: { Button, ContentView, SubmitalHeader },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    ...mapState ({ submital: state => state.configurator.submital }),
  },
  methods: {
    deleteSubmital () {
      if (this.processing) return
      this.processing = true
      this.$http.delete(`/api/production/submitals/${this.id}`)
        .then((res) => {
          console.log(res.data)
          // TODO: redirect to projects?
          // this.$router.push('')
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    }
  }
}
</script>
