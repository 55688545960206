<template>
  <div>
    <NavTabs />
    <ContentView>
      <Subheader title="Configuración de RRHH" />
    </ContentView>

    <ConfigPanelSection>
      <template #title>
        <p>Admin</p>
        <small class="text-info"><i>Pueden agregar, editar, desactivar empleados, aprobar y rechazar solicitudes de vacaciones</i></small>
      </template>
      <template #content>
        <p v-for="user in humanResourcesAdmins">
          {{ user | concatName4 }}
        </p>
      </template>
    </ConfigPanelSection>

    <ConfigPanelSection>
      <template #title>
        <p>Alertas</p>
        <small class=text-info><i>Alertas de solicitudes de vacaciones van a este correo electrónico</i></small>
      </template>
      <template #content>
        <p>{{ vacationsInbox }}</p>
      </template>
    </ConfigPanelSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConfigPanelSection from '../shared/ConfigPanelSection';
import ContentView from '../shared/ContentView';
import NavTabs from './NavTabs';
import Subheader from '../shared/Subheader';

export default {
  name: 'Config',
  components: { ConfigPanelSection, ContentView, NavTabs, Subheader },
  computed: {
    ...mapGetters(['humanResourcesAdmins', 'employeeDataLoaded', 'vacationsInbox'])
  },
  created() {
    if (!this.employeeDataLoaded) {
      this.fetchEmployeeData();
    }
  },
  methods: {
    ...mapActions(['fetchEmployeeData'])
  }
}
</script>
