<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col class="p-0">
        <h2 class="pb-2">REGISTRO DE CONTEO</h2>
        <PurchaseDeliveryContent
          v-if="purchaseDeliverySelected"
          :purchase-delivery="purchaseDeliverySelected"
          parent-component="register"
          :iconHeader="['fas', 'dolly']"
        >
        <!-- Transfer buttons to table component-->
          <template v-slot:table-caption>
          </template>
          <template v-slot:table-footer>
            <b-row align-v="center">
              <b-col>
                <span class="d-inline-block font-weight-light">
                  Comentarios de registro:
                </span>
              </b-col>
            </b-row>
            <b-row align-h="around">
              <b-col cols="6">
                <ValidationProvider rules="max:200" name="comentarios" v-slot="{ errors }">
                  <b-textarea
                    :value="purchaseDeliverySelected.register_notes"
                    v-model="form.register_notes"
                    rows="3"
                    size="sm"
                    trim
                    placeholder="Ingrese las notas de registro"
                    ref="register_notes"
                    :disabled="!isEditable"
                    @blur="errors[0] || form.register_notes == purchaseDeliverySelected.register_notes ? null : updateNotes()"
                    @keydown.enter="errors[0] || form.register_notes == purchaseDeliverySelected.register_notes ? null : updateNotes()"
                  />
                  <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col align-self="end" class="text-right">
                <Button size="lg" :disabled="!canBeFinished" @click="finishRegistration()" variant="green">Finalizar</Button>
              </b-col>
            </b-row>
          </template>
        </PurchaseDeliveryContent>
        <b-container fluid v-else class="empty-page">
          <div>
            <font-awesome-icon :icon="['far', 'info-circle']" class="text-dark modal-icon" />
          </div>
          <div class="text-center">
            <p class="fs-20">No hay una EM seleccionada</p>
            <router-link :to="{ name: 'PurchaseDeliverySearch' }">
              Buscar orden
              <font-awesome-icon :icon="['fas', 'search']"/>
            </router-link>
          </div>
        </b-container>
      </b-col>
    </b-row>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate'
import PurchaseDeliveryContent from './PurchaseDeliveryContent';
import Button from '../../shared/Button';
import ToastAlert from '../../shared/ToastAlert';

export default {
  name: 'PurchaseDeliveryRegister',
  components: { PurchaseDeliveryContent, Button, ValidationProvider, ToastAlert },
  data () {
    return {
      processing: false,
      form: {
        register_notes: null
      },
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  computed: {
    ...mapGetters(['purchaseDeliverySelected', 'canBeFinished']),
    isEditable () {
      if (this.purchaseDeliverySelected) {
        if (!this.purchaseDeliverySelected.is_executed && this.purchaseDeliverySelected.is_open && !this.purchaseDeliverySelected.sap_doc_no) { return true }
      }
      return false
    }
  },
  mounted() {
    if (this.purchaseDeliverySelected) {
      this.form.register_notes = this.purchaseDeliverySelected.register_notes
    }
  },
  methods: {
    updatePurchaseDelivery(data) {
      if (this.processing){ return }
      this.processing = true
      this.$http.patch(`/api/production/purchase_deliveries/${this.purchaseDeliverySelected.id}` ,data)
        .then(response => {
          console.log(response)
          this.$store.commit('setPurchaseDeliveryData', response.data)
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios guardados'
          this.alertShow = true
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'Algo no funcionó'
          this.alertShow = true
        })
        .finally(() => {
          this.processing = false
        })
    },
    updateNotes() {
      const data = {
        purchase_delivery: {
          register_notes: this.form.register_notes
        }
      }
      this.updatePurchaseDelivery(data)
    },
    finishRegistration() {
      if (window.confirm(`¿Está seguro que desea marcar como finalizado el registro?`)) {
        const data = {
          purchase_delivery: {
            is_executed: true
          }
        }
        this.updatePurchaseDelivery(data)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.empty-page {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  opacity: 0.7;
}
</style>