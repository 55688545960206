<template>
  <div>
    <b-container fluid class="top-shelf shadow-sm">
      <b-row align-h="center" class="my-3">
        <b-col lg="6" sm="8">
          <TitleBadge v-if="submital" type="Access" :id="submital.submital_no" :title="badgeTitle" />
        </b-col>
        <b-col lg="2" sm="3" class="text-right">

        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row align-h="center" class="pt-3">
        <b-col lg="8" sm="9" cols="11">
          <b-row v-if="submital">
            <b-col md="4" cols="12" class="text-truncate mb-3 mb-sm-0">
              <p class="font-lato text-muted mb-1 fs-15">Cliente</p>
              <b-link v-if="submital.client" :to="`/app/prod/clientes/${submital.client.id}`" class="related-link">
                <span class="fs-14">{{ submital.client.name }}</span>
              </b-link>
            </b-col>
            <b-col md="4" cols="6" class="text-truncate">
              <p class="font-lato text-muted mb-1 fs-15">Proyecto</p>
              <b-link v-if="submital.project" :to="`/app/prod/proyectos/${submital.project.id}`" class="related-link">
                <span class="fs-14">{{ submital.project.name }}</span>
              </b-link>
            </b-col>
            <b-col md="4" cols="6">
              <p class="font-lato text-muted mb-1 fs-15">Asesor</p>
              <p v-if="submital.salesperson" class="mb-0 fs-14">
                {{ submital.salesperson.first_name }} {{ submital.salesperson.last_name }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <hr>
    <b-container fluid>
      <b-row align-h="center" class="custom-tabs custom-tabs-lg mt-3">
        <b-col lg="8" sm="9" cols="11">
          <b-row v-if="submital">
            <ul>
              <li>
                <b-link :to="`/app/prod/submitals/${submital.id}`" :active="page === 'submital'" class="dash-nav-link">
                  Submital
                </b-link>
              </li>
              <li>
                <b-link :to="`/app/prod/submitals/${submital.id}/fotos`" :active="page === 'fotos'" class="dash-nav-link">
                  Fotos
                  <div class="counter-badge">{{ submital.photos_count }}</div>
                </b-link>
              </li>
              <li v-if="isSupervisor">
                <b-link :to="`/app/prod/submitals/${submital.id}/admin`" :active="page === 'admin'" class="dash-nav-link">
                  Admin
                </b-link>
              </li>
            </ul>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TitleBadge from '../shared/TitleBadge'

export default {
  name: 'SubmitalHeader',
  components: { TitleBadge },
  props: {
    submital: {
      type: Object
    },
    page: {
      type: String
    }
  },
  computed: {
    ...mapGetters (['configurableType', 'isSupervisor']),
    badgeTitle () {
      return this.submital.title ? this.submital.title : this.configurableType
    }
  }
}
</script>
