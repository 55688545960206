import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "toggler", "body", "form", "substitute" ];

  show_form() {
    this.togglerTarget.classList.add("d-none");
    this.bodyTarget.classList.add("d-none");
    this.formTarget.classList.remove("d-none");
  }

  hide_form() {
    this.togglerTarget.classList.remove("d-none");
    this.bodyTarget.classList.remove("d-none");
    this.formTarget.classList.add("d-none");
  }

  showForm() {
    this.bodyTarget.classList.add("d-none");
    this.formTarget.classList.remove("d-none");
  }

  hideForm() {
    this.bodyTarget.classList.remove("d-none");
    this.formTarget.classList.add("d-none");
  }

  show_form_with_sub() {
    this.togglerTarget.classList.add("d-none");
    this.bodyTarget.classList.add("d-none");
    this.formTarget.classList.remove("d-none");
    this.substituteTarget.classList.remove("d-none");
  }

  hide_form_with_sub() {
    this.togglerTarget.classList.remove("d-none");
    this.bodyTarget.classList.remove("d-none");
    this.formTarget.classList.add("d-none");
    this.substituteTarget.classList.add("d-none");
  }
}

