import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "coverId" ];

  connect() {
    this.filterCoverDisplay();
  }

  filterCoverDisplay() {
    // reset all buttons and stars
    var buttons = document.getElementsByClassName('make-cover-button');
    Array.from(buttons).forEach(el => {
      el.classList.remove('d-none');
    });
    var stars = document.getElementsByClassName('cover-star');
    Array.from(stars).forEach(el => {
      el.classList.add('d-none');
    });
    // correctly toggle based on selected cover
    var coverId = this.coverIdTarget.value;
    var coverStar = document.getElementById('cover-label-' + coverId);
    if (coverStar) {
      coverStar.classList.remove('d-none');
      coverStar.previousSibling.classList.add('d-none');
    }
  }
}

