import Vue from 'vue';
import { format, parseISO } from 'date-fns';

Vue.filter('formatDateZeroPad', function (value) {
  if (value) {
    let date = parseISO(String(value).trim())
    return format(date, 'dd/MM/yy')
  }
})

