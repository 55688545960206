import Vue from 'vue';
import { format, parseISO } from 'date-fns';

Vue.filter('formatDateCompact', function (value) {
  if (value) {
    let date = parseISO(String(value).trim())
    return format(date, 'd/M/yy')
  }
})

