<template>
  <div>
    <b-button-group>
      <Button v-if="isDistribution" size="sm" :disabled="processingCsv" @click="generateCsv">
        <b-spinner v-if="processingCsv" small type="grow"></b-spinner>
        <font-awesome-icon v-else :icon="['far', 'fa-arrow-to-bottom']" fixed-width class="pr-1 d-none d-sm-inline" />
        <span>Excel</span>
      </Button>
      <Button size="sm" :disabled="processingPdf" @click="generatePdf">
        <b-spinner v-if="processingPdf" small type="grow"></b-spinner>
        <font-awesome-icon v-else :icon="['far', 'fa-arrow-to-bottom']" fixed-width class="pr-1 d-none d-sm-inline" />
        <span v-if="isDistribution">PDF</span>
        <span v-else>Descargar</span>
      </Button>
      <Button size="sm" @click="copyModalShow = true">
        <font-awesome-icon :icon="['far', 'copy']" fixed-width class="pl-1 d-none d-sm-inline" />
        <span class="px-1">Copiar</span>
      </Button>
      <Button size="sm" @click="openEmailModal">
        <font-awesome-icon :icon="['far', 'paper-plane']" fixed-width class="pl-1 d-none d-sm-inline" />
        <span class="px-1">Enviar</span>
      </Button>
      <Button v-if="isAdmin || isSalesSupervisor" size="sm" @click="transferModalShow = true">
        <font-awesome-icon :icon="['far', 'share']" fixed-width class="pr-1 d-none d-sm-inline" />
        <span>Transferir</span>
      </Button>
      <Button v-if="isDistribution" size="sm" class="text-danger" @click="deleteModalShow = true">
        <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width class="pr-1 d-none d-sm-inline" />
        <span>Borrar</span>
      </Button>
    </b-button-group>

    <!-- Copy Modal -->
    <b-modal
      v-model="copyModalShow"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      @hide="onCopyModalHide"
    >
      <b-row align-h="center">
        <b class="fs-20 pb-2">Copiar esta cotización...</b>
      </b-row>
      <div class="pl-5">
        <p v-if="isDistribution" class="m-1">Asignación cliente:</p>
        <p v-else class="m-1">Asignar cliente existente o nuevo:</p>
        <div v-if="isDistribution">
          <b-row class="pl-4">
            <b-form-group>
              <b-radio-group v-model="copyClientForm.client_id" stacked size="sm">
                <b-radio v-if="client" :value="client.id" :key="client.id" class="fs-14">{{client.name}}</b-radio>
                <b-radio :value="null"><span class="text-secondary">Sin cliente</span></b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>
        </div>
        <b-row v-else class="pl-4">
          <b-col>
            <b-row>
              <b-row v-if="selectedCopyClient" class="pl-2 pt-2" style="width: 400px;">
                <b-col cols="10">
                  <small><b>{{selectedCopyClient.client_num}}</b> - {{selectedCopyClient.name}}</small>
                </b-col>
                <b-col cols="2">
                  <Button size="sm" variant="red" @click="resetCopyClient" class="mr-5">
                    <font-awesome-icon :icon="['far', 'times']" fixed-width />
                  </Button>
                </b-col>
              </b-row>
              <b-row v-else class="pl-4">
                <b-form-group>
                  <b-radio-group v-model="copyClientForm.client_id" @change="fetchProjects(client.id)" stacked size="sm">
                    <b-radio :value="client.id" :key="client.id">{{ client.client_num }} - {{client.name}}</b-radio>
                    <b-radio value="CAMBIAR"><span class="text-success">Cambiar cliente</span></b-radio>
                  </b-radio-group>
                </b-form-group>
              </b-row>
            </b-row>
            <b-row v-if="copyClientForm.client_id == 'CAMBIAR'">
              <ClientSearchInput
                @assign-client="assignCopyClient"
              />
            </b-row>
          </b-col>
        </b-row>
        <!-- Project -->
        <b-row v-if="copyClientForm.client_id && copyClientForm.client_id != 'CAMBIAR' && !isDistribution">
          <b-col v-if="!loadingProjects">
            <hr>
            <p>Asignar proyecto existente o crear nuevo:</p>
            <div v-if="projects.length > 0">
              <div class="pl-4">
                <b-form-group>
                  <b-radio-group v-model="copyClientForm.project_id" @change="resetProjectForm" stacked size="sm">
                    <b-radio v-for="project in projects" :value="project.id" :key="project.id">{{ project.name }}</b-radio>
                    <b-radio value="CREATE"><span class="text-success">Crear nuevo</span></b-radio>
                  </b-radio-group>
                </b-form-group>
              </div>
            </div>
            <div v-else class="text-center pr-5">
              <small class="text-info">El cliente no tiene proyectos, por favor cree uno nuevo para esta cotización:</small>
            </div>
            <div v-if="copyClientForm.project_id == 'CREATE' || projects.length === 0" class="pr-5">
              <div>
                <b-form-group label-size="sm">
                  <template #label>
                    <span>Nombre del proyecto:</span><span class="asterisk">*</span>
                  </template>
                  <b-input v-model="projectForm.name" size="sm" />
                </b-form-group>
                <b-form-group label-size="sm">
                  <template #label>
                    <span>Dirección:</span><span class="asterisk">*</span>
                  </template>
                  <b-textarea v-model="projectForm.address" size="sm" />
                </b-form-group>
                <b-form-group label-size="sm">
                  <template #label>
                    <span>Tipo de ciclos de proyecto:</span><span class="asterisk">*</span>
                  </template>
                  <b-radio-group v-model="projectForm.project_type" stacked size="sm">
                    <b-radio value="Comercial">Comercial o Industrial</b-radio>
                    <b-radio value="Residencial">Residencial o Condominio</b-radio>
                    <b-radio value="Parqueo">Parqueo</b-radio>
                  </b-radio-group>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col v-else>
            <hr>
            <div class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando proyectos...</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="copyModalShow = false; onCopyModalHide()">Cancelar</Button>
        <div v-if="isDistribution">
          <Button variant="blue" @click="copy()" >Duplicar</Button>
        </div>
        <div v-else>
          <Button variant="orange" v-if="copyClientForm.project_id === 'CREATE'" @click="createProject()" :disabled="projectFormValidation">crear proyecto</Button>
          <Button variant="blue" v-else @click="copy()" :disabled="!copyQuoteValidation">
            <span v-if="creatingProject"><b-spinner small type="grow"></b-spinner></span>
            <span v-else>Duplicar</span>
          </Button>
        </div>
      </b-row>
    </b-modal>

    <!-- Send Email Modal -->
    <b-modal v-model="emailModalShow" centered @show="seedEmailText">
      <template #modal-title>
        <h5>Enviar esta cotización a...</h5>
      </template>
      <b-form>
        <b-row v-for="(line, index) in emailAddresses" :key="index" align-v="center" style="height: 50px">
          <b-col cols="1" class="text-center">
            <small>{{ (index + 1).toString() }}</small>
          </b-col>
          <b-col cols="9">
            <b-form-group class="mb-0" >
              <b-form-input v-model="line.email" size="sm" :state="validateEmail(line.email)" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <div class="clickable" @click="removeLine(index)">
              <font-awesome-icon v-if="index != 0" :icon="['far', 'trash-alt']" fixed-width />
            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-row align-v="center" class="mt-2">
        <b-col cols="9" offset="1">
          <Button size="sm" @click="addLine">
            <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
            <span class="pl-1">Email adicional</span>
          </Button>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-2">
        <b-col cols="9" offset="1">
          <b-form-group label="Mensaje" label-size="sm">
            <b-textarea v-model="emailText" rows="8" size="sm" />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <Button variant="green" :disabled="!emailAddressesValid" @click="sendEmails">
          Enviar
        </Button>
      </template>
    </b-modal>

    <!-- Transfer Modal -->
    <b-modal v-model="transferModalShow" centered @show="loadEmployeeData">
      <template #modal-title>
        <h5>Transferir esta cotización...</h5>
      </template>
      <b-row align-h="center">
        <b-select v-model="transfereeId" style="width: 80%">
          <template #first>
            <option :value="null" disabled>- Eligir un asesor -</option>
          </template>
          <option v-for="employee in salesQuotationPeople" :value="employee.id" :key="employee.id">
            {{ employee | concatName4 }}
          </option>
          <option v-if="quote && quote.user_id !== currentUser.id" :value="currentUser.id">
            {{ currentUser | concatName4 }}
          </option>
        </b-select>
      </b-row>
      <template #modal-footer>
        <Button variant="green" :disabled="!transfereeId" @click="transfer">Transferir</Button>
      </template>
    </b-modal>

    <!--Delete Modal -->
    <b-modal v-model="deleteModalShow" hide-footer centered>
      <template #modal-title>
        <h5>Borrar esta cotización...</h5>
      </template>
      <b-row align-h="center">
        <p class="text-danger">¿Está seguro?</p>
      </b-row>
      <b-row align-h="around">
        <Button @click="deleteModalShow = false">
          Cancelar
        </Button>
        <Button variant="red" @click="deleteQuote">
          Sí, Borrar
        </Button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from './Button'
import ClientSearchInput from '../shared/ClientSearchInput'

export default {
  name: 'QuoteButtonBar',
  components: { Button, ClientSearchInput },
  props: {
    quote: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: false  // only applies to Accesos quotes
    },
    displayCRC: {
      type: Boolean,
      required: true
    },
    isTaxExempt: {
      type: Boolean,
      required: false // only applies to Accesos quotes
    }
  },
  data() {
    return {
      copyModalShow: false,
      copyClientForm: {
        client_id: null,
        project_id: null
      },
      selectedCopyClient: null,
      projectForm: {
        client_id: null,
        name: null,
        address: null,
        project_type: null
      },
      projects: [],
      loadingProjects: false,
      creatingProject: false,
      emailModalShow: false,
      emailAddresses: [],
      emailText: 'Hola!\n\nAdjunto cotización de acuerdo a solicitud.\n\nGracias',
      transferModalShow: false,
      transfereeId: null,
      deleteModalShow: false,
      processing: false,
      processingPdf: false,
      processingCsv: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isSalesSupervisor', 'salesQuotationPeople', 'employeeDataLoaded']),
    currentUser () { return this.$store.state.currentUser },
    emailAddressesValid () {
      if (this.emailAddresses.length === 0) {
        return false
      } else {
        let invalidAddresses = this.emailAddresses.filter(line => !this.validateEmail(line.email))
        return invalidAddresses.length >= 1 ? false : true
      }
    },
    isDistribution () { return this.quote.origin == 'distribución' ? true : false },
    scopedUrlString () { return this.isDistribution ? 'contractors' : 'production' },
    scopedUrlDomain () { return this.isDistribution ? 'dist' : 'prod' },
    copyQuoteValidation () {
      if (!this.copyClientForm.client_id || !this.copyClientForm.project_id) {
        return false
      } else if (this.copyClientForm.client_id === 'CAMBIAR' || this.copyClientForm.project_id === 'CREATE') {
        return false
      } else {
        return true
      }
    },
    projectFormValidation() {
      let notRequiredFields = Object.assign({}, this.projectForm)
      delete notRequiredFields['client_id']
      const values = Object.values(notRequiredFields)
      return values.some(val => !val)
    }
  },
  methods: {
    // PDF Download methods
    generatePdf () {
      this.processingPdf = true
      this.$http.get(`/api/${this.scopedUrlString}/quotes/${this.quote.id}/pdf`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
          },
          params: {
            display_crc: this.displayCRC,
            is_tax_exempt: this.isTaxExempt  // can be null
          },
          responseType: 'blob'
        })
        .then(response => {
          console.log(response)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          let attachmentName = `Cotización #${this.quote.quote_id}`
          if (this.quote.sap_doc_num) { attachmentName += ` SAP #${this.quote.sap_doc_num}` }
          attachmentName += '.pdf'
          // PRODUCTION
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = attachmentName
          link.click()

          // DEV ONLY
          // const fileURL = URL.createObjectURL(blob)
          // window.open(fileURL)
        })
        .catch(error => this.$emit('process-failed'))
        .finally(this.processingPdf = false)
    },
    // CSV Download methods
    generateCsv () {
      this.processingCsv = true
      this.$http.get(`/api/${this.scopedUrlString}/quotes/${this.quote.id}/csv`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
          },
          params:{
            display_crc: this.displayCRC
          },
          responseType: 'blob'
        })
        .then(response => {
          console.log(response)
          const blob = new Blob([response.data], { type: 'application/csv' })
          let attachmentName = `Cotización #${this.quote.quote_id}`
          if (this.quote.sap_doc_num) { attachmentName += ` SAP #${this.quote.sap_doc_num}` }
          attachmentName += '.csv'
          // PRODUCTION
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = attachmentName
          link.click()

        })
        .catch(error => this.$emit('process-failed'))
        .finally(this.processingCsv = false)
    },
    // Copy methods
    copy () {
      if (this.processing) { return }
      this.processing = true
      let params = {}
      if (this.isDistribution) {
        params = {
          distributor_client_id: this.copyClientForm.client_id
        }
      } else {
        params = {
          client_id: this.copyClientForm.client_id,
          project_id: this.copyClientForm.project_id
        }
      }
      this.$http.post(`/api/${this.scopedUrlString}/quotes/${this.quote.id}/copy`, {
          quote: params
        })
        .then(response => {
          console.log(response)
          this.$router.push(`/app/${this.scopedUrlDomain}/cotizador/${response.data.id}`)
          this.$emit('quote-copied', response.data)
        })
        .catch(error => this.$emit('process-failed'))
        .finally(() => {
          this.processing = false
          this.copyModalShow = false
        })
    },
    onCopyModalHide(){
      this.copyClientForm.client_id = null
      this.selectedCopyClient = null
      this.resetCopyClient()
      this.resetProjectForm()
    },
    assignCopyClient(client) {
      this.selectedCopyClient = client
      this.copyClientForm.client_id = parseInt(client.id)
      this.fetchProjects(this.copyClientForm.client_id)
    },
    resetCopyClient(){
      this.selectedCopyClient = null
      Object.keys(this.copyClientForm).forEach((i) => this.copyClientForm[i] = null)
      this.resetProjectForm()
      this.projects = []
    },
    // Email methods
    addLine () {
      let checkEmptyLines = this.emailAddresses.filter(line => !line.email)
      if (checkEmptyLines.length >= 1 && this.emailAddresses.length > 0) { return }
      this.emailAddresses.push({ email: null })
    },
    removeLine (lineId) {
      this.emailAddresses.splice(lineId, 1)
    },
    validateEmail (string) {
      if (string === null) { return }
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(string)
    },
    openEmailModal () {
      this.emailModalShow = true
      if (this.emailAddresses.length === 0) {
        // refactor:
        if (this.isDistribution) {
          if (this.quote.distributor_client && this.quote.distributor_client.email) {
            this.emailAddresses.push({ email: this.quote.distributor_client.email })
          } else {
            this.emailAddresses.push({ email: null })
          }
        } else {
          if (this.client) {
            if (this.quote.sap_contact_person_code && this.quote.contact_data['email']) {
              this.emailAddresses.push({ email: this.quote.contact_data['email'] })
            } else {
              this.emailAddresses.push({ email: this.client.email })
            }
          } else {
            this.emailAddresses.push({ email: null })
          }
        }
        //
      }
    },
    seedEmailText () {
      let text = 'Hola'
      if (this.quote.sap_contact_person_code && this.quote.contact_data['name']) {
        text += ` ${this.quote.contact_data['name']},`
      }
      text += `\n\nAdjunto cotización ${this.quote.quote_id} de acuerdo a solicitud.`
      if (this.quote.sap_doc_num) {
        text += `\n\nNúmero SAP: ${this.quote.sap_doc_num}`
      }
      text += '\n\nGracias'
      this.emailText = text
    },
    sendEmails () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/${this.scopedUrlString}/quotes/${this.quote.id}/send_pdf`, {
          params: {
            emails: this.emailAddresses.map(a => a.email),
            display_crc: this.displayCRC,
            email_text: this.emailText
          }
        })
        .then(response => this.$emit('quote-emailed', response.data))
        .catch(error => this.$emit('process-failed'))
        .finally(() => {
          this.processing = false
          this.emailModalShow = false
        })
    },
    // Transfer methods - Accesos only
    transfer () {
      if (this.processing) { return }
      this.processing = true
      this.$http.put(`/api/production/quotes/${this.quote.id}/transfer`, {
          quote: { user_id: this.transfereeId }
        })
        .then(response => {
          console.log(response)
          let user = response.data
          this.transfereeId = null
          this.$emit('quote-transferred', response.data)
        })
        .catch(error => this.$emit('process-failed'))
        .finally(() => {
          this.processing = false
          this.transferModalShow = false
        })
    },
    loadEmployeeData () {
      if (!this.employeeDataLoaded) {
        this.$store.dispatch('fetchEmployeeData')
      }
    },
    // Delete methods - Distributors only
    deleteQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/${this.scopedUrlString}/quotes/${this.quote.id}`)
        .then(response => {
          console.log(response)
          setTimeout(() => this.$router.push(`/app/${this.scopedUrlDomain}/cotizaciones`), 1000)
        })
        .catch(error => this.$emit('process-failed'))
        .finally(this.processing = false)
    },
    // Project
    fetchProjects (clientId) {
      if (this.processing || this.copyClientForm.client_id == 'CAMBIAR') return
      this.processing = true
      this.loadingProjects = true
      this.$http.get(`/api/production/clients/${clientId}/fetch_projects`)
        .then((res) => {
          // console.log(res.data);
          if (res.data.length === 0) {
            this.copyClientForm.project_id = 'CREATE'
          }
          this.projects = res.data
        })
        .catch((e) => {
          console.log(e)
          this.projects = []
        })
        .finally( () => {
          this.resetProjectForm()
          this.processing = false
          this.loadingProjects = false
        })
    },
    resetProjectForm() {
      Object.keys(this.projectForm).forEach((i) => this.projectForm[i] = null)
    },
    createProject() {
      if (this.processing ) return
      this.processing = true
      this.creatingProject = true
      this.projectForm['client_id'] = this.copyClientForm.client_id
      this.$http.post('/api/production/projects', {
        project: this.projectForm
      })
      .then((res) => {
        // console.log(res.data);
        this.projects.push(res.data)
        this.copyClientForm.project_id = res.data.id
      })
      .catch((e) => {
        console.log(e)
      })
      .finally( () => {
        this.processing = false
        setTimeout(() => this.creatingProject = false, 2000)
      })
    }
  }
}
</script>
