<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-link @click="$store.commit('setCurrentStep', 'design')">
            Regresar
          </b-link>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-title">SELECCIONAR COLOR</span>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center" class="my-5">
        <b-col>
          <b-tabs v-model="tabIndex" content-class="mt-3" fill title-link-class="font-lato-tab">
            <!-- Satinado Tab -->
            <b-tab title="Satinado">
              <b-row align-h="center">
                <b-col lg="10" sm="11">
                  <!-- Liso -->
                  <b-container fluid>
                    <b-row>
                      <label class="outer-label">Liso</label>
                    </b-row>
                    <b-row align-h="around" class="mb-2">
                      <div v-for="color in satinadoColors" :key="color.id" class="color-thumbnail" @click="openDetailModal(color)">
                        <div class="color-thumbnail-text rounded-top">
                          <font-awesome-icon v-show="color.id === assignedColor.id" :icon="['fas', 'check-circle']" size="lg" fixed-width class="float-right text-success" />
                          <span>{{ color.name }}</span><br>
                          <span v-if="color.tipo_color === 'Liso'">#{{ color.ral_code }}</span><br>
                          <span>{{ color.tipo_acabado }}</span>
                        </div>
                        <div v-if="color.tipo_color === 'Liso'">
                          <div class="color-sample-hex-thumb" :style="{ 'background-color': '#' + color.hex_code }" />
                        </div>
                        <div v-else>
                          <!-- TODO: -->
                        </div>
                      </div>
                    </b-row>
                  </b-container>

                  <!-- Jaspe -->
                  <!-- NOTE: no jaspe for cortinas -->
                  <b-container v-if="jaspeColors.length > 0" fluid class="mt-3">
                    <b-row>
                      <label class="outer-label">Jaspe</label>
                    </b-row>
                    <b-row align-h="around" class="mb-2">
                      <div v-for="color in jaspeColors" :key="color.id" class="color-thumbnail" @click="openDetailModal(color)">
                        <div class="color-thumbnail-text rounded-top">
                          <font-awesome-icon :icon="['fas', 'check-circle']" size="lg" fixed-width class="float-right" />
                          <span>{{ color.name }}</span><br>
                          <span v-if="color.tipo_color === 'Liso'">#{{ color.ral_code }}</span><br>
                          <span>{{ color.tipo_acabado }}</span>
                        </div>
                        <div>
                          <!-- TODO: -->
                        </div>
                      </div>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-tab>
            <!-- Mate tab -->
            <b-tab title="Mate">
              <b-row align-h="center">
                <b-col lg="10" sm="11">
                  <!-- Liso -->
                  <b-container fluid>
                    <b-row>
                      <label class="outer-label">Liso</label>
                    </b-row>
                    <b-row align-h="around" class="mb-2">
                      <div v-for="color in mateColors" :key="color.id" class="color-thumbnail" @click="openDetailModal(color)">
                        <div class="color-thumbnail-text rounded-top">
                          <font-awesome-icon v-show="color.id === assignedColor.id" :icon="['fas', 'check-circle']" size="lg" fixed-width class="float-right text-success" />
                          <span>{{ color.name }}</span><br>
                          <span v-if="color.tipo_color === 'Liso'">#{{ color.ral_code }}</span><br>
                          <span>{{ color.tipo_acabado }}</span>
                        </div>
                        <div v-if="color.tipo_color === 'Liso'">
                          <div class="color-sample-hex-thumb" :style="{ 'background-color': '#' + color.hex_code }" />
                        </div>
                        <div v-else>
                          <!-- TODO: -->
                        </div>
                      </div>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      v-model="detailModalShow"
      hide-footer
      @show="fetchSelectedColorSamples"
      @hide="clearModal"
    >
      <template #modal-header>
        <span v-if="selectedColor">
          {{ selectedColor.name }}
        </span>
      </template>
      <b-container v-if="selectedColor" fluid>
        <b-img v-for="color in selectedColorSamples" :src="color.image_url" :key="color.id" />

        <b-row v-if="selectedColor.name.toLowerCase().includes('especial')" class="mb-3 border-top">
          <b-link href="http://www.ralcolor.com/" target="_blank">
            RAL Chart
          </b-link>
        </b-row>

        <b-row align-h="center" class="mt-5">
          <Button variant="green" @click="updateColor">
            Seleccionar
          </Button>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Button from '../../shared/Button'

export default {
  name: 'ColorSelector',
  components: { Button },
  data () {
    return {
      tabIndex: 0,
      satinadoColors: [],
      jaspeColors: [],
      mateColors: [],
      coverHash: {},
      sampleHash: {},
      filter: null,
      selectedColor: null,
      selectedColorSamples: [],
      detailModalShow: false,
      processing: false
    }
  },
  computed: {
    ...mapState ({ submital: state => state.configurator.submital }),
    ...mapGetters (['configurableId', 'configurableType', 'configurableTypeUrlString', 'isCortina', 'assignedColor'])
  },
  mounted () {
    this.fetchColors()
  },
  created () {
    if (this.assignedColor.tipo_acabado === 'Mate') {
      this.tabIndex = 1
    }
  },
  methods: {
    fetchColors () {
      this.$http.get(`/api/production/submitals/${this.submital.id}/colors`)
        .then((res) => {
          console.log(res.data)
          this.satinadoColors = res.data.satinado
          this.jaspeColors = res.data.jaspe
          this.mateColors = res.data.mate
        })
        .catch(e => console.log(e))
    },
    updateColor () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.submital.id}/${this.configurableTypeUrlString}/${this.configurableId}`, {
        [this.configurableType.toLowerCase()]: {
          color_id: this.selectedColor.id
        }
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
          this.detailModalShow = false
          this.$store.commit('setCurrentStep', 'design')
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    openDetailModal (color) {
      this.selectedColor = color
      this.detailModalShow = true
    },
    fetchSelectedColorSamples () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/submitals/${this.submital.id}/colors/${this.selectedColor.id}/samples`)
        .then((res) => {
          console.log(res.data)
          this.selectedColorSamples = res.data
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    clearModal () {
      this.selectedColor = null
      this.selectedColorSamples = []
    }
  }
}
</script>
