<template>
  <div style="padding-bottom: 10em">
    <NavTabs />
    <ContentView>
      <Subheader title="Agregar Nuevo Empleado" />
      <b-row align-h="center">
        <b-col lg="6" md="10">
          <b-row align-h="end" class="mb-3">
            <small>
              <span class="asterisk">*</span>
              <span class="pl-2">Obligatorio</span>
            </small>
          </b-row>
          <ValidationObserver v-slot="{ invalid }" ref="form">
            <hr>
            <b-form-group label="Carnet" label-cols-sm="5" label-size="sm" label-align="right">
              <b-input v-model="form.carnet" type="number" :state="form.carnet ? true : null" class="short-input" />
            </b-form-group>

            <b-form-group label-cols-sm="5" label-size="sm" label-align="right">
              <template #label>
                <span>País</span><span class="asterisk">*</span>
              </template>
              <b-radio-group v-model="form.country" size="sm" class="pl-3">
                <b-form-radio value="costa_rica">Costa Rica</b-form-radio>
                <b-form-radio value="panamá">Panamá</b-form-radio>
              </b-radio-group>
            </b-form-group>

            <ValidationProvider rules="required" name="Nombre" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Nombre</span><span class="asterisk">*</span>
                </template>
                <b-input v-model="form.first_name" :state="errors[0] ? false : (valid ? true : null)" />
              </b-form-group>
            </ValidationProvider>

            <b-form-group label="Segundo Nombre" label-cols-sm="5" label-size="sm" label-align="right">
              <b-input v-model="form.second_name" :state="form.second_name ? true : null" />
            </b-form-group>

            <ValidationProvider rules="required" name="Apellido" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Apellido</span><span class="asterisk">*</span>
                </template>
                <b-input v-model="form.last_name" :state="errors[0] ? false : (valid ? true : null)" />
              </b-form-group>
            </ValidationProvider>

            <b-form-group label="Segundo Apellido" label-cols-sm="5" label-size="sm" label-align="right">
              <b-input v-model="form.last_name2" :state="form.last_name2 ? true : null" />
            </b-form-group>

            <ValidationProvider rules="required|email" name="Correo Electrónico" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Correo Electrónico</span><span class="asterisk">*</span>
                </template>
                <b-input v-model="form.email" type="email" :state="errors[0] ? false : (valid ? true : null)" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required|numeric" name="Cédula" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Cédula</span><span class="asterisk">*</span>
                </template>
                <b-input v-model="form.cedula" :state="errors[0] ? false : (valid ? true : null)" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required|date_format_ddmmyyyy" name="Fecha de Ingreso" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Fecha de Ingreso</span><span class="asterisk">*</span>
                </template>
                <b-input v-model="form.start_date" placeholder="DD/MM/AAAA" :state="errors[0] ? false : (valid ? true : null)" class="med-input" />
              </b-form-group>
            </ValidationProvider>

            <b-form-group label-cols-sm="5" label-size="sm" label-align="right">
              <template #label>
                <span>Tipo de planilla</span><span class="asterisk">*</span>
              </template>
              <b-radio-group v-model="form.payroll_type" size="sm" class="pl-3">
                <b-form-radio value="quincenal">Quincenal</b-form-radio>
                <b-form-radio value="plr">PLR</b-form-radio>
              </b-radio-group>
            </b-form-group>

            <hr>

            <b-form-group label-cols-sm="5" label-align="right">
              <b-form-checkbox v-model="form.is_manager" size="sm">
                Es Jefatura
                <div class="d-inline pl-3" id="is-manager-info">
                  <font-awesome-icon :icon="['far', 'info-circle']" class="text-info" />
                </div>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Puesto" label-cols-sm="5" label-size="sm" label-align="right">
              <b-input v-model="form.job_title" :state="form.job_title ? true : null" />
            </b-form-group>

            <ValidationProvider rules="required" name="Departamento" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Departamento</span><span class="asterisk">*</span>
                </template>
                <b-select v-model="form.department" :options="deptOptions" :state="errors[0] ? false : (valid ? true : null)">
                  <template #first>
                    <b-select-option :value="null" disabled>- Eligir una opción -</b-select-option>
                  </template>
                </b-select>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Gerencia" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Gerencia</span><span class="asterisk">*</span>
                </template>
                <b-select v-model="form.division" :options="divisionOptions" :state="errors[0] ? false : (valid ? true : null)">
                  <template #first>
                    <b-select-option :value="null" disabled>- Eligir una opción -</b-select-option>
                  </template>
                </b-select>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="un" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Unidad</span><span class="asterisk">*</span>
                </template>
                <b-select v-model="form.business_unit" :state="errors[0] ? false : (valid ? true : null)" :options="businessUnitOptions">
                  <template #first>
                    <b-select-option :value="null">- Eligir una opción -</b-select-option>
                  </template>
                </b-select>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Jefe Inmediato" v-slot="{ valid, errors }">
              <b-form-group label-cols-sm="5" label-size="sm" label-align="right" :invalid-feedback="errors[0]">
                <template #label>
                  <span>Jefe Inmediato</span><span class="asterisk">*</span>
                </template>
                <b-select v-model="form.parent_id" :state="errors[0] ? false : (valid ? true : null)">
                  <option value=""></option>
                  <option v-for="manager in managers" :value="manager.id" :key="manager.id">
                    {{ manager | concatName3 }}
                  </option>
                </b-select>
              </b-form-group>
            </ValidationProvider>
            <hr>
            <Button variant="green" class="float-right" @click="createEmployee" :disabled="invalid">
              Guardar
            </Button>
          </ValidationObserver>
        </b-col>
      </b-row>
    </ContentView>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
    <!-- Popovers -->
    <b-popover target="is-manager-info" placement="right" triggers="hover focus">
      <span class="text-info">Jefatura pueden solicitar vacaciones, ver la sección de Reportes, y aparecen como opciones en el menú de 'Jefe Inmediato'</span>
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import NavTabs from './NavTabs'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import Subheader from '../shared/Subheader'
import ToastAlert from '../shared/ToastAlert'
import { businessUnits } from '../../sharedOptions/employeeBusinessUnits'
import { departments } from '../../sharedOptions/departments'
import { divisions } from '../../sharedOptions/divisions'

export default {
  name: 'EmployeeNew',
  components: { ValidationObserver, ValidationProvider, NavTabs, Button, ContentView, Subheader, ToastAlert },
  data() {
    return {
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      processing: false,
      form: {
        carnet: null,
        country: 'costa_rica',
        first_name: null,
        second_name: null,
        last_name: null,
        last_name2: null,
        email: null,
        cedula: null,
        start_date: null,
        payroll_type: 'quincenal',
        is_manager: false,
        job_title: null,
        division: null,
        department: null,
        business_unit: null,
        parent_id: ""
      },
      deptOptions: departments,
      divisionOptions: divisions,
      businessUnitOptions: businessUnits
    }
  },
  computed: {
    ...mapGetters(['managers'])
  },
  methods: {
    createEmployee () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post('/api/human_resources/users', {
          user: this.form
        })
        .then((res) => {
          console.log(res)
          this.$store.commit('addEmployee', res.data)
          this.$router.push({ name: 'EmployeeShow', params: { id: res.data.id, created: true }})
        })
        .catch((e) => {
          this.alertShow = true
          this.alertVariant = 'danger'
          if (e.response.data.errors) {
            this.alertMessage = e.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo agregar el empleado'
          }
        })
        .finally(this.processing = false)
    }
  }
}
</script>

<style scoped>
.short-input {
  width: 50%;
}
.med-input {
  width: 75%;
}
.form-group {
  color: #596981;
}
</style>
