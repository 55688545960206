<template>
  <div>
    <b-input-group size="sm" style="width: 95px">
      <b-input-group-prepend>
        <b-button
          :disabled="!isValid || quoteItem.quantity <= 1"
          variant="secondary"
          size="sm"
          class="px-1"
          @click="update('decrement')"
        >
          <font-awesome-icon :icon="['fas', 'minus']" size="sm" fixed-width />
        </b-button>
      </b-input-group-prepend>
      <!-- Editing input -->
      <b-input
        v-if="editing"
        v-model="form.quantity"
        ref="editInput"
        :class="{ 'red-input-border': !isValid }"
        class="text-center"
        @blur="update"
        @keyup.enter="update"
      />
      <!-- View-only input -->
      <b-input
        v-else
        ref="readonlyInput"
        :value="quoteItem.quantity"
        size="sm"
        plaintext
        class="text-center border-top border-bottom"
        @click="beginEditing"
      />
      <b-input-group-append>
        <b-button
          :disabled="!isValid"
          variant="secondary"
          size="sm"
          class="px-1"
          @click="update('increment')"
        >
          <font-awesome-icon :icon="['fas', 'plus']" size="sm" fixed-width />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>

export default {
  name: 'QuoteItemQuantityInput',
  props: {
    quoteId: {
      type: Number,
      required: true
    },
    quoteItem: {
      type: Object,
      required: true
    }
    // TODO: add isDistribution prop
  },
  data () {
    return {
      form: {
        quantity: 1
      },
      editing: false,
      processing: false
    }
  },
  computed: {
    isValid () {
      return this.form.quantity && !isNaN(this.form.quantity) && this.form.quantity > 0
    }
  },
  mounted () {
    this.form.quantity = this.quoteItem.quantity
  },
  methods: {
    beginEditing () {
      this.form.quantity = this.quoteItem.quantity // unnecessary?
      this.editing = true
      this.$nextTick(() => this.$refs.editInput.select())
    },
    update (mode) {
      if (this.processing) { return }
      this.processing = true
      if (this.isValid) {
        if (mode === 'increment') {
          this.form.quantity++
        } else if (mode === 'decrement') {
          this.form.quantity--
        }
        // Improve: Set scopeUrl
        this.$http.patch(`/api/production/quotes/${this.quoteId}/quote_items/${this.quoteItem.id}`, {
          quote_item: {
            quantity: this.form.quantity
          }
        })
          .then((res) => {
            console.log(res)
            this.$emit('item-updated', res.data)
            this.$emit('total-changed')
            this.editing = false
            this.$nextTick(() => this.$refs.readonlyInput.blur())
          })
          .catch(e => console.log(e))
      } else {
        this.editing = false
        this.form.quantity = this.quoteItem.quantity
      }
      this.processing = false
    }
  }
}
</script>
