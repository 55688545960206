<template>
  <div>
    <!-- Access cards (mobile) -->
    <div class="d-xl-none">
      <b-row v-for="access in accesses" :key="access.id" align-h="center">
        <b-card class="mb-3" style="width: 90%">
          <b-container>
            <b-row align-v="center">
              <b-col cols="6">
                <b-row class="text-muted">
                  <span>{{ accessType(access.access_no) }} {{ access.access_no }}</span>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row align-h="end" align-v="center">
                  <span class="pl-2 text-muted">{{ access.progress }}%</span>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="pb-3 border-bottom">
              <span v-if="access.name">{{ access.name }}</span>
              <span v-else class="text-muted font-italic">(Sin Nombre)</span>
            </b-row>
            <b-row class="my-3 pb-3 border-bottom">
              <b-col>
                <b-row align-h="center" align-v="center">
                  <span class="fs-13" style="padding-top: 2px">Cant.</span>
                  <span class="pl-1">{{ access.quantity }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row align-h="center" align-v="center" style="padding-top: 2px">
                  <font-awesome-icon :icon="['far', 'ruler-horizontal']" size="lg" />
                  <span v-if="access.width" class="pl-2">{{ access.width }}cm</span>
                  <span v-else class="pl-2 text-danger fs-11">Pend.</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row align-h="center" align-v="center" style="padding-top: 2px">
                  <font-awesome-icon :icon="['far', 'ruler-vertical']" size="lg" />
                  <span v-if="itemHeight(access)" class="pl-2">{{ itemHeight(access) }}cm</span>
                  <span v-else class="pl-2 text-danger fs-11">Pend.</span>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="my-2 fs-13">
              <font-awesome-icon :icon="['far', 'warehouse']" size="lg" />
              <!-- Fix text wrap for long names -->
              <span v-if="access.design" class="pl-3">{{ access.design.item_code }} {{ access.design.name }}</span>
              <span v-else class="pl-3 fs-11">Pend.</span>
            </b-row>
            <b-row class="my-2 fs-13">
              <!-- Fix text wrap for long names -->
              <font-awesome-icon :icon="['fas', 'cogs']" size="lg" />
              <span v-if="access.motor" class="pl-3">{{ access.motor.item_code }}</span>
              <span v-else class="pl-3 fs-11">Pend.</span>
            </b-row>
            <b-row v-if="isAuthorized" align-h="between" class="mt-3">
              <Button size="sm"  @click="copyAccess(access, true)">
                <font-awesome-icon :icon="['far', 'copy']" fixed-width />
                <span class="pl-1">Copiar</span>
              </Button>
              <Button size="sm" @click="openEditAccessModal(access)">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-1">Editar</span>
              </Button>
              <Button size="sm" variant="blue" @click="goToAccess(access)">
                Config
              </Button>
            </b-row>
          </b-container>
        </b-card>
      </b-row>

      <ProjectShowSubmitalCard v-for="submital in submitals" :submital="submital" :is-authorized="isAuthorized" :key="submital.id" />

      <b-row align-h="center" class="py-3">
        <Button variant="orange" @click="addSubmitalModalShow = true">
          <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
          <span class="pl-1">Nuevo Submital</span>
        </Button>
      </b-row>
    </div>

    <!-- Access table (desktop) -->
    <div class="d-none d-xl-block">
      <b-table
        :items="accesses"
        :fields="fields"
        tbody-tr-class="access-list-row"
        hover
        show-empty
        empty-text="No tiene accesos todavía"
        class="border-bottom fs-14"
      >
        <!-- Name -->
        <template v-slot:cell(name)="data">
          <div v-if="isAuthorized">
            <b-input
              v-if="editingName && data.item.id == selectedAccess.id"
              v-model="selectedAccessForm.name"
              size="sm"
              autofocus
              @blur="updateAccess(accessType(data.item.access_no))"
              @keyup.enter="updateAccess(accessType(data.item.access_no))"
            />
            <div v-else>
              <b-link v-if="data.value" class="edit-link" @click="editAccess(data.item.id, 'editingName')" @mouseover="hoverAccess(data.item.id, 'hoveringName')" @mouseleave="unhoverAccess">
                {{ data.value }}<font-awesome-icon v-show="hoveringName && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
              <b-link v-else class="font-italic edit-link text-muted" @click="editAccess(data.item.id, 'editingName')" @mouseover="hoverAccess(data.item.id, 'hoveringName')" @mouseleave="unhoverAccess">
                (Sin Nombre)<font-awesome-icon v-show="hoveringName && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
            </div>
          </div>
          <div v-else>
            <span v-if="data.value">{{ data.value }}</span>
            <span v-else class="font-italic text-muted">(Sin Nombre)</span>
          </div>
        </template>

        <!-- Quantity -->
        <template v-slot:cell(quantity)="data">
          <div v-if="isAuthorized">
            <div v-if="editingQuantity && data.item.id == selectedAccess.id">
              <ValidationProvider rules="required|min_value:1" v-slot="{ errors }">
                <b-input
                  v-model="selectedAccessForm.quantity"
                  size="sm"
                  autofocus
                  style="width: 50px"
                  :class="errors.length > 0 ? 'red-input-border' : null"
                  v-on="errors.length == 0 ? { blur: () => updateAccess(accessType(data.item.access_no)) } : { blur: () => cancelEditing() }"
                />
              </ValidationProvider>
            </div>
            <b-link v-else class="edit-link" @click="editAccess(data.item.id, 'editingQuantity')" @mouseover="hoverAccess(data.item.id, 'hoveringQuantity')" @mouseleave="unhoverAccess">
              {{ data.value }}<font-awesome-icon v-show="hoveringQuantity && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
          </div>
          <span v-else>{{ data.value }}</span>
        </template>

        <!-- Width -->
        <template v-slot:cell(width)="data">
          <div v-if="isAuthorized">
            <div v-if="editingWidth && data.item.id == selectedAccess.id">
              <ValidationProvider rules="min_value:15" name="Ancho" v-slot="{ errors }">
                <b-input
                  v-model="selectedAccessForm.width"
                  size="sm"
                  autofocus
                  style="width: 50px"
                  :class="errors.length > 0 ? 'red-input-border' : null"
                  v-on="errors.length == 0 ? { blur: () => updateAccess(accessType(data.item.access_no)) } : { blur: () => cancelEditing() }"
                />
              </ValidationProvider>
            </div>
            <div v-else>
              <b-link v-if="data.value" class="edit-link" @click="editAccess(data.item.id, 'editingWidth')" @mouseover="hoverAccess(data.item.id, 'hoveringWidth')" @mouseleave="unhoverAccess">
                {{ data.value }} cm<font-awesome-icon v-show="hoveringWidth && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
              <b-link v-else class="text-warning" @click="editAccess(data.item.id, 'editingWidth')">Llenar</b-link>
            </div>
          </div>
          <div v-else>
            <span v-if="data.value">{{ data.value }} cm</span>
            <span v-else class="text-warning">Pend.</span>
          </div>
        </template>

        <!-- Height -->
        <template v-slot:cell(height)="data">
          <div v-if="isAuthorized">
            <div v-if="editingHeight && data.item.id == selectedAccess.id">
              <ValidationProvider rules="min_value:15" v-slot="{ errors }">
                <b-input
                  v-model="selectedAccessForm.height"
                  size="sm"
                  autofocus
                  style="width: 50px"
                  :class="errors.length > 0 ? 'red-input-border' : null"
                  v-on="errors.length == 0 ? { blur: () => updateAccess(accessType(data.item.access_no)) } : { blur: () => cancelEditing() }"
                />
              </ValidationProvider>
            </div>
            <div v-else>
              <b-link v-if="itemHeight(data.item)" class="edit-link" @click="editAccess(data.item.id, 'editingHeight')" @mouseover="hoverAccess(data.item.id, 'hoveringHeight')" @mouseleave="unhoverAccess">
                {{ itemHeight(data.item) }} cm<font-awesome-icon v-show="hoveringHeight && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
              <b-link v-else class="text-warning" @click="editAccess(data.item.id, 'editingHeight')">Llenar</b-link>
            </div>
          </div>
          <div v-else>
            <span v-if="itemHeight(data.item)">{{ itemHeight(data.item) }} cm</span>
            <span v-else class="text-warning">Pend.</span>
          </div>
        </template>

        <!-- Design -->
        <template v-slot:cell(design)="data">
          <div v-if="isAuthorized">
            <b-select
              v-if="editingDesign && data.item.id == selectedAccess.id"
              v-model="selectedAccessForm.design_id"
              size="sm"
              autofocus
              @change="updateAccess(accessType(data.item.access_no))"
            >
              <template #first>
                <option v-if="!data.value" :value="null">- Eligir diseño -</option>
              </template>
              <option v-for="design in filteredDesigns(accessType(data.item.access_no))" :value="design.id" :key="design.id">{{ design.item_code }} {{ design.name }}</option>
            </b-select>
            <div v-else>
              <b-link v-if="data.value" class="edit-link" @click="editAccess(data.item.id, 'editingDesign')" @mouseover="hoverAccess(data.item.id, 'hoveringDesign')" @mouseleave="unhoverAccess">
                {{ data.item.design.item_code }} {{data.item.design.name }}<font-awesome-icon v-show="hoveringDesign && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
              <b-link v-else class="text-warning" @click="editAccess(data.item.id, 'editingDesign')">Elegir</b-link>
            </div>
          </div>
          <div v-else>
            <span v-if="data.value">{{ data.item.design.item_code }} {{data.item.design.name }}</span>
            <span v-else class="text-warning">Pend.</span>
          </div>
        </template>

        <!-- Motor -->
        <template v-slot:cell(motor)="data">
          <div v-if="isAuthorized">
            <b-select
              v-if="editingMotor && data.item.id == selectedAccess.id"
              v-model="selectedAccessForm.motor_id"
              size="sm"
              autofocus
              @change="updateAccess(accessType(data.item.access_no))"
            >
              <template #first>
                <option v-if="!data.value" :value="null">- Eligir motor -</option>
              </template>
              <option v-for="motor in filteredMotors(accessType(data.item.access_no))" :value="motor.id" :key="motor.id">{{ motor.item_code }}</option>
            </b-select>
            <div v-else>
              <b-link v-if="data.value" class="edit-link" @click="editAccess(data.item.id, 'editingMotor')" @mouseover="hoverAccess(data.item.id, 'hoveringMotor')" @mouseleave="unhoverAccess">
                {{ data.item.motor.item_code }}<font-awesome-icon v-show="hoveringMotor && data.item.id == hoveredAccessId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
              <b-link v-else class="text-warning" @click="editAccess(data.item.id, 'editingMotor')">Elegir</b-link>
            </div>
          </div>
          <div v-else>
            <span v-if="data.value">{{ data.item.motor.item_code }}</span>
            <span v-else class="text-warning">Pend.</span>
          </div>
        </template>

        <!-- Status -->
        <template v-slot:cell(status)="data">
          <b-row v-if="isAuthorized" align-h="around">
            <div v-if="data.item.progress == 100">
              <font-awesome-icon :icon="['fas', 'check-square']" size="lg" fixed-width class="text-success" />
            </div>
            <b-link v-else-if="data.item.alarms.length > 0" style="color: inherit" @click="openAccessAlarmsModal(data.item)">
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="lg" fixed-width class="text-danger" />
            </b-link>
            <b-link v-else-if="data.item.warnings.length > 0" style="color: inherit" @click="openAccessAlarmsModal(data.item)">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" fixed-width class="text-warning" />
            </b-link>
          </b-row>
          <div v-else>
            <font-awesome-icon title="No autorizado" :icon="['fas', 'ban']" size="lg" fixed-width class="text-danger" />
          </div>
        </template>

        <!-- Progress -->
        <template v-slot:cell(progress)="data">{{ data.value }}%</template>

        <!-- Actions -->
        <template v-slot:cell(actions)="data">
          <b-row v-if="isAuthorized" align-h="around" align-v="center">
            <Button size="sm" title="Copiar" style="height: 28px" @click="copyAccess(data.item, false)">
              <font-awesome-icon :icon="['far', 'copy']" size="lg" fixed-width />
            </Button>
            <Button size="sm" variant="blue" @click="goToAccess(data.item)">
              Config
            </Button>
          </b-row>
        </template>
      </b-table>

      <ProjectShowSubmitalTable :submitals="submitals" :is-authorized="isAuthorized" @submital-updated="updateSubmital" />

      <b-row align-h="center" class="py-3">
        <Button variant="orange" @click="addSubmitalModalShow = true">
          <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
          <span class="pl-1">Nuevo Submital</span>
        </Button>
      </b-row>
    </div>

    <!-- Access Alarms Modal -->
    <b-modal v-model="alarmsModalShow" centered hide-footer>
      <template #modal-title>
        <h5>Alarms y avisos de acceso {{ selectedAccess.access_no }}</h5>
      </template>
      <b-container v-if="selectedAccess">
        <b-row v-if="selectedAccess.alarms.length > 0" class="px-2">
          <p v-for="alarm in selectedAccess.alarms" :key="alarm.msg" class="text-danger">{{ parsedAlarm(alarm) }}</p>
        </b-row>
        <b-row v-if="selectedAccess.warnings.length > 0" class="px-2">
          <p v-for="warning in selectedAccess.warnings" :key="warning" class="text-warning">{{ warning }}</p>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Add Submital Modal -->
    <b-modal v-model="addSubmitalModalShow" centered hide-footer>
      <template #modal-title>
        <h5>Nuevo submital para {{ project.name }}</h5>
      </template>
      <b-row align-h="center" class="lead">
        <b-link class="modal-select" @click="createAccess('Corredizo')">
          <div class="text-center">
            <span ref="Corredizo">Corredizo</span>
          </div>
          <div class="text-center">
            <img :src="corredizoImage" class="modal-acceso">
          </div>
        </b-link>
        <b-link class="modal-select" @click="createAccess('Abatible')">
          <div class="text-center">
            <span ref="Abatible">Abatible</span>
          </div>
          <div class="text-center">
            <img :src="abatibleImage" class="modal-acceso">
          </div>
        </b-link>
        <b-link class="modal-select" @click="createAccess('Seccional')">
          <div class="text-center">
            <span ref="Seccional">Seccional</span>
          </div>
          <div class="text-center">
            <img :src="seccionalImage" class="modal-acceso">
          </div>
        </b-link>
        <b-link class="modal-select" @click="createSubmital('Cortina')">
          <div class="text-center">
            <span ref="Cortina">Cortina</span>
          </div>
          <div class="text-center">
            <img :src="cortinaImage" class="modal-acceso">
          </div>
        </b-link>
      </b-row>
    </b-modal>

    <!-- Edit Access Modal -->
    <b-modal v-model="editAccessModalShow" centered hide-footer>
      <template #modal-title>
        <h5>Editar acceso {{ selectedAccess.access_no }}</h5>
      </template>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">
          <b-form-group label="Nombre" label-size="sm" style="margin-bottom: 2em">
            <b-input v-model="selectedAccessForm.name" size="sm" />
          </b-form-group>

          <ValidationProvider rules="required|min_value:1" name="Cantidad" v-slot="{ errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Cantidad</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="selectedAccessForm.quantity" size="sm" :state="errors[0] ? false : null" />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="min_value:15" name="Ancho" v-slot="{ errors }">
            <b-form-group label="Ancho (cm)" label-size="sm" :invalid-feedback="errors[0]">
              <b-input v-model="selectedAccessForm.width" type="text" size="sm" :state="errors[0] ? false : null" />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="min_value:15" name="Alto" v-slot="{ errors }">
            <b-form-group label="Alto (cm)" label-size="sm" :invalid-feedback="errors[0]">
              <b-input v-model="selectedAccessForm.height" type="text" size="sm" :state="errors[0] ? false : null" />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Diseño" label-size="sm" style="margin-bottom: 2em">
            <b-select v-model="selectedAccessForm.design_id">
              <template #first>
                <option v-if="!selectedAccessForm.design_id" :value="null">- Eligir diseño -</option>
              </template>
              <option v-for="design in filteredDesigns(accessType(selectedAccess.access_no))" :value="design.id" :key="design.id">{{ design.item_code }} {{ design.name }}</option>
            </b-select>
          </b-form-group>

          <b-form-group label="Motor" label-size="sm" style="margin-bottom: 2em">
            <template #first>
              <option v-if="!selectedAccessForm.motor_id" :value="null">- Eligir motor -</option>
            </template>
            <b-select v-model="selectedAccessForm.motor_id">
              <option v-for="motor in filteredMotors(accessType(selectedAccess.access_no))" :value="motor.id" :key="motor.id">{{ motor.item_code }}</option>
            </b-select>
          </b-form-group>

          <div class="text-center mt-5 mb-2">
            <Button variant="green" :disabled="invalid" @click="updateAccess(accessType(selectedAccess.access_no))">
              Guardar
            </Button>
          </div>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProjectShowSubmitalCard from './ProjectShowSubmitalCard'
import ProjectShowSubmitalTable from './ProjectShowSubmitalTable'
import CorredizoImage from 'images/corredizo.png'
import AbatibleImage from 'images/abatible.png'
import SeccionalImage from 'images/seccional.png'
import CortinaImage from 'images/cortina.png'
import Button from '../shared/Button'
import ToastAlert from '../shared/ToastAlert'

export default {
  name: 'ProjectShowSubmitalList',
  components: {
    ValidationObserver,
    ValidationProvider,
    ProjectShowSubmitalCard,
    ProjectShowSubmitalTable,
    Button,
    ToastAlert
  },
  props: {
    project: Object,
    accessData: Object,
    accessList: Array,
    submitalData: Object,
    submitalList: Array,
    designs: Array,
    motors: Array
  },
  data () {
    return {
      selectedAccess: null,
      selectedAccessForm: {
        name: null,
        quantity: null,
        width: null,
        height: null,
        design_id: null,
        motor_id: null,
      },
      hoveredAccessId: null,
      hoveringName: false,
      hoveringQuantity: false,
      hoveringWidth: false,
      hoveringHeight: false,
      hoveringDesign: false,
      hoveringMotor: false,
      editingName: false,
      editingQuantity: false,
      editingWidth: false,
      editingHeight: false,
      editingDesign: false,
      editingMotor: false,
      addSubmitalModalShow: false,
      editAccessModalShow: false,
      alarmsModalShow: false,
      transferModalShow: false,
      fields: [
        { key: 'access_no', label: 'ID', thClass: 'top-borderless', thStyle: { width: '90px' } },
        { key: 'name', label: 'Nombre', thClass: 'top-borderless' },
        { key: 'quantity', label: 'Cant.', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'width', label: 'Ancho', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'height', label: 'Alto', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'design', label: 'Diseño', thClass: 'top-borderless', thStyle: { width: '250px' } },
        { key: 'motor', label: 'Motor', thClass: 'top-borderless', thStyle: { width: '200px' } },
        { key: 'status', label: '', tdClass: 'text-center', thClass: 'top-borderless' },
        { key: 'progress', label: 'Progreso', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'actions', label: '', thClass: 'top-borderless' }
      ],
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  computed: {
    ...mapGetters (['isSupervisor']),
    currentUser () { return this.$store.state.currentUser },
    isAuthorized () { return (this.isSupervisor || this.currentUser.id === this.project.user_id) ? true : false },
    accesses () { // repeated in ProjectShow
      let array = this.accessList.map(id => this.accessData[id])
      return array.sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
                  .filter(access => access.access_no.substring(0,2) != 'CT')
    },
    submitals () {
      return this.submitalList.map(id => this.submitalData[id]).sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
    },
    corredizoImage () { return CorredizoImage },
    abatibleImage () { return AbatibleImage },
    seccionalImage () { return SeccionalImage },
    cortinaImage () { return CortinaImage }
  },
  methods: {
    filteredMotors (accessType) {
      return this.motors.filter(m => m.door_type.includes(accessType))
    },
    filteredDesigns (accessType) {
      return this.designs.filter(d => d.door_type.includes(accessType))
    },
    createAccess (type) {  // phase out after converting Corredizo, Abatible, Seccional
      if (this.processing) { return }
      this.processing = true
      this.addSubmitalModalShow = false
      this.$http.post(`/api/production/accesses`, {
          access_type: type,
          access: {
            client_id: this.project.client.id,
            project_id: this.project.id,
            user_id: this.project.user_id
          }
        })
        .then((res) => {
          console.log(res)
          this.$emit('access-created', res.data)
        })
        .catch((e) => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
        })
        .finally(this.processing = false)
    },
    createSubmital (configurableType) { // Cortina only for now
      if (this.processing) { return }
      this.processing = true
      this.addSubmitalModalShow = false
      this.$http.post(`/api/production/submitals`, {
          configurable_type: configurableType,
          submital: {
            client_id: this.project.client.id,
            project_id: this.project.id,
            salesperson_id: this.project.user_id
          }
        })
        .then((res) => {
          console.log('creating Submital')
          console.log(res)
          this.$emit('submital-created', res.data)
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
        })
        .finally(this.processing = false)
    },
    updateSubmital (submital) {
      this.$emit('submital-updated', submital)
    },
    hoverAccess (accessId, accessProperty) {
      this.hoveredAccessId = accessId
      this[accessProperty] = true
    },
    unhoverAccess () {
      this.hoveredAccessId = null
      this.hoveringName = false
      this.hoveringQuantity = false
      this.hoveringWidth = false
      this.hoveringHeight = false
      this.hoveringDesign = false
      this.hoveringMotor = false
    },
    cancelEditing () {
      // console.log('cancel editing')
      this.editingName = false
      this.editingQuantity = false
      this.editingWidth = false
      this.editingHeight = false
      this.editingDesign = false
      this.editingMotor = false
      this.hoveredAccessId = null
      this.hoveringName = false
      this.hoveringQuantity = false
      this.hoveringWidth = false
      this.hoveringHeight = false
      this.hoveringDesign = false
      this.hoveringMotor = false
    },
    editAccess (accessId, accessProperty) {
      this.selectedAccess = this.accessData[accessId]
      this.selectedAccessForm.name = this.selectedAccess.name
      this.selectedAccessForm.quantity = this.selectedAccess.quantity
      this.selectedAccessForm.width = this.selectedAccess.width
      this.selectedAccessForm.height = this.itemHeight(this.selectedAccess)
      this.selectedAccessForm.design_id = this.selectedAccess.design_id
      this.selectedAccessForm.motor_id = this.selectedAccess.motor_id
      this[accessProperty] = true
    },
    openEditAccessModal (access) {  // mobile only
      this.selectedAccess = access
      this.selectedAccessForm.name = this.selectedAccess.name
      this.selectedAccessForm.quantity = this.selectedAccess.quantity
      this.selectedAccessForm.width = this.selectedAccess.width
      this.selectedAccessForm.height = this.itemHeight(this.selectedAccess)
      this.selectedAccessForm.design_id = this.selectedAccess.design_id
      this.selectedAccessForm.motor_id = this.selectedAccess.motor_id
      this.editAccessModalShow = true
    },
    updateAccess (type) {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/accesses/${this.selectedAccess.id}`, {
          access_type: type,
          access: this.selectedAccessForm
        })
        .then(response => {
          console.log(response.data)
          this.$emit('access-updated', response.data)
          this.cancelEditing()
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
         })
        .finally(() => {
          this.processing = false
          this.editAccessModalShow = false
        })
    },
    copyAccess (access, isMobile) {
      if (window.confirm(`¿Está seguro de que quiere copiar ${access.access_no}?`)) {
        if (this.processing) { return }
        this.processing = true
        this.$http.get(`/api/production/accesses/${access.id}/copy`, {
            params: { access_type: this.accessType(access.access_no) }
          })
          .then(response => {
            console.log(response)
            this.$emit('access-created', response.data)
            if (isMobile) {
              window.scrollTo({
                top: 250,
                left: 0,
                behavior: 'smooth'
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(this.processing = false)
      }
    },
    openAccessAlarmsModal (access) {
      this.selectedAccess = access
      this.alarmsModalShow = true
    },
    itemHeight (item) {
      let height = null
      if (this.accessType(item.access_no) == 'Seccional' || this.accessType(item.access_no) == 'Cortina') {
        if (item.desnivel == 'Plano') {
          height = item.h
        } else if (item.desnivel == 'Desnivel izquierdo') {
          height = item.hi
        } else {
          height = item.hd
        }
      } else {
        if (item.hi && item.hd) {
          height = item.hi > item.hd ? item.hi : item.hd
        }
      }
      return height
    },
    goToAccess (access) {
      const accessTypeCode = access.access_no.split('-')[0]
      if (accessTypeCode == 'CZ') {
        window.location.href = `/corredizos/${access.id}`
      } else if (accessTypeCode == 'AB') {
        window.location.href = `/abatibles/${access.id}`
      } else if (accessTypeCode == 'SE') {
        window.location.href = `/seccionales/${access.id}`
      }
    },
    accessType (accessNo) {
      const accessTypeCode = accessNo.split('-')[0]
      if (accessTypeCode == 'CZ') {
        return 'Corredizo'
      } else if (accessTypeCode == 'AB') {
        return 'Abatible'
      } else if (accessTypeCode == 'SE') {
        return 'Seccional'
      }
    },
    parsedAlarm (alarm) {
      let obj = JSON.parse(alarm.split('=>').join(': '))
      return Object.values(obj)[0]["msg"]
    }
  }
}
</script>
