<template>
  <div>
    <InstallerNavTabs />
    <ContentView>
      <Subheader title="Mis Clientes">
        <template #right>
          <Button size="sm" variant="green" class="mr-3" @click="newClientModalShow = true">
            <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
            <span class="pl-2">Nuevo</span>
          </Button>
        </template>
      </Subheader>
      <b-row align-v="center">
        <b-col>
          <b-row class="pl-3">
            <b-form-group style="width: 280px">
              <b-input-group size="sm">
                <b-input
                  v-model="searchTerm"
                  autofocus
                  placeholder="Buscar por nombre"
                  @keyup.enter="fetchInstallerClients"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" size="sm" @click="fetchInstallerClients">
                    <font-awesome-icon v-if="searching" :icon="['fas', 'spinner']" pulse fixed-width />
                    <font-awesome-icon v-else :icon="['fas', 'search']" fixed-width />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group class="ml-3">
              <b-button size="sm" @click="resetSearch">
                Reset
              </b-button>
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :fields="fields"
            :items="installerClients"
            hover
            responsive
            show-empty
            outlined
            head-variant="dark"
            class="fs-12"
            @row-clicked="onRowClick"
          >
            <!-- Loading spinner / empty search text -->
            <template #empty>
              <div v-if="loading" class="text-center text-info">
                <b-spinner small type="grow"></b-spinner>
                <span class="pl-2">Cargando datos...</span>
              </div>
              <div v-else class="text-center font-italic">
                <span v-if="searchTerm" class="text-danger">No resultados</span>
                <span v-else class="text-info">No hay clientes todavía</span>
              </div>
            </template>

            <!-- Table Data -->
            <template v-slot:cell(pricing_mode)="data">
              {{ data.value | titleize }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </ContentView>

    <!-- Modals -->
    <b-modal v-model="newClientModalShow" centered title="Agregar nuevo cliente">
      <b-row align-h="center" class="mt-4">
        <b-form style="width: 200px">
          <b-form-group label-size="sm">
            <template #label>
              <span>Nombre</span><span class="asterisk">*</span>
            </template>
            <b-input
              type="text"
              size="sm"
              v-model="form.name"
            />
          </b-form-group>

          <b-form-group label-size="sm">
            <template #label>
              <span>Lista de precio por defecto</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="form.pricing_mode" :options="priceListOptions" size="sm" stacked></b-radio-group>
          </b-form-group>

          <hr>

          <b-form-group label="Correo Electrónico" label-size="sm">
            <b-input
              type="email"
              size="sm"
              v-model="form.email"
            />
          </b-form-group>

          <b-form-group label="Cédula" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="form.cedula"
            />
          </b-form-group>

          <b-form-group label="Teléfono" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="form.phone"
            />
          </b-form-group>

          <b-form-group label="Dirección" label-size="sm">
            <b-textarea
              size="sm"
              v-model="form.address"
            ></b-textarea>
          </b-form-group>

          <b-form-group label="Encargado" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="form.person_in_charge"
            />
          </b-form-group>
        </b-form>
      </b-row>
      <template #modal-footer>
        <Button variant="green" class="float-right" :disabled="!formValid" @click="createInstallerClient">
          Guardar
        </Button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import SearchBar from '../shared/SearchBar'
import Subheader from '../shared/Subheader'
import InstallerNavTabs from './InstallerNavTabs'

export default {
  name: 'InstallerClientIndex',
  components: { Button, ContentView, InstallerNavTabs, SearchBar, Subheader },
  data () {
    return {
      page: 1,
      next: null,
      prev: null,
      installerClients: [],
      searchTerm: '',
      form: {
        name: null,
        cedula: null,
        email: null,
        phone: null,
        address: null,
        person_in_charge: null,
        pricing_mode: 'público'
      },
      fields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name' , label: 'Nombre', tdClass: 'clickable' },
        { key: 'pricing_mode', label: 'Modo de precio', tdClass: 'clickable' }
      ],
      priceListOptions: [
        { text: 'Público', value: 'público' },
        { text: 'Instalador', value: 'instalador' }
      ],
      loading: false,
      searching: false,
      initialLoad: false,
      newClientModalShow: false
    }
  },
  computed: {
    formValid () {
      return this.form.name
    },
  },
  mounted () {
    this.initialLoad = true
    this.loading = true
    this.fetchInstallerClients()
  },
  methods: {
    fetchInstallerClients () {
      if (this.searching) { return }
      if (this.searchTerm) {
        this.searching = true
      }
      this.$http.get('/api/contractors/distributor_clients', {
        params: {
          q: this.searchTerm
        }
      })
        .then((res) => {
          console.log(res)
          this.installerClients = res.data.distributor_clients
          this.page = res.data.pagy.page
          this.next = res.data.pagy.next
          this.prev = res.data.pagy.prev
          if (!this.initialLoad) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
          this.loading = false
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
          this.initialLoad = false
        })
    },
    onRowClick (item) {
      this.$router.push(`/app/inst/clientes/${item.id}`)
    },
    resetSearch () {
      this.searchTerm = ''
      this.loading = true
      this.fetchInstallerClients()
    },
    createInstallerClient () {
      this.$http.post('/api/contractors/distributor_clients', {
          distributor_client: this.form
        })
        .then((res) => {
          console.log(res)
          this.$router.push(`/app/inst/clientes/${res.data.id}`)
        })
        .catch(e => {
          console.log(e)
          this.newClientModalShow = false
        })
    }
  }
}
</script>
