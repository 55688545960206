<template>
  <div v-if="isSuperAdmin" class="pb-5">
    <TopShelf class="light-yellow">
      <template #action>
        <BackButton to="/app/contratistas" />
      </template>
      <template #header>
        <p class="text-muted pt-1 fs-13">Contratista:</p>
        <div v-if="contractor">
          <span class="name">{{ contractor | concatName4 }}</span>
        </div>
      </template>
      <template #side-content>
        <div v-if="contractor && contractor.status === 'activo'">
          <b-badge variant="success">Activo</b-badge>
        </div>
      </template>
    </TopShelf>

    <PanelSection>
      <template #title>Info</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr>
            <td class="label">Email:</td>
            <td v-if="contractor">{{ contractor.email }}</td>
          </tr>
          <tr>
            <td class="label">Puesto:</td>
            <td v-if="contractor">{{ contractor.job_title }}</td>
          </tr>
        </table>
      </template>
      <template #action>
        <Button v-if="contractor" size="sm" @click="openEditForm(contractor)">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          <span class="pl-1">Editar</span>
        </Button>
      </template>
    </PanelSection>

    <PanelSection>
      <template #title>App</template>
      <template #content>
        <div v-if="contractor && contractor.confirmed">
          <table class="table table-borderless table-show table-sm">
            <tr>
              <td class="label">Autorización:</td>
              <td v-if="contractor.status === 'activo'">{{ contractor.role | titleize }}</td>
              <td v-else class="text-danger">Bloqueado</td>
            </tr>
          </table>
        </div>
      </template>
      <template #action>
        <Button v-if="contractor && contractor.status === 'activo'" size="sm" variant="red" @click="deactivateModalShow = true">Bloquear</Button>
      </template>
    </PanelSection>


    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Modals -->
    <b-modal centered v-model="editModalShow" title="Editar datos" hide-footer>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">
          <ValidationProvider  rules="required" name="Nombre" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Nombre</span><span class="asterisk">*</span>
              </template>
              <b-input
                type="text"
                size="sm"
                v-model="editForm.first_name"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Segundo Nombre" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.second_name"
              :state="editForm.second_name ? true : null"
            />
          </b-form-group>

          <ValidationProvider  rules="required" name="Apellido" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Apellido</span><span class="asterisk">*</span>
              </template>
              <b-input
                type="text"
                size="sm"
                v-model="editForm.last_name"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Segundo Apellido" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.last_name2"
              :state="editForm.last_name2 ? true : null"
            />
          </b-form-group>

          <ValidationProvider  rules="required|email" name="Correo Electrónico" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Correo Electrónico</span><span class="asterisk">*</span>
              </template>
              <b-input
                type="email"
                size="sm"
                v-model="editForm.email"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Puesto" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="editForm.job_title"
              :state="editForm.job_title ? true : null"
            />
          </b-form-group>

          <ValidationProvider  rules="required" name="Autorización" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Autorización</span><span class="asterisk">*</span>
              </template>
              <b-form-select
                size="sm"
                name="editForm.role"
                v-model="editForm.role"
                :state="errors[0] ? false : (valid ? true : null)"
                :options="roleOptions"
              />
            </b-form-group>
          </ValidationProvider>
          <div class="text-center mt-5 mb-2">
            <Button variant="green" :disabled="invalid" @click="updateContractor">Guardar</Button>
          </div>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <b-modal centered lazy v-model="deactivateModalShow" title="Bloquear contratista">
      <div class="container px-3 fs-14">
        <b-row align-h="center" class="my-3">
          <p class="text-danger">¿Está seguro?</p>
        </b-row>
        <b-row align-h="center">
          <b-form-checkbox v-model="deactivateCheck">
            Sí, quiero bloquear<br>{{ contractor | concatName4 }}
          </b-form-checkbox>
        </b-row>
      </div>
      <template #modal-footer>
        <Button variant="red" :disabled="!deactivateCheck" @click="deactivateContractor">Bloquear</Button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BackButton from '../shared/BackButton';
import Button from '../shared/Button';
import PanelSection from '../shared/PanelSection';
import ToastAlert from '../shared/ToastAlert';
import TopShelf from '../shared/TopShelf';

export default {
  name: 'ContractorShow',
  components: { ValidationObserver, ValidationProvider, BackButton, Button, PanelSection, ToastAlert, TopShelf },
  data() {
    return {
      contractorId: Number(this.$route.params.id),
      contractor: null,
      editModalShow: false,
      editForm: {
        first_name: null,
        second_name: null,
        last_name: null,
        last_name2: null,
        email: null,
        job_title: null
      },
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      deactivateModalShow: false,
      deactivateCheck: false,
      roleOptions: [
        { value: 'non_prod', text: 'No es de Producción' },
        // { value: 'vendedor', text: 'Vendedor' },
        // { value: 'técnico', text: 'Técnico' },
        { value: 'distribuidor', text: 'Distribuidor' },
        { value: 'instalador', text: 'Instalador' }
      ]
    }
  },
  mounted() {
    this.fetchContractor();
  },
  computed: {
    ...mapGetters(['isSuperAdmin'])
  },
  methods: {
    fetchContractor() {
      this.$http.get(`/api/contractors/users/${this.contractorId}`)
        .then(response => {
          console.log(response)
          this.contractor = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },
    openEditForm(contractor) {
      this.editModalShow = true;
      // use Object.assign instead?
      this.editForm.first_name = this.contractor.first_name;
      this.editForm.second_name = this.contractor.second_name;
      this.editForm.last_name = this.contractor.last_name;
      this.editForm.last_name2 = this.contractor.last_name2;
      this.editForm.email = this.contractor.email;
      this.editForm.job_title = this.contractor.job_title;
      this.editForm.role = this.contractor.role;
    },
    updateContractor() {
      if (this.processing) return;
      this.editModalShow = false;
      this.processing = true;
      this.$http.put(`/api/contractors/users/${this.contractorId}`, {
          user: this.editForm
        })
        .then(response => {
          console.log(response)
          this.contractor = response.data;
          this.alertVariant = 'success';
          this.alertMessage = 'Cambios se guardaron';
        })
        .catch(error => {
          this.alertVariant = 'danger';
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0];
          } else {
            this.alertMessage = 'No se pudo guardar los cambios';
          }
        })
        .finally(() => {
          this.processing = false;
          this.alertShow = true;
        });
    },
    deactivateContractor() {
      if (this.processing) return;
      this.processing = true;
      this.deactivateModalShow = false;
      this.$http.put(`/api/contractors/users/${this.contractorId}/deactivate`)
        .then(response => {
          console.log(response)
          this.contractor = response.data;
          this.alertVariant = 'success'
          this.alertMessage = 'Empleado se desactivó con éxito';
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
        })
        .finally(() =>  {
          this.processing = false;
          this.alertShow = true;
        });
    }
  }
}
</script>

<style scoped>
.label {
  width: 150px;
  color: #596981;
}
.table {
  margin-bottom: 0;
}
.b-form-group {
  margin-bottom: 2em;
}
</style>
