<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">6</span>
            <span class="step-title">Componentes</span>
          </div>
        </b-col>
      </b-row>

      <ManualMotorNotice for-section />

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <!-- Cuadro de control -->
          <b-row class="mt-5">
            <b-form-group label="Cuadro de control" label-class="outer-label" class="w-100">
              <b-select v-model="form.cuadro_control" :options="cuadroOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.cuadro_control" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Sensor -->
          <b-row class="mt-5">
            <b-form-group label="Sensor" label-class="outer-label" class="w-100">
              <b-select v-model="form.sensor" :options="sensorOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.sensor" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Banda sensible-->
          <b-row class="mt-5">
            <b-form-group label="Banda sensible" label-class="outer-label" class="w-100">
              <b-radio-group v-model="form.banda" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva (std)
                </b-radio>
                <b-radio value="Sí, instalar">
                  Sí, instalar
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <!-- Backup -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.backup" />
                <span>Backup</span>
              </template>
              <b-radio-group v-model="form.backup" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva
                </b-radio>
                <b-radio value="Sí, instalar">
                  Sí, instalar
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>
          <AlarmMessage field="backup" />

          <b-row align-h="center" class="mt-5">
            <div class="subsection-header">
              <span class="lead">Componentes de activación</span>
            </div>
          </b-row>

          <AlarmMessage field="activation" />

          <!-- Botonera -->
          <b-row class="mt-3">
            <b-form-group label="Botonera" label-class="outer-label" class="w-100">
              <b-radio-group v-model="form.botonera" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva
                </b-radio>
                <b-radio value="Sí, un botón">
                  Sí, un botón (std)
                </b-radio>
                <b-radio value="Sí, 2 botón">
                  Sí, 2 botón
                </b-radio>
                <b-radio value="Sí, 3 botón">
                  Sí, 3 botón
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <!-- Botonera especial -->
          <b-row class="mt-5">
            <b-form-group label="Botonera especial" label-class="outer-label" class="w-100">
              <b-radio-group v-model="form.botonera_especial" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva
                </b-radio>
                <b-radio value="Sí, instalar">
                  Sí, instalar
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <!-- Selector a llave -->
          <b-row class="mt-5">
            <b-form-group label="Selector a llave" label-class="outer-label" class="w-100">
              <b-radio-group v-model="form.selector_llave" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva (std)
                </b-radio>
                <b-radio value="Sí">
                  Sí
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <!-- Receptor -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.receptor" />
                <span>Receptor</span>
              </template>
              <b-select v-model="form.receptor" :options="receptorOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.receptor" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Control remoto(s) -->
          <b-row v-show="form.receptor && form.receptor != 'No lleva'" class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.control_remoto" />
                <span>Control remoto(s)</span>
              </template>
              <b-input v-model="form.control_remoto" @change="update" />
            </b-form-group>
          </b-row>

          <!-- MyQ -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.myq" />
                <span>MyQ</span>
              </template>
              <b-radio-group v-model="form.myq" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva
                </b-radio>
                <b-radio value="Sí, especifique">
                  Sí, especifique
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>
          <AlarmMessage field="myq" />

          <!-- Notes automation -->
          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_components" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AlarmMessage from '../AlarmMessage'
import BlankIcon from '../BlankIcon'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
import ManualMotorNotice from '../ManualMotorNotice'

export default {
  name: 'CortinaComponents',
  components: { AlarmMessage, BlankIcon, ConfiguratorStepButtons, ManualMotorNotice },
  data () {
    return {
      form: {
        cuadro_control: 'Incorporado',
        sensor: 'Original del motor',
        banda: 'No lleva',
        backup: null,
        botonera: 'Sí, un botón',
        botonera_especial: null,
        selector_llave: 'No lleva',
        receptor: null,
        control_remoto: null,
        myq: null,
        notes_components: ''
      },
      cuadroOptions: [
        'No lleva',
        { text: 'Incorporado (std)', value: 'Incorporado'},
        'Derecha abajo rollo',
        'Izquierda abajo rollo',
        'Alejado (costo extra)',
        'Otro (detallar)'
      ],
      sensorOptions: [
        'No lleva',
        'CPS',
        'CPS-LN4',
        'Pupila',
        'Delta',
        { text: 'Original del motor (std)', value: 'Original del motor'},
        'Otro (especificar)'
      ],
      receptorOptions: [
        'No lleva',
        'Dentro de cuadro',
        'Columna derecha',
        'Columna izquierda',
        'Alejado (costo extra)',
        'Otro (detallar abajo)',
        'Incorporado'
      ],
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId'])
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.cuadro_control = this.cortina.cuadro_control
    this.form.sensor = this.cortina.sensor
    this.form.banda = this.cortina.banda
    this.form.backup = this.cortina.backup
    this.form.botonera = this.cortina.botonera
    this.form.botonera_especial = this.cortina.botonera_especial
    this.form.selector_llave = this.cortina.selector_llave
    this.form.receptor = this.cortina.receptor
    this.form.control_remoto = this.cortina.control_remoto
    this.form.myq = this.cortina.myq
    this.form.notes_components = this.cortina.notes_components
    this.ready = true
  },
  methods: {
    update () {
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: this.form
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    }
  }
}
</script>
