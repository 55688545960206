<template>
  <div>
    <b-row align-h="center" class="mt-4 mx-0">
      <b-col lg="10">
        <Subheader title="Configuración de App" />
      </b-col>
    </b-row>

    <ConfigPanelSection>
      <template #title>
        <p>Super Admins</p>
        <small class="text-info"><i>Pueden asignar autorización de cuenta del app.  Pueden confirmar y bloquear cuentas de contratistas. Pueden gestionar roles especiales de administración</i></small>
      </template>
      <template #content>
        <p v-for="user in superAdmins" :key="user.id">
          {{ user | concatName4 }}
        </p>
      </template>
      <template #action></template>
    </ConfigPanelSection>

    <ConfigPanelSection>
      <template #title>
        <p>Admins de Distribución</p>
        <small class="text-info"><i>Pueden ver las cotizaciones de los distribuidores y publicar anuncios</i></small>
      </template>
      <template #content>
        <p v-for="user in distributionAdmins" :key="user.id">
          {{ user | concatName4 }}
        </p>
      </template>
      <template #action></template>
    </ConfigPanelSection>

    <ConfigPanelSection>
      <template #title>
        <p>Admins de RRHH</p>
        <small class="text-info"><i>Pueden agregar, editar, desactivar empleados, aprobar y rechazar solicitudes de vacaciones</i></small>
      </template>
      <template #content>
        <p v-for="user in humanResourcesAdmins" :key="user.id">
          {{ user | concatName4 }}
        </p>
      </template>
      <template #action></template>
    </ConfigPanelSection>

    <ConfigPanelSection>
      <template #title>
        <p>Raiz de Organigrama</p>
        <small class="text-info"><i>Raiz del organigrama.  No tiene jefe inmediato</i></small>
      </template>
      <template #content>
        <p>
          {{ theAncestryRoot | concatName4 }}
        </p>
      </template>
      <template #action></template>
    </ConfigPanelSection>

    <ConfigPanelSection>
      <template #title>
        <p>Service Layer API</p>
      </template>
      <template #content>
        <b-checkbox v-model="enableServiceLayer" switch @input="toggleServiceLayer">
          <span v-if="enableServiceLayer" class="text-success">Enabled</span>
          <span v-else class="text-danger">Disabled</span>
        </b-checkbox>
      </template>
      <template #action></template>
    </ConfigPanelSection>

    <ConfigPanelSection>
      <template #title>
        <p>Desarrolladores</p>
      </template>
      <template #content>
        <p v-for="user in developers" :key="user.id">
          {{ user | concatName4 }}
        </p>
      </template>
      <template #action></template>
    </ConfigPanelSection>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ConfigPanelSection from './shared/ConfigPanelSection'
import Subheader from './shared/Subheader'

export default {
  name: 'AppConfig',
  components: { Subheader, ConfigPanelSection },
  data () {
    return {
      processing: false,
      enableServiceLayer: this.$store.state.settings.enable_service_layer
    }
  },
  computed: {
    ...mapState ({
      ancestryRoot: state => state.ancestry_root
    }),
    ...mapGetters ([
      'employeeDataLoaded',
      'superAdmins',
      'distributionAdmins',
      'humanResourcesAdmins',
      'theAncestryRoot',
      'developers'
    ])
  },
  created () {
    if (!this.employeeDataLoaded) {
      this.fetchEmployeeData()
    }
  },
  methods: {
    ...mapActions (['fetchEmployeeData']),
    toggleServiceLayer () {
      if (this.processing) { return }
      this.processing = true;
      this.$http.patch('/api/settings/1', {
          setting: { enable_service_layer: this.enableServiceLayer }
        })
        .then((res) => {
          console.log(res)
          this.$store.commit('toggleServiceLayer')
          this.enableServiceLayer = res.data.enable_service_layer
        })
        .catch(error => {
          console.log(error)
        })
        .finally(this.processing = false)
    }
  }
}
</script>
