<template>
	<transition name="modal">
    <div class="modal-overlay" @click="onOverlayClick">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
            </slot>
            <b-button size="sm" class="p-0 m-0" variant="outline-danger" @click="$emit('close')">
              <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
            </b-button>
          </div>
          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <b-button variant="outline-danger" @click="$emit('close')">
                Cancelar
              </b-button>
              <b-button variant="outline-success" @click="$emit('onConfirm')">
                Confirmar
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    required: Boolean
  },
  methods: {
    onOverlayClick() {
      if (this.required) {
        return
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  min-width: 400px;
  width: 400px;
  z-index: 9999;  /*not preventing close event from being emitted*/
  margin: 0px auto;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  border-radius: 15px;
}

.modal-header {
  padding: 7px 10px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-header .close-action {
  margin-top: 0;
  float: right;
  cursor: pointer;
}

.modal-body {
  text-align: center;
}

.modal-default-button {
  float: right;
}

.modal-footer {
  display: flex;
  gap: 10px;
  padding: 2px 0px;
}

/*
 * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
   * by Vue.js.
    *
     * You can easily play with the modal transition by editing
      * these styles.
       */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

