<template>
  <b-row align-h="end" class="custom-tabs mx-0">
    <ul class="pr-2">
      <li><router-link to="/app/rrhh/empleados" class="dash-nav-link">Empleados</router-link></li>
      <li><router-link to="/app/rrhh/organigrama" class="dash-nav-link">Organigrama</router-link></li>
      <li v-if="isDev"><router-link to="/app/rrhh/boletas" class="dash-nav-link">Boletas</router-link></li>
      <li v-if="isDev"><router-link to="/app/rrhh/reportes" class="dash-nav-link">Reportes</router-link></li>
      <li v-if="isDev"><router-link to="/app/rrhh/config" class="dash-nav-link">Config</router-link></li>
    </ul>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavTabs',
  computed: {
    ...mapGetters(['isManager', 'isHrAdmin', 'isDev'])
  }
}
</script>

