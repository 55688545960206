<template>
  <b-container>
    <!-- Create Order Modal -->
    <b-modal
      id="update-order-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      @hide="onModalHide"
    >
      <template #modal-title>
        <h4 v-if="order">{{modalTitle}}</h4>
      </template>
      <b-row v-if="order" align-h="center" class="fs-14">
        <b-col>
          <b-form @submit="updateOrder">
            <b-form-group  label="Status:" label-for="status-name">
              <b-form-select
                id="status-name"
                v-model="form.status_name"
                :options="statusNameOptions"
                required
              ></b-form-select>
            </b-form-group>
            <div v-if="syncing" class="text-center pb-3">
              <b-spinner variant="info" small />
              <span class="text-info pl-1">Sincronizando...</span>
            </div>
            <div v-if="!syncing && updateError" class="text-danger text-center pb-3">
              <span>{{updateError}}</span>
            </div>
            <div v-if="isDev && !syncing && sapError" class="text-danger text-center pb-3">
              <p class="text-dark">Only Dev:</p>
              <p>{{sapError}}</p>
            </div>
            <div v-if="!syncing && updateMsg" class="text-success text-center pb-3">
              <span>{{updateMsg}}</span>
            </div>
            <div class="action-btn">
              <b-button
              type="submit"
              variant="outline-success"
              :disabled="orderFormValidation"
              >
              Actualizar
            </b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate'
import Button from '../shared/Button'

export default {
  name: 'QuoteUpdateOrderModal',
  components: {ValidationProvider, Button},
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        status_name: null,
        sap_error: null
      },
      statusNameOptions: [
        { value: 'V- Carpeta por Entregar', text: 'V-Carpeta por Entregar'},
        { value: 'P- Recibida', text: 'P- Recibida'},
      ],
      sapSeriesOptions: {
        9: 'OI',
        71: 'OR',
        112: 'OM'
      },
      retrySap: 0,
      pinging: false,
      syncing: false,
      updateError: null,
      updateMsg: null,
      sapError: null
    }
  },
  mounted () {
    this.setData()
  },
  computed: {
    ...mapGetters(['isDev']),
    modalTitle () {
      if (this.order) {
        return `Actualizar ${this.sapSeriesOptions[this.order.sap_series]} #${this.order.sap_doc_num}`
      }
      return 'Actualizar order'
    },
    orderFormValidation () {
      if (!this.form.status_name) { return true }
      return false
    }
  },
  methods : {
    setData () {
      if (!this.validaStatusName(this.order.status_name)) {
        this.statusNameOptions.push({ value: this.order.status_name, text: this.order.status_name, disabled: true })
      }
      this.form.status_name = this.order.status_name
    },
    validaStatusName (status) {
      if (this.statusNameOptions.some(({value}) => value == status)) {
        return true
      }
      return false
    },
    updateOrder (event) {
      event.preventDefault()
      this.$http.patch(`/api/production/orders/${this.order.id}`, this.form)
        .then(resp => {
          if (!this.pinging) {
            this.syncing = true
            setTimeout(() => this.ping(), 2500)
          }
          this.updateError = null
        })
        .catch(error => {
          console.log('err', error);
          this.updateError = "Error al actualizar la orden, por favor intente de nuevo"
          this.updateMsg = null
        })
    },
    ping () {
      console.log('Pinging sap status')
      this.pinging = true
      this.$http.get(`/api/production/orders/${this.order.id}/fetch_sap_status`)
        .then(response => {
          console.log(response.data)
          if (response.data.success) {
            // this.$emit('modal-hidden')
            this.pinging = false
            this.syncing = false
            this.$emit('set-order', response.data.order)
            this.sapError = null
            this.updateError = null
            this.updateMsg = "Orden actualizada correctamente en SAP"
          } else {
            this.retrySap+=1
            if (this.retrySap == 10 || response.data.order.sap_error) {
              this.pinging = false
              this.syncing = false
              this.retrySap = 0
              this.updateMsg = null
              this.sapError = response.data.order.sap_error
              this.$emit('set-order', response.data.order)
              this.updateError = 'Error de actualización en SAP'
              return
            }
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
          this.pinging = false
          this.syncing = false
        })
    },
    onModalHide () {
      this.$emit('modal-hidden')
    }
  }
}
</script>

<style scoped>
  .action-btn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
  }
</style>