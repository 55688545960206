<template>
  <div class="container" id="gallery-photos">
    <div class="vueGallery">
      <b-row align-h="center">
        <b-col class="ml-2 mt-2">
          <b v-if="coverPhotoId === activePhotoId" class="text-primary">
            Principal <sup>*</sup>
          </b>
          <b-form-checkbox v-else size="sm" @change="$emit('update-cover', activePhotoId)">
            <b>Marcar como principal</b>
          </b-form-checkbox>
        </b-col>
        <b-col class="text-right">
          <b-button class="mx-1" variant="outline-light" size="sm" @click="deletePhoto()">
            <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width size="lg"  class="text-danger"/>
          </b-button>
        </b-col>
      </b-row>
      <div class="activePhoto" :style="'background-image: url('+photos[activePhotoIndex].img_url_lg+');'">
        <button type="button" aria-label="Previous Photo" class="previous" @click="previousPhoto()">
          <i class="fas fa-chevron-circle-left"></i>
        </button>
        <button type="button" aria-label="full Photo" class="full-view" @click="openModalPhoto()">
        </button>
        <button type="button" aria-label="Next Photo" class="next" @click="nextPhoto()">
          <i class="fas fa-chevron-circle-right"></i>
        </button>
      </div>
      <div class="thumbnails">
        <div
            v-for="(photo, index) in photos"
            :src="photo.img_url_lg"
            :key="photo.id"
            @click="activePhotoIndex = index; activePhotoId = photo.id"
            :class="{'active': activePhotoIndex == index}" :style="'background-image: url('+photo.img_url_lg+')'"
        >
        </div>
      </div>
      <b-container fluid id="full-view-modal">
        <div id="full-view" :style="'background-image: url('+photos[activePhotoIndex].img_url_lg+');'">
          <button id="close-modal" @click="closeModalPhoto()">
            <font-awesome-icon :icon="['far', 'times-circle']" fixed-width />
          </button>
          <button id="prev-image" @click="previousPhoto()">
            <i class="fas fa-chevron-circle-left"></i>
          </button>
          <button id="next-image" @click="nextPhoto()">
            <i class="fas fa-chevron-circle-right"></i>
          </button>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PhotoGallery',
  props: {
    photos: {
      type: Array,
      required: true
    },
    coverPhotoId: {
      type: Number,
      required: false
    },
  },
  data: function () {
    return {
      activePhotoIndex: 0,
      activePhotoId: null
    }
  },
  mounted () {
    this.activePhotoIndex = 0
    this.activePhotoId = this.photos[0].id
    document.addEventListener("keydown", (event) => {
      if (event.which == 37)
        this.previousPhoto()
      if (event.which == 39)
        this.nextPhoto()
    })
  },
  methods: {
    nextPhoto () {
      this.activePhotoIndex = ( this.activePhotoIndex+1 < this.photos.length ? this.activePhotoIndex+1 : 0 )
      this.activePhotoId = this.photos[this.activePhotoIndex].id
    },
    previousPhoto () {
      this.activePhotoIndex = ( this.activePhotoIndex-1 >= 0 ? this.activePhotoIndex-1 : this.photos.length-1 )
      this.activePhotoId = this.photos[this.activePhotoIndex].id
    },
    openModalPhoto () {
      const image = document.getElementById('full-view-modal')
      image.style.display = 'block';
    },
    closeModalPhoto () {
      const image = document.getElementById('full-view-modal')
      image.style.display = 'none';
    },
    deletePhoto() {
      if (window.confirm(`¿Está seguro de que quiere eliminar la imagen?`)) {
        this.$emit('delete-photo', this.activePhotoId)
        this.previousPhoto()
      }
    }
  }
}
</script>

<style scoped lang="scss">
$brand: #0b448e;

.container {
  padding: 3px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 100vh;
  max-height: 100vh;
  box-shadow: -1px 1px 20px 0px #b4a9a97a;
}

.vueGallery {
  .activePhoto {
    width: 100%;
    margin-bottom: 5px;
    padding-bottom: 65%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #fff;
    position: relative;

    button {
      border: none;
      background-color: transparent;
      font-size: 32px;
      color: #0a0a0a;
      opacity: 0.5;
      position: absolute;
      outline: none;
      height: 100%;

      &:hover {
        opacity: 0.8;
      }
      &.full-view {
        right: 15%;
        left: 15%;
      }
      &.full-view:hover {
        cursor: zoom-in;
      }
      &.previous {
        padding: 0 1em 0 0.7em;
        left: 0;
        background: -moz-linear-gradient(to right, rgb(235 232 232 / 50%) 0%, rgb(255 255 255 / 0%) 100%);
        background: -webkit-linear-gradient(to right, rgb(235 232 232 / 50%) 0%, rgb(255 255 255 / 0%) 100%);
        background: linear-gradient(to right, rgb(235 232 232 / 50%) 0%, rgb(255 255 255 / 0%) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
      }
      &.next {
        padding: 0 0.7em 0 1em;
        right: 0;
        background: -moz-linear-gradient(to left, rgb(235 232 232 / 50%) 0%, rgb(255 255 255 / 0%) 100%);
        background: -webkit-linear-gradient(to left, rgb(235 232 232 / 50%) 0%, rgb(255 255 255 / 0%) 100%);
        background: linear-gradient(to left, rgb(235 232 232 / 50%) 0%, rgb(255 255 255 / 0%) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 );
      }
    }
  }
  .thumbnails {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 10px;
    width: 100%;
    padding: 10px 5px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    div {
      flex: 0 0 auto;
      height: 100px;
      width: 100px;
      border: 2px solid #fff;
      outline: 2px solid #fff;
      cursor: pointer;
      padding: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
      &.active {
        outline-color: $brand;
        opacity: 1;
      }
    }
  }
}

#full-view-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 88%);
  align-items: center;
  justify-content: center;
  z-index: 2000;
  #full-view {
    position: relative;
    margin-bottom: 5px;
    padding-bottom: 50%;
    background-position: center;
    background-repeat: no-repeat;

    button {
      position: absolute;
      border: none;
      background-color: transparent;
      font-size: 32px;
      color: #ffffff;
      opacity: 0.5;
      outline: none;
      z-index: 101;
      & svg:hover {
        opacity: 0.8;
      }
      &#prev-image {
        left: 6px;
        top: 50%;
      }
      &#next-image {
        right: 6px;
        top: 50%;
      }
      &#close-modal {
        top: 10px;
        right: 5px;
      }
    }
  }
}
</style>
