import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["input"];

  connect() {
    this.inputTargets.forEach(el => {
     if (el.value && !isNaN(el.value) && el.value >= 0) {
        el.classList.add('border-success');
      } else {
        el.classList.remove('border-success');
      } 
    });
  }

  // validates AND syncs inputs
  validate(event) {
    var input = event.currentTarget;
    var inputList = document.getElementsByName(input.name);
    if (input.value && !isNaN(input.value) && input.value >= 0) {
      input.classList.add('border-success');
      Array.from(inputList).forEach(el => {
        el.value = input.value;
        el.classList.add('border-success');
      });
    } else {
      input.classList.remove('border-success');
      Array.from(inputList).forEach(el => {
        el.value = input.value;
        el.classList.remove('border-success');
      });
    }
  } 
}

