<template>
  <div>
    <div v-if="projectType === 'Comercial'">
      <b-row class="mt-5">
        <b-form-group label-class="outer-label" class="w-100">
          <template #label>
            <BlankIcon :field="commercialForm.ciclos" />
            <span>Ciclos por Hora</span>
          </template>
          <b-select v-model="commercialForm.ciclos" :options="ciclosOptions" @change="update">
            <template #first>
              <b-select-option v-show="!commercialForm.ciclos" :value="null">
                -- seleccionar --
              </b-select-option>
            </template>
          </b-select>
        </b-form-group>
      </b-row>
      <b-row class="mt-5">
        <b-form-group label-class="outer-label" class="w-100">
          <template #label>
            <BlankIcon :field="commercialForm.horas" />
            <span>Horas de Uso</span>
          </template>
          <b-select v-model="commercialForm.horas" :options="horasOptions" @change="update">
            <template #first>
              <b-select-option v-show="!commercialForm.horas" :value="null">
                -- seleccionar --
              </b-select-option>
            </template>
          </b-select>
        </b-form-group>
      </b-row>
    </div>
    <div v-else>
      <b-row class="mt-5">
        <b-form-group label-class="outer-label" class="w-50">
          <template #label>
            <BlankIcon :field="residentialForm.cantidad_cp" />
            <span v-if="projectType === 'Residencial'">Cantidad de casas a usar acceso</span>
            <span v-else-if="projectType === 'Parqueo'">Cantidad de parqueos</span>
          </template>
          <b-input v-model="residentialForm.cantidad_cp" @blur="update" />
        </b-form-group>
      </b-row>

      <b-row class="mt-5">
        <b-form-group label="Tipo de uso" label-class="outer-label" class="w-100">
          <b-select v-model="residentialForm.tipo_uso" :options="tipoUsoOptions" @change="update" />
        </b-form-group>
      </b-row>
    </div>

    <b-row class="mt-5">
      <b-form-group label-class="outer-label" class="w-100">
        <template #label>
          <BlankIcon :field="sharedForm.uso_paso_peatonal" />
          <span>Uso para paso peatonal para el motor</span>
        </template>
        <b-radio-group v-model="sharedForm.uso_paso_peatonal" :options="usoPasoPeatonalOptions" @change="update" />
      </b-form-group>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BlankIcon from './BlankIcon'

export default {
  name: 'SubformCycles',
  components: { BlankIcon },
  data () {
    return {
      sharedForm: {
        uso_paso_peatonal: null
      },
      commercialForm: {
        ciclos: null,
        horas: null
      },
      residentialForm: {
        tipo_uso: 'Entrada y Salida',
        cantidad_cp: null
      },
      ciclosOptions: [
        '1 ciclo',
        '2-3 ciclos',
        '4-10 ciclos',
        '11-30 ciclos',
        '31-60 ciclos'
      ],
      horasOptions: [
        '3 horas',
        '8 horas',
        '12 horas',
        '18 horas',
        '24 horas'
      ],
      tipoUsoOptions: [
        'Entrada y Salida',
        'Entrada general',
        'Entrada sólo dueños',
        'Entrada sólo visitas',
        'Salida general',
        'Salida sólo dueños',
        'Salida sólo visitas'
      ],
      usoPasoPeatonalOptions: [
        'No, ya existe puerta peatonal aparte',
        'SI, no existe puerta peatonal'
      ]
    }
  },
  computed : {
    ...mapGetters (['configurableId', 'configurableType', 'configurableTypeUrlString', 'projectType']),
  },
  methods: {
    update () {
      const form = this.projectType === 'Comercial' ? this.commercialForm : this.residentialForm
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/${this.configurableTypeUrlString}/${this.configurableId}`, {
        cortina: Object.assign({}, form, this.sharedForm)
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    }
  }

}
</script>
