export const departments = [
  { value: 'automatización', text: 'Automatización' },
  { value: 'calidad', text: 'Calidad' },
  { value: 'centro_de_servicio', text: 'Centro de Servicio' },
  { value: 'contabilidad', text: 'Contabilidad' },
  { value: 'contratación_administrativo', text: 'Contratación Administrativo' },
  { value: 'control_de_accesos', text: 'Control de Accesos' },
  { value: 'distribución', text: 'Distribución' },
  { value: 'división_industrial', text: 'División Industrial' },
  { value: 'exportaciones', text: 'Exportaciones' },
  { value: 'fabricaciones', text: 'Fabricaciones' },
  { value: 'facturación', text: 'Facturación' },
  { value: 'finanzas', text: 'Finanzas' },
  { value: 'gerencia_de_investigación_y_desarrollo', text: 'Gerencia de Investigación y Desarrollo' },
  { value: 'gerencia_de_mercadeo', text: 'Gerencia de Mercadeo' },
  { value: 'gerencia_de_operaciones_1', text: 'Gerencia de Operaciones 1' },
  { value: 'gerencias', text: 'Gerencias' },
  { value: 'guanacaste', text: 'Guanacaste' },
  { value: 'investigación_y_desarrollo', text: 'Investigación y Desarrollo' },
  { value: 'logística', text: 'Logística' },
  { value: 'mercadeo', text: 'Mercadeo' },
  { value: 'operaciones', text: 'Operaciones' },
  { value: 'parking', text: 'Parking' },
  { value: 'post_venta', text: 'Post Venta' },
  { value: 'puertas_automáticas', text: 'Puertas Automáticas' },
  { value: 'talento_humano', text: 'Talento Humano' },
  { value: 'tecnología_informática', text: 'Tecnología Informática' },
  { value: 'telemercadeo', text: 'Telemercadeo' },
  { value: 'ventas', text: 'Ventas' }
]
