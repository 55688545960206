<template>
  <div>
    <Button variant="green" class="d-none d-sm-block" :disabled="modalShow" @click="modalShow = !modalShow">
      <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
      <span>Artículo</span>
    </Button>
    <Button variant="green" size="sm" class="d-sm-none" :disabled="modalShow" @click="modalShow = !modalShow">
      <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
      <span class="pl-1">Artículo</span>
    </Button>

    <b-modal v-model="modalShow" title="Agregar..." scrollable class="modal-backdrop-dark" @hidden="reset">
      <b-tabs content-class="mt-5" active-nav-item-class="font-weight-bold" justified>
        <!-- Single item tab -->
        <b-tab title="Un solo artículo" title-link-class="text-muted">
          <b-container>
            <b-row align-h="center">
              <b-col cols="6">
                <b-form-group label="Buscar" label-size="sm">
                  <b-input-group size="sm">
                    <b-input
                      v-model="searchTerm"
                      ref="searchInput"
                      autofocus
                      placeholder="Ingresar nombre"
                      @keyup.enter="search"
                      @click="selectSearchInput"
                    />
                    <b-input-group-append>
                      <b-button variant="outline-secondary" :disabled="!searchTerm && !manufacturerFilter" @click="search">
                        <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Fabricante" label-size="sm">
                  <b-select v-model="manufacturerFilter" :options="manufacturerOptions" size="sm">
                    <template #first>
                      <b-select-option :value="null">Todos</b-select-option>
                    </template>
                  </b-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="searchResultsEmpty" align-h="center">
              <small class="text-danger">No hubo resultados para esta búsqueda</small>
            </b-row>

            <b-row align-h="center" align-v="center" class="mt-4">
              <b-form-group label="Artículo" label-class="lato-label" style="width: 200px">
                <b-input v-model="itemCodeDisplay" readonly />
              </b-form-group>
            </b-row>

            <b-row align-h="center" align-v="center" class="mt-3">
              <b-form-group label="Cantidad" label-class="lato-label">
                <b-input-group style="width: 200px">
                  <b-input-group-prepend>
                    <b-button :disabled="!quantityIsValid || form.quantity <= 1" @click="decrement">
                      <font-awesome-icon :icon="['fas', 'minus']" size="lg" fixed-width />
                    </b-button>
                  </b-input-group-prepend>
                  <b-input
                    v-model="form.quantity"
                    ref="quantityInput"
                    :class="{ 'red-input-border': !quantityIsValid }"
                    class="text-center"
                    @click="selectQuantityInput"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!quantityIsValid" @click="increment">
                      <font-awesome-icon :icon="['fas', 'plus']" size="lg" fixed-width />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-row>
            <b-row align-h="center" class="mt-5 mb-2">
              <Button variant="green" :disabled="!selectedItem || !quantityIsValid" @click="addQuoteItem">Agregar</Button>
            </b-row>
          </b-container>
        </b-tab>

        <!-- Motor set tab -->
        <b-tab title="Motor set" lazy title-link-class="text-muted" @click="loadMotors">
          <b-container fluid>
            <b-row align-h="center" class="mb-2">
              <small class="font-italic text-info">
                Eligir un motor y sus artículos relacionados se añadirán a la cotización
              </small>
            </b-row>
            <b-row align-h="center" class="my-4 fs-14">
              <b-checkbox-group v-model="motorFilter" switches>
                <b-checkbox value="Corredizo">Corredizo</b-checkbox>
                <b-checkbox value="Abatible">Abatible</b-checkbox>
                <b-checkbox value="Seccional">Seccional</b-checkbox>
                <b-checkbox value="Cortina">Cortina</b-checkbox>
              </b-checkbox-group>
            </b-row>
            <b-row align-h="center">
              <b-table
                :items="filteredMotors"
                :fields="motorFields"
                primary-key="id"
                outlined
                stacked="sm"
                show-empty
                class="fs-13"
              >
                <!-- Loading spinner / empty search text -->
                <template #empty>
                  <div v-if="loadingMotors" class="text-center text-info">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="pl-2">Cargando datos...</span>
                  </div>
                </template>

                <!-- Choose button -->
                <template v-slot:cell(id)="data">
                  <b-button v-if="data.item.motor_items.length > 0" variant="outline-success" size="sm" @click="addMotorSet(data.value)">
                    Añadir
                  </b-button>
                  <span v-else></span>
                </template>

                <!-- Table data -->
                <template v-slot:cell(door_type)="data">
                  {{ data.value.join(', ') }}
                </template>
                <template v-slot:cell(price)="data">
                  {{ data.value | dollarize }}
                </template>
                <!-- Motor Set Details -->
                <template v-slot:cell(show_details)="row">
                  <Button v-if="row.item.motor_items.length > 0" size="sm" @click="row.toggleDetails">
                    <!-- Artículos -->
                    <font-awesome-icon :icon="['fas', 'search']" size="lg" fixed-width class="text-muted" />
                  </Button>
                </template>
                <template v-slot:row-details="row">
                  <b-card>
                    <b-row class="border-bottom mb-2 font-weight-bold">
                      <b-col class="text-center">Cant.</b-col>
                      <b-col>Código</b-col>
                      <b-col class="text-right">
                        <span class="pr-1">Precio {{ priceList }}</span>
                      </b-col>
                    </b-row>
                    <b-row v-for="motorItem in row.item.motor_items" :key="motorItem.id">
                      <b-col class="text-center">{{ motorItem.quantity }}</b-col>
                      <b-col>{{ motorItem.product.item_code }}</b-col>
                      <b-col class="text-right price-display">
                        <div v-if="displayCRC">
                          <span>{{ listedPrice(motorItem.product) | colones }}</span>
                          </div>
                          <div v-else>
                          <span>{{ listedPrice(motorItem.product) | dollarize }}</span>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
      <template #modal-footer><span></span></template>
    </b-modal>

    <!-- Search Results Modal -->
    <b-modal
      v-model="searchResultsModalShow"
      scrollable
      centered
      hide-footer
      @hidden="resetSearch"
    >
      <template #modal-header>
        <b-container>
          <b-row>
            <p><b>Término de búsqueda:</b> "{{ searchTerm }}"</p>
          </b-row>
          <b-row v-if="manufacturerFilter">
            <p><b>Filtro de fabricante:</b> {{ manufacturerOptions.find(o => o.value == manufacturerFilter).text }}</p>
          </b-row>
        </b-container>
      </template>
      <b-container fluid class="pb-3">
        <b-row>
          <small class="text-info">Seleccionar un artículo:</small>
        </b-row>
        <b-row align-h="center">
          <b-table-lite
            :items="searchResults"
            :fields="fields"
            primary-key="item_code"
            hover
            class="border-bottom fs-13"
            @row-clicked="selectItem"
          >
            <!-- <template v-slot:cell(price)="data">
              <span v-if="data.item.is_bundle" class="text-warning">BULTO</span>
              <div v-else class="price-display">
                <span v-if="displayCRC">{{ data.value * exchangeRate | colones }}</span>
                <span v-else>{{ data.value | dollarize }}</span>
              </div>
            </template> -->

            <template v-slot:cell(listed_price)="data">
              <span v-if="data.item.is_bundle" class="text-warning">BULTO</span>
              <div v-else class="price-display">
                <div v-if="displayCRC">
                  {{ listedPrice(data.item) | colones }}
                </div>
                <div v-else>
                  {{ listedPrice(data.item) | dollarize }}
                </div>
              </div>
            </template>
          </b-table-lite>
        </b-row>
        <Observer @intersect="intersected" />
        <SpinnerHorizontal v-if="addingPage" />
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import Button from './Button'
import Observer from './Observer'
import SpinnerHorizontal from './SpinnerHorizontal'

export default {
  name: 'QuoteItemSelector',
  components: { Button, Observer, SpinnerHorizontal },
  props: {
    quoteId: {
      type: Number,
      required: true
    },
    displayCRC: {
      type: Boolean,
      required: true
    },
    exchangeRate: {
      type: Number,
      required: true
    },
    priceList: {
      type: String,
      required: true
    },
    lastSapLine: {
      type: Number,
      required: false
    },
    clientExonerated: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      form: {
        quantity: 1
      },
      selectedItem: null,
      itemCodeDisplay: '',
      searchTerm: '',
      manufacturerFilter: null,
      manufacturerOptions: [
        // NOTE: Manufacturers with over 40 item in inventory
        { value: -1, text: '- Ningún fabricante -' },
        { value: 13, text: 'Accesos Automáticos' },
        { value: 63, text: 'Amarr' },
        { value: 90, text: 'Astumardi Puertas Corta fuego' },
        { value: 68, text: 'Automatic Systems' },
        { value: 7, text: 'Beninca' },
        { value: 76, text: 'Blacksmith' },
        { value: 48, text: 'CAB' },
        { value: 2, text: 'CAME' },
        { value: 11, text: 'CHAMBERLAIN' },
        { value: 170, text: 'CHI HARDWARE CORPORATION LIMIT' },
        { value: 112, text: 'Cristobal Fallas Zamora' },
        { value: 93, text: 'El Lagar C R S.A.' },
        { value: 123, text: 'Extralum' },
        { value: 117, text: 'Grupo el electrico S.A' },
        { value: 95, text: 'Hi-Motions SRL' },
        { value: 39, text: 'HORTON' },
        { value: 86, text: 'Intrade ABC, S.A' },
        { value: 70, text: 'Local' },
        { value: 3, text: 'MAGNETIC' },
        { value: 100, text: 'Napoleon Lynx' },
        { value: 51, text: 'NEDAP' },
        { value: 77, text: 'NERGECO' },
        { value: 29, text: 'Overhead Door' },
        { value: 73, text: 'PARKARE GROUP' },
        { value: 144, text: 'PPA' },
        { value: 36, text: 'RBH' },
        { value: 25, text: 'Richards Wilcox' },
        { value: 82, text: 'Sekunet' },
        { value: 102, text: 'Service Spring Corp' },
        { value: 105, text: 'Solito SRL' },
        { value: 78, text: 'Taller Fabricaciones' }
      ],
      searchResultsModalShow: false,
      searchResults: [],
      searchResultsEmpty: false,
      modalShow: false,
      page: 1,
      count: null,
      last: null,
      // loading: false,
      processing: false,
      addingPage: false,
      // Motor set tab
      loadingMotors: false,
      motors: [],
      motorFilter: [],
      motorFields: [
        { key: 'id', label: '', tdClass: 'align-middle' },
        { key: 'item_code', label: 'Modelo', tdClass: 'font-weight-bold align-middle' },
        { key: 'brand', label: 'Marca', tdClass: 'align-middle' },
        { key: 'door_type', label: 'Tipo de puerta', tdClass: 'align-middle' },
        { key: 'show_details', label: '', tdClass: 'align-middle text-center' }
      ]
    }
  },
  computed: {
    fields () {
      return [
        { key: 'item_code', label: 'Código', tdClass: 'clickable' },
        { key: 'description', label: 'Descripción', tdClass: 'clickable' },
        { key: 'listed_price', label: `Precio ${this.priceList}`, thClass: 'text-right', tdClass: 'text-right clickable' }
      ]
    },
    quantityIsValid () {
      return this.form.quantity && !isNaN(this.form.quantity) && this.form.quantity > 0
    },
    filteredMotors () {
      if (this.motorFilter.length > 0) {
        return this.motors.filter(m => {
          return this.motorFilter.some(f => {
            return m.door_type.includes(f) && m.transmission !== 'Manual'
          })
        })
      } else {
        return this.motors.filter(m => {
          return m.transmission !== 'Manual'
        })
      }
    }
  },
  methods: {
    addQuoteItem () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/quotes/${this.quoteId}/quote_items`, {
        quote_item: {
          quantity: this.form.quantity,
          tax_code: this.clientExonerated ? 'EXO' : 'IV',
          product_id: this.selectedItem.id,
          retail_price: this.selectedItem.price,
          installer_price: this.selectedItem.installer_price,
          wholesale_price: this.selectedItem.wholesale_price,
          price_currency: this.selectedItem.price_currency,
          installer_price_currency: this.selectedItem.installer_price_currency,
          wholesale_price_currency: this.selectedItem.wholesale_price_currency,
          sap_line_num: this.lastSapLine + 1
        },
        is_bundle: this.selectedItem.is_bundle
      })
        .then((res) => {
          console.log(res)
          if (Array.isArray(res.data)) {
            res.data.forEach(item => {
              this.$emit('quote-item-added', item)
            })
          } else {
            this.$emit('quote-item-added', res.data)
          }
          this.$emit('total-changed')
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.modalShow = false
          this.searchTerm = ''
          this.searchResults = []
          this.processing = false
        })
    },
    search () {
      if (this.searchTerm || this.manufacturerFilter) {
        if (this.processing) { return }
        this.processing = true
        this.searchResultsEmpty = false
        this.$http.get('/api/production/products/search', {
          params: {
            q: this.searchTerm,
            manufacturer_filter: this.manufacturerFilter,
            page: this.page
          }
        })
          .then((res) => {
            console.log(res)
            if (res.data.search_results.length == 0) {
              this.searchResultsEmpty = true
            } else {
              if (this.addingPage) {
                this.searchResults = [...this.searchResults, ...res.data.search_results]
              } else {
                this.searchResults = res.data.search_results
              }
              this.searchResultsModalShow = true
            }
            this.count = res.data.pagy.count
            this.last = res.data.pagy.last
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.processing = false
            this.addingPage = false
          })
      }
    },
    productPrice(price, price_currency) {
      if (!price || !price_currency) { return 0 }
      if (this.displayCRC) {
        return price_currency === 'COL' ? price : price * this.exchangeRate
      } else {
        return price_currency === 'COL' ? price / this.exchangeRate : price
      }
    },
    listedPrice(item) {
      if (this.priceList === 'público') {
        return this.productPrice(item.price, item.price_currency)
      } else if (this.priceList === 'instalador') {
        return this.productPrice(item.installer_price, item.installer_price_currency)
      } else if (this.priceList === 'mayorista') {
        return this.productPrice(item.wholesale_price, item.wholesale_price_currency)
      }
    },
    selectItem (item) {
      this.itemCodeDisplay = item.item_code
      this.selectedItem = item
      this.searchResultsModalShow = false
    },
    resetSearch () {
      this.page = 1
      this.searchResults = []
    },
    selectSearchInput () {
      this.$nextTick(() => this.$refs.searchInput.select())
    },
    selectQuantityInput () {
      this.$nextTick(() => this.$refs.quantityInput.select())
    },
    decrement () {
      this.form.quantity--
    },
    increment () {
      this.form.quantity++
    },
    reset () {
      this.form.quantity = 1
      this.itemCodeDisplay = ''
      this.selectedItem = null
      this.manufacturerFilter = null
      this.searchTerm = ''
      this.searchResultsEmpty = false
      this.processing = false
    },
    // Motor set tab
    loadMotors () {
      this.loadingMotors = true
      this.$http.get('/api/production/motors')
        .then(res => this.motors = res.data)
        .catch(e => console.log(e))
        .finally(this.loadingMotors = false)
    },
    addMotorSet (motorId) {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/quotes/${this.quoteId}/quote_items`, {
        motor_id: motorId
      })
        .then((res) => {
          res.data.forEach(item => {
            this.$emit('quote-item-added', item)
          })
          this.$emit('total-changed')
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
          this.modalShow = false
        });
    },
    intersected () {
      if (this.page + 1 <= this.last) {
        this.page++
        this.addingPage = true
        this.search()
      }
    }
  }
}
</script>
