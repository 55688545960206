<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <h4>Artículos</h4>
      </b-col>
      <!-- <b-col class="text-right">
        <b-button size="sm" @click="resetSearch" variant="outline-dark">
          <span class="pr-1">Actualizar cantidades</span>
          <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
        </b-button>
      </b-col> -->
    </b-row>
    <b-row v-if="parentComponent != 'search'" class="py-1">
      <b-col>
        <b-form-group class="m-0" style="max-width: 30rem">
          <b-input-group>
            <b-input
              v-model="searchTerm"
              autofocus
              :disabled="notEditable"
              placeholder="Buscar por código exacto"
              @keyup.enter="searchCode"
            />
            <b-input-group-append>
              <b-button variant="outline-secondary" size="md" @click="searchCode" :disabled="notEditable">
                <font-awesome-icon v-if="searching" :icon="['fas', 'spinner']" pulse fixed-width />
                <font-awesome-icon v-else :icon="['fas', 'search']" fixed-width />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <small v-if="itemNotFound" class="text-danger">
          <font-awesome-icon :icon="['far', 'info-circle']"/>
          {{ itemNotFound }}
        </small>
      </b-col>
      <b-col v-if="parentComponent == 'validation'">
        <b-row class="pr-3" align-h="end">
          <!-- TODO: Set disable button -->
          <ItemSelector
            v-if="!notEditable"
            :request-id="purchaseDelivery.id"
            :show-price="false"
            request-type="articulo"
            origin="purchase_delivery"
            :price-list="'público'"
            :displayCRC="false"
            :exchange-rate="purchaseDelivery.usd_crc_rate"
            :last-sap-line="lastSapLine"
            @item-added="addPurchaseDeliveryItem"
          />
        </b-row>
      </b-col>
      <b-col v-if="parentComponent == 'register'">
        <b-row class="table-captions" align-h="end">
          <b-col class="text-right">
            <Button size="lg" disabled>
              <font-awesome-icon :icon="['fas', 'barcode']" size="lg" fixed-width/>
            </Button>
            <Button size="lg" :disabled="notEditable" @click="notCheckedItemsModal = true">Pendientes</Button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="parentComponent == 'register'" align-h="between" class="py-1">
      <b-col>
        <span class="fs-14">
          Completados:
          <span :class="{'text-danger': items.length != itemsCompleted, 'text-success': items.length == itemsCompleted}"> {{itemsCompleted}} </span>
          /<span :class="{'text-danger': items.length != itemsCompleted, 'text-success': items.length == itemsCompleted}"> {{items.length }}</span>
        </span>
      </b-col>
      <!-- <b-col class="text-right">
        <Button @click="sortItems()">Ordenar artículos</Button>
      </b-col> -->
    </b-row>
    <b-table
      :items="items"
      :fields="fields"
      :filter-included-fields="filterOn"
      :filter="filter"
      head-variant="light"
      sort-icon-left
      show-empty
      fixed
      :hover="!notEditable"
      bordered
      caption-top
      no-border-collapse
      style="max-height: 100%"
      :class="{ 'table-closed text-muted': notEditable }"
      class="fs-14 font-lato border-bottom"
    >
      <template #head()="scope">
        <div v-if="parentComponent == 'register'" class="text-wrap text-center">
          <span
            v-if="['sap_item_code', 'sap_vendor_code'].includes(scope.column)"
            class="m-0 text-break"
          >
            {{ scope.label }}
            <toggle-button
              v-model="toggleItemCode"
              :labels="{ checked: 'Proveedor', unchecked: 'Accesos' }"
              :width="77"
              class="mb-1 ml-2"
              :color="{checked:'#53544b', unchecked: '#163D7D', disabled: '#CCCCCC'}"
            />
          </span>
          <span v-else class="align-middle">{{scope.label}}</span>
        </div>
        <div v-else class="text-nowrap">
          {{scope.label}}
        </div>
      </template>
      <template #empty>
        <div class="text-center text-danger">
          <span>Sin artículos</span>
        </div>
      </template>
      <template v-slot:cell(sap_item_code)="data">
        <span class="font-weight-bold">
          <span class="fs-16">{{data.value}}</span>
          <span
            v-if="!data.item.product_id"
            v-b-tooltip.hover.v-dark.topright='"Artículo no registrado en el APP"'
          >
            <font-awesome-icon :icon="['far', 'info-circle']" class="text-warning" size="sm" />
          </span>
        </span>
      </template>
      <template v-slot:cell(sap_vendor_code)="data">
        <span class="font-weight-bold">
          <span v-if="data.value" class="fs-16">{{data.value}}</span>
          <span v-else class="fs-16 text-muted"> Sin código proveedor</span>
          <span
            v-if="!data.item.product_id"
            v-b-tooltip.hover.v-dark.topright='"Artículo no registrado en el APP"'
          >
            <font-awesome-icon :icon="['far', 'info-circle']" class="text-warning" size="sm" />
          </span>
        </span>
      </template>
      <template v-slot:cell(item_description)="data">
        <span>{{data.value}}</span>
      </template>
      <template v-slot:cell(sap_quantity)="data">
        <span v-if="data.item.origin == 'SAP'">{{ data.value }}</span>
        <span v-else class="text-muted">N/A</span>
      </template>
      <template v-slot:cell(tax_code)="data">
        <b-dropdown
          id="taxCodeItem"
          ref="taxCode"
          size="sm"
          variant="white"
          dropright
          :text="data.value"
          style="width: 70px;"
          :disabled="notEditable || !data.item.is_active"
        >
          <b-dropdown-item-button
            v-for="tax in itemTaxCodes" :key="tax.value"
            @click="updateTaxCode(data.item, tax.value)"
            :active="data.value == tax.value"
            class="p-0 m-0"
          >
            <small>{{tax.text}}</small>
          </b-dropdown-item-button>
        </b-dropdown>
      </template>
      <template v-slot:cell(warehouse_code)="data">
        <b-dropdown
          id="warehouseCodeItem"
          ref="warehouseCode"
          variant="white"
          dropright
          @hidden="searchTermWarehouse = ''"
          :text="data.value"
          :disabled="notEditable || !data.item.is_active"
        >
        <div v-if="warehouseCodes.length > 0 ">
          <b-input
            v-model="searchTermWarehouse"
            id="dropdown-form-search"
            placeholder="Buscar por código"
            class="px-1"
            style="width: 90%; margin: 0px 10px"
          ></b-input>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button
            v-for="warehouseCode in filteredWarehouseCodes" :key="warehouseCode.value"
            @click="updateWarehouseCode(data.item, warehouseCode.value)"
            :active="data.value == warehouseCode.value"
            class="p-0 m-0"
          >
            <small>{{warehouseCode.text}}</small>
          </b-dropdown-item-button>
        </div>
        <div v-else>
          <b-dropdown-item-button>
            <span class="text-danger">No hay bodegas para mostrar</span>
          </b-dropdown-item-button>
        </div>
        </b-dropdown>
      </template>
      <template v-slot:cell(quantity)="data">
        <ItemQuantityInput
          v-if="parentComponent != 'search' && !notEditable && data.item.is_active"
          parent-name="purchase_delivery"
          :parent-id="data.item.purchase_delivery_id"
          :item="data.item"
          size="lg"
          @item-updated="updateQuantity"
        />
        <span v-else>{{ data.value }}</span>
      </template>
      <template v-slot:cell(item_location)="data">
        <span v-if="data.value">{{ data.value }}</span>
        <span v-else class="text-secondary">Sin ubicación</span>
      </template>
      <template v-slot:cell(notes)="data">
        <span v-if="data.value" class="text-secondary">{{ data.value }}</span>
        <b-link
          v-else-if="data.item.quantity != data.item.sap_quantity && !data.value"
          class="text-danger"
          :disabled="notEditable || !data.item.is_active"
          @click="openItemModal(data.item)"
        >
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
          Pendiente
        </b-link>
      </template>
      <template v-slot:cell(is_checked)="data">
        <div class="text-center">
          <span v-if="data.value"><font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-success" /></span>
          <span v-else> <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" /> </span>
        </div>
      </template>
      <template v-slot:cell(price)="data">
        <div class="text-center">
          <div v-if="data.item.item_currency">
            <span v-if="data.item.item_currency == 'COL'">{{ data.value | colones2 }}</span>
            <span v-else-if="data.item.item_currency == 'USD'">{{  data.value| dollarize }}</span>
            <span v-else>{{ data.value }} <sup>{{ data.item.item_currency }}</sup> </span>
          </div>
          <div v-else>
            <span v-if="purchaseDelivery.doc_currency == 'COL'">{{ data.value | colones2 }}</span>
            <span v-else-if="purchaseDelivery.doc_currency == 'USD'">{{  data.value| dollarize }}</span>
            <span v-else>{{ data.value }} <sup>{{ purchaseDelivery.doc_currency }}</sup> </span>
          </div>
          <span v-if="data.value == 0 && data.item.is_checked">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width class="text-danger"/>
          </span>
        </div>
      </template>
      <template v-slot:cell(subtotal)="data">
        <div class="text-center">
          <span v-if="purchaseDelivery.doc_currency == 'COL'">{{  itemSubtotal(data.item) | colones2 }}</span>
          <span v-else-if="purchaseDelivery.doc_currency == 'USD'">{{  itemSubtotal(data.item) | dollarize }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <Button
          variant="yellow"
          :disabled="notEditable || !data.item.is_active"
          @click="openItemModal(data.item)"
        >
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
        </Button>
        <Button
          v-if="parentComponent == 'validation' && !data.item.is_active"
          variant="green"
          class="ml-1"
          :disabled="notEditable"
          @click="openInactiveItemModal(data.item)"
        >
          <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
        </Button>
        <Button
          v-if="parentComponent == 'validation' && data.item.is_active"
          class="ml-1"
          variant="red"
          :disabled="notEditable"
          @click="openInactiveItemModal(data.item)"
        >
          <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
        </Button>
      </template>
    </b-table>
    <!-- item modal -->
    <b-modal
      v-model="showItemModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      hide-footer
      hide-header
    >
      <b-container v-if="selectedItem">
        <b-row>
          <b-col>
            <ValidationObserver v-slot="{ invalid }">
              <article>
                <b-row align-h="center">
                  <b-col class="text-left">
                    <span class="fs-20 font-weight-bold">{{ selectedItem.sap_item_code }}</span>
                  </b-col>
                  <b-col cols="1" class="text-right mr-2 pb-1">
                    <span class=" delete-icon" @click="showItemModal = false">
                      <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
                    </span>
                  </b-col>
                </b-row>
                <b-row align-h="center" style="max-height: 80px; overflow-y: scroll;">
                  <b-col class="text-center">
                    <small class="text-muted text-wrap">{{ selectedItem.item_description }}</small>
                  </b-col>
                </b-row>
              </article>
              <hr>
              <article id="item-vendor-code" class="mb-2">
                <b-row><b-col><p class="font-lato mb-1">Código proveedor:</p></b-col></b-row>
                <b-row align-h="center">
                  <b-col class="text-center">
                    <!-- <b-input disabled :value="selectedItem.sap_vendor_code" class="text-center"></b-input> -->
                    <b v-if="selectedItem.sap_vendor_code">{{ selectedItem.sap_vendor_code }}</b>
                    <b v-else class="text-muted">No registrado</b>
                  </b-col>
                </b-row>
              </article>
              <article id="item-quantity-oc" class="mb-2">
                <b-row><b-col><p class="font-lato mb-1">Cantidad OC:</p></b-col></b-row>
                <b-row align-h="center">
                  <b-col>
                    <b-input disabled :value="selectedItem.origin == 'SAP' ? selectedItem.sap_quantity : 'N/A'" class="text-center"></b-input>
                  </b-col>
                </b-row>
              </article>
              <article id="item-quantity" class="mb-2">
                <b-row><b-col><p class="font-lato mb-1">Cantidad contada:</p></b-col></b-row>
                <b-row align-h="center">
                  <b-col>
                    <ValidationProvider :rules="{ required: true, min_value: 0}" name="cantidad" tag="div" v-slot="{ errors }">
                      <b-row align-h="center">
                        <b-input-group size="sm" style="width: 230px">
                          <b-input-group-prepend>
                            <b-button :disabled="errors.length > 0 || itemForm.quantity < 1" @click="itemForm.quantity--">
                              <font-awesome-icon :icon="['fas', 'minus']" size="lg" fixed-width />
                            </b-button>
                          </b-input-group-prepend>
                          <b-input
                            v-model="itemForm.quantity"
                            ref="quantityInput"
                            :class="{ 'red-input-border': errors[0] }"
                            class="text-center"
                            @click="$nextTick(() => $refs.quantityInput.select())"
                          />
                          <b-input-group-append>
                            <b-button :disabled="errors.length > 0" @click="itemForm.quantity++">
                              <font-awesome-icon :icon="['fas', 'plus']" size="lg" fixed-width />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-row>
                      <b-row v-if="errors.length > 0" align-h="center" class="mt-1" style="height: 20px">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-row>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </article>
              <article v-if="parentComponent == 'validation'" id="item-price" class="mb-2">
                <b-row><b-col><p class="font-lato mb-1">Precio:</p></b-col></b-row>
                <b-row align-h="center">
                  <b-col>
                    <ValidationProvider :rules="{ required: true, min_value: 0.01 }" immediate tag="div" v-slot="{ errors }">
                      <b-row align-h="center">
                        <b-input-group size="sm" style="width: 94%">
                          <b-input-group-prepend is-text>
                            <div v-if="selectedItem.item_currency">
                              <span v-if="selectedItem.item_currency == 'COL'">₡</span>
                              <span v-else-if="selectedItem.item_currency == 'USD'">$</span>
                              <span v-else>{{ selectedItem.item_currency }}</span>
                            </div>
                            <div v-else>
                              <span v-if="purchaseDelivery.doc_currency == 'COL'">₡</span>
                              <span v-else-if="purchaseDelivery.doc_currency == 'USD'">$</span>
                              <span v-else>{{ purchaseDelivery.doc_currency }}</span>
                            </div>
                          </b-input-group-prepend>
                          <b-input
                            v-model="itemForm.price"
                            ref="customPriceInput"
                            :class="{ 'red-input-border': errors[0] }"
                          />
                        </b-input-group>
                      </b-row>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </article>
              <article v-if="parentComponent == 'validation'" id="item-tax-code" class="mb-2">
                <b-row><b-col><p class="font-lato mb-1">Impuesto:</p></b-col></b-row>
                <b-row>
                  <b-col>
                    <b-dropdown
                      id="taxCodeItem"
                      ref="taxCode"
                      style="width: 100px"
                      size="sm"
                      variant="outline-primary"
                      dropright
                      :text="itemForm.tax_code"
                      :disabled="notEditable"
                    >
                      <b-dropdown-item-button
                        v-for="tax in itemTaxCodes" :key="tax.value"
                        @click="itemForm.tax_code = tax.value"
                        :active="selectedItem.tax_code == tax.value"
                        class="p-0 m-0"
                      >
                        <small>{{tax.text}}</small>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </article>
              <article v-if="parentComponent == 'validation'" id="item-warehouse" class="mb-2">
                <b-row><b-col><p class="font-lato mb-1">Bodega:</p></b-col></b-row>
                <b-row>
                  <b-col>
                    <b-dropdown
                      id="warehouseCodeItem"
                      ref="warehouseCode"
                      style="width: 100px"
                      variant="outline-primary"
                      dropright
                      @hidden="searchTermWarehouse = ''"
                      :text="itemForm.warehouse_code"
                      :disabled="notEditable"
                    >
                    <div v-if="warehouseCodes.length > 0 ">
                      <b-input
                        v-model="searchTermWarehouse"
                        id="dropdown-search"
                        placeholder="Buscar por código"
                        class="px-1"
                        style="width: 90%; margin: 0px 10px"
                      ></b-input>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item-button
                        v-for="warehouseCode in filteredWarehouseCodes" :key="warehouseCode.value"
                        @click="itemForm.warehouse_code = warehouseCode.value"
                        :active="selectedItem.warehouse_code == warehouseCode.value"
                        class="p-0 m-0"
                      >
                        <small>{{warehouseCode.text}}</small>
                      </b-dropdown-item-button>
                    </div>
                    <div v-else>
                      <b-dropdown-item-button>
                        <span class="text-danger">No hay bodegas para mostrar</span>
                      </b-dropdown-item-button>
                    </div>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </article>
              <article id="item-notes" class="mb-2">
                <b-row><b-col>
                  <p class="mb-1">Comentarios:<span v-if="itemForm.quantity != selectedItem.sap_quantity" class="asterisk">*</span></p>
                </b-col></b-row>
                <ValidationProvider :rules="{ required: itemForm.quantity != selectedItem.sap_quantity}" name="comentarios" tag="div" v-slot="{ errors }">
                  <b-row align-h="center">
                    <b-col>
                      <b-textarea
                        v-model="itemForm.notes"
                        :autofocus="itemForm.quantity != selectedItem.sap_quantity"
                        placeholder="Ingrese las notas"
                        :class="{ 'red-input-border': errors[0] }"
                        rows="3"
                      ></b-textarea>
                    </b-col>
                  </b-row>
                  <b-row v-if="errors.length > 0" align-h="center" class="mt-1" style="height: 20px">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-row>
                </ValidationProvider>
              </article>
              <b-row align-h="end">
                <b-col class="text-right">
                  <Button variant="green" :disabled="invalid" @click="updateItem">Actualizar</Button>
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-col>
        </b-row>
        <b-row v-if="isDev" align-h="end" class="text-right">
          <b-col>
            <small class="text-info">Item ID: {{ selectedItem.id }}</small>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- not checked items -->
    <b-modal
      v-model="notCheckedItemsModal"
      centered
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      hide-footer
      hide-header
      class="p-0"
    >
      <b-container fluid class="p-0">
        <b-row align-h="end">
          <b-col>
            <b>Artículos pendientes de conteo</b>
          </b-col>
          <b-col class="text-right">
            <span class=" delete-icon pr-0" @click="notCheckedItemsModal = false">
              <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table-simple
              hover
              class="border-bottom fs-14"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th class="text-center" style="max-width: 300px;">Código</b-th>
                  <b-th>Descripción</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="item in notCheckedItems" :key="item.id">
                  <b-td class="text-center">
                    {{ item.sap_item_code }}
                  </b-td>
                  <b-td>
                    {{ item.item_description }}
                  </b-td>
                  <b-td class="text-right">
                    <Button
                      size="sm"
                      variant="yellow"
                      class="ml-2"
                      :disabled="notEditable"
                      @click="openItemModal(item)"
                    >
                      <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                    </Button>
                  </b-td>
                </b-tr>
                <b-tr v-if="notCheckedItems.length === 0">
                  <b-td colspan="3" class="text-center text-danger">
                    No hay artículos pendientes por registrar
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- CONFIRMATION: Inactive-->
    <b-modal
      v-model="inactiveItemModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
    <b-container fluid v-if="selectedItem">
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'question-circle']"  class="text-primary modal-icon" />
      </b-row>
      <b-row align-h="center">
        <span class="fs-20 text-center">¿Desea {{ selectedItem.is_active ? 'borrar':'activar'}} el artículo {{selectedItem.sap_item_code}}?</span>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center">NOTA: El artículo se marcará como {{ selectedItem.is_active ? 'inactivo':'activo'}} y {{ selectedItem.is_active ? 'no':''}} se sincronizará con SAP</small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="inactiveItemModal = false">No</Button>
        <Button variant="green" v-if="selectedItem.is_active" @click="inactiveItem(false)">Sí</Button>
        <Button variant="green" v-if="!selectedItem.is_active" @click="inactiveItem(true)">Sí</Button>
      </b-row>
    </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Button from '../../shared/Button'
import ItemSelector from '../../shared/ItemSelector'
import ItemQuantityInput from '../../shared/ItemQuantityInput'

export default {
  name: 'PurchaseDeliveryItemTable',
  components: { Button, ValidationProvider, ValidationObserver, ItemSelector, ItemQuantityInput },
  props: {
    parentComponent: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchFields: [
        { key: 'sap_item_code', label: 'Código', thClass: 'text-center fs-18', thStyle: { width: '22rem' } },
        { key: 'quantity', label: 'Cant OC', sortable: true, thClass: 'text-center fs-18', tdClass: 'text-center', thStyle: { width: '10rem' } },
        { key: 'remaining_quantity', label: 'Cant restante', thClass: 'text-center fs-18', tdClass: 'text-center', thStyle: { width: '10rem' } },
        { key: 'item_description', label: 'Descripción', tdClass: 'text-left', thClass: 'text-center fs-18',  }
      ],
      validationFields: [
        { key: 'sap_item_code' ,stickyColumn: true,  label: 'Código', tdClass: 'text-break', thClass: 'text-center text-break fs-18', thStyle: { maxWidth: '20rem' } },
        { key: 'sap_quantity', label: 'Cant OC', tdClass: 'text-center align-middle', thClass: 'text-center align-middle fs-18', thStyle: { width: '7rem' }},
        { key: 'quantity', label: 'Cant conteo', tdClass: 'text-center align-middle', thClass: 'text-center align-middle fs-18', thStyle: { width: '10rem' }},
        { key: 'warehouse_code', label: 'Bodega', tdClass: 'text-center align-middle', thClass: 'text-center align-middle fs-18', thStyle: { width: '7rem' }},
        { key: 'price', label: 'Precio', tdClass: 'text-center align-middle', thClass: 'text-center align-middle fs-18', thStyle: { width: '7rem' }},
        { key: 'subtotal', label: 'Subtotal', tdClass: 'text-center align-middle', thClass: 'text-center align-middle fs-18', thStyle: { width: '7rem' }},
        { key: 'tax_code', label: 'Impuesto', tdClass: 'text-center align-middle', thClass: 'text-center align-middle fs-18', thStyle: { width: '7rem' }},
        { key: 'notes', label: 'Comentario', tdClass: 'text-left text-truncate', thClass: 'text-center align-middle fs-18', thStyle: { maxWidth: 'auto' }},
        { key: 'actions', label: '', tdClass: 'align-middle text-center p-0', thStyle: { width: '110px' }}
      ],
      filterOn: ['is_checked'],
      itemTaxCodes: [
        { value: 'IVC', text: 'IVC (13%)' },
        { value: 'IVG', text: 'IVG (13%)' },
        { value: 'IVM', text: 'IVM (10%)' },
        { value: 'EXEC', text: 'EXEC (100%)' },
        { value: 'IMPE', text: 'IMPE (100%)' },
        { value: 'IMPG', text: 'IMPG (100%)' }
      ],
      selectedItem: null,
      itemForm: {
        quantity: null,
        notes: '',
        price: 0,
        tax_code: '',
        warehouse_code: ''
      },
      searchTerm:'',
      filter: null,
      showItemModal: false,
      notCheckedItemsModal: false,
      inactiveItemModal: false,
      itemNotFound: null,
      processing: false,
      searching: false,
      warehouseCodes: [],
      searchTermWarehouse: '',
      loadingWarehouses: false,
      toggleItemCode: false
    }
  },
  mounted () {
    // TODO: Check it out!
    // this.sortItems()
    if (this.parentComponent == 'validation') { this.fetchWarehouses() }
  },
  computed: {
    ...mapGetters({
      isDev: 'isDev',
      purchaseDelivery: 'purchaseDeliverySelected',
      searchItem: 'searchItemByItemCode',
      notCheckedItems: 'notCheckedItems'
    }),
    fields () {
      if (this.parentComponent == 'search') {
        return this.searchFields
      } else if (this.parentComponent == 'register') {
        const itemCode = this.toggleItemCode ? 'sap_vendor_code' : 'sap_item_code'
        return [
          { key: itemCode, stickyColumn: true, label: 'Código', thClass: 'text-center fs-18 text-break align-middle', thStyle: { maxWidth: '20rem' } },
          { key: 'sap_quantity', label: 'Cant OC', tdClass: 'text-center align-middle', thClass: 'text-center fs-18 align-middle', thStyle: { width: '7rem' }},
          { key: 'quantity', label: 'Cant conteo', tdClass: 'text-center align-middle', thClass: 'text-center fs-18 align-middle', thStyle: { width: '10rem' }},
          { key: 'item_location', label: 'Zona', tdClass: 'text-center align-middle', thClass: 'text-center fs-18 align-middle', thStyle: { width: '10rem' }},
          { key: 'item_description', label: 'Descripción', tdClass: 'text-left', thClass: 'text-center fs-18 align-middle' },
          { key: 'notes', label: 'Comentario', tdClass: 'text-left text-truncate', thClass: 'text-center fs-18 align-middle'},
          { key: 'is_checked', label: 'Status', tdClass: 'text-center align-middle', thClass: 'text-center fs-18 align-middle', thStyle: { width: '5rem' }},
          { key: 'actions', label: '', tdClass: 'align-middle text-right', thStyle: { width: '5rem' }}
        ]
      } else if (this.parentComponent == 'validation') {
        return this.validationFields
      }
      return []
    },
    items () {
      let result = []
      if (this.parentComponent == 'search') {
        const items = this.$store.getters['purchaseOrderSearchedItems']
        result = items.filter(el => el.origin == 'SAP')
      } else {
        let items = this.$store.getters['purchaseDeliveryItems']
        if (this.parentComponent == 'register') {
          result = items.filter(el => {
                          return this.purchaseDelivery.is_executed ? el.is_checked && el.origin == 'SAP' : el.origin == 'SAP'
                        })
                        .map(item => {
                          let variant = ''
                          if (item.is_checked) {
                            variant = item.quantity != item.sap_quantity ? 'warning' : 'success'
                          }
                          this.$set(item, '_rowVariant', variant)
                          return item
                        })
        } else if (this.parentComponent == 'validation') {
          if (this.purchaseDelivery.is_executed) {
            result = items.map(item => {
              let variant = ''
              variant = !item.is_active || item.quantity == 0 ? 'danger' : ''
              this.$set(item, '_rowVariant', variant)
              return item
            })
          } else {
            result = items.map(item => {
              let variant = ''
              variant = !item.is_active ? 'danger' : 'secondary'
              this.$set(item, '_rowVariant', variant)
              return item
            })
          }
        }
      }
      return result
    },
    itemsCompleted () {
      if (this.items.length == 0) return 0
      return this.items.filter(item => item.is_checked).length
    },
    notEditable () {
      if (this.parentComponent == 'search') {
        return false
      } else if (this.parentComponent == 'register') {
        if (!this.purchaseDelivery.is_open || this.purchaseDelivery.sap_doc_no) { return true }
        if (this.purchaseDelivery.is_executed) { return true }
      } else if (this.parentComponent == 'validation') {
        if (!this.purchaseDelivery.is_open || this.purchaseDelivery.sap_doc_no) { return true }
        if (!this.purchaseDelivery.is_executed) { return true }
      }
      return false
    },
    lastSapLine () {
      let array = this.items.map(item => item.sap_line_num)
      array = array.sort((a, b) => b - a)
      return array.length > 0 ? array[0] : -1
    },
    filteredWarehouseCodes () {
      if (this.parentComponent != 'validation') { return [] }
      if (this.searchTermWarehouse) {
        return this.warehouseCodes.filter(el => el.value.includes(this.searchTermWarehouse)).slice(0,7)
      }
      return this.warehouseCodes.slice(0,7)
    },
    docCurrency () {
      if (this.purchaseDelivery) {
        return this.purchaseDelivery.doc_currency
      }
      return null
    }
  },
  methods: {
    openItemModal (item) {
      const { quantity, notes, price, tax_code, warehouse_code } = item
      this.itemForm = {
        quantity,
        notes,
        price,
        tax_code,
        warehouse_code
      }
      this.selectedItem = item
      this.showItemModal = true
    },
    searchCode () {
      if (!this.searchTerm) {
        this.itemNotFound = null
        return
      }
      let formateSearchTerm = this.searchTerm.toLowerCase()
      // remove accents from a string
      formateSearchTerm = formateSearchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      // remove whitespaces from a string
      formateSearchTerm = formateSearchTerm.replace(/ /g, '')
      const item = this.searchItem(formateSearchTerm)
      if (item) {
        if (!item.is_active) {
          this.itemNotFound = 'El artículo esta inactivo'
          return
        }
        this.itemNotFound = null
        this.openItemModal(item)
      } else {
        this.itemNotFound = 'No se encontró el artículo'
      }
    },
    openInactiveItemModal (item) {
      this.selectedItem = item
      this.inactiveItemModal = true
    },
    updateItem () {
      if (this.processing){ return }
      this.processing = true
      const usdPrice = this.convertToUsdPrice(this.selectedItem.item_currency, this.itemForm.price)
      this.$http.patch(`/api/production/purchase_deliveries/${this.selectedItem.purchase_delivery_id}/purchase_delivery_items/${this.selectedItem.id}`,{
        purchase_delivery_item: {
          quantity: this.itemForm.quantity,
          price: this.itemForm.price,
          usd_price: usdPrice,
          notes: this.itemForm.notes,
          tax_code: this.itemForm.tax_code,
          warehouse_code: this.itemForm.warehouse_code,
          is_checked: this.itemForm.quantity == 0 && [null, '', ""].includes(this.itemForm.notes) ? false : true
        }
      })
        .then(response => {
          console.log(response)
          this.$store.commit('updatedSelectedItem', response.data)
        })
        .catch(e => {
          // console.log(e.response.data)
        })
        .finally(() => {
          this.processing = false
          this.showItemModal = false
        })
    },
    inactiveItem (value) {
      if (this.processing){ return }
      this.processing = true
      this.$http.patch(`/api/production/purchase_deliveries/${this.selectedItem.purchase_delivery_id}/purchase_delivery_items/${this.selectedItem.id}`,{
        purchase_delivery_item: {
          is_active: value
        }
      })
        .then(response => {
          // console.log(response)
          this.$store.commit('updatedSelectedItem', response.data)
        })
        .catch(e => {
          // console.log(e.response.data)
        })
        .finally(() => {
          this.processing = false
          this.inactiveItemModal = false
        })
    },
    updateQuantity (item) {
      this.$store.commit('updatedSelectedItem', item)
    },
    updatePurchaseDeliveryItem (item,data) {
      if (this.processing){ return }
      this.processing = true
      this.$http.patch(`/api/production/purchase_deliveries/${item.purchase_delivery_id}/purchase_delivery_items/${item.id}`, data)
        .then(response => {
          console.log(response)
          this.$store.commit('updatedSelectedItem', response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        });
    },
    updateTaxCode (item, value) {
      const data = {
        purchase_delivery_item: {
          tax_code: value
        }
      }
      this.updatePurchaseDeliveryItem(item,data)
    },
    updateWarehouseCode (item, value) {
      const data = {
        purchase_delivery_item: {
          warehouse_code: value
        }
      }
      this.updatePurchaseDeliveryItem(item,data)
    },
    addPurchaseDeliveryItem (item) {
      this.$store.commit('addItemToPurchaseDelivery', item)
    },
    sortItems () {
      let sortedItems = []
      if (this.parentComponent == 'register') {
        sortedItems = this.items.sort((a,b) => {
                    // Unchecked items come first
                    if (!a.is_checked && b.is_checked) return -1
                    if (a.is_checked && !b.is_checked) return 1
                    // Checked items: prioritizing quantity vs. sap_quantity
                    if (a.is_checked && b.is_checked) {
                      if (a.quantity !== a.sap_quantity && b.quantity === b.sap_quantity) return -1;
                      if (a.quantity === a.sap_quantity && b.quantity !== b.sap_quantity) return 1;
                    }
                    return 0
                  })
      } else if (this.parentComponent == 'validation') {
        sortedItems = this.items.sort((a,b) => {
          if (a.is_active) {
            return -1;
          } else {
            return 0;
          }
        })
      }
      this.items = sortedItems
    },
    fetchWarehouses() {
      if (this.loadingWarehouses) return;
      this.loadingWarehouses = true;
      this.$http.get('/api/production/warehouses/active_warehouses')
        .then(response => {
          console.log(response)
          const { data: warehouses } = response
          this.warehouseCodes = warehouses.map(({sap_warehouse_code, name}) => {
            return {
              value: sap_warehouse_code,
              text: `${sap_warehouse_code}-${name}`}
          })
        })
        .catch(error => {
          this.warehouseCodes = []
          console.log(error)
        })
        .finally(() => {
          this.loadingWarehouses = false;
        });
    },
    convertToUsdPrice (item_currency, newPrice) {
      const { usd_crc_rate, eur_crc_rate, can_crc_rate } = this.purchaseDelivery
      if (item_currency == 'USD') {
        return newPrice
      } else if (item_currency == 'COL') {
        return this.convertPriceToCurrency('USD', newPrice, usd_crc_rate)
      } else if (item_currency == 'EUR') {
        const colones_price = this.convertPriceToCurrency('COL', newPrice, eur_crc_rate)
        return this.convertPriceToCurrency('USD', colones_price, usd_crc_rate)
      } else if (item_currency == 'CAN') {
        const colones_price = this.convertPriceToCurrency('COL', newPrice, can_crc_rate)
        return this.convertPriceToCurrency('USD', colones_price, usd_crc_rate)
      }
    },
    itemSubtotal ({price, quantity, item_currency}) {
      if (quantity == 0) return 0
      const { usd_crc_rate, eur_crc_rate, can_crc_rate } = this.purchaseDelivery
      if (item_currency == this.docCurrency) return price * quantity

      return this.calcItemSubtotal(usd_crc_rate, eur_crc_rate, can_crc_rate, price, quantity, item_currency)
    },
    calcItemSubtotal (usdCrcRate, eurCrcRate, canCrcRate, price, quantity, itemCurrency) {
      if (this.docCurrency == 'COL') {
        // convert to COLONES
        if (itemCurrency == 'USD') { return this.convertPriceToCurrency('COL', price, usdCrcRate) * quantity }
        else if (itemCurrency == 'EUR') { return this.convertPriceToCurrency('COL', price, eurCrcRate) * quantity }
        else if (itemCurrency == 'CAN') { return this.convertPriceToCurrency('COL', price, canCrcRate) * quantity }
      } else if (this.docCurrency == 'USD') {
        // convert to USD
        if (itemCurrency == 'COL') { return this.convertPriceToCurrency('USD', price, usdCrcRate) * quantity }
        else if (itemCurrency == 'EUR') {
          const colones_price = this.convertPriceToCurrency('COL', price, eurCrcRate)
          return this.convertPriceToCurrency('USD', colones_price, usdCrcRate) * quantity
        } else if (itemCurrency == 'CAN') {
          const colones_price = this.convertPriceToCurrency('COL', price, canCrcRate)
          return this.convertPriceToCurrency('USD', colones_price, usdCrcRate) * quantity
        }
      }
      return 0
    },
    convertPriceToCurrency (currency, price, rate) {
      const ALLOWED_CURRENCIES = ['USD', 'COL']
      if (!currency || !ALLOWED_CURRENCIES.includes(currency)) return 0
      return currency === 'COL' ? price * rate : price / rate
    }
  }
}
</script>

<style lang="scss" scoped>
.table-captions {
  button {
    height: 2.5rem;
  }
  svg {
    width: 2.8125rem;
    height: fit-content;
  }
}
.text-truncate {
  overflow: hidden;
  max-height: 6.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
