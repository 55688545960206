import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tipoAcceso", "tipoPanel" ]

  connect() {
    this.filterFields();
  }

  filterFields() {
    var selectedValue = this.tipoAccesoTarget.value;
    if (selectedValue == 'Seccional') {
      document.getElementById('if-elevadizo').classList.remove('d-none');
      document.getElementById('if-seccional').classList.remove('d-none');
      document.getElementById('unless-seccional').classList.add('d-none');
      document.getElementById('if-cortina').classList.add('d-none');
      document.getElementById('if-coraba').classList.add('d-none');
    } else if (selectedValue == 'Cortina') {
      document.getElementById('if-elevadizo').classList.remove('d-none');
      document.getElementById('if-seccional').classList.add('d-none');
      document.getElementById('unless-seccional').classList.remove('d-none');
      document.getElementById('if-cortina').classList.remove('d-none');
      document.getElementById('if-coraba').classList.add('d-none');
    } else {  // Corredizo/Abatible or blank
      document.getElementById('if-elevadizo').classList.add('d-none');
      document.getElementById('if-seccional').classList.add('d-none');
      document.getElementById('unless-seccional').classList.remove('d-none');
      document.getElementById('if-cortina').classList.add('d-none');
      document.getElementById('if-coraba').classList.remove('d-none');
    }
  }

  //TODO: Move rest of design form jQuery to this file
}

