import Vue from 'vue';

Vue.filter('colones3', function (value) {
  value = Number(value);
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return `COL ${formatter.format(value)}`
})
