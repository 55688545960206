<template>
  <div class="d-inline">
    <font-awesome-icon
      :icon="['fas', 'exclamation-triangle']"
      fixed-width
      :class="{ 'invisible': field != null && field != '' }"
      class="blank-icon"
    />
  </div>
</template>

<script>
export default {
  name: 'BlankIcon',
  props: ['field']
}
</script>

<style scoped>
.blank-icon {
  color: #FAC312;
  margin-top: 6px;
  margin-left: -27px;
}
</style>
