<template>
  <div>
    <ContentView class="pb-5">
      <Subheader>
        <font-awesome-icon :icon="['far', 'newspaper']" size="lg" fixed-width />
        <span class="pl-2">Noticias</span>
      </Subheader>
      <b-row align-h="between" align-v="center" class="py-3 mb-4" style="border-bottom: 1px solid #aaa">
        <div class="font-lato">
          <span class="fs-18">CHANGELOG</span>
          <span class="font-italic text-muted pl-5 fs-12">Registro de los cambios más recientes en el APP que pueden afectar los usuarios.  No incluye los cambios internos y mantenimiento del código</span>
        </div>
        <Button v-if="isSuperAdmin" size="sm" variant="green" class="mr-3" @click="addChangeLogModalShow = true">
          <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
          <span class="pl-2">Nuevo</span>
        </Button>
      </b-row>
      <b-container v-for="group in groupedChangeLogs" :key="group.date" class="mb-3" fluid>
        <b-row class="pl-3">
          <p class="font-weight-light text-muted">{{ group.date | formatDateLong }}</p>
        </b-row>
        <b-card v-for="log in group.log" :key="log.id">
          <b-row class="pt-1 px-5">
            <b-col cols="11">
              <b-row>
                <p class="font-lato">
                  <!-- <b-badge v-if="log.kind == 'nuevo'" variant="success" class="log-badge">Nuevo</b-badge>
                  <b-badge v-else-if="log.kind == 'cambio'" variant="secondary" class="log-badge">Cambio</b-badge>
                  <b-badge v-else-if="log.kind == 'arreglo'" variant="danger" class="log-badge">Arreglo</b-badge> -->
                  <span>•</span>
                  <span class="pl-2">{{ log.title }}</span>
                </p>
              </b-row>
              <b-row v-if="log.description" class="pl-4 fs-13">
                <p class="text-muted">{{ log.description }}</p>
              </b-row>
              <b-row class="pl-4 fs-13">
                <b-badge v-for="tag in log.tags" :key="tag.id" variant="info" class="mr-2">{{ tag.name }}</b-badge>
              </b-row>
            </b-col>
            <b-col cols="1" class="text-right">
              <Button v-if="isSuperAdmin" size="sm" class="ml-3" @click="openEditChangeLogModal(log)">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
              </Button>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
      <Observer v-if="!loading" @intersect="intersected" />
      <SpinnerHorizontal v-if="addingPage" />
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Add Changelog Modal -->
    <b-modal
      centered
      v-model="addChangeLogModalShow"
      title="Agregar cambio del app"
      @hide="resetChangeLogForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-form-group label-size="sm" style="width: 350px">
            <template #label>
              <span>Título:</span><span class="asterisk">*</span>
            </template>
            <b-textarea v-model="changeLogForm.title" size="sm" autofocus></b-textarea>
          </b-form-group>
          <b-form-group label="Tipo:" label-size="sm" style="width: 350px">
            <b-radio-group v-model="changeLogForm.kind" size="sm">
              <b-radio value="cambio">Cambio</b-radio>
              <b-radio value="nuevo">Nuevo feature</b-radio>
              <b-radio value="arreglo">Arreglo</b-radio>
            </b-radio-group>
          </b-form-group>
          <!-- TODO: is breaking checkbox -->
          <b-form-group label="Detalles:" label-size="sm" style="width: 350px">
            <b-textarea v-model="changeLogForm.description" rows="3" size="sm" placeholder="(Opcional)"></b-textarea>
          </b-form-group>
          <b-form-group label="Etiquetas:" label-size="sm" style="width: 350px">
            <b-checkbox-group v-model="changeLogForm.tag_ids" size="sm">
              <b-checkbox v-for="tag in tags" :key="tag.id" :value="tag.id">{{ tag.name }}</b-checkbox>
            </b-checkbox-group>
          </b-form-group>
          <b-form-group label="Fecha de Lanzamiento:" label-size="sm" style="width: 350px">
            <b-row align-h="center">
              <flat-pickr
                v-model="changeLogForm.release_date"
                :config="flatpickrConfig"
                class="d-none"
              ></flat-pickr>
            </b-row>
          </b-form-group>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button class="float-right" variant="green" :disabled="!changeLogForm.title" @click="createChangeLog">Publicar</Button>
      </template>
    </b-modal>

    <!-- Edit Changelog Modal -->
    <b-modal
      centered
      v-model="editChangeLogModalShow"
      title="Editar cambio del app"
      @hide="resetChangeLogForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-form-group label-size="sm" style="width: 350px">
            <template #label>
              <span>Título:</span><span class="asterisk">*</span>
            </template>
            <b-textarea v-model="changeLogForm.title" size="sm" autofocus></b-textarea>
          </b-form-group>
          <b-form-group label="Tipo:" label-size="sm" style="width: 350px">
            <b-radio-group v-model="changeLogForm.kind" size="sm">
              <b-radio value="cambio">Cambio</b-radio>
              <b-radio value="nuevo">Nuevo feature</b-radio>
              <b-radio value="arreglo">Arreglo</b-radio>
            </b-radio-group>
          </b-form-group>
          <!-- TODO: is breaking checkbox -->
          <b-form-group label="Detalles:" label-size="sm" style="width: 350px">
            <b-textarea v-model="changeLogForm.description" rows="3" size="sm" placeholder="(Opcional)"></b-textarea>
          </b-form-group>
          <b-form-group label="Etiquetas:" label-size="sm" style="width: 350px">
            <b-checkbox-group v-model="changeLogForm.tag_ids" size="sm">
              <b-checkbox v-for="tag in tags" :key="tag.id" :value="tag.id">{{ tag.name }}</b-checkbox>
            </b-checkbox-group>
          </b-form-group>
          <b-form-group label="Fecha de Lanzamiento:" label-size="sm" style="width: 350px">
            <b-row align-h="center">
              <flat-pickr
                v-model="changeLogForm.release_date"
                :config="flatpickrConfig"
                class="d-none"
              ></flat-pickr>
            </b-row>
          </b-form-group>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button class="float-right" variant="green" :disabled="!changeLogForm.title" @click="updateChangeLog">Publicar</Button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { mapGetters } from 'vuex';
import { normalize, schema } from 'normalizr';
import Button from './shared/Button';
import ContentView from './shared/ContentView';
import Observer from './shared/Observer';
import SpinnerHorizontal from './shared/SpinnerHorizontal';
import Subheader from './shared/Subheader';
import ToastAlert from './shared/ToastAlert';

export default {
  name: 'AppNews',
  components: { Button, ContentView, Observer, SpinnerHorizontal, Subheader, ToastAlert, flatPickr },
  data() {
    return {
      changeLogData: {},
      changeLogList: [],
      tags: [],
      addChangeLogModalShow: false,
      changeLogForm: {
        user_id: this.$store.state.currentUser.id,
        title: null,
        kind: 'cambio',
        description: null,
        is_breaking: false,
        release_date: new Date(),
        tag_ids: []
      },
      selectedChangeLog: null,
      editChangeLogModalShow: false,
      page: 1,
      count: null,
      last: null,
      loading: false,
      processing: false,
      addingPage: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      flatpickrConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        inline: true,
        disableMobile: true
      }
    }
  },
  computed: {
    ...mapGetters(['isSuperAdmin']),
    changeLogs() {
      let array = this.changeLogList.map(id => this.changeLogData[id]);
      return array.sort((a,b) => new Date(b.release_date) - new Date(a.release_date));
    },
    groupedChangeLogs() {
      // object with dates as keys
      const groups = this.changeLogs.reduce((groups, log) => {
        const date = log.release_date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(log);
        return groups;
      }, {});
      // convert to array format
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          log: groups[date]
        };
      });
      return groupArrays;
    }
  },
  mounted() {
    this.loading = true;
    this.fetchChangeLogs();
  },
  methods: {
    fetchChangeLogs() {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('/api/change_logs', {
          params: {
            page: this.page
          }
        })
        .then(response => {
          console.log(response.data)
          const changeLogData = normalize(
            { changeLogs: response.data.logs },
            { changeLogs: [ new schema.Entity('changeLogs') ] }
          );

          if (this.addingPage) {
            // add to existing data
            if (changeLogData.entities.hasOwnProperty('changeLogs')) {
              this.changeLogData = Object.assign({}, this.changeLogData, changeLogData.entities.changeLogs);
            }
            this.changeLogList = [...this.changeLogList, ...changeLogData.result.changeLogs];
          } else {
            // seed fresh data
            if (changeLogData.entities.hasOwnProperty('changeLogs')) {
              this.changeLogData = changeLogData.entities.changeLogs;
            }
            this.changeLogList = changeLogData.result.changeLogs;
          }
          this.tags = response.data.tags;
          this.count = response.data.pagy.count;
          this.last = response.data.pagy.last;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.processing = false;
          this.addingPage = false;
        });
    },
    createChangeLog() {
      if (this.processing) return;
      this.processing = true;
      this.$http.post('/api/change_logs', {
          change_log: this.changeLogForm
        })
        .then(response => {
          console.log(response.data)
          this.changeLogList.unshift(response.data.id);
          this.$set(this.changeLogData, response.data.id, response.data);
          this.alertVariant = 'success';
          this.alertMessage = `Cambio del app se ha publicado`;
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0];
          } else {
            this.alertMessage = 'Algo no funcionó';
          }
        })
        .finally(() => {
          this.processing = false;
          this.alertShow = true;
          this.addChangeLogModalShow = false;
        });
    },
    openEditChangeLogModal(log) {
      this.selectedChangeLog = log;
      this.changeLogForm.title = this.selectedChangeLog.title;
      this.changeLogForm.kind = this.selectedChangeLog.kind;
      this.changeLogForm.description = this.selectedChangeLog.description;
      this.changeLogForm.is_breaking = this.selectedChangeLog.is_breaking;
      this.changeLogForm.release_date = this.selectedChangeLog.release_date;
      this.changeLogForm.tag_ids = this.selectedChangeLog.tags.map(tag => tag.id);
      this.editChangeLogModalShow = true;
    },
    updateChangeLog() {
      if (this.processing) return;
      this.processing = true;
      this.$http.put(`/api/change_logs/${this.selectedChangeLog.id}`, {
          change_log: this.changeLogForm
        })
        .then(response => {
          console.log(response.data)
          this.changeLogData[response.data.id] = response.data;
          this.selectedChangeLog = null;
          this.alertVariant = 'success';
          this.alertMessage = 'Cambios se guardaron';
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0];
          } else {
            this.alertMessage = 'Algo no funcionó';
          }
        })
        .finally(() => {
          this.processing = false;
          this.alertShow = true;
          this.editChangeLogModalShow = false;
        });
    },
    resetChangeLogForm() {
      this.changeLogForm.title = null;
      this.changeLogForm.kind = 'cambio';
      this.changeLogForm.description = null;
      this.changeLogForm.is_breaking = false;
      this.changeLogForm.release_date = new Date();
      this.changeLogForm.tag_ids = [];
    },
    intersected() {
      console.log('Intersected')
      if (this.page + 1 <= this.last) {
        console.log('Adding next page')
        this.page++;
        this.addingPage = true;
        this.fetchChangeLogs();
      }
    }
  }
}
</script>

<style scoped>
.log-badge {
  width: 70px;
}
</style>
