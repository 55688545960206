<template>
  <div>
    <b-row class="mx-0">
      <b-col style="flex: 0 0 50px">
        <div class="title-badge-icon" :class="'bg-' + type.toLowerCase()">
          <font-awesome-icon v-if="type == 'Client'" :icon="['fas', 'industry-alt']" size="2x" />
          <font-awesome-icon v-if="type == 'Project'" :icon="['fas', 'briefcase']" size="2x" />
          <font-awesome-icon v-if="type == 'Access'" :icon="['fas', 'warehouse']" size="2x" />
          <font-awesome-icon v-if="type == 'Employee'" :icon="['fas', 'user-tie']" size="2x" />
          <font-awesome-icon v-if="type == 'Bundle'" :icon="['fas', 'boxes-alt']" size="2x" />
        </div>
      </b-col>
      <b-col class="pl-5">
        <b-row align-h="between" class="title-badge-text-top pr-2">
          <span style="color: #6c757d;">{{ typeString }}</span>
          <span style="letter-spacing: 2px">
            <slot name="id"></slot>
            {{ id }}
          </span>
        </b-row>
        <b-row class="title-badge-text-bottom">
          <div style="word-wrap: break-word">
            <slot name="title"></slot>
            {{ title }}
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'TitleBadge',
  props: {
    id: String,
    type: String,
    title: String
  },
  computed: {
    typeString() {
      if (this.type == 'Client') {
        return 'Cliente'
      } else if (this.type == 'Project') {
        return 'Proyecto'
      } else if (this.type == 'Access') {
        return 'Acceso'
      } else if (this.type == 'Employee') {
        return 'Empleado'
      } else if (this.type == 'Bundle') {
        return 'Bulto'
      }
    },
  }
}
</script>

<style scoped>
.title-badge-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #163D7D;
  border-radius: 5px;
  width: 50px;
  height: 50px;
}
.title-badge-icon.bg-client {
  background-color: rgb(121, 100, 101);
}
.title-badge-icon.bg-project {
  background-color: #163D7D;
}
.title-badge-icon.bg-access {
  background-color: #ff9900;
}
.title-badge-icon.bg-employee {
  background-color: #333;
}
.title-badge-icon.bg-bundle {
  background-color: #333;
}
.title-badge-text-top {
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 0;
}
.title-badge-text-bottom {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  margin-bottom: 0;
}
</style>
