<template>
  <div>
    <DistributorNavTabs v-if="isDistributor" />
    <InstallerNavTabs v-else />
    <b-container fluid>
      <b-row class="mt-3 mb-4 pl-2 pl-sm-5">
        <b-col lg="9">
          <b-row>
            <b-col lg="6" class="pb-3 pb-lg-0">
              <b-table-simple small borderless class="mb-0">
                <b-tbody>
                  <b-tr class="fs-18">
                    <b-td class="text-right" style="width: 150px">
                      <font-awesome-icon :icon="['fas', 'calculator']" />
                      <span class="pl-2">Cotización:</span>
                    </b-td>
                    <b-td>
                      <span v-if="quote">#{{ quote.quote_id }}</span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col lg="6" class="text-right">
              <QuoteButtonBar
                v-if="quote"
                :quote="quote"
                :client="client"
                :displayCRC="displayCRC"
                :isTaxExempt="isTaxExempt"
                @quote-copied="copyQuote"
                @quote-emailed="emailQuote"
                @quote-transferred="transferQuote"
                @process-failed="showAlertError"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="border-top: 3px solid #f2f2f2">
        <b-col lg="9" order="2" order-lg="1">

          <!-- Quote template -->
          <b-row align-h="center" class="mb-5 px-0 px-sm-3 py-3">
            <b-col class="px-1 px-sm-3">
              <!-- Header -->
              <b-row class="px-2">
                <b-col sm="4" cols="6">
                  <div style="width: 200px; margin-left: 50px">
                    <img v-if="profile && profile.logo_url" :src="profile.logo_url" class="logo-size">
                  </div>
                </b-col>
                <b-col sm="4" class="d-none d-sm-block fs-10">
                  <div v-if="profile" id="quote-contact-info">
                    <p class="mb-0">{{ profile.company_name }}</p>
                    <p class="mb-0">Teléfono: {{ profile.phone }}, Fax: {{ profile.fax }}</p>
                    <p class="mb-0">Email: {{ profile.email }}</p>
                    <p class="mb-0">Cédula: {{ profile.cedula }}</p>
                    <p class="mb-0">{{ profile.city }}, Costa Rica</p>
                  </div>
                </b-col>
                <b-col sm="4" cols="6">
                  <b-container>
                    <b-row align-h="end" class="fs-14">
                      <span>COTIZACIÓN</span>
                    </b-row>
                    <b-row align-h="end">
                      <span v-if="ready" class="font-weight-bold">#{{ quote.quote_id }}</span>
                    </b-row>
                    <b-row align-h="end" class="fs-10">
                      <span v-if="ready">{{ quote.created_at | formatDateCompact }}</span>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>

              <b-container class="mt-4 mb-3">
                <b-row>
                  <b-col>
                    <b-row class="pl-2 mb-2">
                      <b-col lg="2" cols="4" class="px-0 text-right text-muted">
                        <span class="mr-3">Cotizado para:</span>
                      </b-col>
                      <b-col lg="10" cols="8" class="pl-2 pr-0">
                        <b-link v-if="client && isInstaller" :to="`/app/inst/clientes/${client.id}`" class="related-link">
                          {{ client.name }}
                        </b-link>
                        <b-link v-else-if="client && isDistributor" :to="`/app/dist/clientes/${client.id}`" class="related-link">
                          {{ client.name }}
                        </b-link>
                      </b-col>
                    </b-row>
                    <!-- <b-row v-if="quote && quote.sap_contact_person_code" class="pl-2 fs-12">
                      <b-col lg="2" cols="4" class="px-0 pt-1 text-right text-muted">
                        <span class="mr-3">Atención:</span>
                      </b-col>
                      <b-col lg="6" cols="8" class="text-truncate px-0">
                        <div class="click-to-edit" @click="openContactDataModal">
                          <p class="mb-0">
                            <span>{{ quote.contact_data['name'] }}</span>
                          </p>
                          <p v-show="quote.contact_data['phone'] || quote.contact_data['cellular']" class="mb-0">
                            <span v-if="quote.contact_data['phone']">Teléfono:</span>
                            <span v-if="quote.contact_data['phone']" class="pl-1">{{ quote.contact_data['phone'] }}</span>
                            <span v-if="quote.contact_data['cellular']">, Cellular:</span>
                            <span v-if="quote.contact_data['cellular']" class="pl-1">{{ quote.contact_data['cellular'] }}</span>
                          </p>
                          <p v-show="quote.contact_data['email']" class="mb-0">
                            <span>Email:</span>
                            <span class="pl-2">{{ quote.contact_data['email'] }}</span>
                          </p>
                        </div>
                      </b-col>
                    </b-row> -->
                  </b-col>
                </b-row>
              </b-container>
              <hr>

              <b-container>
                <b-row class="my-2">
                  <b-col>
                    <b-row align-v="center" class="pl-2" style="min-height: 45px">
                      <b-col lg="2" cols="3" class="px-0 text-right text-muted">
                        <span class="pl-1 mr-3">Titulo:</span>
                      </b-col>
                      <b-col lg="10" cols="9" class="px-0">
                        <div v-if="ready">
                          <div v-if="titleEdit">
                            <b-input
                              v-model="form.title"
                              size="sm"
                              ref="title"
                              @blur="updateQuote"
                              @keyup.enter="updateQuote"
                            />
                          </div>
                          <div v-else>
                            <span v-if="quote.title" class="click-to-edit" @click="titleEdit = true; focus('title')">
                              {{ quote.title }}
                            </span>
                            <b-button v-else class="fill-quote-button" variant="outline-warning" @click="titleEdit = true; focus('title')">
                              <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                              <span>Llenar</span>
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <b-container>
                <b-row v-if="ready" align-v="end" class="my-3">
                  <b-col cols="7">
                    <toggle-button
                      v-model="displayCRC"
                      :labels="{ checked: 'Colones', unchecked: 'Dolares' }"
                      :width="75"
                      color="#cd8488"
                      class="mb-0"
                    />
                    <toggle-button
                      v-if="quote.is_open"
                      v-model="isTaxExempt"
                      :labels="{ checked: 'EXO', unchecked: 'IV' }"
                      :width="75"
                      sync
                      color="#cd8488"
                      class="mb-0 ml-3"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-row align-h="end" class="pr-1">
                      <QuoteItemSelector
                        v-if="quote.is_open"
                        :quote-id="id"
                        :displayCRC="displayCRC"
                        :exchange-rate="exchangeRate"
                        :price-list="priceList"
                        @quote-item-added="addQuoteItem"
                        @total-changed="saveTotal"
                      />
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Quote List -->
              <b-container class="my-3 fs-13">
                <b-row align-v="center" align-h="center" style="min-height: 5em">
                  <QuoteItemTable
                    v-if="ready"
                    :quote="quote"
                    :quote-items="quoteItems"
                    :price-list="priceList"
                    is-distribution
                    @item-updated="updateItem"
                    @item-deleted="loadItemData"
                    @total-changed="saveTotal"
                    @quote-item-added="addQuoteItem"
                  />
                  <div v-else class="text-info d-flex align-items-center">
                    <b-spinner style="width: 1.25rem; height: 1.25rem" type="grow"></b-spinner>
                    <span class="pl-3 fs-20">Cargando datos...</span>
                  </div>
                </b-row>
              </b-container>

              <!-- Guarantee, condition, and totals -->
              <b-container id="quote-guarantee-section">
                <b-row>
                  <b-col lg="6" order="2" order-lg="1">
                    <b-row align-v="center" class="pl-2">
                      <span class="mr-3 font-weight-light">Garantía: *</span>
                      <div v-if="ready">
                        <div v-if="warrantyTimeEdit">
                          <b-select
                            v-model="form.warranty_time"
                            :options="warrantyTimeOptions"
                            size="sm"
                            ref="warrantyTime"
                            style="width: 200px"
                            @change="updateQuote"
                          />
                        </div>
                        <div v-else>
                          <span v-if="quote.warranty_time" class="click-to-edit" @click="warrantyTimeEdit = true; focus('warrantyTime');">
                            {{ quote.warranty_time }}
                          </span>
                          <b-button v-else class="fill-quote-button" variant="outline-warning" @click="warrantyTimeEdit = true; focus('warrantyTime');">
                            <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                            <span>Llenar</span>
                          </b-button>
                        </div>
                      </div>
                    </b-row>
                    <b-row align-v="center" class="pl-2">
                      <span class="d-inline-block font-weight-light">Comentarios:</span>
                      <b-button v-if="ready && !notesEdit && !quote.notes" variant="outline-warning" class="fill-quote-button ml-3" @click="notesEdit = true; focus('notes');">
                        <span>Anotar</span>
                      </b-button>
                    </b-row>
                    <b-row v-if="ready" class="mt-2 px-2">
                      <div v-if="notesEdit" class="w-100">
                        <ValidationProvider rules="max:260" name="comentarios" v-slot="{ errors }">
                          <b-textarea
                            v-model="form.notes" rows="3" size="sm" trim ref="notes"
                            :class="{ 'red-input-border': errors[0] }"
                            @blur="errors[0] ? null : updateQuote()"
                            @keydown.enter="errors[0] ? null : updateQuote()"
                          />
                          <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div v-else>
                        <p v-if="quote.notes" class="click-to-edit font-italic fs-14" @click="notesEdit = true; focus('notes');">
                          {{ quote.notes }}
                        </p>
                      </div>
                    </b-row>
                  </b-col>
                  <b-col lg="6" order="1" order-lg="2" class="pb-4 pb-sm-0" id="quote-total-box">
                    <b-table-simple borderless small>
                      <b-tbody>
                        <b-tr>
                          <b-td></b-td>
                          <b-td class="text-right lato-label">SUBTOTAL</b-td>
                          <b-td class="text-right price-display">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ subtotalCRC | colones }}</span>
                              <span v-else>{{ subtotal | dollarize }}</span>
                            </div>
                            <div v-else>
                              <span v-if="displayCRC">‎₡0</span>
                              <span v-else>$0</span>
                            </div>
                          </b-td>
                          <b-td></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right">
                            <div v-if="ready">
                              <div v-if="discountEdit">
                                <ValidationProvider :rules="{ required: true, max_value: maxSalesDiscount, min_value: 0 }" slim v-slot="{ errors }">
                                  <b-input-group size="sm" append="%" class="ml-auto" style="width: 90px">
                                    <b-input
                                      v-model="form.discount"
                                      type="number"
                                      ref="discountInput"
                                      class="no-spin"
                                      :class="{ 'red-input-border': errors[0] }"
                                      @click="$nextTick(() => $refs.discountInput.select())"
                                      @keyup.enter="errors[0] ? null : updateDiscount()"
                                      @blur="errors[0] ? null : updateDiscount()"
                                    />
                                  </b-input-group>
                                </ValidationProvider>
                              </div>
                              <span v-else :class="{'click-to-edit': quote.is_open}" @click="focusDiscount">{{ parseFloat(quote.discount.toFixed(2)) }} %</span>
                            </div>
                          </b-td>
                          <b-td class="text-right lato-label second-col">DESCUENTO</b-td>
                          <b-td class="text-right price-display third-col">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ discountCRC | colones }}</span>
                              <span v-else>{{ discount | dollarize }}</span>
                            </div>
                          </b-td>
                          <b-td></b-td>
                        </b-tr>
                        <b-tr v-if="!isTaxExempt">
                          <b-td></b-td>
                          <b-td class="text-right lato-label">IMPUESTO</b-td>
                          <b-td class="text-right price-display">
                            <div v-if="ready && !isTaxExempt">
                              <span v-if="displayCRC">{{ taxCRC | colones }}</span>
                              <span v-else>{{ tax | dollarize }}</span>
                            </div>
                          </b-td>
                          <b-td>
                          </b-td>
                        </b-tr>
                        <b-tr class="pt-3 border-top">
                          <b-td colspan="2" class="text-right">
                            <div v-show="ready && total > 0 && !displayCRC && quote.is_open" style="display: inline; cursor: pointer" id="custom-total-info">
                              <font-awesome-icon :icon="['far', 'info-circle']" class="text-info" />
                            </div>
                            <span v-if="displayCRC" class="font-weight-bold">TOTAL COLONES</span>
                            <span v-else class="font-weight-bold">TOTAL DOLARES</span>
                          </b-td>
                          <b-td class="text-right">
                            <div v-if="ready">
                              <div v-if="customTotalEdit">
                                <b-input-group size="sm" prepend="$">
                                  <b-input
                                    v-model="customTotalForm.total"
                                    type="number"
                                    ref="customTotal"
                                    class="text-center no-spin"
                                    :state="customTotalValid"
                                    @input="inputTotal"
                                    @blur="updateTotal"
                                    @keyup.enter="updateTotal"
                                  />
                                </b-input-group>
                              </div>
                              <div v-else class="price-display">
                                <div v-if="displayCRC">
                                  <span v-if="totalCRC > 0" class="p-0">{{ totalCRC | colones }}</span>
                                  <span v-else>{{ totalCRC | colones }}</span>
                                </div>
                                <div v-else>
                                  <span v-if="total > 0" :class="{ 'click-to-edit': quote.is_open }" class="p-0" @click="focusTotal">{{ total | dollarize }}</span>
                                  <span v-else>{{ total | dollarize }}</span>
                                </div>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Signature footer -->
              <b-container class="mt-5" id="quote-sigbox">
                <b-row class="px-2 border-bottom" style="min-height: 3em">
                  <b-col sm="6" class="asesor-box">
                    <b-row class="label-size-md">Asesor:</b-row>
                  </b-col>
                  <b-col sm="3" class="delivery-box">
                    <b-row align-h="center" class="label-size-md">
                      Tiempo de entrega:
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <div v-if="ready">
                        <div v-if="deliveryTimeEdit">
                          <b-input-group size="sm" append="días" style="width: 120px">
                            <b-input
                              v-model="form.delivery_time"
                              ref="deliveryTime"
                              @keyup.enter="updateQuote"
                              @blur="updateQuote"
                            />
                          </b-input-group>
                        </div>
                        <div v-else>
                          <span v-if="quote.delivery_time" class="click-to-edit" @click="deliveryTimeEdit = true; focus('deliveryTime');">
                            {{ quote.delivery_time }} días
                          </span>
                          <b-button v-else class="fill-quote-button" variant="outline-warning" @click="deliveryTimeEdit = true; focus('deliveryTime')">
                            <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                            <span>Llenar</span>
                          </b-button>
                        </div>
                      </div>
                    </b-row>
                  </b-col>
                  <b-col sm="3" class="valid-until-box">
                    <b-row align-h="center" class="label-size-md">
                      Cotización válida hasta:
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <div v-if="ready">
                        <span v-if="quote.valid_until" :class="{ 'click-to-edit': quote.is_open }" @click="openDatepicker">{{ quote.valid_until | formatDate }}</span>
                        <b-button v-else-if="quote.is_open" class="fill-quote-button" variant="outline-warning" @click="openDatepicker">
                          <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                          <span>Llenar</span>
                        </b-button>
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-h="center" class="px-2 border-bottom border-top border-secondary" id="quote-sigbox-accept">
                  <span class="font-weight-bold label-size-lg">ACEPTACIÓN Y FORMALIZACIÓN DE LA COTIZACIÓN:</span>
                </b-row>
                <!-- <b-row align-h="center" class="label-size-sm p-1 border-bottom border-secondary">
                  <span>Facturar a: _____________________________________</span>
                  <span class="pl-2">Cédula Número: _____________________</span>
                  <span class="px-1">en Moneda:</span>
                  <span v-if="displayCRC">Colones</span>
                  <span v-else>US$</span>
                </b-row> -->
                <b-row align-h="center" class="border-bottom border-secondary label-size-lg" style="min-height: 4em">
                  <b-col cols="4" class="border-right border-secondary">
                    <b-row class="pl-2">Nombre:</b-row>
                  </b-col>
                  <b-col cols="3" class="border-right border-secondary">
                    <b-row class="pl-2">Firma:</b-row>
                  </b-col>
                  <b-col cols="3" class="border-right border-secondary">
                    <b-row class="pl-2">Cédula:</b-row>
                  </b-col>
                  <b-col cols="2">
                    <b-row class="pl-2">Fecha:</b-row>
                  </b-col>
                </b-row>
              </b-container>
              <!-- For watch reactivity? -->
              <div class="invisible">{{ total }}</div>
            </b-col>
          </b-row>
        </b-col>

        <!-- Right sidebar -->
        <b-col lg="3" order="1" order-lg="2" class="font-lato fs-14" id="right-sidebar" style="border-left: 3px solid #f2f2f2">
          <b-row v-if="quote" class="my-2">
            <b-col class="text-right">
              <Button v-if="quote.is_open" size="sm" class="mr-2" @click="editModalShow = true">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-2">Editar</span>
              </Button>
              <Button v-if="quote.is_open" size="sm" class="text-success" @click="closingModalShow = true">
                <font-awesome-icon :icon="['fas', 'check-circle']" />
                <span class="pl-2">Cerrar</span>
              </Button>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pl-3">
            <b-table-simple small borderless>
              <b-tbody v-if="quote">
                <b-tr>
                  <b-td>Estatus:</b-td>
                  <b-td>
                    <span v-if="quote.is_open">Abierto</span>
                    <span v-else class="text-success">Vendido</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="quote.is_open">
                  <b-td style="width: 130px">Resultado:</b-td>
                  <b-td>
                    <div>
                      <span>{{ quote.status | titleize }}</span>
                      <span v-if="quote.status === 'ignorar' || quote.status === 'baja' || quote.status === 'media' || quote.status === 'alta'">
                        probabilidad
                      </span>
                    </div>
                  </b-td>
                </b-tr>
                <!-- <b-tr>
                  <b-td style="width: 130px">Código del cliente:</b-td>
                  <b-td v-if="client">{{ client.client_num }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Asesor del cliente:</b-td>
                  <b-td v-if="client && client.user">
                    {{ client.user | concatName2 }}
                  </b-td>
                </b-tr> -->
                <b-tr>
                  <b-td>Lista de precio:</b-td>
                  <b-td>
                    <span>{{ quote.pricing_mode | titleize }}</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Valida hasta:</b-td>
                  <b-td>
                    <span>{{ quote.valid_until | formatDate }}</span>
                  </b-td>
                </b-tr>
                <b-tr class="border-top">
                  <b-td>Fecha cierre:</b-td>
                  <b-td>
                    <span v-if="quote.closed_on">
                      {{ quote.closed_on | formatDate }}
                    </span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="!quote.is_open">
                  <b-td>Comentario de cierre:</b-td>
                  <b-td>{{ quote.closing_notes }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
          <!-- <b-row align-v="center" style="border-top: 3px solid #f2f2f2; height: 50px">
            <b-col>
              <span class="font-weight-bold fs-15">SAP</span>
            </b-col>
          </b-row>
          <b-row align-h="center" class="mb-1 pl-3">
            <b-table-simple small borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="width: 130px">Sincronización:</b-td>
                  <b-td>
                    <div v-if="quote">
                      <div v-if="sapStatus == 'full'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
                        <span class="text-muted pl-1">Full</span>
                      </div>
                      <div v-else-if="sapStatus == 'syncing'">
                        <b-spinner variant="secondary" small />
                        <span class="text-muted pl-1">Sincronizando...</span>
                      </div>
                      <div v-else-if="sapStatus == 'pending'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
                        <span class="text-muted pl-1">Pendiente</span>
                      </div>
                      <div v-else-if="sapStatus == 'partial'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-warning" />
                        <span class="text-muted pl-1">Parcial</span>
                      </div>
                      <div v-else-if="sapStatus == 'ignored'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-secondary" />
                        <span class="text-muted pl-1">Apagado</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Documento:</b-td>
                  <b-td v-if="quote">
                    <span v-if="quote.sap_doc_num">{{ quote.sap_doc_num }}</span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="quote && (baseValidationError || fixableValidationError|| quote.sap_error)">
                  <b-td class="align-top">Aviso:</b-td>
                  <b-td class="text-danger">
                    <p v-if="baseValidationError">{{ baseValidationError }}</p>
                    <p v-if="fixableValidationError">{{ fixableValidationError }}</p>
                    <p v-if="quote.sap_error">{{ quote.sap_error }}</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Popovers -->
    <b-popover target="custom-total-info" placement="bottom" triggers="hover click">
      <span class="text-info">Haga clic en el número total para ingresar manualmente un total diferente. El cotizador calculará el descuento correcto para alcanzar ese total</span>
    </b-popover>

    <!-- Edit Modal -->
    <b-modal v-model="editModalShow" centered @hide="resetEditModal">
      <template #modal-title>
        <h5>Editar datos compartidos</h5>
      </template>
      <b-container class="pt-2 px-3 fs-14">
        <b-row v-if="quote" align-h="center">
          <b-form-group label="Resultado" style="width: 300px">
            <b-radio-group v-model="form.status" stacked>
              <b-radio value="baja">Baja probabilidad</b-radio>
              <b-radio value="media">Media probabilidad</b-radio>
              <b-radio value="alta">Alta probabilidad</b-radio>
              <b-radio value="desestimada">Desestimada</b-radio>
              <b-radio value="perdida">Perdida</b-radio>
              <b-radio v-if="quote.is_open" value="ignorar">Ignorar</b-radio>
            </b-radio-group>
          </b-form-group>
        </b-row>
        <b-row v-if="quote && quote.is_open" align-h="center" class="mb-4">
          <b-form-group class="text-info" style="width: 300px">
            <small v-if="form.status === 'baja'">Baja probabilidad (menos de 50%)</small>
            <small v-if="form.status === 'media'">Media probabilidad (50 a 79%)</small>
            <small v-if="form.status === 'alta'">Alta probabilidad (más de 80%)</small>
            <small v-if="form.status === 'ignorar'">Ignorar esta cotización por cualquier razón. No se sincronizará con SAP</small>
          </b-form-group>
        </b-row>
        <!-- Pricing list toggle -->
        <b-row v-if="quote && quote.is_open" align-h="center" class="mb-4">
          <b-form-group label="Lista de precio" style="width: 300px">
            <b-radio-group v-if="isInstaller || isDistributor" v-model="form.pricing_mode" :options="priceListOptionsInstaller" size="sm" stacked />
            <b-radio-group v-else v-model="form.pricing_mode" :options="priceListOptions" size="sm" stacked />
          </b-form-group>
        </b-row>
        <b-row v-if="quote && quote.is_open" align-h="center" class="mb-4">
          <b-form-group label="Valida hasta" style="width: 300px">
            <flat-pickr
              v-model="form.valid_until"
              :config="flatpickrConfig"
              placeholder="Eligir fecha.."
              class="form-control form-control-sm white-flatpickr-input"
            ></flat-pickr>
          </b-form-group>
        </b-row>
        <!-- <b-row align-h="center" class="mb-4">
          <b-form-group label="Fecha cierre" style="width: 300px">
            <flat-pickr
              v-model="form.closed_on"
              :config="flatpickrConfig"
              placeholder="Eligir fecha.."
              class="form-control form-control-sm white-flatpickr-input"
            ></flat-pickr>
          </b-form-group>
        </b-row> -->
      </b-container>

      <template #modal-footer>
        <Button variant="green" class="float-right" @click="updateQuote">
          Guardar
        </Button>
      </template>
    </b-modal>

    <!-- Contact Data Modal -->
    <!-- <b-modal v-model="contactDataModalShow" centered @hide="clearContactData">
      <template #modal-title>
        <h5>Editar datos de contacto</h5>
      </template>
      <b-container v-if="client && Object.keys(availableContactsData).length > 0">
        <b-row align-h="center">
          <b-form-group label="Persona de contacto:" label-size="sm" class="mb-5" style="width: 300px">
            <b-select v-model="form.sap_contact_person_code" :options="contactPersonOptions" size="sm" @change="onContactPersonChange"></b-select>
          </b-form-group>
          <b-form-group v-if="client.email || contactEmailCondition" label-size="sm" class="mb-5" style="width: 300px">
            <template #label>
              <span>Email:</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="contactForm.email" size="sm" stacked>
              <b-radio v-if="client.email" :value="client.email.split(';')[0]">{{ client.email.split(';')[0] }}</b-radio>
              <b-radio v-if="contactEmailCondition" :value="availableContactsData[form.sap_contact_person_code].email">
                {{ availableContactsData[form.sap_contact_person_code].email }}
              </b-radio>
            </b-radio-group>
          </b-form-group>
          <b-form-group v-if="client.phone || contactPhoneCondition" label-size="sm" class="mb-5" style="width: 300px">
            <template #label>
              <span>Teléfono:</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="contactForm.phone" size="sm" stacked>
              <b-radio v-if="client.phone" :value="client.phone">{{ client.phone }}</b-radio>
              <b-radio v-if="contactPhoneCondition" :value="availableContactsData[form.sap_contact_person_code].phone">
                {{ availableContactsData[form.sap_contact_person_code].phone }}
              </b-radio>
            </b-radio-group>
          </b-form-group>
          <b-form-group v-if="client.cellular || contactCellularCondition" label="Celular:" label-size="sm" class="mb-5" style="width: 300px">
            <b-radio-group v-model="contactForm.cellular" size="sm" stacked>
              <b-radio v-if="client.cellular" :value="client.cellular">{{ client.cellular }}</b-radio>
              <b-radio v-if="contactCellularCondition" :value="availableContactsData[form.sap_contact_person_code].cellular">
                {{ availableContactsData[form.sap_contact_person_code].cellular }}
              </b-radio>
              <b-radio :value="null">En blanco</b-radio>
            </b-radio-group>
          </b-form-group>
        </b-row>
        <b-row align-h="center" class="mt-5 mb-2">
          <Button variant="green" :disabled="!contactForm.email || !contactForm.phone" @click="updateContactData">Actualizar</Button>
        </b-row>
      </b-container>
      <template #modal-footer><span></span></template>
    </b-modal> -->

    <!-- Valid until Modal -->
    <b-modal v-model="validUntilModalShow" centered>
      <template #modal-title>
        <h5>Cotización valida hasta...</h5>
      </template>
      <b-row align-h="center">
        <flat-pickr
          v-model="form.valid_until"
          :config="flatpickrInlineConfig"
          class="d-none"
          ref="datepicker"
          @on-change="validUntilModalShow = false; updateQuote()"
        ></flat-pickr>
      </b-row>
      <template #modal-footer><span></span></template>
    </b-modal>

    <!-- Closing Modal -->
    <b-modal v-model="closingModalShow" centered>
      <template #modal-title>
        <h5>Cerrar este cotización</h5>
      </template>
      <b-row align-h="center" class="mt-3 fs-14">
        <p class="text-center">Cerrar este cotización y marcarla como vendida:</p>
      </b-row>
      <b-row align-h="center" class="mt-3 fs-14">
        <p class="text-center">
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
          <span class="pl-1">Este proceso es irreversible!</span>
        </p>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" style="width: 300px">
          <template #label>
            <span>Comentarios de cierre</span><span class="asterisk">*</span>
          </template>
          <b-textarea v-model="form.closing_notes" rows="4" size="sm" autofocus></b-textarea>
        </b-form-group>
      </b-row>
      <template #modal-footer>
        <Button :disabled="!form.closing_notes" variant="red" class="float-right" @click="closeQuote">
          Cerrar
        </Button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import Button from '../shared/Button'
import ToastAlert from '../shared/ToastAlert'
import QuoteButtonBar from '../shared/QuoteButtonBar'
import DistributorNavTabs from './DistributorNavTabs'
import InstallerNavTabs from './InstallerNavTabs'
import QuoteItemSelector from '../shared/QuoteItemSelector'
import QuoteItemTable from '../production/QuoteItemTable'

export default {
  name: 'DistributorQuoter',
  components: {
    flatPickr,
    ValidationProvider,
    Button,
    ToastAlert,
    DistributorNavTabs,
    QuoteButtonBar,
    QuoteItemSelector,
    QuoteItemTable,
    InstallerNavTabs,
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      quote: null,
      client: null,
      project: null,
      quoteItemData: {},
      quoteItemList: [],
      form: {
        title: '',
        notes: '',
        pricing_mode: null,
        valid_until: null,
        delivery_time: null,
        warranty_time: null,
        discount: 0,
        status: null,
        closed_on: null,
        closing_notes: null
      },
      installerForm: {
        name: null
      },
      contactForm: {
        email: null,
        phone: null,
        cellular: null
      },
      customTotalForm: {
        discount: null,
        total: null
      },
      isTaxExempt: false,
      editModalShow: false,
      warrantyTimeOptions: [
        { value: null, text: '- Elegir uno -'},
        '1 mes',
        '2 meses',
        '3 meses',
        '6 meses',
        '12 meses',
        '18 meses',
        '24 meses'
      ],
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      ready: false,
      titleEdit: false,
      notesEdit: false,
      warrantyTimeEdit: false,
      deliveryTimeEdit: false,
      discountEdit: false,
      discountIsValid: true,
      customTotalEdit: false,
      customTotalValid: null,
      validUntilModalShow: false,
      closingModalShow: false,
      contactDataModalShow: false,
      availableContactsData: {},
      priceListOptions: [
        { text: 'Público', value: 'público' },
        { text: 'Instalador', value: 'instalador' },
        { text: 'Mayorista', value: 'mayorista' }
      ],
      priceListOptionsInstaller: [
        { text: 'Público', value: 'público' },
        { text: 'Instalador', value: 'instalador' }
      ],
      flatpickrConfig: {
         locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: 'd/m/Y',
        disableMobile: true
      },
      flatpickrInlineConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        inline: true,
        disableMobile: true,
        minDate: "today"
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      profile: state => state.distributor.profile,
      displayCRC: state => state.displayCRC
    }),
    ...mapGetters(['isDev', 'isDistributor', 'isInstaller']),
    priceList () {
      return this.quote.pricing_mode
    },
    displayCRC: {
      get () {
        return this.$store.state.displayCRC
      },
      set (val) {
        this.$store.commit('setDisplayCRC', val)
      }
    },
    exchangeRate () { return this.quote.usd_crc_rate },
    quoteItems () {
      let array = this.quoteItemList.map(id => this.quoteItemData[id])
      return array.sort((a, b) => a.position - b.position)
    },
    distLogo () {
      this.profile ? this.profile.image_url : '#'
    },
    lastSapLine () {
      let array = this.quoteItemList.map(id => this.quoteItemData[id])
      array = array.sort((a, b) => b.sap_line_num - a.sap_line_num)
      return array.length > 0 ? array[0].sap_line_num : -1
    },
    asesor () {
      return this.ready ? this.quote.user : this.$store.state.currentUser
    },
    // Dollars
    subtotal () {
      let subtotal = 0
      if (this.ready) {
        this.quoteItems.forEach(item => subtotal += this.listedPrice(item) * item.quantity)
      }
      return subtotal
    },
    discount () {
      return this.quote ? this.subtotal * (this.quote.discount / 100) : 0
    },
    tax () {
      if (!this.isTaxExempt) {
        return (this.subtotal - this.discount) * 0.13
      } else {
        return 0
      }
    },
    total () {
      return this.subtotal - this.discount + this.tax
    },
    // Colones
    subtotalCRC () {
      let subtotal = 0
      if (this.ready) {
        this.quoteItems.forEach(item => subtotal += this.listedPrice(item) * item.quantity)
      }
      return subtotal
    },
    discountCRC () {
      return this.quote ? this.subtotalCRC * (this.quote.discount / 100) : 0
    },
    taxCRC () {
      if (!this.isTaxExempt) {
        return (this.subtotalCRC - this.discountCRC) * 0.13
      } else {
        return 0
      }
    },
    totalCRC () {
      return this.subtotalCRC - this.discountCRC + this.taxCRC
    },
    //
    // contactPersonOptions () {
    //   let array = []
    //   for (let [key, value] of Object.entries(this.availableContactsData)) {
    //     array.push({ text: value.name, value: key })
    //   }
    //   return array
    // },
    // contactEmailCondition () {
    //   let clientEmail = this.client.email ? this.client.email.trim() : null
    //   if (this.form.sap_contact_person_code &&
    //   this.availableContactsData[this.form.sap_contact_person_code] &&
    //   this.availableContactsData[this.form.sap_contact_person_code].email &&
    //   this.availableContactsData[this.form.sap_contact_person_code].email.length >= 5 &&
    //   this.availableContactsData[this.form.sap_contact_person_code].email.trim() != clientEmail) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // contactPhoneCondition () {
    //   let clientPhone = this.client.phone ? this.client.phone.trim() : null
    //   if (this.form.sap_contact_person_code &&
    //   this.availableContactsData[this.form.sap_contact_person_code] &&
    //   this.availableContactsData[this.form.sap_contact_person_code].phone &&
    //   this.availableContactsData[this.form.sap_contact_person_code].phone.length >= 8 &&
    //   this.availableContactsData[this.form.sap_contact_person_code].phone.trim() != clientPhone) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // contactCellularCondition () {
    //   let clientCellular = this.client.cellular ? this.client.cellular.trim() : null
    //   if (this.form.sap_contact_person_code &&
    //   this.availableContactsData[this.form.sap_contact_person_code] &&
    //   this.availableContactsData[this.form.sap_contact_person_code].cellular &&
    //   this.availableContactsData[this.form.sap_contact_person_code].cellular.length >= 8 &&
    //   this.availableContactsData[this.form.sap_contact_person_code].cellular.trim() != clientCellular) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    maxSalesDiscount () {
      // return this.asesor.max_sales_discount ? this.asesor.max_sales_discount : 99
      return 15
    }
  },
  watch: {
    '$route' () {
      this.id = Number(this.$route.params.id)
      this.fetchQuoteData()
    },
    total (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$http.patch(`/api/contractors/quotes/${this.id}/save_total`, {
          quote: { total: this.displayCRC ? newVal / this.exchangeRate : newVal }
        })
      }
    },
    isTaxExempt (newVal, oldVal) {  // TEST: go over this
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/contractors/quotes/${this.id}`, {
        quote: {
          is_tax_exempt: newVal
        }
      })
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.quote.is_tax_exempt = res.data.is_tax_exempt
            this.saveTotal()
          } else {
            this.isTaxExempt = !this.isTaxExempt
          }
        })
        .catch(e => {
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.isTaxExempt = !this.isTaxExempt
        })
        .finally(this.processing = false)
    }
  },
  mounted () {
    this.fetchQuoteData()
  },
  methods: {
    fetchQuoteData () {
      this.ready = false
      this.$http.get(`/api/contractors/quotes/${this.id}`)
        .then((res) => {
          console.log('fetching Quote Data:')
          console.log(res)
          // load quote item data first because its reference gets deleted in loadQuote
          this.loadItemData(res.data.quote_items)
          if (res.data.distributor_client) {
            // console.log('distributor client present')
            this.client = res.data.distributor_client
          }
          this.quote = res.data

          // fetch distributor profile
          this.$store.dispatch('fetchProfile', res.data.user_id)
          if (this.quote.is_tax_exempt == true) { this.isTaxExempt = true }
          // seed form!
          this.form.title = res.data.title
          this.form.notes = res.data.notes
          this.form.pricing_mode = res.data.pricing_mode
          this.form.warranty_time = res.data.warranty_time
          this.form.delivery_time = res.data.delivery_time
          this.form.status = res.data.status  // compare to old dist. statuses
          this.form.valid_until = res.data.valid_until
          this.form.discount = res.data.discount
          this.form.closed_on = res.data.closed_on
          this.form.closing_notes = res.data.closing_notes
          ////

          // this.$emit('quote-loaded', response.data)
          // delete this.quote['quote_items']
          // delete this.quote['distributor_client']
          this.ready = true
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No se encontró esa cotización!'
          this.alertShow = true
        })
    },
    loadItemData (quoteItems) {
      const quoteItemData = normalize(
        { items: quoteItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (quoteItemData.entities.hasOwnProperty('items')) { // in case of empty data
        this.quoteItemData = quoteItemData.entities.items
      } else {
        this.quoteItemData = {}
      }
      this.quoteItemList = quoteItemData.result.items
    },
    updateQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/contractors/quotes/${this.id}`, {
          quote: this.form
        })
        .then((res) => {
          console.log(res)
          this.quote.title = res.data.title
          this.quote.notes = res.data.notes
          this.quote.pricing_mode = res.data.pricing_mode
          this.quote.warranty_time = res.data.warranty_time
          this.quote.delivery_time = res.data.delivery_time
          this.quote.status = res.data.status
          this.quote.valid_until = res.data.valid_until
          this.quote.closed_on = res.data.closed_on
          //
          this.titleEdit = false
          this.notesEdit = false
          this.warrantyTimeEdit = false
          this.deliveryTimeEdit = false
          // this.customTotalEdit = false
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
          this.editModalShow = false
        })
    },
    closeQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/contractors/quotes/${this.id}`, {
          quote: {
            is_open: false,
            status: 'vendido',
            closed_on: new Date(),
            closing_notes: this.form.closing_notes
          }
        })
        .then((res) => {
          console.log(res)
          this.quote.status = 'vendido'
          this.quote.is_open = res.data.is_open
          this.quote.closed_on = res.data.closed_on
          this.quote.closing_notes = res.data.closing_notes
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
        })
        .finally(() => {
          this.processing = false
          this.closingModalShow = false
        })
    },
    saveTotal () {
      this.$http.patch(`/api/contractors/quotes/${this.id}/save_total`, {
        quote: {
          total: this.displayCRC ? this.total / this.exchangeRate : this.total
        }
      })
    },
    productPrice(price, price_currency) {
      if (!price || !price_currency) { return 0 }
      if (this.displayCRC) {
        return price_currency === 'COL' ? price : price * this.exchangeRate
      } else {
        return price_currency === 'COL' ? price / this.exchangeRate : price
      }
    },
    listedPrice (quoteItem) {
      if (quoteItem.line_type === 'text') { return 0 }
      if (quoteItem.is_custom) {
        let priceColones = quoteItem.custom_colones
        if (this.displayCRC && quoteItem.custom_colones == 0 && quoteItem.custom_dollars > 0) {
          priceColones = quoteItem.custom_dollars * this.exchangeRate
        }
        return this.displayCRC ? priceColones : quoteItem.custom_dollars
      } else {
        if (this.priceList === 'público') {
          return this.productPrice(quoteItem.retail_price, quoteItem.price_currency)
        } else if (this.priceList === 'instalador') {
          return this.productPrice(quoteItem.installer_price, quoteItem.installer_price_currency)
        } else if (this.priceList === 'mayorista') {
          return this.productPrice(quoteItem.wholesale_price, quoteItem.wholesale_price_currency)
        }
      }
    },
    resetEditModal () {
      this.form.status = this.quote.status
      this.form.valid_until = this.quote.valid_until
      this.form.closed_on = this.quote.closed_on
      this.form.closing_notes = this.quote.closing_notes
    },
    focus (formInput) {
      if (formInput) {
        this.$nextTick(() => {
          this.$refs[formInput].focus()
        })
      }
    },
    openDatepicker () {
      if (this.quote.is_open) {
        this.validUntilModalShow = true
        this.$nextTick(() => this.$refs.datepicker.fp.open())
      }
    },
    // Events
    copyQuote (quote) {
      this.alertVariant = 'success'
      this.alertMessage = `Copia ${quote.quote_id} se ha creado`
      this.alertShow = true
    },
    emailQuote () {
      this.alertVariant = 'success'
      this.alertMessage = 'Cotización se ha enviado'
      this.alertShow = true
    },
    transferQuote (transferee) {
      this.quote.user = transferee
      this.quote.user_id = transferee.id
      this.alertVariant = 'success'
      this.alertMessage = `Cotización se ha transferido a ${transferee.first_name + ' ' + transferee.last_name}`
      this.alertShow = true
    },
    showAlertError () {
      this.alertVariant = 'danger'
      this.alertMessage = 'No funcionó'
      this.alertShow = true
    },
    addQuoteItem (item) {
      this.quoteItemList.push(item.id)
      this.$set(this.quoteItemData, item.id, item)
    },
    updateItem (item) {
      this.$set(this.quoteItemData, item.id, item)
    },
    //// Discount field
    focusDiscount () {
      if (this.quote.is_open) {
        this.discountEdit = true
        this.$nextTick(() => {
          this.$refs.discountInput.focus()
          this.$refs.discountInput.select()
        })
      }
    },
    updateDiscount () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/contractors/quotes/${this.id}`, {
          quote: {
            discount: this.form.discount
          }
        })
        .then(response => {
          console.log(response)
          this.quote.discount = response.data.discount
          this.saveTotal()
          this.discountEdit = false
        })
        .catch(e => {
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(this.processing = false)
    },
    //// Custom total field
    focusTotal () {
      if (this.quote.is_open) {
        this.customTotalEdit = true
        this.customTotalForm.total = this.total.toFixed(2)
        this.$nextTick(() => {
          this.$refs.customTotal.focus()
          this.$refs.customTotal.select()
        })
      }
    },
    inputTotal () {
      let taxMultiplier = this.isTaxExempt ? 1.0 : 1.13
      if (this.customTotalForm.total && this.customTotalForm.total >= 0) {
        this.customTotalValid = null
        if (this.customTotalForm.total > this.subtotal * taxMultiplier) {
          // TODO: validation message?
          this.customTotalValid = false
        } else {
          let discountDollars = this.subtotal - (this.customTotalForm.total / taxMultiplier)
          this.customTotalForm.discount = (discountDollars * 100) / this.subtotal
          if (this.customTotalForm.discount > this.maxSalesDiscount) this.customTotalValid = false
        }
      } else  {
        this.customTotalValid = false
      }
    },
    updateTotal () {
      this.customTotalEdit = false
      if (this.customTotalForm.total && this.customTotalForm.total >= 0 && this.customTotalValid !== false && this.customTotalForm.discount) {
        if (this.processing) { return }
        this.processing = true
        this.$http.patch(`/api/contractors/quotes/${this.id}`, {
            quote: {
              discount: this.customTotalForm.discount.toFixed(8)
            }
          })
          .then((res) => {
            console.log(res)
            this.form.discount = res.data.discount
            this.quote.discount = res.data.discount
            this.saveTotal()
          })
          .catch(e => {
            this.alertVariant = 'danger'
            this.alertMessage = 'No funcionó'
            this.alertShow = true
           })
          .finally(this.processing = false)
      }
    },
    // openContactDataModal () {
    //   if (this.processing) { return }
    //   this.processing = true
    //   this.$http.get(`/api/production/clients/${this.client.id}/client_contacts`)
    //     .then(response => {
    //       console.log(response)
    //       const data = normalize(
    //         { contacts: response.data },
    //         { contacts: [ new schema.Entity('contacts', {}, { idAttribute: 'sap_internal_code' }) ] }
    //       )
    //       if (data.entities.hasOwnProperty('contacts')) this.availableContactsData = data.entities.contacts
    //       this.contactForm.email = this.quote.contact_data['email']
    //       this.contactForm.phone = this.quote.contact_data['phone']
    //       this.contactForm.cellular = this.quote.contact_data['cellular']
    //       this.contactDataModalShow = true
    //     })
    //     .catch(e => console.log(e))
    //     .finally(this.processing = false)
    // },
    // clearContactData () {
    //   this.availableContactsData = {}
    //   this.contactForm.email = null
    //   this.contactForm.phone = null
    //   this.contactForm.cellular = null
    // },
    // updateContactData () {
    //   if (this.processing) { return }
    //   this.processing = true
    //   this.$http.patch(`/api/production/quotes/${this.id}`, {
    //       sync: this.form.sap_contact_person_code != this.quote.sap_contact_person_code && this.isValid ? true : false,
    //       quote: {
    //         contact_data: {
    //           name: this.availableContactsData[this.form.sap_contact_person_code].name,
    //           email: this.contactForm.email,
    //           phone: this.contactForm.phone,
    //           cellular: this.contactForm.cellular
    //         }
    //       }
    //     })
    //     .then(response => {
    //       console.log(response)
    //       this.quote.contact_data = response.data.contact_data
    //       this.contactDataModalShow = false
    //     })
    //     .catch(e => console.log(e))
    //     .finally(this.processing = false)
    // },
    // Distributor quoter only
    openClientModal () {
      this.loadingClients = true
      this.clientModalShow = true
      this.$http.get('/api/contractors/distributor_clients')
        .then((res) => {
          console.log(res.data)
          this.clientSearchResults = res.data.distributor_clients
        })
        .catch(e => {
          this.alertVariant = 'danger'
          this.alertMessage = 'Algo no funcionó'
          this.alertShow = true
        })
        .finally(this.loadingClients = false)
    },
    searchClients () {
      this.clientSearchMode = true
      this.$http.get('/api/contractors/distributor_clients/search', {
          params: { q: this.clientSearchTerm }
        })
        .then((res) => {
          console.log(res)
          this.clientSearchResults = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetClientSearch () {
      this.clientSearchTerm = ''
      this.clientSearchMode = false
      this.$http.get('/api/contractors/distributor_clients')
        .then((res) => {
          console.log(res.data)
          this.clientSearchResults = res.data.distributor_clients
        })
        .catch(e => {
          this.alertVariant = 'danger'
          this.alertMessage = 'Algo no funcionó'
          this.alertShow = true
        })
        .finally(this.loadingClients = false)
    },
  }
}
</script>

<style scoped>
.brand-logo {
  width: 200px;
  height: 70px;
}
#quote-guarantee-section p {
  margin-bottom: .25em;
}
#quote-total-box {
  padding-right: 40px;
}
#quote-total-box .second-col {
  width: 120px;
}
#quote-total-box .third-col {
  width: 150px;
}
#quote-total-box p, #quote-total-box span {
  font-size: 18px;
}
#quote-sigbox {
  border: 2px solid #aaa;
}
#quote-sigbox .asesor-box {
  border-right: 1px solid #6c757d;
  padding-bottom: 0;
}
#quote-sigbox .valid-until-box {
  border-left: 1px solid #6c757d;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .brand-logo {
    width: 120px;
    height: 40px;
  }
  #quote-total-box {
    padding-right: 5px;
  }
  #quote-total-box .second-col {
    width: 100px;
  }
  #quote-total-box .third-col {
    width: 125px;
  }
  #quote-total-box p, #quote-total-box span {
    font-size: 16px;
  }
  #quote-sigbox .asesor-box {
    border-right: 0;
    border-bottom: 1px solid #6c757d;
    padding-bottom: 1em;
  }
  #quote-sigbox .delivery-box {
    padding: 1em 0;
  }
  #quote-sigbox .valid-until-box {
    border-left: 0;
    border-top: 1px solid #6c757d;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
#quote-sigbox-accept {
  padding: 0.5em 0;
  background-color: #ddd;
}
.label-size-sm {
  font-size: 11px;
}
.label-size-md {
  font-size: 12px;
}
.label-size-lg {
  font-size: 14px;
}
.fill-quote-button {
  height: 2em;
  display: inline-flex;
  align-items: center;
}
.fill-quote-button span {
  font-size: 12px;
}
#right-sidebar tr {
  height: 35px;
}
#right-sidebar tr td {
  vertical-align: middle;
}
</style>
