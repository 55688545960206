<template>
  <div v-if="isAdmin">
    <b-row align-h="between" class="custom-tabs mx-0">
      <b-link class="ml-3" @click="$router.go(-1)">
        <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
        <span class="pl-2">Atrás</span>
      </b-link>
      <ul>
        <!-- Nav Tabs ? -->
      </ul>
    </b-row>
    <ContentView class="pb-5">
      <Subheader>
        Gestión de Almacenes
      </Subheader>
      <b-row>
        <b-table
          :fields="fields"
          :items="warehouses"
          :tbody-tr-class="rowClass"
          bordered
          responsive
          show-empty
          caption-top
          style="font-size: 13px"
        >
          <!-- Table caption -->
          <template #table-caption>
            <div v-if="count" class="text-info pl-2">
              <span>{{ count.toLocaleString() }}</span>
              <span>almacenes en total</span>
            </div>
          </template>

          <!-- Loading spinner / empty search text -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span>No hay almacenes</span>
            </div>
          </template>

          <!-- Table Data -->
          <template v-slot:cell(sap_warehouse_code)="data">
            <span class="font-weight-bold">{{ data.value }}</span>
          </template>

          <template v-slot:cell(is_active)="data">
            <span v-if="data.value" :class="{ 'text-success': data.item.is_displayed }">Activo</span>
            <span v-else>Inactivo</span>
          </template>

          <template v-slot:cell(is_displayed)="data">
            <Button v-if="data.item.is_active" @click="toggleWarehouseDisplay(data.item)">
              <font-awesome-icon v-if="data.value" :icon="['far', 'eye']" fixed-width />
              <font-awesome-icon v-else :icon="['far', 'eye-slash']" fixed-width />
            </Button>
          </template>
        </b-table>
      </b-row>
      <Observer v-if="!loading" @intersect="intersected" />
      <SpinnerHorizontal v-if="addingPage" />
    </ContentView>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { normalize, schema } from 'normalizr';
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import Observer from '../shared/Observer';
import SpinnerHorizontal from '../shared/SpinnerHorizontal';
import Subheader from '../shared/Subheader';

export default {
  name: 'WarehouseIndex',
  components: { Button, ContentView, Observer, SpinnerHorizontal, Subheader },
  data() {
    return {
      warehouseData: {},
      warehouseList: [],
      fields: [
        { key: 'sap_warehouse_code', label: 'Código', thClass: 'text-center', tdClass: 'align-middle text-center', thStyle: { maxWidth: '60px' }},
        { key: 'name', label: 'Nombre', tdClass: 'align-middle' },
        { key: 'is_active', label: 'Status', thClass: 'text-center', tdClass: 'align-middle text-center' },
        { key: 'is_displayed', label: 'Mostrar en interface?', thClass: 'text-center', tdClass: 'text-center' }
      ],
      page: 1,
      count: null,
      last: null,
      loading: false,
      processing: false,
      addingPage: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    warehouses() { return this.warehouseList.map(id => this.warehouseData[id]) },
  },
  mounted() {
    this.loading = true;
    this.fetchWarehouses();
  },
  methods: {
    fetchWarehouses() {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('/api/production/warehouses', {
          params: { page: this.page }
        })
        .then(response => {
          console.log(response)
          const warehouseData = normalize(
            { warehouses: response.data.warehouses },
            { warehouses: [ new schema.Entity('warehouses') ] }
          );

          if (this.addingPage) {
            // add to existing data
            if (warehouseData.entities.hasOwnProperty('warehouses')) {
              this.warehouseData = Object.assign({}, this.warehouseData, warehouseData.entities.warehouses);
            }
            this.warehouseList = [...this.warehouseList, ...warehouseData.result.warehouses];
          } else {
            // seed fresh data
            if (warehouseData.entities.hasOwnProperty('warehouses')) {
              this.warehouseData = warehouseData.entities.warehouses;
            }
            this.warehouseList = warehouseData.result.warehouses;
          }
          this.count = response.data.pagy.count;
          this.last = response.data.pagy.last;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.processing = false;
          this.addingPage = false;
        });
    },
    toggleWarehouseDisplay(item) {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/warehouses/${item.id}`, {
          warehouse: { is_displayed: !item.is_displayed }
        })
        .then(response => {
          console.log(response)
          this.$set(this.warehouseData, response.data.id, response.data);
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.processing = false);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (!item.is_active || item.is_displayed == false) return 'table-row-muted'
    },
    intersected() {
      console.log('Intersected')
      if (this.page + 1 <= this.last) {
        console.log('Adding next page')
        this.page++;
        this.addingPage = true;
        this.fetchWarehouses();
      }
    }
  }
}
</script>
