<template>
  <div @click="onBackgroundClick">
    <b-container fluid class="top-shelf shadow-sm">
      <b-row align-h="center" class="my-3">
        <b-col lg="6" sm="8">
          <TitleBadge v-if="ready" type="Project" :title="project.name">
            <template #id>{{ project | projectId }}</template>
          </TitleBadge>
        </b-col>
        <b-col lg="2" sm="3" class="text-right">
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row align-h="center" class="pt-3">
        <b-col lg="8" sm="9" cols="11">
          <b-row>
            <b-col lg="4" cols="12" class="text-truncate mb-3 mb-lg-0">
              <p class="font-lato text-muted mb-1 fs-15">
                Cliente
              </p>
              <b-link v-if="ready && project.client" :to="`/app/prod/clientes/${project.client.id}`" class="related-link">
                <span class="fs-14">{{ project.client.name }}</span>
              </b-link>
            </b-col>
            <b-col lg="2" offset-lg="4" cols="6">
              <p class="font-lato text-muted mb-1 fs-15">
                Asesor
              </p>
              <p v-if="ready && project.user" class="mb-0 fs-14">
                {{ project.user.first_name }} {{ project.user.last_name }}
              </p>
            </b-col>
            <b-col lg="2" cols="6" class="text-right">
              <p class="font-lato mb-1 fs-15" style="color: #6c757d">
                Status
              </p>
              <p v-if="ready" class="text-warning fs-14">
                {{ project.status.toUpperCase() }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <hr>

    <b-container fluid>
      <b-row align-h="center" class="mx-0 mb-2">
        <b-col xl="10" lg="8">
          <b-row align-h="end" class="mx-0 pr-2 mb-3 mb-sm-0">
            <b-button-group v-if="ready">
              <Button v-if="isSupervisor || currentUser.id === project.user_id" size="sm" @click="openProjectEditModal">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-1">Editar</span>
              </Button>
              <Button v-if="isSupervisor" size="sm" @click="transferModalShow = true">
                <font-awesome-icon :icon="['far', 'share']" fixed-width />
                <span class="pl-1">Transferir</span>
              </Button>
            </b-button-group>
          </b-row>
          <b-row class="fs-13">
            <b-col lg="5" offset-xl="1">
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr v-if="ready">
                    <td class="text-muted" style="width: 150px">
                      <font-awesome-icon  v-if="project.project_type === 'Comercial'" :icon="['fas', 'industry-alt']" fixed-width />
                      <font-awesome-icon v-else-if="project.project_type == 'Residencial'" :icon="['fas', 'home']" fixed-width />
                      <font-awesome-icon v-else :icon="['fas', 'parking']" fixed-width />
                      <span>Tipo:</span>
                    </td>
                    <td v-if="ready">{{ project.project_type }}</td>
                  </tr>
                  <tr>
                    <td class="text-muted">
                      <font-awesome-icon :icon="['fas', 'map-marker-alt']" fixed-width />
                      <span>Dirección:</span>
                    </td>
                    <td v-if="ready">{{ project.address }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="text-muted">
                      <font-awesome-icon :icon="['fas', 'user-tie']" fixed-width />
                      <span>Encargado:</span>
                    </td>
                    <td v-if="ready">{{ project.manager }}</td>
                  </tr> -->
                </tbody>
              </table>
            </b-col>
            <!-- <b-col lg="3">
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <td class="text-muted">
                      <font-awesome-icon :icon="['far', 'calendar-edit']" fixed-width />
                      <span>Fecha cierre:</span>
                    </td>
                    <td v-if="ready">{{ project.closing_date }}</td>
                  </tr>
                  <tr v-if="ready">
                    <td class="text-muted">
                      <font-awesome-icon :icon="['far', 'calendar-edit']" fixed-width />
                      <span>Fecha facturación:</span>
                    </td>
                    <td v-if="ready">{{ project.billing_date }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col> -->
          </b-row>

          <!-- Tabs for Quotes, Accesses, CRM (mobile) -->
          <b-row class="mr-0 mr-sm-2 mt-5">
            <b-card no-body style="width: 100%; min-height: 150px">
              <b-tabs card v-model="tabIndex" active-nav-item-class="font-weight-bold">
                <!-- QUOTES TAB -->
                <b-tab title-link-class="font-lato-tab">
                  <template #title>
                    Cotizaciones ({{ quoteList.length }})
                  </template>
                  <ProjectShowQuoteList
                    v-if="ready"
                    :project="project"
                    :quoteData="quoteData"
                    :quoteList="quoteList"
                    @quote-created="createQuote"
                    @quote-updated="updateQuote"
                  />
                  <div v-else class="text-center text-info mt-3">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="pl-2 fs-14">Cargando datos...</span>
                  </div>
                </b-tab>
                <!-- ACCESS TAB -->
                <b-tab title-link-class="font-lato-tab">
                  <template #title>
                    Accesos ({{ accesses.length }})
                  </template>
                  <ProjectShowAccessList
                    v-if="ready"
                    ref="accessList"
                    :project="project"
                    :accessData="accessData"
                    :accessList="accessList"
                    :designs="accessDesigns"
                    :motors="accessMotors"
                    @access-created="createAccess"
                    @access-updated="updateAccess"
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- Edit Project Modal -->
    <b-modal v-model="editProjectModalShow" title="Editar proyecto" centered hide-footer>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">
          <ValidationProvider rules="required" name="Nombre" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]" style="margin-bottom: 2em">
              <template #label>
                <span>Nombre</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="projectForm.name" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Tipo de ciclos de proyecto" label-size="sm" style="margin-bottom: 2em">
            <b-radio-group v-model="projectForm.project_type" size="sm" stacked>
              <b-radio value="Comercial">Comercial o Industrial</b-radio>
              <b-radio value="Residencial">Residencial o Condominio</b-radio>
              <b-radio value="Parqueo">Parqueo</b-radio>
            </b-radio-group>
          </b-form-group>

          <ValidationProvider rules="required" name="Dirección" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]" style="margin-bottom: 2em">
              <template #label>
                <span>Dirección</span><span class="asterisk">*</span>
              </template>
              <b-textarea v-model="projectForm.address" size="sm" rows="3" :state="errors[0] ? false : (valid ? true : null)" />
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Status" label-size="sm">
            <b-radio-group v-model="projectForm.status" :options="statusOptions" size="sm" stacked></b-radio-group>
          </b-form-group>
          <div class="text-info" style="margin-bottom: 2em">
            <small v-if="projectForm.status === 'baja'">Baja probabilidad (menos de 50%)</small>
            <small v-if="projectForm.status === 'media'">Media probabilidad (50 a 79%)</small>
            <small v-if="projectForm.status === 'alta'">Alta probabilidad (más de 80%)</small>
            <small v-if="projectForm.status === 'vendido'">Quedo abierta pero no se jalaron todas las líneas - CERRAR</small>
            <small v-if="projectForm.status === 'desestimado'">El cliente no va a hacer nada (ni con nosotros, ni con nadie) - CERRAR</small>
            <small v-if="projectForm.status === 'perdido'">El trabajo lo hizo otra empresa o persona.  Puede ser por precio, tiempo de respuesta de la gestión, especificaciones, plazo de entrega del producto, falta de tiempo del asesor, falla en la atención/servicio - CERRAR</small>
          </div>

          <div class="text-center mt-5 mb-2">
            <Button variant="green" :disabled="invalid" @click="updateProject">Guardar</Button>
          </div>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <!-- Transfer Project Modal -->
    <b-modal v-model="transferModalShow" title="Transferir este proyecto..." centered @shown="loadSalespeople">
      <b-row align-h="center">
        <b-select v-model="transfereeId" style="width: 80%">
          <template #first>
            <option :value="null" disabled>- Eligir otro asesor -</option>
          </template>
          <option v-for="employee in eligibleTransferees" :value="employee.id" :key="employee.id">{{ employee | concatName4 }}</option>
        </b-select>
      </b-row>
      <template #modal-footer>
        <Button variant="green" :disabled="!transfereeId" @click="transfer">
          Transferir
        </Button>
      </template>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Button from '../shared/Button'
import TitleBadge from '../shared/TitleBadge'
import ToastAlert from '../shared/ToastAlert'
import ProjectShowAccessList from './ProjectShowAccessList'
import ProjectShowQuoteList from './ProjectShowQuoteList'

export default {
  name: 'ProjectShow',
  components: {
    ValidationObserver,
    ValidationProvider,
    Button,
    TitleBadge,
    ToastAlert,
    ProjectShowAccessList,
    ProjectShowQuoteList
  },
  data () {
    return {
      id: Number(this.$route.params.id),
      project: null,
      quoteData: {},
      quoteList: [],
      accessData: {},
      accessList: [],
      accessDesigns: [],
      accessMotors: [],
      editProjectModalShow: false,
      projectForm: {
        name: null,
        project_type: null,
        address: null,
        status: null
      },
      transferModalShow: false,
      transfereeId: null,
      tabIndex: 0,  // change according to project stage
      statusOptions: [
        { text: 'Baja probabilidad', value: 'baja' },
        { text: 'Media probabilidad', value: 'media' },
        { text: 'Alta probabilidad', value: 'alta' },
        { text: 'VENDIDO', value: 'vendido' },
        { text: 'DESESTIMADO', value: 'desestimado' },
        { text: 'PERDIDO', value: 'perdido' },
        { text: 'Licitación Construcción', value: 'licitación' }
      ],
      ready: false,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  mounted () {
    this.fetchProjectData()
  },
  computed: {
    ...mapGetters (['isSupervisor', 'salespeople', 'salesSupervisors', 'employeeDataLoaded']),
    currentUser () { return this.$store.state.currentUser },
    eligibleTransferees () {
      if (this.project) { return this.salespeople.concat(this.salesSupervisors).filter(s => s.id !== this.project.user_id) }
    },
    accesses () { // for Tab counter
      return this.accessList.map(id => this.accessData[id])
    }
  },
  methods: {
    fetchProjectData () {
      this.ready = false
      this.$http.get(`/api/production/projects/${this.id}`)
        .then((res) => {
          console.log(res.data)
          this.project = res.data.project

          const quoteData = normalize(
            { quotes: res.data.quotes },
            { quotes: [ new schema.Entity('quotes') ] }
          )
          if (quoteData.entities.hasOwnProperty('quotes')) {
            this.quoteData = quoteData.entities.quotes
          }
          this.quoteList = quoteData.result.quotes

          const accessData = normalize(
            { accesses: res.data.accesses },
            { accesses: [ new schema.Entity('accesses') ] }
          )
          if (accessData.entities.hasOwnProperty('accesses')) {
            this.accessData = accessData.entities.accesses
          }
          this.accessList = accessData.result.accesses

          if (this.accessList.length > 0) {
            this.tabIndex = 1
          }
          this.ready = true
        })
        .catch(e => console.log(e))
        .finally(() => this.fetchDesignsMotors())
    },
    fetchDesignsMotors () {
      this.$http.get(`/api/production/projects/fetch_designs_motors`)
        .then((res) => {
          console.log(res.data)
          this.accessDesigns = res.data.designs
          this.accessMotors = res.data.motors
        })
    },
    openProjectEditModal () {
      this.projectForm.name = this.project.name
      this.projectForm.project_type = this.project.project_type
      this.projectForm.address = this.project.address
      this.projectForm.status = this.project.status
      this.editProjectModalShow = true
    },
    updateProject () {
      if (this.processing) { return }
      this.processing = true
      this.editProjectModalShow = false
      this.$http.put(`/api/production/projects/${this.id}`, {
          project: this.projectForm
        })
        .then((res) => {
          console.log(res)
          this.project = res.data
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios se guardaron'
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger'
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo guardar los cambios'
          }
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
        })
    },
    transfer () {
      if (this.processing) { return }
      this.processing = true
      this.$http.put(`/api/production/projects/${this.id}/transfer`, {
          project: { user_id: this.transfereeId }
        })
        .then(response => {
          console.log(response)
          let user = response.data
          this.project.user = user
          this.project.user_id = user.id
          this.transfereeId = null
          this.alertVariant = 'success'
          this.alertMessage = `Proyecto se ha transferido a ${user.first_name + ' ' + user.last_name}`
        })
        .catch(e => {
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(() => {
          this.processing = false
          this.transferModalShow = false
          this.alertShow = true
        })
    },
    loadSalespeople () {
      if (!this.employeeDataLoaded) {
        this.$store.dispatch('fetchEmployeeData')
      }
    },
    createQuote (quote) {
      this.quoteList.unshift(quote.id)
      this.$set(this.quoteData, quote.id, quote)
    },
    updateQuote (quote) {
      this.$set(this.quoteData, quote.id, quote)
    },
    createAccess (access) {
      this.accessList.unshift(access.id)
      this.$set(this.accessData, access.id, access)
    },
    updateAccess (access) {
      this.$set(this.accessData, access.id, access)
    },
    onBackgroundClick (e) {
      // console.log(e.target)
      // console.log(e.target.closest('tr'))
      if (!(e.target.tagName === 'A' || (e.target.closest('tr') && e.target.closest('tr').classList.contains('access-list-row')))) {
        this.$refs.accessList.cancelEditing()
      }
    }
  }
}
</script>

<style scoped>
.project-card-header {
  font-size: 16px;
  color: #aaa;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
}
</style>
