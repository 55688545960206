<template>
  <div v-if="isSuperAdmin">
    <ContentView>
      <Subheader title="Centro de Anuncios">
        <template #right>
          <Button size="sm" variant="green" class="mr-3" @click="modalShow = true">
            <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
            <span class="pl-2">Nuevo</span>
          </Button>
        </template>
      </Subheader>

      <b-row v-if="activeAnnouncement" align-h="center" class="mt-3">
        <b-col lg="8" sm="10" cols="11">
          <b-row align-h="between" class="lead px-3 mb-1">
            <span>Anuncio Publicado:</span>
            <div>
              <span v-if="activeAnnouncement.expiration_date && new Date(activeAnnouncement.expiration_date) < new Date" class="text-danger">Expirado</span>
              <span v-else class="text-success">Activo</span>
            </div>
          </b-row>
          <b-alert show variant="info">
            <font-awesome-icon :icon="['far', 'bell']" fixed-width />
            <span class="pl-3"><b>Anuncio:</b></span>
            <span class="pl-2">{{ activeAnnouncement.message }}</span>
          </b-alert>
          <b-row align-h="end">
            <small class="mr-3">{{ activeAnnouncement.updated_at | formatDateTime }}</small>
            <small v-if="!(activeAnnouncement.expiration_date && new Date(activeAnnouncement.expiration_date) < new Date)" class="mr-3">
              <b-link @click="unpublish(activeAnnouncement.id)">Despublicar</b-link>
            </small>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else align-h="center">
        <h5>No hay anuncios publicados</h5>
      </b-row>

      <b-row align-h="center" class="mt-4">
        <b-table
          outlined
          responsive
          show-empty
          caption-top
          thead-variant="light"
          :items="announcements"
          :fields="fields"
          class="fs-13"
        >
          <!-- Loading spinner -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando...</span>
            </div>
          </template>

          <!-- Table caption -->
          <template #table-caption>
            Anuncios anteriores:
          </template>

          <!-- Table data -->
          <template v-slot:cell(updated_at)="data">
            {{ data.value | formatDateTime }}
          </template>

          <template v-slot:cell(actions)="data">
            <b-link class="trash-link" @click="destroy(data.item.id)">
              <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
            </b-link>
          </template>
        </b-table>
      </b-row>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Modal -->
    <b-modal
      lazy
      centered
      v-model=" modalShow"
      title="Publicar nuevo anuncio"
    >
      <b-container>
        <b-row align-h="center">
          <b-form-group label="Mensaje:" label-size="sm" style="width: 350px">
            <b-textarea v-model="form.message" rows="5" size="sm" autofocus></b-textarea>
          </b-form-group>
          <b-form-group label="Expira en:" label-size="sm" style="width: 350px">
            <b-radio-group v-model="form.expiration_date" size="sm">
              <b-radio value="one_week">Una semana</b-radio>
              <b-radio :value="null">Nunca</b-radio>
            </b-radio-group>
          </b-form-group>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button class="float-right" variant="green" @click="publish">Publicar</Button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { addWeeks } from 'date-fns'
import { mapGetters } from 'vuex';
import { normalize, schema } from 'normalizr';
import Button from './shared/Button';
import ContentView from './shared/ContentView';
import Subheader from './shared/Subheader';
import ToastAlert from './shared/ToastAlert';

export default {
  name: 'AppAnnouncements',
  components: { Button, ContentView, Subheader, ToastAlert },
  data() {
    return {
      announcementData: {},
      announcementList: [],
      form: {
        message: '',
        expiration_date: 'one_week'
      },
      modalShow: false,
      fields: [
        { key: 'updated_at', label: 'Tiempo publicado' },
        { key: 'message', label: 'Mensaje' },
        { key: 'actions', label: '' }
      ],
      loading: false,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
    }
  },
  computed: {
    ...mapGetters(['isSuperAdmin']),
    announcements() {
      return this.announcementList.map(id => this.announcementData[id]);
    },
    activeAnnouncement() {
      return this.announcements.filter(a => a.active)[0]
    }
  },
  mounted() {
    this.loading = true;
    this.fetchAnnouncementData();
  },
  methods: {
    fetchAnnouncementData() {
      this.$http.get('/api/announcements')
        .then(response => {
          console.log(response)
          const data = normalize(
            { announcements: response.data },
            { announcements: [ new schema.Entity('announcements') ] }
          );
          this.announcementData = data.entities.announcements;
          this.announcementList = data.result.announcements;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.loading = false);
    },
    publish() {
      if (this.processing) { return }
      this.processing = true;
      let expiration_date;
      if (this.form.expiration_date == 'one_week') {
        expiration_date = addWeeks(new Date, 1)
      } else {
        expiration_date = null;
      }
      this.$http.post('/api/announcements', {
          announcement: {
            message: this.form.message,
            expiration_date: expiration_date
          }
        })
        .then(response => {
          console.log(response)
          this.announcements.map(a => a.active = false);
          this.announcementList.unshift(response.data.id);
          this.announcementData[response.data.id] = response.data;
          this.alertVariant = 'success';
          this.alertMessage = 'Anuncio ha sido publicado';
          this.modalShow = false;
          this.form.message = '';
          this.form.expiration_date = 'one_week';
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'Algo no funcionó';
        })
        .finally(() => {
          this.processing = false;
          this.alertShow = true;
        });
    },
    unpublish(id) {
      if (this.processing) { return }
      this.processing = true;
      this.$http.put(`/api/announcements/${id}`, {
          announcement: { active: false }
        })
        .then(response => {
          console.log(response)
          this.announcementData[response.data.id].active = false;
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'Algo no funcionó';
          this.alertShow = true;
        })
        .finally(() => this.processing = false);
    },
    // republish() {
    //   if (this.processing) { return }
    //   this.processing = true;
    //   this.$http.put()
    //     .then(response => {
    //       console.log(response)
    //       this.alertVariant = 'success';
    //       this.alertMessage = 'Anuncio ha sido publicado otra vez';
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       this.alertVariant = 'danger';
    //       this.alertMessage = 'Algo no funcionó';
    //     })
    //     .finally(() => {
    //       this.processing = false;
    //       this.alertShow = true;
    //     });
    // },
    destroy(id) {
      if (this.processing) { return }
      this.processing = true;
      this.$http.delete(`/api/announcements/${id}`)
        .then(response => {
          this.fetchAnnouncementData();
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'Algo no funcionó';
          this.alertShow = true;
        })
        .finally(() => this.processing = false);
    }
  }
}
</script>

<style scoped>
.trash-link {
  color: #6c757d;
}
.trash-link:hover {
  color: #dc3545;
}

</style>