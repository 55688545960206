<template>
  <b-row align-h="center" class="mx-0" style="margin-top: 80px">
    <b-col lg="8">
      <SectionCard class="mx-auto">
        <SectionCardTitle>
          <font-awesome-icon :icon="['fas', 'users']" size="lg" fixed-width />
          Talento Humano
        </SectionCardTitle>

        <div v-if="isHrAdmin || isDev">
          <b-row align-h="center">
            <table class="table table-bordered fs-13" style="width: 300px">
              <tr>
                <td>Vacaciones al Día</td>
                <td>Vacaciones Vencidas</td>
              </tr>
              <tr v-if="ready" style="font-size: 16px; font-weight: bold">
                <td class="text-center" style="background-color: #d6f5d6">{{ percentCurrent.toFixed(1) }}</td>
                <td class="text-center" style="background-color: #ffe6cc">{{ percentExpired.toFixed(1) }}</td>
              </tr>
            </table>
          </b-row>
          <b-row align-h="center">
            <table class="table border-left border-right border-bottom fs-13" style="width: 300px">
              <tr>
                <td><span>Total de vacaciones</span><span class="pl-1 font-weight-bold">al día</span></td>
                <td v-if="ready" class="text-right">{{ currentCount }}</td>
              </tr>
              <tr>
                <td><span>Total de vacaciones</span><span class="pl-1 font-weight-bold">vencidas</span></td>
                <td v-if="ready" class="text-right">{{ expiredCount }}</td>
              </tr>
              <tr>
                <td>Total de vacaciones</td>
                <td v-if="ready" class="text-right">{{ totalCount }}</td>
              </tr>
            </table>
          </b-row>
        </div>

        <b-card header="Aplicaciones:">
          <b-row align-h="around" class="px-2">
            <div class="tile">
              <router-link to="/app/rrhh/empleados">
                <font-awesome-icon :icon="['fas', 'user-tie']" size="lg" />
                <span>Usuarios</span>
              </router-link >
            </div>
            <div class="tile">
              <router-link  to="/app/rrhh/organigrama">
                <font-awesome-icon :icon="['fas', 'sitemap']" size="lg" />
                <span>Organigrama</span>
              </router-link >
            </div>
            <div v-if="isDev" class="tile">
              <router-link  to="/app/rrhh/boletas">
                <font-awesome-icon :icon="['far', 'ticket']" size="lg" />
                <span>Boletas</span>
              </router-link >
            </div>
            <div v-if="isDev" class="tile">
              <router-link  to="/app/rrhh/reportes">
                <font-awesome-icon :icon="['far', 'file-alt']" size="lg" />
                <span>Reportes</span>
              </router-link >
            </div>
            <div v-if="isDev" class="tile">
              <router-link  to="/app/rrhh/config">
                <font-awesome-icon :icon="['far', 'sliders-h']" size="lg" />
                <span>Config</span>
              </router-link >
            </div>
          </b-row>
        </b-card>
      </SectionCard>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SectionCard from '../shared/SectionCard.vue';
import SectionCardTitle from '../shared/SectionCardTitle.vue';

export default {
  name: 'index',
  components: { SectionCard, SectionCardTitle },
  data() {
    return {
      currentCount: null,
      expiredCount: null,
      totalCount: null,
      ready: false
    }
  },
  computed: {
    ...mapGetters(['isManager', 'isHrAdmin', 'isDev']),
    percentCurrent() {
      return this.currentCount / this.totalCount * 100
    },
    percentExpired() {
      return this.expiredCount / this.totalCount * 100
    }
  },
  mounted() {
    if (this.isHrAdmin || this.isDev) {
      this.$http.get('/api/human_resources/reports/vacation_compliance')
        .then(response => {
          console.log(response)
          this.currentCount = response.data.current;
          this.expiredCount = response.data.expired;
          this.totalCount = response.data.total;
          this.ready = true;
        })
        .catch(error => {
          console.log(error)
        });
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 2em;
}
.card-header {
  color: #333;
  font-size: 14px;
  padding: 0.25rem 1.25rem;
  background-color: #ffdec1;
}
</style>
