<template>
  <div class="w-100">
    <!-- Desktop -->
    <div class="d-none d-sm-block">
      <!-- ITEMS DOCUMENT -->
      <table v-if="purchaseRequest.doc_type === 'items_document'" :class="{ 'table-closed': !canEdit }" class="table border-left border-right border-bottom">
        <thead class="thead-light">
          <tr>
            <th class="text-center">Cant.</th>
            <th style="width: 15rem">Código</th>
            <th style="width: 25rem">Descripción</th>
            <th style="width: 13.75rem" class="text-center">UN</th>
            <th style="width: 13.75rem" class="text-center">Impuesto</th>
            <th class="text-center">Precio</th>
            <th class="text-right">Subtotal</th>
            <th v-if="canEdit"></th>
          </tr>
        </thead>
        <tbody ref="itemContainer">
          <tr v-for="item in PurchaseRequestItems" :key="item.id" :id="'item-' + item.id" :class="{'sortable-row': canEdit }" style="height: 56px">
            <!-- Quantity -->
            <td class="text-center align-middle" style="min-width: 100px; width: 100px">
              <PurchaseRequestItemQuantityInput
                v-if="canEdit"
                :purchase-request-id="purchaseRequest.id"
                :purchase-request-item="item"
                @item-updated="updateItem"
                @total-changed="$emit('total-changed')"
              />
              <span v-else>{{ item.quantity }}</span>
            </td>
            <!-- Item Code -->
            <td class="align-middle">
              <div v-if="canEdit">
                {{ item.product.item_code }}
              </div>
              <span v-else>{{ item.product.item_code }}</span>
            </td>
            <!-- Description -->
            <td class="align-middle td-truncate d-none d-sm-table-cell" style="max-width: 220px">
              <div>
                <span v-if="purchaseRequest.doc_type === 'items_document'">{{ item.product.description }}</span>
              </div>
            </td>
            <!-- UN -->
            <td class="align-middle text-center d-none d-sm-table-cell" style="max-width: 220px">
              <b-dropdown
                id="businessUnitItem"
                ref="businessUnit"
                size="sm"
                variant="white"
                dropright
                @hidden="searchedBusinessUnit = ''"
                :text="item.business_unit"
                :disabled="!canEdit"
              >
                <div>
                  <b-input
                    v-model="searchedBusinessUnit"
                    id="dropdown-form-search"
                    placeholder="Buscar por nombre"
                    class="px-1"
                    style="width: 90%; margin: 0px 10px"
                  ></b-input>
                  <b-container v-if="filteredBusinessUnits.length > 0" style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                      v-for="un in filteredBusinessUnits" :key="un.value"
                      :active="item.business_unit == un.value"
                      class="p-0 m-0"
                      @click="updateBusinessUnit(item, un.value)"
                    >
                      <small>{{un.text}}</small>
                    </b-dropdown-item-button>
                  </b-container>
                  <b-container v-else style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="text-center text-danger">
                      <small>No hay resultados</small>
                    </div>
                  </b-container>
                </div>
              </b-dropdown>
            </td>
            <!-- Impuesto -->
            <td class="text-center align-middle px-1">
              <b-dropdown
                id="taxCodeItem"
                ref="taxCode2"
                size="sm"
                variant="white"
                dropright
                @hidden="searchedTaxCode = ''"
                :text="item.tax_code"
                :disabled="!canEdit"
              >
                <div>
                  <b-input
                    v-model="searchedTaxCode"
                    id="dropdown-form-search2"
                    placeholder="Buscar por código"
                    class="px-1"
                    style="width: 90%; margin: 0px 10px"
                  ></b-input>
                  <b-container v-if="filteredTaxes.length > 0" style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                      v-for="tax in filteredTaxes" :key="tax.value"
                      @click="updateTaxCode(item, tax.value)"
                      :active="item.tax_code == tax.value"
                      class="p-0 m-0"
                    >
                      <small>{{tax.text}}</small>
                    </b-dropdown-item-button>
                  </b-container>
                  <b-container v-else style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="text-center text-danger">
                      <small>No hay resultados</small>
                    </div>
                  </b-container>
                </div>
              </b-dropdown>
            </td>
            <!-- Price -->
            <td class="text-right align-middle price-display">
              <div class="center-items">
                <div v-if="priceEdit && item.id === selectedPurchaseRequestItem.id && canEdit" class="center-items">
                  <ValidationProvider :rules="{ required: true }" immediate tag="div" v-slot="{ errors }">
                    <b-input-group size="sm" style="width: 160px">
                      <b-input-group-prepend is-text>
                        <span v-if="displayCRC">₡</span>
                        <span v-else>$</span>
                      </b-input-group-prepend>
                      <b-input
                        v-model="form.price"
                        type="number"
                        autofocus
                        @blur="errors[0] ? cancelCustomEditing() : updatePrice()"
                        placeholder="0"
                        :class="{ 'red-input-border': errors[0] }"
                      />
                    </b-input-group>
                  </ValidationProvider>
                </div>
                <div v-else class="text-center">
                  <div v-if="displayCRC">
                    <span v-if="canEdit" class="click-to-edit" @click="editItem(item, 'price')">
                      {{ item.local_price | colones }}
                    </span>
                    <span v-else>{{ item.local_price | colones }}</span>
                  </div>
                  <div v-else>
                    <span v-if="canEdit" class="click-to-edit" @click="editItem(item, 'price')">
                      {{ item.price | dollarize }}
                    </span>
                    <span v-else>{{ item.price | dollarize }}</span>
                  </div>
                </div>
              </div>
            </td>
            <!-- Subtotal -->
            <td  :class="{'text-danger': item.quantity < 0 }" class="text-right align-middle price-display">
              <span v-if="displayCRC">{{ item.quantity * item.local_price | colones }}</span>
              <span v-else>{{ item.quantity * item.price | dollarize }}</span>
            </td>
            <!-- Delete Icon -->
            <td v-if="canEdit" class="text-right align-middle" style="width: 50px">
              <span v-if="PurchaseRequestItems.length == 1"></span>
              <b-link v-else class="delete-icon" @click="deleteItem(item.id)">
                <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
              </b-link>
            </td>
          </tr>
          <!--quickAddItem -->
          <PurchaseRequestItemQuickAddRow
            v-if="canEdit && purchaseRequest.purchase_type != '05'"
            :purchase-request="purchaseRequest"
            :purchase-request-items="PurchaseRequestItems"
            @purchase-request-item-added="addPurchaseRequestItem"
          />
        </tbody>
      </table>
      <!-- SERVICE DOCUMENT -->
      <table v-else :class="{ 'table-closed': !canEdit }" class="table border-left border-right border-bottom">
        <thead class="thead-light">
          <tr>
            <th>Descripción</th>
            <th style="width: 200px" class="pl-5">Tipo de OC</th>
            <th style="width: 100px" class="text-center">UN</th>
            <th style="width: 100px" class="text-center">Impuesto</th>
            <th style="width: 100px" class="text-center">Precio</th>
            <th style="width: 100px" class="text-right">Subtotal</th>
            <th v-if="canEdit"></th>
          </tr>
        </thead>
        <tbody ref="itemContainer">
          <tr v-for="item in PurchaseRequestItems" :key="item.id" :id="'item-' + item.id" :class="{'sortable-row': canEdit }" style="height: 56px">
            <!-- Description -->
            <td class="align-middle td-truncate d-none d-sm-table-cell" style="max-width: 220px">
              <div v-if="descriptionEdit && item.id === selectedPurchaseRequestItem.id">
                <ValidationProvider :rules="{ required: true }" immediate tag="div" v-slot="{ errors }">
                  <b-input-group size="sm" style="width: auto">
                    <b-textarea
                      v-model="form.description"
                      @blur="errors[0] || form.description === 'Agregar servicio' || form.description === item.item_description ? cancelCustomEditing() : updateDescription()"
                      autofocus
                      rows="1"
                      max-rows="2"
                      :class="{ 'red-input-border': errors[0] }"
                    />
                  </b-input-group>
                </ValidationProvider>
              </div>
              <div v-else>
                <p v-if="canEdit" :class="{'text-danger': item.item_description == 'Agregar servicio'}" class="m-0 p-0 click-to-edit td-truncate" @click="editItem(item, 'description')">
                  <font-awesome-icon v-if="item.item_description == 'Agregar servicio'" :icon="['far', 'exclamation-circle']" fixed-width />
                  {{item.item_description}}
                </p>
                <span v-else>{{item.item_description}}</span>
              </div>
            </td>
            <!-- Type OC -->
            <td class="align-middle td-truncate d-none d-sm-table-cell p-1" style="max-width: 220px">
              <div v-if="OcTypeEdit && item.id === selectedPurchaseRequestItem.id">
                <b-form-select
                  v-model="form.purchase_request_type"
                  :options="purchaseTypeOptions"
                  :disabled="loadingData || !isValidOptions"
                  size="sm"
                  style="width: 200px"
                  @blur="cancelCustomEditing"
                  @change="updatePurchaseTypeOption(item)"
                  >
                    <template #first>
                      <b-form-select-option v-show="!form.purchase_request_type" :value="null" disabled>Seleccione...</b-form-select-option>
                    </template>
                </b-form-select>
              </div>
              <div v-else>
                <div v-if="loadingData" class="text-info">
                  <b-spinner small type="grow"></b-spinner>
                  <span class="pl-2">Cargando...</span>
                </div>
                <div v-else>
                  <span v-if="!canEdit" class="d-inline-block text-truncate" style="max-width: 250px;">{{item.purchase_request_type}}</span>
                  <span
                    v-else-if="item.purchase_request_type && canEdit"
                    class="click-to-edit d-inline-block text-truncate p-0"
                    style="max-width: 250px;"
                    @click="editItem(item, 'type_oc')"
                  >
                    {{item.purchase_request_type}}
                  </span>
                  <span v-else class="text-success font-weight-bold click-to-edit" @click="editItem(item, 'type_oc')">Agregar tipo de OC</span>
                </div>
              </div>
            </td>
            <!-- UN -->
            <td class="align-middle d-none d-sm-table-cell p-1 text-center" style="max-width: 220px">
              <b-dropdown
                id="businessUnitItem"
                ref="businessUnit"
                size="sm"
                variant="white"
                dropright
                @hidden="searchedBusinessUnit = ''"
                :text="item.business_unit"
                :disabled="!canEdit"
              >
                <div>
                  <b-input
                    v-model="searchedBusinessUnit"
                    id="dropdown-form-search"
                    placeholder="Buscar por nombre"
                    class="px-1"
                    style="width: 90%; margin: 0px 10px"
                  ></b-input>
                  <b-container v-if="filteredBusinessUnits.length > 0" style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                      v-for="un in filteredBusinessUnits" :key="un.value"
                      :active="item.business_unit == un.value"
                      class="p-0 m-0"
                      @click="updateBusinessUnit(item, un.value)"
                    >
                      <small>{{un.text}}</small>
                    </b-dropdown-item-button>
                  </b-container>
                  <b-container v-else style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="text-center text-danger">
                      <small>No hay resultados</small>
                    </div>
                  </b-container>
                </div>
              </b-dropdown>
            </td>
            <!-- Impuesto -->
            <td class="text-center align-middle px-1">
              <b-dropdown
                id="taxCodeItem"
                ref="taxCode2"
                size="sm"
                variant="white"
                dropright
                @hidden="searchedTaxCode = ''"
                :text="item.tax_code"
                :disabled="!canEdit"
              >
                <div>
                  <b-input
                    v-model="searchedTaxCode"
                    id="dropdown-form-search2"
                    placeholder="Buscar por código"
                    class="px-1"
                    style="width: 90%; margin: 0px 10px"
                  ></b-input>
                  <b-container v-if="filteredTaxes.length > 0" style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                      v-for="tax in filteredTaxes" :key="tax.value"
                      @click="updateTaxCode(item, tax.value)"
                      :active="item.tax_code == tax.value"
                      class="p-0 m-0"
                    >
                      <small>{{tax.text}}</small>
                    </b-dropdown-item-button>
                  </b-container>
                  <b-container v-else style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="text-center text-danger">
                      <small>No hay resultados</small>
                    </div>
                  </b-container>
                </div>
              </b-dropdown>
            </td>
            <!-- Price -->
            <td class="text-right align-middle price-display">
              <div class="center-items">
                <div v-if="priceEdit && item.id === selectedPurchaseRequestItem.id">
                  <ValidationProvider :rules="{ required: true }" immediate tag="div" v-slot="{ errors }">
                    <b-input-group size="sm" style="width: 160px">
                      <b-input-group-prepend is-text>
                        <span v-if="displayCRC">₡</span>
                        <span v-else>$</span>
                      </b-input-group-prepend>
                      <b-input
                        type="number"
                        v-model="form.price"
                        @blur="errors[0] ? cancelCustomEditing() : updatePrice()"
                        autofocus
                        placeholder="0"
                        :class="{ 'red-input-border': errors[0] }"
                      />
                    </b-input-group>
                  </ValidationProvider>
                </div>
                <div v-else>
                  <div v-if="displayCRC">
                    <span v-if="canEdit" class="click-to-edit" @click="editItem(item, 'price')">
                      {{ item.local_price | colones }}
                    </span>
                    <span v-else>{{ item.local_price | colones }}</span>
                  </div>
                  <div v-else>
                    <span v-if="canEdit" class="click-to-edit" @click="editItem(item, 'price')">
                      {{ item.price | dollarize }}
                    </span>
                    <span v-else>{{ item.price | dollarize }}</span>
                  </div>
                </div>
              </div>
            </td>
            <!-- Subtotal -->
            <td :class="{ 'text-danger': item.quantity < 0 }" class="text-right align-middle price-display">
              <span v-if="displayCRC">{{ item.quantity * item.local_price | colones }}</span>
              <span v-else>{{ item.quantity * item.price | dollarize }}</span>
            </td>
            <!-- Delete Icon -->
            <td v-if="canEdit" class="text-right align-middle" style="width: 50px">
              <span v-if="PurchaseRequestItems.length == 1"></span>
              <b-link v-else class="delete-icon" @click="deleteItem(item.id)">
                <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
              </b-link>
            </td>
          </tr>
          <!-- AddEmptyRow -->
          <tr v-if="canEdit">
            <td>
              <Button :disabled="addingNewRow" size="sm" class="text-success m-2" @click="addEmptyRow()">
                <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
                <span class="pl-1">Agregar</span>
              </Button>
            </td>
            <td></td>
            <td colspan="5"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Mobile -->
    <div class="d-sm-none">
      <table :class="{ 'table-closed': !canEdit }" class="table table-sm border-left border-right border-bottom">
        <thead class="thead-light">
          <tr>
            <th v-if="purchaseRequest.doc_type === 'service_document' && canEdit" style="width: 1.25rem"></th>
            <th v-if="purchaseRequest.doc_type === 'items_document'" class="text-center" style="width: 3.125rem">Cant.</th>
            <th v-if="purchaseRequest.doc_type === 'items_document'" >Código</th>
            <th v-if="purchaseRequest.doc_type === 'service_document'">Descripción</th>
            <th v-if="purchaseRequest.doc_type === 'service_document'">Tipo OC</th>
            <th class="text-center">UN</th>
            <th class="text-right">Subtotal</th>
            <th v-if="canEdit"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in PurchaseRequestItems" :key="item.id" :id="'item-' + item.id" style="height: 50px">
            <!-- Edit data -->
            <td v-if="purchaseRequest.doc_type === 'service_document' && canEdit" class="text-center align-middle" style="width: 20px">
              <small @click="openModal(item)" class="text-warning"><font-awesome-icon :icon="['far', 'edit']" fixed-width /></small>
            </td>
            <!-- Quantity -->
            <td v-if="purchaseRequest.doc_type === 'items_document'" class="text-center align-middle">{{ item.quantity }}</td>
            <!-- Descripción -->
            <td v-if="purchaseRequest.doc_type === 'service_document'" class="align-middle" style="min-width: 120px">
              <span v-if="canEdit" :class="{'text-danger': item.item_description == 'Agregar servicio'}" @click="openModal(item)">
                <font-awesome-icon v-if="item.item_description == 'Agregar servicio'" :icon="['far', 'exclamation-circle']" fixed-width />
                {{item.item_description}}
              </span>
              <span v-else :class="{'text-danger': item.item_description == 'Agregar servicio'}">
                <font-awesome-icon v-if="item.item_description == 'Agregar servicio'" :icon="['far', 'exclamation-circle']" fixed-width />
                {{item.item_description}}
              </span>
            </td>
            <!-- Item Code -->
            <td v-if="purchaseRequest.doc_type === 'items_document'" class="align-middle" style="min-width: 120px">
              <b-link v-if="canEdit" @click="openModal(item)">
                {{ item.product.item_code }}
                <span v-if="!item.product.is_valid" class="text-danger">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </span>
              </b-link>
              <span v-else>{{ item.product.item_code }}</span>
            </td>
            <!--OC Type -->
            <td v-if="purchaseRequest.doc_type === 'service_document'" class="align-middle" style="min-width: 100px">
              <div v-if="canEdit" @click="openModal(item)">
                <span v-if="item.purchase_request_type">{{item.purchase_request_type}}</span>
                <span v-else class="text-success"><font-awesome-icon :icon="['far', 'plus-circle']" fixed-width /><span>Agregar</span></span>
              </div>
              <div v-else>
                <span v-if="item.purchase_request_type">{{item.purchase_request_type}}</span>
              </div>
            </td>
            <!-- UN -->
            <td class="align-middle" style="min-width: 100px">
              <div v-if="canEdit" @click="openModal(item)" class="text-center">
                <span v-if="item.business_unit">{{item.business_unit}}</span>
              </div>
              <div v-else class="text-center">
                <span v-if="item.business_unit">{{item.business_unit}}</span>
              </div>
            </td>
            <!-- Subtotal -->
            <td class="text-right align-middle price-display"  :class="item.quantity < 0 ? 'text-danger' : null">
              <div v-if="canEdit" @click="openModal(item)">
                <span v-if="displayCRC">{{ item.quantity * item.local_price | colones }}</span>
                <span v-else>{{ item.quantity * item.price | dollarize }}</span>
              </div>
              <div v-else>
                <span v-if="displayCRC">{{ item.quantity * item.local_price | colones }}</span>
                <span v-else>{{ item.quantity * item.price | dollarize }}</span>
              </div>
            </td>
            <!-- Delete Icon -->
            <td v-if="canEdit" class="text-right align-middle" style="width: 56px">
              <span v-if="PurchaseRequestItems.length == 1"></span>
              <b-link v-else class="delete-icon" @click="deleteItem(item.id)">
                <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
              </b-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="canEdit && purchaseRequest.doc_type === 'service_document'">
        <Button :disabled="addingNewRow" size="sm" class="text-success m-2" @click="addEmptyRow()">
          <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
          <span class="pl-1">Agregar</span>
        </Button>
      </div>
    </div>

    <!-- Item Detail Modal : only mobile -->
    <b-modal v-model="modalShow" static centered @hide="clearSelectedItem">
      <template #modal-title>
        <h5 v-if="selectedPurchaseRequestItem && purchaseRequest.doc_type === 'items_document'">{{ selectedPurchaseRequestItem.product.item_code }}</h5>
        <h5 v-else> Editar datos...</h5>
      </template>
      <b-container v-if="purchaseRequest.doc_type === 'items_document'">
        <b-row v-if="selectedPurchaseRequestItem && !selectedPurchaseRequestItem.product.is_valid" align-h="center" class="text-danger mb-3">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          <small class="ml-2">Este artículo está marcado como inactivo en el inventario!</small>
        </b-row>
      </b-container>
      <b-container>
        <b-row v-if="selectedPurchaseRequestItem" align-h="center" class="fs-14">
          <b-col sm="11">
            <!-- QUANTITY -->
            <div v-if="purchaseRequest.doc_type === 'items_document'">
              <b-row><b-col><p class="font-lato">CANTIDAD</p></b-col></b-row>
              <b-row align-h="center" class="mb-1">
                <b-col>
                  <ValidationProvider rules="required|min_value:0.01" name="cantidad" tag="div" v-slot="{ errors }">
                    <b-row align-h="center">
                      <b-input-group size="sm" style="width: 130px">
                        <b-input-group-prepend>
                          <b-button :disabled="errors.length > 0 || form.quantity <= 1 || updatingQuantity" @click="decrement">
                            <font-awesome-icon :icon="['fas', 'minus']" size="lg" fixed-width />
                          </b-button>
                        </b-input-group-prepend>
                        <b-input
                          v-model="form.quantity"
                          ref="quantityInput"
                          :class="{ 'red-input-border': errors[0] }"
                          class="text-center"
                          @click="$nextTick(() => $refs.quantityInput.select())"
                          @blur="errors[0] ? null : updateQuantity()"
                          @keyup.enter="errors[0] ? null : updateQuantity()"
                        />
                        <b-input-group-append>
                          <b-button :disabled="errors.length > 0 || updatingQuantity" @click="increment">
                            <font-awesome-icon :icon="['fas', 'plus']" size="lg" fixed-width />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-row>
                    <b-row align-h="center" class="mt-1" style="height: 20px">
                      <small v-if="errors.length > 0" class="text-danger">{{ errors[0] }}</small>
                    </b-row>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <hr>
            </div>
            <!-- PRICE -->
            <b-row><b-col><p class="font-lato">PRECIO</p></b-col></b-row>
            <b-row>
              <b-col cols="10" class="center-items">
                <div v-if="priceEdit">
                  <ValidationProvider :rules="{ required: true }" immediate tag="div" v-slot="{ errors }">
                    <b-input-group size="sm" style="width: 200px">
                      <b-input-group-prepend is-text>
                        <span v-if="displayCRC">₡</span>
                        <span v-else>$</span>
                      </b-input-group-prepend>
                      <b-input
                        type="number"
                        v-model="form.price"
                        autofocus
                        placeholder="0"
                        :class="{ 'red-input-border': errors[0] }"
                      />
                      <b-input-group-append>
                        <b-button :disabled="errors.length > 0" variant="success" size="sm" @click="updatePrice()">
                          <font-awesome-icon :icon="['far', 'save']" fixed-width class="mx-1" />
                        </b-button>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-button size="sm" variant="danger" @click="cancelCustomEditing()">
                          <font-awesome-icon :icon="['far', 'times']" fixed-width class="text-light mx-1" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </ValidationProvider>
                </div>
                <div v-else class="text-center">
                  <div v-if="displayCRC">
                    <span class="click-to-edit" @click="editItem(selectedPurchaseRequestItem, 'price')">
                      {{ selectedPurchaseRequestItem.local_price | colones }}
                    </span>
                  </div>
                  <div v-else>
                    <span  class="click-to-edit" @click="editItem(selectedPurchaseRequestItem, 'price')">
                      {{ selectedPurchaseRequestItem.price | dollarize }}
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <hr>
            <!-- DESCRIPTION -->
            <b-row class="mt-3">
              <b-col>
                <p class="font-lato">DESCRIPCIÓN</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <b-col v-if="purchaseRequest.doc_type !== 'items_document'" cols="1" align-self="start">
                    <font-awesome-icon v-if="!descriptionEdit" :icon="['far', 'edit']" fixed-width  @click="editItem(selectedPurchaseRequestItem, 'description')"/>
                  </b-col>
                  <b-col>
                    <div v-if="descriptionEdit">
                      <ValidationProvider :rules="{ required: true }" immediate tag="div" v-slot="{ errors }">
                        <b-form-textarea
                          id="description"
                          v-model="form.description"
                          @blur="errors[0] || form.description === 'Agregar servicio' || form.description === selectedPurchaseRequestItem.item_description ? cancelCustomEditing() : updateDescription()"
                          autofocus
                          rows="3"
                          max-rows="6"
                          :class="{ 'red-input-border': errors[0] }"
                        ></b-form-textarea>
                      </ValidationProvider>
                    </div>
                    <div v-else>
                      <p v-if="purchaseRequest.doc_type === 'items_document'">{{ selectedPurchaseRequestItem.product.description_lg }}</p>
                      <p v-else :class="{'text-danger': selectedPurchaseRequestItem.item_description == 'Agregar servicio'}" @click="editItem(selectedPurchaseRequestItem, 'description')">
                        {{selectedPurchaseRequestItem.item_description}}
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- OC TYPE -->
            <div v-if="purchaseRequest.doc_type === 'service_document'">
              <hr>
              <b-row><b-col><p class="font-lato">TIPO DE OC</p></b-col></b-row>
              <b-row align-h="center">
                <div v-if="OcTypeEdit">
                  <b-form-select
                    v-model="form.purchase_request_type"
                    :options="purchaseTypeOptions"
                    :disabled="loadingData || !isValidOptions"
                    size="sm"
                    style="width: auto"
                    @blur="cancelCustomEditing"
                    @change="updatePurchaseTypeOption(selectedPurchaseRequestItem)"
                    >
                      <template #first>
                        <b-form-select-option v-show="!form.purchase_request_type" :value="null" disabled>Seleccione...</b-form-select-option>
                      </template>
                  </b-form-select>
                </div>
                <div v-else>
                  <div v-if="loadingData" class="text-info">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="pl-2">Cargando...</span>
                  </div>
                  <span v-else-if="selectedPurchaseRequestItem.purchase_request_type" class="click-to-edit" @click="editItem(selectedPurchaseRequestItem, 'type_oc')">{{selectedPurchaseRequestItem.purchase_request_type}}</span>
                  <span v-else class="text-danger font-weight-bold click-to-edit" @click="editItem(selectedPurchaseRequestItem, 'type_oc')">Agregar tipo de OC</span>
                </div>
              </b-row>
            </div>
            <!-- UN -->
            <div>
              <hr>
              <b-row><b-col><p class="font-lato">Unidad de Negocio</p></b-col></b-row>
              <b-row align-h="center">
                <b-dropdown
                  id="businessUnitItem2"
                  ref="businessUnit"
                  size="sm"
                  variant="white"
                  @hidden="searchedBusinessUnit = ''"
                  :text="selectedPurchaseRequestItem.business_unit"
                  :disabled="!canEdit"
                >
                  <div>
                    <b-input
                      v-model="searchedBusinessUnit"
                      id="dropdown-form-search"
                      placeholder="Buscar por nombre"
                      class="px-1"
                      style="width: 90%; margin: 0px 10px"
                    ></b-input>
                    <b-container v-if="filteredBusinessUnits.length > 0" style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item-button
                        v-for="un in filteredBusinessUnits" :key="un.value"
                        :active="selectedPurchaseRequestItem.business_unit == un.value"
                        class="p-0 m-0"
                        @click="updateBusinessUnit(selectedPurchaseRequestItem, un.value)"
                      >
                        <small>{{un.text}}</small>
                      </b-dropdown-item-button>
                    </b-container>
                    <b-container v-else style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                      <b-dropdown-divider></b-dropdown-divider>
                      <div class="text-center text-danger">
                        <small>No hay resultados</small>
                      </div>
                    </b-container>
                  </div>
                </b-dropdown>
              </b-row>
            </div>
            <!-- TAX -->
            <div>
              <hr>
              <b-row><b-col><p class="font-lato">Impuesto</p></b-col></b-row>
              <b-row align-h="center">
                <b-dropdown
                id="taxCodeItem2"
                ref="taxCode2"
                size="sm"
                variant="white"
                @hidden="searchedTaxCode = ''"
                :text="selectedPurchaseRequestItem.tax_code"
                :disabled="!canEdit"
              >
                <div>
                  <b-input
                    v-model="searchedTaxCode"
                    id="dropdown-form-search2"
                    placeholder="Buscar por código"
                    class="px-1"
                    style="width: 90%; margin: 0px 10px"
                  ></b-input>
                  <b-container v-if="filteredTaxes.length > 0" style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                      v-for="tax in filteredTaxes" :key="tax.value"
                      @click="updateTaxCode(selectedPurchaseRequestItem, tax.value)"
                      :active="selectedPurchaseRequestItem.tax_code == tax.value"
                      class="p-0 m-0"
                    >
                      <small>{{tax.text}}</small>
                    </b-dropdown-item-button>
                  </b-container>
                  <b-container v-else style="max-height: 150px; min-width: 220px; overflow-y: scroll">
                    <b-dropdown-divider></b-dropdown-divider>
                    <div class="text-center text-danger">
                      <small>No hay resultados</small>
                    </div>
                  </b-container>
                </div>
              </b-dropdown>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer><span></span></template>
    </b-modal>
  </div>
</template>

<script>

import { ValidationProvider } from 'vee-validate'
import PurchaseRequestItemQuantityInput from './PurchaseRequestItemQuantityInput'
import PurchaseRequestItemQuickAddRow from './PurchaseRequestItemQuickAddRow'
import Button from '../../shared/Button'
import { businessUnits} from '../../../sharedOptions/purchaseRequestBusinessUnits'

export default {
  name: 'PurchaseRequestItemTable',
  components: {
    ValidationProvider,
    PurchaseRequestItemQuantityInput,
    PurchaseRequestItemQuickAddRow,
    Button
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: false
    },
    PurchaseRequestItems: {
      type: Array,
      required: true
    },
    priceList: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        quantity: null,
        price: null,
        description: null,
        is_custom: false,
        purchase_request_type: null,
        business_unit: null
      },
      customPriceForm: {
        customPrice: 0
      },
      businessUnitOptions: businessUnits,
      purchaseTypeOptions: [],
      isValidOptions: false,
      modalShow: false,
      selectedPurchaseRequestItem: null,
      processing: false,
      updatingQuantity: false,
      addingNewRow: false,
      updatingPrice: false,
      OcTypeEdit: false,
      priceEdit: false,
      businessUnitEdit: false,
      descriptionEdit: false,
      loadingData: false,
      taxCodeOptions: [
        { value: 'IVC', text: 'IVC (13%)' },
        { value: 'EXEC', text: 'EXEC (100%)' },
        { value: 'IVG', text: 'IVG (13%)' },
        { value: 'IMPG', text: 'IMPG (100%)' },
        { value: 'IV1', text: 'IV1 (1%)' },
        { value: 'IV2', text: 'IV2 (2%)' },
        { value: 'IV4', text: 'IV4 (4%)' },
        { value: 'IVA8', text: 'IVA8 (8%)' },
        { value: 'IV', text: 'IV (13%)' },
        { value: 'ISC', text: 'ISC (10%)' },
        { value: 'IMPE', text: 'IMPE (100%)' },
        { value: 'IVM', text: 'IVM (10%)' },
        { value: 'EXO', text: 'EXO (100%)' }
      ],
      searchedBusinessUnit: '',
      searchedTaxCode: ''
    }
  },
  computed: {
    displayCRC () { return this.$store.state.displayCRC },
    exchangeRate () { return this.purchaseRequest.usd_crc_rate },
    // isOpen () { return this.purchaseRequest ? this.purchaseRequest.is_open : true },
    isOrder () {
      if (!this.purchaseRequest) { return false }
      if (this.purchaseRequest && this.purchaseRequest.order_type == 'order' && this.purchaseRequest.is_executed) {
        return true
      }
      return false
    },
    canEdit () {
      if (this.purchaseRequest) {
        if (this.isOrder) {
          return this.purchaseRequest.is_approved ? false : true
        } else {
          return this.purchaseRequest.is_open ? true : false
        }
      }
      return false
    },
    filteredBusinessUnits () {
      if (this.searchedBusinessUnit) {
        return this.businessUnitOptions.filter(el => el.text.toLowerCase().includes(this.searchedBusinessUnit.toLowerCase()))
      }
      return this.businessUnitOptions
    },
    filteredTaxes () {
      if (this.searchedTaxCode) {
        return this.taxCodeOptions.filter(el => el.value.toLowerCase().includes(this.searchedTaxCode.toLowerCase()))
      }
      return this.taxCodeOptions
    }
  },
  mounted () {
    this.fetchPurchaseTypeOptions()
  },
  methods: {
    fetchPurchaseTypeOptions () {
      if (this.processing) return
      if (this.purchaseRequest.doc_type === 'items_document') return
      this.processing = true
      this.loadingData = true
      this.$http.get(`/api/production/purchase_requests/${this.purchaseRequest.id}/fetch_sap_purchase_order_types`)
        .then(response => {
          this.purchaseSelectOptions(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          this.loadingData = false
        });
    },
    purchaseSelectOptions (options){
      if(options.length == 0){
        this.purchaseTypeOptions = []
        this.isValidOptions = false
        this.$emit('set-validation-error', 'Debe solicitar a contabilidad la creación del Tipo de OC para este proveedor')
      } else {
        this.isValidOptions = true
        this.purchaseTypeOptions =  options.map(term => ({text: term.TipoOC, value: [term.CuentaCon, term.TipoOC]}))
      }
    },
    openModal (purchaseRequestItem) {
      this.selectedPurchaseRequestItem = purchaseRequestItem;
      this.form.quantity = purchaseRequestItem.quantity;
      this.modalShow = true;
    },
    updateItem (purchaseRequestItem) { // event from PurchaseRequestItemQuantityInput
      console.log(purchaseRequestItem);
      this.$emit('item-updated', purchaseRequestItem)
    },
    updateQuantity () {
      if (this.processing) return;
      this.processing = true;
      this.updatingQuantity = true;
      this.$http.patch(`/api/production/purchase_requests/${this.selectedPurchaseRequestItem.purchase_request_id}/purchase_request_items/${this.selectedPurchaseRequestItem.id}`, {
          purchase_request_item: { quantity: this.form.quantity }
        })
        .then(response => {
          console.log(response)
          this.selectedPurchaseRequestItem = response.data
          this.form.quantity = response.data.quantity
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => this.updatingQuantity = false, 200)
        });
    },
    updateTaxCode(item, value) {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/purchase_requests/${item.purchase_request_id}/purchase_request_items/${item.id}`, {
        purchase_request_item: {
            tax_code: value,
          }
        })
        .then(response => {
          console.log(response)
          this.selectedPurchaseRequestItem = response.data
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        });
    },
    updatePrice () {
      if (this.selectedPurchaseRequestItem.local_price == this.form.price || this.selectedPurchaseRequestItem.price == this.form.price) return this.cancelCustomEditing()
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/purchase_requests/${this.selectedPurchaseRequestItem.purchase_request_id}/purchase_request_items/${this.selectedPurchaseRequestItem.id}`, {
          purchase_request_item: {
            price: this.displayCRC ? this.form.price / this.exchangeRate : this.form.price,
            local_price: this.displayCRC ? this.form.price : this.form.price * this.exchangeRate,
          }
        })
        .then(response => {
          console.log(response)
          this.selectedPurchaseRequestItem = response.data
          this.form.price = response.data.price
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.priceEdit = false
          this.processing = false
          this.form.price = null
        });
    },
    updateDescription () {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/purchase_requests/${this.selectedPurchaseRequestItem.purchase_request_id}/purchase_request_items/${this.selectedPurchaseRequestItem.id}`, {
          purchase_request_item: { item_description: this.form.description }
        })
        .then(response => {
          console.log(response)
          this.selectedPurchaseRequestItem = response.data
          this.$emit('item-updated', response.data)
          this.$emit('item-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          this.descriptionEdit = false
          this.form.description = null
        });
    },
    updatePurchaseTypeOption (item) {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/purchase_requests/${item.purchase_request_id}/purchase_request_items/${item.id}`, {
          purchase_request_item: {
            purchase_request_type: this.form.purchase_request_type[1],
            account_code: this.form.purchase_request_type[0]
          }
        })
        .then(response => {
          console.log(response)
          this.selectedPurchaseRequestItem = response.data
          // this.form.description = response.data.description
          this.$emit('item-updated', response.data)
          // this.$emit('total-changed')
          this.$emit('item-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.OcTypeEdit = false
          this.processing = false
          this.form.purchase_request_type = null
          // Wait 0.2 seconds until able to increment/decrement quantity again
          // setTimeout(() => this.updatingQuantity = false, 200)
        });
    },
    updateBusinessUnit (item, value) {
      if (this.processing) return;
      this.form.business_unit = value
      this.processing = true;
      this.$http.patch(`/api/production/purchase_requests/${item.purchase_request_id}/purchase_request_items/${item.id}`, {
          purchase_request_item: {
            business_unit: value,
          }
        })
        .then(response => {
          console.log(response)
          this.selectedPurchaseRequestItem = response.data
          this.$emit('item-updated', response.data)
          this.$emit('item-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.businessUnitEdit = false
          this.processing = false
          this.form.business_unit = null
          // Wait 0.2 seconds until able to increment/decrement quantity again
          // setTimeout(() => this.updatingQuantity = false, 200)
        });
    },
    editItem (item, key) {
      // Assign new values to compare in table section
      this.selectedPurchaseRequestItem = item
      // Editing options
      key == 'businessUnit' ? this.businessUnitEdit = true : this.businessUnitEdit = false
      key == 'description' ? this.descriptionEdit = true : this.descriptionEdit = false
      key == 'price' ? this.priceEdit = true : this.priceEdit = false
      key == 'type_oc' ? this.OcTypeEdit = true : this.OcTypeEdit = false
      // show current item values to form input
      key == 'description' && item.item_description != "Agregar servicio" ? this.form.description = item.item_description : this.form.description = null
      key == 'businessUnit' ? this.form.business_unit = item.business_unit : this.form.business_unit = null
      // USD or COL?!
      if (key == 'price' && this.displayCRC){
        this.form.price = this.selectedPurchaseRequestItem.local_price
      } else if (key == 'price' && !this.displayCRC) {
        this.form.price = this.selectedPurchaseRequestItem.price
      } else {
        this.form.price = null
      }
    },
    addEmptyRow () {
      if (this.processing) return
      this.processing = true
      this.addingNewRow = true
      this.$http.get(`/api/production/purchase_requests/${this.purchaseRequest.id}/purchase_request_items/add_row`)
      .then(response => {
        console.log(response.data)
        this.selectedPurchaseRequestItem = response.data
        this.$emit('purchase-request-item-added', response.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.descriptionEdit = true
        this.processing = false
        setTimeout(() => this.addingNewRow = false, 200)
      });
    },
    cancelCustomEditing () {
      this.OcTypeEdit = false
      this.priceEdit = false
      this.descriptionEdit = false
      this.businessUnitEdit = false
      this.form.price = null
      this.form.description = null
      this.form.purchase_request_type = null
      this.form.business_unit = null
      this.processing = false
    },
    deleteItem (purchaseRequestId) {
      if (this.processing) return
      this.processing = true
      this.$http.delete(`/api/production/purchase_requests/${this.purchaseRequest.id}/purchase_request_items/${purchaseRequestId}`)
        .then(response => {
          console.log(response)
          this.$emit('item-deleted', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(this.processing = false)
    },
    isStockEligible (purchaseRequestItem) {
      if (purchaseRequestItem.product && purchaseRequestItem.product.item_code) {
        return ['INS-', 'SERVICIO', 'SM-', 'SRVTA-', 'TRANSPORTE', 'ENCOM', 'FREIGHT', 'HORATECNICO', 'HORAYUDANT', 'PROGRAMA', 'PORTON', 'ELEMAX'].some(substring => purchaseRequestItem.product.item_code.toUpperCase().includes(substring)) ? false : true;
      } else {
        return true;
      }
    },
    isCustomPriceEligible (purchaseRequestItem) {
      if (purchaseRequestItem.product && purchaseRequestItem.product.item_code) {
        return ['INS-', 'SERVICIO', 'SM-', 'SRVTA-', 'TRANSPORTE', 'ENCOM', 'FREIGHT', 'HORATECNICO', 'HORAYUDANT', 'PROGRAMA'].some(substring => purchaseRequestItem.product.item_code.toUpperCase().includes(substring)) ? true : false;
      } else {
        return false
      }
    },
    clearSelectedItem () {
      this.form.quantity = null
      this.customPriceForm.customPrice = 0
      this.selectedPurchaseRequestItem = null
    },
    decrement () {
      this.updatingQuantity = true
      this.form.quantity--
      this.updateQuantity()
    },
    increment () {
      this.updatingQuantity = true
      this.form.quantity++
      this.updateQuantity()
    },
    addPurchaseRequestItem (purchaseRequestItem) {
      this.$emit('purchase-request-item-added', purchaseRequestItem)
      this.$emit('total-changed')
    }
  }
}
</script>

<style scoped>
.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
