import Vue from 'vue'
import VueRouter from 'vue-router'

import AppNews from './components/AppNews'
import AppAnnouncements from './components/AppAnnouncements'
import AppConfig from './components/AppConfig'
import AppRoles from './components/AppRoles'
import AccessIndex from './components/production/AccessIndex'         // deprecate
import ActivityLog from './components/human_resources/ActivityLog'
import BundleIndex from './components/production/BundleIndex'
import BundleShow from './components/production/BundleShow'
import CardTransactionIndex from './components/production/CardTransaction/Index'
import CourseIndex from './components/production/Courses/Index'
import ClientIndex from './components/production/ClientIndex'
import ClientShow from './components/production/ClientShow'
import ClientNew from './components/production/ClientNew'
import ClientUpdate from './components/production/ClientUpdate'
import ContractorIndex from './components/contractors/ContractorIndex'
import ContractorShow from './components/contractors/ContractorShow'
import Distribution from './components/contractors/Distribution'
import DistributorClientIndex from './components/contractors/DistributorClientIndex'
import DistributorClientShow from './components/contractors/DistributorClientShow'
import DistributorQuoteIndex from './components/contractors/DistributorQuoteIndex'
import DistributorQuoter from './components/contractors/DistributorQuoter'
import ExternalData from './components/ExternalData'
import EmployeeIndex from './components/human_resources/EmployeeIndex'
import EmployeeNew from './components/human_resources/EmployeeNew'
import EmployeeShow from './components/human_resources/EmployeeShow'
import EmployeeVacations from './components/human_resources/EmployeeVacations'
import HR from './components/human_resources/index'
import HRConfig from './components/human_resources/Config'
import HRReports from './components/human_resources/Reports'
import HRLinks from './components/human_resources/HumanResourcesLinks.vue'
import InstallerClientIndex from './components/contractors/InstallerClientIndex'
import InstallerClientShow from './components/contractors/InstallerClientShow'
import InactiveEmployeeIndex from './components/human_resources/InactiveEmployeeIndex'
import InactiveEmployeeShow from './components/human_resources/InactiveEmployeeShow'
import ItemIndex from './components/production/ItemIndex'
import ItemShow from './components/production/ItemShow'
import OnlineStoreQuoteIndex from './components/online_store/QuoteIndex'
import OrgChart from './components/human_resources/OrgChart'
import NotFoundComponent from './components/NotFoundComponent'
import Production from './components/production/index'
import ProjectIndex from './components/production/ProjectIndex'
import ProjectShow from './components/production/ProjectShow'         // deprecate
import ProjectShowNext from './components/production/ProjectShowNext' // next version
import PurchaseDeliveryLayout from './components/production/purchaseDeliveries/PurchaseDeliveryLayout.vue'
import PurchaseDeliveryIndex from './components/production/purchaseDeliveries/Index.vue'
import PurchaseDeliverySearch from './components/production/purchaseDeliveries/PurchaseDeliverySearch.vue'
import PurchaseDeliveryRegister from './components/production/purchaseDeliveries/PurchaseDeliveryRegister.vue'
import PurchaseDeliveryValidation from './components/production/purchaseDeliveries/PurchaseDeliveryValidation.vue'
import PurchaseRequestNew from './components/production/purchaseRequests/PurchaseRequestNew.vue'
import PurchaseRequestShow from './components/production/purchaseRequests/PurchaseRequestShow.vue'
import PurchaseRequestIndex from './components/production/purchaseRequests/Index.vue'
import QuoteIndex from './components/production/QuoteIndex'
import QuoteNew from './components/production/QuoteNew'
import QuoteShow from './components/production/QuoteShow'
import SubmitalIndex from './components/production/SubmitalIndex'
import SubmitalShow from './components/production/SubmitalShow'
import SubmitalPhotos from './components/production/SubmitalPhotos'
import SubmitalAdmin from './components/production/SubmitalAdmin'
import VacationTicketIndex from './components/human_resources/VacationTicketIndex'
import VacationTicketShow from './components/human_resources/VacationTicketShow'
import WarehouseIndex from './components/production/WarehouseIndex'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Production, name: 'mainPage' },
    { path: '/app/noticias', component: AppNews},
    { path: '/app/anuncios', component: AppAnnouncements },
    { path: '/app/config', component: AppConfig },
    { path: '/app/datos_externos', component: ExternalData },
    { path: '/app/roles', component: AppRoles },

    // Production
    { path: '/app/prod/clientes', component: ClientIndex },
    { path: '/app/prod/clientes/:id', component: ClientShow, props: true },
    { path: '/app/prod/cliente/:id', component: ClientUpdate, name: 'ClientUpdate',  props: true },
    { path: '/app/prod/cliente', component: ClientNew, meta: { prodOnly: true, prodActive: true } },
    { path: '/app/prod/proyectos', component: ProjectIndex, meta: { prodOnly: true } },
    { path: '/app/prod/proyectos/:id', component: ProjectShow, meta: { prodOnly: true }, props: true },   // replace with ProjectShowNext route
    // NEXT version of ProjectShow
    { path: '/app/prod/proyectos/next/:id', component: ProjectShowNext, meta: { prodOnly: true }, props: true },
    //
    { path: '/app/prod/accesos/:type', component: AccessIndex, props: true },         // deprecate
    // NEXT version of CortinaIndex
    { path: '/app/prod/submitals/index/:configurable_type', component: SubmitalIndex, meta: { prodOnly: true }, props: true },
    // Courses
    { path: '/app/prod/cursos', component: CourseIndex, meta: { prodOnly: true } },
    //
    { path: '/app/prod/submitals/:id', component: SubmitalShow, meta: { prodOnly: true }, props: true },
    { path: '/app/prod/submitals/:id/fotos', component: SubmitalPhotos, meta: { prodOnly: true }, props: true },
    { path: '/app/prod/submitals/:id/admin', component: SubmitalAdmin, meta: { prodOnly: true }, props: true },
    { path: '/app/prod/cotizaciones', component: QuoteIndex, meta: { prodOnly: true } },
    { path: '/app/prod/cotizador', component: QuoteNew, meta: { prodOnly: true } },
    { path: '/app/prod/cotizador/:id', component: QuoteShow, name: 'QuoteShow', props: true, meta: { prodOnly: true }},

    // Inventario module
    { path: '/app/prod/inventario', component: ItemIndex, name: 'ItemIndex' },
    { path: '/app/prod/inventario/bultos', component: BundleIndex },
    { path: '/app/prod/inventario/bultos/:id', component: BundleShow, props: true },
    { path: '/app/prod/inventario/almacenes', component: WarehouseIndex },
    // <Purchase Deliveries Module>
    { path: '/app/prod/inventario/ingreso', component: PurchaseDeliveryLayout, meta: { prodOnly: true },
      children: [
        { name: 'PurchaseDeliveryIndex', path: '/', component: PurchaseDeliveryIndex },
        { name: 'PurchaseDeliverySearch', path: 'busqueda', component: PurchaseDeliverySearch },
        { name: 'PurchaseDeliveryRegister', path: 'registro', component: PurchaseDeliveryRegister },
        { name: 'PurchaseDeliveryValidation', path: 'validacion', component: PurchaseDeliveryValidation }
      ]
    },
    // </Purchase Deliveries Module>
    { path: '/app/prod/inventario/:id', component: ItemShow, name: 'ItemShow', props: true },

    // Card transactions App
    { path: '/app/prod/transacciones', component: CardTransactionIndex, name: 'CardTransactionIndex' },

    // Purchase Request Module
    { path: '/app/prod/solicitud', component: PurchaseRequestNew, meta: { prodOnly: true } },
    { path: '/app/prod/solicitud/:id', component: PurchaseRequestShow, name: 'PurchaseRequestShow', props: true },
    { path: '/app/prod/solicitudes', component: PurchaseRequestIndex, meta: { prodOnly: true } },

    // Online Store
    { path: '/app/tienda_online', component: OnlineStoreQuoteIndex },

    // Contractors  // need meta route guards
    { path: '/app/contratistas', component: ContractorIndex },
    { path: '/app/contratistas/:id', component: ContractorShow, props: true },

    { path: '/app/distribucion', component: Distribution },
    { path: '/app/dist/clientes', component: DistributorClientIndex, meta: { distOnly: true } },
    { path: '/app/dist/clientes/:id', component: DistributorClientShow, props: true, meta: { distOnly: true } },
    { path: '/app/dist/cotizaciones', component: DistributorQuoteIndex, meta: { distOnly: true } },
    { path: '/app/dist/cotizador/:id', component: DistributorQuoter, name: 'DistributorQuoter', props: true, meta: { distOnly: true } },

    { path: '/app/inst/cotizaciones', component: DistributorQuoteIndex, meta: { instOnly: true } },
    { path: '/app/inst/cotizador/:id', component: DistributorQuoter, name: 'InstallerQuoter', props: true, meta: { instOnly: true }},
    { path: '/app/inst/clientes', component: InstallerClientIndex, meta: { instOnly: true } },
    { path: '/app/inst/clientes/:id', component: InstallerClientShow, props: true, meta: { instOnly: true } },

    // Human Resources
    { path: '/app/rrhh', component: HR, meta: { prodOnly: true }, name: 'rrhh' },
    { path: '/app/rrhh/links', component: HRLinks, meta: { prodOnly: true }, name: 'rrhh-links' },
    { path: '/app/rrhh/empleados', component: EmployeeIndex, meta: { prodOnly: true } },
    { path: '/app/rrhh/empleados/nuevo', component: EmployeeNew, meta: { prodOnly: true } },
    { path: '/app/rrhh/empleados/:id', component: EmployeeShow, name: 'EmployeeShow', props: true, meta: { prodOnly: true } },
    { path: '/app/rrhh/empleados/:id/vacaciones', component: EmployeeVacations, props: true, meta: { prodOnly: true } },
    { path: '/app/rrhh/inactivos', component: InactiveEmployeeIndex, meta: { prodOnly: true } },
    { path: '/app/rrhh/inactivos/:id', component: InactiveEmployeeShow, name: 'InactiveEmployeeShow', props: true, meta: { prodOnly: true } },
    { path: '/app/rrhh/organigrama', component: OrgChart, meta: { prodOnly: true } },
    { path: '/app/rrhh/boletas', component: VacationTicketIndex, meta: { prodOnly: true } },
    { path: '/app/rrhh/boletas/:id', component: VacationTicketShow, props: true, meta: { prodOnly: true } },
    { path: '/app/rrhh/actividad', component: ActivityLog, meta: { prodOnly: true } },
    { path: '/app/rrhh/reportes', component: HRReports, meta: { prodOnly: true } },
    { path: '/app/rrhh/config', component: HRConfig, meta: { prodOnly: true }},

    // Not found
    { path: '/app/*', component: NotFoundComponent, name: 'notFound' }
  ]
})
