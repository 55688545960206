<template>
  <b-row align-h="center">
    <div class="spinner-3dot">
      <div class="bounce-1"></div>
      <div class="bounce-2"></div>
      <div class="bounce-3"></div>
    </div>
  </b-row>
</template>

<script>
export default {
  name: 'SpinnerHorizontal'
}
</script>

<style scoped>
.spinner-3dot {
  width: 70px;
  text-align: center;
}

.spinner-3dot > div {
  width: 18px;
  height: 18px;
  background-color: #aaa;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-3dot .bounce-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-3dot .bounce-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>
