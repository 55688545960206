<template>
  <b-container fluid class="py-3">
    <br>
    <!-- Title -->
    <b-row align-v="center" class="justify-content-center">
      <div class="mb-2 main-title">
        <font-awesome-icon :icon="['fas', 'users']" size="lg" fixed-width />
        Talento Humano
      </div>
    </b-row>
    <br>
    <!-- links cards  -->
    <b-container fluid class="justify-content-center">
      <b-row align-h="center">
        <b-col cols="11">
          <br>
          <b-row id="collapse-1">
            <b-col lg="4" sm="12" class="mb-3">
              <b-card class="card-content" border-variant="light">
                <b-card-header class="card-header-content">
                  <h5 class="mb-0 text-center text-white">
                    <font-awesome-icon :icon="['fas', 'file-contract']" size="lg" fixed-width />
                    ULTRADOX
                  </h5>
                </b-card-header>
                <div class="card-items">
                  <b-list-group
                    v-for="item in accessLinks[1]"
                    :key="item.name"
                    horizontal
                    class="d-flex flex-column justify-content-left"
                    :style="{'cursor': item.url ? 'pointer' :'not-allowed'}"
                  >
                    <b-list-group-item class="card-item" :disabled="!item.url" @click="openLink(item)" style="width: auto;">
                      <p class="pr-1 m-0"> {{item.name}}</p>
                      <div class="card-corner" href="#">
                        <div class="go-corner-icon">
                          <font-awesome-icon v-if="item.type == 'file'" :icon="['far', 'file-alt']" size="sm"/>
                          <font-awesome-icon v-else :icon="['fas', 'link']" size="sm"/>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-card>
            </b-col>
            <b-col lg="4" sm="12" class="mb-3">
              <b-card class="card-content" border-variant="light">
                <b-card-header class="card-header-content">
                  <h5 class="mb-0 text-center text-white">
                    <font-awesome-icon :icon="['fas', 'user-check']" size="lg" fixed-width />
                    ACTUALIZACIÓN DE DATOS
                  </h5>
                </b-card-header>
                <div class="card-items">
                  <b-list-group
                    v-for="item in accessLinks[2]"
                    :key="item.name"
                    horizontal
                    class="d-flex flex-column justify-content-left"
                    :style="{'cursor': item.url ? 'pointer' :'not-allowed'}"
                  >
                    <b-list-group-item class="card-item" :disabled="!item.url" @click="openLink(item)" style="width: auto;">
                      <p class="pr-1 m-0">{{item.name}}</p>
                      <div class="card-corner" href="#">
                        <div class="go-corner-icon">
                          <font-awesome-icon v-if="item.type == 'file'" :icon="['far', 'file-alt']" size="sm"/>
                          <font-awesome-icon v-else :icon="['fas', 'link']" size="sm"/>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-card>
            </b-col>
            <b-col lg="4" sm="12" class="mb-3">
              <b-card class="card-content" border-variant="light">
                <b-card-header class="card-header-content">
                  <h5 class="mb-0 text-center text-white">
                    <font-awesome-icon :icon="['fas', 'search']" size="lg" fixed-width />
                    MATERIALES DE CONSULTA
                  </h5>
                </b-card-header>
                <div class="card-items">
                  <b-list-group
                    v-for="item in accessLinks[3]"
                    :key="item.name"
                    horizontal
                    class="d-flex flex-column justify-content-left"
                    :style="{'cursor': item.url ? 'pointer' :'not-allowed'}"
                  >
                    <b-list-group-item class="card-item" :disabled="!item.url" @click="openLink(item)" style="width: auto;">
                      <p class="pr-1 m-0">{{item.name}}</p>
                      <div class="card-corner" href="#">
                        <div class="go-corner-icon">
                          <font-awesome-icon v-if="item.type == 'file'" :icon="['far', 'file-alt']" size="sm"/>
                          <font-awesome-icon v-else :icon="['fas', 'link']" size="sm"/>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <br>
    </b-container>
  </b-container>
</template>

<script>
import linksRrhh from '../../sharedOptions/humanResourcesUrls';
export default {
  name: 'HumanResourcesLinks',
  data () {
    return {
      accessLinks: linksRrhh,
      processing: false
    }
  },
  methods : {
    openLink (item) {
      if (!item || !item.url) return
      if (item.type == 'file') {
        this.downloadResource(item)
      } else {
        window.open(item.url);
      }
    },
    downloadResource(item) {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/production/documents/download_by_name', {
        params: {
          name: item.url
        }
      })
        .then((res) => {
          // console.log(res.data)
          window.open(res.data);
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-title {
    border-bottom: 2px solid #aaa;
    font-size: 1.7rem;
    align-items: center;
    &:hover {
      font-weight: bolder;
      cursor: pointer;
    }
  }
  .list-group:nth-child(1) {
    margin-top: 0.8rem;
  }
  .card-content {
    border-radius: 2rem;
    // height: 100%;
    // box-shadow: 9px 6px gainsboro;
  }
  .card-items {
    overflow: auto;
    max-height: 21.875rem;
  }
  .card-header-content {
    border-radius: 0.5rem;
    background-color:#163D7D;
    margin-bottom: 1.25rem;
  }
  .card-item {
    margin-bottom: 0.5rem;
    padding-left: 0.625rem;
    color: black;
    display: block;
    top: 0px;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;

    &:hover {
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      color: #163D7D;
      border: 1px solid #cccccc;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -16px;
      right: -16px;
      background: #00838d;
      height: 32px;
      width: 32px;
      border-radius: 32px;
      transform: scale(2);
      transform-origin: 50% 50%;
      transition: transform 0.15s ease-out;
    }

    &:hover:before {
      transform: scale(2.15);
    }

  }
  .card-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #163D7D;
    border-radius: 0 4px 0 32px;
  }

  .go-corner-icon {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
</style>