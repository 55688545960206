<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">1</span>
            <span class="step-title">Diseño de Cortina</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <b-row align-h="between" class="mt-3">
            <div>
              <BlankIcon :field="cortina.design_id" />
              <label class="outer-label">Diseño:</label>
            </div>
            <Button variant="blue" @click="$store.commit('setCurrentStep', 'designSelector')">
              Selector
            </Button>
          </b-row>
          <b-row class="mt-3">
            <div class="showcase w-100">
              <div class="showcase-title">Diseño Principal:</div>
              <div v-if="cortina.design_id">
                <b-row align-h="center" class="pb-2">
                  <div class="showcase-label">
                    {{ cortina.design.item_code }}: {{ cortina.design.name }}
                  </div>
                </b-row>
                <b-row align-h="center">
                  <b-img v-if="cortina.design_cover_sample_url" :src="cortina.design_cover_sample_url" />
                  <div v-else class="d-flex align-items-center justify-content-center" style="width: 100px; height 50px">
                    <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-muted" fixed-width />
                  </div>
                </b-row>
              </div>
              <b-row v-else align-h="center">
                <p class="pt-5">Pend.</p>
              </b-row>
            </div>
          </b-row>
          <AlarmMessage field="design" />

          <!-- Add second design link -->
          <b-row v-if="!cortina.design_sec_id" align-h="center" class="mt-3 fs-14">
            <b-link @click="$store.commit('setCurrentStep', 'designSelector2')">
              Agregar Diseño Secundario
            </b-link>
          </b-row>
          <!-- Secondary design -->
          <b-row v-if="cortina.design_sec_id" class="mt-3">
            <div class="showcase w-100">
              <div class="showcase-title">Diseño Secundario:</div>
              <b-row align-h="center" class="pb-2">
                <div class="showcase-label">
                  {{ cortina.design_sec.item_code }}: {{ cortina.design_sec.name }}
                </div>
              </b-row>
              <b-row align-h="center">
                <b-img v-if="cortina.design_sec_cover_sample_url" :src="cortina.design_sec_cover_sample_url" />
                <div v-else class="d-flex align-items-center justify-content-center" style="width: 100px; height 50px">
                  <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-muted" fixed-width />
                </div>
              </b-row>
              <b-row align-h="between">
                <div class="text-muted" @click="deleteSecondaryDesign">
                  <div class="icon-container">
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </div>
                </div>
                <div class="text-muted" @click="$store.commit('setCurrentStep', 'designSelector2')">
                  <div class="icon-container">
                    <font-awesome-icon :icon="['far', 'pen']" fixed-width />
                  </div>
                </div>
              </b-row>
            </div>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label="Encargado Color" label-class="outer-label">
              <b-radio-group v-model="form.encargado_color" stacked @change="update">
                <b-radio value="Accesos color original">
                  Accesos color original (std)
                </b-radio>
                <b-radio value="Accesos color personalizado">
                  Accesos color personalizado
                </b-radio>
                <b-radio value="Cliente">
                  Cliente
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <b-row v-if="form.encargado_color != 'Cliente'" class="mt-5">
            <label class="outer-label">Color:</label>
          </b-row>
          <!-- Original color -->
          <b-container v-if="form.encargado_color === 'Accesos color original'" fluid>
            <b-row align-h="center">
              <div class="showcase w-100">
                <div class="showcase-title">Colores de Slat:</div>
                <b-row align-h="center">
                  <b-col cols="5" class="text-center">
                    <span v-if="cortina.color_ext" class="showcase-label">{{ cortina.color_ext.name }}</span>
                    <b-row v-if="cortina.color_ext" align-h="center">
                      <div class="color-sample-hex-md color-sample-border" :style="{ 'background-color': '#' + cortina.color_ext.hex_code }" />
                    </b-row>
                    <b-row v-else align-h="center" class="mt-4">
                      <div class="color-sample-hex-md color-sample-border" style="background-color: #FFF" />
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="text-center pt-1">
                    <b-img :src="slatImg" />
                  </b-col>
                  <b-col cols="5" class="text-center">
                    <span v-if="cortina.color_int" class="showcase-label">{{ cortina.color_int.name }}</span>
                    <b-row v-if="cortina.color_int" align-h="center">
                      <div class="color-sample-hex-md color-sample-border" :style="{ 'background-color': '#' + cortina.color_int.hex_code }" />
                    </b-row>
                    <b-row v-else align-h="center" class="mt-4">
                      <div class="color-sample-hex-md color-sample-border" style="background-color: #FFF" />
                    </b-row>
                  </b-col>
                </b-row>

                <b-row align-h="center" class="mt-4">
                  <Button v-if="cortina.color_ext" size="sm" @click="swapColors">
                    <font-awesome-icon :icon="['far', 'exchange']" fixed-width />
                    <span class="pl-1">Cambiar lados</span>
                  </Button>
                  <small v-else class="text-danger">Hay que seleccionar un diseño primero</small>
                </b-row>
              </div>
            </b-row>
            <b-row align-h="center" class="mt-4">
              <div class="showcase w-100">
                <div class="showcase-title">Color de Herraje:</div>
                <b-row align-h="center">
                  <div class="showcase-label">Gris (std)</div>
                </b-row>
                <b-row align-h="center">
                  <div class="color-sample-hex-md color-sample-border" style="background-color: #9C9C9C" />
                </b-row>
              </div>
            </b-row>
          </b-container>
          <!-- Personalized color -->
          <b-container v-if="form.encargado_color === 'Accesos color personalizado'" fluid>
            <b-row align-h="center" class="mt-4">
              <div class="showcase w-100">
                <div class="showcase-title">Color de Slat:</div>
                <b-row v-if="cortina.color" align-h="center">
                  <b-col cols="5" class="text-center">
                    <div class="showcase-label">{{ cortina.color.name }}</div>
                    <b-row v-if="cortina.color.tipo_color === 'Liso'" align-h="center">
                      <div class="color-sample-hex-md color-sample-border" :style="{ 'background-color': '#' + cortina.color.hex_code }" />
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="text-center pt-1">
                    <b-img :src="slatImg" />
                  </b-col>
                  <b-col cols="5" class="text-center">
                    <div class="showcase-label">{{ cortina.color.name }}</div>
                    <b-row v-if="cortina.color.tipo_color === 'Liso'" align-h="center">
                      <div class="color-sample-hex-md color-sample-border" :style="{ 'background-color': '#' + cortina.color.hex_code }" />
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-h="center" class="mt-3">
                  <Button variant="blue" @click="$store.commit('setCurrentStep', 'colorSelector')">
                    Selector
                  </Button>
                </b-row>
              </div>
            </b-row>

            <!-- todo: color herraje -->

            <b-row align-h="center" class="mt-5">
              <b-form-group label="Tipo de pintura" label-class="outer-label" class="w-100">
                <b-select v-model="form.tipo_pintura" :options="tipoPinturaOptions" @change="update" />
              </b-form-group>
            </b-row>
            <AlarmMessage field="tipo_pintura" />
          </b-container>

          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_design" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons is-first-step />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AlarmMessage from '../AlarmMessage'
import BlankIcon from '../BlankIcon'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
import Button from '../../../shared/Button'
import SlatImg from 'images/cortina/slat-profile.png'

export default {
  name: 'CortinaDesign',
  components: { AlarmMessage, BlankIcon, ConfiguratorStepButtons, Button },
  data () {
    return {
      form: {
        encargado_color: 'Accesos color original',
        tipo_pintura: 'Mono capa',
        notes_design: ''
      },
      tipoPinturaOptions: [
        'Mono capa',
        'Pintura en polvo',
        'Otro (detallar)'
      ],
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId']),
    slatImg () { return SlatImg }
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.encargado_color = this.cortina.encargado_color
    this.form.tipo_pintura = this.cortina.tipo_pintura
    this.form.notes_design = this.cortina.notes_design
    this.ready = true
  },
  methods: {
    update () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: this.form
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    },
    deleteSecondaryDesign () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: {
          design_sec_id: null
        }
      })
        .then(res => this.$store.commit('setConfigurable', res.data))
        .finally(this.processing = false)
    },
    swapColors () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: {
          color_ext_id: this.cortina.color_int_id,
          color_int_id: this.cortina.color_ext_id
        }
      })
        .then(res => this.$store.commit('setConfigurable', res.data))
        .finally(this.processing = false)
    }
  }
}
</script>
