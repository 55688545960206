import { Controller } from "stimulus"
import Popper from 'popper.js'

export default class extends Controller {
  static targets = ["spinner", "searchIcon", "popper", 'anchor', 'overlay', 'input']

  connect() {
    document.addEventListener('turbolinks:before-cache', this.close());
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('d-none');
    this.searchIconTarget.classList.add('d-none');
  }

  showResults() {
    var popper = this.popperTarget
    new Popper(this.anchorTarget, popper, {
        placement: 'bottom'
    });

    popper.classList.remove('d-none');
    this.overlayTarget.classList.remove('d-none');
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('d-none');
    this.searchIconTarget.classList.remove('d-none');
  }

  close() {
    this.overlayTarget.classList.add('d-none');
    this.popperTarget.classList.add('d-none');
    this.inputTarget.value = "";
  }

  fillForm(event) {
    var productCode = event.currentTarget.dataset.code;
    var productId = event.currentTarget.dataset.id;
    document.getElementById('fill-code').value = productCode;
    document.getElementById('fill-id').value = productId;
    this.close();
  }
}

