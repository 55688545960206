export const divisions = [
  { value: 'Desarrollo Organizacional', text: 'Desarrollo Organizacional' },
  { value: 'Distribución', text: 'Distribución' },
  { value: 'Fabricaciones', text: 'Fabricaciones' },
  { value: 'Finanzas', text: 'Finanzas' },
  { value: 'General', text: 'General' },
  { value: 'Guanacaste', text: 'Guanacaste' },
  { value: 'Investigación y Desarrollo', text: 'Investigación y Desarrollo' },
  { value: 'Mercadeo', text: 'Mercadeo' },
  { value: 'Operaciones', text: 'Operaciones' },
  { value: 'Producción', text: 'Producción' },
  { value: 'Ventas', text: 'Ventas' }
]
