import Vue from 'vue';

Vue.filter('dollarize2', function (value) {
  value = Number(value);
  var formatter = new Intl.NumberFormat('en-US', {
    // no dollar sign
    // style: 'currency',
    currency: 'USD',
  });
  return `USD ${formatter.format(value)}`
})
