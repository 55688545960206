<template>
  <div>
    <ContentView class="pb-5">
      <Subheader title="Tienda Online: Cotizaciones" />
      <!-- <SearchBar
        placeholder="Buscar por ID"
        @search-triggered="search"
        @reset="resetSearch"
      /> -->
      <b-row class="mt-4">
        <b-table
          hover
          outlined
          responsive
          head-variant="light"
          show-empty
          empty-text="No resultados"
          :items="quotes"
          :fields="fields"
          class="fs-13"
          @row-clicked="onRowClick"
        >
          <!-- Table columns -->
          <template  v-slot:cell(total)="data">{{ data.value | dollarize }}</template>
          <template  v-slot:cell(created_at)="data">{{ data.value | formatDateTime }}</template>
        </b-table>
      </b-row>
      <Observer v-if="!loading" @intersect="intersected" />
      <SpinnerHorizontal v-if="addingPage" />
    </ContentView>

    <!-- Detail modal -->
    <b-modal v-model="detailModalShow" size="lg" centered hide-footer @hide="selectedQuote = null">
      <template #modal-title>
        <span v-if="selectedQuote">Cotización {{ selectedQuote.quote_id }}</span>
      </template>
      <b-container>
        <b-row>
          <b-table-simple class="border-bottom fs-13">
            <b-thead>
              <b-tr>
                <b-th>Cant.</b-th>
                <b-th>Código</b-th>
                <b-th>Descripción</b-th>
                <b-th>Precio</b-th>
                <b-th>Subtotal</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="selectedQuote">
              <b-tr v-for="item in selectedQuote.quote_items" :key="item.id">
                <b-td>{{ item.quantity }}</b-td>
                <b-td>{{ item.product.item_code }}</b-td>
                <b-td>{{ item.product.description }}</b-td>
                <b-td>{{ item.retail_price | dollarize }}</b-td>
                <b-td>{{ item.quantity * item.retail_price | dollarize }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-row>
        <b-row align-h="between" class="px-3">
          <span v-if="selectedQuote" class="fs-12">{{ selectedQuote.created_at | formatDateTime }}</span>
          <div class="fs-18">
            <span class="pr-2">Total:</span>
            <span v-if="selectedQuote">{{ selectedQuote.total | dollarize }}</span>
          </div>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentView from '../shared/ContentView';
import Observer from '../shared/Observer';
import SearchBar from '../shared/SearchBar';
import SpinnerHorizontal from '../shared/SpinnerHorizontal';
import Subheader from '../shared/Subheader';

export default {
  name: 'QuoteIndex',
  components: { ContentView, Observer, SearchBar, SpinnerHorizontal, Subheader },
  data() {
    return {
      quotes: [],
      searchTerm: '',
      detailModalShow: false,
      selectedQuote: null,
      loading: false,
      processing: false,
      addingPage: false,
      // pagination
      page: 1,
      count: null,
      last: null,
      fields: [
        { key: 'quote_id', label: 'ID', tdClass: 'clickable' },
        { key: 'total', tdClass: 'clickable' },
        { key: 'created_at', label: 'Fecha de creación', thClass: 'text-right', tdClass: 'clickable text-right' }
      ],
    }
  },
  mounted() {
    this.loading = true;
    this.fetchOnlineStoreQuotes();
  },
  methods: {
    fetchOnlineStoreQuotes() {
      this.$http.get('/api/online_store/quotes', {
          params: {
            q: this.searchTerm,
            page: this.page
          }
        })
        .then(response => {
          console.log(response)
          if (this.addingPage) {
            this.quotes = [...this.quotes, ...response.data.quotes];
          } else {
            this.quotes = response.data.quotes;
          }
          this.count = response.data.pagy.count;
          this.last = response.data.pagy.last;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.processing = false;
          this.addingPage = false;
        });
    },
    onRowClick(item) {
      if (this.processing) return;
      this.processing = true;
      this.$http.get(`/api/online_store/quotes/${item.id}`)
        .then(response => {
          console.log(response)
          this.selectedQuote = response.data;
          this.detailModalShow = true;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.processing = false);
    },
    // search(term) {
    //   this.page = 1;
    //   this.searchTerm = term;
    //   this.fetchProducts();
    // },
    resetSearch() {
      this.searchTerm = '';
    },
    intersected() {
      if (this.page + 1 <= this.last) {
        console.log('Adding next page')
        this.page++;
        this.addingPage = true;
        this.fetchOnlineStoreQuotes();
      }
    },
  }
}
</script>
