<template>
  <b-row align-h="between" class="custom-tabs mx-0">
    <b-link class="ml-3" @click="$router.go(-1)">
      <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
      <span class="pl-2">Atrás</span>
    </b-link>
    <ul>
      <li>
        <router-link to="/app/prod/proyectos" class="dash-nav-link">
          <font-awesome-icon :icon="['far', 'briefcase']" size="lg" class="mr-1"/>
          <span>{{ projectTitle }}</span>
        </router-link>
      </li>
      <li>
        <router-link to="/app/prod/cotizador" class="dash-nav-link">
          <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" class="mr-1" />
          <span >Nueva Cotización</span>
        </router-link>
      </li>
      <li>
        <router-link to="/app/prod/cotizaciones" class="dash-nav-link">
          <font-awesome-icon :icon="['fas', 'calculator']" size="lg" class="mr-1"/>
          <span>{{ quoteTitle }}</span>
        </router-link>
      </li>
    </ul>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'QuoteNavTabs',
  computed: {
    ...mapGetters(['isProductLeader', 'isSupervisor']),
    quoteTitle() {
      if (this.isSupervisor || this.isProductLeader) {
        return 'Cotizaciones'
      } else {
        return 'Mis Cotizaciones'
      }
    },
    projectTitle () {
      if (this.isSupervisor || this.isProductLeader) {
        return 'Proyectos'
      } else {
        return 'Mis Proyectos'
      }
    }
  },
}
</script>

