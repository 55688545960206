import Vue from 'vue';
import TurbolinksAdapter from 'vue-turbolinks';
import QuoteGenerator from '../components/isolated/QuoteGenerator.vue';
import Rails from '@rails/ujs';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.use(TurbolinksAdapter);

import { AlertPlugin } from 'bootstrap-vue'
import { ButtonPlugin } from 'bootstrap-vue'
import { LayoutPlugin } from 'bootstrap-vue'
import { ListGroupPlugin } from 'bootstrap-vue'
import { PopoverPlugin } from 'bootstrap-vue'
Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)
Vue.use(LayoutPlugin)
Vue.use(ListGroupPlugin)
Vue.use(PopoverPlugin)

// Axios
import axios from 'axios';
Vue.use({
  install (Vue) {
    const instance = axios.create();
    instance.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        console.log('401 error intercepted!!!')
        Rails.ajax({
          url: '/timeout',
          type: 'GET',
          dataType: 'script',
          success: function() { window.location.href = '/accounts/sign_in' }
        });
      }
      return error;
    });
    Vue.prototype.$http = instance;
  }
});

document.addEventListener("turbolinks:load", () => {
  const el = document.getElementById("quote-generator-app");
  if (el != null) {
    new Vue({
      el,
      template: "<QuoteGenerator :currentUser='currentUser' :acceso='acceso' :accesoType='accesoType' :accesoTypePlural='accesoTypePlural' :rate='rate' />",
      components: { QuoteGenerator },
      data: {
        currentUser: JSON.parse(el.dataset.currentUser),
        acceso: JSON.parse(el.dataset.acceso),
        accesoType: JSON.parse(el.dataset.accesoType),
        accesoTypePlural: JSON.parse(el.dataset.accesoTypePlural),
        rate: el.dataset.rate
      }
    });
  }
});
