<template>
  <div>
    <QuoteNavTabs />
    <div class="py-3 px-5">
      <b-row class="pl-2">
        <b-col lg="4" class="mb-2 mb-lg-0">
          <b-row style="margin-bottom: 2.5em;">
            <span v-if="isSupervisor" class="font-lato fs-20">Historial de Cotizaciones</span>
            <span v-else class="font-lato fs-20">Mis Cotizaciones</span>
          </b-row>
          <b-row>
          <!-- Search Bar -->
          <b-row v-if="scope == 'for_accesos'" class="pl-2">
            <b-form-group style="width: 290px">
              <b-input-group size="sm">
                <b-input
                  v-model="searchTerm"
                  autofocus
                  placeholder="Buscar por código, cliente, titulo, # SAP"
                  @keyup.enter="search"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" size="sm" @click="search">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group class="ml-3">
              <b-button variant="outline-secondary" size="sm" @click="resetSearch">
                <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
              </b-button>
              <b-button variant="outline-primary" size="sm" v-if="queryMode && searchTerm" @click="searchSapQuotes = true">Buscar en SAP</b-button>
            </b-form-group>
          </b-row>
          </b-row>
        </b-col>
      </b-row>

      <!-- Table caption -->
      <b-row align-h="between" align-v="center" class="mb-1">
        <b-col class="pl-1">
          <div v-if="count" class="fs-12">
            <span v-if="queryMode" class="text-muted">{{ count.toLocaleString() }} resultados</span>
            <span v-else class="text-info">{{ count.toLocaleString() }} cotizaciones en total</span>
          </div>
        </b-col>
        <b-col class="text-right pr-0">
          <b-checkbox v-model="hideIgnored" size="sm" inline>
            Ocultar ignoradas
          </b-checkbox>
          <b-checkbox v-model="hideClosed" size="sm" inline class="mr-2">
            Ocultar cerradas
          </b-checkbox>
        </b-col>
      </b-row>
    </div>

    <!-- Main Table -->
    <b-row align-v="center" class="mb-1 mx-0">
      <b-table
        :fields="tableFields"
        :items="quotes"
        head-variant="dark"
        fixed
        hover
        responsive
        show-empty
        no-local-sorting
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :tbody-tr-class="rowClass"
        class="fs-12"
        @sort-changed="sortColumn"
      >

        <!-- Loading spinner / empty search text -->
        <template #empty>
          <div v-if="loading" class="text-center text-info">
            <b-spinner small type="grow"></b-spinner>
            <span class="pl-2">Cargando datos...</span>
          </div>
          <div v-else class="text-center text-danger">
            <span>No hay resultados</span>
          </div>
        </template>

        <!-- Table data -->
        <template v-slot:cell(user_id)="data">
          <span
            v-if="isSupervisor"
            :class="{'text-danger': data.item.user.status == 'inactivo'}"
            @click="onRowClick(data.item)"
          >
            {{ data.item.user | concatName2 | titleize }}
          </span>
        </template>

        <template v-slot:cell(quote_id)="data">
          <!-- <span @click="onRowClick(data.item)">{{ data.value }}</span> -->
          <b-link :to="`/app/prod/cotizador/${data.item.id}`">
            <span>{{ data.value }}</span>
          </b-link>
        </template>

        <template v-slot:cell(client_name)="data">
          <div @click="onRowClick(data.item)">
            <span v-if="data.value">{{ data.value }}</span>
            <span v-else class="text-warning">
              <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
              <span>Falta Cliente</span>
            </span>
          </div>
        </template>

        <template v-slot:cell(title)="data">
          <span @click="onRowClick(data.item)">{{ data.value }}</span>
        </template>

        <template v-slot:cell(total)="data">
          <span @click="onRowClick(data.item)">{{ data.value | dollarize }}</span>
        </template>

        <template v-slot:cell(status)="data">
          <b-badge
            v-if="data.item.is_open"
            pill
            :variant="data.value"
            class="clickable"
            style="font-size: 90%; width: 100px"
            @click="statusModalShow = true; openEditModal(data)"
          >
            <span>{{ data.value | titleize }}</span>
            <font-awesome-icon :icon="['fas', 'caret-down']" />
          </b-badge>
          <b-badge
            v-else
            pill
            variant="secondary"
            style="font-size: 90%; width: 100px"
          >
            <span>Cerrada</span>
          </b-badge>
        </template>

        <template v-slot:cell(created_at)="data">
          <span @click="onRowClick(data.item)">{{ data.value | formatDate }}</span>
        </template>

        <template v-slot:cell(show_details)="row">
          <Button size="sm" style="width: 25px" @click="showRowDetails(row)">
            <font-awesome-icon v-if="row.detailsShowing" :icon="['fas', 'caret-down']" size="lg" fixed-width />
            <font-awesome-icon v-else :icon="['fas', 'caret-right']" size="lg" fixed-width />
          </Button>
        </template>

        <template v-slot:row-details="row">
          <span class="d-none">{{row}}</span>
          <b-row v-if="selectedQuoteItems" class="px-5">
            <b-table
              :items="quoteItems"
              :fields="subfields"
              primary-key="sap_line_num"
              head-variant="light"
              show-empty
              small
              class="fs-12 px-5"
              >
              <!-- Empty -->
              <template #empty>
                <div v-if="loadingItems" class="text-center text-info">
                  <b-spinner small type="grow"></b-spinner>
                  <span class="pl-2">Cargando items...</span>
                </div>
                <div v-else class="text-center text-danger">
                  <span>Oferta sin artículos</span>
                </div>
              </template>
              <!-- Sap price -->
              <template #cell(sap_price)="data">
                <span v-if="data.item.line_type == 'item'">{{data.item.item_price | dollarize}}</span>
              </template>
              <!-- Item Code -->
              <template #cell(item_code)="data">
                <span v-if="data.item.line_type == 'item'">{{data.item.product.item_code}}</span>
              </template>
              <!-- Item Description -->
              <template #cell(item_description)="data">
                <span v-if="data.item.line_type == 'item'">{{data.item.product.description}}</span>
                <span v-else class="font-weight-bold text-secondary"> {{ data.item.line_text }} </span>
              </template>
              <!-- quantity -->
              <template #cell(quantity)="data">
                <span v-if="data.item.line_type == 'item'">{{data.item.quantity}}</span>
              </template>
            </b-table>
          </b-row>
        </template>
      </b-table>
    </b-row>

    <!-- Page tab-->
    <div class="text-center my-3">
      <PaginationCountless :page="page" :next="next" :prev="prev">
        <template #prev-label>
          <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
        </template>
        <template #next-label>
          <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
        </template>
      </PaginationCountless>
    </div>

    <!-- Quote SAP modal -->
    <QuotesSapModal
      :search-term= searchTerm
      @modal-hidden="onModalHide"
      @update-search="updateSearchTerm"
    />

    <!-- Confirm modal  -->
    <b-modal
      v-model="searchSapQuotes"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'question-circle']"  class="text-warning modal-icon" />
      </b-row>
      <b-row align-h="center">
        <b class="fs-20">¿Desea buscar la cotización en SAP?</b>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center">NOTA: En caso de que este seguro que la cotización buscada no se encuentra en XYZ, presione 'Continuar'</small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="searchSapQuotes = false">Cancelar</Button>
        <Button variant="green" @click="searchInSap">Continuar</Button>
      </b-row>
    </b-modal>

    <!-- Status Modal -->
    <b-modal lazy v-model="statusModalShow">
      <template #modal-title>
        <h5 v-if="selectedQuote">Cambiar status de Cotización "{{ selectedQuote.quote_id }}"</h5>
      </template>
      <b-container class="px-3 fs-14">
        <b-row align-h="center" class="my-3">
          <b-col lg="6" cols="7" class="border-right">
            <b-radio-group v-model="form.status" stacked>
              <b-radio value="baja">Baja probabilidad</b-radio>
              <b-radio value="media">Media probabilidad</b-radio>
              <b-radio value="alta">Alta probabilidad</b-radio>
              <b-radio value="licitación">Licitación</b-radio>
              <b-radio v-if="selectedQuote && selectedQuote.is_open" value="ignorar">Ignorar</b-radio>
            </b-radio-group>
          </b-col>
          <b-col lg="6" cols="5" class="pr-0 text-info">
            <small v-if="form.status === 'baja'">Baja probabilidad (menos de 50%)</small>
            <small v-if="form.status === 'media'">Media probabilidad (50 a 79%)</small>
            <small v-if="form.status === 'alta'">Alta probabilidad (más de 80%)</small>
            <small v-if="form.status === 'licitación'">Licitación Construcción</small>
            <small v-if="form.status === 'ignorar'">Ignorar esta cotización por cualquier razón. No se sincronizará con SAP</small>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <Button variant="green" class="float-right" :disabled="!statusFormValid" @click="updateQuote">
          Actualizar
        </Button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentView from '../shared/ContentView';
import PaginationCountless from '../shared/PaginationCountless';
import QuoteNavTabs from './QuoteNavTabs';
import QuotesSapModal from './QuotesSapModal';
import Button from '../shared/Button.vue'

export default {
  name: 'QuoteIndex',
  components: { ContentView, PaginationCountless, QuoteNavTabs, QuotesSapModal, Button},
  data() {
    return {
      quotesItem: [],
      page: 1,
      next: null,
      prev: null,
      count: null,
      searchTerm: '',
      queryMode: false,
      sortBy: 'created_at',
      sortDesc: true,
      scope: 'for_accesos',
      hideIgnored: true,
      hideClosed: false,
      searchSapQuotes: false,
      fields: [
        { key: 'show_details', label: '', tdClass: 'text-center', thStyle: { width: '10px' } },
        { key: 'quote_id', label: 'ID', tdClass: 'clickable', thStyle: { width: '95px' } },
        { key: 'client_name', label: 'Cliente', tdClass: 'clickable', thStyle: { width: '270px' } },
        { key: 'title', label: 'Titulo', tdClass: 'clickable', thStyle: { width: '50px' }  },
        // { key: 'project', label: 'Proyecto', tdClass: 'clickable' },
        { key: 'total', label: 'Monto', sortable: true, tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '55px' } },
        { key: 'status', tdClass: 'clickable text-center', thClass: 'text-center', thStyle: { width: '60px' }  },
        { key: 'created_at', label: 'Creado en', sortable: true, tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '60px' } }
      ],
      supervisorFields: [
        { key: 'show_details', label: '', tdClass: 'text-center', thStyle: { width: '10px' } },
        { key: 'user_id', label: 'Asesor',  sortable: true, tdClass: 'clickable', thStyle: { width: '70px' } },
        { key: 'quote_id', label: 'ID', tdClass: 'clickable', thStyle: { width: '50px' } },
        { key: 'client_name', label: 'Cliente', tdClass: 'clickable text-truncate', thStyle: { width: '270px' } },
        { key: 'title', label: 'Titulo', tdClass: 'clickable', thStyle: { width: '95px' }  },
        // { key: 'project', label: 'Proyecto', tdClass: 'clickable' },
        { key: 'total', label: 'Monto', sortable: true, tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '55px' } },
        { key: 'status', tdClass: 'clickable text-center', thClass: 'text-center', thStyle: { width: '60px' }  },
        { key: 'created_at', label: 'Creado en', sortable: true, tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '60px' } }
      ],
      subfields: [
        { key: 'item_code', label: 'Código', tdClass: 'align-middle  text-center font-weight-bold', thClass: 'text-center', thStyle: { width: '70px' } },
        { key: 'item_description', label: 'Descripción', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '300px' }},
        { key: 'quantity', label: 'Cantidad', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '20px' }},
        { key: 'sap_price', label: 'Precio total', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '70px' }}
      ],
      form: {
        status: null
      },
      loading: false,
      loadingItems: false,
      processing: false,
      noScroll: false,
      statusModalShow: false,
      selectedQuote: null,
      selectedQuoteItems: null,
      selectedQuoteIndex: null
    }
  },
  computed: {
    ...mapGetters(['isSupervisor']),
    tableFields() {
      return this.isSupervisor ? this.supervisorFields : this.fields;
    },
    statusFormValid () {
      if (this.form.status && this.form.status != this.selectedQuote.status) {
        return true
      } else {
        return false;
      }
    },
    quotes() {
      let quotes = this.quotesItem
      if (quotes) {
        quotes = this.quotesItem.map(quote => {
          this.$set(quote, '_showDetails', false);
          return quote
        })
      }
      return quotes
    },
    quoteItems () {
      if (this.selectedQuoteItems) {
        let items = this.selectedQuoteItems
        items.map(item => {
          this.$set(item, 'item_price', this.listedPrice(item) * item.quantity);
          return item
        })
        return items.sort((a,b) => a.position - b.position)
      }
    }
  },
  watch: {
    '$route.query' (to, from) {
      this.fetchPaginatedQuotes();
    },
    scope(to, from) {
      this.fetchPaginatedQuotes();
    },
    hideIgnored(to, from) {
      this.noScroll = true;
      this.fetchPaginatedQuotes();
    },
    hideClosed(to, from) {
      this.noScroll = true;
      this.fetchPaginatedQuotes();
    }
  },
  mounted() {
    this.noScroll = true;
    this.fetchPaginatedQuotes();
  },
  methods: {
    fetchPaginatedQuotes() {
      this.loading = true;
      this.$http.get('/api/production/quotes', {
          params: {
            page: this.$route.query.pagina,
            q: this.searchTerm,
            scope: this.scope,
            hide_ignored: this.hideIgnored,
            hide_closed: this.hideClosed,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc
          }
        })
        .then(response => {
          // console.log(response)
          this.quotesItem = response.data.quotes;
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.pagy.count;
          if (!this.noScroll) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.noScroll = false;
        });
    },
    updateQuote() {
      if (this.processing) return;
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.selectedQuote.id}`, {
          quote: {
            status: this.form.status
          }
        })
        .then(response => {
          // console.log(response)
          // TODO:  consolidate
          this.quotesItem[this.selectedQuoteIndex].status = response.data.status
        })
        .catch(error => {
          console.log(error)
          // TODO:  alert
        })
        .finally(() => {
          this.processing = false
          this.statusModalShow = false
          this.selectedQuoteIndex = null
          this.selectedQuote = null
          this.form.status = null
        });
    },
    onRowClick(item) {
      this.$router.push(`/app/prod/cotizador/${item.id}`);
    },
    search() {
      if (this.processing) { return }
      this.processing = true;
      let query = Object.assign({}, this.$route.query);
      delete query.pagina;
      this.$router.replace({ query })
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.sortBy = 'created_at';
      this.sortDesc = true;
      this.$http.get('/api/production/quotes', {
          params: {
            q: this.searchTerm.trim(),
            scope: this.scope,
            hide_ignored: this.hideIgnored,
            hide_closed: this.hideClosed,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc
          }
        })
        .then(response => {
          this.queryMode = true;
          // console.log(response)
          this.quotesItem = response.data.quotes;
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.pagy.count;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.processing = false);
    },
    resetSearch() {
      this.queryMode = false;
      this.searchTerm = '';
      this.sortBy = 'created_at';
      this.sortDesc = true;
      this.$router.push('/app/prod/cotizaciones')
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.fetchPaginatedQuotes();
    },
    sortColumn(ctx) {
      if (ctx.sortBy) {
        this.noScroll = true;
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc;
        this.fetchPaginatedQuotes();
      }
    },
    rowClass(item, type) {
      if (!item) return
      if (item.status === 'ignorar') return 'ignored-row'
    },
    searchInSap () {
      this.searchSapQuotes = false
      this.$bvModal.show('quotes-sap-modal')
    },
    updateSearchTerm (value) {
      this.searchTerm = value
    },
    onModalHide() {
      this.searchTerm = ''
      this.$bvModal.hide('quotes-sap-modal')
    },
    openEditModal(data) {
      this.selectedQuote = data.item
      this.form.status = data.item.status
      this.selectedQuoteIndex = data.index
    },
    showRowDetails(row){
      if (!row.detailsShowing) {
        this.selectedQuote = row.item
        if (this.processing) return;
        this.processing = true
        this.loadingItems = true
        this.$http.get(`/api/production/quotes/${row.item.id}`)
          .then(response => {
            this.selectedQuoteItems = response.data.quote.quote_items
            this.quotes.forEach(quote => { row.item.id ===  quote.id ? quote._showDetails = true : quote._showDetails = false})
          })
          .catch(e => {
            console.log(e)
            this.quotes[row.index]._showDetails = false
          })
          .finally(() => {
            this.processing = false
            this.loadingItems = false
          });
      } else {
        this.quotes[row.index]._showDetails = false
        this.selectedQuote = null
        this.selectedQuoteItems = null
      }

    },
    productPrice(price, price_currency) {
      if (!price || !price_currency) { return 0 }
      if (price_currency === 'COL') {
        return price / this.selectedQuote.usd_crc_rate
      } else {
        return price
      }
    },
    listedPrice (quoteItem) {
      if (quoteItem.line_type === 'text') { return 0 }
      if (quoteItem.is_custom) {
        return quoteItem.custom_dollars
      } else {
        if (this.selectedQuote.pricing_mode === 'público') {
          return this.productPrice(quoteItem.retail_price, quoteItem.price_currency)
        } else if (this.selectedQuote.pricing_mode === 'instalador') {
          return this.productPrice(quoteItem.installer_price, quoteItem.installer_price_currency)
        } else if (this.selectedQuote.pricing_mode === 'mayorista') {
          return this.productPrice(quoteItem.wholesale_price, quoteItem.wholesale_price_currency)
        }
      }
    }
  }
}
</script>

<style scoped>
.form-control-sm {
  font-size: 12px !important;
  line-height: 2 !important;
}
</style>
