$( document ).on('turbolinks:load', function() {
    // To prevent form submittal via Enter key
    $('.wizard-form').on('keyup keypress', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });

    // Form validations
      // Selects
    $('.validate-select select').each(function() {
      if ($(this).val()) {
        $(this).siblings('.fa-exclamation-triangle').addClass('invisible');
      }
    });

    $('.validate-select select').on('change', function() {
      if ($(this).val()) {
        $(this).siblings('.fa-exclamation-triangle').addClass('invisible');
      } else {
        $(this).siblings('.fa-exclamation-triangle').removeClass('invisible');
      }
    });

      // Thumbs
    $(".validate-thumbs input[type='radio']").each(function() {
      if ($(this).is(':checked')) {
        $(this).closest('.validate-thumbs').find('.fa-exclamation-triangle').addClass('invisible');
      }
    });
    $(".validate-thumbs input[type='radio']").on('change', function() {
      $(this).closest('.validate-thumbs').find('.fa-exclamation-triangle').addClass('invisible');
    });

      // Radios
    $(".validate-radio input[type='radio']").each(function() {
      if ($(this).is(':checked') && !$(this).hasClass('nil-radio')) {
        $(this).closest('.validate-radio').find('.fa-exclamation-triangle').addClass('invisible');
      }
    });

    $(".validate-radio input[type='radio']").on('click', function() {
      $(this).closest('.validate-radio').find('.fa-exclamation-triangle').addClass('invisible');
    });

      // Checkboxes
    $(".validate-check input[type='checkbox']").each(function() {
      if ($(this).is(':checked')) {
        $(this).closest('.validate-check').find('.fa-exclamation-triangle').addClass('invisible');
      }
    });

    $(".validate-check input[type='checkbox']").on('change', function() {
      if ($(".validate-check input[type='checkbox']:checked").length) {
        $(this).closest('.validate-check').find('.fa-exclamation-triangle').addClass('invisible');
      } else {
        $(this).closest('.validate-check').find('.fa-exclamation-triangle').removeClass('invisible');
      }
    });

      // Table-radios
    $(".validate-table input[type='radio']").each(function() {
      if ($(this).is(':checked') && !$(this).hasClass('nil-radio')) {
        $(this).closest('td').siblings('th').find('.fa-exclamation-triangle').addClass('invisible');
      }
    });
    $(".validate-table input[type='radio']").on('click', function() {
      $(this).closest('td').siblings('th').find('.fa-exclamation-triangle').addClass('invisible');
    });

    // radio-clear button
    $('.radio-clear').on('click', function() {
      $(this).siblings('.row').find('.nil-radio').prop('checked', true);
      $(this).siblings('.fa-exclamation-triangle').removeClass('invisible');
      $(this).closest('td').siblings('th').find('.fa-exclamation-triangle').removeClass('invisible');
    });

    // Modal forms, for showing errors in modal (wiring)
    $('.modal-form').on('ajax:error', function(event) {
      var errors = event.detail[0]; // errors is array
      var newHTML = []
      for (var i = 0; i < errors.length; i++) {
        newHTML.push('<span>' + errors[i] + '</span><br>');
      }
      $('#modal-errors').html(newHTML.join(""));
      $('#modal-errors').addClass('alert alert-danger')
    });

    // Side-menu functionality
    $('#navbarSideButton').on('click', function(){
      $('#navbarSide').addClass('reveal');
      $('.overlay').show();
    });
    $('.overlay').on('click', function(){
      $('#navbarSide').removeClass('reveal');
      $('.overlay').hide();
    });

    // Info popovers
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'focus'
    });

    // Spinner
    $('.spinner').hide();
    $('.submit input[type=submit], .submit a').on('click', function() {
      $(this).closest('.submit').hide().next('.spinner').show();
    });

    // Clickable rows in directories
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });

    // Section-jump links for directories
    $(".section-jump").on('click', function(event) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){
          window.location.hash = hash;
        });
      }
    });

    // File uploads - see file name
    $('.custom-file-input').on('change', function() {
      $('.custom-file-label').html($(this).val().replace("C:\\fakepath\\", ""));
    });

    // Show active state for custom radio image on click and blur rest
    $('.custom-radio-image').on('click', function() {
      $(this).addClass('active').removeClass('blurred');
      $(this).siblings().addClass('blurred').removeClass('active');
    });

    // Colorpicker  - Can be moved to simulus controller
    $('.color-finish-toggle').on('click', function() {
      $(this).addClass('active').siblings().removeClass('active');
      var tipo_color = $(this).text();
      $('#' + tipo_color).show().siblings().hide();
    });
    $('.color-radio').each(function() {
      if ($(this).is(':checked')) {
        var tipo_color = $(this).siblings('.hidden-tipo-color').text();
        var tipo_acabado = $(this).siblings('.hidden-tipo-acabado').text();
        $('#' + tipo_acabado).show().siblings().hide();
        $('#toggle-' + tipo_acabado).addClass('active').siblings().removeClass('active');
        $('#' + $(this).val()).find('i').show();
      }
    });

    // Tipo guia switch (Seccional / Cortina)
    $(".tipo-guia-switch").on('change', function() {
      if ($(this).is(':checked')) {
        $('#' + $(this).val().toLowerCase().replace(/\s/g, "")).show().siblings().hide();
        $('.track-title').text($(this).val());
      }
    });
    $(".tipo-guia-switch").each(function() {
      if ($(this).is(':checked')) {
        $('#' + $(this).val().toLowerCase().replace(/\s/g, "")).show();
        $('.track-title').text($(this).val());
      }
    });
    $("#tipo-guia-cancel").on('click', function() {
      $('#se-track-diagrams').children().hide();   // seccional only
    });

    // Corredizo/Abatible design thumbnail and lamination logic
    $('.design-select-btn').on('click', function() {
      $(this).closest('.modal').modal('hide');  // JS error found 'modal is transitioning' bs4 alpha6 bug?
    });

    $('.design-radio').each(function() {
      if ($(this).is(':checked')) {
        var design_id = $(this).next('.hidden-id').text();
        $('#' + design_id).addClass('active').find('i').show();

        var lam = $(this).siblings('span.laminated-status').text();
        if (lam === 'true') {
          $('.already-laminated').prop('checked', true);
          $('.already-laminated').prop('disabled', false);
          $('.can-disable').prop('disabled', true);
        } else {
          $('.already-laminated').prop('disabled', true);
          $('.can-disable').prop('disabled', false);
        }
      }
    });

    $('.design-radio').on('change', function() {
      if ($(this).is(':checked')) {
        var design_id = $(this).next('.hidden-id').text();
        var design_name = $(this).siblings('.hidden-name').text();
        $('#' + design_id).addClass('active').siblings('.thumbnail').removeClass('active').find('i').hide();
        $('#' + design_id).find('i').show();
        $('.selected-design').text(design_name);

        var lam = $(this).siblings('span.laminated-status').text();
        if (lam === 'true') {
          $('.already-laminated').prop('checked', true);
          $('.already-laminated').prop('disabled', false);
          $('.can-disable').prop('disabled', true);
        } else {
          $('.not-laminated').prop('checked', true);
          $('.already-laminated').prop('disabled', true);
          $('.can-disable').prop('disabled', false);
        }
      }
    });

    // Motor scorecards
    $('.motor-radio').each(function() {
      if ($(this).is(':checked')) {
        var description = $(this).next('.motor-description').text();
        var motor_model = $(this).siblings('.motor-model').text();
        $(this).closest('.scorecard').addClass('active').find('i').show();
        var motor_transmission = $(this).siblings('.scorecard-header').find('.motor-trans').text()
        if (motor_transmission == 'Lateral') {
          $('#motor-position-field').show();
        }
        if (motor_transmission == 'Manual' && motor_model != 'MANUAL-CATALINA') {
          $('#manual-motor-alert').show();
          $('#non-manual-motor-inputs').hide();
        } else {
          $('#non-manual-motor-inputs').show();
        }
        $('.selected-motor-info').show();
        $('.selected-motor, .modal-motor-model').text(motor_model);  // this behavior doesn't work for seccionales now
        $('.selected-motor-description').text(description);
        var image_url = $(this).siblings('.scorecard-header').find('.motor-image').attr('src');
        $('.selected-motor-image').attr('src', image_url)
        if (description.length > 0) {
          $('.fa-info-circle').show();
        } else {
          $('.fa-info-circle').hide();
        }
      }
    });

    $('.motor-radio').on('change', function() {
      if ($(this).is(':checked')) {
        var description = $(this).next('.motor-description').text();
        var motor_model = $(this).siblings('.motor-model').text();
        $(this).siblings('.scorecard-header').find('i').show();
        var motor_transmission = $(this).siblings('.scorecard-header').find('.motor-trans').text()
        if (motor_transmission == 'Lateral') {
          $('#motor-position-field').show();
        } else {
          $('#motor-position-field').hide();
        }
        if (motor_transmission == 'Manual' && motor_model != 'MANUAL-CATALINA') {
          $('#manual-motor-alert').show();
          $('#non-manual-motor-inputs').hide();
        } else {
          $('#manual-motor-alert').hide();
          $('#non-manual-motor-inputs').show();
        }
        $(this).closest('.scorecard').addClass('active').siblings('.scorecard').removeClass('active').find('i').hide();
        $('.selected-motor-info').show();
        $('.selected-motor, .modal-motor-model').text(motor_model);
        $('.selected-motor-description').text(description);
        var image_url = $(this).siblings('.scorecard-header').find('.motor-image').attr('src');
        $('.selected-motor-image').attr('src', image_url)
        if (description.length > 0) {
          $('.fa-info-circle').show();
        } else {
          $('.fa-info-circle').hide();
        }
      }
    });

    // Show active state for pre-checked diagram switches (radios)  // PHASE OUT
    if ($('.diagram-switch:radio').is(':checked')) {
     $('.diagram-switch:checked').closest('label').addClass('active');
    }

    // If client is in charge of color, hide rest of color fields
    $('.encargado-color-radio').change(function() {
      if ($('.encargado-color-radio-cliente').is(':checked')) {
        $('.color-fields').hide();
      } else {
        $('.color-fields').show();
      }
    });
    if ($('.encargado-color-radio-cliente').is(':checked')) {
      $('.color-fields').hide();
    }

    // If receptor is needed then show control-remoto field
    $('.receptor-select').on('change', function() {
      if ($(this).val() !== 'No lleva' && $(this).val() !== '') {
        $('.control-remoto-field').show();
      } else {
        $('.control-remoto-input').val('');
        $('.control-remoto-field').hide();
      }
    });
    if ($('.receptor-select').val() !== 'No lleva' && $('.receptor-select').val() !== '') {
      $('.control-remoto-field').show();
    }

    // my-checkbox

    $('.my-checkbox label').on('click', function() {
      $(this).siblings("input[type='checkbox']").click();
    });

    // Notes sections
    $('.notes-label').on('click', function() {
      $('.notes-area').toggle('slow');
      $(this).find('.down').toggle().siblings('.left').toggle();
    });
    if ($.trim($('.notes-input').val())) {
      $('.notes-area').show();
      $('.left').hide();
      $('.down').show();
    }

    // Email pdf report toggle
    $('.email-toggle').on('click', function() {
      $(this).closest('.row').next('.email-report-section').toggle('slow');
    });

    // Abatibles only
    $('.sync-gi').change(function(){
      $('.sync-gi').val($(this).val());
    });
    $('.sync-gd').change(function(){
      $('.sync-gd').val($(this).val());
    });
  });
