import axios from 'axios';

const state = {
  profile: {},
  error: false
};

const actions = {
  fetchProfile({ commit }, userId) {
    axios.get(`/api/contractors/users/${userId}/fetch_profile`)
      .then(response => {
        commit('setProfileData', response.data);
      })
      .catch(error => {
        commit('setError');
      })
  }
};

const mutations = {
  setProfileData(state, profile) {
    state.profile = profile;
  },
  setError(state) {
    state.error = true;
  }
};

export default {
  state,
  actions,
  mutations
};

