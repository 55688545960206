<template>
  <div>
    <SubmitalNavTabs :configurableType="configurableTypeUrl" @nav-tab-clicked="loading = true" />
    <ContentView class="pb-5">
      <Subheader>
        <span>{{ configurableTypeUrl | titleize }}</span>
      </Subheader>
      <!-- Search Bar -->
      <b-row class="pl-2">
        <b-form-group style="width: 250px">
          <b-input-group size="sm">
            <b-input
              v-model="searchTerm"
              placeholder="Buscar por código o titulo"
              @keyup.enter="search"
            />
            <b-input-group-append>
              <b-button variant="outline-secondary" size="sm" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group class="ml-3">
          <b-button size="sm" @click="resetSearch">
            Reset
          </b-button>
        </b-form-group>
      </b-row>

      <b-row>
        <b-table
          :fields="fields"
          :items="submitals"
          hover
          responsive
          show-empty
          caption-top
          class="fs-12"
        >
          <!-- Table caption -->
          <template #table-caption>
            <div v-if="count" class="text-info pl-2">
              <span>{{ count.toLocaleString() }}</span>
              <span v-if="searching">resultados</span>
              <span v-else>{{ configurableTypeUrl.toLowerCase() }} en total</span>
            </div>
          </template>

          <!-- Loading spinner / empty search text -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span>No resultados</span>
            </div>
          </template>

          <!-- Table Data -->
          <template v-slot:cell(submital_no)="data">
            <b-link :to="`/app/prod/submitals/${data.item.id}`">{{ data.value }}</b-link>
          </template>

          <template v-slot:cell(title)="data">
            <b-link :to="`/app/prod/submitals/${data.item.id}`">{{ data.value }}</b-link>
          </template>

          <template v-slot:cell(progress)="data">
            <span :class="{ 'text-success': data.value == 100 }">{{ data.value }} %</span>
          </template>

          <template v-slot:cell(salesperson)="data">
            <span :class="{ 'text-danger': data.value.status == 'inactivo' }">{{ data.value.first_name }} {{ data.value.last_name }}</span>
          </template>

          <template v-slot:cell(created_at)="data">
            {{ data.value | formatDate }}
          </template>
        </b-table>
      </b-row>
      <Observer v-if="!loading" @intersect="intersected" />
      <SpinnerHorizontal v-if="addingPage" />
    </ContentView>
  </div>
</template>

<script>
import SubmitalNavTabs from './SubmitalNavTabs'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import Observer from '../shared/Observer'
import SearchBar from '../shared/SearchBar'
import SpinnerHorizontal from '../shared/SpinnerHorizontal'
import Subheader from '../shared/Subheader'

export default {
  name: 'SubmitalIndex',
  components: {
    SubmitalNavTabs,
    Button,
    ContentView,
    Observer,
    SearchBar,
    SpinnerHorizontal,
    Subheader
  },
  data () {
    return {
      submitals: [],
      searchTerm: '',
      fields: [
        { key: 'submital_no', label: 'ID', thStyle: { minWidth: '60px' }},
        { key: 'title', label: 'Título' },
        { key: 'progress', label: 'Progreso', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'client_name', label: 'Cliente' },
        { key: 'salesperson', label: 'Asesor' },
        { key: 'created_at', label: 'Creado en', thClass: 'text-right', tdClass: 'text-right' }
      ],
      page: 1,
      count: null,
      last: null,
      count: null,
      loading: false,
      processing: false,
      searching: false,
      addingPage: false,
      configurableTypeUrl: this.$route.params.configurable_type
    }
  },
  watch: {
    'configurableTypeUrl' () {
      this.page = 1
      this.searchTerm = ''
      this.searching = false
      this.fetchSubmitals()
    }
  },
  mounted () {
    this.loading = true
    this.fetchSubmitals()
  },
  methods: {
    fetchSubmitals () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/production/submitals', {
          params: {
            configurable_type_url: this.configurableTypeUrl,
            q: this.searchTerm,
            page: this.page
          }
        })
        .then((res) => {
          console.log(res)
          if (this.addingPage) {
            this.submitals = [...this.submitals, ...res.data.submitals]
          } else {
            this.submitals = res.data.submitals
          }
          this.count = res.data.pagy.count
          this.last = res.data.pagy.last
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
          this.processing = false
          this.addingPage = false
          this.searching = this.searchTerm ? true : false
        })
    },
    search () {
      this.page = 1
      this.fetchSubmitals()
    },
    resetSearch () {
      this.page = 1
      this.searchTerm = ''
      this.fetchSubmitals()
    },
    intersected () {
      console.log('Intersected')
      if (this.page + 1 <= this.last) {
        console.log('Adding next page')
        this.page++
        this.addingPage = true
        this.fetchSubmitals()
      }
    }
  }
}
</script>
