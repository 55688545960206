<template>
  <b-row align-h="center" class="mx-0" style="margin-top: 80px">
    <b-col lg="3">
      <!-- My Profile Card -->
      <SectionCard class="h-100">
        <b-row class="mb-3">
          <b-col>
            <span class="lead font-lato">Mi Perfil</span>
          </b-col>
        </b-row>
        <b-row align-h="center" class="mb-2">
          <div v-if="!profile && !previewRequired" class="logo-size">
            <font-awesome-icon :icon="['fas', 'user-circle']" size="4x" />
          </div>
          <div v-if="previewRequired" id="image-preview" />
          <div v-else-if="profile && profile.logo_url">
            <img :src="profile.logo_url" alt="logo" class="logo-size">
          </div>
        </b-row>
        <b-row align-h="center" id="uppy-target"></b-row>
        <table class="table table-borderless table-sm mt-4 fs-12">
          <tbody>
            <tr>
              <th>Nombre de empresa:</th>
              <td v-if="editProfileMode">
                <b-input v-model="form.company_name" size="sm" />
              </td>
              <td v-else>
                <span v-if="profile">{{ profile.company_name }}</span>
                <span v-else>...</span>
              </td>
            </tr>
            <tr>
              <th style="width: 160px">Cédula:</th>
              <td v-if="editProfileMode">
                <b-input v-model="form.cedula" size="sm" />
              </td>
              <td v-else>
                <span v-if="profile">{{ profile.cedula }}</span>
                <span v-else>...</span>
              </td>
            </tr>
            <tr>
              <th>Email:</th>
              <td v-if="editProfileMode">
                <b-input v-model="form.email" size="sm" />
              </td>
              <td v-else>
                <span v-if="profile">{{ profile.email }}</span>
                <span v-else>...</span>
              </td>
            </tr>
            <tr>
              <th>Teléfono:</th>
              <td v-if="editProfileMode">
                <b-input v-model="form.phone" size="sm" />
              </td>
              <td v-else>
                <span v-if="profile">{{ profile.phone }}</span>
                <span v-else>...</span>
              </td>
            </tr>
            <tr>
              <th>Fax:</th>
              <td v-if="editProfileMode">
                <b-input v-model="form.fax" size="sm" />
              </td>
              <td v-else>
                <span v-if="profile">{{ profile.fax }}</span>
                <span v-else>...</span>
              </td>
            </tr>
            <tr>
              <th>Ciudad:</th>
              <td v-if="editProfileMode">
                <b-input v-model="form.city" size="sm" />
              </td>
              <td v-else>
                <span v-if="profile">{{ profile.city }}</span>
                <span v-else>...</span>
              </td>
            </tr>
            <tr v-if="editProfileMode">
              <td colspan="2" class="text-center pt-4">
                <Button size="sm" class="mr-2" @click="editProfileMode = false">Cancelar</Button>
                <Button variant="green" size="sm" @click="saveProfile">Guardar</Button>
              </td>
            </tr>
          </tbody>
        </table>
        <b-row v-if="!editProfileMode" align-h="center">
          <Button size="sm" @click="editProfile">
            <font-awesome-icon :icon="['far', 'edit']" />
            <span class="pl-2">Editar Perfil</span>
          </Button>
        </b-row>
        <b-row align-h="center" align-v="center" class="text-muted mt-4 fs-11">
          <font-awesome-icon :icon="['far', 'info-circle']" size="sm" />
          <span class="pl-1">Estos datos aparecen en la cotización</span>
        </b-row>
      </SectionCard>
    </b-col>

    <b-col lg="7">
      <SectionCard class="mx-auto h-100">
        <SectionCardTitle>
          <font-awesome-icon :icon="['far', 'warehouse']" size="lg" fixed-width />
          <span class="pl-2 font-lato">Distribuidor App</span>
        </SectionCardTitle>
        <b-row>
          <b-col lg="6">
            <b-row align-h="center">
              <div class="app-btn">
                <b-link to="/app/dist/clientes">
                  <font-awesome-icon :icon="['far', 'address-book']" size="lg" fixed-width />
                  <span>Mis Clientes</span>
                </b-link>
              </div>
            </b-row>
            <b-row align-h="center" class="mt-4">
              <div class="app-btn">
                <b-link to="/app/dist/cotizaciones">
                  <font-awesome-icon :icon="['fas', 'calculator']" size="lg" fixed-width />
                  <span>Mis Cotizaciones</span>
                </b-link>
              </div>
            </b-row>
            <b-row align-h="center" class="mt-4 mb-3">
              <div class="app-btn">
                <b-link to="/app/prod/inventario">
                  <font-awesome-icon :icon="['far', 'warehouse-alt']" size="lg" fixed-width />
                  <span>Inventario</span>
                </b-link>
              </div>
            </b-row>
            <b-row v-if="isAtkAdvisor" align-h="center" class="mt-4 mb-3">
              <div class="app-btn">
                <b-link to="/app/prod/transacciones">
                  <font-awesome-icon :icon="['far', 'credit-card']" size="lg" fixed-width />
                  <span>App Tarjetas</span>
                </b-link>
              </div>
            </b-row>
          </b-col>
          <b-col lg="6">
            <RecentVisitsCard for-distributor />
          </b-col>
        </b-row>
      </SectionCard>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import AwsS3 from '@uppy/aws-s3'
import Button from '../shared/Button'
import RecentVisitsCard from '../shared/RecentVisitsCard'
import SectionCard from '../shared/SectionCard'
import SectionCardTitle from '../shared/SectionCardTitle'

export default {
  name: 'DistributorHome',
  components: { Button, RecentVisitsCard, SectionCard, SectionCardTitle },
  data () {
    return {
      form: {
        company_name: null,
        email: null,
        phone: null,
        fax: null,
        cedula: null,
        city: null
      },
      editProfileMode: false,
      previewRequired: false
    }
  },
  computed: {
    ...mapGetters(['isAtkAdvisor']),
    profile () {
      return this.$store.state.distributor ? this.$store.state.distributor.profile : null
    },
    loadError () {
      return this.$store.state.distributor ? this.$store.state.distributor.error : null
    }
  },
  mounted () {
    this.$store.dispatch('fetchProfile', this.$store.state.currentUser.id)

    const uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 10000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.JPG', '.JPEG', '.png', '.PNG']
      }
    })
    .use(FileInput, {
      target: '#uppy-target',
      replaceTargetContent: false,
      locale: {
        strings: {
          chooseFiles: 'Eligir logo'
        }
      }
    })
    uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 120,
      // thumbnailHeight: 200 // optional, use either width or height,
      waitForThumbnailsBeforeUpload: false
    })
    .use(AwsS3, {
      companionUrl: '/'
    })

    uppy.on('upload', () => {
      this.previewRequired = true
    })

    uppy.on('thumbnail:generated', (file, preview) => {
      const el = document.createElement('img')
      el.src = preview
      el.style.maxHeight = '80px'
      el.style.maxWidth = '120px'
      const anchor = document.getElementById('image-preview')
      anchor.appendChild(el)
    })

    uppy.on('upload-success', (file, response) => {
      // construct uploaded file data in the format that Shrine expects
      let uploadedFileData = JSON.stringify({
        id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })
      if (this.profile) {
        this.$http.put(`/api/contractors/distributor_profiles/${this.profile.id}`, {
          distributor_profile: {
            logo: uploadedFileData
          }
        })
          .then((res) => {
            console.log(res)
            this.$store.commit('setProfileData', res.data)
            /// Original code
            // show image preview
            // if (this.$refs.imagePreview) {
            //   this.$refs.imagePreview.src = URL.createObjectURL(file.data)
            // }
          })
          .catch(e => console.log(e))
      } else {
        this.$http.post(`/api/contractors/distributor_profiles`, {
          distributor_profile: {
            logo: uploadedFileData
          }
        })
          .then(res => this.$store.commit('setProfileData', res.data))
          .catch(e => console.log(e))
      }
    })

    uppy.on('complete', (result) => {
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    })
  },
  methods: {
    editProfile () {
      this.editProfileMode = true
      this.form.company_name = this.profile.company_name
      this.form.email = this.profile.email
      this.form.phone = this.profile.phone
      this.form.fax = this.profile.fax
      this.form.cedula = this.profile.cedula
      this.form.city = this.profile.city
    },
    saveProfile () {
      if (this.profile) {
        this.$http.put(`/api/contractors/distributor_profiles/${this.profile.id}`, {
          distributor_profile: this.form
        })
          .then(response => {
            console.log(response)
            this.$store.commit('setProfileData', response.data)
            this.editProfileMode = false
          })
          .catch(e => console.log(e))
      } else {
        this.$http.post(`/api/contractors/distributor_profiles`, {
          distributor_profile: this.form
        })
          .then(response => {
            console.log(response)
            this.$store.commit('setProfileData', response.data)
            this.editProfileMode = false
          })
          .catch(e => console.log(e))
      }
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 2em;
}
.card-header {
  color: #333;
  font-size: 14px;
  padding: 0.25rem 1.25rem;
  background-color: #ffdec1;
}
.app-btn {
  background-color: #fff;
  border: 1px solid #E3DBCE;
  height: 60px;
  width: 100%;
  text-align: center;
  margin: 0px 10px;
  line-height: 1;
  box-shadow: 0 0 3px #aaa;
}
.app-btn:hover {
  background-color: #ECF6FD;
}
.app-btn:active {
  background-color: #eee;
  box-shadow: 0 0 1px #aaa;
}
.app-btn svg {
  color: #495057;
}
.app-btn a {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 35px;
  display: flex;
  align-items: center;
}
.app-btn a span {
  font-size: 16px;
  padding-left: 10px;
}
.app-btn img {
  width: 50px;
  max-height: 45px;
  margin: auto;
  display: block;
  padding-bottom: 2px;
}
#uppy-target {
  font-size: 12px;
}
table th {
  vertical-align: middle;
  text-align: right;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}
</style>
