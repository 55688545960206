<template>
  <div v-if="canView">
    <PurchaseRequestNavsTabs/>
    <b-container fluid>
      <!-- header section -->
      <b-row class="mt-3 mb-4 pl-2 pl-sm-5">
        <b-col lg="9">
          <b-row>
            <b-col lg="6" class="pb-3 pb-lg-0">
              <b-table-simple small borderless class="mb-0">
                <b-tbody>
                  <b-tr class="fs-18">
                    <b-td class="text-right" style="width: 150px">
                      <font-awesome-icon :icon="['fas', 'hand-holding-box']" size="lg" />
                      <span class="pl-2">{{orderType | titleize}}:</span>
                    </b-td>
                    <b-td><span v-if="purchaseRequest">{{ purchaseRequest.purchase_internal_id }}</span></b-td>
                  </b-tr>
                  <b-tr v-if="isSupervisor" class="text-muted fs-14">
                    <b-td class="text-right">Solicitado por:</b-td>
                    <b-td><span v-if="purchaseRequest">{{ asesor | concatName2 | titleize }}</span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col lg="6" class="text-right">
              <!-- TODO: Include ButtonBar with diferrent options -->
              <PurchaseRequestButtonBar
                v-if="ready"
                :purchase-request="purchaseRequest"
                :current-supplier="supplier"
                @purchase-request-copied="copyPurchaseRequest"
                @process-failed="showAlertError"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- content section -->
      <b-row style="border-top: 3px solid #f2f2f2">
        <b-col lg="9" order="1" order-lg="1">
          <!-- Purchase Request template -->
          <b-row align-h="center" class="mb-5 px-0 px-sm-3 py-3">
            <b-col class="px-1 px-sm-3">
              <!-- Header -->
              <b-row class="px-2">
                <b-col sm="4" cols="6">
                  <img :src="accesosLogo" class="brand-logo">
                </b-col>
                <b-col sm="4" class="d-none d-sm-block fs-10 text-center">
                </b-col>
                <b-col sm="4" cols="6">
                  <b-container>
                    <b-row align-h="end" class="fs-14">
                      <span class="text-uppercase">{{orderType }} de compra</span>
                    </b-row>
                    <b-row align-h="end">
                      <span v-if="ready" class="font-weight-bold">{{ purchaseRequest.purchase_internal_id }}</span>
                    </b-row>
                    <b-row align-h="end" class="fs-10">
                      <span v-if="ready"> Creada: <b>{{ purchaseRequest.created_at | formatDateCompact }}</b></span>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>

              <!-- Supplier Data -->
              <b-container class="mt-4 mb-3">
                <b-row cols="1">
                  <b-col cols="12">
                    <b-row class="pl-3 mb-0" >
                      <b-col lg="2" cols="4" class="px-0 text-right text-muted" style="max-width: 5rem">
                        <span class="mr-3">Proveedor:</span>
                      </b-col>
                      <b-col lg="10" cols="8" class="pl-2 pr-0">
                        <p v-if="supplier"  class="text-primary">{{ supplier.name }}</p>
                      </b-col>
                    </b-row>
                    <b-row v-if="purchaseRequest" class="pl-3 fs-12" cols="2">
                      <!-- Empty Col to order data-->
                      <b-col lg="2" md="2" cols="1" class="px-0 pt-1 text-right text-muted" style="max-width: 5rem"></b-col>
                      <!-- Contact and Supplier data -->
                      <b-col lg="10" md="10" cols="11" class="text-truncate px-0">
                        <div class="pl-2">
                          <p v-show="supplier.contact_person" class="mb-0">
                            <span class="font-weight-bold">Contacto:</span>
                            <span class="pl-2">{{supplier.contact_person }}</span>
                          </p>
                          <p v-show="supplier" class="mb-0">
                            <span v-if="supplier.phone" class="font-weight-bold">Teléfono:</span>
                            <span v-if="supplier.phone" class="pl-1">{{ supplier.phone }}</span>
                          </p>
                          <p v-show="supplier.email" class="mb-0">
                            <span class="font-weight-bold">Correo:</span>
                            <span class="pl-2">{{ supplier.email }}</span>
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
              <hr>

              <!-- Project details, only if the type is met -->
              <b-container v-if="['01','06','02','03','07'].includes(purchaseType)">
                <b-row class="my-2" cols="1">
                  <b-col cols="12">
                    <b-row align-v="center" class="pl-2" style="min-height: 45px" cols="2">
                      <b-col class="px-0 text-muted" style="max-width: 10.5rem">
                        <p class="pl-2 mr-3">Proyecto Asociado:</p>
                      </b-col>
                      <b-col  class="px-0">
                        <div v-if="ready">
                          <div v-if="projectEdit && canEdit">
                            <b-input
                              v-model="form.project_details"
                              size="sm"
                              ref="project_details"
                              @keyup.enter="$event.target.blur()"
                              @blur="updatedPurchaseRequest()"
                            />
                          </div>
                          <div v-else>
                            <p v-if="!canEdit" class="font-lato"> {{ purchaseRequest.project_details }}</p>
                            <p v-else-if="purchaseRequest.project_details && canEdit" class="click-to-edit font-lato" @click="projectEdit = true; focus('project_details')">
                              {{ purchaseRequest.project_details }}
                            </p>
                            <b-button v-else class="fill-purchase-button" variant="outline-warning" @click="projectEdit = true; focus('project_details')">
                              <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                              <span>Llenar</span>
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Purchase Request toggle-buttons -->
              <b-container>
                <b-row v-if="ready" align-v="end" class="my-3">
                  <b-col cols="7">
                    <toggle-button
                      v-model="displayCRC"
                      :labels="{ checked: 'COL', unchecked: 'DOL' }"
                      :width="75"
                      :disabled="togglingCRC"
                      color="#cd8488"
                      class="mb-0"
                    />
                    <!-- <toggle-button
                      v-if="validatesExoneration && canEdit"
                      v-model="form.is_exonerated"
                      :labels="{ checked: 'EXO', unchecked: 'NO EXO' }"
                      :width="75"
                      :disabled="togglingEXO"
                      sync
                      color="#cd8488"
                      class="mb-0 ml-3"
                    /> -->
                  </b-col>
                  <b-col cols="5">
                    <b-row align-h="end" class="pr-1">
                      <ItemSelector
                        v-if="purchaseRequest.doc_type === 'items_document' && canEdit"
                        :request-id="id"
                        :request-type="requestType"
                        :displayCRC="displayCRC"
                        origin="purchase_request"
                        :exchange-rate="exchangeRate"
                        :price-list="'público'"
                        :last-sap-line="lastSapLine"
                        @item-added="addPurchaseRequestItem"
                        @total-changed="saveTotal"
                      />
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Purchase Items List -->
              <b-container class="my-3 fs-13">
                <b-row align-v="center" align-h="center" style="min-height: 5em">
                  <PurchaseRequestItemTable
                    v-if="ready"
                    :purchase-request="purchaseRequest"
                    :purchase-request-items="purchaseItems"
                    :price-list="'público'"
                    @item-updated="updateItem"
                    @item-deleted="loadItemData"
                    @total-changed="saveTotal"
                    @item-changed="updatedPurchaseRequest"
                    @set-validation-error="setValidationError"
                    @purchase-request-item-added="addPurchaseRequestItem"
                  />
                  <div v-else class="text-info d-flex align-items-center">
                    <b-spinner style="width: 1.25rem; height: 1.25rem" type="grow"></b-spinner>
                    <span class="pl-3 fs-20">Cargando datos...</span>
                  </div>
                </b-row>
              </b-container>

              <!--Tax, totals and comments-->
              <b-container id="pr-guarantee-section">
                <b-row>
                  <!-- Comments  and aditional data section -->
                  <b-col lg="6" order="2" order-lg="1">
                    <!-- Additional Data -->
                    <div v-if="['06','09'].includes(purchaseType)">
                      <b-row align-v="center" class="pl-2">
                        <span class="d-inline-block font-weight-light">Tipo de pedido:</span>
                      </b-row>
                      <!-- Show data -->
                      <b-row v-if="ready && !additionalDataEdit && !form.price" align-v="center" class="pl-2 pb-1">
                        <b-col cols="2" class="pr-0"></b-col>
                        <b-col>
                          <p v-if="!canEdit" class="font-italic fs-14 font-weight-bold "> {{ purchaseRequest.additional_data }}</p>
                          <p v-else-if="purchaseRequest.additional_data && canEdit" class="click-to-edit font-italic fs-14 font-weight-bold " @click="additionalDataEdit = true">
                            {{ purchaseRequest.additional_data }}
                          </p>
                          <b-button v-else variant="outline-warning" class="fill-purchase-button ml-3" @click="additionalDataEdit = true">
                            <span>Agregar</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <!-- Add data -->
                      <b-row v-else align-v="center" cols="1" class="pl-2">
                        <b-col >
                          <b-form @submit.prevent="submitFormAdicionalData()" @reset.prevent="resetFormAdicionalData()">
                            <b-row>
                              <b-col class="mt-2 pl-5">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                  <b-form-radio-group
                                    id="input-group-1"
                                    v-model="formAditionalData.RequestTransit"
                                    :aria-describedby="ariaDescribedby"
                                    name="input-group-1"
                                  >
                                    <b-form-radio value="aéreo">Aéreo</b-form-radio>
                                    <b-form-radio value="Transito normal">Transito normal</b-form-radio>
                                  </b-form-radio-group>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row v-if="formAditionalData.RequestTransit == 'aéreo'">
                              <b-col class="mt-1 pl-5">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                  <b-form-radio-group
                                    id="input-group-2"
                                    v-model="formAditionalData.RequestAssumes"
                                    :aria-describedby="ariaDescribedby"
                                    name="input-group-2"
                                  >
                                    <b-form-radio value="Cliente por medio de la Orden">Asume cliente por medio de la Orden</b-form-radio>
                                    <b-form-radio value="UNE directamente al gasto">Asume UNE directamente al gasto</b-form-radio>
                                  </b-form-radio-group>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row class="pl-5">
                              <b-button v-if="!formAditionalData.RequestTransit" type="reset" size="sm" variant="outline-danger">Cancelar</b-button>
                              <b-button v-else :disabled="formAditionalData.RequestTransit == 'aéreo' && !formAditionalData.RequestAssumes" type="submit" size="sm" variant="outline-success">Guardar</b-button>
                            </b-row>
                            <br class="pb-3">
                          </b-form>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- Comments -->
                    <b-row align-v="center" class="pl-2">
                      <span class="d-inline-block font-weight-light">Comentarios:</span>
                      <b-button v-if="ready && !notesEdit && !form.notes & canEdit" variant="outline-warning" class="fill-purchase-button ml-3" @click="notesEdit = true; focus('notes');">
                        <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                        <span>Anotar</span>
                      </b-button>
                    </b-row>
                    <b-row v-if="ready" class="mt-2 px-2">
                      <div v-if="notesEdit && canEdit" class="w-100">
                        <ValidationProvider rules="max:260" name="comentarios" v-slot="{ errors }">
                          <b-textarea
                            v-model="form.notes" rows="3" size="sm" trim ref="notes"
                            :class="{ 'red-input-border': errors[0] }"
                            @blur="errors[0] ? null : updatedPurchaseRequest()"
                            @keydown.enter="errors[0] && form.notes == purchaseRequest.notes ? null : updatedPurchaseRequest()"
                          />
                          <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div v-else>
                        <p v-if="purchaseRequest.notes && canEdit" class="click-to-edit font-italic fs-14 font-weight-bold " @click="notesEdit = true; focus('notes');">
                          {{ purchaseRequest.notes }}
                        </p>
                        <p v-else class="font-italic fs-14 font-weight-bold pl-2">{{ purchaseRequest.notes }}</p>
                      </div>
                    </b-row>
                  </b-col>
                  <!-- Totals -->
                  <b-col lg="6" order="1" order-lg="2" class="pb-4 pb-sm-0" id="purchase-total-box">
                    <b-table-simple borderless small>
                      <b-tbody>
                        <b-tr>
                          <b-td></b-td>
                          <b-td class="text-right lato-label">SUBTOTAL</b-td>
                          <b-td class="text-right price-display">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ subtotalCRC | colones }}</span>
                              <span v-else>{{ subtotal | dollarize }}</span>
                            </div>
                            <div v-else>
                              <span v-if="displayCRC">‎₡0</span>
                              <span v-else>$0</span>
                            </div>
                          </b-td>
                          <b-td></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right">
                            <div v-if="ready">
                              <div v-if="discountEdit">
                                <ValidationProvider :rules="{ required: true, min_value: 0 }" slim v-slot="{ errors }">
                                  <b-input-group size="sm" append="%" class="ml-auto" style="width: 90px">
                                    <b-input
                                      v-model="form.discount"
                                      type="number"
                                      ref="discountInput"
                                      class="no-spin"
                                      :class="{ 'red-input-border': errors[0] }"
                                      @click="$nextTick(() => $refs.discountInput.select())"
                                      @keyup.enter="errors[0] ? null : $event.target.blur()"
                                      @blur="errors[0] ? null : updateDiscount()"
                                    />
                                  </b-input-group>
                                </ValidationProvider>
                              </div>
                              <span v-else :class="{'click-to-edit': canEdit }" @click="focusDiscount">{{ parseFloat(purchaseRequest.discount.toFixed(2)) }} %</span>
                            </div>
                          </b-td>
                          <b-td class="text-right lato-label second-col">DESCUENTO</b-td>
                          <b-td class="text-right price-display third-col">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ discountCRC | colones }}</span>
                              <span v-else>{{ discount | dollarize }}</span>
                            </div>
                          </b-td>
                          <b-td></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td></b-td>
                          <b-td class="text-right lato-label">IMPUESTO</b-td>
                          <b-td class="text-right price-display">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ taxCRC | colones }}</span>
                              <span v-else>{{ tax | dollarize }}</span>
                            </div>
                          </b-td>
                          <b-td>
                          </b-td>
                        </b-tr>
                        <b-tr class="pt-3 border-top">
                          <b-td colspan="2" class="text-right">
                            <div v-show="ready && total > 0 && !displayCRC && purchaseRequest" style="display: inline; cursor: pointer" id="custom-total-info">
                            </div>
                            <span v-if="displayCRC" class="font-weight-bold">TOTAL COLONES</span>
                            <span v-else class="font-weight-bold">TOTAL DOLARES</span>
                          </b-td>
                          <b-td class="text-right">
                            <div v-if="ready">
                              <div class="price-display">
                                <div v-if="displayCRC">
                                  <span v-if="totalCRC > 0" class="p-0">{{ totalCRC | colones }}</span>
                                  <span v-else>{{ 0 | colones }}</span>
                                </div>
                                <div v-else>
                                  <span v-if="total > 0"  class="p-0">{{ total | dollarize }}</span>
                                  <span v-else>{{ 0 | dollarize }}</span>
                                </div>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Signature footer -->
              <b-container class="mt-5" id="purchase-sigbox">
                <b-row class="px-2 border-bottom" style="min-height: 3em">
                  <b-col sm="6" class="asesor-box">
                    <b-row class="label-size-md">Solicitado por:</b-row>
                    <b-row align-h="center" class="label-size-lg">
                      {{ asesor | concatName2 | titleize }}
                    </b-row>
                    <b-row  align-h="center" class="label-size-lg mb-1">
                      {{ asesor.email }}
                    </b-row>
                  </b-col>
                  <b-col sm="6" class="valid-until-box">
                    <b-row align-h="center" class="label-size-md">
                      <span v-if="!isOrder">Fecha requerida:</span>
                      <span v-else>Fecha para pago:</span>
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <div v-if="ready">
                        <span v-if="!canEdit">{{ purchaseRequest.sap_req_date | formatDate }}</span>
                        <span v-else-if="purchaseRequest.sap_req_date" :class="{ 'click-to-edit': purchaseRequest }" @click="editModalShow = true" >{{ purchaseRequest.sap_req_date | formatDate }}</span>
                        <b-button v-else class="fill-purchase-button" variant="outline-warning" @click="editModalShow = true">
                          <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                          <span>Llenar</span>
                        </b-button>
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Footer msg -->
              <b-container>
                <b-row align-h="center">
                  <span class="label-size-sm font-weight-bold text-center ">* <span style="text-decoration: underline">Aplica la política de Compra:</span> Para solicitudes internacionales el tiempo de respuesta es de 24 horas y para locales de 3 días. Las fecha de entrega para todos los casos son estimadas, por favor validar con Supply Chain cualquier requerimiento adicional.</span>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-col>

        <!-- Right sidebar -->
        <b-col lg="3" order="2" order-lg="2" class="font-lato fs-14" id="right-sidebar" style="border-left: 3px solid #f2f2f2">
          <b-row class="my-2">
            <b-col v-if="purchaseRequest" class="text-right">
              <Button :disabled="!canEdit" size="sm" class="mr-2" @click="editModalShow = true">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-2">Editar</span>
              </Button>
              <!-- TODO: it's not necessary in this moment -->
              <!-- <Button v-if="purchaseRequest.status == 'open'" :disabled="sapStatus != 'full' || !isValid" size="sm" class="text-danger" @click="closingModalShow = true">
                <font-awesome-icon :icon="['fas', 'lock-alt']" />
                <span class="pl-2">Cerrar</span>
              </Button> -->
            </b-col>
          </b-row>
          <b-row align-v="center" >
            <b-col>
              <span class="font-weight-bold fs-15">Datos Generales</span>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pl-3">
            <b-table-simple v-if="ready" small borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="width: 130px">Estado:</b-td>
                  <b-td v-if="purchaseRequest">
                    <span v-if="purchaseRequest.is_open" class="text-success">Abierto</span>
                    <span v-else class="text-danger">Cerrado <font-awesome-icon :icon="['fas', 'lock-alt']" size="sm" fixed-width /> </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Código Proveedor:</b-td>
                  <b-td v-if="supplier">{{ supplier.client_num }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Tipo de {{orderType}}:</b-td>
                  <b-td v-if="purchaseRequest">
                    {{ purchaseTypeName }}
                  </b-td>
                </b-tr>
                <b-tr v-if="form.order_id">
                  <b-td style="width: 130px">Orden Asociada:</b-td>
                  <b-td>
                    {{ orderDocNum }}
                  </b-td>
                </b-tr>
                <b-tr v-if="ready">
                  <b-td>
                    <span v-if="!isOrder">Fecha requerida:</span>
                    <span v-else>Fecha para pago:</span>
                  </b-td>
                  <b-td>
                    <span v-if="form.sap_req_date">{{ purchaseRequest.sap_req_date | formatDate }}</span>
                    <span v-else class="text-danger"> Pendiente </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Unidad negocio:</b-td>
                  <b-td>
                    <span v-if="purchaseRequest">{{ purchaseRequest.business_unit }}</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="purchaseRequest && isOrder">
                  <b-td>Solicitud de fondos:</b-td>
                  <b-td>
                    <span v-if="purchaseRequest.payment_request">Sí</span>
                    <span v-else>No</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="isOrder">
                  <b-td style="width: 130px">Autorización:</b-td>
                  <b-td v-if="authorizer">{{authorizer | concatName2 | titleize}}</b-td>
                  <b-td v-else class="text-warning">Sin Jefatura</b-td>
                </b-tr>
                <b-tr v-if="isOrder">
                  <b-td style="width: 130px">Status aprobación:</b-td>
                  <b-td v-if="purchaseRequest.is_approved" class="text-success"><b>Aprobada</b></b-td>
                  <b-td v-else class="text-secondary"><b>Pendiente</b></b-td>
                </b-tr>
                <b-tr v-if="authorizer && isOrder && canRequestApproval">
                  <b-td style="width: 130px"></b-td>
                  <b-td>
                    <Button @click="openRequestApprovalModal()" size="sm" :disabled="!isValidForOrder" variant="blue">
                      <span>Solicitar aprobación </span>
                    </Button>
                  </b-td>
                </b-tr>
                <b-tr v-if="canApproveTheOrder">
                  <b-td style="width: 130px"></b-td>
                  <b-td>
                    <Button @click="approveOrderModalShow = true" :disabled="!isValidForOrder" size="sm" variant="green">
                      <span>Aprobar</span>
                    </Button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
          <!-- SAP STATUS -->
          <b-row align-v="center" style="border-top: 3px solid #f2f2f2; height: 50px">
            <b-col>
              <span class="font-weight-bold fs-15">SAP</span>
            </b-col>
          </b-row>
          <b-row align-h="center" class="mb-1 pl-3">
            <b-table-simple small borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="width: 130px">Sincronización:</b-td>
                  <b-td>
                    <div v-if="purchaseRequest">
                      <div v-if="sapStatus == 'full'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
                        <span class="text-muted pl-1">Full</span>
                      </div>
                      <div v-else-if="sapStatus == 'syncing'">
                        <b-spinner variant="secondary" small />
                        <span class="text-muted pl-1">Sincronizando...</span>
                      </div>
                      <div v-else-if="sapStatus == 'pending'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
                        <span class="text-muted pl-1">Pendiente</span>
                      </div>
                      <div v-else-if="sapStatus == 'partial'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-warning" />
                        <span class="text-muted pl-1">Parcial</span>
                      </div>
                      <div v-else-if="sapStatus == 'ignored'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-secondary" />
                        <span class="text-muted pl-1">Apagado</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Documento:</b-td>
                  <b-td v-if="purchaseRequest">
                    <span v-if="purchaseRequest.sap_doc_no">{{ purchaseRequest.sap_doc_no }}</span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="purchaseRequest && (baseValidationError || purchaseRequest.sap_errors)">
                  <b-td class="align-top">Aviso:</b-td>
                  <b-td class="text-danger">
                    <p v-if="baseValidationError">{{ baseValidationError }}</p>
                    <p v-if="purchaseRequest.sap_errors">{{ purchaseRequest.sap_errors }}</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Edit Modal -->
    <b-modal v-model="editModalShow" centered @hide="resetEditModal">
      <template #modal-title>
        <h5>Editar datos:</h5>
      </template>
      <b-container class="pt-2 px-3 fs-14">
        <!-- order -->
        <b-row v-if="form.order_id" align-h="center" class="mb-2" >
          <div style="width: 300px">
            <p>Orden Asociada: <span :class="{'click-to-edit' : !orderEdit}" @click="orderEdit = true">{{order.sap_doc_num}}</span></p>
          </div>
          <b-row v-if="orderEdit" style="width: 300px">
            <b-col cols="9" class="p-0">
              <b-form-group style="width: 200px">
                <b-input-group size="sm">
                  <b-input
                    v-model="orderSearchTerm"
                    autofocus
                    ref="searchInput"
                    placeholder="Buscar número de doc."
                    @keyup.enter="searchOrder"
                    @click="$nextTick(() => $refs.searchInput.select())"
                  />
                  <b-input-group-append>
                    <b-button variant="outline-secondary" :disabled="!orderSearchTerm" @click="searchOrder">
                      <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>
            </b-col>
            <b-col cols="3" class="p-0">
              <b-form-group class="ml-auto mr-3">
                <Button variant="orange" v-if="orderSearchDone" size="sm" @click="resetOrderSearch">
                  <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width/>
                </Button>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row v-if="orderSearchDone" style="width: 350px">
            <b-card style="width: 350px">
              <b-container v-if="orderSearchResult" fluid class="fs-15">
                <b-row>
                  <b-col>
                    <small class="font-weight-bold fs-13">
                      Orden # {{ orderSearchResult.sap_doc_num }}
                    </small>
                  </b-col>
                  <b-col class="text-right">
                    <small>
                      {{ orderSearchResult.created_at | formatDateCompact }}
                    </small>
                  </b-col>
                </b-row>
                <b-row class="my-2 text-primary text-center fs-14">
                  <b-col>{{ orderSearchResult.client_name }}</b-col>
                </b-row>
                <b-row v-if="orderSearchDone" align-h="center" class="mt-2">
                  <Button variant="green" size="sm" @click="selectOrder">
                    <font-awesome-icon :icon="['fas', 'share']" fixed-width />
                  </Button>
                </b-row>
              </b-container>
              <b-container v-else fluid class="fs-13">
                <b-row align-h="center" class="mt-2">
                  <span class="text-danger">No hay resultados.  Intente de nuevo</span>
                </b-row>
              </b-container>
            </b-card>
          </b-row>
        </b-row>
        <hr v-if="form.order_id"/>
        <!-- sap_req_date -->
        <b-row align-h="center" class="mb-2">
          <b-form-group style="width: 300px" ref="datepicker">
            <template #label>
              <span v-if="!isOrder">Fecha requerida:</span>
              <span v-else>Fecha para pago:</span>
            </template>
            <flat-pickr
              v-model="form.sap_req_date"
              :config="flatpickrConfig"
              placeholder="Seleccione una fecha.."
              class="form-control form-control-sm white-flatpickr-input text-center"
            ></flat-pickr>
          </b-form-group>
        </b-row>
        <hr/>
        <!-- business_unit-->
        <b-row v-if="purchaseRequest" align-h="center">
          <b-form-group label="Unidad negocio" class="mb-2" style="width: 300px">
            <b-select v-model="form.business_unit" :options="businessUnitOptions"  style="width: auto" size="sm"></b-select>
          </b-form-group>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button variant="green"  size="sm" @click="updatedPurchaseRequest()">
          Guardar
        </Button>
      </template>
    </b-modal>

    <!-- Request approval -->
    <b-modal v-model="requestApprovalModalShow" centered>
      <template #modal-title>
        <h5>Solicitar aprobación</h5>
      </template>
      <b-container v-if="ready && authorizer" class="pt-2 px-3 fs-14">
        <!-- authorizer email -->
        <b-row>
          <b-col>
            <b-form>
              <b-form-group label="Correo jefatura:" class="mb-0" >
                <b-form-input v-if="authorizer.email" :value="authorizer.email" size="sm" disabled :state="validateEmail(authorizer.email)"/>
                <b-form-input v-else placeholder="Jefatura sin correo registrado, informe a TI" size="sm" disabled :state="validateEmail('null')"/>
              </b-form-group>
            </b-form>
            <hr>
          </b-col>
        </b-row>
        <!-- body email -->
        <b-row>
          <b-col>
            <b-form-group label="Cuerpo del correo:" label-size="sm">
              <b-textarea v-model="requestApprovalEmailText" rows="8" size="sm" />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- note -->
        <b-row>
          <b-col class="text-center">
            <small class="text-center text-danger"><b>NOTA: Al aprobar la orden, no podrá ser editada</b></small>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button variant="green" size="sm" @click="sendRequestApprovalEmail()" :disabled="!validateEmail(authorizer.email) || !requestApprovalEmailText">
          <font-awesome-icon :icon="['far', 'paper-plane']" fixed-width class="pr-1 d-none d-sm-inline"/>
          <span> Enviar</span>
        </Button>
      </template>
    </b-modal>

    <!-- Approve Modal -->
    <b-modal
      v-model="approveOrderModalShow"
      size="sm"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <template #modal-header="{ close }">
        <span class="fs-18 mt-2 text-secondary">Aprobación de orden</span>
        <b-button size="sm" class="p-0 mt-2 m-0" variant="outline-danger" @click="close()">
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width/>
        </b-button>
      </template>
      <b-row align-h="center" class="text-center" v-if="purchaseRequest">
        <b class="fs-18 m-1">¿Aprobar orden {{purchaseRequest.purchase_internal_id}}?</b>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center text-danger"><b>NOTA: Al aprobar la orden, no podrá ser editada</b></small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" size="sm" class="pl-1 pr-0" @click="approveOrderModalShow = false">
          Cancelar
          <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-light pl-1"/>
        </Button>
        <Button variant="green" size="sm" class="pl-1 pr-0" @click="approveOrder()">
          Aprobar
          <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width class="text-light pl-1"/>
        </Button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { businessUnits} from '../../../sharedOptions/purchaseRequestBusinessUnits'

import ToastAlert from '../../shared/ToastAlert'
import AccesosLogo from 'images/logos/accesos-w300.png'
import Button from '../../shared/Button'
import PurchaseRequestNavsTabs from './PurchaseRequestNavsTabs.vue'
import PurchaseRequestItemTable from './PurchaseRequestItemTable'
import ItemSelector from '../../shared/ItemSelector'
import PurchaseRequestButtonBar from '../../shared/PurchaseRequestButtonBar'

export default {
  name: 'PurchaseRequestShow',
  components : {
    flatPickr,
    ValidationProvider,
    Button,
    ToastAlert,
    PurchaseRequestNavsTabs,
    PurchaseRequestItemTable,
    ItemSelector,
    PurchaseRequestButtonBar
  },
  data () {
    return {
      id: Number(this.$route.params.id),
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      businessUnitOptions: businessUnits,
      purchaseRequest: null,
      authorizer: null,
      supplier: null,
      retrySap: 0,
      supplierSearchTerm: '',
      supplierSearchResults: [],
      supplierSearchDone: false,
      selectedSupplier: null,
      validationError: null,
      additionalDataEdit: false,
      formAditionalData: {
        RequestTransit: null,
        RequestAssumes: null
      },
      supplierSearchFields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name', label: 'Nombre', tdClass: 'clickable' }
      ],
      purchaseRequestItemData: {},
      purchaseRequestItemList: [],
      purchaseTypeNames: [
        { value: '01', text: 'Proyecto Nacional'},
        { value: '06', text: 'Proyecto Internacional'},
        { value: '02', text: 'Servicio Nacional'},
        { value: '03', text: 'Servicio Internacional'},
        { value: '04', text: 'Stock Local'},
        { value: '09', text: 'Stock Internacional'},
        { value: '07', text: 'Mantenimiento Edificio'},
        { value: '05', text: 'Activo Fijo'}
      ],
      ready: false,
      purchaseType : '',
      form: {
        project_details: '',
        notes: '',
        discount: 0,
        order_id: null,
        client_id: null,
        sap_req_date: null,
        // status: null,
        business_unit: null,
        doc_currency: null,
        doc_total: null,
        usd_crc_rate: this.$store.state.settings.usd_crc_rate,
        additional_data: ''
      },
      contactForm: {
        email: null,
        phone: null,
        cellular: null
      },
      order: null,
      orderDocNum: null,
      orderSearchResult: null,
      orderSearchDone: false,
      orderSearchTerm: '',
      editModalShow: false,
      requestApprovalModalShow: false,
      approveOrderModalShow: false,
      processing: false,
      pinging: false,
      syncing: false,
      togglingCRC: false,
      togglingEXO: false,
      projectEdit: false,
      notesEdit: false,
      orderEdit: false,
      supplierEdit: false,
      discountEdit: false,
      sapRequestTimeEdit: false,
      flatpickrConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: 'd/m/Y',
        disableMobile: true
      },
      flatpickrInlineConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        inline: true,
        disableMobile: true,
        minDate: "today"
      },
      requestApprovalEmailText: '',
      taxCodes: {
        IVC : 0.13,
        EXEC : 0.0,
        IMPG : 0.0,
        IMPE : 0.0,
        ISC : 0.10,
        IV : 0.13,
        IV1 : 0.01,
        IV2 : 0.02,
        IV4 : 0.04,
        IVA8 : 0.08,
        EXO : 0.0,
        IVG : 0.13,
        IVM : 0.10,
      },
    }
  },
  computed: {
    ...mapGetters (['isSupervisor', 'isDev', 'isSocAdmin']),
    currentUser() { return this.$store.state.currentUser },
    accesosLogo () { return AccesosLogo },
    asesor () {
      return this.ready ? this.purchaseRequest.user : this.$store.state.currentUser
    },
    sapStatus () {
      if (this.syncing) {
        return 'syncing'
      } else {
        if (this.purchaseRequest.sap_doc_entry) {
          return this.purchaseRequest.sap_is_synced ? 'full' : 'partial'
        } else {
          return 'pending'
        }
      }
    },
    displayCRC: {
      get () {
        return this.$store.state.displayCRC
      },
      set (val) {
        this.$store.commit('setDisplayCRC', val)
      }
    },
    purchaseItems () {
      let array = this.purchaseRequestItemList.map(id => this.purchaseRequestItemData[id])
      return array.sort((a, b) => a.position - b.position)
    },
    // Format string Name
    purchaseTypeName () {
      let str = ''
      Object.entries(this.purchaseTypeNames).forEach(([key, value]) => {
        if ( value.value == this.purchaseRequest.purchase_type ) {
          str = value.text
        }
      });
      return str
    },
    // Dollars
    subtotal () {
      let subtotal = 0
      if (this.ready) {
        this.purchaseItems.forEach(item => subtotal += item.price * item.quantity)
      }
      return subtotal
    },
    // Colones
    subtotalCRC () {
      let subtotal = 0
      if (this.ready) {
        this.purchaseItems.forEach(item => subtotal += item.local_price * item.quantity)
      }
      return subtotal
    },
    discount () {
      return this.purchaseRequest ? this.subtotal * (this.purchaseRequest.discount / 100) : 0
    },
    discountPercent () {
      return this.purchaseRequest ? this.purchaseRequest.discount / 100 : 0
    },
    tax () {
      const totalTax = this.purchaseItems.reduce((acum, val) => {
        const taxAmount = this.taxCodes[val.tax_code] || 0
        const ItemSubtotal = val.price * val.quantity
        const ItemDiscount = ItemSubtotal * this.discountPercent
        let itemTax = (ItemSubtotal - ItemDiscount) * taxAmount
        return acum + itemTax
      }, 0)
      return totalTax
    },
    discountCRC () {
      return this.purchaseRequest ? this.subtotalCRC * (this.purchaseRequest.discount / 100) : 0
    },
    taxCRC () {
      const totalTax = this.purchaseItems.reduce((acum, val) => {
        const taxAmount = this.taxCodes[val.tax_code] || 0
        const ItemSubtotal = val.local_price * val.quantity
        const ItemDiscount = ItemSubtotal * this.discountPercent
        let itemTax = (ItemSubtotal - ItemDiscount) * taxAmount
        return acum + itemTax
      }, 0)
      return totalTax
    },
    totalCRC () {
      return this.subtotalCRC - this.discountCRC + this.taxCRC
    },
    total () {
      return this.subtotal + this.tax
    },
    exchangeRate () { return this.form.usd_crc_rate },
    // validatesExoneration () {
    //   return ['01','06'].includes(this.purchaseRequest.purchase_type)
    // },
    // Is valid to upload to sap?
    baseValidationError () {
      if (this.ready) {
        if (!this.supplier.sap_valid) {
          if (this.supplier.sap_frozen && this.supplier.sap_frozen_remarks) {
            return `Inactivo por: ${this.supplier.sap_frozen_remarks}`
          } else {
            return 'Este proveedor está inválido en SAP'
          }
        }
        if (this.purchaseItems.length == 0 && this.purchaseRequest.doc_type == 'service_document') return 'Debe agregar al menos 1 servicio'
        if (this.purchaseItems.length == 0 && this.purchaseRequest.doc_type == 'items_document') return 'Debe agregar al menos 1 artículo'
        if (!this.purchaseRequest.additional_data && ['06','09'].includes(this.purchaseType)) return 'Debe de completar el Tipo de pedido'
        if (!this.purchaseRequest.notes)  return 'Debe completar los comentarios'
        if (!this.purchaseRequest.sap_req_date) return `Debe completar la fecha ${this.purchaseRequest.order_type == 'order' ? 'para pago' : 'requerida de SAP'}`
        if (this.purchaseItems.some(item => item.item_description == 'Agregar servicio')) return 'Debe agregar la descripción al servicio correspondiente'
        if (this.validationError) return this.validationError
        if (this.purchaseRequest.doc_type == 'service_document' && this.purchaseItems.some(item => item.purchase_request_type == null)) return 'Se requiere completar tipo de OC del proveedor'
        if (!this.asesor.sap_sales_person_code) return 'A este asesor le falta un código de vendedor. Por favor hable con su compañero de TI'
        // It has to be at the end
        if (this.purchaseRequest.order_type == 'order') {
          if (!this.authorizer) { return 'Sin Jefatura, contacte a TI para asignar una'}
          if (!this.purchaseRequest.is_approved) { return 'Se requiere la aprobación de la Jefatura'}
        }
      }
      return false
    },
    lastSapLine () {
      let array = this.purchaseRequestItemList.map(id => this.purchaseRequestItemData[id])
      array = array.sort((a, b) => b.sap_line_num - a.sap_line_num)
      return array.length > 0 ? array[0].sap_line_num : -1
    },
    // valid for syncing to SAP?
    isValid () {
      return this.baseValidationError ? false : true
    },
    isValidForOrder () {
      const validErrors = ['Se requiere la aprobación de la Jefatura']
      return !this.baseValidationError || validErrors.includes(this.baseValidationError);
    },
    orderType () {
      if (!this.ready) { return }
      if (this.purchaseRequest.is_executed) {
        return 'orden'
      } else {
        return 'solicitud'
      }
    },
    isOrder () {
      if (!this.ready) { return false }
      if (this.purchaseRequest && this.purchaseRequest.order_type == 'order' && this.purchaseRequest.is_executed) {
        return true
      }
      return false
    },
    canRequestApproval () {
      if (!this.ready || !this.purchaseRequest || this.purchaseRequest.is_approved) return false
      if (this.isDev) return true
      if (this.currentUser.id == this.authorizer.id) return false
      // Only dev and the SOC/OC owner can request approval
      if (this.currentUser.id == this.purchaseRequest.user_id) return true
      return false
    },
    canApproveTheOrder () {
      if (!this.ready || !this.purchaseRequest || this.purchaseRequest.is_approved || !this.isOrder) return false
      if (this.isDev || this.isSocAdmin) return true
      if (this.authorizer && this.authorizer.id == this.currentUser.id) {
        return true
      }
      return false
    },
    canEdit () {
      if (this.purchaseRequest) {
        if (this.isOrder) {
          return this.purchaseRequest.is_approved ? false : true
        } else {
          return this.purchaseRequest.is_open ? true : false
        }
      }
      return false
    },
    canView () {
      if (!this.ready || !this.purchaseRequest) return false
      // validations
      if (this.isDev || this.isSocAdmin) {
        return true
      } else if (this.currentUser.id == this.purchaseRequest.user_id) {
        return true
      } else if (this.authorizer && this.authorizer.id == this.currentUser.id) {
        return true
      }
      return false
    },
    requestType () {
      if (this.purchaseRequest.purchase_type) {
        return this.purchaseRequest.purchase_type != '05' ? 'articulo' : 'activo'
      }
      return 'articulo'
    }
  },
  watch: {
    '$route' (to, from) {
      this.id = Number(this.$route.params.id)
      this.fetchPurchaseRequestData()
    },
    displayCRC (newVal, oldVal) {
      if(newVal){
        this.form.doc_currency = 'COL'
      } else {
        this.form.doc_currency = 'USD'
      }
      if (this.processing) { return }
      this.processing = true
      this.togglingCRC = true
      this.$http.patch(`/api/production/purchase_requests/${this.id}`, {
          sync: false,
          purchase_request: { doc_currency: this.form.doc_currency }
        })
        .then(response => {
          this.form.doc_currency = response.data.doc_currency
        })
        .catch(e => {
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'Error: No se cambio la moneda'
        })
        .finally(() => {
          this.processing = false
          // Wait 1 second until toggling tax again
          setTimeout(() => this.togglingCRC = false, 1500)
        })
    },
    // watch for changes on tax toggle button
    // 'form.is_exonerated' (newVal, oldVal) {
    //   if (this.processing) { return }
    //   this.processing = true
    //   this.togglingEXO = true
    //   this.$http.patch(`/api/production/purchase_requests/${this.id}`, {
    //       sync: false,
    //       purchase_request: { is_exonerated: newVal }
    //     })
    //     .then(response => {
    //       this.form.is_exonerated = response.data.is_exonerated
    //     })
    //     .catch(e => {
    //       this.alertShow = true
    //       this.alertVariant = 'danger'
    //       this.alertMessage = 'Error: No se cambio la moneda'
    //     })
    //     .finally(() => {
    //       this.processing = false
    //       // Wait 1 second until toggling tax again
    //       setTimeout(() => this.togglingEXO = false, 1500)
    //     })
    // }
  },
  mounted () {
    this.fetchPurchaseRequestData()
  },
  methods: {
    // PurchaseRequest section
    fetchPurchaseRequestData () {
      this.ready = false
      this.$http.get(`/api/production/purchase_requests/${this.id}`)
        .then(response => {
          console.log(response.data)
          // load PR item data first because its reference gets deleted in loadItemData
          this.loadItemData(response.data.purchase_request_items)
          this.fetchAuthorizer()
          if (response.data.order) {
            this.orderDocNum = response.data.order.sap_doc_num
            this.order = response.data.order
          }
          if (response.data.client) {
            this.supplier = response.data.client
          }
          if (response.data.doc_currency == 'COL'){
            this.displayCRC = true
          } else {
            this.displayCRC = false
          }
          this.purchaseRequest = response.data
          this.purchaseType = response.data.purchase_type
          // seed form!
          this.form.project_details = response.data.project_details
          this.form.notes = response.data.notes
          this.form.discount = response.data.discount
          this.form.order_id = response.data.order_id
          this.form.client_id = response.data.client_id
          this.form.doc_total = response.data.doc_total
          this.form.sap_req_date = response.data.sap_req_date
          this.form.business_unit = response.data.business_unit
          this.form.doc_currency = response.data.doc_currency
          this.form.additional_data = response.data.additional_data
          // SAP STATUS

          this.ready = true

          if (this.sapStatus == 'pending' || this.sapStatus == 'partial') {
            setTimeout(() => this.updatedPurchaseRequest(), 2500)
          }
          delete this.purchaseRequest['purchase_request_items']
          delete this.purchaseRequest['client']
          delete this.purchaseRequest['order']
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'La SOC solicitada no se pudo encontrar'
          this.alertShow = true
        })
    },
    updatedPurchaseRequest () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/purchase_requests/${this.id}`, {
          sync: this.isValid ? true : false,
          purchase_request: this.form
        })
        .then(response => {
          console.log(response)
          if (this.form.order_id)( this.orderDocNum = this.order.sap_doc_num )
          if (this.supplierSearchDone) {
            this.supplier.name = this.selectedSupplier.name
            this.supplier.phone = this.selectedSupplier.phone
            this.supplier.client_num = this.selectedSupplier.client_num
            this.supplier.email = this.selectedSupplier.email
          }
          // Purchase Request
          this.purchaseRequest.doc_total = response.data.doc_total
          this.purchaseRequest.project_details = response.data.project_details
          this.purchaseRequest.notes = response.data.notes
          this.purchaseRequest.order_id = response.data.order_id
          this.purchaseRequest.client_id = response.data.client_id
          this.purchaseRequest.sap_req_date = response.data.sap_req_date
          this.purchaseRequest.business_unit = response.data.business_unit
          this.purchaseRequest.discount = response.data.discount
          this.purchaseRequest.doc_currency = response.data.doc_currency
          this.purchaseRequest.additional_data = response.data.additional_data
          this.purchaseRequest.sap_is_synced = response.data.sap_is_synced
          this.purchaseRequest.sap_doc_entry = response.data.sap_doc_entry
          this.purchaseRequest.sap_doc_no = response.data.sap_doc_no
          this.purchaseRequest.sap_errors = response.data.sap_errors
          this.purchaseRequest.is_approved = response.data.is_approved
          this.purchaseRequest.is_executed = response.data.is_executed
          this.purchaseRequest.order_type = response.data.order_type
          //
          this.notesEdit = false
          this.projectEdit = false
          this.sapRequestTimeEdit = false

          if (!this.pinging && this.isValid) {
            this.syncing = true
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.processing = false
          this.editModalShow = false
        })
    },
    saveTotal () {
      this.$http.patch(`/api/production/purchase_requests/${this.id}/save_total`, {
          sync: this.isValid ? true : false,
          purchase_request: { total: this.total }
        })
        .then(response => {
          console.log('save total',response)
          if (!this.pinging && this.isValid) {
            this.syncing = true
            setTimeout(() => this.ping(), 2500)
          }
        })
    },
    // PurchaseRequestItems section
    loadItemData (purchaseRequestItems) {
      const purchaseRequestItemData = normalize(
        { items: purchaseRequestItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (purchaseRequestItemData.entities.hasOwnProperty('items')) { // in case of empty data
        this.purchaseRequestItemData = purchaseRequestItemData.entities.items
      } else {
        this.purchaseRequestItemData = {}
      }
      this.purchaseRequestItemList = purchaseRequestItemData.result.items
    },
    addPurchaseRequestItem (item) {
      this.purchaseRequestItemList.push(item.id)
      this.$set(this.purchaseRequestItemData, item.id, item)
    },
    updateItem (item) {
      this.$set(this.purchaseRequestItemData, item.id, item)
    },
    // To show authorization person
    fetchAuthorizer () {
      this.$http.get(`/api/production/purchase_requests/${this.id}/fetch_oc_authorizer`)
        .then((res) => {
          if (res.data.manager) {
            this.authorizer = res.data.manager
          } else {
            this.authorizer = null
          }
        })
        .catch(e => console.log(e))
    },
    // Common methods sections
    focus (formInput) {
      if (formInput) {
        this.$nextTick(() => {
          this.$refs[formInput].focus()
        })
      }
    },
    resetEditModal () {
      this.form.sap_req_date = this.purchaseRequest.sap_req_date
      this.form.business_unit = this.purchaseRequest.business_unit
      this.form.order_id = this.purchaseRequest.order_id
      this.form.client_id = this.purchaseRequest.client_id
      if (this.form.order_id) { this.order.sap_doc_num = this.orderDocNum }
      this.resetOrderSearch()
    },
    ping () {
      console.log('Pinging sap status')
      this.pinging = true
      this.$http.get(`/api/production/purchase_requests/${this.id}/fetch_sap_status`)
        .then(response => {
          console.log(response.data)
          if (response.data.success) {
            this.purchaseRequest.sap_is_synced = response.data.purchase_request.sap_is_synced
            this.purchaseRequest.sap_doc_entry = response.data.purchase_request.sap_doc_entry
            this.purchaseRequest.sap_doc_no = response.data.purchase_request.sap_doc_no
            this.purchaseRequest.sap_errors = response.data.purchase_request.sap_errors
            this.pinging = false
            this.syncing = false
          } else {
            // TODO: check if is necessary.
            this.retrySap+=1
            if (this.retrySap == 10) {
              this.pinging = false
              this.syncing = false
              this.retrySap = 0
              // this.fetchPurchaseRequestData()
              return
            }
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
          this.pinging = false
          this.syncing = false
        })
    },
    setValidationError (error) {
      this.validationError = error
    },
    submitFormAdicionalData () {
      if(this.formAditionalData.RequestTransit == 'aéreo' && this.formAditionalData.RequestAssumes) {
        this.form.additional_data = `El pedido es ${this.formAditionalData.RequestTransit} y asume ${this.formAditionalData.RequestAssumes}`
      } else {
        this.form.additional_data = `El pedido es ${this.formAditionalData.RequestTransit}`
      }
      this.updatedPurchaseRequest()
      this.resetFormAdicionalData()
    },
    resetFormAdicionalData () {
      this.formAditionalData.RequestTransit = null
      this.formAditionalData.RequestAssumes = null
      this.additionalDataEdit = false
    },
    copyPurchaseRequest(purchaseRequest) {
      this.alertVariant = 'success'
      this.alertMessage = `Copia ${purchaseRequest.purchase_internal_id} se ha creado`
      this.alertShow = true
    },
    showAlertError () {
      this.alertVariant = 'danger'
      this.alertMessage = 'No funcionó'
      this.alertShow = true
    },
    // Order methods section
    searchOrder () {
      if (this.orderSearchTerm) {
        if (this.processing) { return }
        this.processing = true
        this.$http.get('/api/service/orders/search', {
          params: {
            q: this.orderSearchTerm,
          }
        })
          .then((res) => {
            this.orderSearchResult = res.data
            this.form.order_id = res.data.id
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.processing = false
            this.orderSearchDone = true
          })
      }
    },
    selectOrder () {
      this.purchaseRequest.order_id = this.orderSearchResult.id
      this.form.order_id = this.orderSearchResult.id
      this.order.sap_doc_num = this.orderSearchResult.sap_doc_num
      this.orderEdit = false
      this.resetOrderSearch()
    },
    resetOrderSearch () {
      this.orderSearchTerm = ''
      this.orderSearchResult = null
      this.orderSearchDone = false
    },
    // authorization modal
    openRequestApprovalModal () {
      if (this.ready && this.purchaseRequest) {
        this.requestApprovalEmailText = `Buenas\n\nLa siguiente orden ${this.purchaseRequest.purchase_internal_id} requiere ser revisada para su aprobación\n\nGracias`
        this.requestApprovalModalShow = true
      }
    },
    sendRequestApprovalEmail () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/purchase_requests/${this.id}/send_request_approval_oc`,{
        params: {
          email: this.authorizer.email,
          email_text: this.requestApprovalEmailText,
        }
      })
        .then(response => {
          // console.log(response)
          this.alertShow = true
          this.alertVariant = 'success'
          this.alertMessage = 'Email enviado correctamente...'
        })
        .catch(e => { console.log(e) })
        .finally(() => {
          this.requestApprovalModalShow = false
          this.processing = false
        })
    },
    validateEmail (string) {
      if (string === null) { return false }
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(string)
    },
    approveOrder () {
      if (window.confirm(`¿Confirmar aprobación de orden ${this.purchaseRequest.purchase_internal_id}?`)) {
        if (this.processing) { return }
        this.processing = true
        this.$http.patch(`/api/production/purchase_requests/${this.id}`, {
            sync: true,
            purchase_request: {
              is_approved: true
            }
          })
          .then(response => {
            // console.log(response)
            this.purchaseRequest.is_approved = response.data.is_approved
            if (!this.pinging && this.isValid) {
              this.syncing = true
              setTimeout(() => this.ping(), 2500)
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.processing = false
            this.approveOrderModalShow = false
          })
      }
    },
    focusDiscount () {
      if (this.canEdit) {
        const { discount } = this.purchaseRequest
        this.form.discount = parseFloat(discount)
        this.discountEdit = true
        this.$nextTick(() => {
          this.$refs.discountInput.focus()
          this.$refs.discountInput.select()
        })
      }
    },
    updateDiscount () {
      if (this.form.discount == this.discountPercent * 100) {
        this.discountEdit = false
        return
      }
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/purchase_requests/${this.id}`, {
          sync: false,
          purchase_request: { discount: this.form.discount }
        })
        .then(response => {
          this.purchaseRequest.discount = parseFloat(response.data.discount)
          this.saveTotal()
          this.discountEdit = false
        })
        .catch(e => { console.log(e) })
        .finally(this.processing = false)
    }
  }
}
</script>

<style scoped>
.brand-logo {
  width: 200px;
  height: 70px;
}
#pr-guarantee-section p {
  margin-bottom: .25em;
}
#purchase-total-box {
  padding-right: 40px;
}
#purchase-total-box .second-col {
  width: 120px;
}
#purchase-total-box .third-col {
  width: 150px;
}
#purchase-total-box p, #purchase-total-box span {
  font-size: 18px;
}
#purchase-sigbox {
  border: 2px solid #aaa;
}
#purchase-sigbox .asesor-box {
  border-right: 1px solid #6c757d;
  padding-bottom: 0;
}
#purchase-sigbox .valid-until-box {
  border-left: 1px solid #6c757d;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .brand-logo {
    width: 120px;
    height: 40px;
  }
  #purchase-total-box {
    padding-right: 5px;
  }
  #purchase-total-box .second-col {
    width: 100px;
  }
  #purchase-total-box .third-col {
    width: 125px;
  }
  #purchase-total-box p, #purchase-total-box span {
    font-size: 16px;
  }
  #purchase-sigbox .asesor-box {
    border-right: 0;
    border-bottom: 1px solid #6c757d;
    padding-bottom: 1em;
  }
  #purchase-sigbox .delivery-box {
    padding: 1em 0;
  }
  #purchase-sigbox .valid-until-box {
    border-left: 0;
    border-top: 1px solid #6c757d;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
#purchase-sigbox-accept {
  padding: 0.5em 0;
  background-color: #ddd;
}
.label-size-sm {
  font-size: 11px;
}
.label-size-md {
  font-size: 12px;
}
.label-size-lg {
  font-size: 14px;
}
.fill-purchase-button {
  height: 2em;
  display: inline-flex;
  align-items: center;
}
.fill-purchase-button span {
  font-size: 12px;
}
#right-sidebar tr {
  height: 35px;
}
#right-sidebar tr td {
  vertical-align: middle;
}
</style>
