<template>
  <tr>
    <td colspan="3">
      <b-input-group v-if="inputShow" size="sm">
        <b-input
          v-model="searchTerm"
          placeholder="Ingresar código exacto"
          autofocus
          @keyup.enter="searchAndAdd"
        />
        <b-input-group-append>
          <b-button variant="outline-secondary" size="sm" @click="searchAndAdd">
            <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse fixed-width />
            <span v-else>⏎</span>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <Button v-else size="sm" class="text-success" @click="inputShow = true">
        <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
        <span class="pl-1">Artículo rápido</span>
      </Button>
    </td>
    <td class="align-middle text-danger">
      {{ feedbackMessage }}
    </td>
    <td colspan="4"></td>
  </tr>
</template>

<script>
import Button from '../../shared/Button'

export default {
  name: 'PurchaseRequestItemQuickAddRow',
  components: { Button },
  props: {
    purchaseRequest: {
      type: Object,
      required: false
    },
    purchaseRequestItems: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      searchTerm: '',
      feedbackMessage: '',
      inputShow: false,
      processing: false
    }
  },
  methods: {
    searchAndAdd () {
      if (this.processing) { return }
      this.processing = true
      this.feedbackMessage = ''
      if (this.searchTerm) {
        if (this.purchaseRequestItems.some(item => this.searchTerm.trim().toUpperCase() === item.product.item_code.toUpperCase())) {
          this.feedbackMessage = 'El artículo ya existe en esta solicitud de Compra'
        } else {
          this.$http.get(`/api/production/purchase_requests/${this.purchaseRequest.id}/purchase_request_items/quick_add`, {
            params: {
              q: this.searchTerm.trim()
            }
          })
            .then((res) => {
              console.log(res)
              if (res.data.item) {
                this.reset()
                this.$emit('purchase-request-item-added', res.data.purchase_request_item)
              } else {
                this.feedbackMessage = 'No se encontró el artículo'
              }
            })
            .catch(error => {
              this.feedbackMessage = 'No se encontró el artículo'
            })
        }
      }
      this.processing = false
    },
    reset () {
      this.searchTerm = ''
      this.feedbackMessage = ''
    }
  }
}
</script>
