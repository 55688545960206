<template>
  <div>
    <ContentView v-if="currentUser.role !== 'distribuidor'">
      <Subheader>
        <font-awesome-icon :icon="['far', 'address-book']" fixed-width />
        <span class="pl-1">Clientes</span>
        <template #right v-if="currentUser.role !== 'non_prod' && currentUser.role !== 'gerente'">
          <Button to="/app/prod/cliente" size="sm" variant="orange">
            <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
            <span class="pl-1">Nuevo Cliente</span>
          </Button>
        </template>
      </Subheader>
      <!-- Search Bar -->
      <b-row class="pl-2">
        <b-form-group style="width: 250px">
          <b-input-group size="sm">
            <b-input
              v-model="searchTerm"
              placeholder="Buscar código, cédula o nombre"
              @keyup.enter="search"
            />
            <b-input-group-append>
              <b-button variant="outline-secondary" size="sm" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group class="ml-3">
          <b-button size="sm" @click="resetSearch">Reset</b-button>
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="my-3">
        <PaginationCountless :page="page" :next="next" :prev="prev">
          <template #prev-label>
            <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
          </template>
          <template #next-label>
            <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
          </template>
        </PaginationCountless>
      </b-row>
      <b-table
        :fields="fields"
        :items="clients"
        hover
        responsive
        show-empty
        caption-top
        class="fs-12"
        @row-clicked="onRowClick"
      >
        <!-- Table caption -->
        <template #table-caption>
          <div v-if="count">
            <span v-if="searchMode" class="text-muted">{{ count.toLocaleString() }} resultados</span>
            <span v-else class="text-info">{{ count.toLocaleString() }}+ clientes en total</span>
          </div>
        </template>

        <!-- Loading spinner / empty search text -->
        <template #empty>
          <div v-if="loading" class="text-center text-info">
            <b-spinner small type="grow"></b-spinner>
            <span class="pl-2">Cargando datos...</span>
          </div>
          <div v-else class="text-center text-danger">
            <span>No resultados</span>
          </div>
        </template>

        <!-- Table Data -->
        <template v-slot:cell(client_num)="data">
          <span v-if="data.item.lead" class="text-warning">{{ data.value }}</span>
          <span v-else>{{ data.value }}</span>
        </template>

        <template v-slot:cell(sap_valid)="data">
          <div v-if="data.value == false" class="text-danger">
            <span v-if="data.item.sap_frozen">INACTIVO</span>
            <span v-else>INVÁLIDO</span>
          </div>
          <span v-else></span>
        </template>

        <template v-slot:cell(name)="data">
          <b-link :to="`/app/prod/clientes/${data.item.id}`">{{ data.value }}</b-link>
        </template>
      </b-table>

      <b-row align-h="center" class="mt-3">
        <PaginationCountless :page="page" :next="next" :prev="prev">
          <template #prev-label>
            <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
          </template>
          <template #next-label>
            <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
          </template>
        </PaginationCountless>
      </b-row>
    </ContentView>
  </div>
</template>

<script>
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import PaginationCountless from '../shared/PaginationCountless';
import SearchBar from '../shared/SearchBar';
import Subheader from '../shared/Subheader';

export default {
  name: 'ClientIndex',
  components: { Button, ContentView, PaginationCountless, SearchBar, Subheader },
  data() {
    return {
      clients: [],
      page: 1,
      next: null,
      prev: null,
      count: null,
      searchTerm: '',
      searchMode: false,
      fields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'sap_valid', label: ' ', tdClass: 'clickable' },
        { key: 'name' , label: 'Nombre', tdClass: 'clickable' },
        { key: 'email', tdClass: 'clickable' },
        { key: 'sector', label: 'Sector', tdClass: 'clickable' }
      ],
      loading: false,
      initialLoad: false
    }
  },
  computed: {
    currentUser() { return this.$store.state.currentUser }
  },
  watch: {
    '$route.query' (to, from) {
      this.fetchPaginatedClients();
    }
  },
  mounted() {
    this.initialLoad = true;
    this.fetchPaginatedClients();
  },
  methods: {
    fetchPaginatedClients() {
      this.loading = true;
      let params = { page: this.$route.query.pagina, q: this.searchTerm };
      this.$http.get('/api/production/clients', { params })
        .then(response => {
          console.log(response)
          this.clients = response.data.clients;
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.pagy.count;
          if (!this.initialLoad) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.initialLoad = false;
          this.searchMode = false;
        });
    },
    onRowClick(item) {
      this.$router.push(`/app/prod/clientes/${item.id}`);
    },
    search() {
      let query = Object.assign({}, this.$route.query);
      delete query.pagina;
      this.$router.replace({ query })
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.$http.get('/api/production/clients', {
          params: { q: this.searchTerm }
        })
        .then(response => {
          this.searchMode = true;
          console.log(response)
          this.clients = response.data.clients;
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.pagy.count;
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetSearch() {
      this.searchTerm = '';
      this.$router.push('/app/prod/clientes')
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.fetchPaginatedClients();
    }
  }
}
</script>
