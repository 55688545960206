<template>
  <div class="pb-5">

    <TopShelf>
      <template #action>
        <BackButton to="/app/inst/clientes" />
      </template>
      <template #header>
        <div>
          <span v-if="ready" class="name">{{ distributorClient.name }}</span>
        </div>
      </template>
      <template #side-content>
        <span v-if="ready">{{ distributorClient.client_num }}</span>
      </template>
    </TopShelf>
    <ContentView>
      <b-row align-h="center">
        <b-col lg="6" md="8">
          <b-row align-h="between">
            <div class="dash-card-title">Contacto</div>
            <div class="pr-3">
              <Button size="sm" @click="openContactModal">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-1">Editar</span>
              </Button>
            </div>
          </b-row>
          <div class="dash-card">
            <table class="table table-borderless table-show table-sm">
              <tr>
                <td>Correo Eléctronico:</td>
                <td v-if="ready">{{ distributorClient.email }}</td>
              </tr>
              <tr>
                <td>Cédula:</td>
                <td v-if="ready">{{ distributorClient.cedula }}</td>
              </tr>
              <tr>
                <td>Teléfono:</td>
                <td v-if="ready">{{ distributorClient.phone }}</td>
              </tr>
              <tr>
                <td>Dirección:</td>
                <td v-if="ready">{{ distributorClient.address }}</td>
              </tr>
              <tr>
                <td>Encargado:</td>
                <td v-if="ready">{{ distributorClient.person_in_charge }}</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-col lg="6" md="8">
          <b-row align-h="between">
            <div class="dash-card-title lead">Cotizaciones</div>
            <div class="text-center pr-3 pb-3">
              <Button size="sm" variant="blue" @click="createQuote">Nueva Cotización</Button>
            </div>
            <div class="pr-3">
              <Button size="sm" @click="openPriceListModal">
              <font-awesome-icon :icon="['far', 'edit']" fixed-width />
              <span class="pl-1">Editar</span>
            </Button>
            </div>
          </b-row>
          <div class="dash-card">
            <table class="table table-borderless table-show table-sm">
              <tr>
                <td>Lista de precio por defecto:</td>
                <td v-if="ready">{{ distributorClient.pricing_mode | titleize }}</td>
              </tr>
            </table>
            <hr>
            <b-row v-if="ready" align-h="center">
              <b-col cols="11">
                <b-row v-for="quote in quotes" :key="quote.id" align-h="center" class="mb-3">
                  <b-card
                    bg-variant="light"
                    class="quote-card-link"
                    footer-tag="footer"
                  >
                  <b-container fluid class="quote-link" @click="$router.push(`/app/inst/cotizador/${quote.id}`)">
                    <b-row>
                      <b-col>
                        <span>{{ quote.quote_id }}</span>
                      </b-col>
                      <b-col class="text-right font-weight-bold">
                        <span>{{ quote.total | dollarize }}</span>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <span v-if="quote.title">{{ quote.title }}</span>
                        <span v-else>( Sin titulo )</span>
                      </b-col>
                    </b-row>
                  </b-container>
                    <template #footer>
                      <b-container fluid class="p-0">
                        <b-row>
                          <b-col md="1" offset-md="8">
                            <Button variant="danger" size="sm" class="text-danger" @click="openDeleteModal(quote.id)">
                              <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width class="pr-1 d-none d-sm-inline" />
                              <span>Borrar</span>
                            </Button>
                          </b-col>
                        </b-row>
                      </b-container>
                    </template>
                  </b-card>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Modals -->
    <b-modal
      lazy
      v-model="contactModalShow"
      title="Editar datos de contacto"
    >
      <b-row align-h="center" class="mt-4">
        <b-form style="width: 200px">
          <b-form-group label-size="sm">
            <template #label>
              <span>Nombre</span><span class="asterisk">*</span>
            </template>
            <b-input
              type="text"
              size="sm"
              v-model="contactForm.name"
            />
          </b-form-group>

          <b-form-group
            label="Correo Electrónico"
            label-size="sm"
          >
            <b-input
              type="email"
              size="sm"
              name="form.email"
              v-model="contactForm.email"
            />
          </b-form-group>

          <b-form-group label="Cédula" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="contactForm.cedula"
            />
          </b-form-group>

          <b-form-group label="Teléfono" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="contactForm.phone"
            />
          </b-form-group>

          <b-form-group label="Dirección" label-size="sm">
            <b-textarea
              size="sm"
              v-model="contactForm.address"
            ></b-textarea>
          </b-form-group>

          <b-form-group label="Encargado" label-size="sm">
            <b-input
              type="text"
              size="sm"
              v-model="contactForm.person_in_charge"
            />
          </b-form-group>
        </b-form>
      </b-row>
      <template #modal-footer>
        <Button variant="green" class="float-right" :disabled="!contactFormValid" @click="updateContactInfo">Guardar</Button>
      </template>
    </b-modal>

    <b-modal
      lazy
      centered
      v-model="priceListModalShow"
      title="Editar lista de precio"
    >
      <b-row align-h="center" class="mt-4">
        <b-form style="width: 200px">
          <b-form-group label-size="sm">
            <template #label>
              <span>Lista de precio</span><span class="asterisk">*</span>
            </template>
            <b-radio-group v-model="pricingForm.pricing_mode" :options="priceListOptions" size="sm" stacked></b-radio-group>
          </b-form-group>
        </b-form>
      </b-row>
      <template #modal-footer>
        <Button variant="green" class="float-right" @click="updatePriceList">
          Guardar
        </Button>
      </template>
    </b-modal>

    <b-modal v-model="deleteModalShow" hide-footer centered>
      <template #modal-title>
        <h5>Borrar esta cotización...</h5>
      </template>
      <b-row align-h="center">
        <p class="text-danger">¿Está seguro?</p>
      </b-row>
      <b-row align-h="around">
        <Button @click="deleteModalShow = false">
          Cancelar
        </Button>
        <Button variant="red" @click="deleteQuote">
          Sí, Borrar
        </Button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { addDays } from 'date-fns'
import BackButton from '../shared/BackButton'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import ToastAlert from '../shared/ToastAlert'
import TopShelf from '../shared/TopShelf'
// import InstallerNavTabs from './InstallerNavTabs'


export default {
  name: 'InstallerClientShow',
  components: { BackButton, Button, ContentView, ToastAlert, TopShelf },
  data () {
    return {
      id: Number(this.$route.params.id),
      distributorClient: null,
      quotes: [],
      ready: false,
      processing: false,
      contactModalShow: false,
      contactForm: {
        name: null,
        email: null,
        cedula: null,
        phone: null,
        address: null,
        person_in_charge: null,

      },
      priceListModalShow: false,
      pricingForm: {
        pricing_mode: null
      },
      priceListOptions: [
        { text: 'Público', value: 'público' },
        { text: 'Instalador', value: 'instalador' }
      ],
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      deleteModalShow: false,
      selectedQuoteId: ''
    }
  },
  computed: {
    contactFormValid () {
      return this.contactForm.name
    }
  },
  mounted () {
    this.fetchDistributorClientData()
  },
  methods: {
    fetchDistributorClientData () {
      this.ready = false
      this.$http.get(`/api/contractors/distributor_clients/${this.id}`)
        .then((res) => {
          console.log(res)
          this.distributorClient = res.data.distributor_client
          this.quotes = res.data.quotes
          this.ready = true
        })
        .catch(e => console.log(e))
    },
    createQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post('/api/contractors/quotes', {
          quote: {
            distributor_client_id: this.id,
            pricing_mode: this.distributorClient.pricing_mode,
            valid_until: addDays(new Date, 3),
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          }
        })
        .then((res) => {
          console.log(res)
          this.$router.push(`/app/inst/cotizador/${res.data.id}`)
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    openContactModal () {
      this.contactModalShow = true
      this.contactForm.name = this.distributorClient.name
      this.contactForm.email = this.distributorClient.email
      this.contactForm.cedula = this.distributorClient.cedula
      this.contactForm.phone = this.distributorClient.phone
      this.contactForm.address = this.distributorClient.address
      this.contactForm.person_in_charge = this.distributorClient.person_in_charge
    },
    updateContactInfo () {
      if (this.processing) return
      this.processing = true
      this.contactModalShow = false
      this.$http.put(`/api/contractors/distributor_clients/${this.id}`, {
          distributor_client: this.contactForm
        })
        .then((res) => {
          console.log(res.data)
          this.distributorClient.name = res.data.name
          this.distributorClient.email = res.data.email
          this.distributorClient.cedula = res.data.cedula
          this.distributorClient.phone = res.data.phone
          this.distributorClient.address = res.data.address
          this.distributorClient.person_in_charge = res.data.person_in_charge
          this.alertVariant = 'success'
          this.alertMessage = 'Se guardaron los cambios'
        })
        .catch(error => {
          this.alertVariant = 'danger'
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo guardar los cambios'
          }
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
        })
    },
    openPriceListModal () {
      this.priceListModalShow = true
      this.pricingForm.pricing_mode = this.distributorClient.pricing_mode
    },
    updatePriceList () {
      if (this.processing) { return }
      this.processing = true
      this.priceListModalShow = false
      this.$http.put(`/api/contractors/distributor_clients/${this.id}`, {
          distributor_client: this.pricingForm
        })
        .then((res) => {
          console.log(res.data)
          this.distributorClient.pricing_mode = res.data.pricing_mode
          this.alertVariant = 'success'
          this.alertMessage = 'Los cambios se guardaron'
        })
        .catch(error => {
          this.alertVariant = 'danger'
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo guardar los cambios'
          }
        })
        .finally(() => {
          this.processing = false
          this.alertShow = true
        })
    },
    openDeleteModal(quoteId){
      this.deleteModalShow = true
      this.selectedQuoteId = quoteId
    },
    deleteQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/contractors/quotes/${this.selectedQuoteId}`)
        .then(response => {
          console.log(response)
          this.deleteModalShow = false
          this.fetchDistributorClientData()
        })
        .catch(error => this.$emit('process-failed'))
        .finally(this.processing = false)
    }
  }
}
</script>

<style scoped>
.quote-card-link {
  width: 300px;
  transition: all .2s ease-in-out;
}
.quote-card-link:hover {
  transform: scale(1.05);
}
.b-form-group {
  margin-bottom: 2rem !important;
}
.quote-link{
  cursor:pointer;
}
</style>
