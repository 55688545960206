<template>
  <b-container>
    <button v-if="direction == 'top'" @click="scrollToTop" id="scrollToTopBtn" v-show="isVisible" title="Go to top">
      <span class="icon">&#8593;</span>
    </button>
    <button v-else @click="scrollToBottom" id="scrollToTopBtn" title="Go to top">
      <span class="icon">&#8595;</span>
    </button>
  </b-container>
</template>

<script>
export default {
  name: 'ScrollToBtn',
  props: {
    threshold: {
      type: Number,
      required: true
    },
    direction: {
      // valid options: top, bottom
      type: String,
      required: false,
      default: 'top'
    }

  },
  data () {
    return {
      isVisible: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  } ,
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    },
    handleScroll() {
      this.isVisible = window.scrollY > this.threshold
    },
    scrollToBottom () {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        left: 0,
        behavior: "smooth"
      })
    }
  }
}

</script>

<style lang="scss" scoped>
#scrollToTopBtn {
  display: block;
  position: fixed;
  opacity: 0.6;
  bottom: 75px;
  right: 20px;
  z-index: 99;
  background-color: #163D7D;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    color: white;
    opacity: 1;
  }
}

</style>

