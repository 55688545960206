<template>
  <b-container fluid>
    <b-row align-h="center" class="top-shelf py-4">
      <b-col lg="2" class="pl-5 pb-4 pb-sm-0">
        <slot name="action"></slot>
      </b-col>
      <b-col lg="6">
        <slot name="header"></slot>
      </b-col>
      <b-col lg="3" class="text-right">
        <slot name="side-content"></slot>
      </b-col>
      <b-col lg="1"></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'TopShelf'
}
</script>
