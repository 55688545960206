<template>
  <div v-if="isAdminCourses || isDev">
    <div class="pt-3 px-3">
      <b-row class="mb-2">
        <b-col order="3" order-md="1" order-lg="1" cols="12" md="8" lg="3">
          <h3 class="mb-2">Cursos</h3>
          <!-- Search Bar -->
          <div class="d-flex">
            <b-form-group style="width: 290px" class="w-100">
              <b-input-group>
                <b-input
                  v-model="searchTerm"
                  autofocus
                  placeholder="Buscar por nombre, categoría"
                  @keyup.enter="search"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" size="sm" @click="search">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group class="ml-3">
              <b-button variant="outline-secondary" @click="resetSearch">
                <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
              </b-button>
            </b-form-group>
          </div>
        </b-col>
        <b-col order="2" order-md="3" order-lg="2" cols="12" lg="6">
          <div class="d-grid justify-content-center align-items-center border p-4">
            <p class="text-secondary">Crear curso</p>
            <b-row>
              <b-col>
                <ValidationObserver ref="observer1" v-slot="{ invalid }">
                  <b-row>
                    <b-col lg="10">
                      <ValidationProvider rules="required" name="Nombre" v-slot="{ valid, errors }">
                        <b-form class="pb-1" :invalid-feedback="errors[0]">
                          <b-row>
                            <b-col sm="3" lg="3">
                              <span>Nombre:</span><span class="asterisk pr-3 ">*</span>
                            </b-col>
                            <b-col sm="9" lg="9">
                              <b-input type="text" id="course-name" style="width: 100%;" v-model="createCourseForm.name" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationProvider>
                      <ValidationProvider rules="required" name="Categoria" v-slot="{ valid, errors }">
                        <b-form label-size="sm" :invalid-feedback="errors[0]">
                          <b-row class="pt-1">
                            <b-col sm="3" lg="3" class="px-0">
                              <span class="pl-3">Categoría:</span><span class="asterisk">*</span>
                            </b-col>
                            <b-col sm="9" lg="9" class="px-3">
                              <b-select
                                id="course-category"
                                v-model="createCourseForm.category"
                                :options="categoryOptions"
                                class="px-3"
                                size="sm"
                                style="width: 100%;"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <option :value="null" disabled>-- Eligir uno --</option>
                                </template>
                              </b-select>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="2" class="mt-2 mt-lg-0">
                      <Button variant="green" :disabled="invalid" @click="createCourseModal = true">
                        Crear
                      </Button>
                    </b-col>
                  </b-row>
                </ValidationObserver>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col order="1" order-md="2" order-lg="3" cols="12" md="4" lg="3">
          <div class="d-flex w-auto flex-wrap justify-content-center align-items-center border p-2 my-2 my-lg-0">
            <b-button variant="outline-info"  class="m-1" @click="openSummary()">
              <font-awesome-icon :icon="['far', 'file-alt']" fixed-width />
              Resumen
            </b-button>
            <b-button variant="outline-success" class="m-1"  @click="downloadReport()">
              <font-awesome-icon :icon="['fas', 'file-csv']" fixed-width />
              <span>Reporte</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- Table caption -->
      <b-row align-h="between" align-v="center" class="py-2 py-lg-3 px-2">
        <b-col class="pl-1">
          <div v-if="count" class="fs-16">
            <span class="text-primary">Total cursos: {{ count.toLocaleString() }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0">

    <!-- Main content -->
    <b-row align-h="center" v-if="loading" class="text-info">
      <b-spinner small type="grow"></b-spinner>
      <span class="pl-2">Cargando...</span>
    </b-row>
    <div v-else>
      <b-row align-v="start" class="mb-1 mx-0" v-if="courses.length > 0">
        <!-- TODO: make responsive, ej: tabs horizontal in sm, vertical in lg -->
        <b-col cols="3" class="pr-0">
          <b-list-group  v-for="(course, index) in courses" :key="course.id" >
            <b-list-group-item
              button
              :active="course.id == activeCourseTab"
              :class="{'active-course': course.id == activeCourseTab }"
              class="pl-2"
              @click="rowClick(course, index)"
            >
              <span class="fs-13">{{course.name}}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="9" align-self="start">
          <b-card v-if="selectedCourse">
            <!-- Main content -->
            <b-row>
              <b-col>
                <!-- Title -->
                <b-row align-h="center" class="pb-1 fs-18 text-center">
                  <b-col>
                    <b class="text-center">{{ selectedCourse.name | titleize }}</b>
                  </b-col>
                </b-row>
                <!-- Course content -->
                <b-row class="fs-15">
                  <b-col cols="12" md="6">
                    <!-- category -->
                    <div class="pb-1" style="padding-top: 4px;">
                      <div v-if="courseEdit" class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Categoría: </span>
                        <b-select
                          id="course-category1"
                          v-model="editCourseForm.category"
                          :options="categoryOptions"
                          size="sm"
                          style="width: 20rem;"
                        >
                          <template v-slot:first>
                            <option :value="null" disabled>-- Eligir uno --</option>
                          </template>
                        </b-select>
                      </div>
                      <div v-else class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Categoría: </span>
                        <span>{{ selectedCourse.category }}</span>
                      </div>
                    </div>
                    <!-- is_active -->
                    <div class="pb-1">
                      <div v-if="courseEdit" class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Status: </span>
                        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0 d-flex">
                          <b-form-radio-group v-model="editCourseForm.is_active" :aria-describedby="ariaDescribedby" name="is_active">
                            <b-form-radio :value="true">Activo</b-form-radio>
                            <b-form-radio :value="false">Inactivo</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </div>
                      <div v-else class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Status: </span>
                        <span v-if="selectedCourse.is_active">
                          <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
                        </span>
                        <span v-else>
                          <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
                        </span>
                      </div>
                    </div>
                    <!-- has_cost -->
                    <div class="pb-1">
                      <div v-if="courseEdit" class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Tiene costo: </span>
                        <b-form-group  v-slot="{ ariaDescribedby }" class="mb-0">
                          <b-form-radio-group v-model="editCourseForm.has_cost" :aria-describedby="ariaDescribedby" name="has_cost">
                            <b-form-radio :value="true">Si</b-form-radio>
                            <b-form-radio :value="false">No</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </div>
                      <div v-else class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Tiene costo: </span>
                        <span v-if="selectedCourse.has_cost">
                          Sí
                        </span>
                        <span v-else>
                          No
                        </span>
                      </div>
                    </div>
                    <!-- total, currency -->
                    <div v-if="editCourseForm.has_cost">
                      <div v-if="courseEdit" class="d-flex">
                        <p class="font-weight-bold" style="width: 6.25rem">Costo: </p>
                        <div style="width: 20rem;">
                          <b-input-group>
                            <template #prepend>
                              <b-dropdown
                                id="courseCurrency"
                                size="sm"
                                variant="outline-primary"
                                v-model="editCourseForm.currency"
                                :text="editCourseForm.currency == 'USD' ? 'Dólares $' : 'Colones ₡'"
                              >
                                <b-dropdown-item @click="updateCurrency('USD')" :active="editCourseForm.currency == 'USD'">Dólares $</b-dropdown-item>
                                <b-dropdown-item @click="updateCurrency('COL')" :active="editCourseForm.currency == 'COL'">Colones ₡</b-dropdown-item>
                              </b-dropdown>
                            </template>
                            <b-form-input
                              ref="courseTotal"
                              v-model="editCourseForm.total"
                              value=""
                              size="sm"
                              placeholder="Costo del curso"
                              autofocus
                              outline
                              type="number"
                              min="0.00"
                              required
                            ></b-form-input>
                          </b-input-group>
                        </div>
                      </div>
                      <div v-else class="d-flex">
                        <span class="font-weight-bold" style="width: 6.25rem">Costo: </span>
                        <p>
                          <span v-if="selectedCourse.currency == 'USD'">{{ selectedCourse.total | dollarize }}</span>
                          <span v-else>{{ selectedCourse.total | colones2 }}</span>
                        </p>
                      </div>
                    </div>
                  </b-col>
                  <b-col >
                    <div v-if="courseEdit" class="d-flex">
                      <b-button size="sm" variant="danger" @click="courseEdit = false" class="m-1" style="width: 6.25rem;">
                        <font-awesome-icon :icon="['far', 'times']" fixed-width />
                        <span>Cancelar</span>
                      </b-button>
                      <b-button size="sm" variant="success" :disabled="updateCourseValidationForm" @click="updateCourse()" class="m-1" style="width: 6.25rem;">
                        <font-awesome-icon :icon="['far', 'save']" fixed-width />
                        <span>Guardar</span>
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button size="sm" variant="warning" @click="editCourse()" class="m-1" style="width: 6.25rem;">
                        <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                        <span>Editar</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <br>
            <!-- Users -->
            <b-row class="fs-16 pb-2">
              <b-col>
                <u class="font-weight-bold">Empleados del curso:</u>
              </b-col>
            </b-row>
            <!-- add course and filter -->
            <b-row align-h="between">
              <b-col cols="4">
                <b-form-group class="mb-1">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-user-input1"
                      v-model="userFilter"
                      type="search"
                      placeholder="Filtrar usuario"
                      :disabled="courseUsers.length < 1 || !selectedCourse.is_active"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!userFilter" @click="userFilter = ''">Limpiar</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <Button  variant="blue" :disabled="!selectedCourse.is_active" class="ml-1" @click="addCourseUserModal = true">
                  <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
                </Button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  sticky-header
                  :items="courseUsers"
                  :fields="userFields"
                  :filter-included-fields="userFilterOn"
                  :filter="userFilter"
                  head-variant="light"
                  sort-icon-left
                  show-empty
                  fixed
                  caption-top
                  no-border-collapse
                  class="fs-14"
                >
                <template #table-caption v-if="expiredCourseUsers.total > 0">
                  Total: <span class="text-dark">{{ expiredCourseUsers.total }} </span> |
                  Expirados: <span class="text-danger">{{ expiredCourseUsers.expired }} </span> |
                  Vencen hoy: <span class="text-warning">{{ expiredCourseUsers.warning }} </span>
                </template>
                <template #head()="scope">
                  <div class="text-nowrap">
                    {{scope.label}}
                  </div>
                </template>
                <template #empty>
                  <div class="text-center text-danger">
                    <span>Sin resultados</span>
                  </div>
                </template>
                <template v-slot:cell(user)="data">
                  <span class="font-weight-bold">{{ data.value | concatName2 | titleize }}</span>
                </template>
                <template v-slot:cell(due_on)="data">
                  <div v-if="courseItemAction == 'user-due_on' && data.item.id === selectedCourseUser.id">
                    <b-input
                      v-model="courseUserForm.due_on"
                      size="sm"
                      type="date"
                      ref="due_on"
                      style="width: 100%;"
                      @blur="updateCourseUser"
                      @keyup.enter="updateCourseUser"
                    />
                  </div>
                  <div v-else-if="!selectedCourse.is_active">
                    <span v-if="currentDate > data.value" class="text-danger">
                      <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                      {{ data.value | formatDateCompact }}
                    </span>
                    <span v-else-if="currentDate == data.value" class="text-warning">{{ data.value | formatDateCompact }}</span>
                    <span v-else class="text-success">{{ data.value | formatDateCompact }}</span>
                  </div>
                  <div v-else  @click="onRowUserClick(data, 'user-due_on'); focus('due_on')">
                    <div v-if="data.value" class="click-to-edit">
                      <span v-if="currentDate > data.value" class="text-danger">
                        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                        {{ data.value | formatDateCompact }}
                      </span>
                      <span v-else-if="currentDate == data.value" class="text-warning">{{ data.value | formatDateCompact }}</span>
                      <span v-else class="text-success">{{ data.value | formatDateCompact }}</span>
                    </div>
                    <b-button v-else  size="sm" variant="outline-warning" @click="editModalShow = true">
                      <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                      <span>Llenar</span>
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(notes)="data">
                  <div v-if="courseItemAction == 'user-notes' && data.item.id === selectedCourseUser.id">
                    <b-form-textarea
                      v-model="courseUserForm.notes"
                      size="sm"
                      ref="notes"
                      rows="3"
                      max-rows="5"
                      style="width: 100%"
                      @blur="updateCourseUser"
                      @keyup.enter="updateCourseUser"
                    />
                  </div>
                  <div v-else-if="!selectedCourse.is_active" class="text-truncate">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                  <div v-else class="click-to-edit text-truncate" @click="onRowUserClick(data, 'user-notes'); focus('notes')">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <Button
                    size="sm"
                    variant="blue"
                    class="ml-2"
                    @click="onRowUserClick(data, 'attach-file'); documentsModal= true"
                    :disabled="!selectedCourse.is_active"
                  >
                    <font-awesome-icon :icon="['far', 'file-alt']" fixed-width />
                  </Button>
                  <Button
                    size="sm"
                    variant="red"
                    class="ml-2"
                    @click="onRowUserClick(data, 'user-delete');deleteConfirmationModal= true;"
                    :disabled="!selectedCourse.is_active"
                  >
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </Button>
                </template>
                </b-table>
              </b-col>
            </b-row>
            <br>
            <!-- Clients -->
            <b-row class="fs-16 pb-2">
              <b-col>
                <u class="font-weight-bold">Clientes del curso:</u>
              </b-col>
            </b-row>
            <!-- add and filter -->
            <b-row align-h="between">
              <b-col cols="4">
                <b-form-group
                  class="mb-1"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-user-input2"
                      v-model="clientFilter"
                      type="search"
                      placeholder="Filtrar cliente"
                      :disabled="courseClients.length < 1 || !selectedCourse.is_active"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!clientFilter" @click="clientFilter = ''">Limpiar</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <Button  variant="blue" :disabled="!selectedCourse.is_active" class="ml-1" @click="addCourseClientModal = true">
                  <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
                </Button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  sticky-header
                  :items="courseClients"
                  :fields="clientFields"
                  :filter-included-fields="clientFilterOn"
                  :filter="clientFilter"
                  head-variant="light"
                  sort-icon-left
                  show-empty
                  fixed
                  caption-top
                  no-border-collapse
                  class="fs-14"
                >
                <template #table-caption v-if="courseClients.length > 0">
                  Total: <span class="text-dark">{{ courseClients.length }} </span>
                </template>
                <template #empty>
                  <div class="text-center text-danger">
                    <span>Sin resultados</span>
                  </div>
                </template>
                <template v-slot:cell(client)="data">
                  <span class="font-weight-bold">{{ data.value.client_num }} - </span>
                  <span>{{ data.value.name }}</span>
                  <!-- <b-link :to="`/app/prod/clientes/${data.value.id}`" class="related-link">
                    {{ data.value }}
                  </b-link> -->
                </template>
                <template v-slot:cell(contact_person)="data">
                  <div v-if="courseItemAction == 'client-contact' && data.item.id === selectedCourseClient.id">
                    <b-input
                      v-model="courseClientForm.contact_person"
                      size="sm"
                      ref="contact"
                      @blur="updateCourseClient"
                      @keyup.enter="updateCourseClient"
                    />
                  </div>
                  <div v-else-if="!selectedCourse.is_active">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                  <div v-else class="click-to-edit" @click="onRowClientClick(data, 'client-contact'); focus('contact')">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                </template>
                <template v-slot:cell(contact_email)="data">
                  <div v-if="courseItemAction == 'client-email' && data.item.id === selectedCourseClient.id">
                    <ValidationProvider rules="email" name="email-client-input" v-slot="{ valid, errors }">
                      <b-input
                        v-model="courseClientForm.contact_email"
                        size="sm"
                        ref="email-client"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @blur="valid ? updateCourseClient() : courseItemAction = null"
                        @keyup.enter="valid ? updateCourseClient() : courseItemAction = null"
                      />
                    </ValidationProvider>
                  </div>
                  <div v-else-if="!selectedCourse.is_active">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                  <div v-else class="click-to-edit" @click="onRowClientClick(data, 'client-email'); focus('email-client')">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                </template>
                <template v-slot:cell(notes)="data">
                  <div v-if="courseItemAction == 'client-notes' && data.item.id === selectedCourseClient.id">
                    <b-form-textarea
                      v-model="courseClientForm.notes"
                      size="sm"
                      ref="client-notes"
                      rows="3"
                      max-rows="6"
                      style="width: 100%"
                      @blur="updateCourseClient"
                      @keyup.enter="updateCourseClient"
                    />
                  </div>
                  <div v-else-if="!selectedCourse.is_active" class="text-truncate">
                    <span v-if="data.value">{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                  <div v-else class="click-to-edit text-truncate" @click="onRowClientClick(data, 'client-notes'); focus('client-notes')">
                    <span v-if="data.value" >{{ data.value }}</span>
                    <span v-else class="text-center text-secondary"> - </span>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <Button
                    size="sm"
                    variant="red"
                    class="ml-2"
                    @click="onRowClientClick(data, 'client-delete'); deleteConfirmationModal= true;"
                    :disabled="!selectedCourse.is_active"
                  >
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </Button>
                </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-else>
            <b-row align-h="center">
              <span class="text-warning">Seleccione un curso</span>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else align-v="center">
        <b-col>
          <p class="text-danger text-center">Sin resultados</p>
        </b-col>
      </b-row>
    </div>
    <!-- Page tab -->
    <b-row align-h="center" class="mt-3">
      <PaginationCountless :page="page" :next="next" :prev="prev">
        <template #prev-label>
          <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
        </template>
        <template #next-label>
          <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
        </template>
      </PaginationCountless>
    </b-row>

    <!-- CONFIRMATION: Create Course -->
    <b-modal
      v-model="createCourseModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'question-circle']"  class="text-info modal-icon" />
      </b-row>
      <b-row align-h="center">
        <span class="fs-20 text-center">¿Desea crear el curso "<b>{{createCourseForm.name}}</b>" ?</span>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="createCourseModal = false">Cancelar</Button>
        <Button variant="green" @click="verifyCourseName()">Continuar</Button>
      </b-row>
    </b-modal>

    <!-- CONFIRMATION: Delete-->
    <b-modal
      v-model="deleteConfirmationModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'question-circle']" class="text-info modal-icon" />
      </b-row>
      <b-row align-h="center">
        <span v-if="courseItemAction == 'user-delete'" class="fs-20 text-center">¿Desea eliminar el usuario "<b>{{ selectedCourseUser.user  | concatName2 | titleize }}</b>" ?</span>
        <span v-else-if="courseItemAction == 'client-delete'" class="fs-20 text-center">¿Desea eliminar el cliente "<b>{{ selectedCourseClient.client.name }}</b>" ?</span>
        <span v-else-if="courseItemAction == 'attach-file'" class="fs-20 text-center">¿Desea eliminar el archivo?</span>
      </b-row>
      <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
        <small class="text-center">NOTA: El proceso es irreversible</small>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="deleteConfirmationModal = false">No</Button>
        <Button v-if="courseItemAction == 'user-delete'" variant="green" @click="deleteCourseUser()">Sí</Button>
        <Button v-else-if="courseItemAction == 'client-delete'" variant="green" @click="deleteCourseClient()">Sí</Button>
        <Button v-else-if="courseItemAction == 'attach-file'" variant="green" @click="DeleteCourseUserFile()">Sí</Button>
      </b-row>
    </b-modal>

    <!-- attach user file: course user -->
    <b-modal
      v-model="documentsModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      size="lg"
      @show="fetchingFiles = true"
      @shown="fetchCourseUserFiles"
      @hide="onHideDocumentsModal"
    >
      <b-row align-h="center" v-if="selectedCourseUser">
        <b-col>
          <h5 class="text-center">
            {{ selectedCourse.name }} |
            {{ selectedCourseUser.user | concatName2 | titleize }}
          </h5>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="4">
          <p>Subir archivos:</p>
        </b-col>
        <b-col cols="8">
          <p class="text-center">Archivos subidos:</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <FileUploader @file-added="createCourseUserFile"/>
        </b-col>
        <b-col cols="8" class="border-left">
          <b-row v-if="fetchingFiles" align-h="center">
            <div class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando archivos...</span>
            </div>
          </b-row>
          <b-row v-else>
            <b-col v-if="documents.length > 0 & !fetchingFiles">
              <b-row v-for="(doc, index) in documents" :key="doc.id">
                <b-col cols="10">
                  <b-list-group>
                    <b-list-group-item>
                      <b-link @click="downloadUserFile(doc)">{{doc.name}}</b-link>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2" class="p-0" align-self="center">
                  <Button size="sm" variant="red" @click="onDocumentClick(doc, index); deleteConfirmationModal = true">
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </Button>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else>
              <div class="text-center text-warning">
                <span>Sin archivos registrados</span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <b-row align-h="around">
        <Button variant="orange" @click="documentsModal = false">salir</Button>
      </b-row>
    </b-modal>

    <!-- NOTICE: Duplicate -->
    <b-modal
      v-model="duplicatedCourseModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'exclamation-circle']"  class="text-warning modal-icon" />
      </b-row>
      <b-row align-h="center">
        <span class="fs-20 text-center">Ya existe un curso llamado "<b>{{createCourseForm.name}}</b>"</span>
      </b-row>
      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="duplicatedCourseModal = false; createCourseModal = false">Regresar</Button>
      </b-row>
    </b-modal>

    <!-- CREATION: Add course client -->
    <b-modal
      v-model="addCourseClientModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      @hidden="onAddClientHide"
    >
      <b-row align-h="center">
        <u>
          <b>Agregar cliente</b>
        </u>
      </b-row>
      <b-row align-h="center">
        <b-col>
          <ValidationObserver   ref="observer2" v-slot="{ invalid }">
            <!-- Client -->
            <b-form class="pb-1">
              <b-row>
                <b-col>
                  <span>Cliente:</span><span class="asterisk">*</span>
                  <ClientSearchInput
                    v-if="!courseClientForm.client_id"
                    @assign-client="assignClientForm"
                  />
                  <b-form-group v-else>
                    <b-input-group size="sm">
                      <b-input  type="text" id="client-name" :disabled="true" style="width: 80%;" v-model="courseClientNameForm"/>
                      <b-input-group-append>
                        <b-button @click="courseClientForm.client_id = null; courseClientNameForm = null" variant="danger">
                          <font-awesome-icon :icon="['far', 'times']" fixed-width />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <!-- Contact person -->
            <ValidationProvider :rules="{required:true, max: 49}" name="contact-person" v-slot="{ valid, errors }">
              <b-form class="pb-1" :invalid-feedback="errors[0]">
                <b-row>
                  <b-col>
                    <span>Persona contacto:</span><span class="asterisk">*</span>
                    <b-input type="text" id="contact-person" placeholder="Nombre contacto" style="width: 100%;" v-model="courseClientForm.contact_person" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- Contact email -->
            <ValidationProvider rules="email" name="contact-email1" v-slot="{ valid, errors }">
              <b-form class="pb-1" :invalid-feedback="errors[0]">
                <b-row>
                  <b-col>
                    <span>Correo contacto:</span>
                    <b-input type="text" id="contact-email1" placeholder="cliente@example.com" style="width: 100%;" v-model="courseClientForm.contact_email" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- notes -->
            <ValidationProvider name="Notas" :rules="{required:false, max: 150}" v-slot="{ valid, errors }">
              <b-form-group label-size="sm" >
                <template #label>
                  <span class="fs-18">Notas:</span>
                </template>
                <b-textarea
                  placeholder="Notas cliente"
                  v-model="courseClientForm.notes"
                  size="sm"
                  rows="3"
                  :state="errors[0] ? false : (valid ? true : null)"
                />
              </b-form-group>
            </ValidationProvider>
            <b-row align-h="center" v-if="courseClientDuplicated" class="text-center">
              <small class="text-danger">
                <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                Ya existe el cliente en este curso
              </small>
            </b-row>
            <b-row align-h="around" class="my-4">
              <Button variant="red" @click="addCourseClientModal = false">Cancelar</Button>
              <Button variant="green" :disabled="invalid || !courseClientForm.client_id || courseClientDuplicated" @click="addCourseClientModal = false; createCourseClient()">Agregar</Button>
            </b-row>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-modal>

    <!-- CREATION: Add course user -->
    <b-modal
      v-model="addCourseUserModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      @hide="onAddUserHide"
    >
      <b-row align-h="center">
        <u>
          <b>Agregar empleado</b>
        </u>
      </b-row>
      <b-row align-h="center">
        <b-col>
          <ValidationObserver   ref="observer2" v-slot="{ invalid }">
            <!-- User -->
            <b-form class="pb-1">
              <b-row>
                <b-col>
                  <span>Empleado:</span><span class="asterisk">*</span>
                  <div v-if="!courseUserForm.user_id">
                    <b-form-group>
                      <b-input-group
                        v-if="!selectedSearchUser"
                        size="sm">
                        <b-input
                          v-model="userSearchTerm"
                          type="text"
                          autofocus
                          :style="{ 'min-width': '300px' }"
                          placeholder="Buscar nombre, apellido"
                          @keyup.enter="searchUsers"
                        />
                        <b-input-group-append>
                          <b-button :disabled="!userSearchTerm" @click="searchUsers">
                            <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <div v-show="userSearchTouch" style="width: 350px; max-height: 200px" class="overflow-auto">
                      <b-table
                        :items="userSearchResults"
                        :fields="userSearchFields"
                        hover
                        outlined
                        show-empty
                        empty-text="No hay resultados.  Intente de nuevo"
                        class="fs-13"
                        style="min-width: 200px"
                        @row-clicked="selectSearchUser"
                      />
                    </div>
                  </div>
                  <b-form-group v-else>
                    <b-input-group size="sm">
                      <b-input  type="text" id="client-name" :disabled="true" style="width: 80%;" v-model="courseUserNameForm"/>
                      <b-input-group-append>
                        <b-button @click="resetSearchUser()" variant="danger">
                          <font-awesome-icon :icon="['far', 'times']" fixed-width />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <!-- Due on -->
            <ValidationProvider :rules="{required:true, max: 49}" name="due-on" v-slot="{ valid, errors }">
              <b-form class="pb-1" :invalid-feedback="errors[0]">
                <b-row>
                  <b-col>
                    <span>Caducidad:</span><span class="asterisk">*</span>
                    <b-input type="date" id="client-due-on" style="width: 100%;" v-model="courseUserForm.due_on" size="sm" :state="errors[0] ? false : (valid ? true : null)" />
                  </b-col>
                </b-row>
              </b-form>
            </ValidationProvider>
            <!-- notes -->
            <ValidationProvider name="Notas" :rules="{required:false, max: 150}" v-slot="{ valid, errors }">
              <b-form-group label-size="sm" >
                <template #label>
                  <span class="fs-18">Notas:</span>
                </template>
                <b-textarea
                  placeholder="Notas cliente"
                  v-model="courseUserForm.notes"
                  size="sm"
                  rows="3"
                  :state="errors[0] ? false : (valid ? true : null)"
                />
              </b-form-group>
            </ValidationProvider>
            <b-row align-h="center" v-if="courseUserDuplicated" class="text-center">
              <small class="text-danger">
                <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                Ya existe el empleado en este curso
              </small>
            </b-row>
            <b-row align-h="around" class="my-4">
              <Button variant="red" @click="addCourseUserModal = false; onAddUserHide()">Cancelar</Button>
              <Button variant="green" :disabled="invalid || !courseUserForm.user_id || courseUserDuplicated" @click="addCourseUserModal = false; createCourseUser()">Agregar</Button>
            </b-row>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Course Summary modal -->
    <CourseSummaryModal
      @modal-hidden="closeSummary"
    />

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CourseSummaryModal from './CourseSummaryModal';
import PaginationCountless from '../../shared/PaginationCountless';
import Button from '../../shared/Button.vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { normalize, schema } from 'normalizr';
import ClientSearchInput from '../../shared/ClientSearchInput'
import { format } from 'date-fns'
import FileUploader from '../../shared/FileUploader.vue';
import ToastAlert from '../../shared/ToastAlert';

export default {
  name: 'Index',
  components: { PaginationCountless, Button, ValidationObserver, ValidationProvider, ClientSearchInput, CourseSummaryModal, FileUploader, ToastAlert },
  data() {
    return {
      currentDate: '',
      coursesItem: {},
      coursesItemList: [],
      courseUserData: {},
      courseUserList: [],
      courseClientData: {},
      courseClientList: [],
      documentData: {},
      documentList: [],
      page: 1,
      next: null,
      prev: null,
      count: null,
      searchTerm: '',
      queryMode: false,
      userFields: [
        { key: 'user', stickyColumn: true, label: 'Empleado', sortable: true, thStyle: { width: '150px' } },
        { key: 'due_on', label: 'Caducidad', sortable: true, tdClass: 'text-left', thStyle: { width: '150px' } },
        { key: 'notes', label: 'Notas', thStyle: { width: '500px' } },
        { key: 'actions', label: '', tdClass: 'align-middle text-right', thStyle: { width: '120px' } }
      ],
      clientFields: [
        { key: 'actions', stickyColumn: true, label: '', tdClass: 'align-middle text-right', thStyle: { width: '70px' } },
        { key: 'client', label: 'Cliente', sortable: true, thStyle: { width: '350px' } },
        { key: 'contact_person', label: 'Contacto', tdClass: 'text-left', thStyle: { width: '250px' } },
        { key: 'contact_email', label: 'Email', thStyle: { width: '250px' } },
        { key: 'notes', label: 'Notas', thStyle: { width: '400px' }  }
      ],
      categoryOptions: [
        { value: 'General de inducción', text: 'General de inducción' },
        { value: 'Especial', text: 'Especial' }
      ],
      createCourseForm: {
        name: null,
        category: null
      },
      editCourseForm: {
        category: null,
        is_active: true,
        has_cost: false,
        currency: false,
        total: false,
      },
      courseUserForm:{
        due_on: null,
        notes: null,
        user_id: null
      },
      courseClientForm:{
        client_id: null,
        contact_person: null,
        contact_email: null,
        notes: null
      },
      courseClientNameForm: null,
      courseUserNameForm: null,
      courseEdit: false,
      courseItemAction: null,
      loading: false,
      processing: false,
      fetchingFiles: false,
      noScroll: false,
      createCourseModal: false,
      deleteConfirmationModal: false,
      documentsModal: false,
      duplicatedCourseModal: false,
      addCourseUserModal: false,
      addCourseClientModal: false,
      selectedDocument: null,
      selectedDocumentIndex: null,
      selectedCourse: null,
      selectedCourseIndex: null,
      selectedCourseUser: null,
      selectedCourseUserIndex: null,
      selectedCourseClient: null,
      selectedCourseClientIndex: null,
      duplicates: null,
      userFilter: null,
      userFilterOn: ['user'],
      clientFilter: null,
      clientFilterOn: ['client'],
      selectedSearchUser: null,
      userSearchTerm: '',
      userSearchTouch: false,
      userSearchResults: [],
      userSearchFields:[
        { key: 'first_name', label: 'Nombre', tdClass: 'clickable' },
        { key: 'last_name', label: 'Apellido', tdClass: 'clickable' }
      ],
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
    }
  },
  computed: {
    ...mapGetters(['isSupervisor', 'isDev', 'isAdminCourses']),
    courses() {
      if (this.coursesItemList.length > 0){
        let array = this.coursesItemList.map(id => this.coursesItem[id])
        array = array.map(course => {
          this.$set(course, 'filterByFormatted', true)
          return course
        })
        return array.sort((a,b) => a.created_at - b.created_at)
      } else {
        return {}
      }
    },
    courseUsers() {
      let array = this.courseUserList.map(id => this.courseUserData[id])
      array = array.map(user => {
        this.$set(user, '_rowVariant', this.selectedCourse.is_active? '' : 'secondary')
        return user
      })
      return array.sort((a, b) => {
        if (a.due_on < b.due_on) {
          return 1;
        }
        else if (a.due_on > b.due_on) {
          return -1;
        } else {
          return 0;
        }
      })
    },
    expiredCourseUsers(){
      let classified = {
        total: 0,
        expired: 0,
        warning: 0
      }
      let array = this.courseUserList.map(id => this.courseUserData[id])
      array.forEach( user => {
        if ( user.due_on < this.currentDate ) {
          classified.expired++
        } else if ( user.due_on == this.currentDate){
          classified.warning++
        }
        classified.total++
      })
      return classified
    },
    courseClients(){
      let array = this.courseClientList.map(id => this.courseClientData[id])
      array = array.map(client => {
        this.$set(client, '_rowVariant', this.selectedCourse.is_active? '' : 'secondary')
        return client
      })
      return array.sort((a, b) => a.id - b.id)
    },
    courseUserDuplicated() {
      if (this.addCourseUserModal) {
        let values =  Object.values(this.courseUserData)
        const duplicated = values.findIndex(el => el.user.id == this.courseUserForm.user_id)
        return duplicated === -1 ? false : true
      } else {
        return false
      }
    },
    courseClientDuplicated() {
      if (this.addCourseClientModal) {
        let values =  Object.values(this.courseClientData)
        const duplicated = values.findIndex(el => el.client.id == this.courseClientForm.client_id)
        return duplicated === -1 ? false : true
      } else {
        return false
      }
    },
    activeCourseTab(){
      if(this.selectedCourse){
        return this.selectedCourse.id
      } else {
        return -1
      }
    },
    documents(){
      return this.documentList.map(id => this.documentData[id])
    },
    updateCourseValidationForm () {
      let disabled = false
      if (this.courseEdit) {
        if (this.editCourseForm.has_cost) {
          // validate total
          disabled = this.editCourseForm.total && this.editCourseForm.total > 0 ? false : true
        }
      }
      return disabled
    }
  },
  watch: {
    '$route.query' (to, from) {
      this.fetchPaginatedCourses();
    }
  },
  mounted() {
    this.currentDate = format(new Date, 'yyyy-LL-dd')
    this.noScroll = true;
    this.fetchPaginatedCourses();
  },
  methods: {
    fetchPaginatedCourses() {
      this.loading = true;
      this.$http.get('/api/production/courses', {
          params: {
            page: this.$route.query.pagina,
            q: this.searchTerm
          }
        })
        .then(response => {
          this.loadCourseData(response.data.courses)
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.count;
          if (!this.noScroll) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setTimeout(() => this.loading = false, 200)
          this.noScroll = false
          this.resetSelectedCourse()
        });
    },
    search() {
      if (this.processing) { return }
      this.processing = true;
      let query = Object.assign({}, this.$route.query);
      delete query.pagina;
      this.$router.replace({ query })
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.$http.get('/api/production/courses', {
          params: {
            q: this.searchTerm.trim()
          }
        })
        .then(response => {
          this.queryMode = true;
          this.loadCourseData(response.data.courses)
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.count;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          this.resetSelectedCourse()
        });
    },
    resetSearch() {
      this.queryMode = false;
      this.searchTerm = '';
      this.$router.push('/app/prod/cursos')
      .catch( (error)=>{
        if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
          console.log(error)
        }
      })
      this.fetchPaginatedCourses();
    },
    loadCourseData(courseItems){
      const courseItemData = normalize(
        { items: courseItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (courseItemData.entities.hasOwnProperty('items')) {
        this.coursesItem = courseItemData.entities.items
      } else {
        this.coursesItem = {}
      }
      this.coursesItemList = courseItemData.result.items
    },
    rowClick(item, index) {
      this.courseEdit = false
      this.selectedCourse = item
      this.selectedCourseIndex = index
      this.editCourseForm.name = item.name
      this.editCourseForm.category = item.category
      this.editCourseForm.is_active = item.is_active
      this.editCourseForm.has_cost = item.has_cost
      this.editCourseForm.currency = item.currency
      this.editCourseForm.total = item.total
      this.userFilter = null
      this.clientFilter = null
      this.loadUserData(item.course_users)
      this.loadClientData(item.course_clients)
    },
    focus (formInput) {
      if (formInput) {
        this.$nextTick(() => {
          this.$refs[formInput].focus()
        })
      }
    },
    openSummary(){
      this.$bvModal.show('course-summary-modal')
    },
    closeSummary(){
      this.$bvModal.hide('course-summary-modal')
    },
    // COURSE
    createCourse(){
      if (this.processing) { return }
      this.processing = true
      this.$http.post("/api/production/courses", {
        course: {
          name: this.createCourseForm.name.trim(),
          category: this.createCourseForm.category
        }
        })
        .then(response => {
          // set course item
          this.coursesItemList.unshift(response.data.id)
          this.$set(this.coursesItem, response.data.id, response.data)
          //select course
          this.selectedCourse = response.data
          this.selectedCourseIndex = 0 //always will be 0 -> unshift
          this.editCourseForm.name = response.data.name
          this.editCourseForm.category = response.data.category
          this.editCourseForm.has_cost = response.data.has_cost
          this.editCourseForm.is_active = response.data.is_active
          this.editCourseForm.currency = response.data.currency
          this.editCourseForm.total = response.data.total
          //
          this.createCourseForm.name = null
          this.createCourseForm.category = null
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.createCourseModal = false
        })
    },
    updateCourse(){
      if (this.processing) { return }
      this.processing = true

      const formData = {...this.editCourseForm}
      if (!formData.has_cost) {
        formData['currency'] = 'COL'
        formData['total'] = 0.0
      }
      this.$http.patch(`/api/production/courses/${this.selectedCourse.id}`, {
        course: formData
        })
        .then(response => {
          this.selectedCourse =  {
            ...this.selectedCourse,
            category: response.data.category,
            has_cost: response.data.has_cost,
            is_active: response.data.is_active,
            currency: response.data.currency,
            total: response.data.total
          }
          this.coursesItem[response.data.id] = {...response.data }
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.courseEdit = false
        })
    },
    resetSelectedCourse() {
      this.selectedCourse = null
      this.selectedCourseIndex = null
    },
    verifyCourseName(){
      this.$http.get("/api/production/courses/verify_course_name", {
          params: {
            q: this.createCourseForm.name
          }
        })
        .then(response => {
          if (response.data.length > 0){
            this.duplicatedCourseModal = true
          } else {
            this.createCourse()
          }
        })
        .catch(error => {
          console.log('err', error);
        })
        .finally()
    },
    updateCurrency(currency) {
      this.editCourseForm.currency = currency
      this.focus('courseTotal')
    },
    editCourse () {
      this.editCourseForm.category = this.selectedCourse.category
      this.editCourseForm.is_active = this.selectedCourse.is_active
      this.editCourseForm.has_cost = this.selectedCourse.has_cost
      this.editCourseForm.currency = this.selectedCourse.currency
      this.editCourseForm.total = this.selectedCourse.total
      this.courseEdit = true
    },
    // COURSE USER DATA
    loadUserData(userItems){
      const userItemData = normalize(
        { items: userItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (userItemData.entities.hasOwnProperty('items')) {
        this.courseUserData = userItemData.entities.items
      } else {
        this.courseUserData = {}
      }
      this.courseUserList = userItemData.result.items
    },
    onRowUserClick(data, action){
      if (action != 'user-delete') {
        this.courseUserForm.notes = data.item.notes
        this.courseUserForm.due_on = data.item.due_on
        this.courseUserForm.user_id = data.item.user.id
      }
      this.courseItemAction = action
      this.selectedCourseUser = data.item
      let usersObject = this.coursesItem[this.selectedCourse.id].course_users
      this.selectedCourseUserIndex = usersObject.findIndex(item => item.user.id === data.item.user.id)
    },
    resetUserClick(){
      this.selectedCourseUser = null
      this.selectedCourseUserIndex = null
      this.courseItemAction = null
      this.courseUserForm.notes = null
      this.courseUserForm.due_on = null
      this.courseUserForm.user_id = null
    },
    createCourseUser(){
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/courses/${this.selectedCourse.id}/course_users`, {
        course_user: this.courseUserForm
        })
        .then(response => {
          // set the user object
          this.courseUserList.push(response.data.id)
          this.$set(this.courseUserData, response.data.id, response.data)
          // Set the parent object
          let usersObject = this.coursesItem[this.selectedCourse.id].course_users
          usersObject.push(response.data)
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.addCourseUserModal = false
        })
    },
    onAddUserHide(){
      this.courseUserForm.due_on = null
      this.courseUserForm.notes = null
      this.courseUserForm.user_id = null
      this.resetSearchUser()
    },
    updateCourseUser(){
      if(this.courseItemAction == 'user-due_on'){
        if (this.courseUserForm.due_on == null || this.courseUserForm.due_on == this.selectedCourseUser.due_on) {
          return this.resetUserClick()
        }
      } else if(this.courseItemAction == 'user-notes'){
        if (this.courseUserForm.notes == null || this.courseUserForm.notes == this.selectedCourseUser.notes) {
          return this.resetUserClick()
        }
      } else {
        return
      }
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/courses/${this.selectedCourse.id}/course_users/${this.selectedCourseUser.id}`, {
        course_user: this.courseUserForm
        })
        .then(response => {
          // set the user object
          this.$set(this.courseUserData, response.data.id, response.data)
          // Set the parent object
          this.$set(this.coursesItem[this.selectedCourse.id].course_users, this.selectedCourseUserIndex, response.data)
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.resetUserClick()
        })
    },
    deleteCourseUser(){
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/courses/${this.selectedCourse.id}/course_users/${this.selectedCourseUser.id}`)
        .then(response => {
          this.loadUserData(response.data)
          this.coursesItem[this.selectedCourse.id].course_users.splice(this.selectedCourseUserIndex, 1)
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.resetUserClick()
          this.deleteConfirmationModal = false
        })
    },
    // Document files
    onDocumentClick(doc, index){
      this.selectedDocument = doc
      this.selectedDocumentIndex = index
    },
    fetchCourseUserFiles(){
      if (this.processing) { return }
      this.processing = true
      this.fetchingFiles = true
      this.$http.get(`/api/production/course_users/${this.selectedCourseUser.id}/documents`)
        .then((resp) => {
          // console.log('response document:', resp);
          this.loadFiles(resp.data)
        })
        .catch(e => console.log(e))
        .finally(()=>{
          this.processing = false
          setTimeout(()=> this.fetchingFiles = false, 800)
        })
    },
    loadFiles(files){
      const data = normalize(
            { documents: files },
            { documents: [ new schema.Entity('documents') ] }
          )
          if (data.entities.hasOwnProperty('documents')) {
            this.documentData = data.entities.documents
          }
          this.documentList = data.result.documents
    },
    createCourseUserFile(form){
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/course_users/${this.selectedCourseUser.id}/documents`, {
        document: form
      })
        .then((resp) => {
          // console.log('response document:', resp);
          const createdDocument = resp.data.document
          this.$set(this.documentData, createdDocument.id, createdDocument)
          this.documentList.push(createdDocument.id)
        })
        .catch((e) => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'El archivo no se subio correctamente, archivos validos (.xlsx,.pdf)'
          this.alertShow = true
        })
        .finally(()=>{
          this.processing = false
        })
    },
    DeleteCourseUserFile(){
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/course_users/${this.selectedCourseUser.id}/documents/${this.selectedDocument.id}`)
        .then((resp) => {
          // console.log('response delete:', resp);
          this.documentList.splice(this.selectedDocumentIndex, 1)
          delete this.documentData[this.selectedDocument.id]
        })
        .catch(e => console.log(e))
        .finally(()=> {
          this.deleteConfirmationModal = false
          this.processing = false
        })
    },
    downloadUserFile(doc){
      console.log(doc);
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/course_users/${this.selectedCourseUser.id}/documents/${doc.id}/download`)
        .then((resp) => {
          // console.log(resp.data)
          const link = document.createElement('a')
          link.href = resp.data
          link.download = doc.name
          link.click()
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    onHideDocumentsModal(){
      this.fetchingFiles = false
      this.documentData = {}
      this.documentList = []
      this.selectedCourseUser = null
      this.courseItemAction = null
      this.selectedDocument = null
      this.selectedDocumentIndex = null
    },
    // COURSE CLIENT DATA
    loadClientData(clientItems){
      const clientItemData = normalize(
        { items: clientItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (clientItemData.entities.hasOwnProperty('items')) {
        this.courseClientData = clientItemData.entities.items
      } else {
        this.courseClientData = {}
      }
      this.courseClientList = clientItemData.result.items
    },
    onRowClientClick(data, action){
      if (action != 'client-delete') {
        this.courseClientForm.contact_person = data.item.contact_person
        this.courseClientForm.contact_email = data.item.contact_email
        this.courseClientForm.notes = data.item.notes
        this.courseClientForm.client_id = data.item.client.id
      }
      this.courseItemAction = action
      this.selectedCourseClient = data.item
      let clientsObject = this.coursesItem[this.selectedCourse.id].course_clients
      this.selectedCourseClientIndex = clientsObject.findIndex(item => item.client.id === data.item.client.id)
    },
    resetClientClick(){
      this.selectedCourseClient = null
      this.selectedCourseClientIndex = null
      this.courseItemAction = null
      this.courseClientForm.contact_person = null
      this.courseClientForm.contact_email = null
      this.courseClientForm.notes = null
      this.courseClientForm.client_id = null
    },
    createCourseClient(){
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/courses/${this.selectedCourse.id}/course_clients`, {
        course_client: this.courseClientForm
        })
        .then(response => {
          // set the client object
          this.courseClientList.push(response.data.id)
          this.$set(this.courseClientData, response.data.id, response.data)
          // Set the parent object
          let clientsObject = this.coursesItem[this.selectedCourse.id].course_clients
          clientsObject.push(response.data)
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.addCourseClientModal = false
        })
    },
    onAddClientHide(){
      this.courseClientForm.contact_person = null
      this.courseClientForm.contact_email = null
      this.courseClientForm.notes = null
      this.courseClientForm.client_id = null
      this.courseClientNameForm = null
    },
    assignClientForm(client){
      this.courseClientForm.client_id = client.id
      this.courseClientNameForm = `${client.client_num} - ${client.name}`
    },
    updateCourseClient(){
      if(this.courseItemAction == 'client-contact'){
        if (this.courseClientForm.contact_person == null || this.courseClientForm.contact_person == this.selectedCourseClient.contact_person) {
          return this.resetClientClick()
        }
      } else if(this.courseItemAction == 'client-email'){
        if (this.courseClientForm.contact_email == null || this.courseClientForm.contact_email == this.selectedCourseClient.contact_email) {
          return this.resetClientClick()
        }
      } else if(this.courseItemAction == 'client-notes'){
        if (this.courseClientForm.notes == null || this.courseClientForm.notes == this.selectedCourseClient.notes) {
          return this.resetClientClick()
        }
      } else {
        return
      }
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/courses/${this.selectedCourse.id}/course_clients/${this.selectedCourseClient.id}`, {
        course_client: this.courseClientForm
        })
        .then(response => {
          // set the client object
          this.$set(this.courseClientData, response.data.id, response.data)
          // Set the parent object
          this.$set(this.coursesItem[this.selectedCourse.id].course_clients, this.selectedCourseClientIndex, response.data)
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.resetClientClick()
        })
    },
    deleteCourseClient(){
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/courses/${this.selectedCourse.id}/course_clients/${this.selectedCourseClient.id}`)
        .then(response => {
          this.loadClientData(response.data)
          this.coursesItem[this.selectedCourse.id].course_clients.splice(this.selectedCourseClientIndex, 1)
        })
        .catch(error => {
          console.log('err', error)
        })
        .finally( () => {
          this.processing = false
          this.resetClientClick()
          this.deleteConfirmationModal = false
        })
    },
    // searchCourseUser
    searchUsers () {
      this.$http.get('/api/human_resources/users/search', {
        params: {
          q: this.userSearchTerm
        }
      })
        .then((res) => {
          this.userSearchResults = res.data
        })
        .catch(e => console.log(e))
        .finally(this.userSearchTouch = true)
    },
    selectSearchUser(item) {
      this.selectedSearchUser = item
      this.userSearchTouch = false
      this.courseUserForm.user_id = item.id
      this.courseUserNameForm = `${item.first_name} ${item.last_name}`
    },
    resetSearchUser () {
      this.selectedSearchUser = null
      this.userSearchTouch = false
      this.userSearchTerm = ''
      this.courseUserForm.user_id = null
      this.courseUserNameForm = null
      this.userSearchResults = []
    },
    downloadReport () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/exports/users_courses')
        .then((res) => {
          console.log(res)
          const blob = new Blob([res.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Reporte_cursos_${format(new Date, 'd-M-yyyy')}.csv`
          link.click()
        })
        .catch((e) => {
          console.log(e)
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'Error al generar el reporte'
        })
        .finally(this.processing = false)
    }
  }
}
</script>
<style scoped>
.active-course{
  background-image: linear-gradient(-180deg, #4286f4 0%, #1d58b7 90%);
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 6px;
  margin-left: 18px;
  box-shadow: 5px 5px rgba(88, 88, 88, 0.635);
}
.text-truncate {
  overflow: hidden;
  max-height: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
