<template>
  <div>
    <PurchaseRequestNavsTabs/>
    <ContentView>
      <!-- Header -->
      <b-row align-h="between" class="mb-3">
        <b-col cols="6">
          <img :src="accesosLogo" class="brand-logo">
        </b-col>
        <b-col cols="6" align-self="center" class="text-right">
          <span class="mr-1 mt-3 font-weight-bold">{{ orderTypeDm }}</span>
        </b-col>
      </b-row>

      <!-- progress bar -->
      <b-row align-h="center" class="mt-3" v-if="step > 0">
        <b-col lg="8" sm="10" cols="11">
          <b-progress :max="totalSteps" height="20px">
            <b-progress-bar :value="step" :variant="progressBarVariant" striped :animated="true">
              <span>Paso: <strong>{{ step }} de {{ totalSteps }}</strong></span>
            </b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
      <hr>
      <!-- Header card -->
      <b-container class="mb-2">
        <b-row align-content="between">
          <b-col lg="11" cols="9" class="text-align-center">
            <h5 v-if="step == -1">Iniciar {{ orderType }}:</h5>
            <h5 v-else-if="step == 0">Seleccione el tipo de {{ orderType }}:
            </h5>
            <h5 v-else class="text-primary"> {{purchaseTypeTitle}}</h5>
          </b-col>
          <!-- reset button -->
          <b-col lg="1" cols="3">
            <Button v-if="step > -1" size="sm" variant="orange" @click="reset">
              <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
              <span class="pl-1">Reset</span>
            </Button>
          </b-col>
        </b-row>
        <b-row class="ml-1" v-if="step > 0">
          <b-col>
            <ul  class="breadcrumbs" :class="{'text-center': screenSize}">
              <li class="breadcrumbs__item" v-for="(value, index) in breadcrumbs" :key="index">
                <a @click="validLinks(value.number)"
                  :class="{'text-warning': step == value.number,'invalid-step': step < value.number}"
                  class="breadcrumbs__link"
                >
                  {{value.text}}</a>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>

      <!-- Every Step -->
      <b-container fluid>
        <!-- Paso -1 OC/SOC selection" -->
        <b-row v-if="step == -1" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col>
                <p class="step-header text-center">¿La compra ya fue ejecutada?</p>
                <div class="pl-5">
                  <b-form-group v-slot="{ isExecuted }" class="pl-4">
                    <b-form-radio @change="purchaseRequest.payment_request = false" v-model="purchaseRequest.is_executed" :aria-describedby="isExecuted" name="some-exuted_false" :value=false>No</b-form-radio>
                    <b-form-radio @change="purchaseRequest.payment_request = false" v-model="purchaseRequest.is_executed" :aria-describedby="isExecuted" name="exuted_true" :value=true>Sí</b-form-radio>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="purchaseRequest.is_executed">
              <b-col>
                <p class="step-header text-center">¿Requiere solicitud de fondos?</p>
                <div class="pl-5">
                  <b-form-group v-slot="{ paymentRequest }" class="pl-4">
                    <b-form-radio v-model="purchaseRequest.payment_request" :aria-describedby="paymentRequest" name="paymentRequestFalse" :value=false>No</b-form-radio>
                    <b-form-radio v-model="purchaseRequest.payment_request" :aria-describedby="paymentRequest" name="paymentRequestTrue" :value=true>Sí</b-form-radio>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="text-center text-info pb-3">
                  <span v-if="purchaseRequest.is_executed">Se creará una orden de compra y deberá ser autorizada por la jefatura</span>
                  <span v-else>Se creará una solitud de compra</span>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <Button variant="green" size="sm" class="float-right" @click="step = 0">
                Iniciar
              </Button>
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 0 PURCHASE TYPE -->
        <b-row v-if="step == 0" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col>
                <p class="step-header">Tipo de {{ orderType }} de Compra
                </p>
                <div>
                  <b-form-group>
                    <b-radio-group v-model="purchaseRequest.purchase_type" :options="purchaseTypeOptions" stacked size="sm"></b-radio-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <Button variant="green" size="sm" :disabled="!purchaseRequest.purchase_type" class="float-right" @click="firstStep()">
                Continuar
              </Button>
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 1 BUSINESS UNIT -->
        <b-row v-if="step == 1" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: purchaseRequest.business_unit && step > 0 }">1</div>
              </b-col>
              <b-col cols="11">
                <b-row>
                  <b-col cols="9">
                    <p class="step-header">Unidad de Negocio</p>
                  </b-col>
                  <b-col cols="3" v-if="purchaseRequest.business_unit">
                    <Button size="sm" variant="orange" @click="purchaseRequest.business_unit = null" class="ml-2 mb-2">
                      <font-awesome-icon :icon="['far', 'undo-alt']"/>
                    </Button>
                  </b-col>
                </b-row>
                <div v-if="!purchaseRequest.business_unit">
                  <b-form-select v-model="purchaseRequest.business_unit" :options="businessUnitOptions" class="mb-3">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
                <div v-else>
                  <p  class="selected-text mb-0">
                    {{ purchaseRequest.business_unit}}
                  </p>
                  <b-row align-h="center" class="mt-2">
                    <Button variant="green" size="sm" :disabled="!purchaseRequest.business_unit" class="float-right" @click="step = 2">
                      Guardar
                    </Button>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 2 ORDER -->
        <b-row v-if="step == 2 && !validatesOrder" align-h="center">
          <b-card style="width: 400px">
            <b-row>
              <b-col cols="1" class="px-0">
                <div class="step-number float-right" :class="{ valid: purchaseRequest.order_id && step > 1 }">2</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Seleccione la Orden</p>
                <b-row >
                  <b-form-group style="width: 250px">
                    <b-input-group size="sm">
                      <b-input
                        v-model="orderSearchTerm"
                        autofocus
                        ref="searchInput"
                        placeholder="Buscar orden por número de doc."
                        @keyup.enter="searchOrder"
                        @click="$nextTick(() => $refs.searchInput.select())"
                      />
                      <b-input-group-append>
                        <b-button variant="outline-secondary" :disabled="!orderSearchTerm" @click="searchOrder">
                          <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="ml-auto mr-3">
                    <Button variant="orange" v-if="orderSearchDone" size="sm" @click="resetOrderSearch">
                      <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width/>
                      <span class="pl-1">Limpiar</span>
                    </Button>
                  </b-form-group>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="orderSearchDone" style="height: 150px">
              <b-card class="w-100">
                <b-container v-if="orderSearchResult" fluid class="fs-15">
                  <b-row>
                    <b-col>
                      <small class="font-weight-bold fs-13">
                        Orden # {{ orderSearchResult.sap_doc_num }}
                      </small>
                    </b-col>
                    <b-col class="text-right">
                      <small>
                        {{ orderSearchResult.created_at | formatDateCompact }}
                      </small>
                    </b-col>
                  </b-row>
                  <b-row class="my-2 text-primary fs-14">
                    <b-col>{{ orderSearchResult.client_name }}</b-col>
                  </b-row>
                  <b-row v-if="orderSearchDone" align-h="center" class="mt-2">
                    <Button variant="green" size="sm" @click="step = 3">
                      <font-awesome-icon :icon="['fas', 'share']" fixed-width />
                      <span class="pl-1">Seleccionar</span>
                    </Button>
                  </b-row>
                </b-container>
                <b-container v-else fluid class="fs-13">
                  <b-row align-h="center" class="mt-5">
                    <span class="text-danger">No hay resultados</span>
                  </b-row>
                </b-container>
              </b-card>
            </b-row>
            <b-row align-h="center" v-if="!orderSearchTerm && !orderSearchDone && ['02','03','07'].includes(purchaseRequest.purchase_type)">
              <Button variant="green" size="sm"  class="float-right"  @click="step = 3">
                Saltar
              </Button>
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 2 PROVEEDOR -->
        <b-row v-if="step == 2 && validatesOrder" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 1 && selectedSupplier }">2</div>
              </b-col>
              <b-col cols="11">
                <b-row>
                  <b-col cols="9">
                    <p class="step-header">Seleccione el Proveedor</p>
                  </b-col>
                  <b-col cols="3" v-if="selectedSupplier">
                    <Button size="sm" variant="orange" @click="resetSupplier" class="ml-2 mb-2">
                      <font-awesome-icon :icon="['far', 'undo-alt']"/>
                    </Button>
                  </b-col>
                </b-row>
                <b-row v-if="selectedSupplier" class="mb-4">
                  <p class="selected-text mb-0">{{selectedSupplier.name}}</p>
                  <p v-if="!selectedSupplier.sap_valid" class="mt-2 text-danger">
                    <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
                    <span>Este cliente está inválido en SAP.  No se sincronizará con SAP</span>
                  </p>
                </b-row>
                <b-form-group v-else>
                  <b-input-group size="sm">
                    <b-input
                      v-model="supplierSearchTerm"
                      type="text"
                      autofocus
                      placeholder="Buscar por código o nombre"
                      @keyup.enter="searchSuppliers"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!supplierSearchTerm" @click="searchSuppliers">
                        <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!selectedSupplier" align-h="center" class="mt-4">
              <div v-show="supplierSearchTouch" style="width: 350px; max-height: 250px" class="overflow-auto">
                <b-table
                  :items="supplierSearchResults"
                  :fields="supplierSearchFields"
                  hover
                  outlined
                  show-empty
                  empty-text="No hay resultados.  Intente de nuevo"
                  class="fs-13"
                  @row-clicked="selectSupplier"
                />
              </div>
            </b-row>
            <b-row align-h="center" v-if="selectedSupplier">
              <Button variant="green" size="sm"  class="float-right"  @click="step = totalSteps">
                Guardar
              </Button>
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 3 PROVEEDOR -->
        <b-row v-if="step == 3  && !validatesOptionalStep" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 2 && selectedSupplier }">2</div>
              </b-col>
              <b-col cols="11">
                <b-row>
                  <b-col cols="9">
                    <p class="step-header">Seleccione el Proveedor</p>
                  </b-col>
                  <b-col cols="3" v-if="selectedSupplier">
                    <Button size="sm" variant="orange" @click="resetSupplier" class="ml-2 mb-2">
                      <font-awesome-icon :icon="['far', 'undo-alt']"/>
                      <!-- <span class="pl-1"></span> -->
                    </Button>
                  </b-col>
                </b-row>
                <b-row v-if="selectedSupplier" class="mb-4">
                  <p class="selected-text mb-0">{{selectedSupplier.name}}</p>
                  <p v-if="!selectedSupplier.sap_valid" class="mt-2 text-danger">
                    <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
                    <span>Este cliente está inválido en SAP.  No se sincronizará con SAP</span>
                  </p>
                </b-row>
                <b-form-group v-else>
                  <b-input-group size="sm">
                    <b-input
                      v-model="supplierSearchTerm"
                      type="text"
                      autofocus
                      placeholder="Buscar por código o nombre"
                      @keyup.enter="searchSuppliers"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!supplierSearchTerm" @click="searchSuppliers">
                        <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!selectedSupplier" align-h="center" class="mt-4">
              <div v-show="supplierSearchTouch" style="width: 350px; max-height: 250px" class="overflow-auto">
                <b-table
                  :items="supplierSearchResults"
                  :fields="supplierSearchFields"
                  hover
                  outlined
                  show-empty
                  empty-text="No hay resultados.  Intente de nuevo"
                  class="fs-13"
                  @row-clicked="selectSupplier"
                />
              </div>
            </b-row>
            <b-row align-h="center" v-if="selectedSupplier">
              <Button variant="green" size="sm"  class="float-right"  @click="step = 4">
                Guardar
              </Button>
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 4 PROYECTO -->
        <b-row v-if="step == 4" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: purchaseRequest.project_details && step > 3 }">4</div>
              </b-col>
              <b-col cols="11">
                <b-row>
                  <b-col cols="9">
                    <p class="step-header">Proyecto Asociado</p>
                  </b-col>
                  <div v-if="purchaseRequest.order_id">
                    <ul>
                      <li class="mb-0"><small>{{orderSearchResult.client_code}}</small></li>
                      <li class="mb-0"><small>{{orderSearchResult.client_name}}</small></li>
                    </ul>
                  </div>
                  <div v-else>
                    <small class="ml-4 text-secondary"> <span class="text-warning"><font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width /></span> Sin orden asociada</small>
                  </div>
                </b-row>
                <hr class="m-2">
                <div>
                  <b-form-group label="Nombre del proyecto:" label-size="sm">
                    <b-input v-model="purchaseRequest.project_details" size="sm" placeholder="Ingrese el nombre del proyecto" />
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <Button v-if="!purchaseRequest.order_id" variant="green" size="sm" class="float-right" @click="step = totalSteps">
                <span v-if="!purchaseRequest.project_details"> Saltar</span>
                <span v-else>Guardar</span>
              </Button>
              <Button v-else variant="green" size="sm" :disabled="!purchaseRequest.project_details" class="float-right" @click="step = totalSteps">
                <span> Guardar</span>
              </Button>
              <!-- <Button v-else variant="green" size="sm" :disabled="!purchaseRequest.project_details" class="float-right" @click="step = 5">
                Guardar
              </Button> -->
            </b-row>
          </b-card>
        </b-row>
        <!-- Paso 5 EXONERATION"  -->
        <!-- <b-row v-if="step == 5 && validatesExoneration" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: purchaseRequest.is_exonerated && step > 4 }">5</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Exoneración</p>
                <div>
                <b-form-group v-slot="{ isExonerated }">
                  <b-form-radio v-model="purchaseRequest.is_exonerated" :aria-describedby="isExonerated" name="some-radios" value=true>Exonerado</b-form-radio>
                  <b-form-radio v-model="purchaseRequest.is_exonerated" :aria-describedby="isExonerated" name="some-radios" value=false>No Exonerado</b-form-radio>
                </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <Button variant="green" size="sm" class="float-right" @click="step = totalSteps">
                Guardar
              </Button>
            </b-row>
          </b-card>
        </b-row> -->
        <!-- Last Step PAYMENT METHOD-->
        <b-row v-if="step == totalSteps" align-h="center">
          <b-card style="width: 330px">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: purchaseRequest.payment_method && step > 0 }">{{totalSteps}}</div>
              </b-col>
              <b-col cols="11">
                <b-row>
                  <b-col cols="9">
                    <p class="step-header">Forma de Pago</p>
                  </b-col>
                  <b-col cols="3" v-if="purchaseRequest.payment_method">
                    <Button size="sm" variant="orange" @click="purchaseRequest.payment_method = null" class="ml-2 mb-2">
                      <font-awesome-icon :icon="['far', 'undo-alt']"/>
                    </Button>
                  </b-col>
                </b-row>
                <div v-if="!purchaseRequest.payment_method">
                  <b-form-select v-model="purchaseRequest.payment_method" :options="paymentMethodOptions" class="mb-3">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
                <div v-else>
                  <p  class="selected-text mb-0">
                    {{ selectPaymentMethod}}
                  </p>
                  <b-row align-h="center" class="mt-2">
                    <Button variant="green" size="sm"  :disabled="!purchaseRequest.payment_method || creating" class="float-right" @click="createPurchaseRequest">
                      <span v-if="!creating">Finalizar</span>
                      <span v-else class="text-center">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="pl-2">Creando...</span>
                      </span>
                    </Button>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
      </b-container>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import ContentView from '../../shared/ContentView.vue'
import PurchaseRequestNavsTabs from './PurchaseRequestNavsTabs.vue'
import ToastAlert from '../../shared/ToastAlert'
import AccesosLogo from 'images/logos/accesos-w300.png'
import { businessUnits} from '../../../sharedOptions/purchaseRequestBusinessUnits'
import Button from '../../shared/Button'

export default {
  name: 'PurchaseRequestNew',
  components: {
    ContentView,
    PurchaseRequestNavsTabs,
    ToastAlert,
    Button
  },
  data () {
    return {
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      step: -1,
      totalSteps: 6,
      orderSearchResult: null,
      orderSearchDone: false,
      orderSearchTerm: '',
      supplierSearchTerm: '',
      supplierSearchResults: [],
      supplierSearchTouch: false,
      supplierSearchFields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name', label: 'Nombre', tdClass: 'clickable' }
      ],
      selectedSupplier: null,
      processing: false,
      creating: false,
      businessUnitOptions: businessUnits,
      paymentMethodOptions: [
        { value: '01', text: 'Tarjeta de crédito'},
        { value: '04', text: 'Efectivo'},
        { value: '02', text: 'Transferencia Internacional'},
        { value: '03', text: 'Transferencia Local'},
      ],
      purchaseTypeOptions: [
        { value: '01', text: 'Proyecto Nacional'},
        { value: '06', text: 'Proyecto Internacional'},
        { value: '02', text: 'Servicio Nacional'},
        { value: '03', text: 'Servicio Internacional'},
        { value: '04', text: 'Stock Local'},
        { value: '09', text: 'Stock Internacional'},
        { value: '07', text: 'Mantenimiento Edificio'},
        { value: '05', text: 'Activo Fijo'}
      ],
      purchaseTypeTitle : '',
      purchaseRequest: {
        business_unit: null,
        purchase_type: '',
        doc_type: 0,
        project_details: null,
        // is_exonerated: false,
        order_id: null,
        client_id: null,
        payment_method: null,
        is_executed: false,
        payment_request: false
      }
    }
  },
  computed: {
    accesosLogo() { return AccesosLogo },
    validatesOrder () {
      return ['04','09','05'].includes(this.purchaseRequest.purchase_type)
    },
    // validatesExoneration() {
    //   return ['01','06'].includes(this.purchaseRequest.purchase_type)
    // },
    validatesOptionalStep () {
      return ['04','09','05'].includes(this.purchaseRequest.purchase_type)
    },
    breadcrumbs () {
      if (['01','02','03','06','07'].includes(this.purchaseRequest.purchase_type)) {
        return [{text:'Unidad', number: 1}, {text:'Orden', number: 2}, {text:'Proveedor', number: 3}, {text:'Proyecto', number: 4}, {text:'Forma Pago', number: 5}]
      } else {
        return [{text:'Unidad', number: 1}, {text:'Proveedor', number: 2}, {text:'Forma Pago', number: 3}]
      }
    },
    screenSize () {
      if(window.screen.width < 576) {
        return true
      } else {
        return false
      }
    },
    selectPaymentMethod() {
      if(this.purchaseRequest.payment_method == '01'){
        return 'Tarjeta de crédito'
      } else if (this.purchaseRequest.payment_method == '02') {
        return 'Transferencia Internacional'
      } else if (this.purchaseRequest.payment_method == '03') {
        return 'Transferencia Local'
      } else {
        return 'Efectivo'
      }
    },
    progressBarVariant () {
      if (this.step < (this.totalSteps / 2)){
        return 'danger'
      } else if (this.step == this.totalSteps) {
        return 'success'
      } else {
        return 'warning'
      }
    },
    orderTypeDm () {
      if (this.purchaseRequest.is_executed) {
        return 'OC'
      } else {
        return 'SOC'
      }
    },
    orderType () {
      if (this.purchaseRequest.is_executed) {
        return 'orden'
      } else {
        return 'solicitud'
      }
    },
  },
  methods: {
    firstStep () {
      this.step = 1
      this.totalSteps = this.breadcrumbs.length
      switch (this.purchaseRequest.purchase_type) {
        case '01':
          this.purchaseTypeTitle = 'Proyecto Nacional'
          break
        case '02':
          this.purchaseRequest.doc_type = 1
          this.purchaseTypeTitle = 'Servicio Nacional'
          break
        case '03':
          this.purchaseRequest.doc_type = 1
          this.purchaseTypeTitle = 'Servicio Internacional'
          break
        case '04':
          this.purchaseTypeTitle = 'Stock Local'
          break
        case '05':
          this.purchaseTypeTitle = 'Activo Fijo'
          break
        case '06':
          this.purchaseTypeTitle = 'Proyecto Internacional'
          break
        case '07':
          this.purchaseRequest.doc_type = 1
          this.purchaseTypeTitle = 'Mantenimiento Edificio'
          break
        case '09':
          this.purchaseTypeTitle = 'Stock Internacional'
          break
      }
    },
    validLinks (newStep) {
      if(this.step > newStep ) {
        return this.step = newStep
      }
    },
    searchSuppliers () {
      let term = ""
      if (['03','06','09'].includes(this.purchaseRequest.purchase_type)) {
        term = "PE"
      } else {
        term = "PL"
      }
      this.$http.get('/api/production/clients/search_supplier', {
          params: { q: this.supplierSearchTerm, type: term}
        })
        .then(response => {
          console.log(response)
          this.supplierSearchResults = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=> this.supplierSearchTouch = true);
    },
    selectSupplier (supplier) {
      this.purchaseRequest.client_id = supplier.id
      this.selectedSupplier = supplier
    },
    searchOrder () {
      if (this.orderSearchTerm) {
        if (this.processing) { return }
        this.processing = true
        this.$http.get('/api/service/orders/search', {
          params: {
            q: this.orderSearchTerm,
          }
        })
          .then((res) => {
            console.log(res)
            this.orderSearchResult = res.data
            this.purchaseRequest.order_id = res.data.id
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.processing = false
            this.orderSearchDone = true
          })
      }
    },
    createPurchaseRequest () {
      if (this.processing ) { return }
      if (window.confirm(`¿Desea crear la ${this.orderTypeDm}?`)) {
        this.processing = true
        this.creating = true
        let data = {
          purchase_request:{
            business_unit: this.purchaseRequest.business_unit,
            purchase_type: this.purchaseRequest.purchase_type,
            project_details: this.purchaseRequest.project_details,
            // is_exonerated: this.purchaseRequest.is_exonerated,
            order_id: this.purchaseRequest.order_id,
            client_id: this.purchaseRequest.client_id,
            usd_crc_rate: this.$store.state.settings.usd_crc_rate,
            payment_method: this.purchaseRequest.payment_method,
            is_executed: this.purchaseRequest.is_executed,
            payment_request: this.purchaseRequest.payment_request,
            order_type: this.purchaseRequest.is_executed ? 'order' : 'request'
          }
        }
        // console.log(data);
        this.$http.post('/api/production/purchase_requests', data)
          .then(response => {
            // console.log('res', response.data);
            this.$router.push({ name: 'PurchaseRequestShow', params: { id: response.data.id, created: true }});
          })
          .catch(error => {
            this.alertVariant = 'danger';
            if (error.response.data.errors) {
              this.alertMessage = error.response.data.errors[0];
            } else {
              this.alertMessage = `No se pudo crear la ${this.orderTypeDm}, verifique los datos.`
            }
            this.alertShow = true;
          })
          .finally(() => {
            this.processing = false
            setTimeout(() => {
              this.creating = false
            }, 4000);
          });
      }
    },
    reset () {
      this.step = -1
      this.totalSteps = 6
      this.purchaseRequest.business_unit = null
      this.purchaseRequest.purchase_type = ''
      this.purchaseRequest.doc_type = 0
      this.purchaseRequest.project_details = null
      // this.purchaseRequest.is_exonerated = false
      this.purchaseRequest.order_id = null
      this.purchaseRequest.client_id = null
      this.purchaseRequest.payment_method = null
      this.purchaseRequest.is_executed = false
      this.purchaseRequest.payment_request = false
      this.purchaseTypeTitle = ''
      this.supplierSearchResults = []
      this.resetSupplier()
      this.resetOrderSearch()
    },
    resetSupplier () {
      this.purchaseRequest.client_id = null
      this.selectedSupplier = null
      this.supplierSearchTerm = ''
      this.supplierSearchTouch = false
    },
    resetOrderSearch () {
      this.purchaseRequest.order_id = null
      this.orderSearchTerm = ''
      this.orderSearchResult = null
      this.orderSearchDone = false
    }
  }
}
</script>

<style scoped>

.step-number {
  border-radius: 50%;
  border: 1px solid #aaa;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: small;
  font-weight: bold;
}
.step-number.valid {
  background-color: #29a86d;
  border: 1px solid #29a86d;
  font-weight: bold;
  color: #fff;
}
.step-header {
  font-size: 15px;
  font-weight: bold;
}
.selected-text {
  font-size: 16px;
  color: #aaa;
}
.brand-logo {
  width: 200px;
  height: 70px;
}
#request-contact-info {
  font-size: 10px;
}
#request-contact-info p {
  margin-bottom: 0;
}
.invalid-step{
  cursor: not-allowed;
  color: #9FA6B2 !important;
}
@media (max-width: 575.98px) {
  .brand-logo {
    width: 120px;
    height: 40px;
  }
}
.breadcrumbs {
  padding: 10px;
  font-family: sans-serif;
  cursor: pointer;
}
.breadcrumbs__item {
  display: inline-block;
}
.breadcrumbs__item:not(:last-of-type)::after {
  content: '>';
  margin: 0 5px;
  color: #cccccc;
}

</style>
