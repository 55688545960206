<template>
  <div>
    <ContentView>
      <Subheader title="Historial de Actividad" />
      <ul class="list-unstyled">
        <li v-for="log in logs" style="font-weight: 300;">
          <span>[ {{ log.created_at | formatDateTime }} ]</span>
          <span>[ {{ log.id }} ]</span>
          <span v-html="log.activity"></span>
        </li>
      </ul>
    </ContentView>
  </div>
</template>

<script>
import ContentView from '../shared/ContentView';
import Subheader from '../shared/Subheader';

export default {
  name: 'ActivityLog',
  components: { ContentView, Subheader },
  data() {
    return {
      logs: []
    }
  },
  mounted() {
    this.$http.get('/api/activity_logs')
      .then(response => {
        this.logs = response.data
      })
  }
}
</script>

<style>
.log-name {
  font-weight: bold;
}
.log-action {
  color: #663300;
}
.log-action-success {
  color: #28a745;
}
.log-action-danger {
  color: #dc3545;
}
</style>
