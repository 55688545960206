<template>
  <div>
    <NavTabs />
    <ContentView>
      <Subheader title="Boletas de Vacaciones">
        <template #right>
          <Button v-if="isDev" size="sm" to="/app/rrhh/actividad">
            Actividad
          </Button>
        </template>
      </Subheader>
      <SearchBar
        placeholder="Buscar por ID o empleado"
        @search-triggered="search"
        @reset="resetSearch"
      />
      <b-row class="mt-4">
        <b-table
          hover
          outlined
          responsive
          head-variant="light"
          show-empty
          empty-text="No resultados"
          :items="vacationTickets"
          :fields="fields"
          class="fs-13"
          @row-clicked="onRowClick"
        >
          <!-- Table columns -->
          <template v-slot:cell(ticket_no)="data">
            # {{ data.value }}
          </template>

          <template v-slot:cell(user)="data">
            {{ data.value | concatName4 }}
          </template>

          <template v-slot:cell(cedula)="data">
            {{ data.item.user.cedula | titleize }}
          </template>

          <template v-slot:cell(payroll_type)="data">
            {{ data.item.user.payroll_type | titleizePayrollType }}
          </template>

          <template  v-slot:cell(status)="data">
            <span :class="{'text-success': data.value == 'aprobado' || data.value == 'finalizado', 'text-warning': data.value == 'pendiente', 'text-danger': data.value == 'rechazado' || data.value == 'cancelado'}">
              {{ data.value | titleize }}
            </span>
            <span v-if="data.value == 'finalizado'" class="text-success">
              <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
            </span>
          </template>

          <template  v-slot:cell(vacation_dates)="data">
            <div v-if="data.item.modified">
              <p v-for="date in data.item.vacation_dates_modified" :key="date" class="mb-0">
                {{ date | formatDate }}
              </p>
            </div>
            <div v-else>
              <p v-for="date in data.value" :key="date" class="mb-0">
                {{ date | formatDate }}
              </p>
            </div>
          </template>

          <template  v-slot:cell(created_at)="data">{{ data.value | formatDate }}</template>
        </b-table>
      </b-row>
      <b-row v-show="!searchMode" align-h="center" class="mt-5">
        <PaginationCountless :page="page" :next="next" :prev="prev" />
      </b-row>
    </ContentView>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import PaginationCountless from '../shared/PaginationCountless';
import SearchBar from '../shared/SearchBar';
import Subheader from '../shared/Subheader';
import NavTabs from './NavTabs';

export default {
  name: 'VacationTicketIndex',
  components: { Button, ContentView, PaginationCountless, SearchBar, Subheader, NavTabs },
  data() {
    return {
      page: 1,
      next: null,
      prev: null,
      fields: [
        { key: 'ticket_no', label: 'ID', tdClass: 'clickable' },
        { key: 'user', label: 'Empleado', tdClass: 'clickable' },
        { key: 'cedula', label: 'Cédula', tdClass: 'clickable' },
        { key: 'payroll_type', label: 'Planilla', tdClass: 'clickable' },
        { key: 'status', tdClass: 'clickable' },
        { key: 'number_days_requested', label: 'Días a Disfrutar', thClass: 'text-center', tdClass: 'clickable text-center' },
        { key: 'vacation_dates', label: 'Fechas de Vac.', thClass: 'text-center', tdClass: 'clickable text-center' },
        { key: 'created_at', label: 'Fecha de Solicitud', thClass: 'text-right', tdClass: 'clickable text-right' }
      ],
      searchMode: false,
      loading: false,
      initialLoad: false
    }
  },
  computed: {
    ...mapGetters(['vacationTickets', 'isDev'])
  },
  created() {
    this.fetchEmployeeData();
  },
  watch: {
    '$route.query' (to, from) {
      this.fetchVacationTicketData();
    }
  },
  mounted() {
    this.initialLoad = true;
    this.fetchVacationTicketData();
  },
  methods: {
    ...mapActions(['fetchEmployeeData']),
    fetchVacationTicketData() {
      this.loading = true;
      this.$http.get('/api/human_resources/vacation_tickets', {
          params: {
            page: this.$route.query.pagina
          }
        })
        .then(response => {
          console.log(response)
          this.$store.commit('loadVacationTicketData', response.data.tickets);
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          if (!this.initialLoad) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
          // TODO
        })
        .finally(() => {
          this.loading = false;
          this.initialLoad = false;
        })
    },
    onRowClick(item) {
      this.$router.push(`/app/rrhh/boletas/${item.id}`);
    },
    search(term) {
      this.$http.get('/api/human_resources/vacation_tickets/search', {
          params: { q: term }
        })
        .then(response => {
          console.log(response)
          this.searchMode = true;
          this.$store.commit('loadVacationTicketData', response.data)
          // this.page = response.data.pagy.page;
          // this.next = response.data.pagy.next;
          // this.prev = response.data.pagy.prev;
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetSearch() {
      this.searchMode = false;
      this.$router.push('/app/rrhh/boletas');
      this.fetchVacationTicketData();
    }
  }
}
</script>
