const taxCodes = [
  { value: 'IV', text: 'IV (13%)' },
  { value: 'EXO', text: 'EXO (100%)' },
  { value: 'EXEV', text: 'EXEV (100%)' },
  { value: 'IV1', text: 'IV1 (1%)' },
  { value: 'IV2', text: 'IV2 (2%)' },
  { value: 'IVA8', text: 'IVA8 (8%)' }
  // { value: 'ISC', text: 'Selectivo de consumo' },
  // { value: 'IV2.1', text: 'Exonerado ZF 97.90%' },
  // { value: 'IV4', text: 'Reducido 4%' },
  // { value: 'IVA13', text: 'Exonerado 13%' },
  // { value: 'IVA4', text: 'Exonerado en un 9%' },
]

export { taxCodes }