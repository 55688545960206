import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["radio", "validation"];

  connect() {
    this.select();
  }

  select() {
    this.radioTargets.forEach(el => {
      if (el.checked) {
        this.validationTarget.classList.add('d-none');
        var label = el.closest('label');
        label.classList.add('active');
        label.classList.remove('blurred');
        var radioSiblings = Array.prototype.filter.call(label.parentNode.children, function(child) {
          return child !== label;
        });
        radioSiblings.forEach(el => {
          el.classList.remove('active');
          el.classList.add('blurred');
        });
      }
    });
  }
}

