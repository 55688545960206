<template>
  <div>
    <b-button-group>
      <Button size="sm" @click="copyModalShow = true">
        <font-awesome-icon :icon="['far', 'copy']" fixed-width class="pl-1 d-none d-sm-inline" />
        <span class="px-1">Copiar</span>
      </Button>
      <Button size="sm" @click="openDocumentModal()">
        <font-awesome-icon :icon="['far', 'file-alt']" fixed-width class="pl-1 d-none d-sm-inline"/>
        <span class="px-1">Adjuntos</span>
      </Button>
    </b-button-group>

    <!-- Copy Modal -->
    <b-modal v-model="copyModalShow" centered @hide="resetModal">
      <template #modal-title>
        <p>Copiar Solicitud de Compra: </p>
      </template>
      <b-row>
        <b-col cols="4" class="pl-3 pr-0">
          <p>Copiar Solicitud a: </p>
        </b-col>
        <!-- Select options -->
        <b-col class="pl-0 pt-3">
          <b-form-group  v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="copy-purchase-request"
              v-model="copyTo"
              :aria-describedby="ariaDescribedby"
              stacked
              name="copy-purchase-request"
            >
              <b-form-radio value="sameSupplier">Mismo Proveedor</b-form-radio>
              <b-form-radio value="otherSupplier">Otro Proveedor</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Actual Supplier -->
      <b-row v-if="copyTo === 'sameSupplier'" class="mb-2" align-h="center">
            <span class="font-weight-bold">{{currentSupplier.client_num}}</span>
            <span class="mb-0"> - {{currentSupplier.name}}</span>
      </b-row>
      <!-- Other Supplier -->
      <hr v-if="copyTo === 'otherSupplier'">
      <b-row v-if="copyTo === 'otherSupplier'">
        <b-col cols="12" class="pl-3">
          <b-row >
            <b-col cols="5" class="pl-3 pr-0">
              <p v-if="!selectedSupplier">Seleccione el Proveedor</p>
              <p v-else> Copiar a Proveedor: </p>
            </b-col>
            <b-col v-if="selectedSupplier" class="p-0">
              <Button size="sm" variant="orange" @click="resetSupplier" class="mb-2">
                <font-awesome-icon :icon="['far', 'undo-alt']"/>
              </Button>
            </b-col>
          </b-row>
          <!-- Selected Supplier -->
          <b-row v-if="selectedSupplier" class="mb-2" align-h="center">
            <span class="font-weight-bold">{{selectedSupplier.client_num}}</span>
            <span class="mb-0"> - {{selectedSupplier.name}}</span>
            <p v-if="!selectedSupplier.sap_valid" class="mt-2 text-danger">
              <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
              <span>Este cliente está inválido en SAP.  No se sincronizará con SAP</span>
            </p>
          </b-row>
          <b-form-group v-else>
            <b-input-group size="sm">
              <b-input
                v-model="supplierSearchTerm"
                type="text"
                autofocus
                placeholder="Buscar por código o nombre"
                @keyup.enter="searchSuppliers"
              />
              <b-input-group-append>
                <b-button :disabled="!supplierSearchTerm" @click="searchSuppliers">
                  <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Supplier Table -->
      <b-row v-if="!selectedSupplier && copyTo === 'otherSupplier'" align-h="center" class="mt-2">
        <div v-show="supplierSearchTouch" style="width: 350px; max-height: 250px" class="overflow-auto">
          <b-table
            :items="supplierSearchResults"
            :fields="supplierSearchFields"
            hover
            outlined
            show-empty
            empty-text="No hay resultados.  Intente de nuevo"
            class="fs-13"
            @row-clicked="selectSupplier"
          />
        </div>
      </b-row>
      <template #modal-footer>
        <b-button variant="success" :disabled="!copyTo || (copyTo === 'otherSupplier' && !selectedSupplier)" size="sm" @click="copy()">
          Crear
        </b-button>
      </template>
    </b-modal>

    <DocumentUploader
      resource-name="purchase_requests"
      :title="purchaseRequest.purchase_internal_id"
      :resource="purchaseRequest"
      :allowed-documents="1"
      :edit-allowed="canEdit"
      @modal-hidden="closeDocumentModal"
    />
  </div>
</template>

<script>
import Button from './Button'
import DocumentUploader from './DocumentUploaderModal'

export default {
  name: 'PurchaseRequestButtonBar',
  components: { Button, DocumentUploader },
  props: {
    purchaseRequest: {
      type: Object,
      required: true
    },
    currentSupplier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      copyModalShow: false,
      processing: false,
      copyTo: null,
      supplier: null,
      supplierSearchTerm: '',
      supplierSearchResults: [],
      supplierSearchTouch: false,
      supplierSearchFields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name', label: 'Nombre', tdClass: 'clickable' }
      ],
      selectedSupplier: null,
    }
  },
  computed: {
    currentUser () { return this.$store.state.currentUser },
    show () {
      return this.purchaseRequest
    },
    isOrder () {
      if (!this.purchaseRequest) { return false }
      if (this.purchaseRequest.order_type == 'order' && this.purchaseRequest.is_executed) {
        return true
      }
      return false
    },
    canEdit () {
      if (this.purchaseRequest) {
        if (this.isOrder) {
          return this.purchaseRequest.is_approved ? false : true
        } else {
          return this.purchaseRequest.is_open ? true : false
        }
      }
      return false
    },
  },
  methods: {
    // Copy methods
    copy () {
      if (this.processing) { return }
      this.processing = true
      let params = {}
      params = { client_id: this.copyTo === 'otherSupplier' ? this.selectedSupplier.id : this.purchaseRequest.client_id}
      console.log(params);
      this.$http.post(`/api/production/purchase_requests/${this.purchaseRequest.id}/copy`, {
          purchase_request: params
        })
        .then(response => {
          console.log(response)
          this.$router.push(`/app/prod/solicitud/${response.data.id}`)
          this.$emit('purchase-request-copied', response.data)
        })
        .catch(error => this.$emit('process-failed'))
        .finally(() => {
          this.processing = false
          this.copyModalShow = false
        })
    },
    searchSuppliers () {
      let term = ""
      if (['03','06','09'].includes(this.purchaseRequest.purchase_type)) {
        term = "PE"
      } else {
        term = "PL"
      }
      this.$http.get('/api/production/clients/search_supplier', {
          params: { q: this.supplierSearchTerm, type: term}
        })
        .then(response => {
          console.log(response)
          this.supplierSearchResults = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=> this.supplierSearchTouch = true);
    },
    selectSupplier (supplier) {
      this.selectedSupplier = supplier
    },
    resetSupplier () {
      // this.purchaseRequest.client_id = null
      this.selectedSupplier = null
      this.supplierSearchTerm = ''
      this.supplierSearchTouch = false
    },
    resetModal () {
      this.copyTo = null
      this.supplier = null
      this.supplierSearchTerm = ''
      this.supplierSearchResults = []
      this.selectedSupplier =  null
    },
    // Documents methods
    openDocumentModal (){
      this.$bvModal.show('document-uploader-modal')
    },
    closeDocumentModal (){
      this.$bvModal.hide('document-uploader-modal')
    },
  }
}
</script>
