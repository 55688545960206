import { Controller } from "stimulus";
import nouislider from "nouislider";

export default class extends Controller {
  static targets = ['slider', 'desnivel', 'icon'];

  connect() {
    this.load();  // Will throw error in console if second design isn't selected
  }

  load() {
    var startingAlto = this.calcAlto();
    var startingTopHandle = document.getElementById('primary-top-input').value;
    var startingBotHandle = parseFloat(document.getElementById('secondary-input').value) + parseFloat(startingTopHandle);
    console.log('starting top handle: ' + startingTopHandle)
    console.log('starting bot handle: ' + startingBotHandle)
    if (startingAlto && startingTopHandle && startingBotHandle) {
      document.getElementById('alto-display').textContent = startingAlto;
      nouislider.create(this.sliderTarget, {
        start: [startingTopHandle, startingBotHandle],
        orientation: 'vertical',
        behaviour: 'drag',
        step: 1,
        connect: true,
        range: {
          'min': 12,
          'max': Math.round(startingAlto) - 12
        }
      });
      this.iconTarget.classList.add('d-none');
    } else if (startingAlto) {
      console.log('else if triggered!')
      document.getElementById('alto-display').textContent = startingAlto;
      nouislider.create(this.sliderTarget, {
        start: [parseFloat(startingAlto / 2), parseFloat(startingAlto * .3)], // not working as intended
        orientation: 'vertical',
        behaviour: 'drag',
        step: 1,
        connect: true,
        range: {
          'min': 12,
          'max': Math.round(startingAlto) - 12
        }
      });
    } else {
      document.getElementById('alto-display').textContent = "--";
      nouislider.create(this.sliderTarget, {
        start: [100, 400],
        orientation: 'vertical',
        behaviour: 'drag',
        step: 1,
        connect: true,
        range: {
          'min': 12,
          'max': 488
        }
      });
    }

    this.sliderTarget.noUiSlider.on('update', function(values, handle) {
      console.log('update slider')
      var alto = parseFloat(document.getElementById('alto-display').textContent);

      if (alto) {
        var topValue = Math.round(values[0])
        var midValue = Math.round(values[1] - topValue);
        var botValue = Math.round(alto) - values[1];
        if (!isNaN(topValue) && !isNaN(midValue) && !isNaN(botValue)) {
          document.getElementById('primary-top-display').textContent = topValue + ' cm';
          document.getElementById('secondary-display').textContent = midValue + ' cm';
          document.getElementById('primary-bot-display').textContent = botValue + ' cm';

          document.getElementById('primary-top-input').value = topValue;
          document.getElementById('secondary-input').value = midValue;
          document.getElementById('primary-bot-input').value = botValue;

          document.getElementById('slider-blank-icon').classList.add('d-none');  // alias of iconTarget
        } else {
          // clear displays and inputs if valid alto not found
          document.getElementById('primary-top-display').textContent = '-- cm';
          document.getElementById('secondary-display').textContent = '-- cm';
          document.getElementById('primary-bot-display').textContent = '-- cm';
          document.getElementById('alto-display').textContent = '--';

          document.getElementById('primary-top-input').value = "";
          document.getElementById('secondary-input').value = "";
          document.getElementById('primary-bot-input').value = "";

          document.getElementById('slider-blank-icon').classList.remove('d-none');  // alias of iconTarget
        }
      }
    });
  }

  updateRange() {
    var alto = this.calcAlto();
    if (alto) {
      document.getElementById('alto-display').textContent = alto;
      console.log('updating range to..' + alto)

      this.sliderTarget.noUiSlider.updateOptions({
        range: {
          'min': 12,
          'max': Math.round(alto) - 12
        }
      });
      var values = this.sliderTarget.noUiSlider.get();
      var topValue = Math.round(values[0]);
      var midValue = Math.round(values[1] - topValue);
      var botValue = Math.round(alto) - values[1];
      document.getElementById('primary-top-display').textContent = topValue + ' cm';
      document.getElementById('secondary-display').textContent = midValue + ' cm';
      document.getElementById('primary-bot-display').textContent = botValue + ' cm';

      document.getElementById('primary-top-input').value = topValue;
      document.getElementById('secondary-input').value = midValue;
      document.getElementById('primary-bot-input').value = botValue;

      this.iconTarget.classList.add('d-none');
    } else {
      // clear displays and inputs if valid alto not found
      document.getElementById('primary-top-display').textContent = '-- cm';
      document.getElementById('secondary-display').textContent = '-- cm';
      document.getElementById('primary-bot-display').textContent = '-- cm';
      document.getElementById('alto-display').textContent = '--';

      document.getElementById('primary-top-input').value = "";
      document.getElementById('secondary-input').value = "";
      document.getElementById('primary-bot-input').value = "";

      this.iconTarget.classList.remove('d-none');

    }
  }

  calcAlto() {
    var desnivelValue;
    this.desnivelTargets.forEach(el => {
      if (el.checked) {
        desnivelValue = el.value;
      }
    });
    if (desnivelValue == 'Plano') {
      var alto = parseFloat(document.getElementById('plano-h').value);
      var cs = parseFloat(document.getElementById('plano-cs').value)
    } else if (desnivelValue == 'Desnivel izquierdo') {
      var alto =  parseFloat(document.getElementById('desnivel-izq-hd').value);
      var cs = parseFloat(document.getElementById('desnivel-izq-cs').value)
    } else if (desnivelValue == 'Desnivel derecho') {
      var alto = parseFloat(document.getElementById('desnivel-der-hi').value);
      var cs = parseFloat(document.getElementById('desnivel-der-cs').value)
    }

    if (alto && !isNaN(alto) && alto >= 25) {
         // Note:  Alto needs to be 25 or greater
      if (document.getElementById('checkbox-Cargador').checked == true && cs && !isNaN(cs)) {
        return alto - cs
      }
      return alto;
    }
  }
}

