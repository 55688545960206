import { Controller } from "stimulus"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3 from '@uppy/aws-s3'
import Spanish from '@uppy/locales/lib/es_ES'

export default class extends Controller {
  static targets = ['submitButton', 'hiddenInput', 'textArea', 'spinner']

  connect() {
    this.load()
  }

  load() {
    const uppy = Uppy({
      id: 'image-uploader',
      autoProceed: true,
      restrictions: {
        maxFileSize: 10000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.JPG', '.JPEG', '.png', '.PNG']
      },
      locale: Spanish
    })
    .use(Dashboard, {
      inline: true,
      target: '#uppy-target',
      height: 250,
      width: 350,
      showProgressDetails: true,
      hideProgressAfterFinish: true,
      showLinkToFileUploadResult: false,
      note: "Sólo archivos de .jpg, .jpeg, .png",
      disableStatusBar: false,
      disableInformer: false,
      disableThumbnailGenerator: false,
      proudlyDisplayPoweredByUppy: false,
      locale: Spanish
    })
    .use(AwsS3, {
      companionUrl: '/'
    })

    uppy.on('upload-success', (file, response) => {
      // construct uploaded file data in the format that Shrine expects
      var uploadedFileData = JSON.stringify({
        id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.hiddenInputTarget.value = uploadedFileData

      // enable submit button
      this.submitButtonTarget.classList.remove('disabled')
    })

    this.submitButtonTarget.addEventListener('reset-uppy', () => {
      uppy.reset()
    });
  }

  submit() {
    this.spinnerTarget.classList.remove('d-none')
    var event = new Event('reset-uppy')
    this.submitButtonTarget.dispatchEvent(event)
  }
}

