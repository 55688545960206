const rhLinks = {
  1: [
    { name: 'Evaluación establecimiento de puesto', url: 'https://www.ultradox.com/app/1wdLSC02IUuQaWnjVDvjokcbIvFqhADSt', type: 'url',},
    { name: 'Entrevista Jefaturas - Talento humano', url: 'https://www.ultradox.com/app/1Za8qL641nUPLX7S48BLZ6h1uz0-G--H6', type: 'url' },
    { name: 'Eficacia de la capacitación', url: 'https://www.ultradox.com/app/1RBpCeNAaVoEzBSSEIRp4x9G7xHHWKSNx', type: 'url' },
    { name: 'Reconocimientos', url: 'https://www.ultradox.com/app/1mWTIQJ95trEP4HHtfg7JBADt5f_idwGH', type: 'url' },
    { name: 'Medidas disciplinarias', url: 'https://www.ultradox.com/app/1Nwbdywe1Rj9TyNrqkD74rgscJ3aP2k7c', type: 'url' },
    { name: 'Asistencia Grupal', url: 'https://www.ultradox.com/app/17fKwuEWI_IAIT-BpV2-n77Xe9MyxaczV', type: 'url' },
    { name: 'Requisición de personal', url: 'https://www.ultradox.com/app/1GRsOWORX__MAPcVajbg4DYrPHMmAnYj9', type: 'url' },
    { name: 'Mutuo Acuerdo', url: 'https://www.ultradox.com/app/1j_qZzHLlE3qM3UHL26dJeWL4U8OwoxKY', type: 'url' }
  ],
  2: [
    // { name: 'Datos personales', url: 'https://forms.office.com/r/vCzPxs513C', type: 'url' },
    { name: '¡Mantén tus Documentos actualizados!', url: 'https://docs.google.com/forms/d/e/1FAIpQLSd_Fcx3BOQZjnCrbOe53cBkMoFfrMqt3RjU5qAaEgYtpm3ilg/viewform', type: 'url' },
    { name: '¡Comparte tus Logros y Experiencia en Capacitación!', url: 'https://forms.gle/WigEbZgSvAgSpqKf6', type: 'url' },
    { name: '¡Actualiza Datos!', url: 'https://forms.office.com/r/ucQLRJThpG', type: 'url' },
  ],
  3: [
    { name: 'Recuperación contraseña SCG', url: 'recuperacion-de-contraseña.pdf', type: 'file' },
    { name: 'Documentación laboral legal - CÓDIGO DE TRABAJO', url: 'Codigo_trabajo_rrhh.pdf', type: 'file' },
    { name: 'Documentación laboral legal - CRITERIOS JURÍDICO-LABORALES', url: 'Criterios-Juridicos-Laborales.pdf', type: 'file' },
    { name: 'Documentación laboral legal - JORNADAS DE TRABAJO', url: 'Jornadas_trabajo.pdf', type: 'file' },
    { name: 'Documentación laboral legal - FERIADOS 2024', url: 'https://www.mtss.go.cr/temas-laborales/feriados/feriados_calendario_2024.pdf', type: 'url' },
    { name: 'Documentación laboral legal - SALARIOS 2024', url: 'https://www.mtss.go.cr/temas-laborales/salarios/Documentos-Salarios/lista_salarios_2024.pdf', type: 'url' },
    { name: 'Guía de amonestaciones', url: 'Guía-de-amonestaciones-rrhh.pdf', type: 'file' },
    { name: 'Perfil de color - ¿CUÁL ES SU ZONA DE LA GENIALIDAD?', url: 'https://www.tht.company/ebook/Cual_es_su_zona_de_la_genialidad_-_THT_ELDC.pdf', type: 'url' },
    { name: 'Perfil de color - LOS CUATRO ESTILOS DE LIDERAZGO', url: 'https://tht.company/ebook/Los%20Cuatro%20estilos%20del%20Liderazgo_-_THT_ELDC.pdf', type: 'url' },
    { name: 'Perfil de color - CONOZCA UNA TEORÍA MILENARIA', url: 'https://tht.company/ebook/EB_Conozca%20una%20teor%C3%ADa%20milenaria%20-%20THT.pdf', type: 'url' },
  ]
}

export default rhLinks;