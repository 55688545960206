<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">7</span>
            <span class="step-title">Instalación Eléctrica</span>
          </div>
        </b-col>
      </b-row>

      <ManualMotorNotice for-section />

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <b-row class="mt-5">
            <b-form-group label="Sensor, cuadro, y botonera (a máx 3m)" label-class="outer-label">
              <b-radio-group v-model="form.instalacion_electrica" stacked @change="update">
                <b-radio value="Mínimo: Cliente entuba y cablea">
                  Mínimo: Cliente entuba y cablea (std)
                </b-radio>
                <b-radio value="Básico: Accesos cablea, cliente entuba">
                  Básico: Accesos cablea, cliente entuba
                </b-radio>
                <b-radio value="Total: Accesos entuba y cablea">
                  Total: Accesos entuba y cablea
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <b-row class="mt-5">
            <b-col class="pl-0">
              <b-form-group label-class="outer-label">
                <template #label>
                  <BlankIcon :field="form.ie_voltaje" />
                  <span>Voltaje disponible</span>
                </template>
                <b-checkbox-group v-model="form.ie_voltaje" stacked>
                  <b-checkbox value="110">110</b-checkbox>
                  <b-checkbox value="220">220</b-checkbox>
                  <b-checkbox value="220 - 3F">220 - 3F</b-checkbox>
                </b-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="..a carga de" label-class="outer-label">
                <b-radio-group v-model="form.ie_carg" stacked @change="update">
                  <b-radio value="Accesos">
                    Accesos
                  </b-radio>
                  <b-radio value="Cliente">
                    Cliente (std)
                  </b-radio>
                </b-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label="Cableados botoneras por" label-class="outer-label">
              <b-radio-group v-model="form.ie_cableados" stacked @change="update">
                <b-radio value="Accesos">
                  Accesos
                </b-radio>
                <b-radio value="Cliente">
                  Cliente (std)
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label="Supresor de picos" label-class="outer-label">
              <b-radio-group v-model="form.supresor_picos" stacked @change="update">
                <b-radio value="No, a cargo de cliente">
                  No, a cargo de cliente (std)
                </b-radio>
                <b-radio value="Sí, a cargo de Accesos">
                  Sí, a cargo de Accesos
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label="Caja de breaker" label-class="outer-label">
              <b-radio-group v-model="form.caja_breaker" stacked @change="update">
                <b-radio value="Breaker y caja por cliente">
                  Breaker y caja por cliente (std)
                </b-radio>
                <b-radio value="Breaker solamente por Accesos">
                  Breaker solamente por Accesos
                </b-radio>
                <b-radio value="Breaker y caja por Accesos">
                  Breaker y caja por Accesos
                </b-radio>
                   <b-radio value="Breaker y caja intemperie por Accesos">
                  Breaker y caja intemperie por Accesos
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <hr>

          <b-row align-h="between" class="mt-5">
            <label class="outer-label pl-0">Instalación eléctrica</label>
            <Button variant="blue" @click="wiringModalShow = true">
              <font-awesome-icon :icon="['fas', 'plus']" size="lg" fixed-width class="px-1" />
            </Button>
          </b-row>

          <b-row align-h="center" class="mt-3">
            <div v-for="wiring in wirings" :key="wiring.id" class="showcase pt-1 mb-4" style="min-height: 0 !important; width: 20rem">
              <b-row align-h="center" class="pt-4">
                <b-col cols="8">
                  <div class="showcase-label">{{ wiring.lineas }}</div>
                  <div class="showcase-label">Largo de {{ wiring.largo }} m</div>
                  <div class="showcase-label">{{ wiring.tuberia }}</div>
                </b-col>
              </b-row>
              <b-row align-h="between">
                <b-link class="text-danger" @click="deleteWiring(wiring.id)">
                  <div class="icon-container">
                    <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                  </div>
                </b-link>
                <div class="icon-container" @click="openWiringModalForEditing(wiring)">
                  <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                </div>
              </b-row>
            </div>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_electrical" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>

    <!-- Wiring creation/edit modal -->
    <b-modal v-model="wiringModalShow" hide-footer @hide="resetWiringModal">
      <template #modal-title>
        <span v-if="selectedWiring">Editar I.E.</span>
        <span v-else>Agregar instalación eléctrica</span>
      </template>
      <b-container fluid>
        <b-row align-h="center" class="mt-1">
          <b-form-group label="Lineas" label-class="outer-label" style="width: 280px">
            <b-form-select v-model="wiringForm.lineas" :options="lineasOptions">
              <template #first>
                <b-select-option v-show="!wiringForm.lineas" :value="null">-- seleccionar --</b-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-row>

        <b-row align-h="center" class="mt-4">
          <b-form-group label="Largo" label-class="outer-label" style="width: 280px">
            <b-input-group append="m">
              <b-input v-model="wiringForm.largo" />
            </b-input-group>
          </b-form-group>
        </b-row>

        <b-row align-h="center" class="mt-4">
          <b-form-group label="Tubería" label-class="outer-label" style="width: 280px">
            <b-form-select v-model="wiringForm.tuberia" :options="tuberiaOptions">
              <template #first>
                <b-select-option v-show="!wiringForm.tuberia" :value="null">-- seleccionar --</b-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-row>

        <b-row align-h="center" class="mt-5">
          <Button v-if="selectedWiring" variant="green" @click="updateWiring">
            Guardar
          </Button>
          <Button v-else variant="green" @click="createWiring">
            Agregar
          </Button>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import BlankIcon from './BlankIcon'
import ConfiguratorStepButtons from './ConfiguratorStepButtons'
import ManualMotorNotice from './ManualMotorNotice'
import Button from '../../shared/Button'

export default {
  name: 'DefaultElectrical',
  components: { BlankIcon, ConfiguratorStepButtons, ManualMotorNotice, Button },
  data () {
    return {
      wiringData: {},
      wiringList: [],
      form: {
        instalacion_electrica: 'Mínimo: Cliente entuba y cablea',
        ie_voltaje: [],
        ie_carg: 'Cliente',
        ie_cableados: 'Cliente',
        supresor_picos: 'No, a cargo de cliente',
        caja_breaker: 'Breaker y caja por cliente',
        notes_electrical: ''
      },
      wiringModalShow: false,
      selectedWiring: null,
      wiringForm: {
        lineas: null,
        largo: null,
        tuberia: null
      },
      lineasOptions: [
        '3 lineas #12',
        '2 lineas #16',
        '4 lineas #16',
        '7 lineas #16',
        '10 lineas #16',
        '1 UTP cat 5'
      ],
      tuberiaOptions: [
        'Canaleta',
        'Tubería emt expuesta',
        'Tubería pvc expuesta',
        'Corte 8 cm pvc',
        'Corte 15 cm pvc',
        'Adoquines pvc',
        'Enterrado 30 cm pvc'
      ],
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId']),
    wirings () {
      return this.wiringList.map(id => this.wiringData[id])
    }
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.fetchWirings()
    this.form.instalacion_electrica = this.cortina.instalacion_electrica
    this.form.ie_voltaje = this.cortina.ie_voltaje
    this.form.ie_carg = this.cortina.ie_carg
    this.form.ie_cableados = this.cortina.ie_cableados
    this.form.supresor_picos = this.cortina.supresor_picos
    this.form.caja_breaker = this.cortina.caja_breaker
    this.form.notes_electrical = this.cortina.notes_electrical
    this.ready = true
  },
  methods: {
    fetchWirings () {
      console.log('fetching wirings')
      this.$http.get(`/api/production/submitals/${this.$route.params.id}}/wirings`)
        .then((res) => {
          console.log(res.data)
          const data = normalize(
            { wirings: res.data },
            { wirings: [ new schema.Entity('wirings') ] }
          )
          if (data.entities.hasOwnProperty('wirings')) {
            this.wiringData = data.entities.wirings
          }
          this.wiringList = data.result.wirings
          this.loading = false
        })
        .catch(e => console.log(e))
    },
    update () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: this.form
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    },
    createWiring () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/submitals/${this.$route.params.id}/wirings`, {
        wiring: this.wiringForm
      })
        .then((res) => {
          console.log(res.data)
          this.$set(this.wiringData, res.data.id, res.data)
          this.wiringList.unshift(res.data.id)
          this.wiringModalShow = false
        })
        .finally(this.processing = false)
    },
    openWiringModalForEditing (wiring) {
      this.selectedWiring = wiring
      this.wiringForm.lineas = wiring.lineas
      this.wiringForm.largo = wiring.largo
      this.wiringForm.tuberia = wiring.tuberia
      this.wiringModalShow = true
    },
    updateWiring () {
      if (this.processing) { return }
      this.processing = true
      this.$http.put(`/api/production/submitals/${this.$route.params.id}/wirings/${this.selectedWiring.id}`, {
        wiring: this.wiringForm
      })
        .then((res) => {
          console.log(res.data)
          this.$set(this.wiringData, res.data.id, res.data)
          this.wiringModalShow = false
        })
        .finally(this.processing = false)
    },
    deleteWiring (wiringId) {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/submitals/${this.$route.params.id}/wirings/${wiringId}`)
        .then((res) => {
          console.log(res.data)
          this.$delete(this.wiringData, res.data.id)
          this.wiringList = this.wiringList.filter(id => id !== res.data.id)
        })
        .finally(this.processing = false)
    },
    resetWiringModal () {
      this.selectedWiring = null
      this.wiringForm.lineas = null
      this.wiringForm.largo = null
      this.wiringForm.tuberia = null
    }
  }
}
</script>
