<template>
  <header>
    <b-card no-body class="px-4 py-3">
      <b-row>
        <b-col cols="6" class="title-section">
          <p v-if="homeRoute">
            APP ENTRADA DE MERCADERÍA (EM)
          </p>
          <p v-else @click="goToPage('PurchaseDeliveryIndex')" v-b-tooltip.hover.v-dark.topright='"Ir a inicio"' class="title-clickable">
            <font-awesome-icon :icon="['far', 'warehouse']" size="lg" fixed-width />
            <span class="pl-1">IR AL INICIO</span>
          </p>
        </b-col>
        <b-col cols="6" class="nav-links">
          <ul>
            <li @click="goToPage('PurchaseDeliverySearch')" v-b-tooltip.hover.v-dark.topright='"Buscar OC"'>
              <font-awesome-icon :icon="['fas', 'search']" size="lg" />
            </li>
            <li @click="goToPage('PurchaseDeliveryRegister')" v-b-tooltip.hover.v-dark.topright='"Registro de mercadería"'>
              <font-awesome-icon :icon="['fas', 'dolly']" size="lg" />
            </li>
            <li v-if="isPurchaseDeliveryAdmin || isDev" @click="goToPage('PurchaseDeliveryValidation')" v-b-tooltip.hover.v-dark.topright='"Validar registro"'>
              <font-awesome-icon :icon="['fas', 'clipboard-check']" size="lg" />
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-card>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "NavBar",
  computed: {
    ...mapGetters(['isPurchaseDeliveryAdmin', 'isPurchaseDeliverySupervisor', 'isDev']),
    homeRoute () {
      return this.$route.name == 'PurchaseDeliveryIndex'
    }
  },
  methods: {
    goToPage(name) {
      if (!name || this.$route.name == name) return
      this.$router.replace({ name: name })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: #163D7D;
  color: whitesmoke;
  border-radius: 1.25rem;
}
.title-section {
  display: flex;
  margin: auto;
  justify-content: flex-start;
  p {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
  }
}
.title-clickable {
  color: whitesmoke;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: #9eaed2;
    scale: 0.9;
  }
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  ul {
    margin: 0;
    li {
      cursor: pointer;
      text-decoration: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #DAE2F4;
      border: 1px solid black;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      margin-top: 5px;
      svg {
        color: rgb(20, 19, 19);
        text-decoration: none;
      }
      &:hover {
        svg {
          color: #005ef5;
        }
        scale: 0.9;
      }
    }
  }
}
</style>