<template>
  <div>
    <SubmitalHeader :submital="submital" page="fotos" />

    <ContentView>
      <b-row class="mt-5">
        <b-col lg="4" sm="6">
          <b-row align-h="center">
            <ImageUploader @photo-added="createPhoto" @preview-generated="storePreview" />
          </b-row>
        </b-col>
        <b-col lg="8" sm="6">
          <b-row align-h="around">
            <PhotoCard
              v-for="photo in photos"
              :photo="photo"
              :key="photo.id"
              @photo-updated="updatePhoto"
              @photo-deleted="deletePhoto"
            />
          </b-row>
        </b-col>
      </b-row>
    </ContentView>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { normalize, schema } from 'normalizr'
import ContentView from '../shared/ContentView'
import ImageUploader from '../shared/ImageUploader'
import PhotoCard from '../shared/PhotoCard'
import SubmitalHeader from './SubmitalHeader'

export default {
  name: 'SubmitalPhotos',
  components: { ContentView, ImageUploader, PhotoCard, SubmitalHeader },
  data () {
    return {
      photoData: {},
      photoList: [],
      storedPreview: null,
      loading: false,
      processing: false
    }
  },
  computed: {
    ...mapState ({ submital: state => state.configurator.submital }),
    photos () {
      return this.photoList.map(id => this.photoData[id])
    }
  },
  mounted () {
    this.fetchPhotos()
  },
  methods: {
    fetchPhotos () {
      this.$http.get(`/api/production/submitals/${this.submital.id}/photos`)
        .then((res) => {
          console.log(res.data)
          const data = normalize(
            { photos: res.data },
            { photos: [ new schema.Entity('photos') ] }
          )
          if (data.entities.hasOwnProperty('photos')) {
            this.photoData = data.entities.photos
          }
          this.photoList = data.result.photos
          this.loading = false
        })
        .catch(e => console.log(e))
    },
    storePreview (preview) {
      this.storedPreview = preview
    },
    createPhoto (form) {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/submitals/${this.submital.id}/photos`, {
        photo: form
      })
        .then((res) => {
          const createdPhoto = res.data.photo
          this.$set(this.photoData, createdPhoto.id, createdPhoto)
          this.photoList.push(createdPhoto.id)
          this.$store.commit('setSubmital', res.data.submital)

          this.$nextTick(() => {
            const anchors = document.getElementsByClassName('preview-anchor-' + createdPhoto.id)
            console.log(anchors)
            Array.from(anchors).forEach(anc => {
              const img = document.createElement('img')
              img.src = this.storedPreview
              img.style.maxHeight = '800px'
              img.style.maxWidth = '800px'
              img.style.backgroundColor = '#fff'
              anc.appendChild(img)
            })
          })
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    updatePhoto (obj) {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/submitals/${this.submital.id}/photos/${obj.photo.id}`, {
        photo: obj.form
      })
        .then((res) => {
          console.log(res)
          this.$set(this.photoData, res.data.id, res.data)
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    deletePhoto (photo) {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/submitals/${this.submital.id}/photos/${photo.id}`)
        .then((res) => {
          console.log(res)
          delete this.photoData[res.data.photo.id]
          this.photoList = this.photoList.filter(id => id !== res.data.photo.id)
          this.$store.commit('setSubmital', res.data.submital)
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
  }

}
</script>
