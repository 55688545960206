import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lamBoolean", "select"]

  connect() {
    var options = this.selectTarget.querySelectorAll('option');
    if (this.lamBooleanTarget.textContent == 'true') {
      console.log(options);
      options.forEach(el => {
        if (el.value == 'Laminación incorporada') {
          el.disabled = false;
        } else {
          if (el.value) {
            el.disabled = true;
          }
        }
      });
    } else {
      options.forEach(el => {
        if (el.value == 'Laminación incorporada') {
          el.disabled = true;
        } else {
          el.disabled = false;
        }
      });
    }
  }
}

