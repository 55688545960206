import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["icon", "radio", "nilRadio"];

  connect() {
    this.validate();
  }

  validate() {
    this.radioTargets.forEach(el => {
      if (el.checked) {
        this.iconTarget.classList.add('d-none');
      }
    });
  } 

  clear() {
    console.log('clear is clicked')
    this.iconTarget.classList.remove('d-none');
    this.nilRadioTarget.checked = true;
  }
}

