<template>
  <div>
    <b-modal
      id="quotes-sap-modal"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      :header-class="headerVariant"
      @shown="fetchData"
      @hide="onModalHide"
    >
      <template #modal-title>
        <b-row>
          <b-col cols="3">
            <b :class="{'text-secondary': loading}">Búsqueda:</b>
          </b-col>
          <b-col>
            <b-form-group style="width: 290px">
              <b-input-group size="sm">
                <b-input
                  v-model="searchValue"
                  autofocus
                  placeholder="Buscar por # cotización, titulo, cliente"
                  :disabled="loading"
                  @keyup.enter="fetchData"
                />
                <b-input-group-append>
                  <b-button variant="dark" size="sm" @click="fetchData" :disabled="loading">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <b-container v-if="loading" fluid class="fs-13">
        <b-row align-h="center" class="mt-5">
          <div class="text-center text-info" >
            <b-spinner style="width: 1.25rem; height: 1.25rem"></b-spinner>
            <span class="pl-2 fs-20">Obteniendo datos de SAP...</span>
          </div>
        </b-row>
      </b-container>

      <b-container v-else fluid class="mt-1" >
        <b-row v-if="badRequest" align-h="center" class="mt-5">
          <div class="text-danger text-info" >
            <span class="pl-2 fs-18">Sin resultados, por favor intente de nuevo</span>
          </div>
        </b-row>

        <b-row v-else>
          <small v-if="isDev" class="text-info position-absolute" style="right: 15px; top: 10px">
            Resultados: {{groupedQuotes.length}}
          </small>
          <b-col>
            <b-row class="fs-13 pt-2">
              <b-col>
                <b-row>
                  <b-table
                    :items="groupedQuotes"
                    :fields="fields"
                    primary-key="doc_no"
                    show-empty
                    class="border-bottom"
                    sort-icon-left
                    hover
                    @row-clicked="toggleRowDetails"
                  >
                    <template #empty>
                      <div class="d-flex align-items-center justify-content-center" style="height: 225px">
                        <span class="empty-list-text">
                          No hay coincidencias en SAP
                        </span>
                      </div>
                    </template>
                    <!-- Doc Total -->
                    <template #cell(doc_total)="data">
                      <span v-if="data.item.doc_currency == 'COL'">{{ data.value | colones3  }}</span>
                      <span v-else>{{ data.item.doc_total_sys | dollarize2 }}</span>
                    </template>
                    <!-- Client Name -->
                    <template #cell(client_name)="data">
                      <span> <b>{{data.item.card_code}}</b> - {{ data.value }}</span>
                    </template>
                    <!-- doc_date -->
                    <template #cell(doc_date)="data">
                      <span> {{ data.value | formatDate }}</span>
                    </template>
                    <!-- show_details -->
                    <template v-slot:cell(show_details)="row">
                      <Button size="sm" style="width: 25px" @click="row.toggleDetails">
                        <font-awesome-icon v-if="row.detailsShowing" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                        <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="lg" fixed-width />
                      </Button>
                    </template>
                    <template #row-details="row">
                      <!-- Sap Title -->
                      <b-row class="px-4">
                        <span>
                          <b  class="text-primary">Título</b>:
                          <span v-if="row.item.doc_title">{{row.item.doc_title}}</span>
                          <span v-else class="text-secondary"> Sin título</span>
                        </span>
                      </b-row>
                      <hr>
                      <b-row class="px-4">
                        <b-table
                          :items="row.item.doc_items"
                          :fields="subfields"
                          primary-key="sap_line_num"
                          head-variant="light"
                          bordered
                          small
                          class="fs-12"
                        >
                        <!-- Sap unit price -->
                        <template #cell(sap_unit_price)="data">
                          <span v-if="data.item.currency == 'COL'">{{ data.value | colones3  }}</span>
                          <span v-else>{{ data.value | dollarize2 }}</span>
                        </template>

                        <template #cell(sap_total_price)="data">
                          <span v-if="data.item.currency == 'COL'">{{ data.item.sap_unit_price * data.item.quantity | colones3  }}</span>
                          <span v-else>{{ data.item.sap_unit_price * data.item.quantity | dollarize2 }}</span>
                        </template>
                        <!-- Item Code -->
                        <template #cell(item_code)="data">
                          <span v-if="!data.item.product_id" class="text-danger">
                            {{ data.value }}
                            <span  v-b-tooltip.hover.v-dark.topright='"Este artículo no será agregado si duplica esta cotización, debe agregarlo manualmente"' class="text-warning">
                              <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                            </span>
                          </span>
                          <span v-else> {{ data.value }}</span>
                        </template>
                        </b-table>
                      </b-row>
                      <b-row align-h="end" class="pr-4">
                        <Button size="sm" variant="blue" @click="onDuplicateModalShow(row.item)">
                          Duplicar
                        </Button>
                      </b-row>
                    </template>
                  </b-table>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <Button  size="sm" class="text-danger mr-auto" @click="onModalHide">
          <span class="pl-1">Cancelar</span>
        </Button>
      </template>
    </b-modal>

    <b-modal
      v-model="duplicateSapQuote"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center">
        <b class="fs-20 pb-2">Duplicar cotización</b>
      </b-row>

      <!-- Client -->
      <div class="pl-5">
        <p class="m-1">Asignar cliente existente o nuevo:</p>
        <div>
          <b-row v-if="selectedClient" class="pl-4 pt-2">
            <b-col cols="10">
              <small>{{selectedClient.client_num}} - {{selectedClient.name}}</small>
            </b-col>
            <b-col cols="2">
              <Button size="sm" variant="red" @click="resetClientSearch" class="mr-5">
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </Button>
            </b-col>
          </b-row>
          <b-row v-else class="pl-4">
            <b-form-group>
              <b-radio-group v-model="client_id" @change="resetFormValidation" stacked size="sm">
                <b-radio :value="quote.client_id" :key="quote.client_id"><small>{{ quote.card_code }} - {{quote.client_name}}</small></b-radio>
                <b-radio value="CAMBIAR"><span class="text-success">Cambiar cliente</span></b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>
        </div>
        <div v-if="client_id == 'CAMBIAR'" class="pr-5">
          <b-form-group>
            <b-input-group
              v-if="!selectedClient"
              size="sm">
              <b-input
                v-model="clientSearchTerm"
                type="text"
                autofocus
                placeholder="Buscar por código o nombre"
                @keyup.enter="searchClients"
              />
              <b-input-group-append>
                <b-button :disabled="!clientSearchTerm" @click="searchClients">
                  <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div v-show="clientSearchTouch" style="width: 350px; max-height: 200px" class="overflow-auto">
            <b-table
              :items="clientSearchResults"
              :fields="clientSearchFields"
              hover
              outlined
              show-empty
              empty-text="No resultados.  Intenta de nuevo"
              class="fs-13"
              @row-clicked="selectClient"
            />
          </div>
        </div>
      </div>

      <!-- Projects -->
      <div v-if="!loadingProjects && projectStepValidation"  class="pl-5">
        <hr>
        <p>Asignar proyecto existente o crear nuevo: </p>
        <div   v-if="projects.length > 0">
          <div class="pl-4">
            <b-form-group>
              <b-radio-group v-model="form.project_id" stacked size="sm">
                <b-radio v-for="project in projects" :value="project.id" :key="project.id">{{ project.name }}</b-radio>
                <b-radio value="CREATE"><span class="text-success">Crear nuevo</span></b-radio>
              </b-radio-group>
            </b-form-group>
          </div>
        </div>
        <div v-else class="text-center pr-5">
          <small class="text-info">El cliente no tiene proyectos, por favor cree uno nuevo para esta cotización:</small>
        </div>
        <div v-if="form.project_id == 'CREATE' || projects.length === 0" class="pr-5">
          <div>
            <b-form-group label-size="sm">
              <template #label>
                <span>Nombre del proyecto:</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="projectForm.name" size="sm" />
            </b-form-group>
            <b-form-group label-size="sm">
              <template #label>
                <span>Dirección:</span><span class="asterisk">*</span>
              </template>
              <b-textarea v-model="projectForm.address" size="sm" />
            </b-form-group>
            <b-form-group label-size="sm">
              <template #label>
                <span>Tipo de ciclos de proyecto:</span><span class="asterisk">*</span>
              </template>
              <b-radio-group v-model="projectForm.project_type" stacked size="sm">
                <b-radio value="Comercial">Comercial o Industrial</b-radio>
                <b-radio value="Residencial">Residencial o Condominio</b-radio>
                <b-radio value="Parqueo">Parqueo</b-radio>
              </b-radio-group>
            </b-form-group>
          </div>
        </div>
      </div>

      <b-row align-h="around" class="my-4">
        <Button variant="red" @click="onDuplicateModalHide" :disabled="false">Regresar</Button>
        <Button variant="blue" @click="createQuote" :disabled="loadingProjects || !projectFormValidation">Duplicar</Button>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import groupBy from 'lodash/groupBy'
import { mapGetters } from 'vuex'
import Button from '../../components/shared/Button'

export default {
  name: 'QuotesSapModal',
  components: { Button },
  props: {
    searchTerm: {
      type: String
    }
  },
  data () {
    return {
      fields: [
        { key: 'doc_no', label: '# Cotización', tdClass: 'align-middle font-weight-bold font-lato fs-15 clickable'},
        { key: 'client_name', label: 'Cliente', tdClass: 'align-middle font-lato fs-16 clickable' },
        { key: 'doc_date', label: 'Fecha', tdClass: 'align-middle font-lato fs-16 clickable', sortable: true },
        { key: 'doc_total', label: 'Total', tdClass: 'align-middle font-lato fs-16 clickable', sortable: true },
        { key: 'show_details', label: '', tdClass: 'align-middle pl-2' },
      ],
      subfields: [
        { key: 'item_code', label: 'Código', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '70px' } },
        { key: 'item_description', label: 'Descripción', tdClass: 'align-middle  text-center font-weight-bold', thClass: 'text-center', thStyle: { width: '300px' }},
        { key: 'quantity', label: 'Cantidad', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '20px' }, sortable: true  },
        { key: 'sap_unit_price', label: 'Precio unitario', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '70px' }, sortable: true },
        { key: 'sap_total_price', label: 'Precio total', tdClass: 'align-middle  text-center', thClass: 'text-center', thStyle: { width: '70px' }, sortable: true }
      ],
      clientSearchFields: [
        { key: 'client_num', label: 'Código', tdClass: 'clickable' },
        { key: 'name', label: 'Nombre', tdClass: 'clickable' }
      ],
      form: {
        user_id: null,
        client_id: null,
        project_id: null,
        items : null,
      },
      client_id: null,
      projectForm: {
        user_id: null,
        client_id: null,
        name: null,
        address: null,
        project_type: null
      },
      quotes: [],
      quote: [],
      projects: [],
      processing: false,
      badRequest: false,
      duplicateSapQuote: false,
      loading: true,
      loadingProjects: false,
      clientSearchTerm: '',
      clientSearchResults: [],
      clientSearchTouch: false,
      selectedClient: null,
    }
  },
  computed: {
    ...mapGetters (['isDev']),
    headerVariant () {
      return ['bg-oi','pb-0']
    },
    groupedQuotes () {
      const array = []
      for(const [key, value] of Object.entries(groupBy(this.quotes, 'doc_num'))) {
        array.push({
          doc_no: key,
          doc_entry: value[0].doc_entry,
          card_code: value[0].card_code,
          client_name: value[0].client_name,
          doc_title: value[0].title,
          doc_currency: value[0].doc_currency,
          doc_total: value[0].doc_total, // COL
          doc_total_sys: value[0].doc_total_sys, // USD
          doc_date: value[0].doc_date,
          user_id: value[0].user_id,
          client_id: value[0].client_id,
          valid_until: value[0].valid_until,
          doc_items: value[0].documentLines,
          _showDetails: false
        })
      }
      return array.sort((a,b) => new Date(b.doc_date) - new Date(a.doc_date));
    },
    searchValue : {
      get () {
        return this.searchTerm
      },
      set (value) {
        this.$emit('update-search', value)
      }

    },
    projectFormValidation () {
      if (!this.form.project_id || this.form.project_id == 'CREATE') {
        let form = Object.assign({}, this.projectForm)
        delete form['user_id']
        delete form['client_id']
        const values = Object.values(form)
        return values.some( val => !val) ? false : true
      } else if (this.client_id == 'CAMBIAR') {
        return true
      } else {
        return true
      }
    },
    projectStepValidation () {
      if (!this.client_id) {
        return false
      } else if (this.client_id === 'CAMBIAR' && this.selectedClient) {
        return true
      } else if (this.client_id != 'CAMBIAR') {
        return true
      } else{
        return false
      }
    }
  },
  mounted() {
    this.loading = true
  },
  methods: {
    fetchData () {
      console.log('fetching data')
      if (this.processing ) return
      this.loading = true
      this.processing = true
      this.$http.get(`/api/production/quotes/fetch_sap_quotes`, {
        params: { q: this.searchValue}
      })
        .then((res) => {
          // console.log('data',res.data.quotes)
          this.quotes = res.data.quotes
        })
        .catch((e) => {
          console.log(e)
          this.badRequest = true
        })
        .finally( () =>{
          this.loading = false
          this.processing = false
        })
    },
    fetchProjects (clientId) {
      if (this.processing ) return
      this.processing = true
      this.loadingProjects = true
      this.$http.get(`/api/production/clients/${clientId}/fetch_projects`)
        .then((res) => {
          this.projects = res.data
        })
        .catch((e) => {
          console.log(e)
        })
        .finally( () =>{
          this.processing = false
          this.loadingProjects = false
        })
    },
    onModalHide () {
      this.$emit('modal-hidden')
    },
    onDuplicateModalShow (quote){
      this.fetchProjects(quote.client_id)
      this.quote = quote
      this.duplicateSapQuote = true
    },
    onDuplicateModalHide () {
      this.duplicateSapQuote = false
      this.quote = []
      this.selectedClient = null
      this.client_id = null
      Object.keys(this.form).forEach((i) => this.form[i] = null)
      Object.keys(this.projectForm).forEach((i) => this.projectForm[i] = null)
    },
    createQuote () {
      if (this.processing) return
      this.processing = true
      let data
      let items = []
      for(const [key, value] of Object.entries(this.quote.doc_items)) {
        if (!value.product_id) { continue }
        items.push({
          product_id: value.product_id,
          quantity: value.quantity,
          tax_code: value.tax_code,
          sap_line_num: value.sap_line_num
        })
      }

      if (this.form.project_id === 'CREATE' || !this.form.project_id) {
        data = {
          quote: {
            user_id: this.quote.user_id,
            client_id: this.client_id,
            valid_until: this.quote.valid_until,
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          },
          project: {
            user_id: this.quote.user_id,
            client_id: this.client_id,
            name: this.projectForm.name,
            address: this.projectForm.address,
            project_type: this.projectForm.project_type
          },
          quote_items: items
        }
      } else {
        data = {
          quote: {
            user_id: this.quote.user_id,
            client_id: this.client_id,
            project_id: this.form.project_id,
            valid_until: this.quote.valid_until,
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          },
          quote_items: items
        }
      }
      this.$http.post('/api/production/quotes', data)
        .then(response => {
          console.log(response);
          this.$router.push({ name: 'QuoteShow', params: { id: response.data.id, created: true }});
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => this.processing = false);
    },
    searchClients() {
      this.$http.get('/api/production/clients/search', {
          params: { q: this.clientSearchTerm }
        })
        .then(response => {
          // console.log(response)
          this.clientSearchResults = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=> this.clientSearchTouch = true);
    },
    selectClient(item) {
      this.selectedClient = item
      this.client_id = parseInt(item.id)
      this.fetchProjects(item.id)
      this.clientSearchTouch = false
    },
    resetClientSearch () {
      this.selectedClient = null
      this.clientSearchTerm = ''
      this.client_id = null
      this.form.project_id = null
      Object.keys(this.projectForm).forEach((i) => this.projectForm[i] = null)
      this.fetchProjects(this.quote.client_id)
    },
    resetFormValidation () {
      this.form.project_id = null
      Object.keys(this.projectForm).forEach((i) => this.projectForm[i] = null)
    },
    toggleRowDetails (row) {
      row._showDetails =! row._showDetails
    }
  }
}
</script>
