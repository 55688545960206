<template>
  <div>
    <b-row align-h="center">
      <b-card class="mb-3" style="width: 90%">
        <b-container v-if="submital">
          <b-row align-v="center">
            <b-col cols="6">
              <b-row class="text-muted">
                <span>{{ submital.configurable_type.split('::')[1] }} {{ submital.submital_no }}</span>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row align-h="end" align-v="center">
                <span class="pl-2 text-muted">{{ submital.progress }}%</span>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="pb-3 border-bottom">
            <span v-if="submital.title">{{ submital.title }}</span>
            <span v-else class="text-muted font-italic">(Sin Nombre)</span>
          </b-row>
          <b-row class="my-3 pb-3 border-bottom">
            <b-col>
              <b-row align-h="center" align-v="center">
                <span class="fs-13" style="padding-top: 2px">Cant.</span>
                <span class="pl-1">{{ submital.quantity }}</span>
              </b-row>
            </b-col>
            <!-- <b-col>
              <b-row align-h="center" align-v="center" style="padding-top: 2px">
                <font-awesome-icon :icon="['far', 'ruler-horizontal']" size="lg" />
                <span v-if="access.width" class="pl-2">{{ access.width }}cm</span>
                <span v-else class="pl-2 text-danger fs-11">Pend.</span>
              </b-row>
            </b-col>
            <b-col>
              <b-row align-h="center" align-v="center" style="padding-top: 2px">
                <font-awesome-icon :icon="['far', 'ruler-vertical']" size="lg" />
                <span v-if="itemHeight(access)" class="pl-2">{{ itemHeight(access) }}cm</span>
                <span v-else class="pl-2 text-danger fs-11">Pend.</span>
              </b-row>
            </b-col> -->
          </b-row>
          <!-- <b-row class="my-2 fs-13">
            <font-awesome-icon :icon="['far', 'warehouse']" size="lg" />
            <span v-if="access.design" class="pl-3">{{ access.design.item_code }} {{ access.design.name }}</span>
            <span v-else class="pl-3 fs-11">Pend.</span>
          </b-row>
          <b-row class="my-2 fs-13">
            <font-awesome-icon :icon="['fas', 'cogs']" size="lg" />
            <span v-if="access.motor" class="pl-3">{{ access.motor.item_code }}</span>
            <span v-else class="pl-3 fs-11">Pend.</span>
          </b-row> -->
          <b-row v-if="isAuthorized" align-h="between" class="mt-3">
            <!-- <Button size="sm"  @click="copyAccess(access, true)">
              <font-awesome-icon :icon="['far', 'copy']" fixed-width />
              <span class="pl-1">Copiar</span>
            </Button> -->
            <!-- <Button size="sm" @click="openEditAccessModal(access)">
              <font-awesome-icon :icon="['far', 'edit']" fixed-width />
              <span class="pl-1">Editar</span>
            </Button> -->
            <Button size="sm" variant="blue" @click="goToSubmital(submital)">
              Config
            </Button>
          </b-row>
        </b-container>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import Button from '../shared/Button'

export default {
  name: 'ProjectShowSubmitalCard',
  components: { Button },
  props: {
    submital: {
      type: Object
    },
    isAuthorized: {
      type: Boolean
    }
  },
  methods: {
    goToSubmital (submital) {
      this.$router.push(`/app/prod/submitals/${submital.id}`)
    }
  }
}
</script>
