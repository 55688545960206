<template>
  <b-container>
    <!-- Create Order Modal -->
    <b-modal
      id="create-order-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hide="onModalHide"
    >
      <template #modal-title>
        <h4>Crear orden en SAP</h4>
      </template>
      <b-row align-h="center" class="mt-3 fs-14">
        <div>
          <!-- Sap serie -->
          <ValidationProvider :rules="{ required: true }" name="Tipo de Orden" v-slot="{ valid, errors }">
            <b-form
              inline
              class="pb-3"
              :invalid-feedback="errors[0]"
            >
              <label for="sap-series">
                <span>Tipo de Orden:</span><span class="asterisk pr-3">*</span>
              </label>
              <b-select
                id="sap-series"
                v-model="form.sap_series"
                :options="sapSeriesOptions"
                size="sm"
                style="min-width: 15.9rem; margin-left: 0.2rem;"
                :state="errors[0] ? false : (valid ? true : null)"
                @change="changeSapSeries"
              >
                <template v-slot:first>
                  <option :value="null" disabled>-- Eligir uno --</option>
                </template>
              </b-select>
            </b-form>
          </ValidationProvider>
          <b-row v-if="form.sap_series && !fetchingVenregStatus">
            <b-col>
              <!-- venreg status -->
              <ValidationProvider v-if="form.sap_series == 9" name="Venreg status">
                <b-form
                  inline
                  :class="{'pb-3': venreg.text != 'Pendiente'}"
                >
                  <label for="venreg-status">
                    <span>Venreg:</span><span class="asterisk pr-5 ">*</span>
                  </label>
                  <b-input-group>
                    <b-input type="text" id="venreg-status" :style="{'min-width': venreg.text == 'Error al consultar venreg' || venreg.text == 'Pendiente' ? '13.5rem' : '16rem'}" class="ml-3 text-center" disabled  :value="venreg.text" size="sm" :state="venreg.status" />
                    <b-input-group-append v-if="venreg.text == 'Error al consultar venreg' || venreg.text == 'Pendiente'" class="ml-1">
                      <b-button size="sm" variant="danger" @click="fetchQuoteVenregStatus">
                        <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
                <div v-if="venreg.text == 'Pendiente'" class="text-right">
                  <small class="text-danger">
                    Debe completarlo ingresando a: <a href="https://venreg.herokuapp.com/Login" target="_blank">VENREG</a>
                  </small>
                </div>
              </ValidationProvider>
              <!-- maintenance_contract -->
              <ValidationProvider v-if="form.sap_series == 112" :rules="{ required: true, numeric: true }" name="Contrato Mantenimiento" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]"
                >
                  <label for="order-type">
                    <span>Número de contrato:</span><span class="asterisk pr-3 ">*</span>
                  </label>
                  <b-input type="text" id="order-type" style="min-width: 13.8rem"  v-model="form.maintenance_contracts" size="sm" :state="errors[0] ? false : (form.maintenance_contracts && valid ? true : null)" />
                </b-form>
              </ValidationProvider>
              <!-- delivery_to -->
              <ValidationProvider :rules="{ required: true }" name="Entregar a" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]"
                >
                  <label for="delivery-to">
                    <span>Entregar a:</span><span class="asterisk pr-3 ">*</span>
                  </label>
                  <b-input type="text" id="delivery-to" style="min-width: 15.9rem; margin-left: 1.8rem;" v-model="form.deliver_to" size="sm" :state="errors[0] ? false : (form.deliver_to && valid ? true : null)" />
                </b-form>
              </ValidationProvider>
              <!-- due_on date-->
              <ValidationProvider :rules="{ required: true }" name="Fecha entrega" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]"
                >
                  <label for="due-on">
                    <span>Fecha entrega:</span><span class="asterisk pr-3 ">*</span>
                  </label>
                  <b-input type="date" id="due-on" class="ml-1" style="min-width: 15.8rem;" v-model="form.due_on" size="sm" :state="errors[0] ? false : (form.due_on && valid ? true : null)" />
                </b-form>
              </ValidationProvider>
              <!-- started_on date -->
              <ValidationProvider :rules="{ required: true }" name="Fecha inicio" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]"
                >
                  <label for="start-on">
                    <span>Fecha inicio:</span><span class="asterisk pr-3">*</span>
                  </label>
                  <b-input type="date" id="start-on" style="min-width: 15.8rem; margin-left: 1.2rem;" v-model="form.started_on" size="sm" :state="errors[0] ? false : (form.started_on && valid ? true : null)" />
                </b-form>
              </ValidationProvider>
              <!-- install_on date  -->
              <ValidationProvider v-if="form.started_on && invalidStock" :rules="{ required: true, before_date: form.started_on }" name="Fecha logística" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                >
                  <label for="install-on">
                    <span>Fecha logística:</span><span class="asterisk pr-3 ">*</span>
                  </label>
                  <b-input type="date" id="install-on" style="min-width: 15.7rem;" v-model="form.install_on" size="sm" :state="errors[0] ? false : (form.install_on && valid ? true : null)" />
                </b-form>
                <b-popover v-if="errors[0]" target="install-on" placement="topright" triggers="hover click">
                  <span class="text-info">Fecha logística debe ser menor a fecha de inicio</span>
                </b-popover>
              </ValidationProvider>
              <!-- is_special_project -->
              <ValidationProvider v-if="form.sap_series == 9" :rules="{ required: true, numeric: true }" name="¿Proyecto especial?" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]"
                >
                  <label for="order-type">
                    <span>¿Proyecto especial?</span><span class="asterisk pr-3 ">*</span>
                  </label>
                  <b-form-radio v-model="form.is_special_project" class="ml-2" :aria-describedby="valid" name="Si" value=true>Si</b-form-radio>
                  <b-form-radio v-model="form.is_special_project" class="ml-3" :aria-describedby="valid" name="No" value=false>No</b-form-radio>
                </b-form>
              </ValidationProvider>
              <!-- technical_instructions -->
              <ValidationProvider v-if="form.sap_series == 71" :rules="{ required: true }" name="Instrucciones del técnico" v-slot="{ valid, errors }">
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]">
                  <label for="technical_instructions">
                    <span>Instrucciones del técnico:</span><span class="asterisk pr-3">*</span>
                  </label>
                </b-form>
                <b-form
                  inline
                  class="pb-3"
                  :invalid-feedback="errors[0]">
                  <b-textarea id="technical_instructions" style="min-width: 23.5rem; " v-model="form.technical_instructions" size="sm" :state="errors[0] ? false : (form.technical_instructions && valid ? true : null)"></b-textarea>
                </b-form>
              </ValidationProvider>
              <!-- document attachment -->
              <!-- TODO: Next version: Add the option to change or remove document set data on fails! -->
              <b-row v-if="form.sap_series == 71" class="mb-1">
                <b-col>
                  <p class="mb-0">Adjuntar archivo: <span class="asterisk pr-3 ">*</span></p>
                  <FileUploader @file-added="createOrderFile" @file-removed="removeOrderFile"/>
                </b-col>
              </b-row>
              <!-- destination_code, destination_name -->
              <div v-if="client && client.industry == 1">
                <b-row class="mb-2">
                  <b-col>
                    <span>Cliente destino:</span><span class="asterisk pr-3 ">*</span>
                  </b-col>
                  <b-col class="text-right">
                    <Button
                      v-if="form.destination_code && form.destination_name"
                      size="sm"
                      variant="red"
                      @click="form.destination_code = null; form.destination_name = null"
                    >
                      <font-awesome-icon :icon="['far', 'times']" fixed-width />
                    </Button>
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <ClientSearchInput
                    v-if="!form.destination_code && !form.destination_name"
                    @assign-client="assignOrderClient"
                  />
                  <ValidationProvider v-else :rules="{ required: true, max: 100 }" name="Cliente destino" v-slot="{ valid, errors }">
                    <b-form
                      inline
                    >
                      <label for="destination-client">
                        <span class="font-weight-bold">{{ form.destination_code }} - </span>
                      </label>
                      <b-input
                        id="destination-client"
                        v-model="form.destination_name"
                        type="text"
                        style="min-width: 18.2rem; margin-left: 0.5rem;"
                        size="sm"
                        trim
                        :state="errors[0] ? false : (form.destination_name && valid ? true : null)"
                        />
                      </b-form>
                      <div v-if="errors" class="text-center">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                  </ValidationProvider>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="fetchingVenregStatus">
            <b-col align-self="center">
              <div class="text-center fs-16 text-primary">
                <b-spinner small type="grow"></b-spinner>
                <span class="pl-2">Consultando venreg...</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row v-if="syncing || sapError || sapSuccess" class="text-center">
        <b-col>
          <hr>
          <div v-if="syncing">
            <b-spinner variant="info" small />
            <span class="text-info pl-1">Creando en SAP...</span>
          </div>
          <div v-if="!syncing && (sapError || sapSuccess)" class="text-danger">
            <div v-if="isDev">
              <span v-if="sapError">
                Dev: {{sapError}}
              </span>
              <span v-if="!sapError && sapSuccess" class="text-success">
                Se ha creado correctamente la orden en SAP
              </span>
            </div>
            <span v-else>
              <span v-if="sapError">Error al actualizar en SAP, intente de nuevo</span>
              <span v-else-if="!sapError && sapSuccess" class="text-success">
                Se ha creado correctamente la orden en SAP
              </span>
            </span>
          </div>
        </b-col>
      </b-row>
      <!-- Action button -->
      <template #modal-footer>
        <Button :disabled="!orderFormValidation || syncing || sapSuccess" variant="green" class="float-right" @click="createOrder">
          <span v-if="syncing"><b-spinner small /></span>
          <span v-else>crear</span>
        </Button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate'
import FileUploader from '../shared/FileUploader.vue';
import ClientSearchInput from '../shared/ClientSearchInput'
import Button from '../shared/Button'


export default {
  name: 'QuoteCreateOrderModal',
  components: {ValidationProvider, FileUploader, ClientSearchInput, Button},
  props: {
    quoteId: {
      type: Number,
      required: true
    },
    order: {
      type: Object,
      required: false
    },
    client: {
      type: Object,
      required: true
    },
    quoteItems: {
      type: Array,
      required: true
    },
    isInvalidStock: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      document: null,
      form: {
        sap_series: null,
        started_on: null,
        due_on: null,
        maintenance_contracts: null,
        deliver_to: null,
        install_on: null,
        is_special_project: false,
        destination_code: null,
        destination_name: null,
        technical_instructions: null
      },
      sapSeriesOptions: [
        { value: 9, text: 'OI' },
        { value: 71, text: 'OR' },
        { value: 112, text: 'OM' }
      ],
      venreg: {
        text: 'Pendiente',
        status: false
      },
      createdOrder: null,
      syncing: false,
      pinging: false,
      fetchingVenregStatus: false,
      sapError: null,
      sapSuccess: false
    }
  },
  mounted () {
    this.setFormData()
  },
  computed: {
    ...mapGetters(['isDev']),
    invalidStock () {
      return this.isInvalidStock
    },
    hasQuoteItems () {
      return this.quoteItems.length > 0
    },
    orderFormValidation () {
      let notRequiredFields = Object.assign({}, this.form)
      if (this.client && this.client.industry != 1) {
        delete notRequiredFields['destination_code']
        delete notRequiredFields['destination_name']
      }
      if (this.form.sap_series != 112 ) { delete notRequiredFields['maintenance_contracts'] }
      if (this.form.sap_series != 71 ) { delete notRequiredFields['technical_instructions'] }
      if (this.hasQuoteItems && !this.invalidStock) { delete notRequiredFields['install_on'] }
      delete notRequiredFields['is_special_project']
      const values = Object.values(notRequiredFields)
      if (values.every(val => val != null && val != '' )) {
        if (this.invalidStock && this.form.install_on >= this.form.started_on) {
          return false
        }
        if (this.form.sap_series == 71 && !this.document) {
          return false
        }
        if (this.form.sap_series == 9 && this.venreg.text != 'Listo') {
          return false
        }
        return true
      } else {
        return false
      }
    }
  },
  methods : {
    setFormData () {
      if (this.order) {
        this.form = {
          sap_series: this.order.sap_series,
          started_on: this.order.started_on,
          due_on: this.order.due_on,
          maintenance_contracts: this.order.maintenance_contracts,
          deliver_to: this.order.deliver_to,
          install_on: this.order.install_on,
          is_special_project: this.order.is_special_project,
          destination_code: this.order.destination_code,
          destination_name: this.order.destination_name,
          technical_instructions: this.order.technical_instructions
        }
        this.sapError = this.order.sap_error
      }
    },
    createOrder () {
      // add creatingOrder flag with setTimeOut...
      this.syncing = true
      const formattedForm = this.formatForm()
      this.$http.post(`/api/production/quotes/${this.quoteId}/generate_order`, {
          order: formattedForm,
          order_document: this.document
        })
        .then(resp => {
          // console.log('resp', resp);
          if (!this.pinging) {
            this.createdOrder = resp.data
            setTimeout(() => this.ping(), 2000)
          }
        })
        .catch(error => {
          console.error(error);
          this.sapError = error.response.data.error
          this.syncing = false
        })
    },
    ping () {
      console.log('Pinging sap status')
      this.pinging = true
      this.$http.get(`/api/production/orders/${this.createdOrder.id}/fetch_sap_status`)
        .then(response => {
          // console.log(response.data)
          if (response.data.success) {
            this.pinging = false
            this.syncing = false
            this.sapError = null
            this.sapSuccess = true
            this.$emit('set-order', response.data.order)
            this.$emit('close-quote')
            setTimeout(() => {
              this.$emit('modal-hidden')
            }, 4000);
          } else {
            if (response.data.order.sap_error) {
              this.pinging = false
              this.syncing = false
              this.sapError = response.data.order.sap_error
              this.$emit('set-order', response.data.order)
              return
            }
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
          this.pinging = false
          this.syncing = false
        })
    },
    formatForm () {
      let form = Object.assign({}, this.form)
      if (this.client && this.client.industry != 1) {
        form['destination_code'] = null
        form['destination_name'] = null
      }
      if (this.form.sap_series != 112 ) {
        form['maintenance_contracts'] = null
      }
      if (this.hasQuoteItems && !this.invalidStock) {
        form['install_on'] = null
      }
      if (this.form.sap_series != 71 ) {
        form['technical_instructions'] = null
      }
      if (this.form.sap_series != 9 ) {
        form['is_special_project'] = null
      }
      if (form['is_special_project']) {
        form['is_special_project'] == 'false' || false ? form['is_special_project'] = false : form['is_special_project'] = true
      } else {
        form['is_special_project'] = false
      }
      form['sap_error'] = null
      return form
    },
    onModalHide () {
      this.sapError = false
      this.pinging = false
      this.syncing = false
      this.$emit('modal-hidden')
    },
    changeSapSeries (newSerie) {
      if (newSerie == 9) {
        // fetch venreg status
        this.fetchQuoteVenregStatus()
      } else {
        this.venreg.status = false
        this.venreg.text = 'Pendiente'
        this.fetchingVenregStatus = false
      }
      this.resetForm()
    },
    resetForm () {
      this.document = null
      this.form.started_on = null
      this.form.due_on = null
      this.form.maintenance_contracts = null
      this.form.deliver_to = null,
      this.form.install_on = null,
      this.form.is_special_project = false,
      this.form.destination_code = null,
      this.form.destination_name = null,
      this.form.technical_instructions = null
    },
    fetchQuoteVenregStatus () {
      if (this.fetchingVenregStatus || this.venreg.status) return
      this.fetchingVenregStatus = true
      this.$http.get(`/api/production/quotes/${this.quoteId}/fetch_venreg_status`)
        .then(resp => {
          // console.log('resp', resp);
          this.venreg.status = resp.data.status
          this.venreg.text = resp.data.status ? 'Listo' : 'Pendiente'
        })
        .catch(error => {
          this.venreg.status = false
          this.venreg.text = 'Error al consultar venreg'
          console.log('err', error);
        })
        .finally( () => {
          this.fetchingVenregStatus = false
        })
    },
    createOrderFile(form) {
      this.document = form
    },
    removeOrderFile (form) {
      this.document = null
    },
    assignOrderClient (client) {
      this.form.destination_code = client.client_num
      this.form.destination_name = client.name
    }
  }
}
</script>