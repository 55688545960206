<template>
  <div>
    <NavTabs />
    <ContentView>
      <Subheader>
        <template #default>
          <b-img :src="flag" height="25" width="40" class="border mr-2" />
          Empleados
        </template>
        <template #right>
          <Button v-if="isHrAdmin || isDev" to="/app/rrhh/empleados/nuevo" size="sm" variant="green" class="mr-3">
            <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
            <span class="pl-2">Nuevo</span>
          </Button>
          <Button v-if="isHrAdmin || isDev" to="/app/rrhh/inactivos" size="sm" class="mr-3">
            <font-awesome-icon :icon="['fas', 'user-times']" size="sm" fixed-width />
            <span class="pl-2">Ver Inactivos</span>
          </Button>
        </template>
      </Subheader>
      <b-row>
        <b-col class="pl-1 pr-0">
          <b-form-group style="width: 250px;">
            <b-input-group size="sm">
              <b-input
                v-model="filter"
                placeholder="Escriba su búsqueda"
                ref="searchInput"
                autofocus
              />
              <b-input-group-append>
                <b-button size="sm" :disabled="!filter" @click="$refs.searchInput.focus(); filter = '';">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="pl-0 text-right">
          <Button size="sm" class="dropdown-toggle" data-toggle="dropdown">
            <font-awesome-icon :icon="['fas', 'flag']" size="sm" fixed-width />
            <span class="pl-1">País</span>
          </Button>
          <div class="dropdown-menu dropdown-menu-right text-center fs-14" style="width: 80px !important;">
            <button v-if="country =='panamá'" class="dropdown-item" @click="toggleCountry">Costa Rica</button>
            <button v-else class="dropdown-item" @click="toggleCountry">Panamá</button>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-muted">
        <b-col cols="6" class="px-1">
          <small v-if="filterResultCount && filterResultCount < employeesByCountry.length">{{ filterResultCount }} resultados</small>
        </b-col>
        <b-col cols="6" class="px-1 text-right">
          <small>{{ employeesByCountry.length }} empleados en total</small>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-table
          hover
          outlined
          responsive
          head-variant="light"
          show-empty
          :items="employeesByCountry"
          :fields="fieldsByCountry"
          :filter="filter"
          :filter-function="filterWithoutAccent"
          @filtered="onFilter"
          @row-clicked="onRowClick"
        >
          <!-- Loading spinner / empty filter text -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-info">
              No empleados todavía
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-center text-danger">
              <span>No resultados</span>
            </div>
          </template>

          <template v-slot:cell(first_name)="data">
            <span>{{ data.item.first_name }}</span>
            <span v-if="data.item.second_name">{{ data.item.second_name }}</span>
          </template>
          <template v-slot:cell(last_name)="data">
            <span>{{ data.item.last_name }}</span>
            <span v-if="data.item.last_name2">{{ data.item.last_name2 }}</span>
          </template>
          <template v-slot:cell(is_manager)="data">
            <b-badge v-if="data.value" class="theme-orange">Jefatura</b-badge>
            <span v-else></span>
          </template>
          <template v-slot:cell(department)="data">
            {{ data.value | titleize}}
          </template>
          <template v-slot:cell(ancestry)="data">
            <div v-if="isHrAdmin || isDev">
              <div v-if="!data.value && !isRoot(data.item.id)" class="text-danger">
                <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
              </div>
              <div v-else>
                <div v-if="(!data.item.carnet && data.item.country == 'costa_rica') || !data.item.job_title || !data.item.cedula" class="text-warning">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </b-row>
    </ContentView>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import Subheader from '../shared/Subheader';
import NavTabs from './NavTabs';
import CostaRicaFlag from 'images/flags/costa-rica-32.png';
import PanamaFlag from 'images/flags/panama-32.png';

export default {
  name: 'EmployeeIndex',
  components: { Button, ContentView, Subheader, NavTabs },
  data() {
    return {
      country: 'costa_rica',
      fieldsCR: [
        { key: 'carnet', label: 'Carnet', sortable: true, tdClass: 'clickable' },
        { key: 'first_name', label: 'Nombre', sortable: true, tdClass: 'clickable' },
        { key: 'last_name', label: 'Apellidos', sortable: true, tdClass: 'clickable' },
        { key: 'is_manager', label: '', tdClass: 'clickable px-0' },
        { key: 'job_title', label: 'Puesto', sortable: true, tdClass: 'clickable' },
        { key: 'department', label: 'Departamento', sortable: true, tdClass: 'clickable' },
        { key: 'ancestry', label: '', tdClass: 'clickable px-0' }
      ],
      fieldsPA: [
        { key: 'first_name', label: 'Nombre', sortable: true, tdClass: 'clickable' },
        { key: 'last_name', label: 'Apellidos', sortable: true, tdClass: 'clickable' },
        { key: 'is_manager', label: '', tdClass: 'clickable px-0' },
        { key: 'job_title', label: 'Puesto', sortable: true, tdClass: 'clickable' },
        { key: 'department', label: 'Departamento', sortable: true, tdClass: 'clickable' },
        { key: 'ancestry', label: '', tdClass: 'clickable px-0' }
      ],
      filter: null,
      filterResultCount: null
    }
  },
  computed: {
    ...mapState({
      loading: state => state.employees.loading
    }),
    ...mapGetters(['employees', 'isHrAdmin', 'isRoot', 'isDev']),
    flag() {
      return this.country == 'costa_rica' ? CostaRicaFlag : PanamaFlag;
    },
    employeesByCountry() {
      return this.country == 'costa_rica' ? this.employeesCR : this.employeesPA;
    },
    employeesCR() {
      return this.employees.filter(e => e.country === 'costa_rica');
    },
    employeesPA() {
      return this.employees.filter(e => e.country === 'panamá');
    },
    fieldsByCountry() {
      return this.country == 'costa_rica' ? this.fieldsCR : this.fieldsPA;
    }
  },
  mounted() {
    this.fetchEmployeeData();
  },
  methods: {
    ...mapActions(['fetchEmployeeData']),
    onRowClick(item) {
      this.$router.push(`/app/rrhh/empleados/${item.id}`);
    },
    onFilter(filteredItems, filterResultCount) {
      this.filterResultCount = filterResultCount;
    },
    filterWithoutAccent(item, filter) {
      const accentMap = { 'á':'a', 'é':'e', 'í':'i', 'ó':'o', 'ú':'u', 'ñ':'n' };
      let valString = JSON.stringify(item.first_name) + JSON.stringify(item.second_name) + JSON.stringify(item.last_name) + JSON.stringify(item.last_name2) + JSON.stringify(item.department) + JSON.stringify(item.division) + JSON.stringify(item.job_title);
      valString = valString.toLowerCase();
      let valProcessed = '';
      for (let i = 0; i < valString.length; i++) {
        valProcessed += accentMap[valString.charAt(i)] || valString.charAt(i);
      }
      let filterProcessed = '';
      let filterString = filter.toLowerCase();
      for (let i = 0; i < filterString.length; i++) {
        filterProcessed += accentMap[filterString.charAt(i)] || filterString.charAt(i);
      }
      return valProcessed.includes(filterProcessed);
    },
    toggleCountry() {
      this.filter = '';
      this.filterResultCount = 0;
      this.country = this.country == 'costa_rica' ? 'panamá' : 'costa_rica';
    }
  }
}
</script>

<style scoped>
.table-responsive {
  font-size: 13px;
}
.spinner {
  margin-top: 180px;
}
</style>
