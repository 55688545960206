<template>
  <div>
    <b-row align-h="between" class="custom-tabs mx-0">
      <b-link class="ml-3" @click="$router.go(-1)">
        <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
        <span class="pl-2">Atrás</span>
      </b-link>
    </b-row>
    <ContentView>
      <Subheader title="Agregar Nuevo Cliente">
        <template  #right>
          <Button v-if="step !== 1" size="sm" @click="reset" class="mr-3">
            <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
            <span class="pl-1">Reset</span>
          </Button>
        </template>
      </Subheader>
      <!-- First Row Content -->
      <b-row class="mb-3">
        <!-- STEP #1 -->
        <b-col lg="6" class="mb-3 px-1 mb-lg-0">
          <b-card>
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 1 }">1</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Información general del cliente <small class="text-danger"><sup> (Requerido)</sup>  </small> </p>
                <!-- Search data on Hacienda API -->
                <b-row v-if="step == 1" class="ml-1" cols="1">
                  <b-row class="px-3">
                    <b-col lg="7" class="px-0">
                      <small v-if="isLocalGln">Buscar y completar cliente por cédula:</small>
                      <small v-else>Debe completar los datos manualmente: </small>
                    </b-col>
                    <b-col lg="5" class="px-0">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          id="isLocalGln"
                          size="sm"
                          v-model="toggleGln"
                          :aria-describedby="ariaDescribedby"
                          name="isLocalGln"
                        >
                          <b-form-radio  value="local">Local</b-form-radio>
                          <b-form-radio  value="extranjero">Extranjero</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- search input -->
                  <b-row v-if="isLocalGln" cols="2">
                    <b-col cols="11">
                      <ValidationProvider rules="numeric" name="Buscar Cliente" v-slot="{ valid, errors }">
                        <b-form-group style="width: auto" :invalid-feedback="errors[0]">
                          <b-input-group size="sm">
                            <b-input
                              v-model="searchTerm"
                              placeholder="Buscar por número de cédula"
                              :state="errors[0] ? false : (searchTerm && valid ? true : null)"
                              autofocus
                              @keyup.enter="errors[0] ? false : (searchTerm && valid ? search() : null)"
                            />
                            <b-input-group-append>
                              <b-button variant="outline-secondary" size="sm" :disabled="errors[0] || !searchTerm ? true : false" @click="search">
                                <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col cols="1" class="pl-0">
                      <b-button size="sm" :disabled="!searchTerm ? true : false" @click="searchTerm = ''">
                        <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-row>
                <hr class="mt-1">
                <!-- show Data -->
                <b-table-simple v-if="step > 1" small borderless class="fs-12">
                  <b-tbody @click="step = 1" class="step-content">
                    <b-tr>
                      <b-td>Nombre cliente:</b-td>
                      <b-td>{{ form.name }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Cédula:</b-td>
                      <b-td>{{ form.cedula }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Tipo de cédula:</b-td>
                      <b-td>{{ selectedGln }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Sector:</b-td>
                      <b-td>{{ selectedSector }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Subsector:</b-td>
                      <b-td v-if="form.subsector"> {{selectSubsector}}</b-td>
                      <b-td v-else > -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Categoría cliente:</b-td>
                      <b-td v-if="form.rank">{{ form.rank }}</b-td>
                      <b-td v-else > -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Teléfono 1:</b-td>
                      <b-td v-if="form.phone">
                        {{ form.phone }}
                        <span v-if="form.phone_extension">ext - {{ form.phone_extension }}</span>
                      </b-td>
                      <b-td v-else > -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Teléfono 2:</b-td>
                      <b-td v-if="form.phone_2">{{ form.phone_2 }}</b-td>
                      <b-td v-else > -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Correo facturación: </b-td>
                      <b-td v-if="form.email">{{ form.email }}</b-td>
                      <b-td v-else > -- </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <!-- General Client Data -->
                <ValidationObserver v-else ref="observer1" v-slot="{ invalid }">
                  <!-- ID Type -->
                  <ValidationProvider rules="required" name="Tipo de ID" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Tipo de ID</span><span class="asterisk">*</span>
                      </template>
                      <b-select
                        v-model="form.sap_gln"
                        :options="sapGlnOptions"
                        :disabled="!isLocalGlnEditable"
                        size="sm"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <option :value="null" disabled>-- Eligir uno --</option>
                        </template>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Client ID Local-->
                  <ValidationProvider v-if="isLocalGln"  :rules="glnInputRules" name="cedula" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Cédula cliente</span><span class="asterisk">*</span>
                      </template>
                      <b-input type="text" v-model="form.cedula" size="sm" :disabled="!isLocalGlnEditable || !form.sap_gln" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: 3101177456"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Client ID  -->
                  <ValidationProvider v-if="!isLocalGln" :rules="glnInputRules" name="cedula" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Cédula extranjera</span><span class="asterisk">*</span>
                      </template>
                      <b-input type="text" v-model="form.cedula" size="sm" :disabled="isLocalGln" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: 00000000000000000000" />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Client Name -->
                  <ValidationProvider rules="required" name="Nombre" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Nombre empresa o particular</span><span class="asterisk">*</span>
                      </template>
                      <b-input type="text" v-model="form.name" size="sm" :disabled="!isLocalGlnEditable" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: ACCESOS AUTOMATICOS SOCIEDAD ANONIMA"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- SalesEmployee -->
                  <ValidationProvider rules="required" name="Asesor Asignado"  v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Asesor asignado</span>
                        <span class="asterisk">*</span>
                        <font-awesome-icon :icon="['far', 'question-circle']" class="text-info clickable float-right mr-1" fixed-width v-b-tooltip.hover.v-info.rightbottom='"Si no encuentra el asesor debe agregarlo en la app en el apartado de roles"'/>
                      </template>
                      <b-select v-model="form.user_id" size="sm" :state="errors[0] ? false : (valid ? true : null)">
                        <option :value="null">- Eligir asesor -</option>
                        <option v-for="salesperson in salesAgents" :value="salesperson.id" :key="salesperson.id">
                          {{ salesperson.first_name + ' ' + salesperson.last_name }}
                        </option>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- TKM person -->
                  <b-form-group label-size="sm">
                    <template #label>
                      <span>Asesor TKM</span>
                      <font-awesome-icon :icon="['far', 'question-circle']" class="text-info clickable float-right mr-1" fixed-width v-b-tooltip.hover.v-info.rightbottom='"Si no encuentra el asesor debe agregarlo en la app en el apartado de roles"'/>
                    </template>
                    <b-select v-model="form.telemarketer_id" size="sm">
                      <option :value="null">- Eligir asesor -</option>
                      <option v-for="salesTelemarketer in salesTelemarketers" :value="salesTelemarketer.id" :key="salesTelemarketer.id">
                        {{ salesTelemarketer.first_name + ' ' + salesTelemarketer.last_name }}
                      </option>
                    </b-select>
                  </b-form-group>
                  <!-- Industry -->
                  <ValidationProvider rules="required" name="Sector" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Sector</span><span class="asterisk">*</span>
                      </template>
                      <b-select
                        v-model="form.industry"
                        :options="industryOptions"
                        size="sm"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @change="form.subsector = null"
                      >
                        <template v-slot:first>
                          <option :value="null" disabled>-- Eligir uno --</option>
                        </template>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Subsector -->
                  <b-form-group v-if="[1,2,3,4,5,6,10,14].includes(form.industry)" label="Subsector" label-size="sm">
                    <b-select
                      v-model="form.subsector"
                      size="sm"
                      :state="form.subsector ? true : null"
                      :options="subsectorMapping[form.industry]"
                    >
                      <template v-slot:first>
                        <option :value="null" disabled>-- Eligir uno --</option>
                      </template>
                    </b-select>
                  </b-form-group>
                  <!-- category -->
                  <b-form-group label="Categoría cliente:" label-size="sm">
                    <b-select
                      id="client-rank"
                      v-model="form.rank"
                      :options="clientRankOptions"
                      size="sm"
                      style="width: 100%;"
                    >
                      <template v-slot:first>
                        <option :value="null" disabled>-- Eligir uno --</option>
                      </template>
                    </b-select>
                  </b-form-group>
                  <!-- Phone 1-->
                  <ValidationProvider v-if="isLocalGln"  :rules="{digits: 8}" name="Teléfono 1" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Teléfono 1</span>
                      </template>
                      <b-row>
                        <b-col sm="12" lg="6">
                          <b-input v-model="form.phone" size="sm" :state="errors[0] ? false : (form.phone && valid ? true : null)" placeholder="Ej: 22270909"/>
                        </b-col>
                        <b-col sm="12" lg="6">
                          <ValidationProvider :rules="{numeric: true}" name="ext-1" v-slot="{ valid }">
                            <b-input-group size="sm" prepend="ext -">
                              <b-form-input type="number" v-model="form.phone_extension" :state="errors[0] ? false : (form.phone_extension && valid ? true : null)" id="client-ext-1" size="sm" placeholder="500"/>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Phone 1 No local-->
                  <ValidationProvider v-if="!isLocalGln"  :rules="{numeric: true}" name="Teléfono" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Teléfono 1</span>
                      </template>
                      <b-row>
                        <b-col>
                          <b-input v-model="form.phone" size="sm" :state="errors[0] ? false : (form.phone && valid ? true : null)"/>
                        </b-col>
                        <b-col>
                          <ValidationProvider :rules="{numeric: true}" name="ext-1" v-slot="{ valid }">
                            <b-input-group size="sm" prepend="ext -">
                              <b-form-input type="number" v-model="form.phone_extension" :state="errors[0] ? false : (form.phone_extension && valid ? true : null)" id="client-ext-1-1" size="sm" placeholder="500"/>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Phone 2-->
                  <ValidationProvider v-if="isLocalGln" :rules="{digits: 8}" name="Teléfono" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Teléfono 2</span>
                      </template>
                      <b-input v-model="form.phone_2" size="sm" :state="errors[0] ? false : (form.phone_2 && valid ? true : null)" placeholder="Ej: 26535050"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Phone 2  No local -->
                  <ValidationProvider v-if="!isLocalGln"  :rules="{numeric: true}" name="Teléfono2" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Teléfono 2</span>
                      </template>
                      <b-input v-model="form.phone_2" size="sm" :state="errors[0] ? false : (form.phone_2 && valid ? true : null)"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Email-->
                  <ValidationProvider rules="email" name="Correo1" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Correo facturación</span>
                      </template>
                      <b-input type="email" v-model="form.email" size="sm" :state="errors[0] ? false : (form.email && valid ? true : null)" placeholder="Ej: facturacion@accesos.cr"/>
                    </b-form-group>
                  </ValidationProvider>
                  <Button variant="green" class="float-right" :disabled="invalid" @click="verifyClientId">
                    Guardar
                  </Button>
                </ValidationObserver>
              </b-col>
            </b-row>
            <b-row v-if="step > 1" @click="step = 1" align-h="center" class="step-content">
              <small> Ingresar <font-awesome-icon :icon="['far', 'hand-pointer']" fixed-width /></small>
            </b-row>
          </b-card>
        </b-col>
        <!-- STEP #2 -->
        <b-col lg="6" class="mb-3 px-1 mb-lg-0">
          <b-card v-if="step >= 2" style="min-height: 353px;">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 2 }">2</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Datos de persona de contacto <small class="text-danger"><sup> (Requerido)</sup>  </small> </p>
                <hr>
                <!-- Show Data -->
                <b-table-simple v-if="step > 2" small borderless class="fs-12">
                  <b-tbody @click="step = 2" class="step-content">
                    <b-tr>
                      <b-td>Persona de contacto:</b-td>
                      <b-td class="text-uppercase">{{ contactForm.first_name }} {{contactForm.last_name}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Teléfono:</b-td>
                      <b-td>
                        {{ contactForm.phone }}
                        <span v-if="contactForm.phone_extension"> ext {{ contactForm.phone_extension }}</span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Celular:</b-td>
                      <b-td v-if="contactForm.cellular ">{{ contactForm.cellular }}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Correo electrónico:</b-td>
                      <b-td>{{ contactForm.email }}</b-td>
                    </b-tr>
                    <b-tr v-for="(email, index) in additionalEmails" :key="index">
                      <b-td></b-td>
                      <b-td>{{ email.email }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <!-- Input Contact Data fields -->
                <ValidationObserver v-else ref="observer2" v-slot="{ invalid }">
                  <!-- FistName -->
                  <ValidationProvider :rules="{required: true, max: 49}" name="Nombre" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Nombre</span><span class="asterisk">*</span>
                      </template>
                      <b-input v-model="contactForm.first_name" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Nombre contacto"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- LastName -->
                  <ValidationProvider :rules="{required: true, max: 49}" name="Apellido" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Apellido</span><span class="asterisk">*</span>
                      </template>
                      <b-input v-model="contactForm.last_name" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Apellido contacto" />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Profession -->
                  <ValidationProvider :rules="{required: false, max: 49}" name="Puesto" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Puesto</span>
                      </template>
                      <b-input v-model="contactForm.profession" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Puesto contacto" />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Phone -->
                  <ValidationProvider :rules="{required: true, digits: 8}" name="Teléfono" v-slot="{ valid, errors }">
                    <b-row>
                      <b-col class="fs-14">
                        <span>Teléfono 1</span><span class="asterisk">*</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group label-size="sm">
                          <b-input v-model="contactForm.phone" size="sm" :state="errors[0] ? false : (contactForm.phone && valid ? true : null)" placeholder="Ej: 22270909"/>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <ValidationProvider :rules="{numeric: true}" name="ext-phone-1" v-slot="{ valid }">
                          <b-input-group size="sm" prepend="ext -">
                            <b-form-input type="number" v-model="contactForm.phone_extension" :state="valid ? true : false" id="contact-phone-2" size="sm" placeholder="500"/>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row v-if="errors[0]">
                      <b-col>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-col>
                    </b-row>
                  </ValidationProvider>
                  <!-- Cellular -->
                  <ValidationProvider rules="digits:8" name="Celular" v-slot="{ valid, errors }">
                    <b-form-group label="Celular" label-size="sm" :invalid-feedback="errors[0]">
                      <b-input v-model="contactForm.cellular" size="sm" :state="errors[0] ? false : (contactForm.cellular && valid ? true : null)" placeholder="Ej: 88008800"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Emails -->
                  <ValidationProvider rules="required|email" name="Correo" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Correo electrónico</span><span class="asterisk">*</span>
                      </template>
                      <b-input type="email" v-model="contactForm.email" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: contacto@dominio.cr"/>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Additional Emails -->
                  <ValidationProvider v-for="(line, index) in additionalEmails" :key="index" rules="email" :name="'Correo' + (index + 2).toString()" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm">
                      <template #label>
                        <b-row align-h="between" class="px-3">
                          <span>Correo electrónico ({{index + 2}})</span>
                          <div class="clickable" @click="removeLine(index)">
                            <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
                          </div>
                        </b-row>
                      </template>
                      <b-input v-model="line.email" size="sm" autofocus :state="errors[0] ? false : (line.email && valid ? true : null)" />
                    </b-form-group>
                  </ValidationProvider>

                  <Button v-show="contactForm.email && !invalid && AdditionalEmailsAllowed" size="sm" @click="addLine">
                    <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                    <span class="pl-1">Email adicional</span>
                  </Button>

                  <Button variant="green" class="float-right" :disabled="invalid" @click="step = 3">
                    Guardar
                  </Button>
                </ValidationObserver>
              </b-col>
            </b-row>
            <b-row v-if="step > 2" @click="step = 2" align-h="center" class="step-content">
              <small> Ingresar <font-awesome-icon :icon="['far', 'hand-pointer']" fixed-width /></small>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- Second Row Content -->
      <b-row class="mb-2">
        <!-- STEP #3 -->
        <b-col lg="4" class="mb-3 px-1 mb-lg-0">
          <b-card v-if="step >= 3" style="min-height: 16.94rem;">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 3 }">3</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Dirección del cliente <small class="text-primary"><sup> (Opcional)</sup>  </small> </p>
                <hr>
                <!-- Show Data -->
                <b-table-simple v-if="step > 3 && !skipAddressForm" small borderless class="fs-12">
                  <b-tbody @click="step = 3" class="step-content">
                    <b-tr>
                      <b-td>Nombre de dirección:</b-td>
                      <b-td>{{ addressForm.address }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Dirección:</b-td>
                      <b-td>{{ addressForm.street }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Provincia:</b-td>
                      <b-td>{{ selectedProvince }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Cantón:</b-td>
                      <b-td>{{ addressForm.canton[1]}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Distrito:</b-td>
                      <b-td>{{ addressForm.district[1] }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <div v-if="step > 3 && skipAddressForm" class="skip-step">
                  <h6>
                    <font-awesome-icon :icon="['far', 'exclamation-triangle']" size="lg" fixed-width />
                    <span class="pl-1">No se completó la información</span>
                  </h6>
                </div>
                <!-- Input Address data fields -->
                <ValidationObserver v-if="step == 3" ref="observer3" v-slot="{ invalid }">
                  <!-- Address Name -->
                  <ValidationProvider :rules="{required: true, max: 49}" name="Nombre dirección" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Nombre de dirección</span><span class="asterisk">*</span>
                      </template>
                      <b-input type="text" v-model="addressForm.address" size="sm" placeholder="Ej: Oficina" :state="errors[0] ? false : (valid ? true : null)" />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Street/ Address -->
                  <ValidationProvider :rules="{required: true, max: 99}" name="Dirección" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Dirección</span><span class="asterisk">*</span>
                      </template>
                      <b-textarea
                        placeholder="Ej: Sabana sur 50 este del colegio, 30 mtrs oeste"
                        v-model="addressForm.street"
                        size="sm"
                        rows="3"
                        :state="errors[0] ? false : (valid ? true : null)"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Province -->
                  <ValidationProvider rules="required" name="Provincia"  v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Provincia</span><span class="asterisk">*</span>
                      </template>
                      <b-select
                        v-model="addressForm.province"
                        :options="provinceOptions"
                        size="sm"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @change="searchCantons"
                      >
                        <template #first>
                          <b-select-option :value="null">Eligir Provincia</b-select-option>
                        </template>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Canton -->
                  <ValidationProvider rules="required" name="Canton"  v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Canton</span><span class="asterisk">*</span>
                      </template>
                      <b-select
                        v-model="addressForm.canton"
                        :options="cantonAddressOptions"
                        size="sm"
                        :disabled="toggleCanton || !addressForm.province"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @change="searchDistricts"
                      >
                        <template #first>
                          <b-select-option :value="null">Eligir Canton</b-select-option>
                        </template>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- District -->
                  <ValidationProvider rules="required" name="Distrito"  v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Distrito</span><span class="asterisk">*</span>
                      </template>
                      <b-select
                        v-model="addressForm.district"
                        :options="districtAddressOptions"
                        size="sm"
                        :disabled="toggleDistrict || !addressForm.canton"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template #first>
                          <b-select-option :value="null">Eligir Distrito</b-select-option>
                        </template>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider>
                  <Button v-if="skipAddressForm" variant="green" class="float-right" @click="step = 4">
                    Saltar
                  </Button>
                  <Button v-else variant="green" class="float-right" :disabled="invalid" @click="step = 4">
                    Guardar
                  </Button>
                </ValidationObserver>
              </b-col>
            </b-row>
            <hr v-if="step > 3">
            <b-row v-if="step > 3" @click="step = 3" align-h="center" class="step-content">
              <small> Ingresar <font-awesome-icon :icon="['far', 'hand-pointer']" fixed-width /></small>
            </b-row>
          </b-card>
        </b-col>
        <!-- STEP #4 -->
        <b-col lg="4" class="mb-3 px-1 mb-lg-0">
          <b-card v-if="step >= 4" style="min-height: 16.94rem;">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 4 }">4</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Info de proyecto <small class="text-primary"><sup> (Opcional)</sup>  </small> </p>
                <hr>
                <!-- Show Data -->
                <b-table-simple v-if="step > 4 && !skipProjectForm" small borderless class="fs-12">
                  <b-tbody>
                    <b-tr>
                      <b-td>Nombre Proyecto:</b-td>
                      <b-td>{{ projectForm.name }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Dirección:</b-td>
                      <b-td>{{ projectForm.address }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Comentarios:</b-td>
                      <b-td>{{ projectForm.outcome_notes }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Asesor Asignado:</b-td>
                      <b-td>{{ currentUser | concatName4 }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <div v-if="skipProjectForm && step > 4" class="skip-step">
                  <h6>
                    <font-awesome-icon :icon="['far', 'exclamation-triangle']" size="lg" fixed-width />
                    <span class="pl-1">No se completó la información</span>
                  </h6>
                </div>
                <ValidationObserver v-if="step == 4" ref="observer4" v-slot="{ invalid }">
                  <!-- Project Name -->
                  <ValidationProvider rules="required" name="Nombre Proyecto" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Nombre proyecto</span><span class="asterisk">*</span>
                      </template>
                      <b-input type="text" v-model="projectForm.name" size="sm" :state="errors[0] ? false : (valid ? true : null)" placeholder="Ej: Proyecto Nuevo" />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Project Address -->
                  <ValidationProvider rules="required" name="Dirección" v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Dirección</span><span class="asterisk">*</span>
                      </template>
                      <b-textarea
                        v-model="projectForm.address"
                        size="sm"
                        rows="3"
                        placeholder="Ej: Sabana sur 100 este del colegio, 30 mtrs oeste"
                        :state="errors[0] ? false : (valid ? true : null)"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Project Notes -->
                  <b-form-group label="Comentarios sobre el proyecto" label-size="sm">
                    <b-textarea v-model="projectForm.outcome_notes" size="sm" rows="3"/>
                  </b-form-group>
                  <!-- Project User -->
                  <!-- <ValidationProvider v-if="isSalesLeadAdmin" rules="required" name="Asesor Asignado"  v-slot="{ valid, errors }">
                    <b-form-group label-size="sm" :invalid-feedback="errors[0]">
                      <template #label>
                        <span>Asesor Asignado</span><span class="asterisk">*</span>
                      </template>
                      <b-select v-model="form.user_id" size="sm" :state="errors[0] ? false : (valid ? true : null)">
                        <option :value="null">- Eligir asesor -</option>
                        <option v-for="salesperson in salespeople" :value="salesperson.id" :key="salesperson.id">
                          {{ salesperson.first_name + ' ' + salesperson.last_name }}
                        </option>
                      </b-select>
                    </b-form-group>
                  </ValidationProvider> -->
                  <Button v-if="skipProjectForm" variant="green" class="float-right" @click="step = 5">
                    Saltar
                  </Button>
                  <Button v-else variant="green" class="float-right" :disabled="invalid" @click="step = 5">
                    Guardar
                  </Button>
                </ValidationObserver>
              </b-col>
            </b-row>
            <hr v-if="step > 4">
            <b-row v-if="step > 4" @click="step = 4" align-h="center" class="step-content">
              <small> Ingresar <font-awesome-icon :icon="['far', 'hand-pointer']" fixed-width /></small>
            </b-row>
          </b-card>
        </b-col>
        <!-- STEP #5 -->
        <b-col lg="4" class="mb-3 px-1 mb-lg-0">
          <b-card v-if="step >= 5" style="min-height: 16.94rem;">
            <b-row>
              <b-col cols="1" class="px-0 text-right">
                <div class="step-number float-right" :class="{ valid: step > 5 }">5</div>
              </b-col>
              <b-col cols="11">
                <p class="step-header">Datos adicionales del cliente<small class="text-primary"><sup> (Opcional)</sup>  </small> </p>
                <hr>
                <!-- Show Data -->
                <b-table-simple v-if="step > 5 && !skipBillingForm" small borderless class="fs-12">
                  <b-tbody>
                    <b-tr>
                      <b-td>Requiere OC:</b-td>
                      <b-td v-if="billingForm.purchase_order_required  == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Requiere crédito:</b-td>
                      <b-td v-if="billingForm.credit_required == 'true'"> Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Es exento:</b-td>
                      <b-td v-if="billingForm.exonerated == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Correo de facturación:</b-td>
                      <b-td v-if="billingForm.has_billing_mail == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Facturación en colones:</b-td>
                      <b-td v-if="billingForm.local_currency == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Trámite físico:</b-td>
                      <b-td v-if="billingForm.physical_procedure == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Portal de documentos:</b-td>
                      <b-td v-if="billingForm.document_portal == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Anticipos:</b-td>
                      <b-td v-if="billingForm.advance_payment == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Envío de anexos:</b-td>
                      <b-td v-if="billingForm.mailing_attachments == 'true'">Si</b-td>
                      <b-td v-else>No</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Comentarios adicionales:</b-td>
                      <b-td v-if="billingForm.notes">{{billingForm.notes}}</b-td>
                      <b-td v-else> -- </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <div v-if="step > 5 && skipBillingForm" class="skip-step">
                  <h6>
                    <font-awesome-icon :icon="['far', 'exclamation-triangle']" size="lg" fixed-width />
                    <span class="pl-1">No se completó la información</span>
                  </h6>
                </div>
                <ValidationObserver v-if="step == 5" ref="observer5" v-slot="{ invalid }">
                  <!-- OC Required -->
                  <ValidationProvider rules="required" name="Requiere OC" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5" :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Requiere OC?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="purchase_order_required-1"
                        v-model="billingForm.purchase_order_required"
                        name="purchase_order_required-1"
                        class="pt-2"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="purchase_order_required-2"
                        v-model="billingForm.purchase_order_required"
                        name="purchase_order_required-2"
                        value=false
                        class="pt-2"
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Credit Required -->
                  <ValidationProvider rules="required" name="Requiere crédito" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Requiere crédito?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="credit_required-1"
                        v-model="billingForm.credit_required"
                        name="credit_required-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="checkbox-2"
                        v-model="billingForm.credit_required"
                        name="checkbox-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Exonerated -->
                  <ValidationProvider rules="required" name="Es exento" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Es exento?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="exonerated-1"
                        v-model="billingForm.exonerated"
                        name="exonerated-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="exonerated-2"
                        v-model="billingForm.exonerated"
                        name="exonerated-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- has_billing_mail -->
                  <ValidationProvider rules="required" name="Correo de facturación" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]">
                      <template #label>
                        <span v-b-tooltip.hover.v-dark.topright='"FE: Facturación Electrónica"'>¿Email FE validado?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="has_billing_mail-1"
                        v-model="billingForm.has_billing_mail"
                        name="has_billing_mail-1"
                        value=true
                        unchecked-value= null
                        inline
                        v-b-tooltip.hover.v-dark.topright='"FE: Facturación Electrónica"'
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="has_billing_mail-2"
                        v-model="billingForm.has_billing_mail"
                        name="has_billing_mail-2"
                        value=false
                        unchecked-value= null
                        inline
                        v-b-tooltip.hover.v-dark.topright='"FE: Facturación Electrónica"'
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- local_currency -->
                  <ValidationProvider rules="required" name="Colones" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Facturación en colones?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="local_currency-1"
                        v-model="billingForm.local_currency"
                        name="local_currenc-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="local_currency-2"
                        v-model="billingForm.local_currency"
                        name="local_currency-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- physical_procedure -->
                  <ValidationProvider rules="required" name="Trámite físico" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Trámite físico?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="physical_procedure-1"
                        v-model="billingForm.physical_procedure"
                        name="physical_procedure-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="physical_procedure-2"
                        v-model="billingForm.physical_procedure"
                        name="physical_procedure-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- document_portal -->
                  <ValidationProvider rules="required" name="Portal de documentos" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Portal de documentos?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="document_portal-1"
                        v-model="billingForm.document_portal"
                        name="document_portal-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="document_portal-2"
                        v-model="billingForm.document_portal"
                        name="document_portal-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- advance_payment -->
                  <ValidationProvider rules="required" name="Anticipos" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Anticipos?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="advance_payment-1"
                        v-model="billingForm.advance_payment"
                        name="advance_payment-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="advance_payment-2"
                        v-model="billingForm.advance_payment"
                        name="advance_payment-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- mailing_attachments -->
                  <ValidationProvider rules="required" name="Envío de anexos" v-slot="{ errors }">
                    <b-form-group label-size="sm" label-cols-lg="7" content-cols-lg="5"  :invalid-feedback="errors[0]" >
                      <template #label>
                        <span>¿Envío de anexos?</span><span class="asterisk">*</span>
                      </template>
                      <b-form-checkbox
                        id="mailing_attachments-1"
                        v-model="billingForm.mailing_attachments"
                        name="mailing_attachments-1"
                        value=true
                        unchecked-value= null
                        inline
                      >
                        Si
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="mailing_attachments-2"
                        v-model="billingForm.mailing_attachments"
                        name="mailing_attachments-2"
                        value=false
                        unchecked-value= null
                        inline
                      >
                        No
                      </b-form-checkbox>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Billing Notes -->
                  <b-form-group label="Comentarios:" label-size="sm">
                    <b-textarea v-model="billingForm.notes" size="sm" rows="3" placeholder="Comentarios adicionales" />
                  </b-form-group>
                  <Button v-if="skipBillingForm" variant="green" class="float-right" @click="step = 6">
                    Saltar
                  </Button>
                  <Button v-else variant="green" class="float-right" :disabled="invalid || validBillingForm" @click="step = 6">
                    Guardar
                  </Button>
                </ValidationObserver>
              </b-col>
            </b-row>
            <hr v-if="step > 5">
            <b-row v-if="step > 5" @click="step = 5" align-h="center" class="step-content">
              <small> Ingresar <font-awesome-icon :icon="['far', 'hand-pointer']" fixed-width /></small>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- Create Button -->
      <b-row v-if="step == 6" align-h="center">
        <b-row align-h="center" class="py-4">
          <Button variant="orange" @click="createClient">
            <div v-if="processing">
              <b-spinner small type="grow"></b-spinner>
              <span>Procesando...</span>
            </div>
            <span v-else>Crear Cliente</span>
          </Button>
        </b-row>
      </b-row>
    </ContentView>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Search Result Modal -->
    <b-modal
      v-model="searchResultModalShow"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>
        <h5 v-if="searchResults != 0" class="text-dark text-center w-100">
          <font-awesome-icon :icon="['fas', 'user-tie']" size="lg" fixed-width />
          <span class="pl-1">Resultado de Búsqueda</span>
        </h5>
        <h5 v-else class="text-danger text-center w-100">
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" size="lg" fixed-width />
          <span class="pl-1">Sin Resultados</span>
        </h5>
      </template>
      <b-container v-if="searchResults != 0" class="overflow-auto px-0" style="max-height: 400px">
        <p class="text-info font-italic fs-13">Si los datos son los deseados, presione continuar para completarlos en el formulario</p>
        <b-table-simple
          hover
          class="fs-12"
        >
        <b-tbody>
          <b-tr>
            <b-td>Nombre Cliente</b-td>
            <b-td>{{searchResults.nombre}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Cédula</b-td>
            <b-td>{{searchTerm}}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Tipo de Identificación</b-td>
            <b-td>
              <span v-if="searchResults.tipoIdentificacion == '01'">Cédula Física</span>
              <span v-else-if="searchResults.tipoIdentificacion == '02'">Cédula Jurídica</span>
              <span v-else-if="searchResults.tipoIdentificacion == '03'">DIMEX</span>
              <span v-else-if="searchResults.tipoIdentificacion == '04'">NITE</span>
              <span v-else>---</span>
            </b-td>
          </b-tr>
        </b-tbody>
        </b-table-simple>
      </b-container>
      <div v-else>
        <p class="text-center">No hay coincidencias del número de cédula <span class="font-weight-bold fs-17">{{searchTerm}}</span>, por favor intente de nuevo.</p>
      </div>
      <template #modal-footer>
        <Button @click="searchResultModalShow = false" class="mr-auto">Cancelar</Button>
        <Button v-if="searchError && searchResults.length == 0" variant="yellow" @click="searchResultModalShow = false; isGlnEditable = true">Agregar manualmente</Button>
        <Button v-else variant="blue" @click="addSearchResultData" :disabled="searchResults.length == 0">Continuar</Button>
      </template>
    </b-modal>

    <!-- Duplicate Modal -->
    <b-modal
      v-model="duplicateModalShow"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>
        <h5 class="text-warning text-center w-100">
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" size="lg" fixed-width />
          <span class="pl-1">Posibles Duplicados</span>
        </h5>
      </template>

      <b-container class="overflow-auto px-0" style="max-height: 400px">
        <p class="text-info font-italic fs-13">Este cliente ya está registrado con esta cédula, por favor usar el de la lista a continuación:</p>
        <b-table-lite
          :fields="fields"
          :items="duplicates"
          hover
          class="fs-12"
          @row-clicked="onDuplicateRowClick"
        >
          <template v-slot:cell(name)="data">
            <p class="mb-0">{{ data.value | titleize }}</p>
          </template>
          <template v-slot:cell(cedula)="data">
            <p class="mb-0">{{ data.value }}</p>
          </template>
          <template v-slot:cell(email)="data">
            <p v-for="(email, index) in data.value.split(';')" :key="index" class="mb-0">{{ email.trim() }}</p>
          </template>
        </b-table-lite>
      </b-container>
      <template #modal-footer>
        <Button @click="duplicateModalShow = false" class="m-auto">Cancelar</Button>
      </template>
    </b-modal>

    <!-- Result Modal -->
    <b-modal
      v-model="resultModalShow"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'info-circle']"  class="text-info modal-icon" />
      </b-row>
      <b-row align-h="center">
        <b class="fs-20">El cliente se ha creado en la APP</b>
      </b-row>
      <b-row align-h="center" class="pt-2 text-secondary">
        <small>Por favor espere unos segundos para que el cliente también se guarde en SAP</small>
      </b-row>
      <b-row v-if="processingClient" align-h="center">
        <div class="text-center text-info p-2 fs-18">
          <b-spinner type="grow"></b-spinner>
          <span class="pl-2 pb-2">Creando...</span>
        </div>
      </b-row >
      <b-row v-else align-h="around" class="my-4">
        <Button variant="orange" @click="goToHome">Ir a inicio</Button>
        <Button variant="blue" @click="goToClient">Ver cliente</Button>
        <Button variant="green" @click="resultModalShow = false; reset()">Nuevo cliente</Button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ContentView from '../shared/ContentView';
import Button from '../shared/Button';
import Subheader from '../shared/Subheader';
import ToastAlert from '../shared/ToastAlert'
import { provinces} from '../../sharedOptions/provinces'

export default {
  name: 'ClientNew',
  components: { ValidationObserver, ValidationProvider, Button, ContentView, Subheader, ToastAlert },
  data() {
    return {
      step: 1,
      fields: [
        { key: 'name', label: 'Nombre Cliente' },
        { key: 'cedula', label: 'Cédula Cliente' },
        { key: 'email', label: 'Email', thStyle: { width: '100px' }}
      ],
      searchTerm: '',
      form: {
        name: null,
        industry: null,
        subsector: null,
        sap_gln: null,
        cedula: null,
        contact_person: null,
        phone: null,
        phone_2: null,
        email: null,
        user_id: null,
        telemarketer_id: null,
        rank: null,
        phone_extension: null
      },
      contactForm: {
        first_name: null,
        last_name: null,
        profession: null,
        phone: null,
        phone_extension: null,
        cellular: null,
        email: null
      },
      addressForm: {
        address: null,
        street: null,
        province: null,
        canton: null,
        district: null
      },
      billingForm: {
        purchase_order_required: null,
        credit_required: null,
        exonerated: null,
        has_billing_mail: null,
        local_currency: null,
        physical_procedure: null,
        document_portal: null,
        advance_payment: null,
        mailing_attachments: null,
        notes:''
      },
      projectForm: {
        name: null,
        address: null,
        outcome_notes: ''
      },
      additionalEmails: [],
      clientRankOptions: [
        { value: 'Cliente A', text: 'Cliente A' },
        { value: 'Cliente AA', text: 'Cliente AA' },
        { value: 'Cliente AAA', text: 'Cliente AAA' },
        { value: 'Cliente VIP', text: 'Cliente VIP' },
        { value: 'Contado', text: 'Contado' },
        { value: 'Cuentas Claves', text: 'Cuentas Claves' }
      ],
      industryOptions: [
        { value: 1, text: 'A1 - Construcción' },
        { value: 2, text: 'A2 - Industrial' },
        { value: 3, text: 'A3 - Institución' },
        { value: 4, text: 'A4 - Comercial' },
        { value: 5, text: 'A5 - Condominios' },
        { value: 6, text: 'A6 - Casas' },
        { value: 14, text: 'A7 - Gobierno' },
        { value: 7, text: 'B1 - Inst. Premium' },
        { value: 8, text: 'B2 - Inst. Normal' },
        { value: 9, text: 'B3 - Tienda Especial' },
        { value: 10, text: 'B4 - Retailers' },
        { value: 11, text: 'B5 - Revendedor' },
        { value: 13, text: 'C1 - Administrado' },
        { value: 15, text: 'Público' }
      ],
      subsectorMapping: {
        1: [
          { value: 'A1-1', text: 'Constructoras-Electromecanicas' },
          { value: 'A1-2', text: 'Cemento-Concreto-Vidrio-Materiales' },
          { value: 'A1-3', text: 'Especificación-Diseño-Arquitectura' },
          { value: 'A1-4', text: 'Desarrollador Inmobiliario' },
          { value: 'A1-5', text: 'Constructora de Casas' },
          { value: 'A1-6', text: 'Contratista General' }
        ],
        2: [
          { value: 'A2-1', text: 'Metalmecánica-Plásticos-Carton-Textil'},
          { value: 'A2-2', text: 'Agroindustria'},
          { value: 'A2-3', text: 'Alimentaria y Bebidas' },
          { value: 'A2-4', text: 'Centros Logísticos y Aduanales' },
          { value: 'A2-5', text: 'Alta Tecnología (Médico-Biotec-Electrónica-Electromédico)' },
          { value: 'A2-6', text: 'Comunicación-Energía' },
          { value: 'A2-7', text: 'Química-Petroquímica-Cementos' },
          { value: 'A2-8', text: 'Zona Franca' },
          { value: 'A2-9', text: 'Condominio Industrial-ofibodegas' },
          { value: 'A2-10', text: 'Parque Industrial' },
        ],
        3: [
          { value: 'A3-1', text: 'Bancario-Financiero-Ahorro y Crédito' },
          { value: 'A3-2', text: 'Edificios Gubernamentales' },
          { value: 'A3-3', text: 'Educativo (Univer-Coleg-Esc)' },
          { value: 'A3-4', text: 'Hotelería-Turismo-Clubes-Entretenimiento' },
          { value: 'A3-5', text: 'Cámaras - Colegios Profesionales.' },
          { value: 'A3-6', text: 'Iglesias' },
          { value: 'A3-7', text: 'Otros Edificios Grandes' },
          { value: 'A3-8', text: 'Salud (Clínica-Hospital-Ebais)' }
        ],
        4: [
          { value: 'A4-1', text: 'Automotríz y Transportes' },
          { value: 'A4-2', text: 'Centros y Plazas Comerciales' },
          { value: 'A4-3', text: 'General (Tienda-Farmacia-Oficina-Opticas)' },
          { value: 'A4-4', text: 'Compañías Seguridad(Física-Electrónica)' },
          { value: 'A4-5', text: 'Megatiendas-Supermercados' },
          { value: 'A4-6', text: 'Parqueos públicos' },
          { value: 'A4-7', text: 'CallCenter y Oficinas Corporativas' },
          { value: 'A4-8', text: 'Centro de Deportivo' }
        ],
        5: [
          { value: 'A5-1', text: 'Condominio Horizontal' },
          { value: 'A5-2', text: 'Condominio Vertical' },
          { value: 'A5-3', text: 'Condominio Mixto' },
          { value: 'A5-4', text: 'z - sin asignar' }
        ],
        6: [
          { value: 'A6-1', text: 'Casas de Lujo' },
          { value: 'A6-2', text: 'Constructora de Residencias' },
          { value: 'A6-3', text: 'Fincas' },
          { value: 'A6-4', text: 'Casas' },
          { value: 'A6-5', text: 'Apartamentos' }
        ],
        10: [
          { value: 'B4-1', text: 'Cliente A' },
          { value: 'B4-2', text: 'Cliente B' },
          { value: 'B4-3', text: 'Cliente C' }
        ],
        14: [
          { value: 'A7-1', text: 'Ministerios'},
          { value: 'A7-2', text: 'Sector Pub Descentralizados'},
          { value: 'A7-3', text: 'Instituciones SemiAutonomas'},
          { value: 'A7-4', text: 'Empresas Publicas Estatales'},
          { value: 'A7-5', text: 'Empresas Publicas no Estatales'},
          { value: 'A7-6', text: 'Municipalidades'},
          { value: 'A7-7', text: 'Concejos Municipales'},
          { value: 'A7-8', text: 'Organos Adscritos a Instituciones Autonomas'}
        ]
      },
      provinceOptions: provinces,
      cantonAddressOptions: [],
      districtAddressOptions: [],
      duplicates: [],
      duplicateModalShow: false,
      processing: false,
      processingClient: false,
      toggleCanton: false,
      toggleDistrict: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      resultModalShow: false,
      searchResultModalShow: false,
      searchResults: [],
      clientId: null,
      toggleGln: 'local',
      isLocalGln: true,
      isGlnEditable: false,
      searchError: false
    }
  },
  mounted(){
    this.fetchEmployeeData()
  },
  watch: {
    toggleGln (newValue, oldValue) {
      this.isLocalGln = newValue == 'local' ? this.isLocalGln = true : this.isLocalGln = false
      this.form.sap_gln = newValue == 'local' ? this.form.sap_gln = null : this.form.sap_gln = '00'
    }
  },
  computed: {
    ...mapGetters(['salesAgents', 'salesTelemarketers']),
    currentUser() { return this.$store.state.currentUser },
    selectedSector() {
      return this.industryOptions.find(o => o.value == this.form.industry).text;
    },
    selectedGln() {
      return this.sapGlnOptions.find(o => o.value == this.form.sap_gln).text;
    },
    selectedProvince() {
      if (this.addressForm.province) {
        let provinceName = this.provinceOptions.find(el => el.value == this.addressForm.province)
        return provinceName.text
      } else {
        return ' -- '
      }
    },
    selectSubsector() {
      if (this.form.industry &&  this.form.subsector && [1,2,3,4,5,6,10,14].includes(this.form.industry)) {
        return this.subsectorMapping[this.form.industry].find(o => o.value == this.form.subsector).text
      }
    },
    skipAddressForm () {
      // true if every value on Address Form is null
      const values = Object.values(this.addressForm)
      return values.every(val => !val) ? true : false
    },
    skipProjectForm () {
      // true if every value on Project Form is null
      const values = Object.values(this.projectForm)
      return values.every(val => !val) ? true : false
    },
    skipBillingForm () {
      // true if every value on Billing Form is null
      const values = Object.values(this.billingForm)
      return values.every(val => !val  || val == "null" ) ? true : false
    },
    validBillingForm () {
      let notRequiredFields = Object.assign({}, this.billingForm)
      notRequiredFields['notes'] = 'not_required'
      const values = Object.values(notRequiredFields)
      return values.some(val => !val  || val == "null" ) ? true : false
    },
    AdditionalEmailsAllowed () {
      if (this.additionalEmails.length > 0) {
        // Additional emails allowed = 1
        return this.additionalEmails.length < 1 ? true : false
      } else {
        return true
      }
    },
    isLocalGlnEditable () {
      if (this.isLocalGln) {
        return this.isGlnEditable ? true : false
      }
      return false
    },
    sapGlnOptions () {
      if (this.isLocalGln) {
        return [
          { value: '01', text: 'Cédula Física' },
          { value: '02', text: 'Cédula Jurídica' },
          { value: '03', text: 'DIMEX' },
          { value: '04', text: 'NITE' }
        ]
      } else {
        return [
          { value: '00', text: 'Extranjero' }
        ]
      }
    },
    glnInputRules () {
      let rules = { numeric: true, required: true }
      switch (this.form.sap_gln) {
        case '01':
          rules.digits = 9
          break
        case '02':
          rules.digits = 10
          break
        case '03':
          rules.min = 11
          rules.max = 12
          break
        case '04':
          rules.digits = 10
          break
        case '00':
          rules.digits = 20
          break
      }
      return rules
    }
  },
  methods: {
    ...mapActions(['fetchEmployeeData']),
    verifyClientId () {
      if (this.processing) return;
      this.processing = true;
      this.$http.get('/api/production/clients/verify_client_cedula', {
          params: { cedula: this.form.cedula }
        })
        .then(response => {
          console.log(response)
          if (response.data.length > 0) {
            this.duplicates = response.data;
            this.duplicateModalShow = true;
          } else {
            this.step = 2;
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.processing = false);
    },
    createClient () {
      if (this.processing) return;
      this.processing = true
      this.processingClient = true
      // Format Contact Form
      let contactForm = Object.assign({}, this.contactForm)
      contactForm['name'] = `${this.contactForm.first_name} ${this.contactForm.last_name}`
      contactForm['email'] = this.contactForm.email
      contactForm['profession'] = this.contactForm.profession
      if (this.additionalEmails.length > 0) contactForm['email'] += '; ' + this.additionalEmails.filter(y => y).map(x => x.email).join('; ')
      contactForm['phone'] = this.contactForm.phone ? [this.contactForm.phone.slice(0, 4), '-', this.contactForm.phone.slice(4)].join('') : null
      contactForm['cellular'] = this.contactForm.cellular ? [this.contactForm.cellular.slice(0, 4), '-', this.contactForm.cellular.slice(4)].join('') : null

      // Format Client Form
      let clientForm = Object.assign({}, this.form)
      clientForm['email'] = this.form.email
      clientForm['contact_person'] = contactForm.name
      if (this.isLocalGln) {
        clientForm['phone'] = this.form.phone ?  [this.form.phone.slice(0, 4), '-', this.form.phone.slice(4)].join('') : null
        clientForm['phone_2'] = this.form.phone_2 ?  [this.form.phone_2.slice(0, 4), '-', this.form.phone_2.slice(4)].join('') : null
      } else {
        clientForm['phone'] = this.form.phone
        clientForm['phone_2'] = this.form.phone_2
      }
      clientForm['sector'] = this.selectedSector

      // Format Address Form
      let addressForm = Object.assign({}, this.addressForm)
      if (!this.skipAddressForm) {
        addressForm['canton'] = this.addressForm.canton[1]
        addressForm['district'] = this.addressForm.district[1]
      }
      // Format Billing Form
      let billingForm = Object.assign({}, this.billingForm)
      if (!this.skipBillingForm) {
        Object.keys(billingForm).forEach((i) => billingForm[i] == 'false' ? billingForm[i] = false : billingForm[i] = true)
        billingForm['notes'] = this.billingForm.notes
      }
      this.$http.post('/api/production/clients', {
          client: clientForm,
          contact: contactForm,
          address: this.skipAddressForm ? null : addressForm,
          project: this.skipProjectForm ? null : this.projectForm,
          billing: this.skipBillingForm ? null : billingForm
        })
        .then(response => {
          console.log(response)
          this.clientId = response.data.id;
          this.resultModalShow = true;
          setTimeout(() => this.processingClient = false, 4000)
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'Algo no funcionó';
          this.alertShow = true;
        })
        .finally(() => this.processing = false);
    },
    addLine () {
      let checkEmptyLines = this.additionalEmails.filter(line => !line.email);
      if (checkEmptyLines.length >= 1 && this.additionalEmails.length > 0) return;
      this.additionalEmails.push({ email: null });
    },
    removeLine (lineId) {
      this.additionalEmails.splice(lineId, 1);
    },
    onDuplicateRowClick (item) {
      this.$router.push(`/app/prod/clientes/${item.id}`);
    },
    reset () {
      Object.keys(this.form).forEach((i) => this.form[i] = null)
      Object.keys(this.addressForm).forEach((i) => this.addressForm[i] = null)
      Object.keys(this.contactForm).forEach((i) => this.contactForm[i] = null)

      Object.keys(this.billingForm).forEach((i) => this.billingForm[i] = null)
      this.billingForm.notes = ''
      Object.keys(this.projectForm).forEach((i) => this.projectForm[i] = null)
      this.projectForm.outcome_notes = ''

      this.additionalEmails = [];
      this.cantonAddressOptions = []
      this.districtAddressOptions = []
      this.searchResults = []
      this.clientId = null
      this.toggleGln = 'local'
      this.isLocalGln = true
      this.step = 1;
      requestAnimationFrame(() => {
        this.$refs.observer1.reset();
        if (this.$refs.observer2) this.$refs.observer2.reset();
        if (this.$refs.observer3) this.$refs.observer3.reset();
        if (this.$refs.observer4) this.$refs.observer4.reset();
        if (this.$refs.observer5) this.$refs.observer5.reset();
      });
    },
    goToHome () {
      this.$router.push('/');
    },
    goToClient () {
      this.$router.push(`/app/prod/clientes/${this.clientId}`);
    },
    search () {
      this.$http.get(`https://api.hacienda.go.cr/fe/ae?identificacion=${this.searchTerm}`, {
        timeout: 8000
      })
      .then(response => {
        // console.log('success hacienda',response);
        this.searchResults = response.data;
        this.searchResultModalShow = true;
      })
      .catch(error => {
        console.log('error: ', error);
        if (error.response) {
          if (error.response.status == 404) {
            // can't edit manually
            this.searchError = false
          } else {
            this.searchError = true
          }
        } else {
          // can edit manually
          this.searchError = true
        }
        this.searchResults = []
        this.searchResultModalShow = true;
      })
    },
    addSearchResultData () {
      this.form.name = this.searchResults.nombre
      this.form.cedula = this.searchTerm
      if (['01','02','03','04'].includes(this.searchResults.tipoIdentificacion)){
        this.form.sap_gln = this.searchResults.tipoIdentificacion
      }
      this.searchResultModalShow = false;
      this.searchTerm = ''
    },
    searchCantons () {
      if (this.processing) return;
      this.processing = true;
      this.toggleCanton = true
      this.$http.get('/api/canton_addresses', {
          params: { province_code: this.addressForm.province }
        })
        .then(response => {
          this.addressForm.canton = null
          this.addressForm.district = null
          this.formatCantonOptions(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => this.toggleCanton = false, 1000)
        })
    },
    formatCantonOptions (data) {
      this.cantonAddressOptions =  data.map(term => ({text: term.name, value: [term.code, term.name]}))
    },
    searchDistricts () {
      console.log(this.addressForm.canton);
      if (this.processing) return;
      this.processing = true;
      this.toggleDistrict = true
      this.$http.get('/api/district_addresses', {
          params: { canton_code: this.addressForm.canton[0] }
        })
        .then(response => {
          this.addressForm.district = null
          this.formatDistrictOptions(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          setTimeout(() => this.toggleDistrict = false, 1000)
        })
    },
    formatDistrictOptions (data) {
      this.districtAddressOptions =  data.map(term => ({text: term.name, value: [term.code, term.name]}))
    }
  }
}
</script>

<style scoped>
/* Duplicated - move to global styles? */
.step-number {
  font-size: 13px;
  border-radius: 50%;
  border: 1px solid #aaa;
  width: 20px;
  height: 20px;
  text-align: center;
}
.step-number.valid {
  background-color: #6eb165;
  border: 1px solid #6eb165;
  color: #fff;
}
.step-header {
  font-size: 15px;
  font-weight: bold;
}
.step-content:hover {
  cursor: pointer;
}

.step-content:hover small{
  cursor: pointer;
  color: darkslategray;
  transform: scale(1.5);
  font-family: sans-serif;
  font-weight: 400;
}
.skip-step {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9.375rem;
  color: #ffc107;
}
</style>
