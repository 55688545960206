<template>
  <div>
    <b-row align-h="center" class="step-buttons">
      <b-col lg="7" sm="11">
        <b-row align-h="between">
          <b-link class="back-step-button" :class="{'invisible': isFirstStep }" @click="goBack">
            <font-awesome-icon :icon="['fas', 'chevron-circle-left']" size="5x" fixed-width />
          </b-link>
          <b-button variant="outline-success" class="approve-button">
            <font-awesome-icon :icon="['far', 'balance-scale']" size="lg" fixed-width />
            <p>Aprobar</p>
          </b-button>
          <b-link class="next-step-button" :class="{'invisible': isLastStep }" @click="goForward">
            <font-awesome-icon :icon="['fas', 'chevron-circle-right']" size="5x" fixed-width />
          </b-link>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ConfiguratorStepButtons',
  props: {
    isFirstStep: {
      type: Boolean,
      default: false
    },
    isLastStep: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack () {
      this.$store.dispatch('goBack')
    },
    goForward () {
      this.$store.dispatch('goForward')
    }
  }
}
</script>
