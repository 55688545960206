<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">4</span>
            <span class="step-title">Condiciones</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <!-- Columna izquierda -->
          <b-row>
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.col_izq" />
                <span>Estado fisico columna izq.</span>
              </template>
              <b-select v-model="form.col_izq" :options="colOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.col_izq" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Columna derecha -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.col_der" />
                <span>Estado fisico columna der.</span>
              </template>
              <b-select v-model="form.col_der" :options="colOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.col_der" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Cargador superior -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.carg_sup" />
                <span>Cargador superior</span>
              </template>
              <b-select v-model="form.carg_sup" :options="cargSupOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.carg_sup" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Cerradura -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label">
              <template #label>
                <BlankIcon :field="form.cerradura" />
                <span>Cerradura central</span>
              </template>
              <b-radio-group v-model="form.cerradura" :options="cerraduraOptions" stacked @change="update" />
            </b-form-group>
          </b-row>

          <!-- Sello -->
          <b-row class="mt-5">
            <b-form-group label="Sello" label-class="outer-label">
              <b-radio-group v-model="form.sello" :options="selloOptions" stacked @change="update" />
            </b-form-group>
          </b-row>

          <!-- Viento -->
          <b-row class="mt-5">
            <b-form-group label-class="outer-label" class="w-100">
              <template #label>
                <BlankIcon :field="form.viento" />
                <span>Afectación del viento</span>
              </template>
              <b-select v-model="form.viento" :options="vientoOptions" @change="update">
                <template #first>
                  <b-select-option v-show="!form.viento" :value="null">
                    -- seleccionar --
                  </b-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-row>

          <!-- Accesorios -->
          <b-row class="mt-5">
            <b-form-group label="Accesorios" label-class="outer-label" class="w-100">
              <b-checkbox-group v-model="form.accesorios" :options="accesoriosOptions" stacked />
            </b-form-group>
          </b-row>
          <AlarmMessage field="accesorios" />

          <SubformCycles />

          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_conditions" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AlarmMessage from '../AlarmMessage'
import BlankIcon from '../BlankIcon'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
import SubformCycles from '../SubformCycles'

export default {
  name: 'CortinaConditions',
  components: { AlarmMessage, BlankIcon, ConfiguratorStepButtons, SubformCycles },
  data () {
    return {
      form: {
        col_izq: null,
        col_der: null,
        carg_sup: null,
        cerradura: null,
        sello: 'Sólo sello inferior',
        viento: null,
        accesorios: [],
        notes_conditions: ''
      },
      colOptions: ['Concreto', 'Metal', 'Madera', 'Gypsum'],
      cargSupOptions: [
        'Concreto en buen estado',
        'Metal en buen estado',
        'Agregar cargador de metal por Accesos',
        'Agregar sección fija por Accesos',
        'Agregar cargador de concreto por cliente',
        'Agregar cargador de metal por cliente',
        'Otro (detallar)'
      ],
      cerraduraOptions: [
        'No lleva',
        'Cerradura central',
        'Ojos de candado',
        'Otro (detaller)'
      ],
      selloOptions: [
        'No lleva',
        { text: 'Sólo sello inferior (std)', value: 'Sólo sello inferior' },
        'Sello inferior y lateral',
        'Sello inferior, lateral y superior'
      ],
      vientoOptions: [
        'Lugar con poco o nada de viento',
        'Lugar VENTOSO'
      ],
      accesoriosOptions: [
        'Gancho por bajar',
        'Mirilla',
        'Cobertor motor',
        'Guía desmontable',
        'Cenefa',
        'Oculta en cielo razo'
      ],
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId'])
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.col_izq = this.cortina.col_izq
    this.form.col_der = this.cortina.col_der
    this.form.carg_sup = this.cortina.carg_sup
    this.form.cerradura = this.cortina.cerradura
    this.form.sello = this.cortina.sello
    this.form.viento = this.cortina.viento
    this.form.accesorios = this.cortina.accesorios
    this.form.notes_conditions = this.cortina.notes_conditions
    this.ready = true
  },
  methods: {
    update () {
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: this.form
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
        })
        .finally(this.processing = false)
    }
  }
}
</script>
