<template>
  <div>
    <b-table
      :items="submitals"
      :fields="fields"
      tbody-tr-class="access-list-row"
      hover
      class="border-bottom fs-14"
    >
      <!-- Title -->
      <template v-slot:cell(title)="data">
        <div v-if="isAuthorized">
          <b-input
            v-if="editingTitle && data.item.id == selectedSubmital.id"
            v-model="selectedSubmitalForm.title"
            size="sm"
            autofocus
            @blur="updateSubmital"
            @keyup.enter="updateSubmital"
          />
          <div v-else>
            <b-link v-if="data.value" class="edit-link" @click="editSubmital(data.item, 'editingTitle')" @mouseover="hoverSubmital(data.item.id, 'hoveringTitle')" @mouseleave="unhoverSubmital">
              {{ data.value }}<font-awesome-icon v-show="hoveringTitle && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
            <b-link v-else class="font-italic edit-link text-muted" @click="editSubmital(data.item, 'editingTitle')" @mouseover="hoverSubmital(data.item.id, 'hoveringTitle')" @mouseleave="unhoverSubmital">
              (Sin Nombre)<font-awesome-icon v-show="hoveringTitle && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
          </div>
        </div>
        <div v-else>
          <span v-if="data.value">{{ data.value }}</span>
          <span v-else class="font-italic text-muted">(Sin Nombre)</span>
        </div>
      </template>

      <!-- Quantity -->
      <template v-slot:cell(quantity)="data">
        <div v-if="isAuthorized">
          <div v-if="editingQuantity && data.item.id == selectedSubmital.id">
            <ValidationProvider rules="required|min_value:1" v-slot="{ errors }">
              <b-input
                v-model="selectedSubmitalForm.quantity"
                size="sm"
                autofocus
                style="width: 50px"
                :class="errors.length > 0 ? 'red-input-border' : null"
                v-on="errors.length == 0 ? { blur: () => updateSubmital() } : { blur: () => cancelEditing() }"
              />
            </ValidationProvider>
          </div>
          <b-link v-else class="edit-link" @click="editSubmital(data.item, 'editingQuantity')" @mouseover="hoverSubmital(data.item.id, 'hoveringQuantity')" @mouseleave="unhoverSubmital">
            {{ data.value }}<font-awesome-icon v-show="hoveringQuantity && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
          </b-link>
        </div>
        <span v-else>{{ data.value }}</span>
      </template>

      <!-- Width -->
      <!-- <template v-slot:cell(width)="data">
        <div v-if="isAuthorized">
          <div v-if="editingWidth && data.item.id == selectedSubmital.id">
            <ValidationProvider rules="min_value:15" name="Ancho" v-slot="{ errors }">
              <b-input
                v-model="selectedSubmitalForm.width"
                size="sm"
                autofocus
                style="width: 50px"
                :class="errors.length > 0 ? 'red-input-border' : null"
                v-on="errors.length == 0 ? { blur: () => updateSubmital() } : { blur: () => cancelEditing() }"
              />
            </ValidationProvider>
          </div>
          <div v-else>
            <b-link v-if="data.value" class="edit-link" @click="editSubmital(data.item.id, 'editingWidth')" @mouseover="hoverSubmital(data.item.id, 'hoveringWidth')" @mouseleave="unhoverSubmital">
              {{ data.value }} cm<font-awesome-icon v-show="hoveringWidth && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
            <b-link v-else class="text-warning" @click="editSubmital(data.item.id, 'editingWidth')">Llenar</b-link>
          </div>
        </div>
        <div v-else>
          <span v-if="data.value">{{ data.value }} cm</span>
          <span v-else class="text-warning">Pend.</span>
        </div>
      </template> -->

      <!-- Height -->
      <!-- <template v-slot:cell(height)="data">
        <div v-if="isAuthorized">
          <div v-if="editingHeight && data.item.id == selectedSubmital.id">
            <ValidationProvider rules="min_value:15" v-slot="{ errors }">
              <b-input
                v-model="selectedSubmitalForm.height"
                size="sm"
                autofocus
                style="width: 50px"
                :class="errors.length > 0 ? 'red-input-border' : null"
                v-on="errors.length == 0 ? { blur: () => updateSubmital() } : { blur: () => cancelEditing() }"
              />
            </ValidationProvider>
          </div>
          <div v-else>
            <b-link v-if="itemHeight(data.item)" class="edit-link" @click="editSubmital(data.item.id, 'editingHeight')" @mouseover="hoverSubmital(data.item.id, 'hoveringHeight')" @mouseleave="unhoverSubmital">
              {{ itemHeight(data.item) }} cm<font-awesome-icon v-show="hoveringHeight && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
            <b-link v-else class="text-warning" @click="editSubmital(data.item.id, 'editingHeight')">Llenar</b-link>
          </div>
        </div>
        <div v-else>
          <span v-if="itemHeight(data.item)">{{ itemHeight(data.item) }} cm</span>
          <span v-else class="text-warning">Pend.</span>
        </div>
      </template> -->

      <!-- Design -->
      <!-- <template v-slot:cell(design)="data">
        <div v-if="isAuthorized">
          <b-select
            v-if="editingDesign && data.item.id == selectedSubmital.id"
            v-model="selectedSubmitalForm.design_id"
            size="sm"
            autofocus
            @change="updateSubmital()"
          >
            <template #first>
              <option v-if="!data.value" :value="null">- Eligir diseño -</option>
            </template>
            <option v-for="design in filteredDesigns()" :value="design.id" :key="design.id">{{ design.item_code }} {{ design.name }}</option>
          </b-select>
          <div v-else>
            <b-link v-if="data.value" class="edit-link" @click="editSubmital(data.item.id, 'editingDesign')" @mouseover="hoverSubmital(data.item.id, 'hoveringDesign')" @mouseleave="unhoverSubmital">
              {{ data.item.design.item_code }} {{data.item.design.name }}<font-awesome-icon v-show="hoveringDesign && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
            <b-link v-else class="text-warning" @click="editSubmital(data.item.id, 'editingDesign')">Elegir</b-link>
          </div>
        </div>
        <div v-else>
          <span v-if="data.value">{{ data.item.design.item_code }} {{data.item.design.name }}</span>
          <span v-else class="text-warning">Pend.</span>
        </div>
      </template> -->

      <!-- Motor -->
      <!-- <template v-slot:cell(motor)="data">
        <div v-if="isAuthorized">
          <b-select
            v-if="editingMotor && data.item.id == selectedSubmital.id"
            v-model="selectedSubmitalForm.motor_id"
            size="sm"
            autofocus
            @change="updateSubmital()"
          >
            <template #first>
              <option v-if="!data.value" :value="null">- Eligir motor -</option>
            </template>
            <option v-for="motor in filteredMotors()" :value="motor.id" :key="motor.id">{{ motor.item_code }}</option>
          </b-select>
          <div v-else>
            <b-link v-if="data.value" class="edit-link" @click="editSubmital(data.item.id, 'editingMotor')" @mouseover="hoverSubmital(data.item.id, 'hoveringMotor')" @mouseleave="unhoverSubmital">
              {{ data.item.motor.item_code }}<font-awesome-icon v-show="hoveringMotor && data.item.id == hoveredSubmitalId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
            </b-link>
            <b-link v-else class="text-warning" @click="editSubmital(data.item.id, 'editingMotor')">Elegir</b-link>
          </div>
        </div>
        <div v-else>
          <span v-if="data.value">{{ data.item.motor.item_code }}</span>
          <span v-else class="text-warning">Pend.</span>
        </div>
      </template> -->

      <!-- Status -->
      <template v-slot:cell(status)="data">
        <b-row v-if="isAuthorized" align-h="around">
          <div v-if="data.item.progress == 100">
            <font-awesome-icon :icon="['fas', 'check-square']" size="lg" fixed-width class="text-success" />
          </div>
          <b-link v-if="data.item.alarm_data && Object.keys(data.item.alarm_data).length > 0" style="color: inherit" @click="openAlarmsModal(data.item)">
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="lg" fixed-width class="text-danger" />
          </b-link>
          <b-link v-if="data.item.warnings.length > 0" style="color: inherit" @click="openAlarmsModal(data.item)">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" fixed-width class="text-warning" />
          </b-link>
        </b-row>
        <div v-else>
          <font-awesome-icon title="No autorizado" :icon="['fas', 'ban']" size="lg" fixed-width class="text-danger" />
        </div>
      </template>

      <!-- Progress -->
      <template v-slot:cell(progress)="data">{{ data.value }}%</template>

      <!-- Actions -->
      <template v-slot:cell(actions)="data">
        <b-row v-if="isAuthorized" align-h="around" align-v="center">
          <!-- TODO: Reenable -->
          <!-- <Button size="sm" title="Copiar" style="height: 28px" @click="copyAccess(data.item, false)">
            <font-awesome-icon :icon="['far', 'copy']" size="lg" fixed-width />
          </Button> -->
          <Button size="sm" variant="blue" @click="goToSubmital(data.item)">
            Config
          </Button>
        </b-row>
      </template>
    </b-table>

    <!-- Alarms Modal -->
    <b-modal v-model="alarmsModalShow" centered hide-footer>
      <template #modal-title>
        <h5>Alarms y avisos de submital {{ selectedSubmital.submital_no }}</h5>
      </template>
      <b-container v-if="selectedSubmital" class="px-3">
        <div v-for="(stepData, stepName) in selectedSubmital.alarm_data" :key="stepName">
          <b-row v-for="(alarmObj, index) in stepData.alarms" :key="index">
            <p class="text-danger">
              {{ Object.values(alarmObj)[0] }}
            </p>
          </b-row>
        </div>
        <b-row v-if="selectedSubmital.warnings.length > 0">
          <p v-for="warning in selectedSubmital.warnings" :key="warning" class="text-warning">{{ warning }}</p>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Button from '../shared/Button'
import ToastAlert from '../shared/ToastAlert'

export default {
  name: 'ProjectShowSubmitalTable',
  components: {
    ValidationProvider,
    Button,
    ToastAlert
  },
  props: {
    submitals: {
      type: Array
    },
    isAuthorized: {
      type: Boolean
    }
  },
  data () {
    return {
      selectedSubmital: null,
      selectedSubmitalForm: {
        title: null,
        quantity: null,
        // width: null,
        // height: null,
        // design_id: null,
        // motor_id: null,
      },
      hoveredSubmitalId: null,
      hoveringTitle: false,
      hoveringQuantity: false,
      // hoveringWidth: false,
      // hoveringHeight: false,
      // hoveringDesign: false,
      // hoveringMotor: false,
      editingTitle: false,
      editingQuantity: false,
      // editingWidth: false,
      // editingHeight: false,
      // editingDesign: false,
      // editingMotor: false,
      alarmsModalShow: false,
      fields: [
        { key: 'submital_no', label: 'ID', thClass: 'top-borderless', thStyle: { width: '90px' } },
        { key: 'title', label: 'Nombre', thClass: 'top-borderless' },
        { key: 'quantity', label: 'Cant.', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        // { key: 'width', label: 'Ancho', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        // { key: 'height', label: 'Alto', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        // { key: 'design', label: 'Diseño', thClass: 'top-borderless', thStyle: { width: '250px' } },
        // { key: 'motor', label: 'Motor', thClass: 'top-borderless', thStyle: { width: '200px' } },
        { key: 'status', label: '', tdClass: 'text-center', thClass: 'top-borderless' },
        { key: 'progress', label: 'Progreso', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'actions', label: '', thClass: 'top-borderless' }
      ],
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  methods: {
    goToSubmital (submital) {
      this.$router.push(`/app/prod/submitals/${submital.id}`)
    },
    hoverSubmital (submitalId, property) {
      this.hoveredSubmitalId = submitalId
      this[property] = true
    },
    unhoverSubmital () {
      this.hoveredSubmitalId = null
      this.hoveringTitle = false
      this.hoveringQuantity = false
      // this.hoveringWidth = false
      // this.hoveringHeight = false
      // this.hoveringDesign = false
      // this.hoveringMotor = false
    },
    cancelEditing () {
      // console.log('cancel editing')
      this.editingTitle = false
      this.editingQuantity = false
      // this.editingWidth = false
      // this.editingHeight = false
      // this.editingDesign = false
      // this.editingMotor = false
      this.hoveredSubmitalId = null
      this.hoveringTitle = false
      this.hoveringQuantity = false
      // this.hoveringWidth = false
      // this.hoveringHeight = false
      // this.hoveringDesign = false
      // this.hoveringMotor = false
    },
    editSubmital (submital, property) {
      this.selectedSubmital = submital
      this.selectedSubmitalForm.title = this.selectedSubmital.title
      this.selectedSubmitalForm.quantity = this.selectedSubmital.quantity
      // this.selectedSubmitalForm.width = this.selectedSubmital.width
      // this.selectedSubmitalForm.height = this.itemHeight(this.selectedSubmital)
      // this.selectedSubmitalForm.design_id = this.selectedSubmital.design_id
      // this.selectedSubmitalForm.motor_id = this.selectedSubmital.motor_id
      this[property] = true
    },
    updateSubmital () {
      if (this.processing) { return }
      this.processing = true;
      this.$http.patch(`/api/production/submitals/${this.selectedSubmital.id}`, {
          submital: this.selectedSubmitalForm
        })
        .then((res) => {
          this.$emit('submital-updated', res.data)
          this.cancelEditing()
        })
        .catch((e) => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
         })
        .finally(this.processing = false)
    },
    openAlarmsModal (submital) {
      this.selectedSubmital = submital
      this.alarmsModalShow = true
    }
  }
}
</script>
