const state = {
  submital: null,
  configurable: null,
  currentStep: 'design'
}

const getters = {
  configurableId (state) {
    if (state.submital) {
      return state.submital.configurable_id
    }
  },
  configurableType (state) {
    if (state.submital) {
      return state.submital.configurable_type.split('::')[1]
    }
  },
  configurableTypeUrlString (_, getters) {
    let urlString
    if (getters.configurableType === 'Seccional') {
      urlString = getters.configurableType.toLowerCase() + 'es'
    } else {
      urlString = getters.configurableType.toLowerCase() + 's'
    }
    return urlString
  },
  configuratorSteps (_, getters) {
    if (getters.configurableType === 'Cortina') {
      return [
        { en: 'design', es: 'diseño' },
        { en: 'dimensions', es: 'dimensiones' },
        { en: 'track', es: 'guías' },
        { en: 'conditions', es: 'condiciones' },
        { en: 'automation', es: 'automatización' },
        { en: 'components', es: 'componentes' },
        { en: 'electrical', es: 'eléctrica' },
        { en: 'limitations', es: 'limitaciones' }
      ]
    } else {
      return []
    }
  },
  projectType (state) {
    if (state.submital) {
      return state.submital.project.project_type
    }
  },
  isCortina (_, getters) {
    return getters.configurableType === 'Cortina'
  },
  isSeccional (_, getters) {
    return getters.configurableType === 'Seccional'
  },
  isCorredizo (_, getters) {
    return getters.configurableType === 'Corredizo'
  },
  isAbatible (_, getters) {
    return getters.configurableType === 'Abatible'
  },
  assignedColor (state, getters) {
    if (getters.isCortina && state.configurable.encargado_color === 'Accesos color original') {
      return state.configurable.color_ext
    } else {
      return state.configurable.color
    }
  }
}

const actions = {
  goForward ({ state, commit, getters }) {
    const index = getters.configuratorSteps.map(s => s.en).indexOf(state.currentStep)
    commit('setCurrentStep', getters.configuratorSteps[index + 1].en)
  },
  goBack ({ state, commit, getters }) {
    const index = getters.configuratorSteps.map(s => s.en).indexOf(state.currentStep)
    commit('setCurrentStep', getters.configuratorSteps[index - 1].en)
  },
  clear ({ state }) { // unused
    state.configurable = null
  }
}

const mutations = {
  setSubmital (state, obj) {
    state.submital = obj
  },
  setConfigurable (state, obj) {
    state.configurable = obj
  },
  setCurrentStep (state, string) {
    state.currentStep = string
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
