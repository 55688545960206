<template>
  <b-card header="Historial Reciente">
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner type="grow" small class="my-3"></b-spinner>
    </div>
    <div v-else>
      <div v-if="visits.length > 0">
        <div v-if="forDistributor">
          <b-link v-for="visit in visits" :to="visit.url" :key="visit.id" class="visit-link">
            <div class="row visit-row">
              <div class="col-3">{{ visit.label }}</div>
              <div class="col-3">{{ visit.code }}</div>
              <div class="col-6 text-truncate">{{ visit.name }}</div>
            </div>
          </b-link>
        </div>
        <div v-else>
          <a v-for="visit in visits" :href="visit.url" :key="visit.id" class="visit-link" data-turbolinks="false">
            <div class="row visit-row">
              <div class="col-3">{{ visit.label }}</div>
              <div class="col-3">{{ visit.code }}</div>
              <div class="col-6 text-truncate">{{ visit.name }}</div>
            </div>
          </a>
        </div>
      </div>
      <div v-else>
        <div class="row justify-content-center mt-4 mb-4 pt-2">
          <p class="pt-2 fs-12"><i>No hay historial todavía</i></p>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'RecentVisitsCard',
  props: {
    forDistributor: Boolean,
    forInstaller: Boolean
  },
  data() {
    return {
      loading: true,
      visitData: []
    }
  },
  computed: {
    visits() {
      const urlConverter = (val) => {
        if (val == 'Client') {
          return 'app/prod/clientes'
        } else if (val == 'Project') {
          return 'app/prod/proyectos'
        } else if (val == 'Quote') {
            if (this.forDistributor) {
              return this.forDistributor ? 'app/dist/cotizador' : 'app/prod/cotizador' ;
            } else if (this.forInstaller) {
              return this.forInstaller ? 'app/inst/cotizador' : 'app/prod/cotizador' ;
            } else {
              return 'app/prod/cotizador'
            }
        } else if (val == 'DistributorClient') {
          return 'app/dist/clientes';
        } else {
          if (val == 'Seccional') {
            val += 'e';
          }
          return val.toLowerCase() + 's';
        }
      }
      const labelConverter = (val) => {
        if (val == 'Client' || val == 'DistributorClient') {
          return 'Cliente';
        } else if (val == 'Project') {
          return 'Proyecto'
        } else if (val == 'Quote') {
          return 'Cotización';
        } else if (val == 'Template') {
          return 'Plantilla';
        } else {
          return val;
        }
      }
      let visits = this.visitData;
      visits.map(v => {
        v.url = `/${urlConverter(v.model_type)}/${v.model_id}`;
        v.label = labelConverter(v.model_type);
      })
      return visits;
    }
  },
  mounted() {
    this.fetchVisitData();
  },
  methods: {
    fetchVisitData() {
      this.$http.get('/api/visits')
        .then(response => {
          console.log(response)
          this.visitData = response.data;
        })
        .catch(error => {
          console.log(error)
          // TODO
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
.visit-link {
  text-decoration: none;
  color: #596981;
  height: 30px;
}
.visit-row {
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 12px;
}
.visit-row:hover {
  background-color: #f8f9fa;
}
.card {
  margin-bottom: 2em;
}
.card-header {
  color: #f0eaea;
  font-size: 0.875rem;
  font-weight: 370;
  padding: 0.25rem 1.25rem;
  background-color: #8a8987 !important;
}
.card-body {
  padding: 0;
}
</style>
