import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  disable() {
    console.log('disabling button')
    this.buttonTarget.disabled = true;
  }

  delayEnable() {
    console.log('enabling button')
    setTimeout(() => this.enable(), 1000);
  }

  enable() {
    this.buttonTarget.disabled = false;
  }
}

