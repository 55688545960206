import Vue from 'vue';

Vue.filter('dollarize', function (value) {
  value = Number(value);
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
})
