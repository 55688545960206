<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col
        v-for="link in accessLinks"
        :key="link.title"
        sm="4"
        class="p-0"
        align-self="center"
      >
      <router-link :to="link.url">
        <b-card no-body>
          <b-card-text>
            <font-awesome-icon :icon="link.iconName"/>
            <h4 id="title" class="py-2">{{link.title}}</h4>
            <p id="description">{{link.description}}</p>
          </b-card-text>
        </b-card>
      </router-link>
      </b-col>
    </b-row>
    <!-- <h2><u>Últimos {{purchaseOrders.length}} registros</u></h2> -->
    <hr>
    <b-row class="pt-2 overflow-auto">
      <b-col>
        <b-table
          :fields="fields"
          :items="purchaseOrders"
          head-variant="light"
          fixed
          hover
          show-empty
          class="fs-15"
        >
          <template #empty>
            <div v-if="processing" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span>No hay resultados</span>
            </div>
          </template>
          <template v-slot:cell(client_id)="data">
            <span>{{ data.item.client.client_num }} - {{ data.item.client.name }}</span>
          </template>
          <template v-slot:cell(sap_doc_no)="data">
            <b @click="copyTextToClipboard(data.value)" class="copy-text">
              {{ data.value }}
              <sup><font-awesome-icon :icon="['far', 'copy']" fixed-width /></sup>
            </b>
          </template>
          <template v-slot:cell(created_at)="data">
            <span>{{ data.value | formatDateTime}}</span>
          </template>
          <template v-slot:cell(purchase_deliveries_count)="data">
            <b-badge :variant="data.item.purchase_deliveries.length == 0 ? 'danger' : 'primary'" pill>{{data.item.purchase_deliveries.length}}</b-badge>
          </template>
          <template v-slot:cell(show_details)="row">
            <Button size="sm" style="width: 25px" @click="showRowDetails(row.item, row.index)">
              <font-awesome-icon v-if="row.detailsShowing" :icon="['fas', 'caret-down']" size="lg" fixed-width />
              <font-awesome-icon v-else :icon="['fas', 'caret-right']" size="lg" fixed-width />
            </Button>
          </template>
          <template v-slot:row-details="row">
            <b-row class="px-4">
              <b-col>
                <ul v-if="row.item.purchase_deliveries.length > 0" :class="'ul-margin'" class="fs-12 mb-0">
                  <li v-for="purchaseDelivery in row.item.purchase_deliveries" :key="purchaseDelivery.id" class="pb-2">
                    <table class="table table-borderless table-sm brown-color m-0">
                      <tr style="vertical-align: middle">
                        <td class="p-0" style="width: 10rem">
                          <span class="pl-2"># {{ purchaseDelivery.purchase_internal_id }}</span>
                        </td>
                        <td class="p-0 text-center" style="width: 1.25rem;">|</td>
                        <td class="p-0 text-center" style="width: 5rem">
                          <span v-if="purchaseDelivery.sap_doc_no"><b-badge variant="success">Finalizada</b-badge></span>
                          <span v-else-if="purchaseDelivery.is_executed"><b-badge variant="warning">En validación</b-badge></span>
                          <span v-else ><b-badge variant="danger">En registro</b-badge></span>
                        </td>
                        <td class="p-0 text-center" style="width: 1.25rem;">|</td>
                        <td class="p-0">
                          <span v-if="purchaseDelivery.sap_doc_no">SAP # <b>{{purchaseDelivery.sap_doc_no}}</b></span>
                          <span v-else class="text-muted">Sin # doc SAP</span>
                        </td>
                        <!-- TODO: make it work with link -->
                        <!-- <td class="p-0 text-center" style="width: 20px;">|</td>
                        <td class="p-0 text-center" style="width: 110px">
                          <span v-if="purchaseDelivery.sap_doc_no"><b-badge variant="success">Finalizada</b-badge></span>
                          <span v-else-if="purchaseDelivery.is_executed"><b-badge variant="warning">En validación</b-badge></span>
                          <span v-else ><b-badge variant="danger">En registro</b-badge></span>
                        </td>
                        <td class="p-0 text-center" style="width: 10px;">|</td>
                        <td class="p-0" >
                          <b-link to="#" class="pl-2 text-primary">
                            Ingresar
                          </b-link>
                        </td> -->
                      </tr>
                    </table>
                  </li>
                </ul>
                <div v-else class="text-center">
                  <ul :class="'ul-margin'" class="fs-12 mb-0">
                    <li class="pb-2">
                      <table class="table table-borderless table-sm brown-color m-0">
                        <tr style="vertical-align: middle">
                          <td class="p-0 text-danger text-left" style="width: 110px">
                            <span class="pl-2">Sin registros de EM</span>
                          </td>
                        </tr>
                      </table>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr';
import Button from '../../shared/Button'
import ToastAlert from '../../shared/ToastAlert'
export default {
  name: "Index",
  components: { Button, ToastAlert },
  data () {
    return {
      adminAccessLinks: [
        {
          title: 'CONSULTAR OC',
          description: 'Buscar por número de documento la OC que corresponde a la entrada de mercadería',
          iconName: ['fas', 'search'],
          url: {name: 'PurchaseDeliverySearch'}
        },
        {
          title: 'REGISTRAR CONTEO',
          description: 'Registrar el conteo basado en la OC buscada',
          iconName: ['fas', 'dolly'],
          url: {name: 'PurchaseDeliveryRegister'}
        },
        {
          title: 'VALIDAR CONTEO',
          description: 'Validar lo registrado para crear en SAP el registro',
          iconName: ['fas', 'clipboard-check'],
          url: {name: 'PurchaseDeliveryValidation'}
        }
      ],
      supervisorAccessLinks: [
        {
          title: 'CONSULTAR OC',
          description: 'Buscar por número de documento la OC que corresponde a la entrada de mercadería',
          iconName: ['fas', 'search'],
          url: {name: 'PurchaseDeliverySearch'}
        },
        {
          title: 'REGISTRAR CONTEO',
          description: 'Registrar el conteo basado en la OC buscada',
          iconName: ['fas', 'dolly'],
          url: {name: 'PurchaseDeliveryRegister'}
        }
      ],
      purchaseOrderData: {},
      purchaseOrderList: [],
      processing: false,
      loading: false,
      fields: [
        { key: 'show_details', label: '', tdClass: 'text-left', thStyle: { width: '10px' } },
        { key: 'purchase_internal_id', label: '# documento', tdClass: 'clickable', thStyle: { width: '70px' } },
        { key: 'sap_doc_no', label: 'OC SAP', tdClass: 'clickable', thStyle: { width: '30px' } },
        { key: 'client_id', label: 'Proveedor', tdClass: 'clickable', thStyle: { width: '120px' } },
      { key: 'purchase_deliveries_count', label: "Cantidad EM", tdClass: 'clickable text-center',thClass: 'text-center', thStyle: { width: '30px' } },
        { key: 'created_at', label: 'Fecha creación', tdClass: 'clickable', thStyle: { width: '55px' } },
      ],
      rowSelected: {},
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  mounted () {
    this.fetchPurchaseOrders()
  },
  computed: {
    ...mapGetters(['isPurchaseDeliveryAdmin', 'isPurchaseDeliverySupervisor', 'isDev']),
    purchaseOrders () {
      let data = this.purchaseOrderList.map(id => {
        let item = this.purchaseOrderData[id]
        this.$set(item, '_showDetails', false)
        return item
      })
      return data.sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
    },
    accessLinks () {
      if (this.isPurchaseDeliveryAdmin || this.isDev) {
        return this.adminAccessLinks
      }
      return this.supervisorAccessLinks
    }
  },
  methods: {
    fetchPurchaseOrders () {
      if (this.processing){ return }
      this.processing = true
      this.$http.get('/api/production/purchase_orders', {
        params: {
          view_filter: false
        }
      })
        .then(response => {
          // console.log(response.data)
          this.normalizeData(response.data)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.processing  = false
        })
    },
    normalizeData (data) {
      const dataNormalized = normalize(
        { purchaseOrders: data },
        { purchaseOrders: [ new schema.Entity('purchaseOrders') ] }
      )
      if (dataNormalized.entities.hasOwnProperty('purchaseOrders')) {
        this.purchaseOrderData = dataNormalized.entities.purchaseOrders
      }
      this.purchaseOrderList = dataNormalized.result.purchaseOrders
    },
    showRowDetails (row, index) {
      if (!row._showDetails) {
        this.purchaseOrders[index]._showDetails = true
        this.purchaseOrders.forEach(transaction => {
          row.id ===  transaction.id ? transaction._showDetails = true : transaction._showDetails = false
        })
        this.rowSelected = row
      } else {
        row._rowVariant = ''
        this.purchaseOrders[index]._showDetails = false
        this.rowSelected = null
      }
    },
    async copyTextToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text);
        this.alertVariant = 'info'
        this.alertMessage = '# de OC copiado'
        this.alertShow = true
      } catch($e) {
        this.alertVariant = 'danger'
        this.alertMessage = 'Error al copiar el # OC, intente nuevamente'
        this.alertShow = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 1rem;
  opacity: 0.9;
  height: 20rem;
  border: 0px;
  background: #dae2f4;
  border-radius: 1rem;
  margin: 10px;
  svg {
    width: 100px;
    height: 100px;
  }
  #description {
    font-weight: 200;
  }
  #title {
    font-weight: 500;
  }
}
.card:hover {
  opacity: 1;
  box-shadow: 0 0 20px 4px #828ca2;
  text-shadow: 2px 5px 14px #828ca2;
  svg {
    animation: iconMove 1.5s ease infinite;
  }
}
@keyframes iconMove {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-30px); }
  100% { transform: translateY(0); }
}
a {
  text-decoration: none;
  color: inherit;
}

/*Tree lines*/
.ul-margin {
  margin-left: 7%;
}

ul, li {
  position: relative;
}
ul {
  list-style: none;
  padding-left: 35px;
}

li::before, li::after {
  content: "";
  position: absolute;
  left: -12px;
}
li::before {
  border-top: 1px solid rgb(121, 100, 101);
  top: 9px;
  width: 11px;
  height: 0;
}
li::after {
  border-left: 1px solid rgb(121, 100, 101);
  top: -10px;
  width: 0px;
  height: 100%;
}
ul > li:last-child::after {
  height: 20px;
}
.copy-text {
  &:hover {
    cursor: copy;
    font-size: 1rem;
    color: blue;
  }
}
</style>