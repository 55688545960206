import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radioOption", "validationBlank"]

  connect() {
    var type = this.data.get('type');
    if (type == 'class') {
      this.toggleByClass();
    } else {
      this.toggleById();
    }
  }

  toggleByClass() {
    this.radioOptionTargets.forEach(el => {
      if (el.checked) {
        if (this.data.has('validation')) {
          this.validationBlankTarget.classList.add('d-none');
        }
        var label = el.closest('label');
        label.classList.add('active');
        label.classList.remove('blurred');
        var radioSiblings = Array.prototype.filter.call(label.parentNode.children, function(child) {
          return child !== label;
        });
        radioSiblings.forEach(el => {
          el.classList.remove('active');
          el.classList.add('blurred');
        });

        var selectedClass = document.getElementsByClassName(el.value.toLowerCase().replace(/\s/g, ''));
        Array.from(selectedClass).forEach(el => {
          el.classList.remove('d-none');
          var siblings = Array.prototype.filter.call(el.parentNode.children, function(child) {
            return child !== el;
          });
          siblings.forEach(el => {
            el.classList.add('d-none');
          });
        });
      }
    });
  }

  toggleById() {
    this.radioOptionTargets.forEach(el => {
      if (el.checked) {
        if (this.data.has('validation')) {
          this.validationBlankTarget.classList.add('d-none');
        }
        var label = el.closest('label');
        label.classList.add('active');
        label.classList.remove('blurred');
        var radioSiblings = Array.prototype.filter.call(label.parentNode.children, function(child) {
          return child !== label;
        });
        radioSiblings.forEach(el => {
          el.classList.remove('active');
          el.classList.add('blurred');
        });

        var selected = document.getElementById(el.value.toLowerCase().replace(/\s/g, ''));
        selected.classList.remove('d-none');
        var siblings = Array.prototype.filter.call(selected.parentNode.children, function(child) {
          return child !== selected;
        });
        siblings.forEach(el => {
          el.classList.add('d-none');
        });
      }
    });
  }
}

