<template>
  <div>
    <!-- Quote cards (mobile) -->
    <div class="d-xl-none">
      <b-row v-for="quote in quotes" :key="quote.id" align-h="center">
        <b-card class="mb-3" style="width: 90%">
          <b-container>
            <b-row align-v="center">
              <b-col cols="6">
                <b-row class="text-muted">
                  <span>{{ quote.quote_id }}</span>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row align-h="end" align-v="center">
                  <span class="pl-2 text-muted">{{ quote.total | dollarize }}</span>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="pb-3 border-bottom">
              <span v-if="quote.title">{{ quote.title }}</span>
              <span v-else class="text-muted font-italic">(Sin Titulo)</span>
            </b-row>
            <b-row class="my-3 pb-3 border-bottom text-muted">
              <b-col>
                <b-row align-v="center">
                  <span>Status:</span>
                  <span class="pl-2">{{ quote.status | titleize }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row align-h="end" align-v="center">
                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                  <span class="pl-2">{{ quote.created_at | formatDateCompact }}</span>
                </b-row>
              </b-col>
            </b-row>
            <b-row align-h="between" class="mt-3">
              <Button v-if="isAuthorized" size="sm"  @click="copyQuote(quote, true)">
                <font-awesome-icon :icon="['far', 'copy']" fixed-width />
                <span class="pl-1">Copiar</span>
              </Button>
              <div v-else></div>
              <Button size="sm" variant="blue" @click="goToQuote(quote)">Cotizador</Button>
            </b-row>
          </b-container>
        </b-card>
      </b-row>
      <b-row align-h="center" class="py-3">
        <Button variant="orange" @click="createQuoteModalShow = true">
          <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
          <span class="pl-1">Nueva Cotización</span>
        </Button>
      </b-row>
    </div>

     <!-- Quote table (desktop) -->
    <div class="d-none d-xl-block">
      <b-table
        :items="quotes"
        :fields="fields"
        hover
        show-empty
        empty-text="No tiene cotizaciones todavía"
        class="border-bottom fs-14"
      >
        <!-- Title -->
        <template v-slot:cell(title)="data">
          <div v-if="isAuthorized">
            <b-input
              v-if="editingTitle && data.item.id == selectedQuote.id"
              v-model="selectedQuoteForm.title"
              size="sm"
              autofocus
              @blur="updateQuote(); unhoverQuote()"
              @keyup.enter="updateQuote(); unhoverQuote()"
            />
            <div v-else>
              <b-link v-if="data.value" class='edit-link' @click="editQuote(data.item.id, 'editingTitle')" @mouseover="hoverQuote(data.item.id, 'hoveringTitle')" @mouseleave="unhoverQuote">
                {{ data.value }}<font-awesome-icon v-show="hoveringTitle && data.item.id == hoveredQuoteId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
              <b-link v-else class="text-muted font-italic edit-link" @click="editQuote(data.item.id, 'editingTitle')" @mouseover="hoverQuote(data.item.id, 'hoveringTitle')" @mouseleave="unhoverQuote">
                (Sin Titulo)<font-awesome-icon v-show="hoveringTitle && data.item.id == hoveredQuoteId" :icon="['fas', 'edit']" fixed-width class="text-black-50" />
              </b-link>
            </div>
          </div>
          <div v-else>
            <span v-if="data.value">{{ data.value }}</span>
            <span v-else class="text-muted font-italic">(Sin Titulo)</span>
          </div>
        </template>

        <template v-slot:cell(total)="data">
          <span>{{ data.value | dollarize }}</span>
        </template>

        <template v-slot:cell(status)="data">
          <span v-if="data.item.project_status === 'vendido' || data.item.project_status === 'desestimado' || data.item.project_status ==='perdido'">Cerrada</span>
          <span v-else>{{ data.value | titleize }}</span>
        </template>

        <template v-slot:cell(created_at)="data">
          {{ data.value | formatDateCompact }}
        </template>

        <template v-slot:cell(actions)="data">
          <b-row align-h="around" align-v="center">
            <Button v-if="isAuthorized" size="sm" title="Copiar" style="height: 28px" @click="copyQuote(data.item, false)">
              <font-awesome-icon :icon="['far', 'copy']" size="lg" fixed-width />
            </Button>
            <Button size="sm" variant="blue" @click="goToQuote(data.item)">Cotizador</Button>
          </b-row>
        </template>
      </b-table>
      <b-row align-h="center" class="py-3">
        <Button variant="orange" @click="createQuoteModalShow = true">
          <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
          <span class="pl-1">Nueva Cotización</span>
        </Button>
      </b-row>
    </div>

    <!-- Create Quote Modal -->
    <b-modal v-model="createQuoteModalShow" centered hide-footer>
      <template #modal-title>
        <h5>Nueva cotización para {{ project.name }}</h5>
      </template>

      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">

          <ValidationProvider rules="required" name="Unidad de negocio">
            <b-form-group label="Unidad de negocio" label-size="sm">
              <b-radio-group v-model="form.business_unit" :options="businessUnitOptions" stacked size="sm"></b-radio-group>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider v-if="showBusinessUnit2Options" rules="required" name="Subnorma">
            <b-form-group label="Subnorma" label-size="sm">
              <b-radio-group v-model="form.business_unit2" :options="businessUnit2Options" stacked size="sm"></b-radio-group>
            </b-form-group>
          </ValidationProvider>

          <div class="text-center mt-5 mb-2">
            <Button variant="orange" :disabled="invalid" @click="createQuote">Crear</Button>
          </div>
        </ValidationObserver>
      </b-row>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { addDays } from 'date-fns';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Button from '../shared/Button';
import ToastAlert from '../shared/ToastAlert';
import { businessUnits, businessUnits2 } from '../../sharedOptions/quoteBusinessUnits';

export default {
  name: 'ProjectShowQuoteList',
  components: { ValidationObserver, ValidationProvider, Button, ToastAlert },
  props: {
    project: Object,
    quoteData: Object,
    quoteList: Array
  },
  data() {
    return {
      selectedQuote: null,
      selectedQuoteForm: {
        title: ''
      },
      hoveredQuoteId: null,
      hoveringTitle: false,
      editingTitle: false,
      form: {
        business_unit: null,
        business_unit2: null
      },
      createQuoteModalShow: false,
      fields: [
        { key: 'quote_id', label: 'ID', thClass: 'top-borderless' },
        { key: 'title', label: 'Titulo', thClass: 'top-borderless' },
        { key: 'total', label: 'Monto', tdClass: 'text-right', thClass: 'top-borderless text-right' },
        { key: 'status', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'created_at', label: 'Fecha Creación', tdClass: 'text-center', thClass: 'top-borderless text-center' },
        { key: 'actions', label: '', thClass: 'top-borderless' }
      ],
      businessUnitOptions: businessUnits,
      businessUnit2Options: businessUnits2,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  computed: {
    ...mapGetters(['isSupervisor']),
    currentUser() { return this.$store.state.currentUser },
    isAuthorized() { return this.isSupervisor || this.currentUser.id === this.project.user_id ? true : false },
    quotes() {
      let array = this.quoteList.map(id => this.quoteData[id]);
      return array.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));
    },
    showBusinessUnit2Options () {
      if (this.form.business_unit) {
        const subnorms= ['CA','PA','PK','DI','HZ','VV','PVT','CM','TG','CSC']
        return subnorms.includes(this.form.business_unit)
      }
      return false
    }
  },
  methods: {
    createQuote() {
      if (this.processing) return;
      this.processing = true;
      this.$http.post('/api/production/quotes', {
          quote: {
            user_id: this.$store.state.currentUser.id,
            business_unit: this.form.business_unit,
            business_unit2: this.form.business_unit2,
            client_id: this.project.client_id,
            project_id: this.project.id,
            valid_until: addDays(new Date, 15),
            usd_crc_rate: this.$store.state.settings.usd_crc_rate
          }
        })
        .then(response => {
          this.$router.push({ name: 'QuoteShow', params: { id: response.data.id, created: true }});
        })
        .catch(error => {
          this.alertVariant = 'danger';
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0];
          } else {
            this.alertMessage = 'No funcionó';
          }
          this.alertShow = true;
        })
        .finally(() => this.processing = false);
    },
    hoverQuote(quoteId, quoteProperty) {
      this.hoveredQuoteId = quoteId;
      this[quoteProperty] = true;
    },
    unhoverQuote() {
      this.hoveredQuoteId = null;
      this.hoveringTitle = false;
    },
    editQuote(quoteId, quoteProperty) {
      this.selectedQuote = this.quoteData[quoteId];
      this.selectedQuoteForm.title = this.selectedQuote.title;
      this[quoteProperty] = true;
    },
    updateQuote(type) {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/quotes/${this.selectedQuote.id}`, {
          quote: this.selectedQuoteForm
        })
        .then(response => {
          console.log(response.data)
          this.$emit('quote-updated', response.data);
          this.editingTitle = false;
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
          this.alertShow = true;
         })
        .finally(() => {
          this.processing = false;
          // this.editAccessModalShow = false;
        });
    },
    copyQuote(quote, isMobile) {
      if (window.confirm(`¿Está seguro de que quiere copiar ${quote.quote_id}?`)) {
        if (this.processing) return;
        this.processing = true;
        this.$http.post(`/api/production/quotes/${quote.id}/copy`, {
            quote: { client_id: quote.client_id }
          })
          .then(response => {
            console.log(response)
            this.$emit('quote-created', response.data);
            if (isMobile) {
              window.scrollTo({
                top: 250,
                left: 0,
                behavior: 'smooth'
              });
            }
            this.alertVariant = 'success';
            this.alertMessage = `Copia ${response.data.quote_id} se ha creado`;

          })
          .catch(error => {
            this.alertVariant = 'danger';
            this.alertMessage = 'No funcionó';
          })
          .finally(() => {
            this.processing = false;
            this.alertShow = true;
          });
      }
    },
    goToQuote(item) {
      this.$router.push(`/app/prod/cotizador/${item.id}`)
    }
  }
}
</script>
