<template>
  <div class="d-inline">
    <Button size="sm" @click="openModal">
      <b-spinner v-if="loading" small type="grow" class="text-muted mr-2"></b-spinner>
      <span :class="{ 'text-muted': loading }">Datos de Almacén</span>
    </Button>

    <b-modal v-model="modalShow" centered hide-footer scrollable @hide="reset">
      <template #modal-title>
        <span v-if="item" class="text-secondary">{{ item.item_code }}: Datos de Almacén</span>
      </template>
      <b-row align-h="center">
        <div v-if="loading" class="text-dark py-5 fs-14">
          <b-spinner small type="grow"></b-spinner>
          <span class="pl-2">Consultando SAP...</span>
        </div>
        <div v-else>
          <b-table-simple v-if="warehouses.length > 0" bordered small>
            <b-thead head-variant="dark" class="fs-10">
              <b-tr>
                <b-th class="text-center">
                  Código
                </b-th>
                <b-th>
                  Nombre del almacén
                </b-th>
                <b-th class="text-center" style="min-width: 70px; max-width: 70px">
                  En stock
                </b-th>
                <b-th v-if="!isDistribution && !isInstallation" class="text-center" style="min-width: 70px; max-width: 70px; word-wrap: break-word; white-space: normal">
                  Comprometido
                </b-th>
                <b-th v-if="!isDistribution && !isInstallation" class="text-center" style="min-width: 70px; max-width: 70px">
                  Pedido
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="warehouse in filteredWarehouses" :key="warehouse.code" class="fs-13">
                <b-td class="text-center">{{ warehouse.code}}</b-td>
                <b-td>{{ warehouse.name }}</b-td>
                <b-td class="text-center">
                  <div v-if="warehouse.in_stock > 0">
                    <span v-if="(isDistribution || isInstallation) && warehouse.in_stock > 30">30</span>
                    <span v-else>{{ warehouse.in_stock }}</span>
                  </div>
                </b-td>
                <b-td v-if="!isDistribution && !isInstallation" class="text-center">
                  <span v-if="warehouse.committed > 0">{{ warehouse.committed }}</span>
                </b-td>
                <b-td v-if="!isDistribution && !isInstallation" class="text-center">
                  <span v-if="warehouse.ordered > 0">{{ warehouse.ordered }}</span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-else-if="pendingResult" class="text-center pb-5">
            <p class="text-danger fs-14">
              La operación tardó más de lo esperado
            </p>
            <Button class="mt-2" @click="ping">
              Intentarlo de nuevo
            </Button>
          </div>
          <!-- Dev only timestamp -->
          <small v-if="isDev && timestamp" class="text-info float-right pr-2">
            Última actualización {{ timestamp | formatDateTime }}
          </small>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '../shared/Button'

export default {
  name: 'WarehouseDataModal',
  components: { Button },
  props: {
    item: {
      type: Object
    },
    isDistribution: {
      type: Boolean,
      default: false
    },
    isInstallation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      warehouses: [],
      modalShow: false,
      timestamp: null,
      loading: false,
      processing: false,
      retryPingCount: 0,
      pendingResult: false
    }
  },
  computed: {
    ...mapGetters (['isDev']),
    filteredWarehouses () {
      if (this.isDistribution) {
        return this.warehouses.filter(w => ['01', '08', '09', '10', '15', '31'].includes(w['code']))
      } else if (this.isInstallation) {
        return this.warehouses.filter(w => ['01', '31'].includes(w['code']))
      } else {
        return this.warehouses
      }
    }
  },
  methods: {
    openModal () {
      if (this.loading) { return }
      this.loading = true
      this.modalShow = true
      this.$http.get(`/api/production/products/${this.item.id}/fetch_warehouses`, {
        params: {
          fetch_sap: true
        }
      })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            this.warehouses = res.data.warehouses
            this.timestamp = res.data.timestamp
            if (this.item.stock != res.data.stock) {
              this.$emit('item-stock-updated', res.data.stock)
            }
            this.loading = false
            this.retryPingCount = 0
          } else {
            setTimeout(() => this.ping(), 5000)
          }
        })
        .catch(e => console.log(e))
    },
    ping () {
      if (this.processing) { return }
      this.processing = true
      this.loading = true
      console.log('Pinging warehouses')
      this.$http.get(`/api/production/products/${this.item.id}/fetch_warehouses`, {
        params: {
          fetch_sap: this.retryPingCount == 5 ? true : false
        }
      })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            this.warehouses = res.data.warehouses
            this.timestamp = res.data.timestamp
            if (this.item.stock != res.data.stock) {
              this.$emit('item-stock-updated', res.data.stock)
            }
            this.loading = false
            this.pendingResult = false
          } else {
            this.retryPingCount+=1
            if (this.retryPingCount == 10) {
              this.loading = false
              this.pendingResult = true
              this.retryPingCount = 0
              return
            }
            setTimeout(() => this.ping(), 5000)
          }
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
        })
    },
    reset () {
      this.warehouses = []
      this.timestamp = null
      this.loading = false
      this.processing = false
      this.pendingResult = false
    }
  }
}
</script>
