<template>
  <div id="app-wrapper">
    <AppSidebar v-if="!isDistributor && !isInstaller" />
    <b-container
      fluid
      id="content"
      class="px-0"
      @auth-denied="authAlertShow = true"
    >
      <b-row v-if="authAlertShow" align-h="center" class="mt-2 mx-0">
        <b-col lg="8" sm="10">
          <b-alert :show="authAlertShow" variant="danger">
            Usted no está autorizado a hacer eso
            <button class="close-button" @click="authAlertShow = false">
              <span>&times;</span>
            </button>
          </b-alert>
        </b-col>
      </b-row>
      <!-- ༼ つ ◕_◕ ༽つ Announcements -->
    <!--   <b-row v-if="announcement && visibleToUser" align-h="center" class="mt-2">
        <b-col lg="8" sm="10">
          <b-alert show dismissible variant="info" @dismissed="hideAnnouncement">
            <font-awesome-icon :icon="['far', 'bell']" size="lg" fixed-width />
            <span><b>Anuncio:</b></span>
            {{ announcement}}
          </b-alert>
        </b-col>
      </b-row> -->

      <keep-alive include="EmployeeIndex,ProjectIndex,AppConfig,DistributorQuoteIndex">
        <router-view></router-view>
      </keep-alive>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppSidebar from './layouts/AppSidebar'

export default {
  name: 'App',
  components: { AppSidebar },
  data() {
    return {
      announcement: null,
      authAlertShow: false
    }
  },
  mounted() {
    this.handleBgColor()
  },
  computed: {
    ...mapGetters (['isDistributor', 'isInstaller']),
    currentUser () { return this.$store.state.currentUser },
    visibleToUser () {
      if (this.currentUser.comp_type == 'salario' && !this.currentUser.hide_announcement && (this.$route.path == "/" || this.$route.path == "/app/rrhh")) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    '$route'() {
      this.handleBgColor();
    }
  },
  methods: {
    hideAnnouncement () {
      // TODO
    },
    handleBgColor () {
      let path = this.$route.path;
      if (path == "/" || path == "/app/rrhh") {
        document.body.classList.add('bg-gray');
        document.body.classList.remove('bg-white');
      } else {
        document.body.classList.add('bg-white');
        document.body.classList.remove('bg-gray');
      }
      if (path == "/" || path.includes("/app")) {
        document.body.style.paddingBottom = "0";
      }
    }
  }
}
</script>

<style>
#app-wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: stretch;
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1.25rem;
  color: inherit;
  background-color: transparent;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  float: right;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
</style>
