import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["icon", "input"];

  connect() {
    this.validate();
  }

  validateNumber() {
    if (this.inputTarget && this.inputTarget.value && !isNaN(this.inputTarget.value) && this.inputTarget.value > 0) {
      this.iconTarget.classList.add('d-none');
    } else {
      this.iconTarget.classList.remove('d-none');
    }
  }

  validate() {
    if (this.inputTarget && this.inputTarget.value) {
      this.iconTarget.classList.add('d-none');
    } else {
      this.iconTarget.classList.remove('d-none');
    }
  }
}

