<template>
  <div v-if="isAuthorizedBundleUser">
    <!-- Bundle TopShelf -->
    <b-container fluid>
      <b-row class="top-shelf py-4">
        <b-col lg="2" class="pl-5 pb-4 pb-sm-0">
          <BackButton @click="$router.go(-1)" />
        </b-col>
        <b-col lg="6" sm="8" class="px-0">
          <TitleBadge v-if="bundle" type="Bundle">
            <template #title>{{ bundle.item_code }}</template>
          </TitleBadge>
        </b-col>
        <b-col v-if="bundle" lg="2" sm="3" class="text-right">
          <Button size="sm" variant="red" @click="confirmModal = true">
            <p v-if="removingBundle" class="m-0">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Deshaciendo...</span>
            </p>
            <span v-else class="pl-2">
              <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
              <span>Deshacer bulto</span>
            </span>
          </Button>
        </b-col>
      </b-row>
    </b-container>

    <ContentView>
      <b-row align-h="center">
        <b-col lg="8">
          <b-row align-h="between" align-v="end" class="my-3 px-2">
            <div>
              <small v-if="bundle" class="text-info">Artículos en {{ bundle.item_code }}:</small>
            </div>
            <Button v-if="bundle" size="sm" variant="green" @click="addItemModalShow = true">
              <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
              <span class="pl-2">Agregar Artículo</span>
            </Button>
          </b-row>
          <b-row align-h="center">
            <table class="table border-bottom border-right border-left fs-14">
              <thead class="thead-light">
                <tr>
                  <th class="text-center">Cantidad</th>
                  <th>Código</th>
                  <th>Status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody ref="itemContainer">
                <tr v-for="item in bundleItems" :key="item.id" :id="'item-' + item.id" class="sortable-row" style="height: 55px">
                  <td class="text-center movable">{{ item.quantity }}</td>
                  <td class="movable">{{ item.subproduct.item_code }}</td>
                  <td>
                    <span v-if="item.subproduct.is_valid" class="text-center text-success movable">Activo</span>
                    <span v-else class="text-center text-danger movable">Inactivo</span>
                  </td>
                  <td style="width: 50px">
                    <Button size="sm" @click="openEditItemModal(item)">Editar</Button>
                  </td>
                  <td style="width: 50px">
                    <Button size="sm" style="color: #dc3545" @click="deleteItem(item)">Borrar</Button>
                  </td>
                </tr>
                <tr v-if="bundleItems.length == 0">
                  <td v-if="loading" colspan="5" class="text-center text-info">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="pl-2">Cargando datos...</span>
                  </td>
                  <td v-else colspan="5" class="text-center font-italic">
                    No artículos en este bulto todavía
                  </td>
                </tr>
              </tbody>
            </table>
          </b-row>
        </b-col>
      </b-row>
    </ContentView>

    <!-- Add Item Modal -->
    <b-modal v-model="addItemModalShow" size="lg" centered @hide="resetForm">
      <template #modal-title>
        <span v-if="bundle">Agregar artículo a {{ bundle.item_code }}</span>
      </template>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">
          <b-form-group class="mb-5" style="width: 250px">
            <b-input-group size="sm">
              <b-input
                v-model="searchTerm"
                autofocus
                placeholder="Buscar por código"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" size="sm" @click="search">
                  <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <ValidationProvider rules="required">
            <b-form-group label-size="sm">
              <template #label>
                <span>Artículo</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="subproductCode" size="sm" readonly />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required|min_value:1" name="Cantidad" v-slot="{ errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Cantidad</span><span class="asterisk">*</span>
              </template>
              <b-input
                v-model="form.quantity"
                type="number"
                size="sm"
                style="width: 50%"
                :state="errors[0] ? false : null"
                @keyup.enter="createItem"
              />
            </b-form-group>
          </ValidationProvider>
          <div class="text-center mt-5 mb-2">
            <Button variant="green" :disabled="invalid" @click="createItem">
              Guardar
            </Button>
          </div>
        </ValidationObserver>
      </b-row>
      <template #modal-footer><div /></template>
    </b-modal>

    <!-- Search Results Modal -->
    <b-modal v-model="searchResultsModalShow" centered hide-header hide-footer>
      <b-row class="px-3">
        <small class="text-info">Seleccionar un artículo:</small>
      </b-row>
      <b-row align-h="center">
        <b-list-group class="fs-13">
          <b-list-group-item
            v-for="item in searchResults"
            :key="item.id"
            class="clickable"
            @click="selectItem(item)"
          >
            <b-row>
              <b-col cols="4">{{ item.item_code }}</b-col>
              <b-col cols="8">{{ item.description }}</b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-row>
    </b-modal>

    <!-- Edit Item Modal -->
    <b-modal v-model="editItemModalShow" size="lg" centered @hide="resetForm">
      <template #modal-title>
        <span v-if="selectedItem">Editar artículo {{ selectedItem.subproduct.item_code }}</span>
      </template>
      <b-row align-h="center" class="mt-4">
        <ValidationObserver v-slot="{ invalid }" style="width: 280px">
          <ValidationProvider rules="required">
            <b-form-group label-size="sm">
              <template #label>
                <span>Artículo</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="subproductCode" size="sm" readonly />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required|min_value:1" name="Cantidad" v-slot="{ errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]">
              <template #label>
                <span>Cantidad</span><span class="asterisk">*</span>
              </template>
              <b-input
                v-model="form.quantity"
                type="number"
                size="sm"
                autofocus
                style="width: 50%"
                :state="errors[0] ? false : null"
                @keyup.enter="updateItem"
              />
            </b-form-group>
          </ValidationProvider>
          <div class="text-center mt-5 mb-2">
            <Button variant="green" :disabled="invalid" @click="updateItem">
              Guardar
            </Button>
          </div>
        </ValidationObserver>
      </b-row>
      <template #modal-footer><div /></template>
    </b-modal>

    <!-- ConfirmModal -->
    <ConfirmModal v-if="confirmModal" :processing="removingBundle" :required=false @close="confirmModal = false" @onConfirm="removeBundle">
      <template #header>
        <h5>Deshacer bulto {{ bundle.item_code }}</h5>
      </template>
      <template #body>
        <p class="fs-5 font-weight-bold">¿Está seguro que desea deshacer el bulto {{ bundle.item_code }}?</p>
        <p class="text-muted text-center">NOTA: Se eliminaran todos los articulos asociados y se convertirá nuevamente en un producto</p>
      </template>
    </ConfirmModal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalize, schema } from 'normalizr'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Sortable } from '@shopify/draggable'
import BackButton from '../shared/BackButton'
import Button from '../shared/Button'
import ContentView from '../shared/ContentView'
import TitleBadge from '../shared/TitleBadge'
import ConfirmModal from '../shared/ConfirmModal'
import ToastAlert from '../shared/ToastAlert';

export default {
  name: 'BundleShow',
  components: { ValidationObserver, ValidationProvider, BackButton, Button, ContentView, TitleBadge, ConfirmModal, ToastAlert },
  data () {
    return {
      id: Number(this.$route.params.id),
      bundle: null,
      bundleItemData: {},
      bundleItemList: [],
      searchResults: [],
      addItemModalShow: false,
      searchResultsModalShow: false,
      editItemModalShow: false,
      selectedItem: null,
      searchTerm: '',
      form: {
        quantity: 1,
        subproduct_id: null
      },
      subproductCode: '',
      loading: false,
      processing: false,
      confirmModal: false,
      removingBundle: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isSalesSupervisor', 'isProductLeader']),
    bundleItems () { return this.bundleItemList.map(id => this.bundleItemData[id]) },
    isAuthorizedBundleUser() {
      if (this.isAdmin || this.isSalesSupervisor || this.isProductLeader) { return true}
      return false
    }
  },
  mounted () {
    this.loading = true
    this.fetchBundle()

    const sortable = new Sortable(this.$refs.itemContainer, {
      draggable: '.sortable-row',
      delay: 300,
      scrollable: { speed: 0 },
      mirror: { constrainDimensions: true }
    })

    sortable.on('sortable:stop', () => {
      let items = sortable.getDraggableElementsForContainer(this.$refs.itemContainer)
      let data = []
      for (var i = 0; i < items.length; i++) {
        console.log(items[i].id)
        data.push({"bundle_item_id": items[i].id.split('-')[1]})
      }
      this.$http.patch(`/api/production/bundles/${this.id}/bundle_items/sort`, {
        bundle_items: data
      })
    })
  },
  methods: {
    fetchBundle () {
      this.$http.get(`/api/production/bundles/${this.id}`)
        .then((res) => {
          console.log(res)
          this.bundle = res.data.bundle;
          const bundleItemData = normalize(
            { bundleItems: res.data.bundle_items },
            { bundleItems: [ new schema.Entity('bundleItems') ] }
          );
          if (bundleItemData.entities.hasOwnProperty('bundleItems')) {
            this.bundleItemData = bundleItemData.entities.bundleItems;
          }
          this.bundleItemList = bundleItemData.result.bundleItems;
        })
        .catch(e => console.log(e))
        .finally(this.loading = false)
    },
    removeBundle () {
      if (this.removingBundle) { return }
      this.removingBundle = true
      this.$http.delete(`/api/production/bundles/${this.id}`)
        .then((res) => {
          this.alertVariant = 'success'
          this.alertMessage = 'Funcionó correctamente, redirigiendo...'
          setTimeout(() => {
            this.$router.push(`/app/prod/inventario/bultos`)
          }, 1500);
        })
        .catch(e => {
          this.alertVariant = 'danger'
          this.alertMessage = 'Error al deshacer el bulto'
        })
        .finally( () => {
          this.removingBundle = false
          this.alertShow = true
        })
    },
    createItem () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post(`/api/production/bundles/${this.id}/bundle_items`, {
        bundle_item: this.form
      })
        .then((res) => {
          console.log(res)
          this.$set(this.bundleItemData, res.data.id, res.data)
          this.bundleItemList.push(res.data.id)
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
          this.addItemModalShow = false
        })

    },
    openEditItemModal (item) {
      this.selectedItem = item
      this.form.quantity = item.quantity
      this.form.subproduct_id = item.subproduct_id
      this.subproductCode = item.subproduct.item_code
      this.editItemModalShow = true
    },
    updateItem () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/bundles/${this.id}/bundle_items/${this.selectedItem.id}`, {
        bundle_item: this.form
      })
        .then((res) => {
          console.log(res)
          this.$set(this.bundleItemData, res.data.id, res.data)
          this.editItemModalShow = false
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    deleteItem (item) {
      if (this.processing) { return }
      this.processing = true
      this.$http.delete(`/api/production/bundles/${this.id}/bundle_items/${item.id}`)
        .then((res) => {
          console.log(res)
          this.$delete(this.bundleItemData, res.data.id)
          this.bundleItemList = this.bundleItemList.filter(id => id !== res.data.id)
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    search () {
      if (this.searchTerm) {
        if (this.processing) { return }
        this.processing = true
        this.$http.get('/api/production/products/search', {
          params: {
            q: this.searchTerm
          }
        })
          .then((res) => {
            console.log(res)
            this.searchResults = res.data.search_results
            this.searchResultsModalShow = true
          })
          .catch(e => console.log(e))
          .finally(this.processing = false)
      }
    },
    selectItem (item) {
      this.form.subproduct_id = item.id
      this.subproductCode = item.item_code
      this.searchResults = []
      this.searchResultsModalShow = false
    },
    resetForm () {
      this.form.quantity = 1
      this.form.subproduct_id = null
      this.searchTerm = ''
      this.subproductCode = ''
      this.searchResults = []
      this.selectedItem = null
    }
  }
}
</script>
