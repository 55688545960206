<template>
  <div>
    <Button size="sm" class="text-success" @click="addTextLine">
      <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
      <span class="pl-1">Línea texto</span>
    </Button>
  </div>
</template>

<script>
import Button from '../shared/Button'

export default {
  name: 'QuoteItemAddLineText',
  components: { Button },
  props: {
    quoteId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    addTextLine () {
      if (this.processing) return
      this.processing = true;
      this.$http.get(`/api/production/quotes/${this.quoteId}/quote_items/add_text_line`)
        .then(response => {
          if (response.data.quote_item) {
            this.$emit('quote-item-added', response.data.quote_item)
          } else {
            this.makeToast('danger')
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast('Error al agregar línea de texto', {
        title: 'Error: Línea texto',
        variant: variant,
        toaster: 'b-toaster-bottom-right',
        solid: true
      })
    }
  }
}
</script>
