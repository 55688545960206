<template>
  <div>
    <InstallerNavTabs v-if="isInstaller" />
    <DistributorNavTabs v-else />
    <ContentView>
      <!-- Substitute subheader -->
      <b-row class="pl-2">
        <b-col lg="4" class="mb-2 mb-lg-0">
          <b-row style="margin-bottom: 2.5em;">
            <span class="font-lato fs-20">Mis Cotizaciones</span>
          </b-row>
          <b-row>
            <b-form-group style="width: 280px">
              <b-input-group size="sm">
                <b-input
                  v-model="searchTerm"
                  autofocus
                  placeholder="Buscar por código, titulo, cliente"
                  @keyup.enter="search"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" size="sm" @click="search">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group class="ml-3">
              <b-button size="sm" @click="resetSearch">Reset</b-button>
            </b-form-group>
          </b-row>
        </b-col>
        <b-col lg="8" class="px-0">
          <!-- Status Filter -->
          <div id="status-filter-group" class="p-2 ml-auto">
            <b-row align-h="between" align-v="center" class="clickable px-4">
              <span class="fs-12" style="font-weight: 400">Filtro de Estatus:</span>
            </b-row>
            <hr class="mt-1 mb-2">
            <b-row class="px-4">
              <b-form-group class="m-0 px-1">
                <b-checkbox v-model="statusFilter" value="baja" size="sm" switch>Baja</b-checkbox>
                <b-checkbox v-model="statusFilter" value="media" size="sm" switch>Media</b-checkbox>
                <b-checkbox v-model="statusFilter" value="alta" size="sm" switch>Alta</b-checkbox>
              </b-form-group>
              <b-form-group class="m-0 px-1">
                <b-checkbox v-model="statusFilter" value="vendido" size="sm" switch>Vendido</b-checkbox>
                <b-checkbox v-model="statusFilter" value="desestimada" size="sm" switch>Desestimada</b-checkbox>
              </b-form-group>
              <b-form-group class="m-0 px-1">
                <b-checkbox v-model="statusFilter" value="perdida" size="sm" switch>Perdida</b-checkbox>
                <b-checkbox v-model="statusFilter" value="ignorar" size="sm" switch>Ignorada</b-checkbox>
              </b-form-group>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <!-- Table caption -->
      <b-row align-h="between" align-v="center" class="mb-1 mt-2">
        <b-col>
          <div v-if="count" class="fs-12">
            <span v-if="queryMode" class="text-muted">{{ count.toLocaleString() }} resultados</span>
            <span v-else class="text-info">{{ count.toLocaleString() }} cotizaciones en total</span>
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="quotes"
        head-variant="dark"
        bordered
        hover
        responsive
        show-empty
        no-local-sorting
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="fs-13"
        @row-clicked="onRowClick"
        @sort-changed="sortColumn"
      >

        <!-- Loading spinner / empty search text -->
        <template #empty>
          <div v-if="loading" class="text-center text-info">
            <b-spinner small type="grow"></b-spinner>
            <span class="pl-2">Cargando datos...</span>
          </div>
          <div v-else class="text-center text-danger">
            <span>No resultados</span>
          </div>
        </template>

        <!-- Table data -->
        <template v-slot:cell(distributor_client_name)="data">
          <span v-if="data.value">{{ data.value }}</span>
          <span v-else class="text-warning">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
            <span>Falta Cliente</span>
          </span>
        </template>

        <template v-slot:cell(pricing_mode)="data">
          <span>{{ data.value | titleize }}</span>
        </template>

        <template v-slot:cell(total)="data">
          <span v-if="isInstaller" class="price-display">{{ data.value * data.item.usd_crc_rate | colones }}</span>
          <span v-else  class="price-display">{{ data.value | dollarize }}</span>
        </template>

        <template v-slot:cell(status)="data">
          <b-badge pill :variant="data.value" class="clickable" style="font-size: 90%; width: 100px">
            <span>{{ data.value | titleize }}</span>
          </b-badge>
        </template>

        <template v-slot:cell(created_at)="data">
          {{ data.value | formatDate }}
        </template>
      </b-table>

      <b-row align-h="center" class="mt-5">
        <PaginationCountless :page="page" :next="next" :prev="prev">
          <template #prev-label>
            <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
          </template>
          <template #next-label>
            <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
          </template>
        </PaginationCountless>
      </b-row>
    </ContentView>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentView from '../shared/ContentView'
import PaginationCountless from '../shared/PaginationCountless'
import Subheader from '../shared/Subheader'
import DistributorNavTabs from './DistributorNavTabs'
import InstallerNavTabs from './InstallerNavTabs'

export default {
  name: 'DistributorQuoteIndex',
  components: { ContentView, PaginationCountless, Subheader, DistributorNavTabs, InstallerNavTabs },
  data () {
    return {
      quotes: [],
      page: 1,
      next: null,
      prev: null,
      count: null,
      searchTerm: '',
      queryMode: false,
      sortBy: 'created_at',
      sortDesc: true,
      fields: [
        { key: 'quote_id', label: 'Código', tdClass: 'clickable', thClass: 'font-lato-th', thStyle: { width: '120px' } },
        { key: 'distributor_client_name', label: 'Cliente', tdClass: 'clickable', thClass: 'font-lato-th' },
        { key: 'title', label: 'Titulo', tdClass: 'clickable', thClass: 'font-lato-th' },
        { key: 'pricing_mode', label: 'Modo de precio', tdClass: 'clickable text-center', thClass: 'font-lato-th text-center' },
        { key: 'total', label: 'Monto', sortable: true, tdClass: 'clickable text-right', thClass: 'text-right font-lato-th' },
        { key: 'status', label: 'Estatus', tdClass: 'clickable text-center', thClass: 'text-center font-lato-th' },
        { key: 'created_at', label: 'Fecha Creación', sortable: true, tdClass: 'clickable text-center', thClass: 'text-center font-lato-th', thStyle: { minWidth: '95px' } }
      ],
      loading: false,
      processing: false,
      noScroll: false
    }
  },
  computed: {
    ...mapGetters (['isInstaller']),
    // crcRate () { return this.$store.state.settings.usd_crc_rate },
    statusFilter: {
      get () {
        return this.$store.state.projectStatusFilter
      },
      set (val) {
        this.$store.commit('setProjectStatusFilter', val)
      }
    },
  },
  watch: {
    '$route.query' () {
      this.fetchPaginatedQuotes()
    },
    statusFilter () {
      this.noScroll = true
      this.fetchPaginatedQuotes()
    }
  },
  mounted () {
    this.noScroll = true
    this.fetchPaginatedQuotes()
  },
  methods: {
    fetchPaginatedQuotes () {
      this.loading = true
      this.$http.get('/api/contractors/quotes', {
          params: {
            page: this.$route.query.pagina,
            q: this.searchTerm,
            status_filter: this.statusFilter,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc
          }
        })
        .then((res) => {
          console.log(res)
          this.quotes = res.data.quotes
          this.page = res.data.pagy.page
          this.next = res.data.pagy.next
          this.prev = res.data.pagy.prev
          this.count = res.data.pagy.count
          if (!this.noScroll) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
          this.noScroll = false
        });
    },
    onRowClick (item) {
      if (this.isInstaller) {
        this.$router.push(`/app/inst/cotizador/${item.id}`)
      } else {
        this.$router.push(`/app/dist/cotizador/${item.id}`)
      }
    },
    search () {
      if (this.processing) { return }
      this.processing = true
      let query = Object.assign({}, this.$route.query)
      delete query.pagina
      this.$router.replace({ query })
      this.sortBy = 'created_at'
      this.sortDesc = true
      this.$http.get('/api/contractors/quotes', {
          params: {
            q: this.searchTerm.trim(),
            status_filter: this.statusFilter,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc
          }
        })
        .then((res) => {
          this.queryMode = true
          console.log(res)
          this.quotes = res.data.quotes
          this.page = res.data.pagy.page
          this.next = res.data.pagy.next
          this.prev = res.data.pagy.prev
          this.count = res.data.pagy.count
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    resetSearch () {
      this.queryMode = false
      this.searchTerm = ''
      this.sortBy = 'created_at'
      this.sortDesc = true
      if (this.isInstaller) {
        this.$router.push('/app/inst/cotizaciones')
      } else {
        this.$router.push('/app/dist/cotizaciones')
      }
      this.fetchPaginatedQuotes()
    },
    sortColumn (ctx) {
      console.log(ctx)
      if (ctx.sortBy) {
        this.noScroll = true
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc
        this.fetchPaginatedQuotes()
      }
    }
  }
}
</script>

<style scoped>
#status-filter-group {
  border: 1px solid #dee2e6;
  border-radius: 3px;
  width: 350px;
}
@media (max-width: 575.98px) {
  #status-filter-group {
    width: 330px;
  }
}
.form-control-sm {
  font-size: 12px !important;
  line-height: 2 !important;
}
</style>
