import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "checkbox" ]

  connect () {
    let inputs
    this.checkboxTargets.forEach(el => {
      if (!el.checked) {
        if (el.value == 'Cargador') {
          inputs = document.getElementsByClassName('se-input-cs')
        } else if (el.value == 'Izquierda') {
          inputs = document.getElementsByClassName('se-input-cli')
        } else if (el.value == 'Derecha') {
          inputs = document.getElementsByClassName('se-input-cld')
        }
        Array.from(inputs).forEach(el => {
          el.classList.add('d-none')
        })
      }
    })
  }

  fetchDiagram () {
    let ajustes = []
    this.checkboxTargets.forEach(el => {
      if (el.checked) {
        ajustes.push(el.value)
      }
    })
    Rails.ajax({
      url: this.data.get('url'),
      type: 'GET',
      data: `ajustes=${ajustes}`
    })
  }

  filterInput (event) {
    let inputs
    if (event.target.value == 'Cargador') {
      inputs = document.getElementsByClassName('se-input-cs')
    } else if (event.target.value == 'Izquierda') {
      inputs = document.getElementsByClassName('se-input-cli')
    } else if (event.target.value == 'Derecha') {
      inputs = document.getElementsByClassName('se-input-cld')
    }
    console.log('filter input')
    console.log(inputs)
    if (event.target.checked) {
      Array.from(inputs).forEach(el => {
        el.classList.remove('d-none')
      })
    } else {
      Array.from(inputs).forEach(el => {
        el.classList.add('d-none')
      })
    }
  }
}
