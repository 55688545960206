import Vue from 'vue'
import Vuex from 'vuex'
// Plugins
import createPersistedState from 'vuex-persistedstate'
// Modules
import configurator from './modules/configurator'
import distributor from './modules/distributor'
import employees from './modules/employees'
import vacationTickets from './modules/vacationTickets'
import purchaseDeliveries from './modules/purchaseDeliveries'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: {},
    userRoles: [],
    //  Global Settings
    settings: {},
      // human_resources_admins       [id]  // deprecate
      // ancestry_root                 id
      // dev                           id
      // super_admins                 [id]
      // vacations_inbox             email: string // deprecate
      // usd_crc_rate                float
      // distribution_admins          [id]
      // enable_service_layer        boolean
      // developers                   [id]

    // UI Settings
    previousRoute: null,
    projectStatusFilter: ['baja', 'media', 'alta', 'licitación', 'vendido'],
    transactionStatusFilter: ['por_aprobar', 'pendiente', 'en_proceso', 'aprobada', 'pendiente_af', 'rechazada'], // Use in CardTransactionIndex.vue
    displayCRC: false
  },
  getters: {
    // Auth / role getters
    isManager (state) {
      return state.currentUser.is_manager
    },
    isAdmin (state) {
      return state.currentUser.role === 'administrador'
    },
    isSupervisor (state) {
      let role = state.currentUser.role
      if (role === 'administrador' || role === 'supervisor_ventas' || role === ' supervisor_división' || role === 'gerente') {
        return true
      } else {
        return false
      }
    },
    isSalesSupervisor (state) {
      return state.currentUser.role === 'supervisor_ventas'
    },
    isSuperAdmin (state) {
      return state.settings.super_admins.includes(state.currentUser.id)
    },
    isDistributionAdmin (state) {
      return state.settings.distribution_admins.includes(state.currentUser.id)
    },
    isCoordinator (state) {
      const matches = state.userRoles.filter(s => s.includes('Coordinador'))
      return matches.length > 0
    },
    isHrAdmin (state) {
      return state.settings.human_resources_admins.includes(state.currentUser.id)
    },
    isRoot (state) {
      return id => state.settings.ancestry_root === id
    },
    isDev (state) {
      return state.settings.developers.includes(state.currentUser.id)
    },
    isAdminCourses(state){ // TODO: detele after testing
      return [3244,152, 28].includes(state.currentUser.id)
    },
    isClientAdmin(state){
      const allowedUsers = {
        69: 'Carlos Giral'
      }
      const allowedUserIds = Object.keys(allowedUsers).map(Number)
      return allowedUserIds.includes(state.currentUser.id)
    },
    isDistributor (state) {
      return state.currentUser.role === 'distribuidor'
    },
    isInstaller (state) {
      return state.currentUser.role === 'instalador'
    },
    isTransactionAssetAdmin(state) {
      return [222,170,3199, 2912, 180].includes(state.currentUser.id)
    },
    isTransactionAdmin(state) {
      return [170,3199].includes(state.currentUser.id)
    },
    isProductLeader (state) {
      return state.currentUser.role === 'lider_producto'
    },
    isSocAdmin (state) {
      return [123].includes(state.currentUser.id)
    },
    isAtkAdvisor (state) {
      const matches = state.userRoles.filter(role => role.includes('ATK - asesores'))
      return matches.length > 0
    },
    canViewAccess (state) {
      return accessModel => {
        if (state.currentUser.role == 'supervisor_división' && !state.currentUser.permissions.includes(accessModel)) {
          return false
        } else {
          return true
        }
      }
    },
    usdCrRate(state){
      return state.settings.usd_crc_rate
    },
    eurRate(state){
      return state.settings.eur_crc_rate
    },
    canRate(state){
      return state.settings.can_crc_rate
    }
  },
  actions: {

  },
  mutations: {
    setDisplayCRC (state, boolean) {
      state.displayCRC = boolean
    },
    setProjectStatusFilter (state, array) {
      Vue.set(state, 'projectStatusFilter', array)
    },
    setTransactionStatusFilter (state, array) {
      Vue.set(state, 'transactionStatusFilter', array)
    },
    setCrcRates (state, rates) {
      state.settings.usd_crc_rate = rates.usd_crc_rate
      state.settings.eur_crc_rate = rates.eur_crc_rate
      state.settings.can_crc_rate = rates.can_crc_rate
    },
    toggleServiceLayer (state) {
      state.settings.enable_service_layer = !state.settings.enable_service_layer
    }
  },
  modules: {
    configurator,
    distributor,
    employees,
    vacationTickets,
    purchaseDeliveries
  },
  plugins: [
    createPersistedState()
  ]
})
