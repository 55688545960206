<template>
  <!-- NOTE: remove this component when all types of submitals are migrated -->
  <b-container>
    <b-row align-h="between">
      <div class='section-title'>Cotización:</div>
      <div v-if="acceso.motor_id">
        <div id="generate-button-info" class="clickable text-info d-inline mr-3">
          <i class="far fa-question-circle"></i>
        </div>
        <b-button size="sm" variant="outline-success" @click="generateQuote">
          Generar
        </b-button>
      </div>
    </b-row>
    <b-row v-if="quotes.length > 0" class="overflow-auto" style="height: 120px">
      <b-list-group class="w-100 fs-13">
        <b-list-group-item v-for="quote in quotes" :key="quote.id" :href="`/app/prod/cotizador/${quote.id}`" class="py-2">
          <span class="font-weight-bold">{{ quote.quote_id }}:</span><span class="pl-2">{{ quote.title }}</span>
        </b-list-group-item>
      </b-list-group>
    </b-row>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Popovers -->
    <b-popover target="generate-button-info" placement="bottom" triggers="hover click">
      <span class="text-info">Genera una nueva cotizacíon basada en el motor seleccionado</span>
    </b-popover>
  </b-container>
</template>

<script>
import { addDays } from 'date-fns';
import ToastAlert from '../shared/ToastAlert';

export default {
  name: 'QuoteGenerator',
  components: { ToastAlert },
  props: {
    acceso: Object,
    accesoType: String,
    accesoTypePlural: String,
    currentUser: Object,
    rate: String
  },
  data() {
    return {
      quotes: [],
      loading: false,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: ''
    }
  },
  mounted() {
    this.$http.get(`/api/production/${this.accesoTypePlural}/${this.acceso.id}/quotes`)
      .then(response => {
        console.log(response)
        this.quotes = response.data;
      })
  },
  methods: {
    generateQuote() {
      if (this.processing) return;
      this.processing = true;
      let businessUnit = ''
      if (this.accesoType == 'Corredizo' || this.accesoType == 'Abatible') {
        businessUnit = 'HZ';
      } else {
        businessUnit = 'DI';
      }
      this.$http.post('/api/production/quotes', {
          quote: {
            user_id: this.acceso.user_id,
            client_id: this.acceso.client_id,
            project_id: this.acceso.project_id,
            quotable_acceso_type: this.accesoType,
            quotable_acceso_id: this.acceso.id,
            business_unit: businessUnit,
            valid_until: addDays(new Date, 15),
            usd_crc_rate: this.rate
          },
          motor_id: this.acceso.motor_id
        })
        .then(response => {
          // window.location.href = `/app/prod/cotizador/${response.data.id}`;
          this.quotes.unshift(response.data);
          this.alertVariant = 'success';
          this.alertMessage = `Cotización ${response.data.quote_id} se ha creado con éxito`;
          this.$http.get(`/api/production/quotes/${response.data.id}/generate`)
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
        })
        .finally(() => {
          this.alertShow = true;
          this.processing = false;
        });
    }
  }
}
</script>
