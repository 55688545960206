<template>
  <b-row align-h="between" class="custom-tabs mx-0">
    <b-link class="ml-3" @click="$router.go(-1)">
      <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
      <span class="pl-2">Atrás</span>
    </b-link>
    <ul>
      <li>
       <router-link to="/app/inst/cotizaciones" class="dash-nav-link">Mis Cotizaciones</router-link>
      </li>
    </ul>
  </b-row>
</template>

<script>
export default {
  name: 'InstallerNavTabs'
}
</script>
