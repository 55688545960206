<template>
  <b-container fluid v-if="isPurchaseDeliveryAdmin || isDev">
    <b-row align-h="center">
      <b-col class="p-0">
        <h2 class="pb-2">VALIDACIÓN</h2>
        <PurchaseDeliveryContent
          v-if="purchaseDeliverySelected"
          :purchase-delivery="purchaseDeliverySelected"
          parent-component="validation"
          :iconHeader="['fas', 'clipboard-check']"
          :syncingDate="syncingDocDate"
          @update-invoice-number="updatedInvoiceNumber"
          @update-accounting-date="updateDocDate"
        >
        <!-- Transfer buttons to table component-->
          <template v-slot:section-content>
            <b-row v-if="purchaseDeliverySelected">
              <b-col>
                <h4>SAP</h4>
                <b-table-simple small borderless class="pl-2">
                  <b-tbody>
                    <b-tr>
                      <b-td style="width: 200px">Sincronización:</b-td>
                      <b-td>
                        <div>
                          <div v-if="sapStatus == 'full'">
                            <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
                            <span class="text-muted pl-1">Full</span>
                          </div>
                          <div v-else-if="sapStatus == 'syncing'">
                            <b-spinner variant="secondary" small />
                            <span class="text-muted pl-1">Sincronizando...</span>
                          </div>
                          <div v-else-if="sapStatus == 'pending'">
                            <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
                            <span class="text-muted pl-1">Pendiente</span>
                          </div>
                          <div v-else-if="sapStatus == 'partial'">
                            <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-warning" />
                            <span class="text-muted pl-1">Parcial</span>
                          </div>
                          <div v-else-if="sapStatus == 'ignored'">
                            <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-secondary" />
                            <span class="text-muted pl-1">Apagado</span>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Número Documento:</b-td>
                      <b-td>
                        <span v-if="purchaseDeliverySelected.sap_doc_no">{{ purchaseDeliverySelected.sap_doc_no }}</span>
                        <span v-else>-</span>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="purchaseDeliverySelected && purchaseDeliverySelected.sap_errors">
                      <b-td class="align-top">Avisos:</b-td>
                      <b-td class="text-danger">
                        <p v-if="purchaseDeliverySelected.sap_errors">
                          {{ purchaseDeliverySelected.sap_errors }}
                        </p>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
          <template v-slot:table-caption>
          </template>
          <template v-slot:table-footer>
            <b-row align-h="between">
              <b-col md="7" xl="5">
                <div>
                  <p class="d-inline-block font-weight-light m-0">Comentarios de registro:</p>
                  <p v-if="purchaseDeliverySelected.register_notes" class="fs-14 pl-2 mb-1">{{ purchaseDeliverySelected.register_notes }}</p>
                  <p v-else class="text-muted fs-14 pl-2 mb-1">Sin notas de registro</p>
                </div>
                <p class="d-inline-block font-weight-light m-0">Comentarios de validación:</p>
                <ValidationProvider rules="max:200" name="comentarios" v-slot="{ errors }">
                  <b-textarea
                    :value="purchaseDeliverySelected.notes"
                    v-model="form.notes"
                    rows="4"
                    size="sm"
                    trim
                    placeholder="Ingrese las notas de validación"
                    ref="validation_notes"
                    :disabled="!isEditable"
                    @blur="errors[0] || form.notes == purchaseDeliverySelected.notes ? null : updateNotes()"
                    @keydown.enter="errors[0] || form.notes == purchaseDeliverySelected.notes ? null : updateNotes()"
                  />
                  <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col md="5" xl="5" align-self="end" class="text-right p-0">
                <b-table-simple borderless small>
                  <b-tbody>
                    <!-- subtotal -->
                    <b-tr>
                      <b-td></b-td>
                      <b-td class="text-right lato-label">SUBTOTAL</b-td>
                      <b-td class="text-right price-display">
                        <div v-if="ready">
                          <span v-if="currency === 'COL'">{{ subtotal | colones2 }}</span>
                          <span v-else-if="currency === 'USD'">{{ subtotal | dollarize }}</span>
                          <span v-else> N/A</span>
                          <!-- <span v-else>{{ subtotal }} <sup><small>{{ currency }}</small></sup></span> -->
                        </div>
                        <div v-else>
                          <span v-if="currency === 'COL'">₡0</span>
                          <span v-else-if="currency === 'USD'">$0</span>
                          <span v-else>0 <sup><small>{{ currency }}</small></sup></span>
                        </div>
                      </b-td>
                      <b-td></b-td>
                    </b-tr>
                    <!-- discount -->
                    <b-tr>
                      <b-td class="text-right">
                        <div v-if="ready">
                          <div v-if="discountEdit">
                            <ValidationProvider :rules="{ required: true, min_value: 0 }" slim v-slot="{ errors }">
                              <b-input-group size="sm" append="%" class="ml-auto" style="width: 90px">
                                <b-input
                                  v-model="form.discount"
                                  type="number"
                                  ref="discountInput"
                                  class="no-spin"
                                  :class="{ 'red-input-border': errors[0] }"
                                  @click="$nextTick(() => $refs.discountInput.select())"
                                  @keyup.enter="errors[0] ? null : updateDiscount()"
                                  @blur="errors[0] ? null : updateDiscount()"
                                />
                              </b-input-group>
                            </ValidationProvider>
                          </div>
                          <span v-else :class="{'click-to-edit': isEditable}" @click="focusDiscount">{{ parseFloat(purchaseDeliverySelected.discount.toFixed(2)) }} %</span>
                        </div>
                      </b-td>
                      <b-td class="text-right lato-label second-col">DESCUENTO</b-td>
                      <b-td class="text-right price-display third-col">
                        <div v-if="ready">
                          <span v-if="currency === 'COL'">{{ discount | colones2 }}</span>
                          <span v-else-if="currency === 'USD'">{{ discount | dollarize }}</span>
                          <span v-else>N/A</span>
                          <!-- <span v-else>{{ discount }} <sup><small>{{ currency }}</small></sup></span> -->
                        </div>
                      </b-td>
                      <b-td></b-td>
                    </b-tr>
                    <!-- tax -->
                    <b-tr>
                      <b-td></b-td>
                      <b-td class="text-right lato-label">IMPUESTO</b-td>
                      <b-td class="text-right price-display">
                        <div v-if="ready">
                          <span v-if="currency === 'COL'">{{ tax | colones2 }}</span>
                          <span v-else-if="currency === 'USD'">{{ tax | dollarize }}</span>
                          <span v-else>N/A</span>
                          <!-- <span v-else>{{ tax }} <sup><small>{{ currency }}</small></sup></span> -->
                        </div>
                      </b-td>
                      <b-td>
                      </b-td>
                    </b-tr>
                    <!-- Other amount -->
                    <b-tr>
                      <b-td></b-td>
                      <b-td class="text-right lato-label">GASTOS</b-td>
                      <b-td class="text-right price-display" style="width:11.25rem">
                        <div v-if="ready">
                          <div v-if="additionalExpenseEdit">
                            <ValidationProvider :rules="{ required: true, min_value: 0 }" slim v-slot="{ errors }">
                              <b-input-group size="sm">
                                <template #prepend>
                                  <b-input-group-text>
                                    <span v-if="currency === 'COL'">₡</span>
                                    <span v-else-if="currency === 'USD'">$</span>
                                    <!-- <span v-else><small>{{ currency }}</small></span> -->
                                  </b-input-group-text>
                                </template>
                                <b-form-input
                                  v-model="form.additional_expense"
                                  type="number"
                                  ref="additionalExpenseInput"
                                  class="no-spin"
                                  :class="{ 'red-input-border': errors[0] }"
                                  @click="$nextTick(() => $refs.additionalExpenseInput.select())"
                                  @keyup.enter="errors[0] ? null : updateAnotherAmount()"
                                  @blur="errors[0] ? null : updateAnotherAmount()"
                                >
                              </b-form-input>
                              </b-input-group>
                            </ValidationProvider>
                          </div>
                          <span v-else :class="{'click-to-edit': isEditable}" class="p-0" @click="focusAdditionalExpense">
                            <span v-if="currency === 'COL'">{{ purchaseDeliverySelected.additional_expense | colones2 }}</span>
                            <span v-else-if="currency === 'USD'">{{ purchaseDeliverySelected.additional_expense | dollarize }}</span>
                            <span v-else>N/A</span>
                            <!-- <span v-else>{{ purchaseDeliverySelected.additional_expense }} <sup><small>{{ currency }}</small></sup></span> -->
                          </span>
                        </div>
                      </b-td>
                      <b-td>
                      </b-td>
                    </b-tr>
                    <!-- total -->
                    <b-tr class="pt-3 border-top">
                      <b-td colspan="2" class="text-right">
                        <span v-if="currency == 'COL'" class="font-weight-bold">TOTAL COLONES</span>
                        <span v-else-if="currency == 'USD'" class="font-weight-bold">TOTAL DÓLARES</span>
                        <!-- <span v-else class="font-weight-bold">TOTAL {{currency}}</span> -->
                        <span v-else class="font-weight-bold">TOTAL</span>
                      </b-td>
                      <b-td class="text-right">
                        <div v-if="ready">
                          <!-- <div v-if="customTotalEdit">
                            <b-input-group size="sm" prepend="$">
                              <b-input
                                v-model="customTotalForm.total"
                                type="number"
                                ref="customTotal"
                                class="text-center no-spin"
                                :state="customTotalValid"
                                @input="inputTotal"
                                @blur="updateTotal"
                                @keyup.enter="updateTotal"
                              />
                            </b-input-group>
                          </div> -->
                          <div  class="price-display">
                            <span v-if="currency === 'COL'">{{ total | colones2 }}</span>
                            <span v-else-if="currency === 'USD'">{{ total | dollarize }}</span>
                            <!-- <span v-else>{{ total }}</span> -->
                            <span v-else>N/A</span>
                            <!-- <div v-if="displayCRC">
                              <span v-if="totalCRC > 0" class="p-0">{{ totalCRC | colones }}</span>
                            </div>
                            <div v-else>
                              <span v-if="total > 0" :class="{ 'click-to-edit': quote.is_open }" class="p-0" @click="focusTotal">{{ total | dollarize }}</span>
                              <span v-else>{{ total | dollarize }}</span>
                            </div> -->
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col md="12" xl="2" align-self="end" class="text-right">
                <Button size="lg" v-if="!purchaseDeliverySelected.sap_doc_no" :disabled="!canCreateEmOnSap || !isEditable || syncing" @click="confirmationModal = true" variant="green">Crear EM</Button>
              </b-col>
            </b-row>
          </template>
        </PurchaseDeliveryContent>
        <b-container fluid v-else class="empty-page">
          <div>
            <font-awesome-icon :icon="['far', 'info-circle']" class="text-dark modal-icon" />
          </div>
          <div class="text-center">
            <p class="fs-20">No hay una EM seleccionada</p>
            <router-link :to="{ name: 'PurchaseDeliverySearch' }">
              Buscar orden
              <font-awesome-icon :icon="['fas', 'search']"/>
            </router-link>
          </div>
        </b-container>
      </b-col>
    </b-row>
    <!-- CONFIRMATION: Inactive-->
    <b-modal
      v-model="confirmationModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <b-container fluid v-if="purchaseDeliverySelected">
        <b-row align-h="center" >
          <font-awesome-icon :icon="['far', 'question-circle']"  class="text-info modal-icon" />
        </b-row>
        <b-row align-h="center">
          <span class="fs-20 text-center">¿Desea crear la {{ purchaseDeliverySelected.purchase_internal_id }} en SAP?</span>
        </b-row>
        <b-row align-h="center" class="pt-2 pl-2 pr-2 text-secondary">
          <small class="text-center">NOTA: La EM se creará en SAP y ya no se podrá editar en XYZ</small>
        </b-row>
        <b-row align-h="around" class="my-4">
          <Button variant="red" @click="confirmationModal = false">No</Button>
          <Button variant="green" @click="createEmOnSap()">Sí</Button>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import PurchaseDeliveryContent from './PurchaseDeliveryContent'
import { ValidationProvider } from 'vee-validate'
import Button from '../../shared/Button'
import ToastAlert from '../../shared/ToastAlert'

export default {
  name: 'PurchaseDeliveryValidation',
  components: { PurchaseDeliveryContent, Button, ValidationProvider, ToastAlert },
  data () {
    return {
      processing: false,
      pinging: false,
      syncing: false,
      retryPingCount: 0,
      form: {
        notes: '',
        additional_expense: 0,
        discount: 0
      },
      confirmationModal: false,
      discountEdit: false,
      additionalExpenseEdit: false,
      taxCodes: {
        IVC : 0.13,
        IVG : 0.13,
        IVM : 0.10,
        EXEC : 0.0,
        IMPE : 0.0,
        IMPG : 0.0
      },
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      syncingDocDate: false
    }
  },
  mounted() {
    if (this.purchaseDeliverySelected) {
      this.form.notes = this.purchaseDeliverySelected.notes
      const { eur_crc_rate, can_crc_rate, doc_date } = this.purchaseDeliverySelected
      if ((!eur_crc_rate || !can_crc_rate) && doc_date) { this.updateDocDate(doc_date, false)}
    }
    this.fetchSapState()
  },
  computed: {
    ...mapGetters(['purchaseDeliverySelected', 'canCreateEmOnSap', 'isPurchaseDeliveryAdmin', 'isDev']),
    isEditable () {
      if (this.purchaseDeliverySelected) {
        if (this.purchaseDeliverySelected.is_executed && this.purchaseDeliverySelected.is_open && !this.purchaseDeliverySelected.sap_doc_entry) {
          return true
        }
      }
      return false
    },
    sapStatus () {
      if (!this.purchaseDeliverySelected) { return 'pending' }
      if (this.syncing) {
        return 'syncing'
      } else {
        if (this.purchaseDeliverySelected.sap_doc_entry) {
          return this.purchaseDeliverySelected.sap_is_synced ? 'full' : 'partial'
        } else {
          return 'pending'
        }
      }
    },
    sapSyncErrors () {
      if (this.purchaseDeliverySelected) {
        const { sap_errors, sap_doc_entry } = this.purchaseDeliverySelected
        if (!sap_doc_entry && sap_errors) { return true }
      }
      return false
    },
    ready () {
      if (this.purchaseDeliverySelected) {
        return true
      }
      return false
    },
    currency () {
      if (this.purchaseDeliverySelected) {
        return this.purchaseDeliverySelected.doc_currency
      }
      return null
    },
    items () {
      if (this.purchaseDeliverySelected) {
        return this.$store.getters['purchaseDeliveryItems'].filter(el => el.is_active)
      }
      return []
    },
    subtotal () {
      if (!this.purchaseDeliverySelected) { return 0 }
      if (this.items.length == 0) { return 0 }
      let subtotal = 0
      if (this.purchaseDeliverySelected) {
        this.items.forEach(item => subtotal += parseFloat(this.itemSubtotal(item)))
      }
      return subtotal.toFixed(2)
      // return Math.floor(subtotal * 100) / 100
    },
    discount () {
      if (this.purchaseDeliverySelected) {
        return this.subtotal * (this.purchaseDeliverySelected.discount / 100)
      }
      return 0
    },
    discountPercent () {
      return this.purchaseDeliverySelected ? this.purchaseDeliverySelected.discount / 100 : 0
    },
    additionalExpense () {
      if (this.purchaseDeliverySelected) {
        // TODO: Next version!
        // if (this.items.length > 0 ) {
        //   const firstItemTax = this.items[0].tax_code
        //   const taxAmount = this.taxCodes[firstItemTax] ? this.taxCodes[firstItemTax] + 1 : null
        //   return parseFloat(this.purchaseDeliverySelected.additional_expense) * taxAmount
        // }
        return parseFloat(this.purchaseDeliverySelected.additional_expense) * 1.13
      }
      return 0
    },
    tax () {
      if (!this.purchaseDeliverySelected) { return 0 }
      if (this.items.length > 0 ) {
        const totalTax = this.items.reduce((acum, val) => {
          const taxAmount = this.taxCodes[val.tax_code] || 0
          const ItemSubtotal = this.itemSubtotal(val)
          const ItemDiscount = ItemSubtotal * this.discountPercent
          let itemTax = (ItemSubtotal - ItemDiscount) * taxAmount
          return acum + itemTax
        }, 0)
        return totalTax
      }
      return 0
    },
    total () {
      if (!this.purchaseDeliverySelected) { return 0 }
      return this.subtotal - this.discount + this.tax + this.additionalExpense
    },
  },
  methods: {
    updatePurchaseDelivery (data) {
      if (this.processing){ return }
      this.processing = true
      this.$http.patch(`/api/production/purchase_deliveries/${this.purchaseDeliverySelected.id}`, data)
        .then(response => {
          console.log(response)
          this.$store.commit('setPurchaseDeliveryData', response.data)
          this.discountEdit = false
          this.additionalExpenseEdit = false
          this.alertVariant = 'success'
          this.alertMessage = 'Cambios guardados'
          this.alertShow = true
        })
        .catch(e => {
          console.log(e.response)
          this.alertVariant = 'danger'
          this.alertMessage = 'Algo no funcionó'
          this.alertShow = true
        })
        .finally(() => {
          this.processing = false
        })
    },
    createEmOnSap () {
      this.processing = true
      this.$http.patch(`/api/production/purchase_deliveries/${this.purchaseDeliverySelected.id}/create_on_sap`, {
        sync: true,
        update_order_quantities: this.sapSyncErrors ? false : true
      })
        .then(response => {
          console.log(response.data)
          this.$store.commit('resetPurchaseOrderState')
          if (!this.pinging) {
            this.syncing = true
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.confirmationModal = false
          this.processing = false
        })
    },
    fetchSapState () {
      if (this.purchaseDeliverySelected && this.sapStatus == 'pending' && this.purchaseDeliverySelected.sap_errors) {
        this.syncing = true
        this.ping()
      }
    },
    ping () {
      console.log('Pinging sap status')
      this.pinging = true
      this.$http.get(`/api/production/purchase_deliveries/${this.purchaseDeliverySelected.id}/fetch_sap_status`)
        .then(response => {
          console.log(response.data)
          if (response.data.success) {
            this.pinging = false
            this.syncing = false
          } else {
            this.retryPingCount+=1
            if (this.retryPingCount == 10) {
              this.pinging = false
              this.syncing = false
              this.retryPingCount = 0
              return
            }
            setTimeout(() => this.ping(), 2500)
          }
          this.$store.commit('setPurchaseDeliveryData', response.data.purchase_delivery)
        })
        .catch(e => {
          console.log(e)
          this.pinging = false
          this.syncing = false
        })
    },
    updateNotes () {
      const data = {
        purchase_delivery: {
          notes: this.form.notes
        }
      }
      this.updatePurchaseDelivery(data)
    },
    focusDiscount () {
      if (this.isEditable) {
        const { discount } = this.purchaseDeliverySelected
        this.form.discount = discount
        this.discountEdit = true
        this.$nextTick(() => {
          this.$refs.discountInput.focus()
          this.$refs.discountInput.select()
        })
      }
    },
    updateDiscount () {
      if (this.form.discount == this.discountPercent * 100) {
        this.discountEdit = false
        return
      }
      const data = {
        purchase_delivery: {
          discount: this.form.discount
        }
      }
      this.updatePurchaseDelivery(data)
    },
    updatedInvoiceNumber (value) {
      const data = {
        purchase_delivery: {
          invoice_number: value
        }
      }
      this.updatePurchaseDelivery(data)
    },
    updateDocDate (newDate, showAlerts = true) {
      if (this.processing){ return }
      this.processing = true
      if (showAlerts) this.syncingDocDate = true
      this.$http.patch(`/api/production/purchase_deliveries/${this.purchaseDeliverySelected.id}/update_doc_date`, {
        purchase_delivery: {
          doc_date: newDate
        }
      })
        .then(response => {
          console.log(response)
          this.$store.commit('setPurchaseDeliveryData', response.data)
          if (showAlerts) {
            this.alertVariant = 'success'
            this.alertMessage = 'Fecha actualizada correctamente'
            this.alertShow = true
          }
        })
        .catch(e => {
          console.error(e.response)
          if (showAlerts) {
            this.alertVariant = 'danger'
            this.alertMessage = 'Algo no funcionó'
            this.alertShow = true
          }
        })
        .finally(() => {
          this.processing = false
          this.syncingDocDate = false
        })
    },
    focusAdditionalExpense () {
      if (this.currency != 'USD' && this.currency != 'COL') return
      if (this.isEditable) {
        const { additional_expense } = this.purchaseDeliverySelected
        this.form.additional_expense = additional_expense
        this.additionalExpenseEdit = true
        this.$nextTick(() => {
          this.$refs.additionalExpenseInput.focus()
          this.$refs.additionalExpenseInput.select()
        })
      }
    },
    updateAnotherAmount () {
      if (this.form.additional_expense == this.purchaseDeliverySelected.additional_expense) {
        this.additionalExpenseEdit = false
        return
      }
      const data = {
        purchase_delivery: {
          additional_expense: this.form.additional_expense
        }
      }
      this.updatePurchaseDelivery(data)
    },
    itemSubtotal ({price, quantity, item_currency}) {
      if (quantity == 0) return 0
      const { usd_crc_rate, eur_crc_rate, can_crc_rate } = this.purchaseDeliverySelected
      if (item_currency == this.currency) return price * quantity

      return this.calcItemSubtotal(usd_crc_rate, eur_crc_rate, can_crc_rate, price, quantity, item_currency)
    },
    calcItemSubtotal (usdCrcRate, eurCrcRate, canCrcRate, price, quantity, itemCurrency) {
      if (this.currency == 'COL') {
        // convert to COLONES
        if (itemCurrency == 'USD') { return this.convertPriceToCurrency('COL', price, usdCrcRate) * quantity }
        else if (itemCurrency == 'EUR') { return this.convertPriceToCurrency('COL', price, eurCrcRate) * quantity }
        else if (itemCurrency == 'CAN') { return this.convertPriceToCurrency('COL', price, canCrcRate) * quantity }
      } else if (this.currency == 'USD') {
        // convert to USD
        if (itemCurrency == 'COL') { return this.convertPriceToCurrency('USD', price, usdCrcRate) * quantity }
        else if (itemCurrency == 'EUR') {
          const colones_price = this.convertPriceToCurrency('COL', price, eurCrcRate)
          return this.convertPriceToCurrency('USD', colones_price, usdCrcRate) * quantity
        } else if (itemCurrency == 'CAN') {
          const colones_price = this.convertPriceToCurrency('COL', price, canCrcRate)
          return this.convertPriceToCurrency('USD', colones_price, usdCrcRate) * quantity
        }
      }
      return 0
    },
    convertPriceToCurrency (currency, price, rate) {
      const ALLOWED_CURRENCIES = ['USD', 'COL']
      if (!currency || !ALLOWED_CURRENCIES.includes(currency)) return 0
      return currency === 'COL' ? price * rate : price / rate
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-page {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  opacity: 0.7;
}
</style>
