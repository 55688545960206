import { normalize, schema } from 'normalizr';

const state = {
  vacationTicketData: {},
  vacationTicketList: []
};

const getters = {
  getTicketById(state) {
    return id => state.vacationTicketData[id];
  },
  vacationTickets(state) {
    return state.vacationTicketList.map(id => state.vacationTicketData[id]);
  }
};

const actions = {

};

const mutations = {
  loadVacationTicketData(state, data) {
    const vacationTicketData = normalize(
      { vacationTickets: data },
      { vacationTickets: [ new schema.Entity('vacationTickets') ] }
    );
    if (vacationTicketData.entities.hasOwnProperty('vacationTickets')) { // in case of empty data
      state.vacationTicketData = vacationTicketData.entities.vacationTickets;
    } else {
      state.vacationTicketData = {}
    }
    state.vacationTicketList = vacationTicketData.result.vacationTickets;
  },
  addTicket(state, vacationTicket) {
    state.vacationTicketList.unshift(vacationTicket.id);
    state.vacationTicketData[vacationTicket.id] = vacationTicket;
  },
  setTicketData(state, payload) {
    let ticket = state.vacationTicketData[payload.id];
    ticket.status = payload.status;
    ticket.modified = payload.modified;
    ticket.number_days_requested = payload.number_days_requested;
    ticket.vacation_dates = payload.vacation_dates;
    ticket.vacation_dates_modified = payload.vacation_dates_modified;
    ticket.vacation_days_balance_snapshot = payload.vacation_days_balance_snapshot;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

