<template>
  <div>
    <ContentView>
      <Subheader title="Datos Externos" />
      <b-row align-h="center">
        <b-col lg="6" md="8">
          <b-row align-h="between">
            <div class="dash-card-title">Tipos de Cambio</div>
            <div class="pr-3">
              <Button size="sm" :disabled="!isDev" @click="openExchangeRateModal">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-1">Actualizar</span>
              </Button>
            </div>
          </b-row>
          <div class="dash-card" style="min-height: 0; padding-bottom: 1em">
            <b-row style="height: 100%">
              <b-col align-self="center" class="text-right price-display" style="font-size: 35px">
                {{ crcRate.toFixed(2) }}
              </b-col>
              <b-col align-self="center">
                CRC / USD
              </b-col>
            </b-row>
            <b-row style="height: 100%">
              <b-col align-self="center" class="text-right price-display" style="font-size: 35px">
                {{ eurRate.toFixed(2) }}
              </b-col>
              <b-col align-self="center">
                CRC / EUR
              </b-col>
            </b-row>
            <b-row style="height: 100%">
              <b-col align-self="center" class="text-right price-display" style="font-size: 35px">
                {{ canRate.toFixed(2) }}
              </b-col>
              <b-col align-self="center">
                CRC / CAN
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-col lg="6" md="8">
          <b-row>
            <div class="dash-card-title lead">Exportar</div>
          </b-row>
          <div class="dash-card" style="min-height: 0; padding-bottom: 1em">
            <b-row align-h="around" align-v="center" class="my-3">
              <!-- <Button @click="fetchExport('quotes_in_sap')">
                Cotizaciones en SAP
              </Button> -->
              <Button @click="fetchExport('users_with_login')" :disabled="!isDev">
                Usuarios Activos
              </Button>
              <Button @click="fetchExport('users_sap_codes')" :disabled="!isDev">
                Usuarios Códigos SAP
              </Button>
              <Button @click="fetchExport('cortina_database')">
                Cortinas
              </Button>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="isDev" align-h="center" class="mt-3">
        <b-col lg="6" md="8">
          <b-row align-h="between">
            <div class="dash-card-title">Empleados Anteriores</div>
            <div class="pr-3">
              <Button size="sm" variant="green" @click="addPreviousEmployeeModalShow = true">
                <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
                <span class="pl-2">Agregar</span>
              </Button>
            </div>
          </b-row>
          <div class="dash-card" style="min-height: 0; padding-bottom: 1em">
            <b-row align-h="around" align-v="center" class="my-3 px-2">
              <b-table-lite small :items="previousEmployees" :fields="previousEmployeeFields">

              </b-table-lite>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <!-- Testing sandbox -->
      <b-row v-if="isDev" align-h="center">
        <Button size="sm" @click="sendTestEmail">
          Test Email
        </Button>
      </b-row>
    </ContentView>

    <!-- Modals -->
    <b-modal
      centered
      v-model="exchangeRateModalShow"
      title="Actualizar tipo de cambio"
    >
      <b-row align-h="center" class="mt-4">
        <b-form-group label-size="sm" label="CRC / USD:" style="width: 200px">
          <b-input v-model="exchangeRateForm.usd_crc_rate" autofocus/>
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="CRC / EUR:" style="width: 200px">
          <b-input v-model="exchangeRateForm.eur_crc_rate" autofocus/>
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="CRC / CAN:" style="width: 200px">
          <b-input v-model="exchangeRateForm.can_crc_rate" autofocus/>
        </b-form-group>
      </b-row>
      <template #modal-footer>
        <Button variant="green" class="float-right" :disabled="!exchangeRateFormValid" @click="updateSetting">Guardar</Button>
      </template>
    </b-modal>

    <b-modal
      centered
      v-model="addPreviousEmployeeModalShow"
      title="Agregar Empleado Anterior"
    >
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="Nombre:" style="width: 200px">
          <b-input v-model="previousEmployeeForm.first_name" autofocus size="sm" />
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="Seg. Nombre:" style="width: 200px">
          <b-input v-model="previousEmployeeForm.second_name" size="sm" />
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="Apellido:" style="width: 200px">
          <b-input v-model="previousEmployeeForm.last_name" size="sm" />
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="Seg. Apellido:" style="width: 200px">
          <b-input v-model="previousEmployeeForm.last_name2" size="sm" />
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="Número empleado:" style="width: 200px">
          <b-input v-model="previousEmployeeForm.sap_employee_id" size="sm" />
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-2">
        <b-form-group label-size="sm" label="Código vendador:" style="width: 200px">
          <b-input v-model="previousEmployeeForm.sap_sales_person_code" size="sm" />
        </b-form-group>
      </b-row>
      <template #modal-footer>
        <Button variant="green" class="float-right" @click="createPreviousEmployee">Guardar</Button>
      </template>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import Button from './shared/Button'
import ContentView from './shared/ContentView'
import Subheader from './shared/Subheader'
import ToastAlert from './shared/ToastAlert'

export default {
  name: 'ExternalData',
  components: { Button, ContentView, Subheader, ToastAlert },
  data() {
    return {
      exchangeRateModalShow: false,
      exchangeRateForm: {
        usd_crc_rate: null,
        eur_crc_rate: null,
        can_crc_rate: null
      },
      previousEmployees: [],
      addPreviousEmployeeModalShow: false,
      previousEmployeeForm: {
        first_name: null,
        second_name: null,
        last_name: null,
        last_name2: null,
        status: 'anterior',
        sap_employee_id: null,
        sap_sales_person_code: null
      },
      previousEmployeeFields: [
        { key: 'id', label: 'ID' },
        { key: 'first_name', label: 'Nombre' },
        { key: 'last_name', label: 'Apellido' },
        { key: 'sap_employee_id', label: 'Número empleado' },
        { key: 'sap_sales_person_code', label: 'Código vendedor' }
      ],
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      processing: false,
    }
  },
  computed: {
    ...mapGetters(['isDev']),
    crcRate () {
      return this.$store.state.settings.usd_crc_rate
    },
    eurRate () {
      return this.$store.state.settings.eur_crc_rate
    },
    canRate () {
      return this.$store.state.settings.can_crc_rate
    },
    exchangeRateFormValid () {
      if (!this.exchangeRateForm.usd_crc_rate || isNaN(this.exchangeRateForm.usd_crc_rate)) { return false }
      if (!this.exchangeRateForm.eur_crc_rate || isNaN(this.exchangeRateForm.eur_crc_rate)) { return false }
      if (!this.exchangeRateForm.can_crc_rate || isNaN(this.exchangeRateForm.can_crc_rate)) { return false }
      return true
    }
  },
  mounted () {
    if (this.isDev) {
      this.fetchPreviousEmployees()
    }
  },
  methods: {
    openExchangeRateModal () {
      this.exchangeRateForm.usd_crc_rate = this.crcRate
      this.exchangeRateForm.eur_crc_rate = this.eurRate
      this.exchangeRateForm.can_crc_rate = this.canRate
      this.exchangeRateModalShow = true
    },
    updateSetting () {
      if (this.exchangeRateFormValid) {  // to validate before enter keypress as well
        if (this.processing) { return }
        this.processing = true
        this.$http.put('/api/settings/1', {
            setting: {
              usd_crc_rate: this.exchangeRateForm.usd_crc_rate,
              eur_crc_rate: this.exchangeRateForm.eur_crc_rate,
              can_crc_rate: this.exchangeRateForm.can_crc_rate
            }
          })
          .then(response => {
            console.log(response)
            const { usd_crc_rate, eur_crc_rate, can_crc_rate } = response.data
            this.$store.commit('setCrcRates', { usd_crc_rate, eur_crc_rate, can_crc_rate })
            this.alertVariant = 'success'
            this.alertMessage = 'Cambio guardado'
          })
          .catch(e => {
            console.log(error)
            this.alertVariant = 'danger'
            this.alertMessage = 'No funcionó'
          })
          .finally(() => {
            this.processing = false
            this.exchangeRateModalShow = false
            this.alertShow = true
          })
      }
    },
    fetchPreviousEmployees () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get('/api/human_resources/users/previous_employees')
        .then(response => {
          console.log(response)
          this.previousEmployees = response.data
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    createPreviousEmployee () {
      if (this.processing) { return }
      this.processing = true
      this.$http.post('/api/human_resources/users', {
          user: this.previousEmployeeForm
        })
        .then(response => {
          console.log(response)
          this.previousEmployees.push(response.data)
          this.previousEmployeeForm.first_name = null
          this.previousEmployeeForm.second_name = null
          this.previousEmployeeForm.last_name = null
          this.previousEmployeeForm.last_name2 = null
          this.previousEmployeeForm.sap_employee_id = null
          this.previousEmployeeForm.sap_sales_person_code = null
        })
        .catch(error => {
          this.alertShow = true
          this.alertVariant = 'danger'
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0]
          } else {
            this.alertMessage = 'No se pudo agregar el empleado'
          }
        })
        .finally(() => {
          this.processing = false
          this.addPreviousEmployeeModalShow = false
        })
    },
    fetchExport (exportCode) {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/exports/${exportCode}`)
        .then((res) => {
          console.log(res)
          const blob = new Blob([res.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${exportCode.toUpperCase()}_${format(new Date, 'd-M-yyyy')}.csv`
          link.click()
        })
        .catch((e) => {
          console.log(e)
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(this.processing = false)
    },
    sendTestEmail () {
      // TODO: Go over this
      console.log('sending email');
    }
  }
}
</script>
