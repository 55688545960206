<template>
  <div class="row justify-content-center">
    <span class="lead">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SectionCardTitle'
}
</script>

<style scoped>
.row {
  line-height: 30px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 2em;
  padding-bottom: 5px;
}
span {
  color: #333;
}
</style>

