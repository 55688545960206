<template>
  <div>
    <b-modal
      id="course-summary-modal"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      :header-class="headerVariant"
      @shown="fetchData"
      @hide="onModalHide"
    >
      <template #modal-title>
        <b-row>
          <b-col>
            <b :class="{'text-secondary': loading}">Resumen cursos:</b>
          </b-col>
        </b-row>
      </template>

      <b-container v-if="loading" fluid class="fs-13">
        <b-row align-h="center" class="mt-5">
          <div class="text-center text-info" >
            <b-spinner style="width: 1.25rem; height: 1.25rem"></b-spinner>
            <span class="pl-2 fs-20">Obteniendo datos...</span>
          </div>
        </b-row>
      </b-container>

      <b-container v-else fluid class="mt-1" >
        <b-row v-if="badRequest" align-h="center" class="mt-5">
          <div class="text-danger text-info" >
            <span class="pl-2 fs-18">Sin resultados, por favor intente de nuevo</span>
          </div>
        </b-row>
        <b-row v-else>
          <b-col>
            <b-row align-h="between">
              <b-col cols="4">
                <b-form-group class="mb-1">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-user-input"
                      v-model="userFilter"
                      type="search"
                      placeholder="Empleado, UN"
                      :disabled="loading"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!userFilter" @click="userFilter = ''"><font-awesome-icon :icon="['far', 'undo-alt']" fixed-width /></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :items="groupedCourseUsers"
              :fields="fields"
              :filter-included-fields="userFilterOn"
              :filter="userFilter"
              small
              primary-key="id"
              show-empty
              sticky-header="500px"
              fixed
              class="border-bottom"
              hover
            >
              <template #empty>
                <div class="d-flex align-items-center justify-content-center" style="height: 225px">
                  <span class="empty-list-text">
                    No hay resultados
                  </span>
                </div>
              </template>
              <template #emptyfiltered>
                <div class="d-flex align-items-center justify-content-center" style="height: 225px">
                  <span class="empty-list-text">
                    No hay resultados
                  </span>
                </div>
              </template>
              <!-- course_name -->
              <template #cell(course_name)="data">
                <span>{{ data.item.course.name }}</span>
              </template>
              <!-- user -->
              <template #cell(user)="data">
                <span>{{ data.value | concatName2 | titleize }}</span>
              </template>
              <!-- business_unit -->
              <template #cell(business_unit)="data">
                <span>{{ data.item.user.business_unit }}</span>
              </template>
              <!-- email -->
              <template #cell(email)="data">
                <span>{{ data.item.user.email }}</span>
              </template>
              <!-- due_on -->
              <template #cell(due_on)="data">
                <span>{{ data.value | formatDateCompact }}</span>
              </template>
              <!-- days -->
              <template #cell(days)="data">
                <span
                  class="text-success"
                  :class="{
                    'text-warning': countDaysRemaining(data.item.due_on) > 0 && countDaysRemaining(data.item.due_on) <16,
                    'text-danger': countDaysRemaining(data.item.due_on) < 0
                  }"
                >
                  {{ countDaysRemaining(data.item.due_on) }}
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-row align-h="end">
          <Button  size="sm" variant="red" class="mr-auto" @click="onModalHide">
            <span class="pl-1">Cerrar</span>
          </Button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Button from '../../shared/Button'
import { normalize, schema } from 'normalizr';
import { format } from 'date-fns'

export default {
  name: 'CourseSummaryModal',
  components: { Button },
  data () {
    return {
      currentDate: '',
      courseUserData: {},
      courseUserList: [],
      fields: [
        { key: 'course_name', label: 'Curso', tdClass: 'align-middle font-lato fs-16'},
        { key: 'user', label: 'Empleado', tdClass: 'align-middle font-lato fs-16' },
        { key: 'business_unit', label: 'UN', thClass: 'text-center', tdClass: 'align-middle text-center font-lato fs-16', thStyle: { width: '80px' }},
        { key: 'email', label: 'Correo', tdClass: 'align-middle font-lato fs-16' },
        { key: 'due_on', label: 'Caducidad', tdClass: 'align-middle font-lato fs-16', sortable: true, thStyle: { width: '120px' } },
        { key: 'days', label: 'Dias', tdClass: 'align-middle font-lato fs-16', thStyle: { width: '80px' } }
      ],
      userFilter: null,
      userFilterOn: ['user', 'business_unit'],
      processing: false,
      badRequest: false,
      loading: true,
    }
  },
  computed: {
    headerVariant () {
      return ['bg-oi','pb-0']
    },
    groupedCourseUsers () {
      if (this.courseUserList.length > 0){
        let array = this.courseUserList.map(id => this.courseUserData[id])
        return array.sort((a, b) => {
          if (a.due_on < b.due_on) {
            return -1;
          }
          else if (a.due_on > b.due_on) {
            return 1;
          } else {
            return 0;
          }
        })
      } else {
        return {}
      }
    }
  },
  mounted() {
    this.loading = true
    this.currentDate = format(new Date, 'yyyy-LL-dd')
  },
  methods: {
    fetchData () {
      console.log('fetching data')
      if (this.processing ) return
      this.loading = true
      this.processing = true
      this.$http.get(`/api/production/course_users`)
        .then((res) => {
          console.log('data',res.data.course_users)
          this.loadCourseData(res.data.course_users)
        })
        .catch((e) => {
          console.log(e)
          this.badRequest = true
        })
        .finally( () =>{
          this.loading = false
          this.processing = false
        })
    },
    loadCourseData(courseItems){
      const courseItemData = normalize(
        { items: courseItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (courseItemData.entities.hasOwnProperty('items')) {
        this.courseUserData = courseItemData.entities.items
      } else {
        this.courseUserData = {}
      }
      this.courseUserList = courseItemData.result.items
    },
    countDaysRemaining(start_date){
      if(start_date && this.currentDate) {
        var date1 = new Date(start_date)
        var date2 = new Date(this.currentDate)
        let difference = date1 - date2
        return difference / (1000 * 3600 * 24)
      } else {
        return 'N/A'
      }
    },
    onModalHide () {
      this.userFilter = ''
      this.$emit('modal-hidden')
    },
    toggleRowDetails (row) {
      row._showDetails =! row._showDetails
    }
  }
}
</script>
