<template>
  <div>
    <b-card v-if="photo" img-top class="clickable" @click="openModal">
      <b-container fluid>
        <b-card-img v-if="photo.img_url" :src="photo.img_url" top></b-card-img>
        <div v-else :class="'preview-anchor-' + photo.id" style="max-height: 500px; max-width: 500px" />
        <b-card-text>
          <div class="photo-caption text-muted text-truncate">
            {{ photo.caption }}
          </div>
        </b-card-text>
      </b-container>
    </b-card>

    <b-modal
      v-model="modalShow"
      size="lg"
      title="Detalles de foto"
      static
      hide-footer
    >
      <b-container v-if="photo">
        <b-row align-h="center">
          <b-img v-if="photo.img_url_lg" :src="photo.img_url_lg" class="modal-img" />
          <div v-else :class="'preview-anchor-' + photo.id" />
        </b-row>
        <b-row v-if="captionFormShow" align-h="center">
          <b-form-group style="width: 300px">
            <b-textarea
              v-model="form.caption"
              size="sm"
              rows="3"
              placeholder="Escribir leyenda"
              autofocus
              @keyup.enter="updatePhoto"
            ></b-textarea>
          </b-form-group>
        </b-row>
        <b-row v-if="captionFormShow" align-h="center">
          <b-form-group>
            <Button variant="green" size="sm" @click="updatePhoto">
              Guardar
            </Button>
          </b-form-group>
        </b-row>
        <b-row v-else class="mt-3">
          <b-col>
            <p>{{ photo.caption }}</p>
          </b-col>
        </b-row>
        <b-row align-h="between">
          <Button variant="red" size="sm" @click="deletePhoto">
            Borrar
          </Button>
          <Button size="sm" @click="captionFormShow = !captionFormShow">
            Editar Leyenda
          </Button>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import Button from './Button'

export default {
  name: 'PhotoCard',
  components: { Button },
  props: {
    photo: {
      type: Object
    }
  },
  data () {
    return {
      modalShow: false,
      captionFormShow: false,
      form: {
        caption: ''
      }
    }
  },
  methods: {
    openModal () {
      this.form.caption = this.photo.caption
      this.modalShow = true
    },
    updatePhoto () {
      this.$emit('photo-updated', { photo: this.photo, form: this.form })
      this.captionFormShow = false
    },
    deletePhoto () {
      this.modalShow = false
      this.$emit('photo-deleted', this.photo)
    },
    resetModal () {
      this.form.caption = ''
      this.captionFormShow = false
    }
  }
}
</script>
