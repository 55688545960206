<template>
  <div v-if="isHrAdmin || isDev" class="pb-5">
    <NavTabs />
    <!-- Employee TopShelf -->
    <b-container fluid>
      <b-row align-h="center" class="top-shelf py-4">
        <b-col lg="6" sm="8" class="px-0">
          <TitleBadge type="Employee">
            <template #title>{{ employee | concatName4 }}</template>
          </TitleBadge>
        </b-col>
        <b-col lg="2" sm="3" class="text-right">
          <div v-if="employee.status === 'activo'">
            <b-badge variant="success">Activo</b-badge>
            <div v-if="employee.is_manager">
              <b-badge class="theme-orange">Jefatura</b-badge>
            </div>
            <div v-if="employee.confirmed == true">
              <b-badge variant="secondary">Tiene Login</b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Subheader -->
    <b-container fluid>
      <b-row align-h="center" class="mt-5">
        <b-col lg="10">
          <b-row align-v="center">
            <b-col lg="1">
              <b-link class="ml-3" @click="$router.go(-1)">
                <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
                <span class="pl-2">Atrás</span>
              </b-link>
            </b-col>
            <b-col lg="10">
              <b-row align-h="between" align-v="center" class="pl-4 pr-3">
                <div class="pt-3 pt-sm-0">
                  <font-awesome-icon :icon="['far', 'umbrella-beach']" size="lg" fixed-width />
                  <span class="pl-2 fs-20">Detalles de Vacaciones</span>
                </div>
                <b-button-group class="pt-3 pt-sm-0">
                  <Button size="sm" class="mb-3" style="width: 100px" @click="fetchVacationIndividualReport">
                    <font-awesome-icon :icon="['far', 'arrow-to-bottom']" fixed-width />
                    <span class="pl-1">Reporte</span>
                  </Button>
                  <Button v-if="employeeDaysAvailable > 0" size="sm" class="text-danger mb-3" style="width: 100px" @click="deductionModalShow = true">
                    <font-awesome-icon :icon="['far', 'minus-circle']" fixed-width />
                    <span class="pl-1">Restar Días</span>
                  </Button>
                </b-button-group>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <ContentView>
      <b-row align-h="center" class="mt-3">
        <b-col lg="10">
          <b-row>
            <b-col lg="4" class="mb-4 mb-lg-0">
              <b-card header="Resumen" header-class="font-lato" style="height: 275px">
                <b-row align-h="center">
                  <b-table-simple borderless class="fs-15" style="width: 300px">
                    <b-tbody>
                      <b-tr>
                        <b-td style="width: 200px">Días Acumulados</b-td>
                        <b-td>{{ employee.vacation_days_accumulated }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Días Disfrutados</b-td>
                        <b-td>{{ employee.vacation_days_spent }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Días Restados</b-td>
                        <b-td>{{ employee.vacation_days_deducted }}</b-td>
                      </b-tr>
                      <b-tr class="border-top">
                        <b-th>Saldo de Vacaciones</b-th>
                        <b-td class="font-weight-bold">{{ employeeDaysAvailable }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
              </b-card>
            </b-col>
            <b-col lg="4" class="mb-4 mb-lg-0">
              <b-card header="Días Disponibles por Periodo" header-class="font-lato" class="overflow-auto" style="height: 275px">
                <b-row align-h="center">
                  <b-table-lite v-if="employeeDaysAvailable > 0" :items="availableDayDetail" small borderless class="fs-13" style="width: 275px" />
                  <small v-else class="font-italic text-info">No tiene días disponibles</small>
                </b-row>
              </b-card>
              <!-- <b-card header="Boletas Aprobadas" header-class="font-lato" class="overflow-auto" style="height: 275px">
                <b-row align-h="center">
                  <b-table-simple v-if="tickets.length > 0" class="fs-13" small borderless style="width: 275px">
                    <b-thead>
                      <b-tr>
                        <b-th>ID</b-th>
                        <b-th class="text-center" style="width: 80px">Días</b-th>
                        <b-th>Status</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="ticket in tickets" :key="ticket.id">
                        <b-td>
                          <span># {{ ticket.ticket_no }}</span>
                        </b-td>
                        <b-td class="text-center">{{ ticket.number_days_requested}}</b-td>
                        <b-td>
                          <span :class="{'text-success': ticket.status == 'aprobado' || ticket.status == 'finalizado'}">
                            {{ ticket.status | titleize }}
                          </span>
                          <span v-if="ticket.status == 'finalizado'" class="text-success">
                            <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
                          </span>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <small v-else class="font-italic text-info">No tiene boletas aprobadas</small>
                </b-row>
              </b-card> -->
            </b-col>
            <b-col lg="4">

            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5">
        <b-col lg="10">
          <b-card no-body style="width: 100%; min-height: 150px">
            <b-tabs card v-model="tabIndex" active-nav-item-class="font-weight-bold">
              <!-- BOLETAS TAB -->
              <b-tab title-link-class="font-lato-tab">
                <template #title>
                  Boletas Aprobadas ({{ tickets.length }})
                </template>
                <div v-if="loading" class="text-center text-info mt-3">
                  <b-spinner small type="grow"></b-spinner>
                  <span class="pl-2 fs-14">Cargando datos...</span>
                </div>
                <b-table v-else show-empty :items="tickets" :fields="ticketFields" class="fs-13">
                  <template #empty>
                    <p class="font-italic text-info text-center">No tiene boletas aprobadas todavía</p>
                  </template>
                  <template v-slot:cell(created_at)="data">
                    {{ data.value | formatDate }}
                  </template>
                  <template v-slot:cell(ticket_no)="data">
                    # {{ data.value }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span>{{ data.value | titleize }}</span>
                    <span v-if="data.value == 'finalizado'" class="text-success">
                      <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
                    </span>
                  </template>
                  <template  v-slot:cell(vacation_dates)="data">
                    <div v-if="data.item.modified">
                      <p v-for="date in data.item.vacation_dates_modified" :key="date" class="mb-0">
                        {{ date | formatDate }}
                      </p>
                    </div>
                    <div v-else>
                      <p v-for="date in data.value" :key="date" class="mb-0">
                        {{ date | formatDate }}
                      </p>
                    </div>
                  </template>
                </b-table>
              </b-tab>
              <!-- DEDUCTIONS TAB -->
              <b-tab title-link-class="font-lato-tab">
                <template #title>
                  Restas de Días ({{ vacationDeductionList.length }})
                </template>
                <b-table show-empty :items="vacationDeductions" :fields="deductionFields" class="fs-13">
                  <template #empty>
                    <p class="font-italic text-info text-center">No tiene días restados todavía</p>
                  </template>
                  <template v-slot:cell(created_at)="data">
                    {{ data.value | formatDate }}
                  </template>
                  <template v-slot:cell(admin)="data">
                    {{ data.item.admin | concatName2 }}
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-row align-h="around">
                      <!-- <Button size="sm" title="Editar" class="mb-3 mb-sm-0" @click="openEditModal(data.item)">
                        <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                      </Button> -->
                      <Button size="sm" title="Cancelar" @click="openCancelDeductionModal(data.item)">
                        <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
                      </Button>
                    </b-row>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Vacation Deduction Modal -->
    <b-modal v-model="deductionModalShow" centered hide-footer @hidden="clearForm">
      <template #modal-title>
        <h5>Restar días</h5>
      </template>
      <b-row align-h="center">
        <b-table-simple small borderless class="fs-14" style="width: 200px">
          <b-tbody>
            <b-tr>
              <b-td style="width: 130px" class="text-info">Días disponibles:</b-td>
              <b-td>{{ employeeDaysAvailable }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-form-group label-size="sm" style="width: 300px">
          <template #label>
            <span>Cantidad de días a restar</span><span class="asterisk">*</span>
          </template>
          <b-input v-model="deductionForm.number_days" type="number" size="sm" autofocus style="width: 180px" />
        </b-form-group>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-form-group label-size="sm" style="width: 300px">
          <template #label>
            <span>Razón</span><span class="asterisk">*</span>
          </template>
          <b-textarea v-model="deductionForm.reason" size="sm"></b-textarea>
        </b-form-group>
      </b-row>
      <b-row v-if="deductionForm.number_days > employeeDaysAvailable" align-h="center">
        <small class="text-danger">No se puede restar más días que el saldo disponible</small>
      </b-row>
      <!-- <b-row v-if="validationShow" align-h="center">
        <small class="text-danger">{{ validationMessage }}</small>
      </b-row> -->
      <b-row align-h="center" class="my-3">
        <Button
          size="sm"
          variant="red"
          :disabled="deductionForm.number_days > employeeDaysAvailable || !deductionForm.number_days || deductionForm.number_days <= 0 || isNaN(deductionForm.number_days) || !deductionForm.reason"
          @click="createVacationDeduction"
        >
          Restar días
        </Button>
      </b-row>
    </b-modal>

    <!-- Cancel Deduction Modal -->
    <b-modal v-model="cancelDeductionModalShow" centered hide-footer>
      <template #modal-title>
        <h5>Cancelar resta de días</h5>
      </template>
      <b-row align-h="center" class="px-5 mb-3 fs-13">
        <p class="text-info">Cancelar esta resta de días hace que los días restados vuelvan a estar disponibles en el saldo</p>
      </b-row>
      <b-row align-h="center">
        <b-table-simple v-if="selectedDeduction" small borderless class="fs-13" style="width: 300px">
          <b-tbody>
            <b-tr>
              <b-td style="width: 150px">Cantidad de días:</b-td>
              <b-td>{{ selectedDeduction.number_days }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Razón:</b-td>
              <b-td>{{ selectedDeduction.reason }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <b-row align-h="center" class="my-3">
        <Button size="sm" variant="red" @click="cancelVacationDeduction">Cancelar</Button>
      </b-row>
    </b-modal>

    <!-- Deduct Modal -->
    <!-- <b-modal v-model="deductModalShow" hide-footer @hidden="clearValidation(); clearForm()">
      <template #modal-title>
        <h5>Restar días</h5>
      </template>
      <b-row align-h="center">
        <b-table-simple small borderless class="fs-13" style="width: 200px">
          <b-tbody>
            <b-tr>
              <b-td style="width: 130px">Días disponibles:</b-td>
              <b-td>{{ employeeDaysAvailable }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Días a restar:</b-td>
              <b-td :class="{'text-danger': selectedDatesArray.length > employeeDaysAvailable}">
                {{ selectedDatesArray.length }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <b-row align-h="center" class="fs-13">
        <span class="text-info">Seleccionar fechas para restar</span>
      </b-row>
      <b-row align-h="center">
        <flat-pickr
          v-model="selectedDates"
          :config="flatpickrConfig"
          name="selectedDates"
          class="d-none"
          @on-change="clearValidation"
        ></flat-pickr>
      </b-row>
      <b-row align-h="center" class="mt-3 fs-13">
        <div class="overflow-auto text-center" style="width: 200px; height: 70px">
          <p v-for="date in selectedDatesArray" :key="date" class="mx-auto text-info mb-1">{{ date | formatDateLong }}</p>
        </div>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-form-group label-size="sm" style="width: 300px">
          <template #label>
            <span>Razón</span><span class="asterisk">*</span>
          </template>
          <b-textarea v-model="vacationDayForm.deduction_reason" size="sm"></b-textarea>
        </b-form-group>
      </b-row>
      <b-row v-if="selectedDatesArray.length > employeeDaysAvailable" align-h="center">
        <small class="text-danger">No se puede restar más días que el saldo disponible</small>
      </b-row>
      <b-row v-if="validationShow" align-h="center">
        <small class="text-danger">{{ validationMessage }}</small>
      </b-row>
      <b-row align-h="center" class="my-3">
        <Button
          size="sm"
          variant="red"
          :disabled="!selectedDatesArray.length > 0 || selectedDatesArray.length > employeeDaysAvailable || !vacationDayForm.deduction_reason"
          @click="deductVacationDays"
        >
          Restar días seleccionadas
        </Button>
      </b-row>
    </b-modal> -->

    <!-- Edit Day Modal -->
    <!-- <b-modal v-model="editModalShow" hide-footer @hidden="clearValidation">
      <template #modal-title>
        <h5>Editar día restado</h5>
      </template>
      <b-row align-h="center" class="fs-13">
        <span class="text-info">Editar fecha restada</span>
      </b-row>
      <b-row align-h="center">
        <flat-pickr
          v-model="vacationDayForm.spent_at"
          :config="flatpickrConfig2"
          name="spent_at"
          class="d-none"
          @on-change="clearValidation"
        ></flat-pickr>
      </b-row>
      <b-row align-h="center" class="mt-3 fs-13">
        <div class="text-center" style="width: 200px; height: 30px">
          <p class="mx-auto text-info mb-1">{{ vacationDayForm.spent_at | formatDateLong }}</p>
        </div>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-form-group label-size="sm" style="width: 300px">
          <template #label>
            <span>Razón</span><span class="asterisk">*</span>
          </template>
          <b-textarea v-model="vacationDayForm.deduction_reason" size="sm"></b-textarea>
        </b-form-group>
      </b-row>
      <b-row v-if="validationShow" align-h="center">
        <small class="text-danger">{{ validationMessage }}</small>
      </b-row>
      <b-row align-h="center" class="my-3">
        <Button size="sm" variant="green" :disabled="!vacationDayForm.spent_at || !vacationDayForm.deduction_reason" @click="updateVacationDay">
          Guardar cambios
        </Button>
      </b-row>
    </b-modal> -->

    <!-- Add Back Day Modal -->
    <!-- <b-modal v-model="addBackModalShow" hide-footer>
      <template #modal-title>
        <h5>Deshacer día restado</h5>
      </template>
      <b-row align-h="center" class="px-5 mb-3 fs-13">
        <p class="text-info">Deshacer este día restado dará como resultado que el día vuelva a estar disponible y incluido en el saldo</p>
      </b-row>
      <b-row align-h="center">
        <b-table-simple v-if="selectedDay" small borderless class="fs-13" style="width: 250px">
          <b-tbody>
            <b-tr>
              <b-td style="width: 60px">Fecha:</b-td>
              <b-td>{{ selectedDay.spent_at | formatDateLong }}</b-td>
            </b-tr>
            <b-tr>
              <b-td>Razón:</b-td>
              <b-td>{{ selectedDay.deduction_reason }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <b-row align-h="center" class="my-3">
        <Button size="sm" variant="red" @click="addBackVacationDay">Deshacer día restado</Button>
      </b-row>
    </b-modal> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { normalize, schema } from 'normalizr';
// import flatPickr from 'vue-flatpickr-component';
// import { Spanish } from "flatpickr/dist/l10n/es.js";
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import TitleBadge from '../shared/TitleBadge';
import ToastAlert from '../shared/ToastAlert';
import NavTabs from './NavTabs';

export default {
  name: 'EmployeeVacations',
  components: { Button, ContentView, TitleBadge, ToastAlert, NavTabs },
  data() {
    return {
      employeeId: Number(this.$route.params.id),
      tickets: [],
      // vacationDayData: {},
      // vacationDayList: [],
      // spentVacationDays: [],
      vacationDeductionData: {},
      vacationDeductionList: [],
      availableDayData: {},
      deductionModalShow: false,
      selectedDeduction: null,
      deductionForm: {
        admin_id: null,
        number_days: null,
        reason: null,
      },
      selectedDeduction: null,
      cancelDeductionModalShow: false,

      // deductModalShow: false,
      // selectedDates: '',
      // editModalShow: false,
      // addBackModalShow: false,
      // selectedDay: null,
      // vacationDayForm: {
      //   status: null,
      //   deduction_reason: null,
      //   spent_at: null,
      // },
      tabIndex: 0,
      ticketFields: [
        { key: 'created_at', label: 'Fecha de solicitud', thClass: 'top-borderless', thStyle: { width: '100px' } },
        { key: 'ticket_no', label: 'Boleta', thClass: 'top-borderless text-center', tdClass: 'text-center' },
        { key: 'number_days_requested', label: 'Cantidad de días', thClass: 'top-borderless text-center', tdClass: 'text-center' },
        { key: 'status', thClass: 'top-borderless' },
        { key: 'vacation_dates', label: 'Fechas de Vac.', thClass: 'top-borderless text-center', tdClass: 'text-center' }
      ],
      deductionFields: [
        { key: 'created_at', label: 'Fecha de registro', thClass: 'top-borderless', thStyle: { width: '100px' } },
        { key: 'number_days', label: 'Cantidad de días', thClass: 'top-borderless text-center', tdClass: 'text-center' },
        { key: 'reason', label: 'Razón', thClass: 'top-borderless' },
        { key: 'admin', label: 'Admin RRHH', thClass: 'top-borderless' },
        { key: 'actions', label: '', thClass: 'top-borderless text-center', tdClass: 'text-center', thStyle: { width: '120px' } }
      ],
      loading: false,
      processing: false,
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      validationShow: false,
      validationMessage: '',
      // flatpickrConfig: { // For multiple day selection
      //   locale: {
      //     ...Spanish,
      //     firstDayOfWeek: 0
      //   },
      //   inline: true,
      //   mode: "multiple",
      //   disable: [
      //     function(date) {
      //       return date.getDay() === 0;
      //     }
      //   ],
      //   disableMobile: "true"
      // },
      // flatpickrConfig2: { // For single day editing
      //   locale: {
      //     ...Spanish,
      //     firstDayOfWeek: 0
      //   },
      //   inline: true,
      //   disable: [
      //     function(date) {
      //       return date.getDay() === 0;
      //     }
      //   ],
      //   disableMobile: "true"
      // }
    }
  },
  computed: {
    ...mapGetters(['getEmployeeById', 'isHrAdmin', 'isDev']),
    currentUser() { return this.$store.state.currentUser },
    employee() {
      return this.getEmployeeById(this.employeeId);
    },
    employeeDaysAvailable() {
      return this.employee.vacation_days_accumulated - this.employee.vacation_days_spent - this.employee.vacation_days_deducted;
    },
    vacationDeductions() {
      let array = this.vacationDeductionList.map(id => this.vacationDeductionData[id]);
      return array.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));
    },
    availableDayDetail() {
      return Object.keys(this.availableDayData).map(key => ({ periodo: key, saldo: this.availableDayData[key] }));
    },
    // selectedDatesArray() {
    //   let array = this.selectedDates.split(',').map(date => date.trim()).filter(v => v);
    //   return array.sort((a,b) => new Date(a) - new Date(b)) // sort by ascending date
    // }
  },
  mounted() {
    this.deductionForm.admin_id = this.currentUser.id;
    this.fetchVacationDays();
  },
  methods: {
    fetchVacationDays() {
      this.loading = true;
      this.$http.get(`/api/human_resources/users/${this.employeeId}/fetch_vacation_details`)
        .then(response => {
          console.log(response)
          // const vacationDayData = normalize(
          //   { vacationDays: response.data.spent_vacation_days },
          //   { vacationDays: [ new schema.Entity('vacationDays') ] }
          // );
          // if (vacationDayData.entities.hasOwnProperty('vacationDays')) {
          //   this.vacationDayData = vacationDayData.entities.vacationDays;
          // }
          // this.vacationDayList = vacationDayData.result.vacationDays;
          // this.spentVacationDays = response.data.spent_vacation_days;

          this.tickets = response.data.tickets;
          const vacationDeductionData = normalize(
            { vacationDeductions: response.data.deductions },
            { vacationDeductions: [ new schema.Entity('vacationDeductions') ] }
          );
          if (vacationDeductionData.entities.hasOwnProperty('vacationDeductions')) {
            this.vacationDeductionData = vacationDeductionData.entities.vacationDeductions;
          }
          this.vacationDeductionList = vacationDeductionData.result.vacationDeductions;
          this.availableDayData = response.data.available_days;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.loading = false);
    },
    fetchVacationIndividualReport() {
      this.$http.get('/api/human_resources/reports/vacation_individual', {
          params: {
            user_id: this.employeeId
          }
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' });
          // Download link
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          link.download = `Vacaciones_${this.employee.first_name}_${this.employee.last_name}_${format(new Date, 'd-M-yyyy')}.csv`;
          link.click()
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
          this.alertShow = true;
        })
    },
    createVacationDeduction() {
      if (this.processing) return;
      this.processing = true;
      this.$http.post(`/api/human_resources/users/${this.employee.id}/vacation_deductions`, {
          vacation_deduction: this.deductionForm
        })
        .then(response => {
          console.log(response)
          this.$store.commit('adjustDeductedVacationDays', response.data.employee);
          this.vacationDeductionList.unshift(response.data.deduction.id);
          this.$set(this.vacationDeductionData, response.data.deduction.id, response.data.deduction);
          this.availableDayData = response.data.available_days;
          this.clearForm();
          this.deductionModalShow = false;
          this.alertVariant = 'success';
          this.alertMessage = 'Resta se procesó con éxito';
          this.alertShow = true;
        })
        .catch(error => {
          console.log(error)
          this.validationMessage = 'No funcionó';
          this.validationShow = true;
        })
        .finally(() => this.processing = false);
    },
    openCancelDeductionModal(deduction) {
      this.selectedDeduction = deduction;
      this.cancelDeductionModalShow = true;
    },
    cancelVacationDeduction() {
      if (this.processing) return;
      this.processing = true;
      this.$http.delete(`/api/human_resources/users/${this.employee.id}/vacation_deductions/${this.selectedDeduction.id}`)
        .then(response => {
          console.log(response)
          this.$store.commit('adjustDeductedVacationDays', response.data.employee);
          const vacationDeductionData = normalize(
            { vacationDeductions: response.data.deductions },
            { vacationDeductions: [ new schema.Entity('vacationDeductions') ] }
          );
          if (vacationDeductionData.entities.hasOwnProperty('vacationDeductions')) {
            this.vacationDeductionData = vacationDeductionData.entities.vacationDeductions;
          }
          this.vacationDeductionList = vacationDeductionData.result.vacationDeductions;
          this.availableDayData = response.data.available_days;
          this.selectedDeduction = null;
          this.alertVariant = 'success';
          this.alertMessage = 'La resta se canceló con éxito';
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
        })
        .finally(() => {
          this.processing = false;
          this.cancelDeductionModalShow = false;
          this.alertShow = true;
        });
    },
    // deductVacationDays() {
    //   if (this.processing) return;
    //   this.processing = true;
    //   this.$http.put(`/api/human_resources/users/${this.employee.id}/vacation_days/deduct`, {
    //       deducted_dates: this.selectedDatesArray,
    //       deduction_reason: this.vacationDayForm.deduction_reason
    //     })
    //     .then(response => {
    //       console.log(response)
    //       this.$store.commit('adjustDeductedVacationDays', response.data.employee);
    //       const vacationDayData = normalize(
    //         { vacationDays: response.data.spent_vacation_days },
    //         { vacationDays: [ new schema.Entity('vacationDays') ] }
    //       );
    //       if (vacationDayData.entities.hasOwnProperty('vacationDays')) {
    //         this.vacationDayData = vacationDayData.entities.vacationDays;
    //       }
    //       this.vacationDayList = vacationDayData.result.vacationDays;
    //       this.availableDayData = response.data.available_days;
    //       this.clearForm();
    //       this.deductModalShow = false;
    //       this.alertVariant = 'success';
    //       this.alertMessage = 'Los días se restaron con éxito';
    //       this.alertShow = true;
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       if (error.response && error.response.data.errors) {
    //         this.validationMessage = error.response.data.errors[0];
    //       } else {
    //         this.validationMessage = 'No funcionó';
    //       }
    //       this.validationShow = true;
    //     })
    //     .finally(() => this.processing = false);
    // },
    // openEditModal(day) {
    //   this.selectedDay = day;
    //   this.vacationDayForm.spent_at = this.selectedDay.spent_at;
    //   this.vacationDayForm.status = this.selectedDay.status;
    //   this.vacationDayForm.deduction_reason = this.selectedDay.deduction_reason;
    //   this.editModalShow = true;
    // },
    // updateVacationDay() {
    //   if (this.processing) return;
    //   this.processing = true;
    //   this.$http.put(`/api/human_resources/users/${this.employee.id}/vacation_days/${this.selectedDay.id}`, {
    //       vacation_day: this.vacationDayForm
    //     })
    //     .then(response => {
    //       console.log(response)
    //       this.$set(this.vacationDayData, response.data.id, response.data);
    //       this.selectedDay = null;
    //       this.clearForm();
    //       this.editModalShow = false;
    //       this.alertVariant = 'success';
    //       this.alertMessage = 'Día se actualizó con éxito';
    //       this.alertShow = true;
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       if (error.response && error.response.data.errors) {
    //         this.validationMessage = error.response.data.errors[0];
    //       } else {
    //         this.validationMessage = 'No funcionó';
    //       }
    //       this.validationShow = true;
    //     })
    //     .finally(() => this.processing = false);
    // },
    // openAddBackModal(day) {
    //   this.selectedDay = day;
    //   this.addBackModalShow = true;
    // },
    // addBackVacationDay() {
    //   if (this.processing) return;
    //   this.processing = true;
    //   this.$http.put(`/api/human_resources/users/${this.employee.id}/vacation_days/${this.selectedDay.id}`, {
    //       vacation_day: {
    //         status: 'disponible',
    //         deduction_reason: null,
    //         spent_at: null
    //       }
    //     })
    //     .then(response => {
    //       console.log(response)
    //       this.$store.commit('adjustDeductedVacationDays', response.data.employee);
    //       const vacationDayData = normalize(
    //         { vacationDays: response.data.spent_vacation_days },
    //         { vacationDays: [ new schema.Entity('vacationDays') ] }
    //       );
    //       if (vacationDayData.entities.hasOwnProperty('vacationDays')) {
    //         this.vacationDayData = vacationDayData.entities.vacationDays;
    //       }
    //       this.vacationDayList = vacationDayData.result.vacationDays;
    //       this.availableDayData = response.data.available_days;
    //       this.selectedDay = null;
    //       this.alertVariant = 'success';
    //       this.alertMessage = 'Día ya está disponible de nuevo';
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       this.alertVariant = 'danger';
    //       this.alertMessage = 'No funcionó';
    //     })
    //     .finally(() => {
    //       this.processing = false;
    //       this.addBackModalShow = false;
    //       this.alertShow = true;
    //     });
    // },
    clearForm() {
      // this.selectedDates = '';
      // this.vacationDayForm.status = null;
      // this.vacationDayForm.deduction_reason = null;
      // this.vacationDayForm.spent_at = null;

      this.deductionForm.number_days = null,
      this.deductionForm.reason = null
    },
    // clearValidation() {
    //   this.validationShow = false;
    //   this.validationMessage = '';
    // }
  }
}
</script>
