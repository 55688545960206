<template>
  <div>
    <NavTabs />
    <ContentView>
      <Subheader title="Organigrama" />
      <div v-if="loading" class="spinner d-flex justify-content-center">
        <div class="spinner-border"></div>
      </div>
      <div v-if="error">
        <span class="text-danger">El organigrama no pudo cargar!</span>
      </div>
      <div id="org-wrapper"></div>
    </ContentView>
  </div>
</template>

<script>
import $ from 'jquery';
import 'orgchart';
import 'orgchart/dist/css/jquery.orgchart.min.css';
import ContentView from '../shared/ContentView';
import Subheader from '../shared/Subheader';
import NavTabs from './NavTabs';

export default {
  name: 'OrgChart',
  components: { ContentView, Subheader, NavTabs },
  data() {
    return {
      loading: true,
      chartData: [],
      error: false
    }
  },
  watch: {
    loading(newState, oldState) {
      if (newState == false) {
        let mainTree = this.chartData.filter(d => d.children.length > 5)[0]
        let options = {
          data: mainTree, // Temporary to get the main tree object
          nodeContent: 'title',
          direction: "l2r"
          //exportButton: true,
          //exportFilename: "OrganigramaAccesos",
          //exportFileextension: "png"
        }
        var oc = $('#org-wrapper').orgchart(options);
        $('.orgchart').addClass('noncollapsable');
      }
    }
  },
  mounted() {
    console.log('Fetching chartData')
    this.fetchChartData();
  },
  methods: {
    fetchChartData() {
      this.$http.get('/api/human_resources/users/org_chart')
        .then(response => {
          console.log(response)
          this.chartData = response.data;
        })
        .catch(error => {
          console.log(error)
          this.error = true;
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style>
/*.orgchart .node {
  width: 140px !important;
}*/
.orgchart .title {
  font-size: 10px !important;
}
.spinner {
  padding-top: 80px;
}
</style>

