<template>
  <b-container fluid class="h-100">
    <b-row class="h-100 d-flex align-items-center justify-content-center">
      <b-col class="text-center">
        <img :src="img" alt="accesos-logo" width="40%">
        <h2>
          <b class="text-danger" style="font-size: 3rem;">404</b>
          <span class="mb-1"> - Página no encontrada</span>
        </h2>
        <b-row align-h="center" class="mb-2">
          <b-col lg="3" sm="12">
            <p class="text-secondary">
              La página que esta buscando no existe,
              regrese a la página principal o anterior de la aplicación.
            </p>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col v-if="!['distribuidor', 'instalador'].includes($store.state.currentUser.role)" lg="2" cols="6">
            <Button variant="blue" @click="$router.back()">
              <font-awesome-icon :icon="['far', 'chevron-left']" class="pr-3" size="lg"/>
              Página anterior
            </Button>
          </b-col>
          <b-col lg="2" cols="6">
            <Button variant="blue" @click="$router.push('/')">
              Página principal
              <font-awesome-icon :icon="['fas', 'home']" class="pl-2" fixed-width />
            </Button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Button from './shared/Button.vue'
import accesosLogo from 'images/logos/accesos-original.jpg'

export default {
  name: 'NotFoundComponent',
  components: { Button},
  data () {
    return {
      img: accesosLogo
    }
  }
}
</script>
