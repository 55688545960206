<template>
  <div>
    <QuoteNavTabs />
    <b-container fluid>
      <b-row class="mt-3 mb-4 pl-2 pl-sm-5">
        <b-col lg="9">
          <b-row>
            <b-col lg="6" class="pb-3 pb-lg-0">
              <b-table-simple small borderless class="mb-0">
                <b-tbody>
                  <b-tr class="fs-18">
                    <b-td class="text-right" style="width: 150px">
                      <font-awesome-icon :icon="['fas', 'calculator']" />
                      <span class="pl-2">Cotización:</span>
                    </b-td>
                    <b-td><span v-if="quote">#{{ quote.quote_id }}</span></b-td>
                  </b-tr>
                  <b-tr v-if="isSupervisor" class="text-muted fs-14">
                    <b-td class="text-right">Asesor:</b-td>
                    <b-td><span v-if="quote">{{ asesor | concatName2 | titleize }}</span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col lg="6" class="text-right">
              <!-- TODO: Go over this [isTaxExempt] -->
              <QuoteButtonBar
                v-if="quote"
                :quote="quote"
                :client="client"
                :displayCRC="displayCRC"
                @quote-copied="copyQuote"
                @quote-emailed="emailQuote"
                @quote-transferred="transferQuote"
                @process-failed="showAlertError"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="border-top: 3px solid #f2f2f2">
        <b-col lg="9" order="2" order-lg="1">

          <!-- Quote template -->
          <b-row align-h="center" class="mb-5 px-0 px-sm-3 py-3">
            <b-col class="px-1 px-sm-3">
              <!-- Header -->
              <b-row class="px-2">
                <b-col sm="4" cols="6">
                  <img :src="accesosLogo" class="brand-logo">
                </b-col>
                <b-col sm="4" class="d-none d-sm-block fs-10">
                  <b-row>Teléfono: 2227-0909, Fax: 2227-4200</b-row>
                  <b-row>Email: info@accesos.co.cr</b-row>
                  <b-row>Cedula Juridica: 3-101-177456</b-row>
                  <b-row>San José, Costa Rica</b-row>
                </b-col>
                <b-col sm="4" cols="6">
                  <b-container>
                    <b-row align-h="end" class="fs-14">
                      <span>COTIZACIÓN</span>
                    </b-row>
                    <b-row align-h="end">
                      <span v-if="ready" class="font-weight-bold">#{{ quote.quote_id }}</span>
                    </b-row>
                    <b-row align-h="end" class="fs-10">
                      <span v-if="ready">{{ quote.created_at | formatDateCompact }}</span>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>

              <b-container class="mt-4 mb-3">
                <b-row>
                  <b-col>
                    <b-row class="pl-2 mb-2">
                      <b-col lg="2" cols="4" class="px-0 text-right text-muted">
                        <span class="mr-3">Cotizado para:</span>
                      </b-col>
                      <b-col lg="10" cols="8" class="pl-2 pr-0">
                        <b-link v-if="client" :to="`/app/prod/clientes/${client.id}`" class="related-link">{{ client.name }}</b-link>
                      </b-col>
                    </b-row>
                    <b-row v-if="quote && quote.sap_contact_person_code" class="pl-2 fs-12">
                      <b-col lg="2" cols="4" class="px-0 pt-1 text-right text-muted">
                        <span class="mr-3">Atención:</span>
                      </b-col>
                      <b-col lg="6" cols="8" class="text-truncate px-0">
                        <div :class="{ 'click-to-edit': isOpen }" class="px-1 py-1"  @click="openContactDataModal">
                          <p class="mb-0">
                            <span>{{ quote.contact_data['name'] }}</span>
                          </p>
                          <p v-show="quote.contact_data['phone'] || quote.contact_data['cellular']" class="mb-0">
                            <span v-if="quote.contact_data['phone']">Teléfono:</span>
                            <span v-if="quote.contact_data['phone']" class="pl-1">{{ quote.contact_data['phone'] }}</span>
                            <span v-if="quote.contact_data['cellular']">, Celular:</span>
                            <span v-if="quote.contact_data['cellular']" class="pl-1">{{ quote.contact_data['cellular'] }}</span>
                          </p>
                          <p v-show="quote.contact_data['email']" class="mb-0">
                            <span>Email:</span>
                            <span class="pl-2">{{ quote.contact_data['email'] }}</span>
                          </p>
                          <p v-if="!quote.address">
                            <span>Dirección:</span>
                            <span class="text-secondary">dirección no definida, seleccione una...</span>
                          </p>
                          <p v-else v-show="quote.address" class="mb-0">
                            <span>Dirección:</span>
                            <span v-if="quote.address" class="pl-1">{{ quote.address }}</span>
                            <span v-else> - </span>
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
              <hr>

              <b-container>
                <b-row class="my-2">
                  <b-col>
                    <b-row align-v="center" class="pl-2" style="min-height: 45px">
                      <b-col lg="2" cols="3" class="px-0 text-right text-muted">
                        <span class="pl-1 mr-3">Titulo:</span>
                      </b-col>
                      <b-col lg="10" cols="9" class="px-0">
                        <div v-if="ready">
                          <div v-if="titleEdit">
                            <b-input
                              v-model="form.title"
                              size="sm"
                              ref="title"
                              @blur="updateQuote"
                              @keyup.enter="updateQuote"
                            />
                          </div>
                          <div v-else class="text-truncate">
                            <span v-if="quote.title" class="text-truncate" :class="{ 'click-to-edit': isOpen }"  @click="openEditInput('title')">
                              {{ quote.title }}
                            </span>
                            <b-button v-else class="fill-quote-button" variant="outline-warning" :disabled="!isOpen" @click="openEditInput('title')">
                              <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                              <span>Llenar</span>
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <b-container>
                <b-row v-if="ready" align-v="end" class="my-3">
                  <b-col cols="7">
                    <toggle-button
                      v-model="displayCRC"
                      :labels="{ checked: 'Colones', unchecked: 'Dolares' }"
                      :width="75"
                      color="#cd8488"
                      class="mb-0"
                    />
                    <!-- <toggle-button
                      v-if="quote.is_open"
                      v-model="isTaxExempt"
                      :labels="{ checked: 'EXO', unchecked: 'IV' }"
                      :disabled="togglingTax"
                      :width="75"
                      sync
                      color="#cd8488"
                      class="mb-0 ml-3"
                    /> -->
                  </b-col>
                  <b-col cols="5">
                    <b-row align-h="end" class="pr-1">
                      <QuoteItemSelector
                        v-if="quote.is_open"
                        :quote-id="id"
                        :displayCRC="displayCRC"
                        :exchange-rate="exchangeRate"
                        :price-list="priceList"
                        :last-sap-line="lastSapLine"
                        :client-exonerated="client_exonerated"
                        @quote-item-added="addQuoteItem"
                        @total-changed="saveTotal"
                      />
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Quote List -->
              <b-container class="my-3 fs-13">
                <b-row align-v="center" align-h="center" style="min-height: 5em">
                  <QuoteItemTable
                    v-if="ready"
                    :quote="quote"
                    :quote-items="quoteItems"
                    :price-list="priceList"
                    @sync-to-sap="syncToSap = true"
                    @item-updated="updateItem"
                    @item-deleted="loadItemData"
                    @total-changed="saveTotal"
                    @quote-item-added="addQuoteItem"
                  />
                  <div v-else class="text-info d-flex align-items-center">
                    <b-spinner style="width: 1.25rem; height: 1.25rem" type="grow"></b-spinner>
                    <span class="pl-3 fs-20">Cargando datos...</span>
                  </div>
                </b-row>
              </b-container>

              <!-- Guarantee, condition, and totals -->
              <b-container id="quote-guarantee-section">
                <b-row>
                  <b-col lg="6" order="2" order-lg="1">
                    <!-- payment_terms -->
                    <b-row align-v="center" class="pl-2">
                      <span class="mr-3 font-weight-light">Condición de pago:</span>
                      <span v-if="ready && client">{{ client.payment_terms }}</span>
                    </b-row>
                    <!-- warranty -->
                    <b-row align-v="center" class="pl-2 my-3">
                      <span class="mr-3 font-weight-light">Garantía: *</span>
                      <div v-if="ready">
                        <div v-if="warrantyTimeEdit">
                          <b-select
                            v-model="form.warranty_time"
                            :options="warrantyTimeOptions"
                            size="sm"
                            ref="warrantyTime"
                            style="width: 200px"
                            @change="updateQuote"
                          />
                        </div>
                        <div v-else>
                          <span v-if="quote.warranty_time" :class="{'click-to-edit': isOpen}" @click="openEditInput('warrantyTime')">
                            {{ quote.warranty_time }}
                          </span>
                          <b-button v-else class="fill-quote-button" variant="outline-warning" :disabled="!isOpen" @click="openEditInput('warrantyTime')">
                            <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                            <span>Llenar</span>
                          </b-button>
                        </div>
                      </div>
                    </b-row>
                    <!-- Notes -->
                    <b-row align-v="center" class="pl-2">
                      <span class="d-inline-block font-weight-light">
                        Comentarios:
                        <sup v-b-tooltip.hover.v-dark.topright='"Los comentarios son visibles para el cliente"'><font-awesome-icon :icon="['far', 'info-circle']" class="text-info" /></sup>
                      </span>
                      <b-button v-if="ready && !notesEdit && !quote.notes" variant="outline-warning" class="fill-quote-button ml-3" :disabled="!isOpen" @click="openEditInput('notes')">
                        <span>Anotar</span>
                      </b-button>
                    </b-row>
                    <b-row v-if="ready" class="mt-2 px-2">
                      <div v-if="notesEdit" class="w-100">
                        <ValidationProvider rules="max:260" name="comentarios" v-slot="{ errors }">
                          <b-textarea
                            v-model="form.notes" rows="3" size="sm" trim ref="notes"
                            :class="{ 'red-input-border': errors[0] }"
                            @blur="errors[0] || form.notes == quote.notes ? resetNotes() : updateQuote()"
                            @keydown.enter="errors[0] || form.notes == quote.notes ? resetNotes() : updateQuote()"
                          />
                          <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div v-else class="text-truncate">
                        <p v-if="quote.notes" class="font-italic fs-14 text-truncate" :class="{'click-to-edit': isOpen, 'ml-2': !isOpen}" @click="openEditInput('notes')">
                          {{ quote.notes }}
                        </p>
                      </div>
                    </b-row>
                  </b-col>
                  <!-- amounts -->
                  <b-col lg="6" order="1" order-lg="2" class="pb-4 pb-sm-0" id="quote-total-box">
                    <b-table-simple borderless small>
                      <b-tbody>
                        <b-tr>
                          <b-td></b-td>
                          <b-td class="text-right lato-label">SUBTOTAL</b-td>
                          <b-td class="text-right price-display">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ subtotalCRC | colones }}</span>
                              <span v-else>{{ subtotal | dollarize }}</span>
                            </div>
                            <div v-else>
                              <span v-if="displayCRC">‎₡0</span>
                              <span v-else>$0</span>
                            </div>
                          </b-td>
                          <b-td></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right">
                            <div v-if="ready">
                              <div v-if="discountEdit">
                                <ValidationProvider :rules="{ required: true, max_value: maxSalesDiscount, min_value: 0 }" slim v-slot="{ errors }">
                                  <b-input-group size="sm" append="%" class="ml-auto" style="width: 90px">
                                    <b-input
                                      v-model="form.discount"
                                      type="number"
                                      ref="discountInput"
                                      class="no-spin"
                                      :class="{ 'red-input-border': errors[0] }"
                                      @click="$nextTick(() => $refs.discountInput.select())"
                                      @keyup.enter="errors[0] ? null : $event.target.blur()"
                                      @blur="errors[0] ? null : updateDiscount()"
                                    />
                                  </b-input-group>
                                </ValidationProvider>
                              </div>
                              <span v-else :class="{'click-to-edit': quote.is_open}" @click="focusDiscount">{{ parseFloat(quote.discount.toFixed(2)) }} %</span>
                            </div>
                          </b-td>
                          <b-td class="text-right lato-label second-col">DESCUENTO</b-td>
                          <b-td class="text-right price-display third-col">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ discountCRC | colones }}</span>
                              <span v-else>{{ discount | dollarize }}</span>
                            </div>
                          </b-td>
                          <b-td></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td></b-td>
                          <b-td class="text-right lato-label">IMPUESTO</b-td>
                          <b-td class="text-right price-display">
                            <div v-if="ready">
                              <span v-if="displayCRC">{{ taxCRC | colones }}</span>
                              <span v-else>{{ tax | dollarize }}</span>
                            </div>
                          </b-td>
                          <b-td>
                          </b-td>
                        </b-tr>
                        <b-tr class="pt-3 border-top">
                          <b-td colspan="2" class="text-right">
                            <div v-show="ready && total > 0 && !displayCRC && quote.is_open" style="display: inline; cursor: pointer" id="custom-total-info">
                              <font-awesome-icon :icon="['far', 'info-circle']" class="text-info" />
                            </div>
                            <span v-if="displayCRC" class="font-weight-bold">TOTAL COLONES</span>
                            <span v-else class="font-weight-bold">TOTAL DOLARES</span>
                          </b-td>
                          <b-td class="text-right">
                            <div v-if="ready">
                              <div v-if="customTotalEdit">
                                <b-input-group size="sm" prepend="$">
                                  <b-input
                                    v-model="customTotalForm.total"
                                    type="number"
                                    ref="customTotal"
                                    class="text-center no-spin"
                                    :state="customTotalValid"
                                    @input="inputTotal"
                                    @blur="updateTotal"
                                    @keyup.enter="updateTotal"
                                  />
                                </b-input-group>
                              </div>
                              <div v-else class="price-display">
                                <div v-if="displayCRC">
                                  <span v-if="totalCRC > 0" class="p-0">{{ totalCRC | colones }}</span>
                                  <span v-else>{{ totalCRC | colones }}</span>
                                </div>
                                <div v-else>
                                  <span v-if="total > 0" :class="{ 'click-to-edit': quote.is_open }" class="p-0" @click="focusTotal">{{ total | dollarize }}</span>
                                  <!-- <span v-if="total > 0"  class="p-0">{{ total | dollarize }}</span> -->
                                  <span v-else>{{ total | dollarize }}</span>
                                </div>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <!-- follow_up_notes, technician_notes -->
                <b-row v-if="ready && (quote.follow_up_notes || quote.technician_notes)">
                  <b-col v-if="quote.follow_up_notes" class="pl-2">
                    <span class="d-inline-block font-weight-light mb-2">
                      Comentarios de seguimiento:
                      <sup v-b-tooltip.hover.v-dark.topright='"Comentarios internos, no son visibles para el cliente"'><font-awesome-icon :icon="['far', 'info-circle']" class="text-info" /></sup>
                    </span>
                    <div v-if="followNotesEdit" class="w-100">
                      <ValidationProvider rules="max:260" name="Comentarios de seguimiento" v-slot="{ errors }">
                        <b-textarea
                          v-model="form.follow_up_notes" rows="3" size="sm" trim ref="followNotes"
                          :class="{ 'red-input-border': errors[0] }"
                          @blur="errors[0] || form.follow_up_notes == quote.follow_up_notes ? resetFollowNotes() : updateQuote()"
                          @keydown.enter="errors[0] || form.follow_up_notes == quote.follow_up_notes ? resetFollowNotes() : updateQuote()"
                        />
                        <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                    <div v-else>
                      <p class="font-italic fs-14 text-truncate" :class="{'click-to-edit': isOpen}" @click="openEditInput('followNotes')">
                        {{ quote.follow_up_notes }}
                      </p>
                    </div>
                  </b-col>
                  <b-col v-if="quote.technician_notes" class="pl-2">
                    <span class="d-inline-block font-weight-light mb-2">Comentarios al técnico:</span>
                    <div v-if="technicianNotesEdit" class="w-100">
                      <ValidationProvider rules="max:260" name="Comentarios al técnico" v-slot="{ errors }">
                        <b-textarea
                          v-model="form.technician_notes" rows="3" size="sm" trim ref="techniciaNotes"
                          :class="{ 'red-input-border': errors[0] }"
                          @blur="errors[0] || form.technician_notes == quote.technician_notes ? resetTechnicianNotes() : updateQuote()"
                          @keydown.enter="errors[0] || form.technician_notes == quote.technician_notes ? resetTechnicianNotes() : updateQuote()"
                        />
                        <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                    <div v-else>
                      <p class="font-italic fs-14 text-truncate" :class="{'click-to-edit': isOpen}" @click="openEditInput('techniciaNotes')">
                        {{ quote.technician_notes }}
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-container>

              <!-- Signature footer -->
              <b-container class="mt-5" id="quote-sigbox">
                <b-row class="px-2 border-bottom" style="min-height: 3em">
                  <b-col sm="6" class="asesor-box">
                    <b-row class="label-size-md">Asesor:</b-row>
                    <b-row align-h="center" class="label-size-lg">
                      {{ asesor | concatName2 | titleize }}
                    </b-row>
                    <b-row  align-h="center" class="label-size-lg mb-1">
                      {{ asesor.email }}
                    </b-row>
                  </b-col>
                  <b-col sm="3" class="delivery-box">
                    <b-row align-h="center" class="label-size-md">
                      Tiempo de entrega:
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <div v-if="ready">
                        <div v-if="deliveryTimeEdit">
                          <b-input-group size="sm" append="días" style="width: 120px">
                            <b-input
                              v-model="form.delivery_time"
                              ref="deliveryTime"
                              @keyup.enter="updateQuote"
                              @blur="updateQuote"
                            />
                          </b-input-group>
                        </div>
                        <div v-else>
                          <span v-if="quote.delivery_time" class="click-to-edit" @click="openEditInput('deliveryTime')">
                            {{ quote.delivery_time }} días
                          </span>
                          <b-button v-else class="fill-quote-button" variant="outline-warning" :disabled="!isOpen" @click="openEditInput('deliveryTime')">
                            <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                            <span>Llenar</span>
                          </b-button>
                        </div>
                      </div>
                    </b-row>
                  </b-col>
                  <b-col sm="3" class="valid-until-box">
                    <b-row align-h="center" class="label-size-md">
                      Cotización válida hasta:
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <div v-if="ready">
                        <span>{{ validUntilDate | formatDate }}</span>
                        <!-- <span v-if="quote.valid_until" :class="{ 'click-to-edit': quote.is_open }" @click="openDatepicker">{{ quote.valid_until | formatDate }}</span>
                        <b-button v-else-if="quote.is_open" class="fill-quote-button" variant="outline-warning" @click="openDatepicker">
                          <font-awesome-icon :icon="['far', 'exclamation-circle']" size="sm" fixed-width />
                          <span>Llenar</span>
                        </b-button> -->
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-h="center" class="px-2 border-bottom border-top border-secondary" id="quote-sigbox-accept">
                  <span class="font-weight-bold label-size-lg">ACEPTACIÓN Y FORMALIZACIÓN DE LA COTIZACIÓN:</span>
                </b-row>
                <b-row class="p-1 border-bottom border-secondary">
                  <span class="label-size-sm">Declaro y acepto que los precios y condiciones aquí descritas son satisfactorias.  Igualmente acepto que el plazo de entrega será efectivo a partir de la fecha en que se haya pagado el monto del adelanto correspondiente, los planos respectivos están debidamente aprobados y se haya notificado formalmente que los trabajos de previstas eléctricas y obra civil están debidamente terminados.  Autorizo a ACCESOS AUTOMATICOS S.A. para que proceda según esta oferta.  El pago se realizará según lo acordado.  Facturar de acuerdo a la siguiente información:</span>
                </b-row>
                <b-row align-h="center" class="label-size-sm p-1 border-bottom border-secondary">
                  <span>Facturar a: _____________________________________</span>
                  <span class="pl-2">Cédula Número: _____________________</span>
                  <span class="px-1">en Moneda:</span>
                  <span v-if="displayCRC">Colones</span>
                  <span v-else>US$</span>
                </b-row>
                <b-row align-h="center" class="border-bottom border-secondary label-size-lg" style="min-height: 4em">
                  <b-col cols="4" class="border-right border-secondary">
                    <b-row class="pl-2">Nombre:</b-row>
                  </b-col>
                  <b-col cols="3" class="border-right border-secondary">
                    <b-row class="pl-2">Firma:</b-row>
                  </b-col>
                  <b-col cols="3" class="border-right border-secondary">
                    <b-row class="pl-2">Cédula:</b-row>
                  </b-col>
                  <b-col cols="2">
                    <b-row class="pl-2">Fecha:</b-row>
                  </b-col>
                </b-row>
                <b-row class="p-1">
                  <span class="label-size-sm">Agradecemos su pago via transferencia electrónica o depósito, a las siguientes cuentas a nombre de Accesos Automáticos S.A.  Cédula 3-101-177456 - Banco Nacional de Costa Rica ¢: #100-01-000-204446-9 cc. 15100010012044467 / US$: #100-02-000-614006-5 cc. 15100010026140068 - BAC San José ¢: #903686152 cc. 10200009036861524 / US$: #904751120 cc. 10200009047511201 - Banco General ¢: 12620103000024449 / US$: 12620103010024441</span>
                </b-row>
              </b-container>
              <b-container>
                <b-row align-h="center">
                  <span class="label-size-sm font-weight-bold">* Aplican restricciones, sujeto a disponibilidad de existencias en el momento de la compra.  Todos los equipos requieren mantenimiento periodico</span>
                </b-row>
              </b-container>
              <!-- For watch reactivity? -->
              <div class="invisible">{{ total }}</div>
            </b-col>
          </b-row>
        </b-col>

        <!-- Right sidebar -->
        <b-col lg="3" order="1" order-lg="2" class="font-lato fs-14" id="right-sidebar" style="border-left: 3px solid #f2f2f2">
          <b-row class="my-2">
            <b-col class="text-right">
              <Button v-if="quote" size="sm" class="mr-2" :disabled="!isOpen" @click="editModalShow = true">
                <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                <span class="pl-2">Editar</span>
              </Button>
              <Button v-if="quote && quote.is_open" :disabled="sapStatus != 'full' || !isValid" size="sm" class="text-danger" @click="closingModalShow = true">
                <font-awesome-icon :icon="['fas', 'lock-alt']" />
                <span class="pl-2">Cerrar</span>
              </Button>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pl-3">
            <b-table-simple small borderless>
              <b-tbody>
                <b-tr>
                  <b-td>Estatus:</b-td>
                  <b-td v-if="quote">
                    <span v-if="quote.is_open">Abierto</span>
                    <span v-else class="text-danger">Cerrado</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="quote && !quote.is_open">
                  <b-td>Notas de cierre:</b-td>
                  <b-td>{{ quote.closing_notes }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Resultado:</b-td>
                  <b-td>
                    <div v-if="quote">
                      <span>{{ quote.status | titleize }}</span>
                      <span v-if="['alta', 'media', 'baja'].includes(quote.status)">
                        probabilidad
                      </span>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Código del cliente:</b-td>
                  <b-td v-if="client">{{ client.client_num }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Asesor del cliente:</b-td>
                  <b-td v-if="client && client.user">
                    {{ client.user | concatName2 }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Lista de precio:</b-td>
                  <b-td>
                    <span v-if="quote">{{ quote.pricing_mode | titleize }}</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Fecha de entrega:</b-td>
                  <b-td>
                    <span v-if="quote">{{ quote.valid_until | formatDate }}</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Fecha cierre:</b-td>
                  <b-td>
                    <span v-if="quote && quote.closed_on">
                      {{ quote.closed_on | formatDate }}
                    </span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Fecha facturación:</b-td>
                  <b-td>
                    <span v-if="quote && quote.billed_on">{{ quote.billed_on | formatDate }}</span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Próximo contacto:</b-td>
                  <b-td>
                    <span v-if="quote && quote.next_contact_on">{{ quote.next_contact_on | formatDate }}</span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Unidad negocio:</b-td>
                  <b-td>
                    <span v-if="quote">{{ quote.business_unit }}</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="quote && quote.business_unit2">
                  <b-td>Subnorma:</b-td>
                  <b-td>{{ quote.business_unit2 }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Versión plano:</b-td>
                  <b-td>
                    <span v-if="quote && quote.drawing_version">{{ quote.drawing_version }}</span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
          <!-- SAP result -->
          <b-row align-v="center" style="border-top: 3px solid #f2f2f2; height: 50px">
            <b-col>
              <span class="font-weight-bold fs-15">SAP</span>
            </b-col>
          </b-row>
          <b-row align-h="center" class="mb-1 pl-3">
            <b-table-simple small borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="width: 130px">Sincronización:</b-td>
                  <b-td>
                    <div v-if="quote">
                      <div v-if="sapStatus == 'full'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-success" />
                        <span class="text-muted pl-1">Full</span>
                      </div>
                      <div v-else-if="sapStatus == 'syncing'">
                        <b-spinner variant="secondary" small />
                        <span class="text-muted pl-1">Sincronizando...</span>
                      </div>
                      <div v-else-if="sapStatus == 'pending'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-danger" />
                        <span class="text-muted pl-1">Pendiente</span>
                      </div>
                      <div v-else-if="sapStatus == 'partial'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-warning" />
                        <span class="text-muted pl-1">Parcial</span>
                      </div>
                      <div v-else-if="sapStatus == 'ignored'">
                        <font-awesome-icon :icon="['fas', 'dot-circle']" fixed-width class="text-secondary" />
                        <span class="text-muted pl-1">Apagado</span>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Cotización:</b-td>
                  <b-td v-if="quote">
                    <span v-if="quote.sap_doc_num">{{ quote.sap_doc_num }}</span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
                <b-tr v-if="ready && order && order.sap_doc_num">
                  <b-td>
                    <span v-if="order.sap_series == 9">OI:</span>
                    <span v-else-if="order.sap_series == 71">OR:</span>
                    <span v-else-if="order.sap_series == 112">OM:</span>
                    <span v-else>Orden:</span>
                  </b-td>
                  <b-td>
                    <b-link @click="openUpdateOrderModal">
                      {{ order.sap_doc_num }}
                    </b-link>
                  </b-td>
                </b-tr>
                <b-tr v-if="quote && (baseValidationError || fixableValidationError|| quote.sap_error)">
                  <b-td class="align-top">Aviso:</b-td>
                  <b-td class="text-danger">
                    <p v-if="baseValidationError">{{ baseValidationError }}</p>
                    <p v-if="fixableValidationError">{{ fixableValidationError }}</p>
                    <p v-if="quote.sap_error">{{ sapErrorHash[quote.sap_error] || quote.sap_error }}</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
          <!-- Local data -->
          <b-row align-v="center" style="border-top: 3px solid #f2f2f2; height: 50px">
            <b-col>
              <span class="font-weight-bold fs-15">Datos locales</span>
            </b-col>
          </b-row>
          <b-row class="mb-1 pl-3" style="border-bottom: 3px solid #f2f2f2">
            <b-table-simple small borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="width: 130px">Proyecto:</b-td>
                  <b-td v-if="project">
                    <b-link :to="`/app/prod/proyectos/${project.id}`" class="related-link">{{ project.name }}</b-link>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 130px">Impresiones:</b-td>
                  <b-td>
                    <div v-if="ready">
                      <div v-if="printCountEdit">
                        <ValidationProvider rules="required|min_value:0" v-slot="{ errors }">
                          <b-input
                            v-model="form.print_count"
                            type="number"
                            size="sm"
                            ref="printCountInput"
                            :class="{ 'red-input-border': errors[0] }"
                            style="width: 50px"
                            @click="$nextTick(() => $refs.printCountInput.select())"
                            @blur="errors[0] ? null : updatePrintCount()"
                            @keyup.enter="errors[0] ? null : updatePrintCount()"
                          />
                        </ValidationProvider>
                      </div>
                      <span v-else class="click-to-edit" @click="focusPrintCount">{{ quote.print_count }}</span>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
          <!-- Order  validation-->
          <b-row v-if="ready && quote.is_open" align-v="center">
            <b-col v-if="!order || order && order.sap_error">
              <span v-if="!OrderClientValidation" class="font-weight-bold fs-15">Validación OI/OR/OM</span>
              <span v-else class="font-weight-bold fs-15">Crear Orden en SAP</span>
            </b-col>
          </b-row>
          <b-row v-if="quote && quote.is_open && order && order.sap_error">
            <span v-if="isDev" class="pl-5 text-danger">Only dev: {{ order.sap_error }}</span>
            <span v-else class="pl-5 text-danger">Error: Error al crear en SAP, intente de nuevo...</span>
          </b-row>
          <b-row v-if="ready && !OrderClientValidation" align-h="center" class="mt-1 pl-3">
            <b-link v-if="client" :to="`/app/prod/clientes/${client.id}`" class="related-link " style="text-decoration: underline">
              Datos cliente
            </b-link>
            <b-table-simple small borderless>
              <b-tbody>
                <b-tr
                  v-if="!client.email"
                  v-b-tooltip.hover.v-dark.topright='"Debe de completar el correo del cliente"'
                >
                  <b-td style="width: 150px">Correo:</b-td>
                  <b-td>
                    <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" />
                  </b-td>
                </b-tr>
                <b-tr
                  v-if="!client.phone || !client.phone_2"
                  v-b-tooltip.hover.v-dark.topright='"Debe completar teléfono 1 y/o 2 del cliente"'
                >
                  <b-td>Teléfonos:</b-td>
                  <b-td>
                    <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" />
                  </b-td>
                </b-tr>
                <b-tr
                v-if="!has_addresses"
                  v-b-tooltip.hover.v-dark.topright='"El cliente debe tener al menos una dirección"'
                >
                  <b-td>Direcciones:</b-td>
                  <b-td>
                    <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" />
                  </b-td>
                </b-tr>
                <b-tr
                  v-if="!has_contacts"
                  v-b-tooltip.hover.v-dark.topright='"El cliente debe tener al menos un contacto"'
                >
                  <b-td>Contactos:</b-td>
                  <b-td>
                    <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" />
                  </b-td>
                </b-tr>
                <b-tr
                  v-if="!has_billing_notes"
                  v-b-tooltip.hover.v-dark.topright='"Debe de completar las notas de facturación del cliente"'
                >
                  <b-td style="width: 150px">Notas de facturación:</b-td>
                  <b-td>
                    <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" />
                  </b-td>
                </b-tr>
                <b-tr
                  v-if="has_billing_notes && !validItemExoneration"
                  v-b-tooltip.hover.v-dark.topright='"El cliente no es exento, no puede exonerar las líneas del documento"'
                >
                  <b-td style="width:  150px">Cliente no exento:</b-td>
                  <b-td>
                    <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width class="text-danger" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
          <b-row v-if="ready && quote.is_open" class="pt-2" align-h="center">
            <Button v-if="OrderClientValidation && shouldDisplayCreateButton" :variant="order && order.sap_error ? 'yellow' : 'green'" @click="openCreateOrderModal" :disabled="sapStatus != 'full' || !validBusinessUnit">
              <span v-if="order && order.sap_error">Reintentar</span>
              <span v-else>Crear</span>
            </Button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Popovers -->
    <b-popover target="custom-total-info" placement="bottom" triggers="hover click">
      <span class="text-info">Haga clic en el número total para ingresar manualmente un total diferente. El cotizador calculará el descuento correcto para alcanzar ese total</span>
    </b-popover>

    <!-- Edit Modal -->
    <b-modal v-model="editModalShow" centered @hide="resetEditModal">
      <template #modal-title>
        <h5>Editar datos compartidos</h5>
      </template>
      <b-container class="pt-1 px-3 fs-14">
        <!-- status -->
        <b-row v-if="quote" align-h="center">
          <b-form-group label="Resultado" style="width: 300px">
            <b-radio-group v-model="form.status" stacked>
              <b-radio value="baja">Baja probabilidad</b-radio>
              <b-radio value="media">Media probabilidad</b-radio>
              <b-radio value="alta">Alta probabilidad</b-radio>
              <b-radio value="licitación">Licitación</b-radio>
              <b-radio v-if="quote.is_open" value="ignorar">Ignorar</b-radio>
            </b-radio-group>
          </b-form-group>
        </b-row>
        <b-row v-if="quote && quote.is_open" align-h="center">
          <b-form-group class="text-info" style="width: 300px">
            <small v-if="form.status === 'baja'">Baja probabilidad (menos de 50%)</small>
            <small v-if="form.status === 'media'">Media probabilidad (50 a 79%)</small>
            <small v-if="form.status === 'alta'">Alta probabilidad (más de 80%)</small>
            <small v-if="form.status === 'ignorar'">Ignorar esta cotización por cualquier razón. No se sincronizará con SAP</small>
            <small v-if="form.status === 'licitación'">Licitación Construcción</small>
          </b-form-group>
        </b-row>
        <!-- valid_until -->
        <b-row v-if="quote && quote.is_open" align-h="center" class="mb-1">
          <b-form-group label="Fecha de entrega" style="width: 300px">
            <flat-pickr
              v-model="form.valid_until"
              :config="flatpickrConfig"
              placeholder="Eligir fecha.."
              class="form-control form-control-sm white-flatpickr-input"
            ></flat-pickr>
          </b-form-group>
        </b-row>
        <!-- closed_on -->
        <b-row align-h="center" class="mb-1">
          <b-form-group label="Fecha cierre" style="width: 300px">
            <flat-pickr
              v-model="form.closed_on"
              :config="flatpickrConfig"
              placeholder="Eligir fecha.."
              class="form-control form-control-sm white-flatpickr-input"
            ></flat-pickr>
          </b-form-group>
        </b-row>
        <!-- billed_on -->
        <b-row align-h="center" class="mb-1">
          <b-form-group label="Fecha facturación" style="width: 300px">
            <flat-pickr
              v-model="form.billed_on"
              :config="flatpickrConfig"
              placeholder="Eligir fecha.."
              class="form-control form-control-sm white-flatpickr-input"
            ></flat-pickr>
          </b-form-group>
        </b-row>
        <!-- next_contact_on -->
        <b-row align-h="center" class="mb-1">
          <b-form-group label="Próximo contacto" style="width: 300px">
            <flat-pickr
              v-model="form.next_contact_on"
              :config="flatpickrConfig"
              placeholder="Eligir fecha.."
              class="form-control form-control-sm white-flatpickr-input"
            ></flat-pickr>
          </b-form-group>
        </b-row>
        <!-- business_unit -->
        <b-row v-if="quote && quote.is_open" align-h="center">
          <b-form-group label="Unidad negocio" class="mb-4" style="width: 300px">
            <b-select v-model="form.business_unit" :options="businessUnitOptions" size="sm">
              <template #first>
                <b-form-select-option :value="null" disabled>- Eligir una opción -</b-form-select-option>
              </template>
            </b-select>
          </b-form-group>

          <b-form-group v-if="showBusinessUnit2Options" label="Subnorma" class="mb-4" style="width: 300px">
            <b-select v-model="form.business_unit2" :options="businessUnit2Options" size="sm">
              <template #first>
                <b-select-option :value="null">- Eligir una opción -</b-select-option>
              </template>
            </b-select>
          </b-form-group>
        </b-row>
        <!-- drawing_version -->
        <b-row align-h="center">
          <b-form-group label="Versión plano" style="width: 300px">
            <b-select
              v-model="form.drawing_version"
              :options="drawingOptions"
              size="sm"
            >
              <template v-slot:first>
                <option :value="null" disabled>-- Eligir uno --</option>
              </template>
            </b-select>
          </b-form-group>
        </b-row>
        <!-- follow_up_notes -->
        <b-row align-h="center">
          <b-form-group label="Comentarios de seguimiento" style="width: 300px">
            <ValidationProvider rules="max:260" name="Comentarios de seguimiento" v-slot="{ errors }">
              <b-form-textarea
                id="followNotes1"
                ref="followNotes1"
                trim
                v-model="form.follow_up_notes"
                class="fs-14"
                :class="{ 'red-input-border': errors[0] }"
                placeholder="Comentarios se seguimiento de la cotización"
                rows="3"
                max-rows="4"
              />
              <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <!-- technician_notes -->
        <b-row align-h="center">
          <b-form-group label="Comentarios al técnico" style="width: 300px">
            <ValidationProvider rules="max:260" name="Comentarios al técnico" v-slot="{ errors }">
              <b-form-textarea
                id="techniciaNotes1"
                ref="techniciaNotes1"
                trim
                v-model="form.technician_notes"
                class="fs-14"
                :class="{ 'red-input-border': errors[0] }"
                placeholder="Comentarios para el técnico"
                rows="3"
                max-rows="4"
              />
              <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-row>
      </b-container>

      <template #modal-footer>
        <Button :disabled="showBusinessUnit2Options && !form.business_unit2" variant="green" class="float-right" @click="updateQuote">
          Guardar
        </Button>
      </template>
    </b-modal>

    <!-- Contact Data Modal -->
    <b-modal v-model="contactDataModalShow" centered @hide="clearContactData" hide-header hide-footer>
      <b-tabs class="fs-18" active-nav-item-class="font-weight-bold text-primary" justified v-model="bTabIndex">
        <b-tab title="Datos contacto">
          <b-container v-if="client && Object.keys(availableContactsData).length > 0">
            <b-row align-h="center">
              <b-form-group label="Persona de contacto:" label-size="sm" class="mb-5 mt-2" style="width: 300px">
                <b-select v-model="form.sap_contact_person_code" :options="contactPersonOptions" size="sm" @change="onContactPersonChange"></b-select>
              </b-form-group>
              <b-form-group v-if="client.email || contactEmailCondition" label-size="sm" class="mb-5" style="width: 300px">
                <template #label>
                  <span>Email:</span><span class="asterisk">*</span>
                </template>
                <b-radio-group v-model="contactForm.email" size="sm" stacked>
                  <b-radio v-if="client.email" :value="client.email.split(';')[0]">{{ client.email.split(';')[0] }}</b-radio>
                  <b-radio v-if="contactEmailCondition" :value="availableContactsData[form.sap_contact_person_code].email">
                    {{ availableContactsData[form.sap_contact_person_code].email }}
                  </b-radio>
                </b-radio-group>
              </b-form-group>
              <b-form-group v-if="client.phone || contactPhoneCondition" label-size="sm" class="mb-5" style="width: 300px">
                <template #label>
                  <span>Teléfono:</span><span class="asterisk">*</span>
                </template>
                <b-radio-group v-model="contactForm.phone" size="sm" stacked>
                  <b-radio v-if="client.phone" :value="client.phone">{{ client.phone }}</b-radio>
                  <b-radio v-if="contactPhoneCondition" :value="availableContactsData[form.sap_contact_person_code].phone">
                    {{ availableContactsData[form.sap_contact_person_code].phone }}
                  </b-radio>
                </b-radio-group>
              </b-form-group>
              <b-form-group v-if="client.cellular || contactCellularCondition" label="Celular:" label-size="sm" class="mb-5" style="width: 300px">
                <b-radio-group v-model="contactForm.cellular" size="sm" stacked>
                  <b-radio v-if="client.cellular" :value="client.cellular">{{ client.cellular }}</b-radio>
                  <b-radio v-if="contactCellularCondition" :value="availableContactsData[form.sap_contact_person_code].cellular">
                    {{ availableContactsData[form.sap_contact_person_code].cellular }}
                  </b-radio>
                  <b-radio :value="null">En blanco</b-radio>
                </b-radio-group>
              </b-form-group>
            </b-row>
            <b-row align-h="center" class="mt-5 mb-2">
              <Button variant="green" :disabled="!contactForm.email || !contactForm.phone" @click="updateContactData">Actualizar</Button>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab title="Datos dirección">
          <b-container>
            <b-row  align-h="center">
              <b-form-group label="Dirección cotización:" label-size="sm" class="mb-3 mt-2" style="width: 350px">
                <b-form-select v-model="form.address" :options="quoteAddressOptions" size="sm" @change="onQuoteAddressChange">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleccione una opción --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-table-simple small stacked>
                <b-tbody>
                  <b-tr class="fs-14">
                    <b-td stacked-heading="Nombre:" variant="primary">
                      <span v-if="addressForm.address">{{ addressForm.address }}</span>
                      <span v-else> - </span>
                    </b-td>
                    <b-td stacked-heading="Dirección:">
                      <span v-if="addressForm.street">{{ addressForm.street }}</span>
                      <span v-else> - </span>
                    </b-td>
                    <b-td stacked-heading="Provincia:">
                      <span v-if="addressForm.province">{{ filterProvinceAddress(addressForm.province) }}</span>
                      <span v-else> - </span>
                    </b-td>
                    <b-td stacked-heading="Distrito:">
                      <span v-if="addressForm.district">{{ addressForm.district }}</span>
                      <span v-else> - </span>
                    </b-td>
                    <b-td stacked-heading="Canton:">
                      <span v-if="addressForm.canton">{{ addressForm.canton }}</span>
                      <span v-else> - </span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
            <b-row align-h="center" class="mt-5 mb-2">
              <Button variant="green" :disabled="!addressForm.address" @click="updateAddressData">Actualizar</Button>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- Valid until Modal -->
    <!-- <b-modal v-model="validUntilModalShow" centered>
      <template #modal-title>
        <h5>Cotización valida hasta...</h5>
      </template>
      <b-row align-h="center">
        <flat-pickr
          v-model="form.valid_until"
          :config="flatpickrInlineConfig"
          class="d-none"
          ref="datepicker"
          @on-change="validUntilModalShow = false; updateQuote();"
        ></flat-pickr>
      </b-row>
      <template #modal-footer><span></span></template>
    </b-modal> -->

    <!-- Closing Modal -->
    <b-modal v-model="closingModalShow" centered>
      <template #modal-title>
        <h5>Cerrar esta cotización en SAP</h5>
      </template>
      <b-container fluid>
        <!-- alert -->
        <b-row>
          <b-col>
            <b-form-group class="mb-0">
              <template #label>
                <span class="font-weight-bold">Resultado</span><span class="asterisk">*</span>
              </template>
              <b-radio-group v-model="form.status" stacked class="ml-2">
                <b-radio value="desestimada">Desestimada</b-radio>
                <b-radio value="alternativa">Alternativa</b-radio>
                <b-radio value="vendido">Vendido</b-radio>
                <b-radio value="perdida">Perdida</b-radio>
              </b-radio-group>
            </b-form-group>
            <small
              v-if="!['desestimada', 'alternativa', 'vendido', 'perdida'].includes(form.status)"
              disabled
              class="text-secondary pl-2 fs-14"
            >
              Resultado actual: {{form.status}}
            </small>
            <b-form-group class="text-info mb-0 ml-2 fs-14">
              <span v-if="form.status === 'desestimada'">No es urgente, equipo funciona, lo va a dejar así</span>
              <span v-if="form.status === 'alternativa'">Ya compró una de las opciones</span>
              <span v-if="form.status === 'vendido'">Quedo abierta pero no se jalaron todas las líneas</span>
              <span v-if="form.status === 'perdida'">El trabajo lo hizo otra empresa o persona</span>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- closing notes -->
        <b-row>
          <b-col>
            <b-form-group >
              <template #label>
                <span class="font-weight-bold">Comentarios de cierre</span><span class="asterisk">*</span>
              </template>
              <b-textarea v-model="form.closing_notes" rows="4" size="sm" class="ml-2" autofocus placeholder="Notas de cierre de cotización"></b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- alert -->
        <b-row align-h="center">
          <b-col>
            <p class="text-danger text-center fs-14 mb-0">
              <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
              <span>El cierre de esta cotización es irreversible</span>
              <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
            </p>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <Button :disabled="!validClosingModalForm" variant="red" class="float-right" @click="closeQuote">
          Cerrar
        </Button>
      </template>
    </b-modal>

    <!-- Create Order modal -->
    <QuoteCreateOrderModal
      v-if="ready && client && quote"
      :quote-id="id"
      :order="order"
      :client="client"
      :quote-items="quoteItemList"
      :is-invalid-stock="isInvalidStock"
      @set-order="setOrderFromCreate"
      @close-quote="closeQuoteAfterOrderCreation"
      @modal-hidden="onHideCreateOrderModal"
    />

    <!-- Create Order modal -->
    <QuoteUpdateOrderModal
      v-if="ready && order && quote"
      :order="order"
      @modal-hidden="closeUpdateOrderModal"
      @set-order="setOrderFromUpdate"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, addDays } from 'date-fns'
import { normalize, schema } from 'normalizr'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import AccesosLogo from 'images/logos/accesos-w300.png'
import Button from '../shared/Button'
import ToastAlert from '../shared/ToastAlert'
import QuoteButtonBar from '../shared/QuoteButtonBar'
import QuoteItemSelector from '../shared/QuoteItemSelector'
import QuoteItemTable from './QuoteItemTable'
import QuoteNavTabs from './QuoteNavTabs'
import { businessUnits, businessUnits2 } from '../../sharedOptions/quoteBusinessUnits'
import { provinces} from '../../sharedOptions/provinces'
import QuoteCreateOrderModal from './QuoteCreateOrderModal'
import QuoteUpdateOrderModal from './QuoteUpdateOrderModal'

export default {
  name: 'QuoteShow',
  components: {
    flatPickr,
    ValidationProvider,
    Button,
    ToastAlert,
    QuoteButtonBar,
    QuoteItemSelector,
    QuoteItemTable,
    QuoteNavTabs,
    QuoteCreateOrderModal,
    QuoteUpdateOrderModal
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      quote: null,
      order: null,
      client: null,
      project: null,
      quoteItemData: {},
      quoteItemList: [],
      form: {
        title: '',
        notes: '',
        delivery_time: null,
        warranty_time: null,
        discount: 0,
        status: null,
        valid_until: null,
        closed_on: null,
        closing_notes: null,
        billed_on: null,
        next_contact_on: null,
        follow_up_notes: null,
        technician_notes: null,
        business_unit: null,
        business_unit2: null,
        drawing_version: null,
        print_count: null,
        sap_contact_person_code: null,
        address: null,
      },
      contactForm: {
        email: null,
        phone: null,
        cellular: null
      },
      addressForm: {
        address: null,
        street: null,
        province: null,
        district: null,
        canton: null
      },
      customTotalForm: {
        discount: null,
        total: null
      },
      isTaxExempt: false,
      editModalShow: false,
      businessUnitOptions: businessUnits,
      businessUnit2Options: businessUnits2,
      warrantyTimeOptions: [
        { value: null, text: '- Elegir uno -'},
        '1 mes',
        '2 meses',
        '3 meses',
        '6 meses',
        '12 meses',
        '18 meses',
        '24 meses'
      ],
      drawingOptions: [
        { value: 'Fotomontaje', text: '1-Fotomontaje' },
        { value: 'Dibujo 3D', text: '2-Dibujo 3D' },
        { value: 'Previstas eléctricas (estándar)', text: '3-Previstas eléctricas (estándar)' },
        { value: 'Planos únicamente de fabricación', text: '4-Planos únicamente de fabricación' },
        { value: 'Planos arquitectónicos', text: '5-Planos arquitectónicos' },
        { value: 'Planos eléctricos', text: '6-Planos eléctricos' },
        { value: 'Planos estructurales', text: '7-Planos estructurales' },
        { value: 'Planos para especificación', text: '8-Planos para especificación' },
        { value: 'Juego de Planos Completos', text: '9-Juego de Planos Completos' }
      ],
      sapErrorHash: {
        'Error 400: Invalid value  [OQUT.ShipToCode]' : 'SAP_ERROR: Dirección del cliente inválida'
      },
      processing: false,
      syncToSap: false,
      processingAddresses: false,
      togglingTax: false,
      pinging: false, // upon initiating pinging loop
      syncing: false, // upon change that triggers sync
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      ready: false,
      titleEdit: false,
      notesEdit: false,
      followNotesEdit: false,
      technicianNotesEdit: false,
      warrantyTimeEdit: false,
      deliveryTimeEdit: false,
      discountEdit: false,
      discountIsValid: true,
      customTotalEdit: false,
      customTotalValid: null,
      printCountEdit: false,
      printCountValid: null,
      validUntilDate: '',
      // validUntilModalShow: false,
      closingModalShow: false,
      contactDataModalShow: false,
      availableContactsData: {},
      availableAddressesData: {},
      flatpickrConfig: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        altInput: true,
        altFormat: 'd/m/Y',
        minDate: "today",
        disableMobile: true
      },
      flatpickrInlineConfig: { // always display open
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        inline: true,
        disableMobile: true,
        minDate: "today"
      },
      has_addresses: false,
      has_billing_notes: false,
      has_contacts: false,
      client_exonerated: false,
      bTabIndex: 0,
      provinceNames: provinces,
      taxCodes: {
        IV : 0.13,
        EXO : 0.0,
        EXEV : 0.0,
        IV1 : 0.01,
        IV2 : 0.02,
        IVA8 : 0.08
      }
    }
  },
  computed: {
    ...mapGetters(['isDev', 'isSupervisor']),
    isOpen () {
      return this.quote ? this.quote.is_open : false
    },
    priceList () {
      if (this.quote.pricing_mode === 'público') {
        return 'público'
      } else if (this.quote.pricing_mode === 'instalador') {
        return 'instalador'
      } else if (this.quote.pricing_mode === 'mayorista') {
        return 'mayorista'
      }
    },
    displayCRC: {
      get () {
        return this.$store.state.displayCRC
      },
      set (val) {
        this.$store.commit('setDisplayCRC', val)
      }
    },
    exchangeRate () { return this.quote.usd_crc_rate },
    quoteItems () {
      let array = this.quoteItemList.map(id => this.quoteItemData[id])
      return array.sort((a, b) => a.position - b.position)
    },
    lastSapLine () {
      let array = this.quoteItemList.map(id => this.quoteItemData[id])
      array = array.sort((a, b) => b.sap_line_num - a.sap_line_num)
      return array.length > 0 ? array[0].sap_line_num : -1
    },
    asesor () {
      return this.ready ? this.quote.user : this.$store.state.currentUser
    },
    // Dollars
    subtotal () {
      let subtotal = 0
      if (this.ready) {
        this.quoteItems.forEach(item => subtotal += this.listedPrice(item) * item.quantity)
      }
      return subtotal
    },
    discount () {
      return this.quote ? this.subtotal * (this.quote.discount / 100) : 0
    },
    discountPercent () {
      return this.quote ? this.quote.discount / 100 : 0
    },
    tax () {
      const totalTax = this.quoteItems.reduce((acum, val) => {
        const taxAmount = this.taxCodes[val.tax_code] || 0
        const ItemSubtotal = this.listedPrice(val) * val.quantity
        const ItemDiscount = ItemSubtotal * this.discountPercent
        let itemTax = (ItemSubtotal - ItemDiscount) * taxAmount
        return acum + itemTax
      }, 0)
      return totalTax
    },
    total () {
      return this.subtotal - this.discount + this.tax
    },
    // Colones
    subtotalCRC () {
      let subtotal = 0
      if (this.ready) {
        this.quoteItems.forEach(item => subtotal += this.listedPrice(item) * item.quantity)
      }
      return subtotal
    },
    discountCRC () {
      return this.quote ? this.subtotalCRC * (this.quote.discount / 100) : 0
    },
    taxCRC () {
      const totalTax = this.quoteItems.reduce((acum, val) => {
        const taxAmount = this.taxCodes[val.tax_code] || 0
        const ItemSubtotal = this.listedPrice(val) * val.quantity
        const ItemDiscount = ItemSubtotal * this.discountPercent
        let itemTax = (ItemSubtotal - ItemDiscount) * taxAmount
        return acum + itemTax
      }, 0)
      return totalTax
    },
    totalCRC () {
      return this.subtotalCRC - this.discountCRC + this.taxCRC
    },
    //
    accesosLogo () { return AccesosLogo },
    contactPersonOptions () {
      let array = []
      for (let [key, value] of Object.entries(this.availableContactsData)) {
        array.push({ text: value.name, value: key })
      }
      return array
    },
    quoteAddressOptions () {
      let array = []
      for (let [key, value] of Object.entries(this.availableAddressesData)) {
        array.push({ text: value.address, value: key })
      }
      return array
    },
    contactEmailCondition () {
      let clientEmail = this.client.email ? this.client.email.trim() : null
      if (this.form.sap_contact_person_code &&
      this.availableContactsData[this.form.sap_contact_person_code] &&
      this.availableContactsData[this.form.sap_contact_person_code].email &&
      this.availableContactsData[this.form.sap_contact_person_code].email.length >= 5 &&
      this.availableContactsData[this.form.sap_contact_person_code].email.trim() != clientEmail) {
        return true
      } else {
        return false
      }
    },
    contactPhoneCondition () {
      let clientPhone = this.client.phone ? this.client.phone.trim() : null
      if (this.form.sap_contact_person_code &&
      this.availableContactsData[this.form.sap_contact_person_code] &&
      this.availableContactsData[this.form.sap_contact_person_code].phone &&
      this.availableContactsData[this.form.sap_contact_person_code].phone.length >= 8 &&
      this.availableContactsData[this.form.sap_contact_person_code].phone.trim() != clientPhone) {
        return true
      } else {
        return false
      }
    },
    contactCellularCondition () {
      let clientCellular = this.client.cellular ? this.client.cellular.trim() : null
      if (this.form.sap_contact_person_code &&
      this.availableContactsData[this.form.sap_contact_person_code] &&
      this.availableContactsData[this.form.sap_contact_person_code].cellular &&
      this.availableContactsData[this.form.sap_contact_person_code].cellular.length >= 8 &&
      this.availableContactsData[this.form.sap_contact_person_code].cellular.trim() != clientCellular) {
        return true
      } else {
        return false
      }
    },
    maxSalesDiscount () {
      // return this.asesor.max_sales_discount ? this.asesor.max_sales_discount : 99
      return 15
    },
    baseValidationError () {
      if (this.ready) {
        if (!this.client.sap_gln) {
          return 'Este cliente no tiene GLN en SAP'
        } else if (!this.client.sap_valid) {
          if (this.client.sap_frozen && this.client.sap_frozen_remarks) {
            return this.client.sap_frozen_remarks
          } else {
            return 'Este cliente está inválido en SAP'
          }
        }
        if (!this.form.business_unit) return 'Debe de seleccionar una Unidad de Negocio'
        if (this.total == 0) return 'Total de la cotización no puede ser cero'
        if (!this.asesor.sap_sales_person_code) return 'A este asesor le falta un código de vendedor. Por favor hable con su compañero de TI'
        if (this.showBusinessUnit2Options && !this.form.business_unit2) return 'Debe de seleccionar subnorma'
        // if (this.asesor.country == 'panamá') return 'Asesores de Panamá no pueden sincronizar sus cotizaciones'
      }
      return false
    },
    fixableValidationError () {
      if (this.ready) {
        if (this.quote.status == 'ignorar') return 'Esta cotización está ignorada.  Nuevos cambios no se sincronizarán'
      }
      return false
    },
    pendingValidationError () {
      if (this.form.status == 'ignorar'|| this.total == 0) {
        return true
      } else {
        return false
      }
    },
    isValid () { // valid for syncing
      return this.baseValidationError || this.pendingValidationError ? false : true
    },
    sapStatus () {
      if (this.syncing) {
        return 'syncing'
      } else if (this.quote.status == 'ignorar') {
        return 'ignored'
      } else {
        if (this.quote.sap_doc_entry) {
          return this.quote.sap_is_synced ? 'full' : 'partial'
        } else {
          return 'pending'
        }
      }
    },
    showBusinessUnit2Options () {
      if (this.form.business_unit) {
        const subnorms= ['CA','PA','PK','DI','HZ','VV','PVT','CM','TG','CSC']
        return subnorms.includes(this.form.business_unit)
      }
      return false
    },
    validBusinessUnit() {
      if (this.showBusinessUnit2Options && !this.form.business_unit2) {
        return false
      } else {
        return true
      }
    },
    OrderClientValidation () {
      if (this.ready) {
        if (this.client.email && this.client.phone && this.client.phone_2 && this.has_addresses && this.has_contacts && this.has_billing_notes && this.validItemExoneration) {
          return true
        } else {
          return false
        }
      }
    },
    shouldDisplayCreateButton () {
      if (!this.ready) return false
      if (!this.order || (this.order && this.order.sap_error)) {
        return true
      }
      return false
    },
    isInvalidStock () { //TODO: CHECK IF PRODUCT IS VALID...
      if (this.quoteItems) {
        return this.quoteItems.some(val => val.product && val.quantity > val.product.stock && this.isStockEligible(val))
      } else {
        return false
      }
      return false
    },
    validItemExoneration () {
      if (!this.ready) { return false}
      if (this.client_exonerated) { return true }

      const hasExemptItems = this.quoteItems.map(item => {
        return item.tax_code
      }).some(code => code !== 'IV')
      return !hasExemptItems
    },
    validClosingModalForm () {
      if (!this.ready) { return false}
      const allowedClosingStatuses = ['desestimada', 'alternativa', 'vendido', 'perdida']
      const {status, closing_notes} = this.form
      if (allowedClosingStatuses.includes(status) && closing_notes) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route' (to, from) {
      this.id = Number(this.$route.params.id)
      this.fetchQuoteData()
    },
    'form.business_unit' (val, oldVal) {
      const subnorms= ['CA','PA','PK','DI','HZ','VV','PVT','CM','TG','CSC']
      if (!subnorms.includes(val)) {
        this.form.business_unit2 = null
      }
    }
  },
  mounted () {
    this.fetchQuoteData()
  },
  methods: {
    fetchQuoteData () {
      this.ready = false
      this.$http.get(`/api/production/quotes/${this.id}`)
        .then(response => {
          // console.log(response)
          const {
            created_at, title, notes, warranty_time, delivery_time, discount, status,
            valid_until, closed_on, closing_notes, billed_on, next_contact_on, follow_up_notes,
            technician_notes, business_unit, business_unit2, drawing_version, print_count,
            sap_contact_person_code, address, order, client, project, quote_items
          } = response.data.quote
          // load quote item data first because its reference gets deleted in loadQuote
          this.loadItemData(quote_items)
          this.quote = response.data.quote
          if (client) {
            this.client = client
            this.project = project
          }
          if (order) {this.order = order}
          // Format and set validUntilDate
          this.validUntilDate = format(addDays(new Date(created_at), 15), 'yyyy-LL-dd')
          // seed form!
          this.form.title = title
          this.form.notes = notes
          this.form.warranty_time = warranty_time
          this.form.delivery_time = delivery_time
          this.form.discount = discount
          this.form.status = status
          this.form.valid_until = valid_until
          this.form.closed_on = closed_on
          this.form.closing_notes = closing_notes
          this.form.billed_on = billed_on
          this.form.next_contact_on = next_contact_on
          this.form.follow_up_notes = follow_up_notes
          this.form.technician_notes = technician_notes
          this.form.business_unit = business_unit
          this.form.business_unit2 = business_unit2
          this.form.drawing_version = drawing_version
          this.form.print_count = print_count
          this.form.sap_contact_person_code = sap_contact_person_code
          this.form.address = address
          // Client validation
          const { has_addresses, has_contacts, has_billing_notes, client_exonerated } = response.data
          this.has_addresses = has_addresses
          this.has_contacts = has_contacts
          this.has_billing_notes = has_billing_notes
          this.client_exonerated = client_exonerated
          ////

          this.ready = true
          if (this.sapStatus == 'pending' || this.sapStatus == 'partial') {
            setTimeout(() => this.updateQuote(), 1000)
          }
          delete this.quote['order']
          delete this.quote['quote_items']
          delete this.quote['client']
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'Esa cotización no se pudo encontrar!'
          this.alertShow = true
        })
    },
    loadItemData (quoteItems) {
      const quoteItemData = normalize(
        { items: quoteItems },
        { items: [ new schema.Entity('items') ] }
      )
      if (quoteItemData.entities.hasOwnProperty('items')) { // in case of empty data
        this.quoteItemData = quoteItemData.entities.items
      } else {
        this.quoteItemData = {}
      }
      this.quoteItemList = quoteItemData.result.items
    },
    updateQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.id}`, {
          sync: this.isValid ? true : false,
          quote: this.form
        })
        .then(response => {
          // console.log(response)
          this.quote.title = response.data.title
          this.quote.notes = response.data.notes
          this.quote.warranty_time = response.data.warranty_time
          this.quote.delivery_time = response.data.delivery_time
          this.quote.status = response.data.status
          this.quote.valid_until = response.data.valid_until
          this.quote.closed_on = response.data.closed_on
          this.quote.billed_on = response.data.billed_on
          this.quote.next_contact_on = response.data.next_contact_on
          this.quote.follow_up_notes = response.data.follow_up_notes
          this.quote.technician_notes = response.data.technician_notes
          this.quote.business_unit = response.data.business_unit
          this.quote.business_unit2 = response.data.business_unit2
          this.quote.drawing_version = response.data.drawing_version
          this.quote.print_count = response.data.print_count
          this.quote.pricing_mode = response.data.pricing_mode
          //
          this.titleEdit = false
          this.notesEdit = false
          this.followNotesEdit = false
          this.technicianNotesEdit = false
          this.warrantyTimeEdit = false
          this.deliveryTimeEdit = false
          this.customTotalEdit = false

          if (!this.pinging && this.isValid) {
            this.syncing = true
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'Error al actualizar la cotización, por favor reintente !'
          this.alertShow = true
        })
        .finally(() => {
          this.processing = false
          this.editModalShow = false
        })
    },
    isStockEligible (quoteItem) {
      if (quoteItem.product && quoteItem.product.item_code) {
        return ['INS-', 'SERVICIO', 'SM-', 'SRVTA-', 'TRANSPORTE', 'ENCOM', 'FREIGHT', 'HORATECNICO', 'HORAYUDANT', 'PROGRAMA', 'PORTON', 'ELEMAX'].some(substring => quoteItem.product.item_code.toUpperCase().includes(substring)) ? false : true;
      } else {
        return true;
      }
    },
    // Closing quote only possible in UI if syncStatus is 'full' and quote is open and valid
    closeQuote () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.id}`, {
          sync: true,
          quote: {
            is_open: false,
            closed_on: new Date(),
            status: this.form.status,
            closing_notes: this.form.closing_notes
          }
        })
        .then(response => {
          // console.log(response)
          this.quote.is_open = response.data.is_open
          this.quote.status = response.data.status
          this.quote.closed_on = response.data.closed_on
          this.quote.closing_notes = response.data.closing_notes

          if (!this.pinging && this.isValid) {
            this.syncing = true
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
          this.alertShow = true
        })
        .finally(() => {
          this.processing = false
          this.closingModalShow = false
        })
    },
    saveTotal () {
      this.$http.patch(`/api/production/quotes/${this.id}/save_total`, {
        sync: this.isValid ? true : false,
        syncToSap: this.syncToSap ? true : false,
        quote: { total: this.displayCRC ? this.total / this.exchangeRate : this.total }
      })
      .then(response => {
        if (!this.pinging && this.isValid) {
          this.syncing = true
          setTimeout(() => this.ping(), 2500)
        }
      })
      .finally(this.syncToSap = false)
    },
    productPrice(price, price_currency) {
      if (!price || !price_currency) { return 0 }
      if (this.displayCRC) {
        return price_currency === 'COL' ? price : price * this.exchangeRate
      } else {
        return price_currency === 'COL' ? price / this.exchangeRate : price
      }
    },
    listedPrice (quoteItem) {
      if (quoteItem.line_type === 'text') { return 0 }
      if (quoteItem.is_custom) {
        let priceColones = quoteItem.custom_colones
        if (this.displayCRC && quoteItem.custom_colones == 0 && quoteItem.custom_dollars > 0) {
          priceColones = quoteItem.custom_dollars * this.exchangeRate
        }
        return this.displayCRC ? priceColones : quoteItem.custom_dollars
      } else {
        if (this.priceList === 'público') {
          return this.productPrice(quoteItem.retail_price, quoteItem.price_currency)
        } else if (this.priceList === 'instalador') {
          return this.productPrice(quoteItem.installer_price, quoteItem.installer_price_currency)
        } else if (this.priceList === 'mayorista') {
          return this.productPrice(quoteItem.wholesale_price, quoteItem.wholesale_price_currency)
        }
      }
    },
    ping () {
      console.log('Pinging sap status')
      this.pinging = true
      this.$http.get(`/api/production/quotes/${this.id}/fetch_sap_status`)
        .then(response => {
          // console.log(response.data)
          if (response.data.success) {
            this.quote.sap_is_synced = response.data.quote.sap_is_synced
            this.quote.sap_doc_entry = response.data.quote.sap_doc_entry
            this.quote.sap_doc_num = response.data.quote.sap_doc_num
            this.quote.sap_error = response.data.quote.sap_error
            this.pinging = false
            this.syncing = false
          } else {
            setTimeout(() => this.ping(), 2500)
          }
        })
        .catch(e => {
          console.log(e)
          this.pinging = false
          this.syncing = false
        })
    },
    resetEditModal () {
      this.form.status = this.quote.status
      this.form.valid_until = this.quote.valid_until
      this.form.closed_on = this.quote.closed_on
      this.form.closing_notes = this.quote.closing_notes
      this.form.billed_on = this.quote.billed_on
      this.form.next_contact_on = this.quote.next_contact_on
      this.form.follow_up_notes = this.quote.follow_up_notes
      this.form.technician_notes = this.quote.technician_notes
      this.form.business_unit = this.quote.business_unit
      this.form.business_unit2 = this.quote.business_unit2
      this.form.drawing_version = this.quote.drawing_version
    },
    resetNotes(){
      this.form.notes = this.quote.notes
      this.notesEdit = false
    },
    resetTechnicianNotes(){
      this.form.technician_notes = this.quote.technician_notes
      this.technicianNotesEdit = false
    },
    resetFollowNotes(){
      this.form.follow_up_notes = this.quote.follow_up_notes
      this.followNotesEdit = false
    },
    focus (formInput) {
      if (formInput) {
        this.$nextTick(() => {
          this.$refs[formInput].focus()
        })
      }
    },
    // openDatepicker () {
    //   if (this.quote.is_open) {
    //     this.validUntilModalShow = true
    //     this.$nextTick(() => this.$refs.datepicker.fp.open())
    //   }
    // },
    openEditInput (input) {
      if (!this.isOpen || !input) { return }
      if (input == 'title') {
        this.titleEdit = true
      } else if (input == 'warrantyTime') {
        this.warrantyTimeEdit = true
      } else if (input == 'notes') {
        this.notesEdit = true
      } else if (input == 'deliveryTime') {
        this.deliveryTimeEdit = true
      } else if (input == 'techniciaNotes') {
        this.technicianNotesEdit = true
      } else if (input == 'followNotes') {
        this.followNotesEdit = true
      }
      this.focus(input)
    },
    // Events
    copyQuote (quote) {
      this.order = null
      this.alertVariant = 'success'
      this.alertMessage = `Copia ${quote.quote_id} se ha creado`
      this.alertShow = true
    },
    emailQuote () {
      this.alertVariant = 'success'
      this.alertMessage = 'Cotización se ha enviado'
      this.alertShow = true
    },
    transferQuote (transferee) {
      this.quote.user = transferee
      this.quote.user_id = transferee.id
      this.alertVariant = 'success'
      this.alertMessage = `Cotización se ha transferido a ${transferee.first_name + ' ' + transferee.last_name}`
      this.alertShow = true
    },
    showAlertError () {
      this.alertVariant = 'danger'
      this.alertMessage = 'No funcionó'
      this.alertShow = true
    },
    addQuoteItem (item) {
      this.quoteItemList.push(item.id)
      this.$set(this.quoteItemData, item.id, item)
    },
    updateItem (item) {
      this.$set(this.quoteItemData, item.id, item)
    },
    //// Discount field
    focusDiscount () {
      if (this.quote.is_open) {
        this.discountEdit = true
        this.$nextTick(() => {
          this.$refs.discountInput.focus()
          this.$refs.discountInput.select()
        })
      }
    },
    updateDiscount () {
      if (this.form.discount == this.discountPercent * 100) {
        this.discountEdit = false
        return
      }
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.id}`, {
          sync: false,
          quote: { discount: this.form.discount }
        })
        .then(response => {
          // console.log(response)
          this.quote.discount = response.data.discount
          this.saveTotal()
          this.discountEdit = false
        })
        .catch(error => {
          this.alertShow = true
          this.alertVariant = 'danger'
          this.alertMessage = 'No funcionó'
        })
        .finally(this.processing = false)
    },
    //// Custom total field
    taxWithoutDiscount() {
      const noDiscountTax = this.quoteItems.reduce((acum, val) => {
        const taxAmount = this.taxCodes[val.tax_code] || 0
        const ItemSubtotal = this.listedPrice(val) * val.quantity
        const ItemDiscount = ItemSubtotal * 0
        let itemTax = (ItemSubtotal - ItemDiscount) * taxAmount
        return acum + itemTax
      }, 0)
      return noDiscountTax
    },
    focusTotal () {
      if (this.quote.is_open) {
        this.customTotalEdit = true
        this.customTotalForm.total = this.total.toFixed(2)
        this.$nextTick(() => {
          this.$refs.customTotal.focus()
          this.$refs.customTotal.select()
        })
      }
    },
    inputTotal () {
      if (this.customTotalForm.total && this.customTotalForm.total >= 0) {
        this.customTotalValid = null
        const tax = this.taxWithoutDiscount()
        if (this.customTotalForm.total > tax + this.subtotal) {
          // TODO: validation message?
          this.customTotalValid = false
        } else {
          const total = this.subtotal + tax
          const discountDollars = total - this.customTotalForm.total
          this.customTotalForm.discount = (discountDollars / total) * 100
          if (this.customTotalForm.discount > this.maxSalesDiscount) { this.customTotalValid = false }
        }
      } else  {
        this.customTotalValid = false
      }
    },
    updateTotal () {
      this.customTotalEdit = false
      if (this.customTotalForm.total && this.customTotalForm.total >= 0 && this.customTotalValid !== false && this.customTotalForm.discount) {
        if (this.processing) { return }
        this.processing = true
        this.$http.patch(`/api/production/quotes/${this.id}`, {
            sync: false,
            quote: {
              discount: this.customTotalForm.discount.toFixed(8)
            }
          })
          .then(response => {
            // console.log(response)
            this.form.discount = response.data.discount
            this.quote.discount = response.data.discount
            this.saveTotal()
          })
          .catch(e => {
            this.alertVariant = 'danger'
            this.alertMessage = 'No funcionó'
            this.alertShow = true
          })
          .finally(this.processing = false)
      }
    },
    //// Print count field
    focusPrintCount () {
      this.printCountEdit = true
      this.$nextTick(() => {
        this.$refs.printCountInput.focus()
        this.$refs.printCountInput.select()
      })
    },
    updatePrintCount () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.id}`, {
          sync: false,
          quote: { print_count: this.form.print_count }
        })
        .then(response => {
          // console.log(response)
          this.quote.print_count = response.data.print_count
          this.printCountEdit = false
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    // Contact Data
    openContactDataModal () {
      if (this.processing || !this.isOpen) { return }
      this.processing = true
      this.$http.get(`/api/production/clients/${this.client.id}/client_contacts`)
        .then(response => {
          // console.log(response)
          const data = normalize(
            { contacts: response.data },
            { contacts: [ new schema.Entity('contacts', {}, { idAttribute: 'sap_internal_code' }) ] }
          )
          if (data.entities.hasOwnProperty('contacts')) this.availableContactsData = data.entities.contacts
          this.contactForm.email = this.quote.contact_data['email']
          this.contactForm.phone = this.quote.contact_data['phone']
          this.contactForm.cellular = this.quote.contact_data['cellular']
          this.contactDataModalShow = true
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
        })
        this.fetchAddressData()
    },
    clearContactData () {
      this.availableContactsData = {}
      this.contactForm.email = null
      this.contactForm.phone = null
      this.contactForm.cellular = null
      this.availableAddressesData = {}
      this.addressForm.address = null
      this.addressForm.street = null
      this.addressForm.province = null
      this.addressForm.district = null
      this.addressForm.canton = null
    },
    updateContactData () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.id}`, {
          sync: this.form.sap_contact_person_code != this.quote.sap_contact_person_code && this.isValid ? true : false,
          quote: {
            sap_contact_person_code: this.form.sap_contact_person_code,
            contact_data: {
              name: this.availableContactsData[this.form.sap_contact_person_code].name,
              email: this.contactForm.email,
              phone: this.contactForm.phone,
              cellular: this.contactForm.cellular
            }
          }
        })
        .then(response => {
          // console.log(response)
          if (this.form.sap_contact_person_code != this.quote.sap_contact_person_code) {
            this.quote.sap_contact_person_code = response.data.sap_contact_person_code
            if (!this.pinging && this.isValid) setTimeout(() => this.ping(), 2500)
          }
          this.quote.contact_data = response.data.contact_data
          this.contactDataModalShow = false
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    onContactPersonChange () {
      if (this.contactForm.email != this.client.email.split(';')[0]) this.contactForm.email = null
      if (this.contactForm.phone != this.client.phone) this.contactForm.phone = null
      if (this.contactForm.cellular != this.client.cellular) this.contactForm.cellular = null
    },
    // Address Data
    fetchAddressData() {
      if (this.processingAddresses) { return }
      this.processingAddresses = true
      this.$http.get(`/api/production/clients/${this.client.id}/client_addresses`)
        .then(response => {
          // console.log(response)
          const data = normalize(
            { addresses: response.data },
            { addresses: [ new schema.Entity('addresses', {}, { idAttribute: 'address' }) ] }
          )
          if (data.entities.hasOwnProperty('addresses')) this.availableAddressesData = data.entities.addresses
          if(this.quote.address){
            this.form.address = this.quote.address
            this.addressForm.address =  this.availableAddressesData[this.quote.address].address
            this.addressForm.street =  this.availableAddressesData[this.quote.address].street
            this.addressForm.province =  this.availableAddressesData[this.quote.address].province
            this.addressForm.district =  this.availableAddressesData[this.quote.address].district
            this.addressForm.canton =  this.availableAddressesData[this.quote.address].canton
          }
        })
        .catch(e => console.log(e))
        .finally(this.processingAddresses = false)
    },
    updateAddressData () {
      if (this.processing) { return }
      this.processing = true
      this.$http.patch(`/api/production/quotes/${this.id}`, {
          sync: this.form.address != this.quote.address && this.isValid ? true : false,
          quote: {
            address: this.addressForm.address
          }
        })
        .then(response => {
          // console.log(response)
          if (this.form.address != this.quote.address) {
            this.quote.address = response.data.address
            if (!this.pinging && this.isValid) setTimeout(() => this.ping(), 2500)
          }
          this.contactDataModalShow = false
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    },
    onQuoteAddressChange (item) {
      this.addressForm.address =  this.availableAddressesData[item].address
      this.addressForm.street =  this.availableAddressesData[item].street
      this.addressForm.province =  this.availableAddressesData[item].province
      this.addressForm.district =  this.availableAddressesData[item].district
      this.addressForm.canton =  this.availableAddressesData[item].canton
    },
    filterProvinceAddress(value) {
      if (['1','2','3','4','5','6','7'].includes(value)){
        const name = this.provinceNames.find(word => word.value == value);
        return name.text
      } else {
        return 'N/A'
      }
    },
    // CreateOrder
    openCreateOrderModal () {
      // this.searchSapQuotes = false
      this.$bvModal.show('create-order-modal')
    },
    onHideCreateOrderModal () {
      // this.searchTerm = ''
      this.$bvModal.hide('create-order-modal')
    },
    setOrderFromCreate (newOrder) {
      this.order = {
        sap_doc_entry: newOrder.sap_doc_entry,
        sap_doc_num: newOrder.sap_doc_num,
        status_name: newOrder.status_name,
        sap_error: newOrder.sap_error,
        sap_series: newOrder.sap_series,
        started_on: newOrder.started_on,
        due_on: newOrder.due_on,
        maintenance_contracts: newOrder.maintenance_contracts,
        deliver_to: newOrder.deliver_to,
        install_o:  newOrder.install_on,
        is_special_project: newOrder.is_special_project,
        destination_code: newOrder.destination_code,
        destination_name: newOrder.destination_name,
        technical_instructions: newOrder.technical_instructions
      }
    },
    // updateOrder
    openUpdateOrderModal () {
      this.$bvModal.show('update-order-modal')
    },
    closeUpdateOrderModal () {
      this.$bvModal.hide('update-order-modal')
    },
    setOrderFromUpdate (newOrder) {
      this.order.status_name = newOrder.status_name
      this.order.sap_error = newOrder.sap_error
    },
    closeQuoteAfterOrderCreation () {
      this.quote.is_open = false
    }
  },
}
</script>

<style scoped>
.brand-logo {
  width: 200px;
  height: 70px;
}
#quote-guarantee-section p {
  margin-bottom: .25em;
}
#quote-total-box {
  padding-right: 40px;
}
#quote-total-box .second-col {
  width: 120px;
}
#quote-total-box .third-col {
  width: 150px;
}
#quote-total-box p, #quote-total-box span {
  font-size: 18px;
}
#quote-sigbox {
  border: 2px solid #aaa;
}
#quote-sigbox .asesor-box {
  border-right: 1px solid #6c757d;
  padding-bottom: 0;
}
#quote-sigbox .valid-until-box {
  border-left: 1px solid #6c757d;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .brand-logo {
    width: 120px;
    height: 40px;
  }
  #quote-total-box {
    padding-right: 5px;
  }
  #quote-total-box .second-col {
    width: 100px;
  }
  #quote-total-box .third-col {
    width: 125px;
  }
  #quote-total-box p, #quote-total-box span {
    font-size: 16px;
  }
  #quote-sigbox .asesor-box {
    border-right: 0;
    border-bottom: 1px solid #6c757d;
    padding-bottom: 1em;
  }
  #quote-sigbox .delivery-box {
    padding: 1em 0;
  }
  #quote-sigbox .valid-until-box {
    border-left: 0;
    border-top: 1px solid #6c757d;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
#quote-sigbox-accept {
  padding: 0.5em 0;
  background-color: #ddd;
}
.label-size-sm {
  font-size: 11px;
}
.label-size-md {
  font-size: 12px;
}
.label-size-lg {
  font-size: 14px;
}
.fill-quote-button {
  height: 2em;
  display: inline-flex;
  align-items: center;
}
.fill-quote-button span {
  font-size: 12px;
}
#right-sidebar tr {
  height: 35px;
}
#right-sidebar tr td {
  vertical-align: middle;
}
</style>
