<template>
  <div class="d-inline">
    <Button size="sm" @click="openModal">
      <b-spinner v-if="loading" small type="grow" class="text-muted mr-2"></b-spinner>
      <span :class="{ 'text-muted': loading }">Cantidad ATP</span>
    </Button>

    <b-modal v-model="modalShow" size="lg" centered hide-footer scrollable @hide="reset">
      <template #modal-title>
        <span v-if="item" class="text-secondary">{{ item.item_code }}: Cantidad ATP</span>
      </template>
      <b-row align-h="center">
        <b-col>
          <div v-if="loading" class="text-dark text-center py-5 fs-14">
            <b-spinner small type="grow"></b-spinner>
            <span class="pl-2">Consultando SAP...</span>
          </div>
          <div v-else>
            <b-table-simple v-if="orders.length > 0" sticky-header="500px" small>
              <b-thead head-variant="dark" class="fs-10">
                <b-tr>
                  <b-th class="text-left" style="width: 6.30rem;"># Orden</b-th>
                  <b-th >Acreedor</b-th>
                  <b-th class="text-center" style="min-width: 60px; max-width: 60px; word-wrap: break-word; white-space: normal">Fecha del pedido</b-th>
                  <b-th class="text-center" style="min-width: 60px; max-width: 60px; word-wrap: break-word; white-space: normal">Fecha de entrega</b-th>
                  <b-th class="text-center">Solicitado</b-th>
                  <b-th class="text-center">Comprometido</b-th>
                  <b-th class="text-center">Almacén</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="order in orders" :key="order.doc_entry" class="fs-12">
                  <b-td>
                    <span v-if="order.quantity > 0">PO </span>
                    <span v-else>OR </span>
                    {{ order.doc_num}}
                  </b-td>
                  <b-td>{{ order.card_name }}</b-td>
                  <b-td class="text-center">{{ order.doc_date | formatDateCompact }}</b-td>
                  <b-td class="text-center">{{ order.ship_date | formatDateCompact }}</b-td>
                  <b-td class="text-center">
                    <span v-if="order.quantity > 0" class="text-success"> {{ order.quantity }} </span>
                    </b-td>
                      <b-td class="text-center">
                        <span v-if="order.quantity < 0" class="text-danger"> {{ Number(Math.abs(order.quantity)) }} </span>
                      </b-td>
                  <b-td class="text-center">{{ order.warehouse_code }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div v-else-if="pendingResult" class="text-center pb-5">
              <p class="text-danger fs-14">La operación tardó más de lo esperado</p>
              <Button class="mt-2" @click="ping">Intentarlo de nuevo</Button>
            </div>
            <p v-else class="text-danger text-center fs-14">Este artículo no tiene ninguna orden pendiente</p>
            <!-- Dev only timestamp -->
            <small v-if="isDev && timestamp" class="text-info float-right pr-2">
              Última actualización {{ timestamp | formatDateTime }}
            </small>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '../shared/Button'

export default {
  name: 'PurchaseOrderDataWidget',
  components: { Button },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      purchaseOrders: [],
      modalShow: false,
      timestamp: null,
      loading: false,
      processing: false,
      retryPingCount: 0,
      pendingResult: false
    }
  },
  computed: {
    ...mapGetters (['isDev']),
    orders() {
      if (this.purchaseOrders){
        return this.purchaseOrders.sort((a, b) => a.doc_num - b.doc_num)
      } else {
        return []
      }
    }
  },
  methods: {
    openModal () {
      if (this.loading) { return }
      this.loading = true
      this.modalShow = true
      this.$http.get(`/api/production/products/${this.item.id}/fetch_purchase_orders`, {
        params: {
          fetch_sap: true
        }
      })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            this.setData(res.data)
            this.loading = false
            this.retryPingCount = 0
          } else {
            setTimeout(() => this.ping(), 4000)
          }
        })
        .catch(e => console.log(e))
    },
    ping () {
      if (this.processing) { return }
      this.processing = true
      this.loading = true
      console.log('Pinging purchase orders')
      this.$http.get(`/api/production/products/${this.item.id}/fetch_purchase_orders`, {
        params: {
          fetch_sap: this.retryPingCount == 5 ? true : false
        }
      })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            this.setData(res.data)
            this.loading = false
            this.pendingResult = false
          } else {
            this.retryPingCount+=1
            if (this.retryPingCount == 10) {
              this.loading = false
              this.pendingResult = true
              this.retryPingCount = 0
              return
            }
            setTimeout(() => this.ping(), 5000)
          }
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.processing = false
        })
    },
    setData (result) {
      this.purchaseOrders = result.purchase_orders
      this.timestamp = result.timestamp
    },
    reset () {
      this.purchaseOrders = []
      this.timestamp = null
      this.loading = false
      this.processing = false
      this.pendingResult = false
    }
  }
}
</script>
