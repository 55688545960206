<template>
    <div>
      <PurchaseRequestNavsTabs/>
      <ContentView>
        <Subheader>
          <span>Solicitudes de Compra</span>
        </Subheader>

        <!-- search Bar -->
        <b-row class="pl-2">
          <b-form-group style="width: 290px">
            <b-input-group size="sm">
              <b-input
                v-model="searchTerm"
                autofocus
                placeholder="Buscar por cliente, # interno"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" size="sm" @click="search">
                  <font-awesome-icon :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group class="ml-3">
            <b-button size="sm" @click="resetSearch" :variant="resetButtonVariant">
              <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
            </b-button>
          </b-form-group>
        </b-row>

        <br>

        <!-- Table caption -->
        <b-row align-h="between" align-v="center" class="mb-1">
          <b-col>
            <div v-if="count" class="fs-12">
              <span v-if="queryMode" class="text-muted"><b>{{ count.toLocaleString() }}</b> resultados</span>
              <span v-else class="text-info"> <b>{{ count.toLocaleString() }}</b> Solicitudes de Compra en total</span>
            </div>
          </b-col>
          <b-col class="text-right pr-0">
            <b-checkbox v-model="hideClosed" size="sm" inline>
              Ocultar Cerradas
            </b-checkbox>
          </b-col>
        </b-row>

        <!-- Table data -->
        <b-table
          :fields="fields"
          :items="purchaseRequests"
          head-variant="dark"
          bordered
          hover
          responsive
          show-empty
          no-local-sorting
          :sort-desc.sync="sortDesc"
          class="text-center fs-14"
          @row-clicked="onRowClick"
          @sort-changed="sortColumn"
        >

          <!-- Loading spinner / empty search text -->
          <template #empty>
            <div v-if="loading" class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
            <div v-else class="text-center text-danger">
              <span>No hay resultados</span>
            </div>
          </template>

          <!-- Table data -->
          <template v-slot:cell(purchase_internal_id)="data">
            <span>{{ data.value}}</span>
          </template>

          <template v-slot:cell(user)="data">
            <span>{{ data.value | concatName2 | titleize }}</span>
          </template>

          <template v-slot:cell(client_name)="data">
            <span class="text-uppercase font-weight-bold fs-13">{{ data.value }}</span>
          </template>

          <template v-slot:cell(purchase_type)="data">
            <span v-if="data.value == '01'"><b-badge variant="primary">Proyecto Nacional</b-badge></span>
            <span v-else-if="data.value == '02'"><b-badge variant="warning">Servicio Nacional</b-badge></span>
            <span v-else-if="data.value == '03'"><b-badge variant="warning">Servicio Internacional</b-badge></span>
            <span v-else-if="data.value == '04'"><b-badge variant="secondary">Stock Local</b-badge></span>
            <span v-else-if="data.value == '05'"><b-badge variant="success">Activo Fijo</b-badge></span>
            <span v-else-if="data.value == '06'"><b-badge variant="primary">Proyecto Internacional</b-badge></span>
            <span v-else-if="data.value == '07'"><b-badge variant="warning">Mantenimiento Edificio</b-badge></span>
            <span v-else-if="data.value == '09'"><b-badge variant="secondary">Stock Internacional</b-badge></span>
            <span v-else>{{ data.value}}</span>
          </template>

          <template v-slot:cell(is_open)="data">
            <span v-if="data.value" class="fs-9"><b-badge pill class="text-success" variant="light">Abierto</b-badge></span>
            <span v-else class="fs-9"><b-badge pill class="text-danger" variant="light">Cerrado</b-badge></span>
          </template>

          <template v-slot:cell(created_at)="data">
            <span class="text-center">{{ data.value | formatDate }}</span>
          </template>
        </b-table>

        <!-- Pagination -->
        <b-row align-h="center" class="mt-5">
          <PaginationCountless :page="page" :next="next" :prev="prev">
            <template #prev-label>
              <font-awesome-icon :icon="['fas', 'backward']" size="lg" fixed-width />
            </template>
            <template #next-label>
              <font-awesome-icon :icon="['fas', 'forward']" size="lg" fixed-width />
            </template>
          </PaginationCountless>
        </b-row>

      </ContentView>
    </div>
</template>

<script>
import ContentView from '../../shared/ContentView.vue';
import PurchaseRequestNavsTabs from './PurchaseRequestNavsTabs.vue';
import Subheader from '../../shared/Subheader';
import PaginationCountless from '../../shared/PaginationCountless.vue';

export default {
  name: 'PurchaseRequestIndex',
  components: { ContentView, PurchaseRequestNavsTabs, Subheader, PaginationCountless },
  data () {
    return {
      purchaseRequests: [],
      page: 1,
      next: null,
      prev: null,
      count: 1,
      searchTerm: '',
      HideServiceDocuments: false,
      HideItemsDocuments: false,
      loading: false,
      noScroll: false,
      queryMode: false,
      sortDesc: true,
      hideClosed: true,
      fields: [
        { key: 'purchase_internal_id', label: 'Número interno', tdClass: 'clickable', thStyle: { width: '130px' } },
        { key: 'client_name', label: 'Proveedor', tdClass: 'clickable', thStyle: { width: '300px' } },
        { key: 'purchase_type', label: 'Tipo de Solicitud', tdClass: 'clickable', thStyle: { width: '100px' } },
        { key: 'user', label: 'Solicitante', tdClass: 'clickable', thStyle: { width: '80px' } },
        { key: 'is_open', label: 'Status', tdClass: 'clickable', thStyle: { width: '80px' } },
        { key: 'created_at', label: 'Creado en', sortable: true, tdClass: 'clickable text-right', thClass: 'text-right', thStyle: { width: '100px' } },
      ]

    }
  },
  computed: {
    resetButtonVariant(){
      if(this.queryMode || this.searchTerm){
        return 'danger'
      } else {
        return 'secondary'
      }
    }
  },
  watch: {
    '$route.query' (to, from) {
      this.fetchPurchaseRequest();
    },
    hideClosed(to, from) {
      this.noScroll = true;
      this.fetchPurchaseRequest();
    }
  },
  mounted () {
    this.noscroll = true
    this.fetchPurchaseRequest()

  },
  methods: {
    fetchPurchaseRequest () {
      this.loading = true;
      this.$http.get('/api/production/purchase_requests', {
          params: {
            page: this.$route.query.pagina,
            q: this.searchTerm,
            sort_desc: this.sortDesc,
            hide_closed: this.hideClosed
          }
        })
        .then(response => {
          console.log(response)
          this.purchaseRequests = response.data.purchase_requests
          this.page = response.data.pagy.page
          this.next = response.data.pagy.next
          this.prev = response.data.pagy.prev
          this.count = response.data.pagy.count
          if (!this.noScroll) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
          this.noScroll = false;
        });
    },
    search () {
      if (this.processing) { return }
      this.processing = true;
      let query = Object.assign({}, this.$route.query);
      delete query.pagina;
      this.$router.replace({ query })
        .catch( (error)=>{
          if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
            console.log(error)
          }
        })
      // this.sortBy = 'created_at';
      this.sortDesc = true;
      this.$http.get('/api/production/purchase_requests', {
          params: {
            q: this.searchTerm.trim(),
            sort_desc: this.sortDesc
          }
        })
        .then(response => {
          this.queryMode = true;
          console.log(response)
          this.purchaseRequests = response.data.purchase_requests
          this.page = response.data.pagy.page;
          this.next = response.data.pagy.next;
          this.prev = response.data.pagy.prev;
          this.count = response.data.pagy.count;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        });
    },
    resetSearch () {
      this.queryMode = false;
      this.searchTerm = '';
      this.sortDesc = true;
      this.$router.push('/app/prod/solicitudes')
        .catch( (error)=>{
          if(error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')){
            console.log(error);
          }
        });;
      this.fetchPurchaseRequest();
    },
    sortColumn(ctx) {
      if (ctx.sortBy) {
        this.noScroll = true;
        this.sortDesc = ctx.sortDesc;
        this.fetchPurchaseRequest();
      }
    },
    onRowClick (item) {
      this.$router.push(`/app/prod/solicitud/${item.id}`);
    }
  }
}
</script>
