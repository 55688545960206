import Vue from 'vue';

Vue.filter('concatName4', function (obj) {
  if (obj) {
    let name = obj.first_name
    if (obj.second_name) {
      name = name + ' ' + obj.second_name;
    }
    name = name + ' ' + obj.last_name;
    if (obj.last_name2) {
      name = name + ' ' + obj.last_name2;
    }
    return name;
  }
})
