import Vue from 'vue'
import { format, isToday, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

// Used in TechnicianBoard.vue
Vue.filter('formatDateTab', function (value) {
  if (value) {
    let date = parseISO(String(value).trim())
    if (isToday(date)) {
      return format(date, "d MMM", { locale: es })
    } else {
      return format(date, "EEE, d MMM", { locale: es })
    }
  }
})
