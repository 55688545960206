<template>
  <div class="pb-5">
    <NavTabs />
    <TopShelf>
      <template #action>
        <BackButton to="/app/rrhh/inactivos" />
      </template>
      <template #header>
        <div>
          <span v-if="user" class="name">{{ user | concatName4 }}</span>
        </div>
      </template>
      <template #side-content>
        <div>
          <b-badge v-if="user && user.status == 'inactivo'" variant="danger">
            Inactivo
          </b-badge>
        </div>
      </template>
    </TopShelf>

    <PanelSection v-if="user && (isHrAdmin || isDev)">
      <template #title>Info</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr>
            <td class="label">Carnet:</td>
            <td>{{ user.carnet }}</td>
          </tr>
          <tr>
            <td class="label">Cedula:</td>
            <td>{{ user.cedula }}</td>
          </tr>
          <tr>
            <td class="label">Fecha de ingreso:</td>
            <td>{{ user.start_date | formatDate }}</td>
          </tr>
          <tr class="text-danger">
            <td class="label">Desactivado en:</td>
            <td v-if="user.status == 'inactivo'">
              <span>{{ user.locked_at | formatDate }}</span>
              <Button variant="green" size="sm" class="ml-3" @click="reactivateModalShow = true">
                <span>Reactivar</span>
              </Button>
            </td>
            <td v-if="user.status == 'activo'">
              <span class="text-success">Reactivado</span>
            </td>
          </tr>
        </table>
      </template>
    </PanelSection>

    <PanelSection v-if="user">
      <template #title>Rol</template>
      <template #content>
        <table class="table table-borderless table-show table-sm">
          <tr>
            <td class="label">Puesto:</td>
            <td>{{ user.job_title }}</td>
          </tr>
          <tr>
            <td class="label">Departamento:</td>
            <td>{{ user.department | titleize }}</td>
          </tr>
          <tr>
            <td class="label">Gerencia:</td>
            <td>{{ user.division }}</td>
          </tr>
        </table>
      </template>
    </PanelSection>

    <!-- Reactivate Modal -->
    <b-modal v-model="reactivateModalShow" centered hide-footer title="Reactivar empleado" @hide="reset">
      <ValidationObserver v-slot="{ invalid }">
        <b-row align-h="center">
          <ValidationProvider rules="required|email" name="Correo Electrónico" v-slot="{ valid, errors }">
            <b-form-group label-size="sm" :invalid-feedback="errors[0]" style="width: 300px">
              <template #label>
                <span>Correo Electrónico</span><span class="asterisk">*</span>
              </template>
              <b-input v-model="form.email" size="sm" autofocus type="email" :state="errors[0] ? false : (valid ? true : null)" />
            </b-form-group>
          </ValidationProvider>
        </b-row>
        <b-row v-if="validationMessage" align-h="center">
          <small class="text-danger">{{ validationMessage }}</small>
        </b-row>
        <div class="text-center mt-5">
          <Button variant="green" :disabled="invalid" @click="reactivateUser">
            Guardar
          </Button>
        </div>
      </ValidationObserver>
    </b-modal>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BackButton from '../shared/BackButton'
import Button from '../shared/Button'
import PanelSection from '../shared/PanelSection'
import ToastAlert from '../shared/ToastAlert'
import TopShelf from '../shared/TopShelf'
import NavTabs from './NavTabs'

export default {
  name: 'InactiveEmployeeShow',
  components: { ValidationObserver, ValidationProvider, BackButton, Button, PanelSection, ToastAlert, TopShelf, NavTabs },
  data() {
    return {
      id: Number(this.$route.params.id),
      user: null,
      reactivateModalShow: false,
      validationMessage: '',
      form: {
        email: ''
      },
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      processing: false
    }
  },
  computed: {
    ...mapGetters(['isHrAdmin', 'isDev']),
  },
  mounted() {
    this.$http.get(`/api/human_resources/users/${this.$route.params.id}`)
      .then(res => {
        console.log(res)
        this.user = res.data
        if (this.$route.params.deactivated) {
          this.alertVariant = 'success'
          this.alertMessage = 'Empleado se desactivó con éxito'
          this.alertShow = true
        }
      })
      .catch(error => {
        console.log(error)
        this.alertVariant = 'danger'
        this.alertMessage = 'Algo no funcionó'
        this.alertShow = true
      })
  },
  methods: {
    reactivateUser () {
      if (this.processing) { return }
      this.processing = true
      this.$http.put(`/api/human_resources/users/${this.id}`, {
          status: 'activo',
          email: this.form.email,
          locked_at: null
        })
        .then((res) => {
          console.log(res)
          this.user = res.data
          this.reactivateModalShow = false
        })
        .catch(e => {
          console.log(e)
          if (e.response.data && e.response.data.errors) {
            this.validationMessage = e.response.data.errors[0]
          }
        })
        .finally(this.processing = false)
    },
    reset () {
      this.form.email = ''
      this.validationMessage = ''
    }
  }
}
</script>

<style scoped>
.label {
  width: 150px;
  color: #596981;
}
</style>
