<template>
  <!-- TODO: reorder columns in mobile to show title and action slots at top -->
  <b-row align-h="center" class="mx-0 px-2 px-sm-0">
    <b-col lg="8">
      <b-row class="panel-section">
        <b-col lg="3">
          <div class="panel-section-title pb-4 pb-sm-0">
            <slot name="title"></slot>
          </div>
        </b-col>
        <b-col lg="7">
          <slot name="content"></slot>
        </b-col>
        <b-col lg="2" class="text-right">
          <slot name="action"></slot>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'PanelSection'
}

</script>

<style scoped>
.panel-section {
  padding: 2em 0;
  border-bottom: 1px solid #f2f2f2;
}
.panel-section-title {
  font-size: 18px;
  color: #163D7D;
}
</style>