<template>
  <div>
    <b-row>
      <b-col>
        <small class="text-center text-secondary ml-2">Documentos: xlsx/csv/pdf</small>
        <div id="uppy-target">
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3 from '@uppy/aws-s3'
import Spanish from '@uppy/locales/lib/es_ES'

export default {
  name: 'FileUploader',
  data () {
    return {
      newFileForm: {
        file: null,
        name: null
      },
      processing: false,
      locale: Spanish
    }
  },
  mounted () {
    this.locale.strings.browse = 'Permitidos: 1'
    const uppy = Uppy({
      id: 'file-uploader',
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 10000000, //can change!
        maxNumberOfFiles: 1, //can change!
        minNumberOfFiles: 1,
        allowedFileTypes: ['.xlsx', '.pdf']
      },
      locale: Spanish
    })
    .use(Dashboard, {
      inline: true,
      target: '#uppy-target',
      height: 50,
      width: 200,
      thumbnailWidth: 350,
      thumbnailType: 'pdf/xlsx',
      showProgressDetails: true,
      hideProgressAfterFinish: false,
      showLinkToFileUploadResult: false,
      note: "Sólo archivos de xlsx/pdf",
      disableStatusBar: false,
      disableInformer: false,
      disableThumbnailGenerator: false,
      proudlyDisplayPoweredByUppy: false,
      locale: Spanish,
      showRemoveButtonAfterComplete: true,
      doneButtonHandler: () => {
        uppy.reset()
      }
    })
    .use(AwsS3, {
      companionUrl: '/'
    })
    uppy.on('upload-success', (file, response) => {
      // construct uploaded file data in the format that Shrine expects
      var uploadedFileData = JSON.stringify({
        id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      })
      // console.log('Uploaded construct File Data:')
      // console.log(uploadedFileData)
      this.newFileForm.file = uploadedFileData
      this.newFileForm.name = file.name
      this.addFile()

      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      // this.hiddenInputTarget.value = uploadedFileData

      // this.spinnerTarget.classList.remove('d-none')
    })
    // uppy.on('thumbnail:generated', (file, preview) => {
    //   console.log('Thumbnail generated event')
    //   console.log('preview',preview)
    //   // this.$emit('preview-generated', preview)
    // })
    uppy.on('file-removed', (file, reason) => {
      if (reason === 'removed-by-user') {
        this.removeFile(file);
      }
    })
    uppy.on('complete', (result) => {
      // uppy.reset()  // Seems to break the thumbnail preview img link
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    })
  },
  methods: {
    addFile () {
      this.$emit('file-added', this.newFileForm)
      // this.newFileForm.file = null
      // this.newFileForm.name = null
    },
    removeFile () {
      this.$emit('file-removed', this.newFileForm)
      this.newFileForm.file = null
      this.newFileForm.name = null
    }
  }
}
</script>