import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "customRadio", "customInput", "customInputArea", "validationText", "validationIcon", "leftLabel",
    "rightLabel", "selectedValue" ];

  connect() {
    var selectedValue = this.selectedValueTarget.textContent;
    if (selectedValue == 'Interno') {
      this.hideCustomInputArea();
      this.rightLabelTargets.forEach(el => {
        el.textContent = 'Interior';
      });
      this.leftLabelTargets.forEach(el => {
        el.textContent = 'Exterior';
      });
    } else if (selectedValue == 'Externo') {
      this.hideCustomInputArea();
      this.rightLabelTargets.forEach(el => {
        el.textContent = 'Exterior';
      });
      this.leftLabelTargets.forEach(el => {
        el.textContent = 'Interior';
      });
    } else {
      this.customRadioTarget.checked = true;
      this.customRadioTarget.value = selectedValue;
      this.customInputTarget.value = selectedValue;
      if (this.customInputTarget.value) {
        this.validationTextTarget.classList.add('d-none');
      } else {
        this.validationIconTarget.classList.remove('d-none');
      }
      this.showCustomInputArea();
      this.rightLabelTargets.forEach(el => {
        el.textContent = this.customInputTarget.value;
      });
      this.leftLabelTargets.forEach(el => {
        el.textContent = '';
      });
    }
  }

  setLabel(event) {
    this.validationIconTarget.classList.add('d-none');
    if (event.currentTarget.value == 'Interno') {
      this.hideCustomInputArea();
      this.rightLabelTargets.forEach(el => {
        el.textContent = 'Interior';
      });
      this.leftLabelTargets.forEach(el => {
        el.textContent = 'Exterior';
      });
    } else if (event.currentTarget.value == 'Externo') {
      this.hideCustomInputArea();
      this.rightLabelTargets.forEach(el => {
        el.textContent = 'Exterior';
      });
      this.leftLabelTargets.forEach(el => {
        el.textContent = 'Interior';
      });
    } 
  }

  setCustomLabel() {
    this.showCustomInputArea();
    this.customRadioTarget.value = this.customInputTarget.value;
    this.rightLabelTargets.forEach(el => {
      el.textContent = this.customInputTarget.value;
    });
    this.leftLabelTargets.forEach(el => {
      el.textContent = '';
    });
    if (!this.customInputTarget.value) {
      this.validationIconTarget.classList.remove('d-none');
    }
  }

  updateCustomValue() {
    this.customRadioTarget.value = this.customInputTarget.value;
    this.rightLabelTargets.forEach(el => {
      el.textContent = this.customInputTarget.value;
    });
    if (this.customInputTarget.value) {
      this.validationTextTarget.classList.add('d-none');
      this.validationIconTarget.classList.add('d-none');
    } else {
      this.validationTextTarget.classList.remove('d-none');
      this.validationIconTarget.classList.remove('d-none');
    }
  }

  showCustomInputArea() {
    this.customInputAreaTarget.classList.remove('invisible');
  }

  hideCustomInputArea() {
    this.customInputAreaTarget.classList.add('invisible');
  }
}

