<template>
  <div>
    <NavTabs />
    <ContentView>
      <Subheader title="Empleados Inactivos">
        <template #right>
          <Button to="/app/rrhh/empleados" size="sm">
            <font-awesome-icon :icon="['fas', 'user-check']" size="sm" fixed-width />
            <span class="pl-2">Ver Activos</span>
          </Button>
        </template>
      </Subheader>
      <div class="row justify-content-center mt-4">
        <b-table
          v-if="isDev || isHrAdmin"
          hover
          caption-top
          outlined
          responsive
          head-variant="dark"
          show-empty
          sort-by="locked_at"
          sort-desc
          :items="inactive_employees"
          :fields="fields"
          @row-clicked="onRowClick"
        >
          <!-- Table caption -->
          <template #table-caption>
            <div class="row justify-content-end ml-1 mr-1">
              <div class="text-right">
                {{ inactive_employees.length }} empleados inactivos en total
              </div>
            </div>
          </template>

          <!-- Loading spinner  -->
          <template #empty>
            <div class="text-center text-info">
              <b-spinner small type="grow"></b-spinner>
              <span class="pl-2">Cargando datos...</span>
            </div>
          </template>

          <!-- Table data -->
          <template v-slot:cell(first_name)="data">
            <span>{{ data.item.first_name }}</span>
            <span v-if="data.item.second_name">{{ data.item.second_name }}</span>
          </template>
          <template v-slot:cell(last_name)="data">
            <span>{{ data.item.last_name }}</span>
            <span v-if="data.item.last_name2">{{ data.item.last_name2 }}</span>
          </template>
          <template v-slot:cell(locked_at)="data">
            <span>{{ data.value | formatDate }}</span>
          </template>
        </b-table>
      </div>
    </ContentView>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';   // temp solution to auth
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import Subheader from '../shared/Subheader';
import NavTabs from './NavTabs';

export default {
  name: 'InactiveEmployeeIndex',
  components: { Button, ContentView, Subheader, NavTabs },
  data() {
    return {
      inactive_employees: [],
      fields: [
        { key: 'first_name', label: 'Nombre', sortable: true, tdClass: 'clickable' },
        { key: 'last_name', label: 'Apellidos', sortable: true, tdClass: 'clickable' },
        { key: 'job_title', label: 'Puesto', sortable: true, tdClass: 'clickable' },
        { key: 'locked_at', label: 'Desactivado en', sortable: true, thClass: 'text-right', tdClass: 'text-right clickable' }
      ],
    }
  },
  computed: {
    ...mapGetters(['isDev', 'isHrAdmin'])  // temp solution to auth
  },
  mounted() {
    this.$http.get('/api/human_resources/users/inactive_employees')
      .then(response => {
        console.log(response)
        this.inactive_employees = response.data;
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    onRowClick(item) {
      this.$router.push(`/app/rrhh/inactivos/${item.id}`);
    }
  }
}
</script>

<style scoped>
.table-responsive {
  font-size: 13px;
}
</style>
