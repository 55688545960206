<template>
  <b-container id="PurchaseDeliveryContent" fluid class="pb-3">
    <b-row id="main-content" align-h="center" v-if="purchaseDelivery">
      <b-col class="p-0">
        <b-row id="header" class="pb-3">
          <b-col xl="1" lg="2" sm="3" cols="4" class="d-flex align-items-center">
            <div class="icon-header">
              <font-awesome-icon :icon="iconHeader"/>
            </div>
          </b-col>
          <b-col xl="6" lg="6" sm="7" cols="8" class="pl-0">
            <p class="mb-1">
              Creado: {{ purchaseDelivery.created_at | formatDate }}
            </p>
            <p class="mb-1">
              <span v-if="purchaseDelivery.client">
                <b>{{ purchaseDelivery.client.client_num }}</b> -
                {{ purchaseDelivery.client.name }}
              </span>
              <span v-else>
                <b>{{ purchaseDelivery.client_num }}</b> -
                {{ purchaseDelivery.client_name }}
              </span>
            </p>
            <p class="mb-1" v-if="parentComponent != 'search'">
              Moneda: {{ purchaseDelivery.doc_currency}}
              <sup v-if="purchaseDelivery.doc_currency == 'USD'">{{ purchaseDelivery.usd_crc_rate }}</sup>
              <sup v-else-if="purchaseDelivery.doc_currency == 'CAN'">{{ purchaseDelivery.can_crc_rate }}</sup>
              <sup v-else-if="purchaseDelivery.doc_currency == 'EUR'">{{ purchaseDelivery.eur_crc_rate }}</sup>
            </p>
            <p class="mb-1" v-if="parentComponent == 'search'">
              Total OC:
              <span v-if="purchaseDelivery.doc_currency == 'USD'">{{ purchaseDelivery.doc_total | dollarize }}</span>
              <span v-else-if="purchaseDelivery.doc_currency == 'COL'">{{ purchaseDelivery.doc_total | colones }}</span>
              <span v-else>{{ purchaseDelivery.doc_currency }}-{{ purchaseDelivery.doc_total }}</span>
            </p>
            <div v-if="parentComponent == 'validation'" style="display: flex;">
              <p style="min-width: fit-content; margin: 0.3rem 1rem 0px 0px;">Número de factura:</p>
              <b-input
                v-model="form.invoiceNumber"
                type="text"
                size="sm"
                :state="form.invoiceNumber.length > 99 ? false : null"
                :disabled="purchaseDelivery.sap_doc_entry || !purchaseDelivery.is_executed ? true : false"
                @blur="updateInvoiceNumber($event.target.value)"
                @keydown.enter.prevent="updateInvoiceNumber($event.target.value)"
              />
            </div>
            <div v-if="parentComponent == 'validation'" style="display: flex;" class="pt-1">
              <p style="width: 8.8rem; margin: 0.5rem 1rem 0px 0px;">Fecha contable:</p>
              <b-datepicker
                v-model="form.doc_date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long' }"
                :max="currentDate"
                :state="dateValid ? null : false"
                style="width: auto;"
                placeholder="Elegir fecha"
                hide-header
                label-help="Seleccione una fecha menor al día de hoy"
                size="sm"
                locale="es-CR"
                :disabled="purchaseDelivery.sap_doc_entry || !purchaseDelivery.is_executed || syncingDate ? true : false"
                @input="updateDate"
              />
              <div v-if="syncingDate" class="pl-2 text-center text-info d-flex align-items-center">
                <b-spinner small label="Spinning"></b-spinner>
                <span class="pl-2">Actualizando fecha...</span>
              </div>
            </div>
            <p v-if="parentComponent == 'search'" class="mb-1">
              Cantidad de EM:
              <b :class="{'text-danger': purchaseDeliveriesCount == 0}">
                {{purchaseDeliveriesCount}}
              </b>
            </p>
          </b-col>
          <b-col xl="5" lg="4" class="text-right d-none d-sm-block">
            <p class="mb-1 fs-14">
              <span v-if="orderType == 'EM'">ENTRADA DE MERCADERÍA</span>
              <span v-else>ORDEN DE COMPRA</span>
            </p>
            <p v-if="purchaseDelivery.purchase_internal_id" class="mb-1 pr-1">
              <b>{{ purchaseDelivery.purchase_internal_id }}</b>
            </p>
            <p v-if="orderType == null || orderType == 'OC' && purchaseDelivery.sap_doc_no" class="mb-1">
              <b @click="copyTextToClipboard(purchaseDelivery.sap_doc_no)" class="copy-text">
                OC# {{ purchaseDelivery.sap_doc_no }}
                <sup><font-awesome-icon :icon="['far', 'copy']" fixed-width /></sup>
              </b>
            </p>
            <p v-if="orderType == 'EM' && purchaseDelivery.purchase_order.sap_doc_no" class="mb-1">
              <b @click="copyTextToClipboard(purchaseDelivery.purchase_order.sap_doc_no)" class="copy-text">
                OC# {{ purchaseDelivery.purchase_order.sap_doc_no }}
                <sup><font-awesome-icon :icon="['far', 'copy']" fixed-width /></sup>
              </b>
            </p>
          </b-col>
        </b-row>
        <slot name="section-content"></slot>
        <b-row>
          <b-col>
            <slot name="table-caption"></slot>
            <b-row class="pt-1">
              <b-col>
                <ItemTable
                  :parent-component="parentComponent"
                />
                <slot name="table-footer"></slot>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>
  </b-container>
</template>

<script>
import { parseISO, format } from 'date-fns'
import ItemTable from './PurchaseDeliveryItemTable'
import ToastAlert from '../../shared/ToastAlert'

export default {
  name: 'PurchaseDeliveryContent',
  components: {
    ItemTable,
    ToastAlert
  },
  props: {
    iconHeader: {
      type: Array,
      required: true
    },
    parentComponent: {
      type: String,
      required: true
    },
    purchaseDelivery: {
      type: Object,
      required: true
    },
    syncingDate: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      form:{
        invoiceNumber: this.purchaseDelivery.invoice_number ? this.purchaseDelivery.invoice_number : '',
        doc_date: this.purchaseDelivery.doc_date ? this.purchaseDelivery.doc_date : '',
      },
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      currentDate: '',
    }
  },
  mounted () {
    this.currentDate = format(new Date, 'yyyy-LL-dd')
  },
  watch: {
    syncingDate (newValue, OldValue) {
      if (!newValue) {
        this.form.doc_date = this.purchaseDelivery.doc_date
      }
    }
  },
  computed: {
    purchaseDeliveriesCount () {
      if (this.parentComponent == 'search' && this.purchaseDelivery && this.purchaseDelivery.purchase_deliveries) {
        return this.purchaseDelivery.purchase_deliveries.length
      }
      return 0
    },
    orderType () {
      const { purchase_internal_id } = this.purchaseDelivery
      if (purchase_internal_id) {
        const identifier = purchase_internal_id.split('', 2).join('')
        return identifier
      }
      return null
    },
    dateValid () {
      if (!this.purchaseDelivery || !this.purchaseDelivery.doc_date) return false
      return parseISO(this.form.doc_date) <= parseISO(this.currentDate)
    },
  },
  methods: {
    updateInvoiceNumber(value) {
      if (!value || value.length > 99) { return }
      this.$emit('update-invoice-number', value)
    },
    updateDate(value) {
      if (!value) { return }
      this.$emit('update-accounting-date', value)
    },
    async copyTextToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text);
        this.alertVariant = 'primary'
        this.alertMessage = '# de OC copiado'
        this.alertShow = true
      } catch($e) {
        this.alertVariant = 'danger'
        this.alertMessage = 'Error al copiar el # OC, intente nuevamente'
        this.alertShow = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-header {
  background: #163D7D;
  color: whitesmoke;
  width: fit-content;
  padding: 10px;
  border-radius: 20px;
  svg {
    width: 60px;
    height: 60px;
  }
}

#main-content {
  padding: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 6px 0px, rgba(60, 64, 67, 0.15) 0px 1px 20px 0px;
  border-radius: 15px;
}
.copy-text {
  &:hover {
    cursor: copy;
    font-size: 1.1rem;
    color: blue;
  }
}
</style>