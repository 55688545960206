<template>
  <b-row align-h="between" class="custom-tabs mx-0">
    <b-link class="ml-3 pl-2" @click="$router.go(-1)">
      <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
      <span class="pl-2">Atrás</span>
    </b-link>
    <ul>
      <li>
        <router-link to="/app/prod/solicitud" class="dash-nav-link">
          <font-awesome-icon :icon="['fas', 'plus-circle']" size="lg" />
          <span class="ml-1">Nueva Solicitud</span>
        </router-link>
      </li>
      <li>
        <router-link to="/app/prod/solicitudes" class="dash-nav-link pr-2">
          <font-awesome-icon :icon="['fas', 'hand-holding-box']" size="lg" />
          <span v-if="isDev" class="ml-1">Solicitudes</span>
          <span v-else class="ml-1">Mis Solicitudes</span>
        </router-link>
      </li>
    </ul>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PurchaseRequestNavsTabs',
  computed: {
    ...mapGetters(['isDev'])
  }
}
</script>
