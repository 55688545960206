<template>
  <b-link :to="this.to" @click="$emit('click')">
    <font-awesome-icon :icon="['far', 'arrow-left']" size="lg" />
    <span>Atrás</span>
  </b-link>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    to: String
  }
}
</script>

<style scoped>
span {
  padding-left: 5px;
}
a:hover {
  text-decoration: none;
}
</style>
