<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">3</span>
            <span class="step-title">Guía</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="4" cols="6">

          <b-row align-h="center" class="mt-5">
            <b-form-group label="Posición del rollo" label-class="outer-label">
              <b-radio-group v-model="form.tipo_guia" stacked @change="update">
                <b-radio value="Normal sobre viga">
                  Normal sobre viga (std)
                </b-radio>
                <b-radio value="Debajo de cielo">
                  Debajo de cielo
                </b-radio>
                <b-radio value="Con ajuste de viga">
                  Con ajuste de viga
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>
        </b-col>
        <b-col lg="4" cols="6">

          <b-row align-h="center" class="mt-5">
            <b-form-group label="Lado que se instala" label-class="outer-label">
              <b-radio-group v-model="form.lado_instalacion" stacked @change="update">
                <b-radio value="Interno">
                  Interno (std)
                </b-radio>
                <b-radio value="Externo">
                  Externo
                </b-radio>
                <b-radio value="Especial">
                  Especial
                </b-radio>
              </b-radio-group>
              <div v-if="form.lado_instalacion === 'Especial'">
                <b-input v-model="ladoInstalacionText" size="sm" @blur="update" />
                <small v-if="!ladoInstalacionText" class="text-danger">
                  Describir lado especial
                </small>
              </div>
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="ready" align-h="center">
        <div id="ct-track-diagrams">
          <p class="text-blue text-center lead mb-0">
            {{ form.tipo_guia }}
          </p>
          <div class="diagram-container text-center">
            <b-img :src="diagramImg" class="diagram-pic" />
            <div class="ct-guia-left-label diagram-blue lead">
              {{ installLeftLabel }}
            </div>
            <div class="ct-guia-right-label diagram-blue lead">
               {{ installRightLabel }}
            </div>
            <!-- TODO: Fix id on next div -->
            <div
              :id="{'ct-guia-p-debajodecielo': form.tipo_guia === 'Debajo de cielo'}"
              :class="{'ct-guia-p': form.tipo_guia != 'Debajo de cielo'}"
            >
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_p"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_p }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div class="ct-guia-o">
              <label class="pr-2 diagram-blue"> -- OFFSET</label>
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_o"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_o }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div v-if="form.tipo_guia === 'Con ajuste de viga'" id="ct-guia-s">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_s"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_s }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div class="ct-offset-explanation">
              <p class="mb-0">Reduzca el "offset" para cargadores bajos.</p>
              <p>Nota: cortina no abriría completa.</p>
            </div>
          </div>
        </div>
      </b-row>

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <hr>
          <b-row align-h="center" class="mt-5">
            <label class="outer-label pb-0">Posición de guias</label>
          </b-row>
          <b-row align-h="center">
            <label class="outer-label fs-14">(Vista en Planta)</label>
          </b-row>
          <b-row align-h="center" class="mt-2">
            <b-col>
              <b-row align-h="center">
                <BlankIcon :field="form.guia_izq" />
                <label class="outer-label">Izquierda</label>
              </b-row>
              <div class="visual-radio-group-stacked">
                <label class="visual-radio" :class="{'active': form.guia_izq === 'Entre paredes', 'blurred': form.guia_izq != 'Entre paredes' }">
                  <b-radio v-model="form.guia_izq" value="Entre paredes" name="guia-izq-radios" class="d-none" />
                  <span>Entre paredes</span>
                  <b-img :src="leftBtwWallsImg" class="radio-image" />
                </label>

                <label class="visual-radio" :class="{'active': form.guia_izq === 'Cara a la pared', 'blurred': form.guia_izq != 'Cara a la pared' }">
                  <b-radio v-model="form.guia_izq" value="Cara a la pared" name="guia-izq-radios" class="d-none" />
                  <div v-if="cortina.ajuste_buque.includes('Izquierda')">
                    <span>Cara a la columna</span>
                    <b-img :src="leftOnColumnImg" class="radio-image" />
                  </div>
                  <div v-else>
                    <span>Cara a la pared</span>
                    <b-img :src="leftOnWallImg" class="radio-image" />
                  </div>
                </label>
              </div>
            </b-col>
            <b-col>
              <b-row align-h="center">
                <BlankIcon :field="form.guia_der" />
                <label class="outer-label">Derecha</label>
              </b-row>
              <div class="visual-radio-group-stacked">
                <label class="visual-radio" :class="{'active': form.guia_der === 'Entre paredes', 'blurred': form.guia_der != 'Entre paredes' }">
                  <b-radio v-model="form.guia_der" value="Entre paredes" name="guia-der-radios" class="d-none" />
                  <span>Entre paredes</span>
                  <b-img :src="rightBtwWallsImg" class="radio-image" />
                </label>

                <label class="visual-radio" :class="{'active': form.guia_der === 'Cara a la pared', 'blurred': form.guia_der != 'Cara a la pared' }">
                  <b-radio v-model="form.guia_der" value="Cara a la pared" name="guia-der-radios" class="d-none" />
                  <div v-if="cortina.ajuste_buque.includes('Derecha')">
                    <span>Cara a la columna</span>
                    <b-img :src="rightOnColumnImg" class="radio-image" />
                  </div>
                  <div v-else>
                    <span>Cara a la pared</span>
                    <b-img :src="rightOnWallImg" class="radio-image" />
                  </div>
                </label>
              </div>
            </b-col>
          </b-row>
          <AlarmMessage field="posicion_guias" />
          <hr>

          <b-row class="mt-5">
            <b-form-group label="Tamaño de guía" label-class="outer-label">
              <b-radio-group v-model="form.tamano_guia" stacked @change="update">
                <b-radio value="2 pulg">
                  2 pulg (std)
                </b-radio>
                <b-radio value="3 pulg">
                  3 pulg
                </b-radio>
                <b-radio value="4 pulg">
                  4 pulg
                </b-radio>
                <b-radio value="5 pulg">
                  5 pulg
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>
          <AlarmMessage field="tamano_guia" />

          <b-row class="mt-5">
            <b-form-group label="Cobertor" label-class="outer-label">
              <b-radio-group v-model="form.cobertor" stacked @change="update">
                <b-radio value="No lleva">
                  No lleva
                </b-radio>
                <b-radio value="Redondo">
                  Redondo (std)
                </b-radio>
                <b-radio value="Cuadrado">
                  Cuadrado
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

           <b-row class="mt-5">
            <b-form-group label="Ojos de fijación" label-class="outer-label">
              <b-radio-group v-model="form.ojos_fijacion" stacked @change="update">
                <b-radio value="Sí, hacia interior">
                  Sí, hacia interior (std)
                </b-radio>
                <b-radio value="Sí, hacia exterior">
                  Sí, hacia exterior
                </b-radio>
                <b-radio value="No">
                  No
                </b-radio>
              </b-radio-group>
            </b-form-group>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_track" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import AlarmMessage from '../AlarmMessage'
import BlankIcon from '../BlankIcon'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
// Tipo guia
import NormalSobreViga from 'images/cortina/guia-normalsobreviga.png'
import DebajoCielo from 'images/cortina/guia-debajodecielo.png'
import ConAjusteViga from 'images/cortina/guia-conajustedeviga.png'
// Guia izq + guia der
import LeftBtwWalls from 'images/cortina/guia-izq-entreparedes.png'
import LeftOnColumn from 'images/cortina/guia-izq-alacolumna.png'
import LeftOnWall from 'images/cortina/guia-izq-alapared.png'
import RightBtwWalls from 'images/cortina/guia-der-entreparedes.png'
import RightOnColumn from 'images/cortina/guia-der-alacolumna.png'
import RightOnWall from 'images/cortina/guia-der-alapared.png'

export default {
  name: 'CortinaTrack',
  components: { ValidationProvider, AlarmMessage, BlankIcon, ConfiguratorStepButtons },
  data () {
    return {
      form: {
        tipo_guia: 'Normal sobre viga',
        lado_instalacion: 'Interno',
        dim_p: null,
        dim_s: null,
        dim_o: null,
        guia_izq: null,
        guia_der: null,
        tamano_guia: '2 pulg',
        cobertor: 'Redondo',
        ojos_fijacion: 'Sí, hacia interior', // What is default for this??
        notes_track: ''
      },
      ladoInstalacionText: null, // special text for lado_instalacion 'especial' option
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId']),
    diagramImg () {
      if (this.form.tipo_guia === 'Normal sobre viga') {
        return NormalSobreViga
      } else if (this.form.tipo_guia === 'Debajo de cielo') {
        return DebajoCielo
      } else if (this.form.tipo_guia === 'Con ajuste de viga') {
        return ConAjusteViga
      }
    },
    leftBtwWallsImg () { return LeftBtwWalls },
    leftOnColumnImg () { return LeftOnColumn },
    leftOnWallImg () { return LeftOnWall },
    rightBtwWallsImg () { return RightBtwWalls },
    rightOnColumnImg () { return RightOnColumn },
    rightOnWallImg () { return RightOnWall },
    installLeftLabel () {
      if (this.form.lado_instalacion == 'Interno') {
        return 'Externo'
      } else if (this.form.lado_instalacion == 'Externo') {
        return 'Interno'
      }
    },
    installRightLabel () {
      if (this.form.lado_instalacion === 'Interno') {
        return 'Interno'
      } else if (this.form.lado_instalacion === 'Externo') {
        return 'Externo'
      } else if (this.form.lado_instalacion === 'Especial') {
        return this.ladoInstalacionText
      }
    }
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.tipo_guia = this.cortina.tipo_guia
    this.processLadoInstalacion()
    this.form.dim_p = this.cortina.dim_p
    this.form.dim_s = this.cortina.dim_s
    this.form.dim_o = this.cortina.dim_o
    this.form.guia_izq = this.cortina.guia_izq
    this.form.guia_der = this.cortina.guia_der
    this.form.tamano_guia = this.cortina.tamano_guia
    this.form.cobertor = this.cortina.cobertor
    this.form.ojos_fijacion = this.cortina.ojos_fijacion
    this.form.notes_track = this.cortina.notes_track
    this.ready = true
  },
  methods: {
    update () {
      const submittedForm = this.form
      if (this.form.lado_instalacion === 'Especial') {
        if (this.ladoInstalacionText) {
          submittedForm.lado_instalacion = this.ladoInstalacionText
        } else {
          return
        }
      }
      console.log('updating CortinaTrack')
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: submittedForm
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
          this.processLadoInstalacion()
        })
        .finally(this.processing = false)
    },
    processLadoInstalacion () {
      if (this.cortina.lado_instalacion && this.cortina.lado_instalacion != 'Interno' && this.cortina.lado_instalacion != 'Externo') {
        this.form.lado_instalacion = 'Especial'
        this.ladoInstalacionText = this.cortina.lado_instalacion
      } else {
        this.form.lado_instalacion = this.cortina.lado_instalacion
      }
    }
  }
}
</script>
