<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" ref="configuratorTop">
        <b-col sm="10">
          <div class="step-header">
            <span class="step-number badge badge-pill badge-dark">2</span>
            <span class="step-title">Dimensiones</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <b-row align-h="center">
            <b-form-group label="Ajuste de buque?" label-class="outer-label" size="lg">
              <b-checkbox-group v-model="form.ajuste_buque">
                <b-checkbox value="Izquierda">Izquierda</b-checkbox>
                <b-checkbox value="Cargador">Cargador</b-checkbox>
                <b-checkbox value="Derecha">Derecha</b-checkbox>
              </b-checkbox-group>
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <!-- Diagrams -->
      <b-row align-h="center" class="mobile-sm">
        <span>* Lado en que se instala la cortina *</span>
      </b-row>
      <b-row align-h="center">
        <p>*Medidas en centimetros*</p>
      </b-row>

      <b-row v-if="ready" align-h="center">
        <div id="ct-frontal-diagrams">
          <div class="diagram-container">
            <b-img :src="diagramImg" class="diagram-pic" />
            <div :id="[ isPlano ? 'se-plano-input-a' : '' ]" :class="{ 'se-desnivel-input-a': !isPlano }">
              <ValidationProvider rules="min_value:15" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_a"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_a }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div class="se-input-c">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_c"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_c }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div v-if="hasCargador" class="se-input-cs">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_cs"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_cs }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>

            <div v-if="isPlano" id="se-plano-input-h">
              <ValidationProvider rules="min_value:15" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_h"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_h }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div v-if="!isPlano" class="se-desnivel-input-hi">
              <ValidationProvider rules="min_value:15" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_hi"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_hi }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div v-if="!isPlano" class="se-desnivel-input-hd">
              <ValidationProvider rules="min_value:15" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_hd"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_hd }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>

            <div class="se-input-li">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_li"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_li }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div class="se-input-ld">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_ld"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_ld }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div v-if="hasColumnaIzquierda" class="se-input-cli">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_cli"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_cli }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
            <div v-if="hasColumnaDerecha" class="se-input-cld">
              <ValidationProvider rules="min_value:0" v-slot="{ valid, errors }">
                <b-input
                  v-model="form.dim_cld"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="{ 'border-success': valid && form.dim_cld }"
                  class="diagram-input"
                  v-on="{ blur: valid ? update : null }"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
      </b-row>
      <AlarmMessage field="dim_a" />
      <AlarmMessage field="dim_cs" />
      <AlarmMessage field="dim_h" />
      <AlarmMessage field="dim_hi" />
      <AlarmMessage field="dim_hd" />
      <AlarmMessage field="dim_li" />
      <AlarmMessage field="dim_ld" />
      <AlarmMessage field="peso_aproximado" />

      <b-row v-show="ready" align-h="center" class="mt-3">
        <b-col lg="7" sm="11">
          <!-- Desnivel -->
          <b-row align-h="center">
            <label class="outer-label">Desniveles?</label>
          </b-row>
          <b-row align-h="around" class="visual-radio-group">
            <label class="visual-radio" :class="{'active': isDesnivelIzquierdo, 'blurred': !isDesnivelIzquierdo }">
              <b-img :src="desnivelIzquierdoIcon" class="radio-image" />
              <b-radio v-model="form.desnivel" value="Desnivel izquierdo" name="desnivel-radios" class="d-none" @change="update" />
              <span>Desnivel izquierdo</span>
            </label>
            <label class="visual-radio" :class="{'active': isPlano, 'blurred': !isPlano }">
              <b-img :src="desnivelPlanoIcon" class="radio-image" />
              <b-radio v-model="form.desnivel" value="Plano" name="desnivel-radios" class="d-none" @change="update" />
              <span>Plano</span>
            </label>
            <label class="visual-radio" :class="{'active': isDesnivelDerecho, 'blurred': !isDesnivelDerecho }">
              <b-img :src="desnivelDerechoIcon" class="radio-image" />
              <b-radio v-model="form.desnivel" value="Desnivel derecho" name="desnivel-radios" class="d-none" @change="update" />
              <span>Desnivel derecho</span>
            </label>
          </b-row>

          <!-- Proporciones de 2 Diseños -->
          <!-- <b-row v-if="cortina.design && cortina.design_sec" class="mt-5">
            <label class="outer-label">
              Proporciones de 2 Diseños
            </label>
          </b-row>
          <b-row v-if="cortina.design && cortina.design_sec" no-gutters class="mt-3" style="height: 300px">
            <b-col cols="1" class="px-3">
              <b-row id="alto-display-col" align-h="center" align-v="center">
                <div>
                  <p class="lead text-center">
                    Alto:
                    <span v-if='heightDisplay'>{{ heightDisplay }} cm</span>
                    <span v-else>--</span>
                  </p>
                </div>
              </b-row>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col cols="1">
              <div ref="slider" style="height: 100%" />
            </b-col>
            <b-col cols="9">
              <div class="fs-14 w-100" style="height: 100%">
                <b-row no-gutters class="mt-2">
                  <b-col cols="3">
                    <span v-if="sliderValue.primaryTop">{{ sliderValue.primaryTop }}</span>
                    <span v-else>--</span>
                    <span> cm</span>
                  </b-col>
                  <b-col cols="3">
                    <div class="color-sample-strip d-inline-block ml-3" style="background-color: #f9f9f9; border: 1px solid #ccc" />
                  </b-col>
                  <b-col cols="5" class="mobile-sm">
                    <span class="font-weight-bold">SS - Principal</span><br>
                    <span class="text-truncate">{{ cortina.design.item_code }}</span>
                  </b-col>
                </b-row>

                <b-row no-gutters style="margin-top: 7em">
                  <b-col cols="3">
                    <span v-if="sliderValue.secondary">{{ sliderValue.secondary }}</span>
                    <span v-else>--</span>
                    <span> cm</span>
                  </b-col>
                  <b-col cols="3">
                    <div class="color-sample-strip d-inline-block ml-3" style="background-color: #37afa5; border: 1px solid #ccc" />
                  </b-col>
                  <b-col cols="5" class="mobile-sm">
                    <span class="font-weight-bold">SC - Secundario</span><br>
                    <span class="text-truncate">{{ cortina.design_sec.item_code }}</span>
                  </b-col>
                </b-row>

                <b-row no-gutters style="margin-top: 6em">
                  <b-col cols="3">
                    <span v-if="sliderValue.primaryBot">{{ sliderValue.primaryBot }}</span>
                    <span v-else>--</span>
                    <span> cm</span>
                  </b-col>
                  <b-col cols="3">
                    <div class="color-sample-strip d-inline-block ml-3" style="background-color: #f9f9f9; border: 1px solid #ccc" />
                  </b-col>
                  <b-col cols="5" class="mobile-sm">
                    <span class="font-weight-bold">SI - Principal</span><br>
                    <span class="text-truncate">{{ cortina.design.item_code }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row> -->

          <!-- Proporciones de 2 Diseños (version 2 without slider) -->
          <b-container v-if="cortina.design && cortina.design_sec" fluid class="mt-5 mb-3">
            <b-row>
              <label class="outer-label">
                Proporciones de 2 Diseños
              </label>
            </b-row>
            <b-row v-if="!designProportionsAreValid">
              <small class="text-danger">{{ designProportionsValidationMessage }}</small>
            </b-row>
            <b-row no-gutters class="mt-3" style="height: 300px">
              <b-col cols="1" class="px-2">
                <b-row id="alto-display-col" align-h="center" align-v="center">
                  <div>
                    <p class="text-center">
                      Alto:
                      <span v-if='cortinaHeight'>{{ cortinaHeight }} cm</span>
                      <span v-else>--</span>
                    </p>
                  </div>
                </b-row>
              </b-col>
              <b-col cols="10" offset="1">
                <div class="fs-14 w-100" style="height: 100%">
                  <b-row no-gutters align-v="center" class="mt-2">
                    <b-col cols="3">
                      <b-input-group size="sm" append="cm">
                        <b-input v-model="designProportions.primaryTop" @blur="validateUpdateDesignPorportions" />
                      </b-input-group>
                    </b-col>
                    <b-col cols="3">
                      <div class="color-sample-strip d-inline-block ml-3" style="background-color: #f9f9f9; border: 1px solid #ccc" />
                    </b-col>
                    <b-col cols="5" class="mobile-sm">
                      <span class="font-weight-bold">SS - Principal</span><br>
                      <span class="text-truncate">{{ cortina.design.item_code }}</span>
                    </b-col>
                  </b-row>

                  <b-row no-gutters align-v="center" style="margin-top: 7em">
                    <b-col cols="3">
                      <b-input-group size="sm" append="cm">
                        <b-input v-model="designProportions.secondary" @blur="validateUpdateDesignPorportions" />
                      </b-input-group>
                    </b-col>
                    <b-col cols="3">
                      <div class="color-sample-strip d-inline-block ml-3" style="background-color: #37afa5; border: 1px solid #ccc" />
                    </b-col>
                    <b-col cols="5" class="mobile-sm">
                      <span class="font-weight-bold">SC - Secundario</span><br>
                      <span class="text-truncate">{{ cortina.design_sec.item_code }}</span>
                    </b-col>
                  </b-row>

                  <b-row no-gutters align-v="center" style="margin-top: 6em">
                    <b-col cols="3">
                      <b-input-group size="sm" append="cm">
                        <b-input v-model="designProportions.primaryBot" @blur="validateUpdateDesignPorportions" />
                      </b-input-group>
                    </b-col>
                    <b-col cols="3">
                      <div class="color-sample-strip d-inline-block ml-3" style="background-color: #f9f9f9; border: 1px solid #ccc" />
                    </b-col>
                    <b-col cols="5" class="mobile-sm">
                      <span class="font-weight-bold">SI - Principal</span><br>
                      <span class="text-truncate">{{ cortina.design.item_code }}</span>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <b-row align-h="center" class="mt-5">
            <b-link @click="downloadPedestrianDoorPdf">
              Descargar Pedido Directo Peatonales
            </b-link>
          </b-row>

          <b-row class="mt-5">
            <b-form-group label-class="notes-label" class="w-100">
              <template #label>
                <font-awesome-icon :icon="['far', 'sticky-note']" size="sm" class="text-muted" fixed-width />
                <span>Notas:</span>
              </template>
              <b-textarea v-model="form.notes_dimensions" rows="3" trim @blur="update" />
            </b-form-group>
          </b-row>
        </b-col>
      </b-row>

      <ConfiguratorStepButtons />
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import nouislider from "nouislider"
import AlarmMessage from '../AlarmMessage'
import ConfiguratorStepButtons from '../ConfiguratorStepButtons'
import DesnivelIzquierdoIcon from 'images/shared/desnivel-izquierdo-icon.png'
import DesnivelPlanoIcon from 'images/shared/desnivel-plano-icon.png'
import DesnivelDerechoIcon from 'images/shared/desnivel-derecho-icon.png'
// import BuqueDD from 'images/elevadizo/buque-dd.png'
// import BuqueDI from 'images/elevadizo/buque-di.png'
// import Buque from 'images/elevadizo/buque.png'
// import BuqueCargadorDD from 'images/elevadizo/buqueCargador-dd.png'
// import BuqueCargadorDI from 'images/elevadizo/buqueCargador-di.png'
// import BuqueCargador from 'images/elevadizo/buqueCargador.png'
// import BuqueCargadorDerechaDD from 'images/elevadizo/buqueCargadorDerecha-dd.png'
// import BuqueCargadorDerechaDI from 'images/elevadizo/buqueCargadorDerecha-di.png'
// import BuqueCargadorDerecha from 'images/elevadizo/buqueCargadorDerecha.png'
// import BuqueDerechaDD from 'images/elevadizo/buqueDerecha-dd.png'
// import BuqueDerechaDI from 'images/elevadizo/buqueDerecha-di.png'
// import BuqueDerecha from 'images/elevadizo/buqueDerecha.png'
// import BuqueIzquierdaDD from 'images/elevadizo/buqueIzquierda-dd.png'
// import BuqueIzquierdaDI from 'images/elevadizo/buqueIzquierda-di.png'
// import BuqueIzquierda from 'images/elevadizo/buqueIzquierda.png'
// import BuqueIzquierdaCargadorDD from 'images/elevadizo/buqueIzquierdaCargador-dd.png'
// import BuqueIzquierdaCargadorDI from 'images/elevadizo/buqueIzquierdaCargador-di.png'
// import BuqueIzquierdaCargador from 'images/elevadizo/buqueIzquierdaCargador.png'
// import BuqueIzquierdaCargadorDerechaDD from 'images/elevadizo/buqueIzquierdaCargadorDerecha-dd.png'
// import BuqueIzquierdaCargadorDerechaDI from 'images/elevadizo/buqueIzquierdaCargadorDerecha-di.png'
// import BuqueIzquierdaCargadorDerecha from 'images/elevadizo/buqueIzquierdaCargadorDerecha.png'
// import BuqueIzquierdaDerechaDD from 'images/elevadizo/buqueIzquierdaDerecha-dd.png'
// import BuqueIzquierdaDerechaDI from 'images/elevadizo/buqueIzquierdaDerecha-di.png'
// import BuqueIzquierdaDerecha from 'images/elevadizo/buqueIzquierdaDerecha.png'

export default {
  name: 'CortinaDimensions',
  components: { ValidationProvider, AlarmMessage, ConfiguratorStepButtons },
  data () {
    return {
      form: {
        ajuste_buque: [],
        dim_a: null,
        dim_c: null,
        dim_cs: null,
        dim_h: null,
        dim_hi: null,
        dim_hd: null,
        dim_li: null,
        dim_ld: null,
        dim_cli: null,
        dim_cld: null,
        desnivel: 'Plano',
        proporciones_disenos: [],
        notes_dimensions: ''
      },
      // sliderValue: { // type number or null
      //   primaryTop: null,
      //   secondary: null,
      //   primaryBot: null
      // },
      designProportions: {
        primaryTop: null,
        secondary: null,
        primaryBot: null
      },
      designProportionsAreValid: false,
      designProportionsValidationMessage: '',
      // heightDisplay: null,
      processing: false,
      ready: false
    }
  },
  computed: {
    ...mapState ({ cortina: state => state.configurator.configurable }),
    ...mapGetters (['configurableId']),
    desnivelIzquierdoIcon () { return DesnivelIzquierdoIcon },
    desnivelPlanoIcon () { return DesnivelPlanoIcon },
    desnivelDerechoIcon () { return DesnivelDerechoIcon },
    sortedAjusteBuque () {
      let array = this.form.ajuste_buque
      const izqIndex = array.indexOf('Izquierda')
      if (izqIndex > 0) {
        array.splice(izqIndex, 1)
        array.unshift('Izquierda')
      }
      const derIndex = array.indexOf('Derecha')
      if (derIndex >= 0) {
        array.splice(derIndex, 1)
        array.push('Derecha')
      }
      return array
    },
    diagramImg () {
      let desnivel
      if (this.isPlano) {
        desnivel = ''
      } else if (this.isDesnivelIzquierdo) {
        desnivel = '-di'
      } else if (this.isDesnivelDerecho) {
        desnivel = '-dd'
      }
      return require(`images/elevadizo/buque${this.sortedAjusteBuque.join('')}${desnivel}.png`)
    },
    isPlano () {
      return this.form.desnivel === 'Plano'
    },
    isDesnivelIzquierdo () {
      return this.form.desnivel === 'Desnivel izquierdo'
    },
    isDesnivelDerecho () {
      return this.form.desnivel === 'Desnivel derecho'
    },
    hasColumnaIzquierda () {
      return this.form.ajuste_buque.includes('Izquierda')
    },
    hasColumnaDerecha () {
      return this.form.ajuste_buque.includes('Derecha')
    },
    hasCargador () {
      return this.form.ajuste_buque.includes('Cargador')
    },
    cortinaHeight () { // needed for cortina slider
      let height = null
      if (this.form.desnivel === 'Plano') {
        height = this.form.dim_h
      } else if (this.form.desnivel === 'Desnivel izquierdo') {
        height = this.form.dim_hd
      } else if (this.form.desnivel === 'Desnivel derecho') {
        height = this.form.dim_hi
      }
      if (height && !isNaN(height) && height >= 25) {
        // Note:  Height needs to be 25 or greater
        if (this.form.ajuste_buque.includes('Cargador') && this.form.dim_cs && !isNaN(this.form.dim_cs)) {
          return height - this.form.dim_cs
        }
      }
      return height
    },
    designProportionsHeight () {
      if (this.designProportions.primaryTop && this.designProportions.secondary && this.designProportions.primaryBot) {
        return parseFloat(this.designProportions.primaryTop) + parseFloat(this.designProportions.secondary) + parseFloat(this.designProportions.primaryBot)
      }
    }
  },
  mounted () {
    this.$refs.configuratorTop.scrollIntoView({ scrollBehavior: 'smooth' })
    this.form.ajuste_buque = this.cortina.ajuste_buque
    this.form.dim_a = this.cortina.dim_a
    this.form.dim_c = this.cortina.dim_c
    this.form.dim_cs = this.cortina.dim_cs
    this.form.dim_h = this.cortina.dim_h
    this.form.dim_hi = this.cortina.dim_hi
    this.form.dim_hd = this.cortina.dim_hd
    this.form.dim_li = this.cortina.dim_li
    this.form.dim_ld = this.cortina.dim_ld
    this.form.dim_cli = this.cortina.dim_cli
    this.form.dim_cld = this.cortina.dim_cld
    this.form.desnivel = this.cortina.desnivel
    // this.form.proporciones_disenos = this.cortina.proporciones_disenos
    // if (this.cortina.proporciones_disenos.length === 3) {
    //   this.sliderValue.primaryTop = parseFloat(this.cortina.proporciones_disenos[0])
    //   this.sliderValue.secondary = parseFloat(this.cortina.proporciones_disenos[1])
    //   this.sliderValue.primaryBot = parseFloat(this.cortina.proporciones_disenos[2])
    // }
    this.loadDesignProportions()
    this.validateDesignProportions()
    this.form.notes_dimensions = this.cortina.notes_dimensions
    this.ready = true

    // Setup slider
    // const startingAlto = this.cortinaHeight
    // // var startingTopHandle = document.getElementById('primary-top-input').value
    // const startingTopHandle = this.sliderValue.primaryTop
    // // const startingBotHandle = parseFloat(document.getElementById('secondary-input').value) + parseFloat(startingTopHandle)
    // const startingBotHandle = this.sliderValue.secondary + startingTopHandle
    // console.log('starting top handle: ' + startingTopHandle)
    // console.log('starting bot handle: ' + startingBotHandle)
    // if (startingAlto && startingTopHandle && startingBotHandle) {
    //   console.log('all 3 handles have values')
    //   this.heightDisplay = startingAlto
    //   nouislider.create(this.$refs.slider, {
    //     start: [startingTopHandle, startingBotHandle],
    //     orientation: 'vertical',
    //     behaviour: 'drag',
    //     step: 1,
    //     connect: true,
    //     range: {
    //       'min': 12,
    //       'max': Math.round(startingAlto) - 12
    //     }
    //   })
    //   // this.iconTarget.classList.add('d-none')
    // } else if (startingAlto) {
    //   console.log('else if triggered!')
    //   this.heightDisplay = startingAlto
    //   nouislider.create(this.$refs.slider, {
    //     start: [parseFloat(startingAlto / 2), parseFloat(startingAlto * .3)], // not working as intended
    //     orientation: 'vertical',
    //     behaviour: 'drag',
    //     step: 1,
    //     connect: true,
    //     range: {
    //       'min': 12,
    //       'max': Math.round(startingAlto) - 12
    //     }
    //   })
    // } else {
    //   console.log('else clause triggered')
    //   this.heightDisplay = null
    //   nouislider.create(this.$refs.slider, {
    //     start: [100, 400],
    //     orientation: 'vertical',
    //     behaviour: 'drag',
    //     step: 1,
    //     connect: true,
    //     range: {
    //       'min': 12,
    //       'max': 488
    //     }
    //   })
    // }
    // console.log('Mounted alto display: ')
    // console.log(this.heightDisplay)

    // this.$refs.slider.noUiSlider.on('update', function(values, _) {
    //   console.log('update slider')
    //   console.log('update event values: ')
    //   console.log(values)
    //   const alto = this.heightDisplay // PROBLEM: is undefined
    //   console.log('Update function alto display: ')
    //   console.log(alto)
    //   if (alto) {
    //     const topValue = Math.round(Number(values[0]))
    //     const midValue = Math.round(Number(values[1]) - topValue)
    //     const botValue = Math.round(alto - Number(values[1]))
    //     console.log('TopValue:')
    //     console.log(topValue)
    //     if (!isNaN(topValue) && !isNaN(midValue) && !isNaN(botValue)) {

    //       // document.getElementById('primary-top-input').value = topValue
    //       // document.getElementById('secondary-input').value = midValue
    //       // document.getElementById('primary-bot-input').value = botValue

    //       this.sliderValue.primaryTop = topValue
    //       this.sliderValue.secondary = midValue
    //       this.sliderValue.primaryBot = botValue

    //       //document.getElementById('slider-blank-icon').classList.add('d-none')  // alias of iconTarget
    //     } else {
    //       // clear displays and inputs if valid alto not found
    //       // document.getElementById('primary-top-display').textContent = '-- cm'
    //       // document.getElementById('secondary-display').textContent = '-- cm'
    //       // document.getElementById('primary-bot-display').textContent = '-- cm'
    //       // document.getElementById('alto-display').textContent = '--'
    //       this.heightDisplay = null


    //       // document.getElementById('primary-top-input').value = ""
    //       // document.getElementById('secondary-input').value = ""
    //       // document.getElementById('primary-bot-input').value = ""
    //       this.sliderValue.primaryTop = null
    //       this.sliderValue.secondary = null
    //       this.sliderValue.primaryBot = null

    //       //document.getElementById('slider-blank-icon').classList.remove('d-none')  // alias of iconTarget
    //     }
    //   }
    // })
  },
  methods: {
    update () {
      const submittedForm = this.form
      if (this.cortina.design_id && this.cortina.design_sec_id) {
        submittedForm.proporciones_disenos = [this.designProportions.primaryTop, this.designProportions.secondary, this.designProportions.primaryBot]
        if (!this.designProportionsAreValid) { return }
      }
      this.$http.patch(`/api/production/submitals/${this.$route.params.id}/cortinas/${this.configurableId}`, {
        cortina: submittedForm
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
          this.loadDesignProportions()
        })
        .finally(this.processing = false)
    },
    validateUpdateDesignPorportions () {
      this.validateDesignProportions()
      this.update()
    },
    loadDesignProportions () {
      if (this.cortina.proporciones_disenos.length === 3) {
        this.designProportions.primaryTop = parseFloat(this.cortina.proporciones_disenos[0])
        this.designProportions.secondary = parseFloat(this.cortina.proporciones_disenos[1])
        this.designProportions.primaryBot = parseFloat(this.cortina.proporciones_disenos[2])
      }
      this.form.proporciones_disenos = this.cortina.proporciones_disenos
    },
    validateDesignProportions () {
      if (this.designProportionsHeight) {
        const difference = Math.abs(this.designProportionsHeight - this.cortinaHeight)
        if (this.designProportionsHeight === this.cortinaHeight) {
          this.designProportionsAreValid = true
          this.designProportionsValidationMessage = ''
        } else if (this.designProportionsHeight > this.cortinaHeight) {
          this.designProportionsAreValid = false
          this.designProportionsValidationMessage = `The total proportions exceed the alto by ${difference} cm`
        } else if (this.cortinaHeight > this.designProportionsHeight) {
          this.designProportionsAreValid = false
          this.designProportionsValidationMessage = `The total proportions are shorter than the alto by ${difference} cm`
        }
      }
    },
    downloadPedestrianDoorPdf () {
      if (this.processing) { return }
      this.processing = true
      this.$http.get(`/api/production/submitals/download_pedestrian_pdf`)
        .then((res) => {
          console.log(res.data)
          const link = document.createElement('a')
          link.href = res.data
          link.download = 'Pedido Directo Peatonales.pdf'
          link.click()
        })
        .catch(e => console.log(e))
        .finally(this.processing = false)
    }
  }
}
</script>
