<template>
  <b-button-group>
    <b-button :disabled="!prev" :to="prevUrl" class="page-button">
      <slot name="prev-label">Más reciente</slot>
    </b-button>
    <b-button  disabled size="sm" variant="outline-primary" class="mx-1">
      <span>{{page}}</span>
    </b-button>
    <b-button :disabled="!next" :to="nextUrl" class="page-button">
      <slot name="next-label">Más antiguo</slot>
    </b-button>
  </b-button-group>
</template>

<script>
import Button from './Button';

export default {
  name: 'PaginationCountless',
  components: { Button },
  props: {
    page: Number,
    prev: Number,
    next: Number
  },
  computed: {
    prevUrl() {
      return this.$route.path + `?pagina=${this.prev}`
    },
    nextUrl() {
      return this.$route.path + `?pagina=${this.next}`
    }
  }
}
</script>

<style scoped>
.page-button {
  color: #163D7D;
  background-color: #fff;
  font-size: 13px;
}
.page-button:hover {
  color: #fff;
  background-color: #163D7D !important;
  border-color: #163D7D !important
}

.page-button.disabled {
  opacity: .50;
  pointer-events: none;
}
</style>
