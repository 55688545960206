<template>
  <div class="pb-5 mb-3">
    <NavTabs />
    <b-row align-h="center" class="mt-4">
      <b-col lg="2">
        <BackButton @click="$router.go(-1)" />
      </b-col>
      <b-col lg="9"></b-col>
    </b-row>
    <ContentView>
      <b-container>
        <b-row class="mb-3 mt-1">
   <!--        <div> -->
        <!--     <small class="font-italic">Solicitado por: {{ manager | concatName2 }}</small><br> -->
          <!--   <small class="font-italic">{{ manager | concatName2 }}</small> -->
  <!--           </div> -->
          <b-col cols="12" class="text-right pr-0 fs-22">
            <span :class="{ 'text-success': approved || finalized, 'text-warning': pending, 'text-danger': rejected || canceled }">
              {{ ticket.status | titleize }}
            </span>
          </b-col>
        </b-row>
        <b-row v-if="isHrAdmin || isDev">
          <b-col lg="6" order="2" order-sm="1" class="pl-0">
            <div v-if="pending">
              <flat-pickr
                ref="datepicker"
                v-model="currentVacationDates"
                :config="config"
                class="d-none">
              </flat-pickr>
              <b-button-group>
                <Button size="sm" @click="openDatepicker">
                  <font-awesome-icon :icon="['far', 'calendar-edit']" size="lg" fixed-width />
                  <span class="pl-1">Modificar fechas</span>
                </Button>
                <Button v-if="isModified" size="sm" @click="restoreOriginalDates">
                  <font-awesome-icon :icon="['far', 'undo-alt']" size="lg" fixed-width />
                  <span class="pl-1">Fechas Originales</span>
                </Button>
              </b-button-group>
            </div>
          </b-col>
          <b-col lg="6" order="1" order-sm="2" class="pb-5 pb-sm-3 pr-0 text-right">
            <div v-if="pending">
              <Button @click="approve()" size="sm" variant="green" :disabled="!isValid">
                <font-awesome-icon :icon="['far', 'check']" size="lg" fixed-width />
                <span class="pl-1">Aprobar</span>
              </Button>
              <Button @click="reject()" size="sm" variant="red" class="ml-3" :disabled="!isValid">
                <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
                <span class="pl-1">Rechazar</span>
              </Button>
            </div>
            <div>
              <Button v-if="approved" @click="finalize()" size="sm" variant="green">
                <font-awesome-icon :icon="['far', 'signature']" size="lg" fixed-width />
                <span class="pl-1">Finalizar</span>
              </Button>
              <Button v-if="approved || canceled || finalized" @click="fetchPdf()" size="sm" class="ml-3">
                <font-awesome-icon :icon="['far', 'arrow-to-bottom']" size="lg" fixed-width />
                <span class="pl-1">Descargar</span>
              </Button>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <!-- Boleta Replica -->
      <b-container class="mt-3" id="boleta-box">
        <b-row align-h="between" class="wide-row mb-4 px-4">
          <div class="text-info"><small v-if="isModified">Modificada</small></div>
          <h5 class="lead">Boleta de Vacaciones</h5>
          <div>
            <span class="fs-22">#{{ ticket.ticket_no }}</span>
          </div>
        </b-row>
        <b-row align-h="end" class="pr-4">
          <span class="label">FECHA DE SOLICITUD:</span>
          <span>{{ ticket.created_at | formatDate }}</span>
        </b-row>
        <hr>
        <b-row class="pl-3 mt-3">
          <b-col lg="8">
            <b-row align-v="center" class="wide-row">
              <span class="label">NOMBRE:</span>
              <b-link :to="`/app/rrhh/empleados/${employee.id}`" class="related-link">{{ employee | concatName4 }}</b-link>
            </b-row>
            <b-row align-v="center" class="wide-row">
              <span class="label">FECHA DE INGRESO:</span>
              <span>{{ employee.start_date | formatDate }}</span>
            </b-row>

          </b-col>
          <b-col lg="4">
            <b-row align-v="center" class="wide-row">
              <span class="label">PUESTO:</span>
              <span>{{ employee.job_title }}</span>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="pl-3 mt-3">
          <b-col lg="8" class="pb-3 pb-sm-0">
            <b-row align-v="center" class="thin-row">
              <span class="label">DÍAS ACUMULADOS:</span>
              <span v-if="pending">{{ employee.vacation_days_accumulated - employee.vacation_days_spent - employee.vacation_days_deducted }}</span>
              <span v-else>{{ ticket.vacation_days_balance_snapshot }}</span>
            </b-row>
            <b-row align-v="center" class="thin-row">
              <span class="label">DÍAS A DISFRUTAR:</span>
              <span v-if="isModified">{{ currentVacationDatesArray.length }}</span>
              <span v-else>{{ ticket.number_days_requested }}</span>
            </b-row>
            <b-row align-v="center" class="thin-row">
              <span class="label">DÍAS PENDIENTES:</span>
              <span v-if="approved || finalized">
                {{ ticket.vacation_days_balance_snapshot - ticket.number_days_requested }}
              </span>
              <span v-else>-</span>
            </b-row>
          </b-col>
          <b-col lg="4">
            <b-row class="thin-row">
              <span class="label">FECHAS DE VACACIONES:</span>
            </b-row>
            <b-row>
              <div v-if="isValid">
                <p v-for="date in currentVacationDatesArray" :key="date" class="text-secondary mb-0 pl-3">
                  {{ date | formatDate }}
                </p>
              </div>
              <div v-else>
                <small class="text-danger">Debe seleccionarse al menos un día!</small>
              </div>
            </b-row>
          </b-col>
        </b-row>
        <span v-if="canceled" class="red-stamp text-danger">CANCELADO</span>
      </b-container>

      <b-row v-if="isHrAdmin || isDev" align-h="center" class="mt-5">
        <Button v-if="approved || finalized" size="sm" variant="red" @click="cancelVacationTicketShow = true">
          <font-awesome-icon :icon="['far', 'undo-alt']" size="lg" fixed-width />
          <span class="pl-1">Cancelar</span>
        </Button>
      </b-row>
    </ContentView>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- Modal -->
    <b-modal centered lazy v-model="cancelVacationTicketShow" title="Cancelar esta boleta" @hide="checkbox = false">
      <b-container class="px-3">
        <b-row align-h="center" align-v="center" class="mt-4 text-danger">
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" fixed-width />
          <span class="pl-2">Cuidado</span>
        </b-row>
        <b-row class="mt-3">
          <p class="text-danger text-center">Esta acción es irreversible.  Cancelar esta boleta reintegrará los días disfrutados de <b>{{ employee | concatName4 }}</b> a su saldo de vacaciones</p>
        </b-row>
        <b-row align-h="center" class="mt-5">
          <b-form-checkbox v-model="checkbox">
            Entiendo, quiero cancelar esta boleta
          </b-form-checkbox>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button variant="danger" @click="cancelVacationTicket" :disabled="!checkbox">Cancelar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { subMonths } from 'date-fns';
import flatPickr from 'vue-flatpickr-component';
import { Spanish } from "flatpickr/dist/l10n/es.js";
import BackButton from '../shared/BackButton';
import Button from '../shared/Button';
import ContentView from '../shared/ContentView';
import ToastAlert from '../shared/ToastAlert';
import NavTabs from './NavTabs';

export default {
  name: 'VacationTicketShow',
  components: { flatPickr, BackButton, Button, ContentView, ToastAlert, NavTabs },
  data() {
    return {
      ticketId: Number(this.$route.params.id),
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
      processing: false,
      currentVacationDates: '',
      config: {
        locale: {
          ...Spanish,
          firstDayOfWeek: 0
        },
        position: "below",
        mode: "multiple",
        disable: [
          function(date) {
            return date.getDay() === 0;
          }
        ],
        minDate: subMonths(new Date(), 1),
        wrap: true,
        static: true
      },
      cancelVacationTicketShow: false,
      checkbox: false
    }
  },
  computed: {
    ...mapGetters(['getTicketById', 'getEmployeeById', 'isDev', 'isHrAdmin']),
    ticket() {
      return this.getTicketById(this.ticketId);
    },
    currentVacationDatesArray() {
      let array = this.currentVacationDates.split(',').map(date => date.trim()).filter(v => v);
      return array.sort((a,b) => new Date(a) - new Date(b)) // sort by ascending date
    },
    isModified() {
      return this.currentVacationDatesArray.toString() !== this.ticket.vacation_dates.toString();
    },
    isValid() {
      return this.currentVacationDatesArray.length > 0;
    },
    employee() {
      return this.getEmployeeById(this.ticket.user_id);
    },
    // manager() {
    //   return this.getEmployeeById(this.ticket.manager_id);
    // },

    // Ticket status:
    pending() {
      return this.ticket.status == 'pendiente' ? true : false;
    },
    approved() {
      return this.ticket.status == 'aprobado' ? true : false;
    },
    rejected() {
      return this.ticket.status == 'rechazado' ? true : false;
    },
    canceled() {
      return this.ticket.status == 'cancelado' ? true : false;
    },
    finalized() {
      return this.ticket.status == 'finalizado' ? true : false;
    }
  },
  mounted() {
    if (this.ticket.modified) {
      this.currentVacationDates = this.ticket.vacation_dates_modified.join(', ');
    } else {
      this.currentVacationDates = this.ticket.vacation_dates.join(', ');
    }
  },
  methods: {
    openDatepicker() {
      this.$refs.datepicker.fp.open();
    },
    restoreOriginalDates() {
      this.currentVacationDates = this.ticket.vacation_dates.join(', ');
    },
    approve() {
      if (this.processing === true) {
        return;
      }
      this.processing = true;
      if (!this.isModified) {  // Vacation Dates unchanged
        var data = {
          vacation_ticket: {
            status: 'aprobado',
            modified: false,
            vacation_dates: this.ticket.vacation_dates,
            number_days_requested: this.ticket.number_days_requested,
            vacation_days_balance_snapshot: this.employee.vacation_days_accumulated - this.employee.vacation_days_spent - this.employee.vacation_days_deducted
          }
        }
      } else {  // Vacation dates modified
        var data = {
          vacation_ticket: {
            status: 'aprobado',
            modified: true,
            vacation_dates_modified:  this.currentVacationDatesArray,
            number_days_requested: this.currentVacationDatesArray.length,
            vacation_days_balance_snapshot: this.employee.vacation_days_accumulated - this.employee.vacation_days_spent - this.employee.vacation_days_deducted
          }
        }
      }
      axios({
        method: 'put',
        url: `/api/human_resources/users/${this.ticket.user_id}/vacation_tickets/${this.ticketId}/approve`,
        data: data
      })
      .then(response => {
        console.log(response)
        this.$store.commit('setTicketData', response.data.ticket)
        this.$store.commit('approveVacationRequest', response.data.employee)
        this.alertVariant = 'success';
        this.alertMessage = 'La solicitud fue aprobado!'
      })
      .catch(error => {
        this.alertVariant = 'danger';
        if (error.response.data.errors) {
          this.alertMessage = error.response.data.errors[0];
        } else {
          this.alertMessage = 'No funcionó';
        }
      })
      .finally(() => {
        this.processing = false;
        this.alertShow = true;
      });
    },
    reject() {
      if (this.processing === true) {
        return;
      }
      this.processing = true;
      axios({
        method: 'put',
        url: `/api/human_resources/users/${this.ticket.user_id}/vacation_tickets/${this.ticketId}/reject`,
        data: {
          vacation_ticket: {
            status: 'rechazado',
            vacation_days_balance_snapshot: this.employee.vacation_days_accumulated - this.employee.vacation_days_spent - this.employee.vacation_days_deducted
          }
        }
      })
      .then(response => {
        console.log(response)
        this.$store.commit('setTicketData', response.data.ticket)
        this.$store.commit('rejectVacationRequest', response.data.employee)
        this.restoreOriginalDates();
        this.alertVariant = 'success';
        this.alertMessage = 'La solicitud fue rechazado'
      })
      .catch(error => {
        this.alertVariant = 'danger';
        if (error.response.data.errors) {
          this.alertMessage = error.response.data.errors[0];
        } else {
          this.alertMessage = 'No funcionó';
        }
      })
      .finally(() => {
        this.processing = false;
        this.alertShow = true;
      });
    },
    cancelVacationTicket() {
      if (this.processing === true) {
        return;
      }
      this.processing = true;
      axios({
        method: 'put',
        url: `/api/human_resources/users/${this.ticket.user_id}/vacation_tickets/${this.ticketId}/cancel`,
        data: {
          vacation_ticket: { status: 'cancelado' }
        }
      })
      .then(response => {
        console.log(response)
        this.cancelVacationTicketShow = false;
        this.$store.commit('setTicketData', response.data.ticket)
        this.$store.commit('cancelVacationRequest', response.data.employee)
        this.alertVariant = 'success';
        this.alertMessage = 'La boleta fue cancelado'
      })
      .catch(error => {
        this.alertVariant = 'danger';
        if (error.response.data.errors) {
          this.alertMessage = error.response.data.errors[0];
        } else {
          this.alertMessage = 'No funcionó';
        }
      })
      .finally(() => {
        this.processing = false;
        this.alertShow = true;
      });
    },
    finalize() {
      if (this.processing === true) { return }
      this.processing = true;
      axios
        .put(`/api/human_resources/users/${this.ticket.user_id}/vacation_tickets/${this.ticketId}/finalize`, {
          vacation_ticket: { status: 'finalizado' }
        })
        .then(response => {
          console.log(response)
          this.$store.commit('setTicketData', response.data)
          this.alertVariant = 'success';
          this.alertMessage = 'La solicitud fue finalizado'
        })
        .catch(error => {
          this.alertVariant = 'danger';
          if (error.response.data.errors) {
            this.alertMessage = error.response.data.errors[0];
          } else {
            this.alertMessage = 'No funcionó';
          }
        })
        .finally(() => {
          this.processing = false;
          this.alertShow = true;
        });
    },
    fetchPdf() {
      axios
        .get(`/api/human_resources/vacation_tickets/${this.ticketId}/pdf`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
          },
          responseType: 'blob'
        })
        .then(response => {
          console.log(response)
          const blob = new Blob([response.data], { type: 'application/pdf' });
          // Open in browser window
          // const fileUrl = window.URL.createObjectURL(blob);
          // window.open(fileUrl);

          // Download link
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Boleta #${this.ticket.ticket_no} - ${this.employee.first_name} ${this.employee.last_name}.pdf`;
          link.click()
        })
        .catch(error => {
          console.log(error)
          this.alertVariant = 'danger';
          this.alertMessage = 'No funcionó';
          this.alertShow = true;
        });
    }
  }
}
</script>

<style scoped>
#boleta-box {
  position: relative;
  padding: 1em;
  font-size: 14px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.label {
  font-weight: bold;
  padding-right: 10px;
}
.wide-row {
  height: 50px;
}
.thin-row {
  height: 30px;
}
.red-stamp {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: rotate(-30deg);
  font-size: 30px;
}
</style>
