<template>
  <div>
    <b-row align-h="center">
      <b-col sm="10">
        <div class="step-header">
          <div class="step-title">
            <span>SELECCIONAR DISEÑO</span>
            <span v-if="secondary">SECUNDARIO</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right">
        <Button @click="$store.commit('setCurrentStep', 'design')">
          Regresar
        </Button>
      </b-col>
    </b-row>

    <b-row v-if="secondary" align-h="center" class="lead">
      <span>** Diseños Secundarios Compatibles **</span>
    </b-row>

    <b-row align-h="center">
      <b-col lg="3" sm="4" cols="6">
        <b-radio-group v-model="cyclesFilter" :options="cyclesOptions" stacked />
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col lg="8" sm="10">
        <b-row class="mt-5 pl-2">
          <span class="text-muted">Seleccione uno..</span>
        </b-row>

        <b-row align-h="around">
          <div v-for="design in filteredDesigns" :key="design.id" class="thumbnail" @click="openDetailModal(design)">
            <div class="thumbnail-text rounded-top">
              <font-awesome-icon :icon="['fas', 'check-circle']" size="lg" fixed-width />
              <span>#{{ design.item_code }}</span><br>
              <span>{{ design.name }}</span>
              <b-row align-h="end" class="pr-3">
                <span>{{ design.price }}</span>
              </b-row>
            </div>
            <b-img v-if="design.cover_sample_id" :src="design.cover_sample_url" class="rounded-bottom" />
            <div v-else class="d-flex align-items-center justify-content-center border rounded-bottom" style="min-height: 80px">
              <font-awesome-icon :icon="['far', 'image']" size="2x" class="text-muted" />
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>

    <b-modal v-model="detailModalShow" hide-footer @hide="selectedDesign = null">
      <template #modal-header>
        <span v-if="selectedDesign">
          {{ selectedDesign.item_code }}: {{ selectedDesign.name }}
        </span>
      </template>
      <b-container v-if="selectedDesign" fluid>
        <b-row align-h="center">
          <b-img v-for="sample in sampleHash[selectedDesign.id]" :src="sample" :key="sample" class="rounded-bottom" />
        </b-row>
        <b-row>
          <b-col>
            <p>{{ selectedDesign.description }}</p>
          </b-col>
        </b-row>
        <b-row v-if="isCortina">
          <b-col cols="6">
            <p class="outer-label">
              Tipo de slat:
            </p>
          </b-col>
          <b-col cols="6">
            <span>{{ selectedDesign.slat_type }}</span>
          </b-col>
        </b-row>
        <b-row v-if="isCortina">
          <b-col cols="6">
            <p class="outer-label">
              Capacidad de ciclos:
            </p>
          </b-col>
          <b-col cols="6">
            <span>{{ selectedDesign.max_cycles }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <p class="outer-label">
              Afectación del viento:
            </p>
          </b-col>
          <b-col cols="6">
            <span>{{ selectedDesign.wind_factor }}%</span>
          </b-col>
        </b-row>

        <!-- TODO:  colores de slat (cortina) -->
        <b-row>

        </b-row>

        <b-row v-if="isCortina" align-h="center">
          <b-col cols="11">
            <b-row align-h="center">
              <Button v-if="!isCortina || (isCortina && selectedDesign.colors.length === 2)" variant="green" size="lg" @click="updateDesign">
                Seleccionar
              </Button>
              <small v-else class="text-danger">
                Este diseño faltan 2 colores de slat. Advise a su administrador por favor.
              </small>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Button from '../../shared/Button'

export default {
  name: 'DesignSelector',
  components: { Button },
  props: {
    secondary: { // Secondary design mode
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      designs: [],
      sampleHash: {},
      cyclesFilter: 'c15000',  // load according to configuration?
      selectedDesign: null,
      detailModalShow: false,
      categoryOptions: [ 'Elemax', 'OHD', 'ALTAS', 'Universal' ],
      c8000: [],
      c10000: [],
      c15000: [],
      c50000: [] ,
      c100000: [],
      cyclesOptions: [
        { text: 'Ciclos 8.000', value: 'c8000' },
        { text: 'Ciclos 10.000', value: 'c10000' },
        { text: 'Ciclos 15.000', value: 'c15000' },
        { text: 'Ciclos 50.000', value: 'c50000' },
        { text: 'Ciclos 100.000', value: 'c100000' }
      ],
      processing: false
    }
  },
  computed: {
    ...mapState ({ submital: state => state.configurator.submital }),
    ...mapGetters (['configurableId', 'configurableType', 'configurableTypeUrlString', 'isCortina']),
    filteredDesigns () {
      return this[this.cyclesFilter]
    }
  },
  mounted () {
    this.fetchDesigns()
  },
  methods: {
    fetchDesigns () {
      this.$http.get(`/api/production/submitals/${this.submital.id}/designs`, {
        params: {
          secondary: this.secondary
        }
      })
        .then((res) => {
          console.log(res.data)
          this.designs = res.data.designs
          this.designs.forEach(d => {
            if (d.max_cycles <= 8000) {
              this.c8000.push(d)
            } else if (d.max_cycles <= 10000) {
              this.c10000.push(d)
            } else if (d.max_cycles <= 15000) {
              this.c15000.push(d)
            } else if (d.max_cycles <= 50000) {
              this.c50000.push(d)
            } else {
              this.c100000.push(d)
            }
          })
          this.sampleHash = res.data.samples
        })
        .catch(e => console.log(e))
    },
    updateDesign () {
      const field = this.secondary ? 'design_sec_id' : 'design_id'
      this.$http.patch(`/api/production/submitals/${this.submital.id}/${this.configurableTypeUrlString}/${this.configurableId}`, {
        [this.configurableType.toLowerCase()]: {
          [field]: this.selectedDesign.id
        }
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('setConfigurable', res.data)
          this.detailModalShow = false
          this.$store.commit('setCurrentStep', 'design')
        })
        .catch(e => console.log(e))
    },
    openDetailModal (design) {
      this.selectedDesign = design
      this.detailModalShow = true
    }
  }
}
</script>
